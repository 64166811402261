import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../../store';
import { sidebarClose } from '../../store/modules/Sidebar/actions';
import { SidebarContainer, CloseButton } from './sidebar.styles';

export default function Sidebar({ onCloseCallback, children }) {
  const [bgColor, _setBgColor] = useState('#D1DEE2');

  const [state, dispatch] = useContext(Store);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(state.sidebarStatus);
  }, [state]);

  function handleCloseSidebar() {
    dispatch(sidebarClose());
    setCollapsed(false);
    if (onCloseCallback != null) {
      onCloseCallback();
    }
  }

  return (
    <SidebarContainer bgColor={bgColor} collapsed={collapsed}>
      <CloseButton onClick={handleCloseSidebar} />
      {children}
    </SidebarContainer>
  );
}
