import { useRef } from 'react';
import useVideoContext from '../../../hooks/video/useVideoContext';
import useLocalAudioToggle from '../../../hooks/video/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/video/useLocalVideoToggle';
import useDevices from '../../../hooks/video/useDevices';
import { ROLE_PROFESSIONAL } from '../../../routes/constants';
import CreateConsultDialog, {
  type CreateConsultDialogHandles
} from '../../../v2/dialogs/consult/create';
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  InsertInvitation,
  MoreVert,
  CallEnd
} from '@mui/icons-material';
import { type Token } from '../../../v2/entities/session';

type RoomControlProps = {
  disabled?: boolean;
  sessionToken: Token;
  handleDisconnectCall: () => void;
  onClickMoreButton?: () => void;
};

export default function RoomControls({
  disabled = false,
  sessionToken,
  handleDisconnectCall,
  onClickMoreButton
}: RoomControlProps) {
  const createConsultDialogRef = useRef<CreateConsultDialogHandles>(null);

  const { localTracks } = useVideoContext();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  // @ts-expect-error Localtracks is not correct typed
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  const { hasVideoInputDevices } = useDevices();

  return (
    <>
      <div className="flex w-full items-center justify-center gap-x-4 p-5 md:gap-x-9 [&_span]:m-0 [&_svg]:scale-150">
        <button
          type="button"
          className="grid h-14 w-14 shrink-0 place-items-center rounded-full border-2 border-black/10 bg-yellow-180 p-0 transition-all duration-500 hover:bg-black/25 md:h-16 md:w-16 [&_svg]:fill-gray-950"
          onClick={toggleAudioEnabled}
          disabled={!hasAudioTrack || disabled}
        >
          {isAudioEnabled ? (
            <Mic sx={{ fontSize: '20px' }} />
          ) : (
            <MicOff sx={{ fontSize: '20px' }} />
          )}
        </button>

        <button
          type="button"
          className="grid h-14 w-14 shrink-0 place-items-center rounded-full border-2 border-black/10 bg-yellow-180 p-0 transition-all duration-500 hover:bg-black/25 md:h-16 md:w-16 [&_svg]:fill-gray-950"
          onClick={toggleVideoEnabled}
          disabled={!hasVideoInputDevices || disabled}
        >
          {isVideoEnabled ? (
            <Videocam sx={{ fontSize: '20px' }} />
          ) : (
            <VideocamOff sx={{ fontSize: '20px' }} />
          )}
        </button>

        {sessionToken && sessionToken?.user_type === ROLE_PROFESSIONAL && (
          <button
            type="button"
            className="grid h-14 w-14 shrink-0 place-items-center rounded-full border-2 border-black/10 bg-yellow-180 p-0 transition-all duration-500 hover:bg-black/25 md:h-16 md:w-16 [&_svg]:fill-error"
            onClick={handleDisconnectCall}
          >
            <CallEnd sx={{ fontSize: '20px' }} />
          </button>
        )}

        {sessionToken && sessionToken?.user_type === ROLE_PROFESSIONAL && (
          <button
            type="button"
            className="hidden h-14 w-14 shrink-0 place-items-center rounded-full border-2 border-black/10 bg-yellow-180 p-0 transition-all duration-500 hover:bg-black/25 md:h-16 md:w-16 lg:grid [&_svg]:fill-gray-950"
            onClick={() => createConsultDialogRef.current?.open()}
          >
            <InsertInvitation sx={{ fontSize: '20px' }} />
          </button>
        )}

        {sessionToken &&
          sessionToken?.user_type === ROLE_PROFESSIONAL &&
          onClickMoreButton && (
            <button
              type="button"
              className="grid h-14 w-14 shrink-0 place-items-center rounded-full border-2 border-black/10 bg-yellow-180 p-0 transition-all duration-500 hover:bg-black/25 md:h-16 md:w-16 lg:hidden [&_svg]:fill-gray-950"
              onClick={() => {
                onClickMoreButton();
              }}
            >
              <MoreVert sx={{ fontSize: '25px' }} />
            </button>
          )}
      </div>

      {sessionToken && sessionToken?.user_type === ROLE_PROFESSIONAL && (
        <CreateConsultDialog ref={createConsultDialogRef} />
      )}
    </>
  );
}
