import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Container,
  TabBody,
  List
} from './tabmenu.styles';
import DataFormPatient from './TabContent/DataFormPatient';
import DataFormSecretary from './TabContent/DataFormSecretary';
import DataFormDoctor from './TabContent/DataFormDoctor';
import MyConsentmentTerms from './TabContent/MyConsentmentTerms';
// import MoreDetailsPatient from './TabContent/MoreDetailsPatient';
import MoreDetailsDoctor from './TabContent/MoreDetailsDoctor';
import PaymentMethods from './TabContent/PaymentMethods';
import PaymentDetails from './TabContent/PaymentDetails';
import PasswordTab from './TabContent/PasswordTab';
import {
  enableDoctorPaymentMethods,
  enableDoctorSchedule
} from '../../constants/featureToggles';
import { useToast } from '../../contexts/ToastContext';
import { getSubscription } from '../../services/plan';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PROFESSIONAL } from '../../routes/constants';

export default function TabMenu({
  isDoctor = false,
  isSecretary = false,
  view
}) {
  const { t } = useTranslation(['doctor_profile']);
  const [subscription, setSubscription] = useState(null);
  let data = [];

  const { credentials } = useAuth();
  const toast = useToast();

  const getSubscriptionData = async () => {
    try {
      const resSub = await getSubscription();
      setSubscription(resSub.data);
    } catch (error) {
      toast(t('Não foi possível buscar as informações de Inscrição.'), {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
      getSubscriptionData();
    }
  }, [credentials?.token?.user_type]);

  if (isDoctor) {
    data = [
      {
        key: 'data',
        title: t('Dados'),
        content: <DataFormDoctor />
      },
      {
        key: 'schedule',
        title: t('Horários'),
        content: <MoreDetailsDoctor />,
        hidden: !enableDoctorSchedule
      },
      {
        key: 'payments',
        title: t('Meios de Pagamento'),
        content: <PaymentMethods />,
        hidden: !enableDoctorPaymentMethods
      },
      {
        key: 'password',
        title: t('Senha'),
        content: <PasswordTab />
      },
      {
        key: 'plan_details',
        title: t('Plano'),
        content: (
          <PaymentDetails
            subscription={subscription}
            reloadSubscription={getSubscriptionData}
            view={view}
          />
        )
      }
    ].filter(({ hidden }) => !hidden);
  } else if (isSecretary) {
    data = [
      {
        key: 'data',
        title: t('Dados'),
        content: <DataFormSecretary />
      },
      {
        key: 'password',
        title: t('Senha'),
        content: <PasswordTab />
      }
    ].filter(({ hidden }) => !hidden);
  } else {
    data = [
      {
        key: 'data',
        title: t('Dados'),
        content: <DataFormPatient />
      },
      {
        key: 'consentTerms',
        title: t('Termos de Consentimento'),
        content: <MyConsentmentTerms />
      },
      {
        key: 'password',
        title: t('Senha'),
        content: <PasswordTab />
      }
      // { title: 'Mais', content: <MoreDetailsPatient /> },
    ];
  }

  const [active, setActive] = useState(data[0].key);

  const updateActive = useCallback(
    key => {
      if (isDoctor) localStorage.setItem('doctor-profile-tab', key);
      setActive(key);
    },
    [setActive]
  );

  useEffect(() => {
    if (isDoctor) {
      const profileTab = localStorage.getItem('doctor-profile-tab');

      // It only updates the active tab if the local storage value provided is valid
      if (profileTab && data.some(item => item.key === profileTab)) {
        setActive(profileTab);
      }
    }
  }, []);

  return (
    <Container>
      <ul>
        {data.map(item => (
          <List
            key={item.key}
            onClick={() => {
              updateActive(item.key);
            }}
            isActive={item.key === active}
            isDoctor={isDoctor}
          >
            {item.title}
          </List>
        ))}
      </ul>
      {data.map(
        ({ key, content }, id) =>
          key === active && <TabBody key={id.toString()}>{content}</TabBody>
      )}
    </Container>
  );
}
