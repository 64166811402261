import { Listbox } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type SelectInputProps = {
  label?: string
  options: Array<{ label: string, value: string }>
  defaultOption?: string | null
  onSelectOption?: (option: { label: string, value: string } | null) => void
  error?: string
}

export default function SelectInput({
  label,
  error,
  options,
  defaultOption,
  onSelectOption
}: SelectInputProps) {
  const [lastDefaultOption, setLastDefaultOption] = useState(defaultOption);

  const [selectedOption, setSelectedOption] = useState<null | {
    label: string
    value: string
  }>(options.find((item) => item.value === lastDefaultOption) ?? null);

  useEffect(() => {
    if (defaultOption !== lastDefaultOption) {
      setLastDefaultOption(defaultOption);
      const option = options.find((item) => item.value === defaultOption);
      if (option) setSelectedOption(option);
    }
  }, [defaultOption, options, lastDefaultOption]);

  return (
    <div className="relative flex items-start w-full flex-col">
      {label && (
        <label className="mb-1 font-nunito text-sm font-semibold text-gray-350">
          {label}
        </label>
      )}

      <Listbox
        as="div"
        className="relative flex flex-col w-full"
        value={selectedOption}
        onChange={(option) => {
          setSelectedOption(option);
          if (onSelectOption) onSelectOption(option);
        }}
      >
        <Listbox.Button
          className={twMerge(
            'form-select text-left h-10 w-full rounded-md px-4 py-3 text-sm text-gray-450 ring-transparent focus:border-yellow-650 focus:ring-yellow-650',
            error ? 'border-error bg-error/10' : 'border-blue-650/50 bg-white'
          )}
        >
          {selectedOption?.label}
        </Listbox.Button>
        <Listbox.Options className="absolute w-full h-fit overflow-y-auto max-h-[13rem] top-[110%] bg-white shadow-md rounded-md overflow-hidden flex flex-col z-10">
          {React.Children.toArray(
            options.map((option) => (
              <Listbox.Option
                className="py-2 px-4 text-sm cursor-pointer text-blue-650 hover:bg-yellow-650 hover:text-white transition-all duration-200"
                value={option}
              >
                {option.label}
              </Listbox.Option>
            ))
          )}
        </Listbox.Options>
      </Listbox>

      {error && (
        <span className="text-xs font-nunito mt-1 text-error">{error}</span>
      )}
    </div>
  );
}
