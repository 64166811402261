import { SYMPTOM_NAMES } from '../../constants/common';

const groupSymptoms = (symptomMap) => {
  const verifiedSymptoms = symptomMap.reported;
  return symptomMap.domain.reduce(
    (groups, symptomName) => {
      if (Object.prototype.hasOwnProperty.call(verifiedSymptoms, symptomName)) {
        if (verifiedSymptoms[symptomName].cert === 'Y') {
          const subquestionData = verifiedSymptoms[symptomName].sub || {};
          return {
            ...groups,
            yes: { ...groups.yes, [symptomName]: subquestionData }
          };
        }
        return { ...groups, maybe: [...groups.maybe, symptomName] };
      }

      return { ...groups, no: [...groups.no, symptomName] };
    },
    { yes: {}, maybe: [], no: [] }
  );
};

const stringifySymptomList = (symptomList) => {
  if (symptomList.length)
    return symptomList
      .map((symptomId) => `${SYMPTOM_NAMES[symptomId]}`)
      .join('\n\n');
  return 'nenhum item';
};

const stringifySymptomSubanswers = (sub) => {
  if (Object.keys(sub).length) {
    return `\\[${Object.entries(sub)
      .map(([shorthand, answer]) => `**${shorthand}**: ${answer}`)
      .join(' ｜ ')}\\]`;
  }
  return '';
};

const stringifySymptomMap = (symptomMap) => {
  if (Object.keys(symptomMap).length) {
    return Object.entries(symptomMap)
      .map(
        ([symptomId, symptomSub]) =>
          `${SYMPTOM_NAMES[symptomId]} ${stringifySymptomSubanswers(
            symptomSub
          )}`
      )
      .join('\n\n');
  }
  return 'nenhum item';
};

const formatGroupedSymptoms = (groupedSymptoms) => {
  const confirmedSymptomString = stringifySymptomMap(groupedSymptoms.yes);
  const uncertainSymptomString = stringifySymptomList(groupedSymptoms.maybe);
  const refutedSymptomString = stringifySymptomList(groupedSymptoms.no);
  const confirmedSymptomHeader = '**Respondi SIM para:**\n';
  const uncertainSymptomHeader = '**Respondi NÃO SEI para:**\n';
  const refutedSymptomHeader = '**Respondi NÃO para:**\n';
  const separator = '---------------------------------------';

  if (groupedSymptoms.maybe.length > 0) {
    return [
      confirmedSymptomHeader,
      confirmedSymptomString,
      '\n',
      separator,
      uncertainSymptomHeader,
      uncertainSymptomString,
      '\n',
      separator,
      refutedSymptomHeader,
      refutedSymptomString
    ].join('\n');
  }

  return [
    confirmedSymptomHeader,
    confirmedSymptomString,
    '\n',
    separator,
    refutedSymptomHeader,
    refutedSymptomString
  ].join('\n');
};

export default (symptomString) => {
  try {
    const symptomsMap = JSON.parse(symptomString);
    const groupedSymptoms = groupSymptoms(symptomsMap.data);
    return formatGroupedSymptoms(groupedSymptoms);
  } catch (err) {
    return 'N/A';
  }
};
