import styled from 'styled-components/macro';
import { FaArrowAltCircleRight } from 'react-icons/fa';

export const ProcessFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GetInRoom = styled.div`
  position: relative;
  text-align: center;
  margin-top: 10px;

  p {
    font-size: 18px;
    font-weight: bold;
    color: var(--caren-green);
  }
`;

export const GetInIcon = styled(FaArrowAltCircleRight).attrs({
  size: 35,
  color: 'var(--caren-green)'
})`
  position: absolute;
  top: -50px;
  right: 19px;

  animation: wait 1.5s infinite 0s ease-out;

  @keyframes wait {
    70% {
      transform: rotate(0deg) scale(0.85);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;
