import { useEffect, useState, useMemo, useRef } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import Button from '../../inputs/buttons/Button';
import TextInput from '../../inputs/input/text';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import MaskInput from '../../inputs/input/mask';
import SelectInput from '../../inputs/input/select';
import { stateLabels, stateCities } from '../../../../utils/brazilStateUtils';
import AutocompleteInput from '../../inputs/input/autocomplete';
import useCheckoutForm from '../../../hooks/checkout/useCheckoutForm';
import InvalidPostalCodeDialog, {
  type InvalidPostalCodeDialogHandles
} from '../../../dialogs/checkout/invalidPostalCode';

const AddressFormSchema = z.object({
  zipCode: z
    .string({ required_error: 'Digite um CEP' })
    .nonempty('Digite um CEP')
    .regex(/\d{5}-\d{3}/, 'Digite um CEP válido'),
  street: z
    .string({ required_error: 'Digite o logradouro' })
    .nonempty('Digite o logradouro'),
  number: z
    .string({ required_error: 'Digite o número' })
    .nonempty('Digite o número'),
  complement: z.string().optional(),
  city: z
    .string({ required_error: 'Digite a cidade' })
    .nonempty('Digite a cidade'),
  state: z
    .string({ required_error: 'Escolha um estado' })
    .nonempty('Escolha um estado')
    .regex(/[A-Z]{2}/, 'Escolha um estado válido'),
  country: z
    .string({ required_error: 'Digite um país válido' })
    .nonempty('Digite um país válido')
    .regex(/[A-Z]{2}/, 'Digite um país válido')
});

export type AddressFormData = z.infer<typeof AddressFormSchema>;

type AddressStepProps = {
  step: number;
};

export default function AddressStep({ step }: AddressStepProps) {
  const { actualStep, forwardStep, backwardStep, setStepData } =
    useCheckoutForm();

  const [addressState, setAddressState] = useState<null | string>(null);
  const [addressCity, setAddressCity] = useState<null | string>(null);
  const [zipCodeError, setZipCodeError] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    resetField,
    formState: { errors, defaultValues }
  } = useForm<AddressFormData>({
    resolver: zodResolver(AddressFormSchema),
    defaultValues: { country: 'BR' }
  });

  const zipcode = watch('zipCode');
  const selectedAddressState = watch('state');

  const invalidPostalCodeDialogRef =
    useRef<InvalidPostalCodeDialogHandles>(null);

  const cityOptions = useMemo(() => {
    if (!selectedAddressState) {
      return [];
    }

    const stateKey = selectedAddressState.toLowerCase();

    const cities = stateCities[stateKey as keyof typeof stateCities] ?? [];

    return cities.map((city: string) => ({ label: city, value: city }));
  }, [selectedAddressState]);

  const saveData = (data: AddressFormData) => {
    setStepData(step, data);

    if (zipCodeError) {
      invalidPostalCodeDialogRef?.current?.open();
      return;
    }

    forwardStep();
  };

  useEffect(() => {
    if (zipcode) {
      const zipcodeNumbers = zipcode.replace(/\D/g, '');

      if (zipcodeNumbers.length >= 8) {
        fetch(`https://viacep.com.br/ws/${zipcodeNumbers}/json/`, {
          headers: { 'Content-Type': 'application/json' }
        })
          .then(async response => await response.json())
          .then(data => {
            setValue('street', data.logradouro, { shouldValidate: true });
            setValue('state', data.uf, { shouldValidate: true });
            setValue('city', data.localidade, { shouldValidate: true });
            setAddressState(data.uf);
            setAddressCity(data.localidade);
            setZipCodeError(data.erro || false);
          });
      }
    }
  }, [zipcode]);

  if (actualStep !== step) return null;

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="mt-7 flex h-full w-full max-w-sm flex-grow flex-col items-center md:mt-10"
    >
      <div className="mb-8 mt-6 flex w-full flex-col gap-y-5">
        <div className="grid w-full grid-cols-1 gap-5 sm:grid-cols-3">
          <Controller
            control={control}
            name="zipCode"
            render={({ field: { onChange, ref, value } }) => (
              <MaskInput
                mask="99999-999"
                label="CEP:"
                error={errors.zipCode?.message}
                onChange={e => {
                  onChange(e);
                }}
                defaultValue={value}
                ref={ref}
              />
            )}
          />
          <div className="col-span-2 w-full">
            <TextInput
              label="Logradouro:"
              type="text"
              {...register('street')}
              error={errors.street?.message}
            />
          </div>
        </div>
        <div className="grid w-full grid-cols-1 gap-5 sm:grid-cols-3">
          <TextInput
            label="Número:"
            type="text"
            {...register('number')}
            error={errors.number?.message}
          />
          <div className="col-span-2 w-full">
            <TextInput
              label="Complemento:"
              type="text"
              {...register('complement')}
              error={errors.complement?.message}
            />
          </div>
        </div>
        <div className="grid w-full grid-cols-1 gap-5 sm:grid-cols-3">
          <SelectInput
            label="País:"
            defaultOption={defaultValues?.country}
            options={[{ label: 'Brasil', value: 'BR' }]}
            onSelectOption={option => {
              if (option) {
                setValue('country', option.value, { shouldValidate: true });
              } else {
                resetField('country', { keepError: false });
              }
            }}
            error={errors.country?.message}
          />
          <div className="col-span-2 w-full">
            <SelectInput
              label="Estado:"
              defaultOption={addressState ?? defaultValues?.state}
              options={Object.entries(stateLabels).map(([key, value]) => ({
                label: value,
                value: key.toUpperCase()
              }))}
              onSelectOption={option => {
                if (option) {
                  setValue('state', option.value, { shouldValidate: true });
                } else {
                  resetField('state', { keepError: false });
                }
              }}
              error={errors.state?.message}
            />
          </div>
        </div>
        <div className="w-full">
          <AutocompleteInput
            label="Cidade:"
            defaultOption={addressCity ?? defaultValues?.city}
            options={cityOptions}
            onSelectOption={option => {
              if (option) {
                setValue('city', option.value, { shouldValidate: true });
              } else {
                resetField('city', { keepError: false });
              }
            }}
            error={errors.city?.message}
          />
        </div>
      </div>
      <Button
        label="Ir para pagamento"
        type="submit"
        colors="yellow"
        size="large"
      />
      <button
        className="mt-10 font-nunito text-sm font-semibold text-blue-650"
        type="button"
        onClick={() => {
          backwardStep();
        }}
      >
        Voltar
      </button>
      <InvalidPostalCodeDialog
        ref={invalidPostalCodeDialogRef}
        onProceed={() => {
          forwardStep();
        }}
      />
    </form>
  );
}
