import { type ButtonHTMLAttributes, type ElementType } from 'react';
import { twMerge } from 'tailwind-merge';

const BUTTON_STYLES = {
  yellow: {
    icon: 'text-white',
    text: 'text-white',
    background: 'bg-gray-950 hover:bg-gray-450',
    disabled: 'disabled:opacity-30'
  },
  neutral: {
    icon: 'text-gray-950 group-hover:text-gray-950',
    text: 'text-gray-950 hover:text-gray-950',
    background: 'bg-yellow-180 hover:bg-yellow-400',
    disabled: 'disabled:opacity-30'
  },
  success: {
    icon: 'text-white',
    text: 'text-white',
    background: 'bg-success hover:bg-success-dark',
    disabled: 'disabled:grayscale'
  }
};

type ButtonProps = {
  label: string;
  icon?: ElementType;
  colors?: 'yellow' | 'neutral' | 'success';
  size?: 'small' | 'large' | 'medium';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  label,
  icon: Icon,
  colors = 'neutral',
  size = 'small',
  ...rest
}: ButtonProps) {
  return (
    <button
      className={twMerge(
        'group flex items-center justify-center gap-x-2 rounded-md px-2.5 py-3 font-nunito transition-all duration-300  disabled:cursor-not-allowed',
        BUTTON_STYLES[colors].background,
        BUTTON_STYLES[colors].text,
        BUTTON_STYLES[colors].disabled,
        size === 'small'
          ? 'h-10 w-full text-sm font-semibold sm:w-fit'
          : size === 'large'
          ? 'h-14 w-full text-base font-bold'
          : 'h-12 w-full text-base font-semibold'
      )}
      {...rest}
    >
      {Icon && (
        <Icon
          className={twMerge(
            'inline h-6 w-6 shrink-0 transition-all duration-300',
            BUTTON_STYLES[colors].icon
          )}
        />
      )}
      {label}
    </button>
  );
}
