import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useRef } from 'react';
import {
  CreateConsultButton,
  DoctorConsultCard,
  SecretaryPanelContainer
} from './styles';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';
import { FORMAT } from '../../utils/moment/momentFormat';
import ImageOrName from '../ImageOrName';
import CreateConsultDialog, {
  type CreateConsultDialogHandles
} from '../../v2/dialogs/consult/create';
import { useNextConsults } from '../../v2/hooks/secretary/useConsults';

type SecretaryPanelProps = {
  onResendClick: (key: string) => void;
}

export default function SecretaryPanel({ onResendClick }: SecretaryPanelProps) {
  const createConsultDialogRef = useRef<CreateConsultDialogHandles>(null);
  const { doctors } = useNextConsults();

  const getDateNextConsultDoctor = (date: string) => {
    const localDate = momentUtcLocal(date);

    const isToday = localDate.isBefore(
      moment()
        .add(1, 'days')
        .set({ h: 0, m: 0, s: 0, millisecond: 0 })
        .toISOString()
    );
    const isTomorrow =
      localDate.isAfter(
        moment()
          .add(1, 'days')
          .set({ h: 0, m: 0, s: 0, millisecond: 0 })
          .toISOString()
      ) &&
      localDate.isBefore(
        moment()
          .add(2, 'days')
          .set({ h: 0, m: 0, s: 0, millisecond: 0 })
          .toISOString()
      );

    let dateText = localDate.format(FORMAT['pt-datemonth']);
    if (isToday) dateText = 'Hoje';
    if (isTomorrow) dateText = 'Amanhã';

    return `${dateText} - ${localDate.format(FORMAT['hour-and-minute'])}`;
  };

  const maybeRenderResendButton = (consult: any) => {
    const hasSecretaryFeature =
      consult?.employee?.active_subscription?.plan?.enable_secretaries;

    const canShow =
      consult.invitation?.insurance_partner ||
      consult?.teleconsultation?.insurance_coverage ||
      hasSecretaryFeature;

    if (canShow && consult.invitation.accepted_at === null) {
      return (
        <button
          type="button"
          onClick={() => {
            onResendClick(consult.invitation.key);
          }}
        >
          Reenviar convite
        </button>
      );
    }

    return null;
  };

  return (
    <SecretaryPanelContainer>
      <div>
        <CreateConsultButton
          onClick={() => {
            createConsultDialogRef.current?.open();
          }}
          type="button"
        >
          <PaperAirplaneIcon /> Criar Teleconsulta
        </CreateConsultButton>
      </div>
      <div>
        <p>Próximos Atendimentos</p>
      </div>
      <ul>
        {Array.from(doctors, data => data[1] ?? []).map(consult => (
          <li key={consult.key}>
            <DoctorConsultCard
              today={moment
                .utc(consult.start_datetime)
                .local()
                .isBefore(
                  moment()
                    .add(1, 'days')
                    .set({ h: 0, m: 0, s: 0, millisecond: 0 })
                    .toISOString()
                )}
              pending={!consult.teleconsultation?.confirmed}
            >
              <header>
                <div>
                  <div>
                    <ImageOrName
                      src={consult.employee?.avatar_url}
                      title={consult.employee?.name ?? 'Desconhecido'}
                      titleSize={16}
                      backgroundStyle={{
                        width: 60,
                        height: 60,
                        borderRadius: '100%',
                        backgroundColor: 'var(--caren-image-fallback)'
                      }}
                    />
                  </div>
                  <div>
                    <ImageOrName
                      src={consult.patient?.avatar_url}
                      title={consult.patient_name ?? 'Paciente'}
                      titleSize={16}
                      backgroundStyle={{
                        width: 45,
                        height: 45,
                        borderRadius: '100%',
                        backgroundColor: 'var(--caren-image-fallback)'
                      }}
                      iconSyle={{
                        width: 40,
                        height: 40
                      }}
                    />
                  </div>
                </div>
                <span>{getDateNextConsultDoctor(consult.start_datetime)}</span>
                <h2>
                  {consult.employee?.gender === 'female' ? 'Dra.' : 'Dr.'}
                  &nbsp;{consult.employee?.name ?? 'Desconhecido'}
                </h2>
                <p>
                  Paciente:{' '}
                  {consult.patient_name && consult.patient_name !== 'Paciente'
                    ? consult.patient_name
                    : consult.invitation.email}
                </p>
              </header>
              <footer>
                <p>
                  {consult.invitation && consult.invitation.accepted_at === null
                    ? 'Confirmação Pendente'
                    : 'Consulta Confirmada'}
                </p>
                <Countdown
                  date={moment.utc(consult.start_datetime).local().valueOf()}
                  renderer={({
                    formatted: { days, hours, minutes, seconds }
                  }) => (
                    <>
                      <p>
                        <span>{days !== '00' ? `${days} dias` : ''}</span>
                        {hours}h&nbsp;{minutes}m&nbsp;
                        {days === '00' ? `${seconds}s` : ''}
                      </p>
                    </>
                  )}
                />
                {maybeRenderResendButton(consult)}
              </footer>
            </DoctorConsultCard>
          </li>
        ))}
      </ul>

      <CreateConsultDialog ref={createConsultDialogRef} isManager />
    </SecretaryPanelContainer>
  );
}
