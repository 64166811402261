import { captureException } from '@sentry/react';
import axios from 'axios';
import i18n from 'i18next';
import getUserCurrentTimezone from '../utils/getUserCurrentTimezone';

export const API_BASEURL = import.meta.env.VITE_API || 'http://localhost:3001';
const instanceId = `${Date.now()}_${Math.floor(Math.random() * 1000000)}`;

let refreshTokenPromise: Promise<any> | null = null;
const maxRefreshAttempts = 3;

const api = axios.create({
  baseURL: API_BASEURL,
  timeout: import.meta.env.VITE_TIMEOUT || 60000,
  withCredentials:
    import.meta.env.PROD || import.meta.env.VITE_CREDENTIALS === 'true'
});

const fetchRefreshToken = async (
  attempt: number
): Promise<string | Promise<string>> =>
  await api
    .request({
      url: '/refresh',
      method: 'post',
      refreshAttempt: attempt
    })
    .then(({ data }) => {
      const { token } = data;
      window.postMessage(
        { type: 'refreshSession', data: { token } },
        window.location.origin
      );

      return token;
    })
    .catch(async () => {
      if (attempt < maxRefreshAttempts) {
        return await fetchRefreshToken(attempt + 1);
      }

      window.postMessage(
        { type: 'clearSession', data: {} },
        window.location.origin
      );

      return null;
    });

api.interceptors.request.use(async config => {
  if (!config.headers) return config;
  config.headers['X-Instance-Id'] = instanceId;

  const { language } = i18n;
  if (language) {
    config.headers['Accept-Language'] = language;
  }

  const timezone = getUserCurrentTimezone();
  if (timezone) {
    config.headers['X-Timezone'] = timezone;
  }

  if (
    config.url &&
    (config.url.indexOf('http://') === 0 ||
      config.url.indexOf('https://') === 0)
  ) {
    return config;
  }

  const hasKey = localStorage.getItem('@Caren:token') !== null;
  if (hasKey) {
    const { token } = JSON.parse(localStorage.getItem('@Caren:token') ?? '');

    const tokenToSend = token.token;

    config.headers['X-CSRF-TOKEN'] = tokenToSend;
  }

  return config;
});

// window.postMessage(
//   { type: 'refreshSession', data: { token: csrfToken } },
//   window.location.origin
// );

api.interceptors.response.use(
  successResponse => successResponse,
  async errorResponse => {
    const { response, config } = errorResponse;

    if (
      response?.status === 401 &&
      config.refreshAttempt == null &&
      !document.hidden &&
      localStorage.getItem('@Caren:token') !== null
    ) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = fetchRefreshToken(
          (config.refreshAttempt ?? 0) + 1
        )
          .then(async data => {
            refreshTokenPromise = null;
            return await data;
          })
          .catch(_e => {
            refreshTokenPromise = null;
          });
      }

      return await refreshTokenPromise.then(async token => {
        if (!token) {
          throw errorResponse;
        }

        const newConfig = { ...errorResponse.config };
        if (newConfig.headers) newConfig.headers['X-CSRF-Token'] = token;

        return await api.request(newConfig);
      });
    }

    if (response?.status !== 401) {
      errorResponse.message = `${errorResponse?.config?.url} - ${errorResponse?.message}`;
      captureException(errorResponse);
    }

    if (response?.status === 403 && response?.data === 'Forbidden') {
      // Unrecoverable CSRF error

      window.postMessage(
        { type: 'clearSession', data: {} },
        window.location.origin
      );
    }

    return await Promise.reject(errorResponse);
  }
);

export default api;
