import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import {
//   AssignmentTurnedIn,
//   AssignmentLate,
//   AccessTime
// } from '@mui/icons-material';
import Header from '../../components/Header';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import DoctorPanel from '../../components/DoctorPanel';
import SecretaryPanel from '../../components/SecretaryPanel';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_SECRETARY } from '../../routes/constants';
import { type Doctor } from '../../v2/entities/doctor';
import SelectDoctors from '../../v2/components/secretary/SelectDoctors';
import PersonImage from '../../v2/components/data/personImage';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
import ScheduleTable from '../../v2/components/schedule/table';

export default function Schedule() {
  const { credentials, refreshSession } = useAuth();

  const isSecretary = credentials?.token?.user_type === ROLE_SECRETARY;

  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [keyToResend, setKeyToResend] = useState<string | null>(null);

  useEffect(() => {
    if (credentials?.token) {
      refreshSession(credentials.token);
    }
  }, []);

  return (
    <>
      <Helmet title="Agenda - Caren"></Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <div className="flex flex-col items-center gap-16 px-6 py-12 xl:flex-row xl:items-start xl:px-10">
        <div className="w-full max-w-xs shrink-0 xl:w-64 xl:max-w-none">
          {isSecretary ? (
            <SecretaryPanel
              onResendClick={key => {
                setKeyToResend(key);
              }}
            />
          ) : (
            <DoctorPanel />
          )}
        </div>

        {isSecretary && selectedDoctor === null ? (
          <div className="w-full flex-grow">
            <SelectDoctors
              onSelectDoctor={doctor => {
                setSelectedDoctor(doctor);
              }}
            />
          </div>
        ) : (
          <div className="flex w-full flex-grow flex-col items-stretch">
            {isSecretary && selectedDoctor && (
              <button
                type="button"
                onClick={() => {
                  setSelectedDoctor(null);
                }}
                className="mb-10 flex w-full cursor-pointer flex-col items-center gap-y-4 rounded-md bg-white p-4 font-nunito md:w-fit"
              >
                <div className="flex items-center gap-x-2 md:w-full md:self-start">
                  <div className="h-12 w-12">
                    <PersonImage
                      name={selectedDoctor.name}
                      image={selectedDoctor.avatar_url}
                    />
                  </div>
                  <p className="text-base font-semibold text-blue-650">
                    {selectedDoctor.name}
                  </p>
                </div>
                <span className="text-xs text-gray-250">
                  Clique aqui para trocar de profissional
                </span>
              </button>
            )}
            <ScheduleTable
              selectedDoctor={selectedDoctor}
              keyToResend={keyToResend}
            />
          </div>
        )}
      </div>

      <Tawk />
      <Chatwoot />
    </>
  );
}
