import styled from 'styled-components/macro';

export const SecretaryHome = styled.section`
  width: 100%;
  height: 100%;
  padding: 30px;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 20px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    row-gap: 20px;

    @media (max-width: 1024px) {
      padding-left: 0;
    }
  }
`;

export const ReturnButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #262c2e;
  cursor: pointer;
  .icon-container {
    color: var(--caren-darkred);
    margin-right: 10px;
    font-size: 26px;
  }
`;

export const Title = styled.p`
  color: var(--caren-icongrey);
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  margin-top: 30px;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
`;

export const DocListBox = styled.div`
  display: flex;
  align-items: center;
`;

export const StatisticsBox = styled.div`
  display: flex;
  align-items: start;
`;
