import React, { useRef, useEffect, useState /* , useCallback */ } from 'react';
// import {
//   AssignmentTurnedIn,
//   AssignmentLate,
//   AccessTime
// } from '@mui/icons-material';
// import moment from 'moment';
// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
// import { useToast } from '../../contexts/ToastContext';
// import ImageOrName from '../../components/ImageOrName';
import Header from '../../components/Header';
import useCurrentDatetime from '../../hooks/currentDatetime.hook';
// import {
//   doctorCancelConsultationService,
//   doctorDeleteInvitationService,
//   doctorGetSchedule,
//   doctorResendInvitationService
// } from '../../services/doctors.service';
// import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
// import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
// import { ReactComponent as SmsIcon } from '../../assets/icons/sms-tracking.svg';
// import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import {
  Container,
  FirstGrid,
  SecondGrid,
  WelcomeTitle,
  WelcomeSubtitle
  // SearchContainer,
  // SearchInputContainer,
  // SearchInputIcon,
  // SearchInput,
  // Wrapper,
  // WrapperLoading,
  // Consults,
  // SectionTitle,
  // ConsultsTable
} from './doctor-clinic.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import DoctorPanel from '../../components/DoctorPanel';
// import { FORMAT } from '../../utils/moment/momentFormat';
// import { momentUtcLocal } from '../../utils/moment/momentHelpers';
// import Modal from '../../components/Modal';
// import AnamneseModal from '../../components/Modal/AnamneseModal';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
// import {
//   type Consult /*, type Teleconsultation*/
// } from '../../v2/entities/consult';
// import SuccessStep from '../../v2/dialogs/consult/steps/success';
import FirstAccessDialog, {
  type FirstAccessDialogHandles
} from '../../v2/dialogs/intro/firstAccess';
import DoctorStatsCounter from '../../components/DoctorStatsCounter';
// import { Table } from '../../v2/components/table';
// import IconButton from '../../v2/components/inputs/buttons/IconButton';
import StartFirstConsultDialog, {
  type StartFirstConsultDialogHandles
} from '../../v2/dialogs/intro/StartFirstConsult';
import { getProfileCompleteness } from '../../v2/services/user';
import CreateConsultDialog, {
  type CreateConsultDialogHandles
} from '../../v2/dialogs/consult/create';
import { getStatsCount } from '../../v2/services/doctor';
import ResendConfirmationEmailDialog, {
  type ResendConfirmationEmailDialogHandles
} from '../../v2/dialogs/emailConfirmation/resend';
import { useAuth } from '../../hooks/AuthContext';
// import { AxiosError } from 'axios';
import ExceededAppointmentsDialog, {
  type ExceededAppointmentsDialogHandles
} from '../../v2/dialogs/upgrade/exceededAppointments';
// import { useFreePlan } from '../../v2/hooks/plan/useFreePlan';
import ScheduleTable from '../../v2/components/schedule/table';

export default function Schedule() {
  const currentHourState = useCurrentDatetime();
  const { t } = useTranslation(['schedule']);
  // const toast = useToast();
  const firstAccessDialogRef = useRef<FirstAccessDialogHandles>(null);
  const startFirstConsultDialogRef =
    useRef<StartFirstConsultDialogHandles>(null);
  const createConsultDialogRef = useRef<CreateConsultDialogHandles>(null);
  const { credentials } = useAuth();
  const session = credentials?.session;
  // const { data: freePlan } = useFreePlan();

  // const searchInputElement = useRef<HTMLInputElement>(null);
  // const [loading, setLoading] = useState(true);
  // const [loadedPatients, setLoadedPatients] = useState(false);
  const [panelReloadTime, setPanelReloadTime] = useState(new Date().getTime());
  // const [search, setSearch] = useState<string | null>(null);
  // const [patients, setPatients] = useState<{
  //   today: Consult[];
  //   tomorrow: Consult[];
  // }>({
  //   today: [],
  //   tomorrow: []
  // });

  // const [openAnamneseModal, setOpenAnamneseModal] = useState(false);
  // const [selectedAnamnese, setSelectedAnamnese] = useState<Consult | null>(
  //   null
  // );

  // const [openConfirmation, setOpenConfirmation] = useState(false);
  // const [invitationLink, setInvitationLink] = useState(null);
  // const [openUnreversible, setOpenUnreversible] = useState(false);
  // const [unreversibleType, setUnreversibleType] = useState('');
  // const [selectedInvitation, setSelectedInvitation] = useState<Consult | null>(
  //   null
  // );
  const emailConfirmed =
    Boolean(session?.email_confirmed_at) ||
    (session?.insurance_partners && session?.insurance_partners.length > 0);
  const resendConfirmationEmailDialogRef =
    useRef<ResendConfirmationEmailDialogHandles>(null);
  const exceededAppointmentsDialogRef =
    useRef<ExceededAppointmentsDialogHandles>(null);

  const TITLE = 'Consultas - Caren';

  useEffect(() => {
    if (localStorage.getItem('@Caren:firstAccess') === 'true') {
      firstAccessDialogRef.current?.open();
    }

    // const element = searchInputElement?.current;

    // if (element) {
    //   element.value = '';
    //   setSearch(null);
    // }
  }, []);

  useEffect(() => {
    if (
      !emailConfirmed &&
      localStorage.getItem('@Caren:firstAccess') !== 'true' &&
      resendConfirmationEmailDialogRef.current
    ) {
      resendConfirmationEmailDialogRef.current.open();
    }
  }, [emailConfirmed, resendConfirmationEmailDialogRef]);

  // const isConfirmed = (item: Consult) =>
  //   item.invitation.accepted_at !== null && item.teleconsultation !== null;

  // function handleSearchPatient(e: React.ChangeEvent<HTMLInputElement>) {
  //   const { value } = e.target;
  //   const searchValue = value.trim();

  //   if (searchValue.length > 2) {
  //     setSearch(value);
  //   } else {
  //     setSearch(null);
  //   }

  //   setLoadedPatients(false);
  // }

  // function formatHour(dateTime: string) {
  //   const today = moment().local();
  //   const selected = momentUtcLocal(dateTime);

  //   const time = momentUtcLocal(dateTime).format(FORMAT['hour-and-minute']);
  //   const date = momentUtcLocal(dateTime).format(FORMAT['pt-date']);

  //   if (selected.isSame(today, 'day')) return `${time}`;

  //   return `${date}`;
  // }

  // function renderStatus(teleconsultation: null | Teleconsultation) {
  //   if (teleconsultation) {
  //     if (teleconsultation.flow_concluded) {
  //       if (teleconsultation.open && teleconsultation.room_opened_at) {
  //         return <p className="progress">Em progresso</p>;
  //       }
  //       return <p className="check">Confirmado</p>;
  //     }
  //     return (
  //       <p className="check">
  //         Confirmado
  //         <span>O paciente precisa preencher a anamnese</span>
  //       </p>
  //     );
  //   }

  //   return (
  //     <p className="warning">
  //       Pendente <span>O paciente precisa confirmar a consulta</span>
  //     </p>
  //   );
  // }

  // function renderAnamnese(consultation: Consult) {
  //   const actions = [];

  //   if (consultation.teleconsultation) {
  //     const { teleconsultation } = consultation;

  //     if (teleconsultation.flow_concluded) {
  //       actions.push(
  //         <button
  //           type="button"
  //           title="Ler anamnese"
  //           aria-label="Ler anamnese"
  //           className="check"
  //           onClick={() => {
  //             handleAnamneseModal(consultation);
  //           }}
  //         >
  //           <AssignmentTurnedIn />
  //         </button>
  //       );
  //     } else {
  //       actions.push(
  //         <button
  //           type="button"
  //           title="Copiar link para preenchimento da anamnese"
  //           aria-label="Copiar link para preenchimento da anamnese"
  //           onClick={() => {
  //             handleCopyLinkToAnamnesis(consultation);
  //           }}
  //         >
  //           <AssignmentLate />
  //         </button>
  //       );
  //     }
  //   } else {
  //     actions.push(
  //       <div className="anamnese">
  //         <AccessTime />
  //       </div>
  //     );
  //   }

  //   return actions;
  // }

  // const getPatients = useCallback(
  //   async (query: Record<string, unknown>) =>
  //     await new Promise<Consult[]>((resolve, reject) => {
  //       doctorGetSchedule(query)
  //         .then(({ data }) => {
  //           resolve(data);
  //         })
  //         .catch(() => {
  //           toast(t('Ops, não foi possível carregar os pacientes...'), {
  //             variant: 'error'
  //           });
  //           reject(Error('Cannot get patients'));
  //         });
  //     }),
  //   [t, toast]
  // );

  // useEffect(() => {
  //   if (loadedPatients) return;

  //   const queryToday: Record<string, unknown> = { limit: 10 };
  //   const queryTomorrow: Record<string, unknown> = { limit: 10 };

  //   if (search) {
  //     queryToday.search = search;
  //     queryTomorrow.search = search;
  //   }

  //   const todayFrom = moment().startOf('day').utc().toISOString();
  //   const todayTo = moment().endOf('day').utc().toISOString();
  //   queryToday.from = todayFrom;
  //   queryToday.to = todayTo;

  //   const tomorrowFrom = moment()
  //     .add(1, 'days')
  //     .startOf('day')
  //     .utc()
  //     .toISOString();
  //   const tomorrowTo = moment()
  //     .add(1, 'days')
  //     .endOf('day')
  //     .add('1', 'month')
  //     .utc()
  //     .toISOString();
  //   queryTomorrow.from = tomorrowFrom;
  //   queryTomorrow.to = tomorrowTo;

  //   setLoading(true);

  //   Promise.all([getPatients(queryToday), getPatients(queryTomorrow)]).then(
  //     response => {
  //       const patientsTodayData = response[0];
  //       const patientsTomorrowData = response[1];
  //       setPatients({
  //         today: patientsTodayData,
  //         tomorrow: patientsTomorrowData
  //       });
  //       setLoadedPatients(true);
  //     }
  //   );

  //   setLoading(false);
  // }, [getPatients, loadedPatients, search]);

  // function handleResendClick(schedule: Consult) {
  //   setUnreversibleType('resend');
  //   setSelectedInvitation(schedule);
  //   setOpenUnreversible(true);
  // }

  /*   async function handleCopyLinkToAnamnesis(schedule: Consult) {
    if (!schedule) {
      toast(t('Ops, houve um erro ao gerar novo o novo link...'), {
        variant: 'error'
      });

      return;
    }

    const { link } = await doctorResendInvitationService(
      schedule.invitation.key,
      null,
      false
    );

    navigator.clipboard.writeText(link);
    toast(
      t(
        'Link para preenchimento da anamnese copiado! Favor enviar ao paciente.'
      ),
      {
        variant: 'success'
      }
    );
  }

  async function handleResendInvitation() {
    if (!selectedInvitation) {
      toast(t('Ops, houve um erro no reenvio do convite...'), {
        variant: 'error'
      });

      return;
    }

    try {
      const { link } = await doctorResendInvitationService(
        selectedInvitation.invitation.key
      );
      setInvitationLink(link);

      toast(t('Convite reenviado com sucesso.'), {
        variant: 'success'
      });
      setOpenConfirmation(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;

        const exceededMessage = 'You have exceeded the free trial limit';
        const responseMessage = response?.data?.error?.message;

        if (response?.status === 403 && responseMessage === exceededMessage) {
          exceededAppointmentsDialogRef.current?.open();
          return;
        }
      }
      toast(t('Ops, houve um erro no reenvio do convite...'), {
        variant: 'error'
      });
    }
  }

  async function handleDeleteSchedule() {
    if (!selectedInvitation) return;

    try {
      await doctorDeleteInvitationService(selectedInvitation.invitation.key);

      toast(t('Agendamento deletado com sucesso.'), {
        variant: 'success'
      });
      setLoadedPatients(false);
    } catch (error) {
      toast(t('Ops, houve um erro na exclusão...'), {
        variant: 'error'
      });
    }
  }

  async function handleCancelSchedule() {
    if (!selectedInvitation) return;

    try {
      await doctorCancelConsultationService(
        selectedInvitation.teleconsultation?.key
      );

      toast(t('Consulta cancelada com sucesso.'), {
        variant: 'success'
      });
      setLoadedPatients(false);
    } catch (error) {
      toast(t('Ops, houve um erro no cancelamento...'), {
        variant: 'error'
      });
    }
  }

  function handleConfirmation(status: boolean) {
    if (status) {
      doUnreversibleAction();
    }

    setOpenUnreversible(false);
  }

  function doUnreversibleAction() {
    if (unreversibleType === 'resend') {
      handleResendInvitation();
    } else if (unreversibleType === 'cancelConsultation') {
      handleCancelSchedule();
    } else if (unreversibleType === 'deleteInvite') {
      handleDeleteSchedule();
    }
  }

  function decideUnreversibleTitle() {
    if (unreversibleType === 'deleteInvite') {
      return t(
        'Você tem certeza de que deseja cancelar esta teleconsulta?\n\nIsto não pode ser desfeito.'
      );
    }
    if (unreversibleType === 'cancelConsultation') {
      return t('Você tem certeza de que deseja cancelar esse convite?');
    }
    if (unreversibleType === 'resend') {
      return t('Você tem certeza de que deseja reenviar esse convite?');
    }

    return null;
  } */

  // function handleToggleConfirmationModal() {
  //   setOpenConfirmation(!openConfirmation);
  // }

  // function handleAnamneseModal(consultation: Consult) {
  //   setSelectedAnamnese(consultation);
  //   setOpenAnamneseModal(true);
  // }

  // function consultationPatientLink(consultation: Consult) {
  //   if (!consultation.patient) {
  //     return '#';
  //   }

  //   return `/pacientes/${consultation.patient?.key}`;
  // }

  // const validateFreeTrialAppointment = (
  //   appointmentInsurancePartner: string | null
  // ) => {
  //   if (appointmentInsurancePartner != null) return true;

  //   if (freePlan) {
  //     return (
  //       credentials?.session?.active_subscription?.plan
  //         ?.enable_private_teleconsultation_invitations === true ||
  //       freePlan?.used < freePlan?.total
  //     );
  //   }

  //   return false;
  // };

  // const renderWaitingRoomIconButton = (consultation: Consult) => {
  //   const valid = validateFreeTrialAppointment(
  //     consultation.invitation.insurance_partner
  //   );

  //   if (valid) {
  //     return (
  //       <IconButton
  //         title="Sala de Espera"
  //         disabled={consultation.teleconsultation === null}
  //         href={`/consultas/${consultation.teleconsultation?.key}/sala-de-espera`}
  //         icon={CameraIcon}
  //         type="primary"
  //       />
  //     );
  //   }

  //   return (
  //     <IconButton
  //       title="Sala de Espera"
  //       disabled={consultation.teleconsultation === null}
  //       onClick={() => {
  //         exceededAppointmentsDialogRef.current?.open();
  //       }}
  //       icon={CameraIcon}
  //       type="primary"
  //     />
  //   );
  // };

  useEffect(() => {
    const verifyHasFirstConsult = async () => {
      const perfilCompleteness = await getProfileCompleteness();
      const consultsStats = await getStatsCount();

      if (
        perfilCompleteness &&
        perfilCompleteness.profile_completeness === 100 &&
        consultsStats
      ) {
        const alreadyMakeFirstConsult =
          Object.entries(consultsStats.counts)
            .map(([_, value]) => value)
            .find(value => value > 0) !== undefined;

        if (!alreadyMakeFirstConsult) {
          startFirstConsultDialogRef.current?.open();
        }
      }
    };

    verifyHasFirstConsult();
  }, []);

  return (
    <>
      <Helmet title={TITLE} />
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel
            reloadTime={panelReloadTime}
            onCreateConsult={() => {
              // setLoadedPatients(false);
              setPanelReloadTime(new Date().getTime());
            }}
          />
        </FirstGrid>

        <SecondGrid>
          <div className="mb-8 flex">
            <div className="flex w-full flex-col justify-between lg:flex-row">
              <div className="mb-5 flex shrink-0 flex-col justify-end lg:mb-0 xl:w-1/4">
                <WelcomeTitle>
                  {t('Bem-vindo(a) ao seu consultório virtual!').toString()}
                </WelcomeTitle>
                <WelcomeSubtitle>{currentHourState}</WelcomeSubtitle>
              </div>
              <DoctorStatsCounter />
            </div>
          </div>

          <ScheduleTable />

          {/*           <SearchContainer>
            <SearchInputContainer>
              <SearchInputIcon />
              <SearchInput
                className="border-none focus:ring-yellow-750"
                placeholder={t('Buscar na agenda').toString()}
                onChange={handleSearchPatient}
                ref={searchInputElement}
              />
            </SearchInputContainer>
          </SearchContainer>

          {loading ? (
            <Consults>
              <Wrapper>
                <WrapperLoading>
                  <span />
                  <span />
                </WrapperLoading>
              </Wrapper>
              <Wrapper>
                <WrapperLoading>
                  <span />
                  <span />
                </WrapperLoading>
              </Wrapper>
            </Consults>
          ) : (
            <Consults>
              <Wrapper>
                <SectionTitle>Consultas de Hoje</SectionTitle>
                {patients.today.length !== 0 ? (
                  <ConsultsTable>
                    <thead>
                      <tr>
                        <th scope="col">Paciente</th>
                        <th scope="col" className="center">
                          Horário
                        </th>
                        <th scope="col" className="center">
                          Status
                        </th>
                        <th scope="col" className="center">
                          Anamnese
                        </th>
                        <th scope="col" className="center">
                          Ações
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {patients.today.map(consultation => (
                        <tr key={consultation.key}>
                          <td>
                            <Link to={consultationPatientLink(consultation)}>
                              <div className="perfil">
                                <ImageOrName
                                  src={consultation?.patient?.avatar_url}
                                  title={
                                    consultation.patient_name ?? 'Paciente'
                                  }
                                  titleSize={16}
                                  backgroundStyle={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: '100%',
                                    backgroundColor:
                                      'var(--caren-image-fallback)'
                                  }}
                                  iconSyle={{
                                    width: 40,
                                    height: 40
                                  }}
                                />
                                <p>
                                  {consultation.patient_name &&
                                  consultation.patient_name !== 'Paciente'
                                    ? consultation.patient_name
                                    : consultation.invitation.email}
                                </p>
                              </div>
                            </Link>
                          </td>
                          <td className="center">
                            <span>Horário</span>
                            <p>{formatHour(consultation.start_datetime)}</p>
                          </td>
                          <td className="center">
                            <span>Status</span>
                            <p>{renderStatus(consultation.teleconsultation)}</p>
                          </td>
                          <td className="center">
                            <span>Anamnese</span>
                            <p>{renderAnamnese(consultation)}</p>
                          </td>
                          <td>
                            <span>Ações</span>
                            <div className="flex w-full items-center justify-center">
                              <Table.Cell.Actions
                                actions={React.Children.toArray([
                                  renderWaitingRoomIconButton(consultation),
                                  <IconButton
                                    title="Reenviar Convite"
                                    onClick={() => {
                                      handleResendClick(consultation);
                                    }}
                                    icon={SmsIcon}
                                  />,
                                  <IconButton
                                    title={
                                      isConfirmed(consultation)
                                        ? 'Cancelar Consulta'
                                        : 'Deletar Convite'
                                    }
                                    onClick={() => {
                                      setUnreversibleType(
                                        isConfirmed(consultation)
                                          ? 'cancelConsultation'
                                          : 'deleteInvite'
                                      );
                                      setSelectedInvitation(consultation);
                                      setOpenUnreversible(true);
                                    }}
                                    icon={TrashIcon}
                                  />
                                ])}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ConsultsTable>
                ) : (
                  <span>Nenhum paciente encontrado para hoje.</span>
                )}
              </Wrapper>
              <Wrapper>
                <SectionTitle color="--caren-grey">
                  Próximas Consultas
                </SectionTitle>
                {patients.tomorrow.length !== 0 ? (
                  <ConsultsTable>
                    <thead>
                      <tr>
                        <th scope="col">Paciente</th>
                        <th scope="col" className="center">
                          Data
                        </th>
                        <th scope="col" className="center">
                          Status
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {patients.tomorrow.map(consultation => (
                        <tr key={consultation.key}>
                          <td>
                            <p>
                              {consultation.patient_name &&
                              consultation.patient_name !== 'Paciente'
                                ? consultation.patient_name
                                : consultation.invitation.email}
                            </p>
                          </td>

                          <td className="center">
                            <span>Data</span>
                            <p>{formatHour(consultation.start_datetime)}</p>
                          </td>

                          <td className="center">
                            <span>Status</span>
                            <p>{renderStatus(consultation.teleconsultation)}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ConsultsTable>
                ) : (
                  <span>Nenhum paciente encontrado para os próximos dias.</span>
                )}
              </Wrapper>
            </Consults>
          )} */}
        </SecondGrid>

        {/* <UnreversibleConfirmationModal
          title={decideUnreversibleTitle()}
          openModal={openUnreversible}
          setOpenModal={setOpenUnreversible}
          handleConfirmation={handleConfirmation}
          cancelButtonText="Não"
          confirmButtonText="Sim"
        /> */}
      </Container>

      {/* <AnamneseModal
        openAnamneseModal={openAnamneseModal}
        setOpenAnamneseModal={setOpenAnamneseModal}
        consultation={selectedAnamnese}
      /> */}

      {/* <Modal
        handleOpenModal={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        openModal={openConfirmation}
        modalTitle={t('Convite Reenviado!')}
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside
      >
        <SuccessStep
          invitationLink={invitationLink ?? ''}
          onClose={handleToggleConfirmationModal}
        />
      </Modal> */}
      <FirstAccessDialog ref={firstAccessDialogRef} />
      <StartFirstConsultDialog
        ref={startFirstConsultDialogRef}
        onClose={() => {
          createConsultDialogRef.current?.open();
        }}
      />
      <CreateConsultDialog
        ref={createConsultDialogRef}
        onSuccess={() => {
          // setLoadedPatients(false);
          setPanelReloadTime(new Date().getTime());
        }}
      />
      <ResendConfirmationEmailDialog ref={resendConfirmationEmailDialogRef} />
      <ExceededAppointmentsDialog ref={exceededAppointmentsDialogRef} />
      <Tawk />
      <Chatwoot />
    </>
  );
}
