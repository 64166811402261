import { type PatientCreate } from '../v2/entities/patient';
import api from './api';

export const patientSignup = async (params: PatientCreate) =>
  await api.post('/patients/signup', { ...params });

export const patientResumeSignup = async (login: string) => {
  const params: { login: string } = { login };

  return await api.post('/patients/resume-signup', { ...params });
};

export const getPatientEmail = async (cpf: string, employee?: string) => {
  const params: {
    employee_key?: string
  } = {};

  if (employee) {
    params.employee_key = employee;
  }

  const { data } = await api.get(
    `/teleconsultations/invitations/fiscal-code/${cpf}/email`,
    {
      params
    }
  );

  return data;
};

export const getPatientList = async () => {
  const { data } = await api.get('/employees/teleconsultations/patients', {});

  return data
};
