import styled from 'styled-components/macro';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const FirstGrid = styled.div`
  background-color: #f6f7f8;
  padding: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SecondGrid = styled.div`
  padding: 20px;
`;

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubgridTitle = styled.div`
  min-height: 32px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor && 'var(--caren-subheader)'};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 5px;
  margin-bottom: 15px;
  p {
    color: var(--caren-white);
    font-size: 12px;
  }
`;

export const ResponseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;

  text-align: center;

  p {
    font-size: 28px;
    color: var(--caren-icongrey);
  }
`;

export const SubHeaderContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AddButtonContainer = styled.div`
  text-align: right;
`;

export const FormContainer = styled.form`
  input {
    background-color: var(--caren-white);
    border: 1px solid var(--caren-icongrey);
    border-radius: 5px;
    padding-left: 5px;
    height: 35px;
    margin: 10px 0;
    width: 50%;
    font-size: 16px;
    margin-right: auto;
  }

  label {
    color: var(--caren-icongrey);
    margin: 10px 0;
    width: 30%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Input = styled.input`
  padding-left: 10px;
  margin: 5px 0;
  height: 40px;
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
`;

export const ModalButton = styled.button`
  height: 40px;
  background-color: var(--caren-green);
  color: var(--caren-white);
  font-size: 20px;
  margin-top: 20px;
  border-radius: 10px;
  width: 30%;
`;
