import { useCallback, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import useVideoContext from './useVideoContext';
import useIsTrackEnabled from './useIsTrackEnabled';

export default function useLocalVideoToggle() {
  const {
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    setVideoTrackEnabled,
    onError
  } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  );
  const [isPublishing, setIspublishing] = useState(false);
  const isEnabled = useIsTrackEnabled(videoTrack);

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (isEnabled) {
        const localTrackPublication =
          localParticipant?.unpublishTrack(videoTrack);
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
        setVideoTrackEnabled(false);
      } else {
        setIspublishing(true);
        getLocalVideoTrack()
          .then(track => {
            localParticipant?.publishTrack(track, { priority: 'low' });
            setVideoTrackEnabled(true);
          })
          .catch(onError)
          .finally(() => {
            setIspublishing(false);
          });
      }
    }
  }, [
    isPublishing,
    isEnabled,
    localParticipant,
    videoTrack,
    removeLocalVideoTrack,
    setVideoTrackEnabled,
    getLocalVideoTrack,
    onError
  ]);

  return [isEnabled, toggleVideoEnabled];
}
