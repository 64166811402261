import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
  width: 100%;
`;

export const ModalWrapperSmall = styled.div`
  color: #47525e;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ModalWrapperDescription = styled.div`
  margin-top: 10px;

  > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  p {
    color: black;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;

    a {
      color: #00cdd6;
      font-weight: bold;
      transition: color 0.3s;

      &:hover {
        color: black;
      }
    }
  }
`;
