import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaUserMd, FaSyringe } from 'react-icons/fa';
import { GiPill } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: 'var(--caren-grey)',
    },
    '&:focus': {
      backgroundColor: 'theme.palette.primary.main',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'var(--caren-green)',
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const { t } = useTranslation(['doctor_record']);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{
          width: '100%',
          height: 40,
          backgroundColor: '#fff',
          textAlign: 'left',
          padding: 0,
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '10px',
        }}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color=""
        onClick={handleClick}
      >
        {t('Evento')}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <FaUserMd size={23} />
          </ListItemIcon>
          <ListItemText primary={t('Remoção de Paciente')} />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <GiPill size={23} />
          </ListItemIcon>
          <ListItemText primary={t('Prescrição Médica')} />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <FaSyringe size={23} />
          </ListItemIcon>
          <ListItemText primary={t('Medicamentação')} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
