import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route as ReactDomRoute, Redirect } from 'react-router-dom';
import { useToast } from '../contexts/ToastContext';

import { useAuth } from '../hooks/AuthContext';
import { ROLE_GUEST, ROLE_PROFESSIONAL, ROLE_SECRETARY } from './constants';
import { Container, WrapperLoading } from './styles';

const Route = ({ isPrivate = false, render: Component, userRole, ...rest }) => {
  const { credentials, signOut } = useAuth();
  const { t } = useTranslation(['common']);
  const toast = useToast();

  function ValidComponent() {
    const { location } = rest;

    if (credentials?.session?.cached) {
      return (
        <Container>
          <WrapperLoading size={160}>
            <span />
            <span />
          </WrapperLoading>
        </Container>
      );
    }

    if (
      !userRole?.includes(ROLE_GUEST) &&
      !userRole?.includes(credentials?.token?.user_type)
    ) {
      if (credentials === null) {
        toast(t('Logout efetuado com sucesso! Até breve.'), {
          variant: 'success',
        });
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { noRedirect: true },
            }}
          />
        );
      }

      if (!credentials?.token) {
        toast(t('Para acessar esta área, por favor confirme o seu login.'), {
          variant: 'info',
        });
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: location, noRedirect: true },
            }}
          />
        );
      }

      if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
        return <Redirect to="/consultorio" />;
      }

      if (credentials?.token?.user_type === ROLE_SECRETARY) {
        return <Redirect to="/clinic" />;
      }

      if (credentials?.token?.session_type === 'limited') {
        signOut();
      }

      return <Redirect to="/consultas" />;
    }

    if (isPrivate && !credentials?.token) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { referrer: location, noRedirect: true },
          }}
        />
      );
    }

    return <Component {...rest} />;
  }

  return <ReactDomRoute render={ValidComponent} />;
};

export default Route;
