import React, { useEffect } from 'react';

import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  CloseButton,
  ModalInfoContainer,
  ModalInfo,
  ModalInfoTitle
} from './styles';

export default function Modal({
  openModal = true,
  flexTitle = false,
  handleOpenModal,
  modalTitle,
  children,
  modalWidth,
  modalTitleColor,
  containerStyles = {},
  noClosing = false,
  noClosingOutside = false,
  setModalmsg = () => {}
}) {
  useEffect(() => {
    function handleKeyPress(event) {
      if (!noClosing && (event.key === 'Escape' || event.key === 'Esc')) {
        handleOpenModal();
        if (setModalmsg) {
          setModalmsg('');
        }
      }
    }

    if (openModal) {
      document.onkeydown = e => handleKeyPress(e);
    }
  }, [handleOpenModal, openModal]);

  const stopPropagation = e => e.stopPropagation();

  const closeModal = () => {
    if (!openModal) return;
    handleOpenModal();
    if (setModalmsg) {
      setModalmsg('');
    }
  };

  return (
    <ModalContainer
      openModal={openModal}
      onClick={() => (!noClosing && !noClosingOutside ? closeModal() : '')}
    >
      <ModalContent modalWidth={modalWidth} onClick={stopPropagation}>
        <ModalHeader>
          {noClosing ? (
            ''
          ) : (
            <CloseButton onClick={closeModal}>&times;</CloseButton>
          )}
        </ModalHeader>
        <ModalInfoContainer>
          <ModalInfo style={{ ...containerStyles }}>
            <ModalInfoTitle
              flexTitle={flexTitle}
              modalTitleColor={modalTitleColor}
            >
              {modalTitle}
            </ModalInfoTitle>
            {children}
          </ModalInfo>
        </ModalInfoContainer>
      </ModalContent>
    </ModalContainer>
  );
}
