import api from './api';

export const changeUserPasswordService = async (
  currentPassword,
  newPassword
) => {
  const response = await api.put('/users/password', {
    current_password: currentPassword,
    new_password: newPassword
  });

  const { data, status } = response;

  if (status !== 200) {
    throw Error('Error in change password request');
  }

  return data;
};

export const changeUserPictureService = async image => {
  const form = new FormData();
  form.append('file', image);

  const { data } = await api.post('/users/picture', form);

  return data;
};
