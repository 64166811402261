import styled from 'styled-components/macro';

export const MainTour = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 5000;
`;

export const TourItem = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  z-index: 1;

  &.open {
    opacity: 1;
    pointer-events: initial;
    z-index: 2;
  }
`;

export const TourItemContent = styled.div`
  position: absolute;
`;

export const TourItemContentBG = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    background-color: black;
    position: absolute;
    opacity: 0.3;
  }

  &.one {
    &::before {
      top: 0;
      right: 100%;
      width: 200vw;
      height: 100%;
    }

    &::after {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%);
      width: 200vw;
      height: 200vh;
    }
  }

  &.two {
    &::before {
      top: 0;
      left: 100%;
      width: 200vw;
      height: 100%;
    }

    &::after {
      top: 100%;
      left: 50%;
      transform: translate(-50%);
      width: 200vw;
      height: 200vh;
    }
  }
`;

export const TourItemContentBlock = styled.div`
  background-color: white;
  position: absolute;
  padding: 30px 35px 20px;
  width: 350px;
  border-radius: 8px;
  z-index: 2;

  &::before {
    content: '';
    background-color: white;
    position: absolute;
    z-index: 1;
  }

  &.top {
    bottom: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);

    &::before {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 18px;
      height: 10px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
    }
  }

  &.right {
    top: 50%;
    left: calc(100% + 20px);
    transform: translateY(-50%);

    &::before {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      width: 10px;
      height: 18px;
      clip-path: polygon(100% 0, 100% 100%, 0 50%);
    }
  }

  &.bottom {
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);

    &::before {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 18px;
      height: 10px;
      clip-path: polygon(50% 0, 100% 100%, 0 100%);
    }
  }

  &.left {
    top: 50%;
    right: calc(100% + 20px);
    transform: translateY(-50%);

    &::before {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      width: 10px;
      height: 18px;
      clip-path: polygon(0 0, 100% 50%, 0 100%);
    }
  }

  &.top-left {
    bottom: calc(100% + 15px);
    right: calc(100% + 15px);

    &::before {
      top: calc(100% - 5px);
      left: calc(100% - 5px);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      clip-path: polygon(0 0, 100% 50%, 0 100%);
    }
  }

  &.top-right {
    bottom: calc(100% + 15px);
    left: calc(100% + 15px);

    &::before {
      top: calc(100% - 5px);
      right: calc(100% - 5px);
      transform: rotate(-45deg);
      width: 10px;
      height: 10px;
      clip-path: polygon(100% 0, 100% 100%, 0 50%);
    }
  }

  &.bottom-left {
    top: calc(100% + 15px);
    right: calc(100% + 15px);

    &::before {
      bottom: calc(100% - 5px);
      left: calc(100% - 5px);
      transform: rotate(135deg);
      width: 10px;
      height: 10px;
      clip-path: polygon(100% 0, 100% 100%, 0 50%);
    }
  }

  &.bottom-right {
    top: calc(100% + 15px);
    left: calc(100% + 15px);

    &::before {
      bottom: calc(100% - 5px);
      right: calc(100% - 5px);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      clip-path: polygon(100% 0, 100% 100%, 0 50%);
    }
  }
`;

export const TourItemContentBlockClose = styled.button`
  background-color: var(--caren-greenblue);
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  border-radius: 8px;

  svg path {
    fill: white;
    transition-property: fill;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  }

  &:hover {
    background-color: #ff9052;

    svg path {
      fill: white;
    }
  }
`;

export const TourItemContentBlockCount = styled.div`
  color: var(--caren-greenblue);
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
`;

export const TourItemContentBlockDescription = styled.div`
  p {
    color: black;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    white-space: pre-wrap;
  }
`;

export const TourItemContentBlockActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const TourItemContentBlockAction = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }

  button {
    height: 40px;
    padding: 0 20px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

    span {
      color: white;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
    }

    &:hover {
      background-color: #53d3b0 !important;
    }
  }

  &:first-child button {
    background-color: #00cdd6;
  }

  &:last-child button {
    background-color: #ff9052;
  }
`;
