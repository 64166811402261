import React, { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useToast } from '../../../contexts/ToastContext';
import { getPatientBadges } from '../../../services/badges';
import { FORMAT } from '../../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../../utils/moment/momentHelpers';
import ImageOrName from '../../ImageOrName';
import {
  PatientInfoContent,
  PatientInfoPicture,
  PatientListing,
  PatientListingColumn,
  PatientListingColumnTitle,
  PatientListingItems,
  PatientListingItem,
  PatientProfileInfo
} from './styles';
import type { Badge } from '../../../v2/entities/badges';

type PatientInfoProps = {
  teleconsultationKey?: string;
  patient: {
    name: string;
    avatar_url?: string | null;
  };
  scheduledDatetime?: string;
};

export default function PatientInfo({
  patient,
  teleconsultationKey,
  scheduledDatetime
}: PatientInfoProps) {
  const [badges, setBadges] = useState<{
    allergy?: Badge[];
    comorbidities?: Badge[];
  } | null>(null);
  const toast = useToast();

  useEffect(() => {
    const fetchBadges = async (key: string) => {
      try {
        const data = await getPatientBadges(key);
        setBadges(data);
      } catch (error) {
        toast(
          'Não foi possível carregar as alergias e comorbidades do paciente.',
          {
            variant: 'error'
          }
        );
      }
    };

    if (teleconsultationKey) {
      fetchBadges(teleconsultationKey);
    }
  }, [teleconsultationKey]);

  const formatText = (entry: Badge) => {
    let description = `${entry?.name} ${
      entry?.detail ? `(${entry?.detail})` : ''
    }`;

    description = description.replaceAll(' ', ' ');
    description = description.replaceAll('&nbsp;', ' ');

    return description;
  };

  return (
    <>
      <PatientProfileInfo>
        <PatientInfoPicture>
          <ImageOrName
            src={patient.avatar_url}
            title={patient.name}
            titleSize={45}
            backgroundStyle={{
              width: 70,
              height: 70,
              borderRadius: '100%',
              backgroundColor: 'var(--caren-image-fallback)'
            }}
          />
        </PatientInfoPicture>

        <PatientInfoContent>
          <span>{patient.name}</span>
          {scheduledDatetime && (
            <span>
              {momentUtcLocal(scheduledDatetime).format(FORMAT['pt-datetime'])}
            </span>
          )}
        </PatientInfoContent>
      </PatientProfileInfo>

      <PatientListing>
        {badges?.comorbidities && badges.comorbidities.length > 0 && (
          <PatientListingColumn>
            <PatientListingColumnTitle>
              <FiAlertTriangle />
              <span>Comorbidades</span>
            </PatientListingColumnTitle>

            <PatientListingItems>
              {React.Children.toArray(
                badges.comorbidities.map(entry => (
                  <PatientListingItem>• {formatText(entry)}</PatientListingItem>
                ))
              )}
            </PatientListingItems>
          </PatientListingColumn>
        )}
        {badges?.allergy && badges.allergy.length > 0 && (
          <PatientListingColumn>
            <PatientListingColumnTitle>
              <FiAlertTriangle />
              <span>Alergia</span>
            </PatientListingColumnTitle>

            <PatientListingItems>
              {React.Children.toArray(
                badges.allergy.map(entry => (
                  <PatientListingItem>• {formatText(entry)}</PatientListingItem>
                ))
              )}
            </PatientListingItems>
          </PatientListingColumn>
        )}
      </PatientListing>
    </>
  );
}
