import { useContext } from 'react';
import { PatientSignupContext } from '../../pages/PatientSignup/context';

export default function usePatientSignupForm() {
  const context = useContext(PatientSignupContext);

  if (!context)
    throw Error(
      'PatientSignupContext should be used within PatientSignupContextProvider'
    );

  return context;
}
