import styled from 'styled-components/macro';

export const CardContainer = styled.div`
  position: relative;
  width: 43%;
  margin-right: 25px;
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--caren-white);
  box-shadow: 3px 3px 10px 1px #e2e2e2;
`;

export const CardTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: var(--caren-grey);
`;

export const CardWeekContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const CardWeekText = styled.p`
  margin-top: 25px;
`;

export const CardWeekDay = styled.div<{ isSelected: boolean }>`
  margin: 0 5px;
  min-width: 40px;
  min-height: 40px;
  background-color: ${({ isSelected }) =>
    isSelected ? 'var(--caren-lightblue)' : '#eee'};
  color: #999;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--caren-lightblue);
    font-weight: bold;
    color: white;
  }
`;

export const CardDatepickerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;

export const CardIntervalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    position: relative;
    top: 8px;
    left: 5px;
  }
`;

export const CardDatepickerContainerDay = styled.div``;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  color: var(--caren-lightgrey);
  font-size: 25px;
  font-weight: bold;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
    color: var(--caren-grey);
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const AddTime = styled.span`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;
