import axios from 'axios';
import api from './api';

const apiPagarme = axios.create({
  baseURL: import.meta.env.VITE_PAGARME_API_URL
});

const getTokenizePaymentCard = async (data: {
  number: string
  name: string
  cpf: string
  exp_month: number
  exp_year: number
  cvv: string
}) => {
  const result = await apiPagarme.post(
    '/tokens',
    {
      type: 'card',
      card: {
        number: data.number,
        holder_name: data.name,
        holder_document: data.cpf,
        exp_month: data.exp_month,
        exp_year: data.exp_year,
        cvv: data.cvv
      }
    },
    {
      params: {
        appId: import.meta.env.VITE_PAGARME_APP_ID
      }
    }
  );

  return result.data.id as string;
};

const createPlanSubscribe = async (data: {
  plan_key: string
  card_token: string
  discount_code: string | null
  billing_address: {
    line_1: string
    line_2: string
    zip_code: string
    city: string
    state: string
    country: string
  }
}) => {
  const result = await api.post('/subscriptions', data);

  return result.data;
};

const updateSubscriptionCard = async (
  subscription: string,
  data: {
    card_token: string
    billing_address: {
      line_1: string
      line_2: string
      zip_code: string
      city: string
      state: string
      country: string
    }
  }
) => {
  const result = await api.put(`/subscriptions/${subscription}/card`, data);

  return result.data;
};

export { getTokenizePaymentCard, createPlanSubscribe, updateSubscriptionCard };
