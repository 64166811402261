import React from 'react';

// import { IoIosArrowRoundForward } from 'react-icons/io';
// import { Link } from 'react-router-dom';
import {
  Container,
  /* ArrowContainer, */ TitleContainer,
} from './white-card.styles';

export default function WhiteCard({ children, title, icon }) {
  return (
    <Container>
      <TitleContainer>
        <p>{title}</p>
        {icon}
      </TitleContainer>
      {children}
      {/* <Link to={link}>
        <ArrowContainer>
          <IoIosArrowRoundForward size={27} color="var(--caren-greenblue)" />
        </ArrowContainer>
      </Link> */}
    </Container>
  );
}
