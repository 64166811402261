const INITIAL_STATE = { sidebarStatus: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'OPEN':
      return true;
    case 'CLOSE':
      return false;

    default:
      return state;
  }
};
