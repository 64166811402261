import React, { type ElementType, type InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TextInputProps = {
  icon?: ElementType;
  label?: string;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement>

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ icon: Icon, label, error, ...rest }, ref) => {
    return (
      <div className="relative flex w-full flex-col items-start">
        {label && (
          <label className="mb-1 font-nunito text-sm font-semibold text-gray-350">
            {label}
          </label>
        )}

        <div className="relative flex w-full items-center">
          {Icon && (
            <Icon className="pointer-events-none absolute bottom-3 left-4 h-3.5 w-3.5 text-warning" />
          )}
          <input
            ref={ref}
            className={twMerge(
              'h-10 w-full rounded-md px-4 py-3 text-sm text-gray-450 ring-transparent focus:border-yellow-650 focus:ring-yellow-650',
              Icon ? 'pl-10' : '',
              error ? 'border-error bg-error/10' : 'border-blue-650/50 bg-white'
            )}
            {...rest}
          />
        </div>
        {error && (
          <span className="mt-1 font-nunito text-xs text-error">{error}</span>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
export default TextInput;
