import { FaRegCheckCircle } from 'react-icons/fa';
import styled from 'styled-components/macro';

export const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > input {
    background-color: var(--caren-white);
    width: 66%;
    height: 40px;
    border: 1px solid var(--caren-lightgrey);
    border-radius: 4px;
    padding: 0 10px;
    margin-bottom: 4px;

    ::placeholder {
      color: var(--caren-lightgrey);
      font-size: 14px;
    }
  }

  > span {
    color: var(--caren-red);
    width: 100%;
    text-align: center;
  }
`;

export const ConfirmationButton = styled.button`
  padding: 12px 50px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  color: var(--caren-icongrey);
  background-color: var(--caren-green);
  transition: all 300ms ease-in-out;
  margin-top: 30px;

  &:hover {
    background-color: var(--caren-darkgreen);
  }

  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
  }
`;

export const ModalSuccessIcon = styled(FaRegCheckCircle).attrs({
  size: 90,
  color: 'var(--caren-green)'
})`
  margin-bottom: 1em;
`;

export const ModalButton = styled.button`
  background-color: #00cdd6;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  height: 50px;
  margin: 40px auto 0;
  padding: 0 20px;
  border-radius: 10px;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  @media (max-width: 767px) {
    min-width: 100%;
    max-width: 100%;
  }

  > svg {
    display: inline-block;
    margin-right: 14px;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    path {
      fill: white;
    }
  }

  span {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
  }

  &:hover {
    background-color: #53d3b0;

    > svg {
      transform: translateX(5px);
    }
  }
`;
