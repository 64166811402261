import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { Container } from './notifications.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import history from '../../services/history';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PROFESSIONAL } from '../../routes/constants';

export default function Notifications() {
  const { credentials } = useAuth();

  const {
    i18n: { language },
  } = useTranslation(['common']);

  const _iframeElement = useRef(null);
  const TITLE = 'Notificações - Caren';
  const IFRAME_THEME =
    credentials?.token?.user_type === ROLE_PROFESSIONAL ? 'doctor' : 'patient';
  const _IFRAME_SRC = combineURLs(
    import.meta.env.VITE_IFRAME_DOMAIN,
    `/notifications?theme=telemed-${IFRAME_THEME}&lng=${language}`
  );

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header rightAction="back" />
      <Container>
        <Redirect to="/" />
        {/* <iframe
          ref={iframeElement}
          src={IFRAME_SRC}
          title={TITLE}
          style={{ flex: 1, width: '100%', border: 'none' }}
        /> */}
      </Container>
    </>
  );
}
