import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HomeIcon,
  FolderIcon,
  DocumentTextIcon,
  UsersIcon,
  PowerIcon,
  ClipboardIcon,
  UserIcon,
  ClipboardDocumentCheckIcon,
  VideoCameraIcon,
  CalendarDaysIcon
} from '@heroicons/react/20/solid';
import Sidebar from '..';

import {
  SideBarUserName,
  SideBarLogoBox,
  DisplayFlex,
  Border,
  Menu,
  List,
  IconText,
  IconDoctor,
  IconFavorite
} from './usersidebar.styles';
import SidebarBox from '../SidebarBox';
import { useAuth } from '../../../hooks/AuthContext';
import { sidebarClose } from '../../../store/modules/Sidebar/actions';
import { Store } from '../../../store';
import HomeSidebar from '../HomeSidebar';
import ImageOrName from '../../ImageOrName';
import {
  ROLE_PATIENT,
  ROLE_PROFESSIONAL,
  ROLE_SECRETARY
} from '../../../routes/constants';
import {
  enableDoctorSearchInMenu,
  enableFavoriteDoctorsInMenu
} from '../../../constants/featureToggles';

const stylesSideBox = {
  style: { flex: '1', display: 'flex', alignItems: 'flex-end' }
};

export default function UserSidebar() {
  const { t } = useTranslation(['common']);
  const [, dispatch] = useContext(Store);
  const { credentials, signOut } = useAuth();

  function closeSidebar() {
    dispatch(sidebarClose());
  }

  async function handleSignOut() {
    closeSidebar();
    await signOut();
  }

  function UserSidebarMenus() {
    if (credentials && credentials.token.session_type === 'limited')
      return <LimitedUserMenus />;
    if (credentials && credentials.token.user_type === ROLE_PROFESSIONAL)
      return <DoctorMenus />;
    if (credentials && credentials.token.user_type === ROLE_PATIENT)
      return <UserMenus />;
    if (credentials && credentials.token.user_type === ROLE_SECRETARY)
      return <SecretaryMenus />;
  }

  function UserMenus() {
    return (
      <SidebarBox heightArea="60%">
        <Menu>
          {enableDoctorSearchInMenu && (
            <List onClick={closeSidebar} to="/medicos">
              <IconDoctor className="icon" />
              <IconText>{t('Médicos')}</IconText>
            </List>
          )}
          {enableFavoriteDoctorsInMenu && (
            <List
              onClick={closeSidebar}
              to={{
                pathname: '/medicos',
                favorite: true
              }}
            >
              <IconFavorite className="icon" />
              <IconText>{t('Favoritos')}</IconText>
            </List>
          )}
          <List onClick={closeSidebar} to="/consultas">
            <VideoCameraIcon className="icon" />
            <IconText>{t('Consultas')}</IconText>
          </List>
          <List onClick={closeSidebar} to="/perfil">
            <DocumentTextIcon className="icon" />
            <IconText>{t('Meus Dados')}</IconText>
          </List>
        </Menu>
      </SidebarBox>
    );
  }

  function LimitedUserMenus() {
    return <SidebarBox heightArea="60%" />;
  }

  function DoctorMenus() {
    return (
      <SidebarBox heightArea="60%">
        <Menu>
          <List onClick={closeSidebar} to="/consultorio">
            <HomeIcon className="icon" />
            <IconText>{t('Consultório Virtual')}</IconText>
          </List>

          {/* <List
            onClick={closeSidebar}
            to={{
              pathname: '/agenda',
              favorite: true
            }}
          >
            <FolderIcon className="icon" />
            <IconText>{t('Consultas')}</IconText>
          </List> */}

          {/* <List onClick={closeSidebar} to="/calendar">
            <CalendarDaysIcon className="icon" />
            <IconText>{t('Agenda')}</IconText>
          </List> */}

          <List onClick={closeSidebar} to="/pacientes">
            <ClipboardDocumentCheckIcon className="icon" />
            <IconText>{t('Meus Pacientes')}</IconText>
          </List>

          <List onClick={closeSidebar} to="/equipe">
            <UsersIcon className="icon" />
            <IconText>{t('Equipe')}</IconText>
          </List>

          {/* <List onClick={closeSidebar} to="/estatisticas">
            <ChartBarIcon className="icon" />
            <IconText>{t('Estatísticas')}</IconText>
          </List> */}

          <List onClick={closeSidebar} to="/perfil">
            <DocumentTextIcon className="icon" />
            <IconText>{t('Meus Dados')}</IconText>
          </List>
        </Menu>
      </SidebarBox>
    );
  }

  function SecretaryMenus() {
    return (
      <SidebarBox heightArea="60%">
        <Menu>
          <List onClick={closeSidebar} to="/clinic">
            <HomeIcon className="icon" />
            <IconText>{t('Consultório Virtual')}</IconText>
          </List>

          <List onClick={closeSidebar} to="/calendar">
            <CalendarDaysIcon className="icon" />
            <IconText>{t('Agenda')}</IconText>
          </List>

          <List
            onClick={closeSidebar}
            to={{
              pathname: '/agenda',
              favorite: true
            }}
          >
            <ClipboardIcon className="icon" />
            <IconText>{t('Consultas')}</IconText>
          </List>

          <List onClick={closeSidebar} to="/clinic/invitations">
            <UserIcon className="icon" />
            <IconText>{t('Profissionais da Clínica')}</IconText>
          </List>

          {/* <List onClick={closeSidebar} to="/clinic/statistics"> */}
          {/*   <ChartBarIcon className="icon" /> */}
          {/*   <IconText>{t('Estatísticas')}</IconText> */}
          {/* </List> */}

          <List onClick={closeSidebar} to="/perfil">
            <DocumentTextIcon className="icon" />
            <IconText>{t('Meus Dados')}</IconText>
          </List>
        </Menu>
      </SidebarBox>
    );
  }

  return credentials && credentials.session ? (
    <Sidebar>
      <SidebarBox heightArea="30%">
        <DisplayFlex>
          <SideBarLogoBox>
            <ImageOrName
              src={credentials.session.avatar_url}
              title={credentials.session.name}
              titleSize={55}
              backgroundStyle={{
                backgroundColor: 'transparent',
                width: 134,
                height: 134,
                borderRadius: 134
              }}
            />
          </SideBarLogoBox>
          <SideBarUserName>{credentials.session.name}</SideBarUserName>
        </DisplayFlex>
        <Border />
      </SidebarBox>
      <UserSidebarMenus />
      <SidebarBox {...stylesSideBox}>
        <List onClick={handleSignOut} to="#">
          <PowerIcon className="icon" />
          <IconText>{t('Sair')}</IconText>
        </List>
      </SidebarBox>
    </Sidebar>
  ) : (
    <HomeSidebar />
  );
}
