import { useEffect, useState, useCallback } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';
import { useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { BiUndo } from 'react-icons/bi';
import { UserIcon } from '@heroicons/react/24/solid';
import OtpInput from 'react-otp-input';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { ROLE_PATIENT, ROLE_PROFESSIONAL } from '../../routes/constants';
import { useToast } from '../../contexts/ToastContext';
import Header from '../../components/Header';
import { insurancePartnersApi } from '../../services/insurance-partners.service';
import {
  Container,
  FindADoctorContainer,
  BackButtonContainer,
  FindADoctor,
  MainText,
  ProcessContainer,
  ProcessFooter,
  UserProcessContainer,
  UserProcessBackground,
  UserProcessImg,
  ProcessLine,
  Border,
  FooterText,
  HealthInsuranceCard,
  HealthInsuranceCardContent,
  HealthInsuranceCardFront,
  HealthInsuranceCardBack,
  HealthInsuranceCardButton,
  HealthInsuranceCardCContainer,
  HealthInsuranceInvitationAcceptButton,
  HealthInsuranceInvitationCard,
  HealthInsuranceInvitationDeclineButton,
  MainIconContainer,
  MainIcon,
  NurseLogo,
  ConfirmationTop,
  ConfirmationContainer
} from './health-insurance.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import {
  doctorDetailsService,
  doctorDeclineInvitationService
} from '../../services/doctors.service';
import { createConsultationService } from '../../services/create-consultation.service';
import { useAuth } from '../../hooks/AuthContext';
import { insuranceAuthorizationsService } from '../../services/schedule-process.service';
import { createEmergencyConsultationService } from '../../services/create-emergency-consultation.service';
import formattedDate from '../../utils/formattedDate';
import CheckOutlineAnimation from '../../assets/animations/check-outline.json';
import getDoctorTitle from '../../utils/getDoctorTitle';
import { FORMAT } from '../../utils/moment/momentFormat';
import ImageOrName from '../../components/ImageOrName';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
import ValidateCpfModal from '../../components/Modal/validateCpfModal';
import history from '../../services/history';
import { verifyInvitationStatus } from '../../services/verify-invitation-status';
import { PRIVATE_PARTNER_ICON } from '../../constants/common';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles({
  spinner: {
    color: 'var(--caren-darkgreen)'
  },

  otpContainer: {
    justifyContent: 'space-around'
  },

  otpInput: {
    lineHeight: '2.4em',
    width: '2em !important',
    fontSize: '1.5em',
    border: '1px solid var(--caren-lightgrey)',
    borderRadius: '5px'
  },

  backButton: {
    marginRight: '-12px',
    marginTop: '-12px'
  },

  buttonRoot: {
    display: 'block',
    margin: '10px auto'
  },
  buttonText: {
    color: 'var(--caren-white) !important',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
});

export default function HealthInsurance({
  computedMatch: {
    params: { id, doctor_id }
  }
}) {
  const { credentials, signOut } = useAuth();
  const { t } = useTranslation(['health_insurance']);
  const query = useQuery();
  const classes = useStyles();
  const scheduledDatetime = query.get('sd');
  const appointmentType = query.get('t') ?? 'default';
  const partnerKey = query.get('p');
  const invitationKey = query.get('i');
  const responseToken = query.get('rt');

  const isInvitation = !!invitationKey;
  const dots = new Array(15).fill(true);

  const toast = useToast();
  const [doctor, setDoctor] = useState({});
  const [insurancePartner, setInsurancePartner] = useState({});
  const [partnerCode, setPartnerCode] = useState('');
  const [token, setToken] = useState('');
  const [sending, setSending] = useState(false);
  const [cardFlip, setCardFlip] = useState(false);
  const [success, setSuccess] = useState(false);
  const [consultationKey, setConsultationKey] = useState('');
  const [anamnesisKey, setAnamnesisKey] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [fiscalCodeAuthenticationOpen, setFiscalCodeAuthenticationOpen] =
    useState(false);
  const [notifyModalOpen, setNotifyModalOpen] = useState(false);
  const [appointmentKey, setAppointmentKey] = useState('');
  const [emergencyKey, setEmergencyKey] = useState('');
  const TITLE = 'Sala de espera - Caren';
  const location = useLocation();
  const isEmergency = location.pathname.includes('/emergencia/');

  const maybeCreateTeleconsultation = useCallback(async () => {
    setSending(true);

    try {
      if (isEmergency) {
        const data = await createEmergencyConsultationService(
          insurancePartner.code,
          { insurance_token: token }
        );
        toast(t('Consulta agendada com sucesso!'), {
          variant: 'success'
        });
        setSuccess(true);
        setAppointmentKey(data.partner_appointment_key);
        setEmergencyKey(data.key);
        return;
      }

      const { data } = await createConsultationService({
        doctor_key: doctor?.key,
        invitation_key: invitationKey,
        response_token: responseToken,
        insurance_partner: insurancePartner.code,
        insurance_token: token,
        scheduled_datetime: scheduledDatetime,
        type: appointmentType
      });
      toast(t('Consulta agendada com sucesso!'), {
        variant: 'success'
      });
      setSuccess(true);
      setAnamnesisKey(data.anamnesis_key);
      setConsultationKey(data.key);
    } catch (error) {
      setPartnerCode('');
      setToken('');
      setCardFlip(false);

      const message = error?.response?.data?.error?.message;

      if (message?.start_datetime) {
        if (
          message?.start_datetime?.join(', ').includes('Overlapping patient')
        ) {
          toast(t('Você já tem uma consulta neste horário!'), {
            variant: 'error'
          });
        } else {
          toast(t('Este horário não está mais disponível.'), {
            variant: 'error'
          });
        }
      } else if (message === 'Invitation has already been accepted') {
        const teleconsultationKey =
          error?.response?.data?.error?.teleconsultation_key;
        history.push(`/consultas/${teleconsultationKey}/sala-de-espera`);
      } else if (message === 'Patient fiscal code is invalid') {
        await signOut();
        toast(
          t(
            'Esta consulta não pode ser aceita por este CPF. Favor clicar no convite novamente e digitar o CPF correto.'
          ),
          {
            variant: 'error'
          }
        );
      } else if (message?.fiscal_code) {
        await signOut();
        history.push('/login', { noRedirect: true });
        toast(
          t(
            'O CPF informado pelo médico já está em uso por outro usuário. Favor clicar no convite novamente e digitar o CPF correto.'
          ),
          {
            variant: 'error'
          }
        );
      } else if (message === "Invitations can't be accepted after deadline") {
        toast(
          t(
            'A consulta passou do prazo para ser aceita, uma nova deve ser criada'
          ),
          {
            variant: 'error'
          }
        );
      } else {
        toast(t('O convite foi cancelado pelo profissional da saúde.'), {
          variant: 'error'
        });
      }
    }
    setSending(false);
  }, [
    appointmentType,
    doctor,
    insurancePartner,
    invitationKey,
    isEmergency,
    responseToken,
    scheduledDatetime,
    t,
    toast,
    token,
    signOut
  ]);

  const verifyInvitation = useCallback(
    async key => {
      try {
        const invitation = await verifyInvitationStatus(key);
        if (invitation.accepted_at && invitation.teleconsultation) {
          history.push(
            `/consultas/${invitation.teleconsultation.key}/sala-de-espera`
          );
        }
        if (invitation.cancelled_at || invitation.rejected_at) {
          toast(t('Consulta cancelada, entre em contato com seu profissional'), {
            variant: 'error'
          });
          history.push('/');
        }
      } catch (error) {
        toast(t('Este convite não é mais válido'), {
          variant: 'error'
        });
        history.push('/');
      }
    },
    [t, toast]
  );

  useEffect(() => {
    if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
      toast(
        t('Para confirmar a consulta, é preciso estar logado como paciente.'),
        {
          variant: 'error'
        }
      );
      history.push('/consultorio');
    }

    if (credentials?.token?.user_type) {
      verifyInvitation(invitationKey);
    }

    const isPatient = credentials?.token?.user_type !== ROLE_PATIENT;
    setFiscalCodeAuthenticationOpen(isPatient);
  }, [credentials, invitationKey]);

  useEffect(() => {
    async function getDoctorDetailService() {
      const { data } = await doctorDetailsService(doctor_id);
      setDoctor(data);
      if (partnerKey === 'private') {
        setInsurancePartner({ key: partnerKey, icon: PRIVATE_PARTNER_ICON });
      } else {
        const partner = data.insurance_partners.find(
          insurance => insurance.key === partnerKey
        );
        setInsurancePartner(partner);
      }
    }

    if (doctor_id) getDoctorDetailService();
  }, [doctor_id, partnerKey]);

  useEffect(() => {
    const getInsurancePartners = async () => {
      const { data } = await insurancePartnersApi();
      setInsurancePartner(data.find(insurance => insurance.key === id));
    };
    if (isEmergency) getInsurancePartners();
  }, [id, isEmergency]);

  useEffect(() => {
    if (!sending && token.length === 6) {
      maybeCreateTeleconsultation();
    }
  }, [token, sending, maybeCreateTeleconsultation]);

  async function handleValidateInsurance(e) {
    e.preventDefault();

    try {
      setSending(true);
      await insuranceAuthorizationsService(insurancePartner.code, partnerCode);
      setCardFlip(true);
      toast(t('Enviamos um token para o seu e-mail!'), {
        variant: 'success'
      });
    } catch (error) {
      toast(t('Este token não é válido.'), {
        variant: 'error'
      });
    }
    setSending(false);
  }

  const handleChangePartnerCode = e => {
    setPartnerCode(e.target.value);
  };
  const handleChangeToken = newToken => {
    setToken(newToken.toUpperCase());
  };
  const handleBackButton = () => {
    setCardFlip(false);
  };

  function renderTeleconsultationTime() {
    if (isEmergency) {
      return (
        <span>
          {t('Horário')}: {t('Imediato')}
        </span>
      );
    }

    return (
      <div>
        <span>{t('Horário')}: </span>
        <span>{formattedDate(scheduledDatetime)}</span>
        <span>
          {moment
            .utc(scheduledDatetime)
            .local()
            .format(FORMAT['hour-and-minute'])}
        </span>
      </div>
    );
  }

  function renderTeleconsultationWith() {
    if (isEmergency) {
      return <p>{t('Pronto atendimento')}</p>;
    }

    return (
      <p>
        {t('Teleconsulta')}: {getDoctorTitle(doctor)} {doctor.name}
      </p>
    );
  }

  function renderTeleconsultationInfo() {
    return (
      <>
        {renderTeleconsultationWith(isEmergency)}
        {renderTeleconsultationTime(isEmergency)}
        {/* <p>
          <b>{t('Valor')}</b>: {t('Coberto pelo plano')}
        </p> */}
      </>
    );
  }

  function handleLinkTo() {
    if (isEmergency) {
      return '/medicos';
    }

    return `/medicos/${doctor_id}`;
  }

  function renderDoctorImage() {
    if (isEmergency) {
      return <NurseLogo />;
    }

    return (
      <UserProcessImg
        opacity={1}
        imgUrl={doctor?.avatar_url}
        title={doctor?.name}
        titleSize={50}
        backgroundStyle={{
          width: 160,
          height: 160,
          borderRadius: 140,
          backgroundColor: 'var(--caren-image-fallback)',
          marginRight: 5
        }}
        iconSyle={{
          width: 100,
          height: 100
        }}
      />
    );
  }

  function renderCardButton() {
    if (isEmergency) {
      return (
        <div>
          <Link
            to={`/emergencia/${emergencyKey}/plano/${insurancePartner.code}/anamnese/${appointmentKey}/chat`}
          >
            <HealthInsuranceCardButton style={{ width: '100%' }}>
              {t('Pré-atendimento')}
            </HealthInsuranceCardButton>
          </Link>
        </div>
      );
    }

    return (
      <div style={{ padding: '0 20px 0' }}>
        <h3 style={{ marginBottom: '-10px', textAlign: 'center' }}>
          Antes de realizar a sua consulta, precisamos que você preencha o seu
          pré-atendimento.
        </h3>

        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: CheckOutlineAnimation,
            rendererSettings: {
              viewBoxSize: '100 100 245 245',
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          width={100}
          height={100}
        />

        <Link
          to={`/consultas/${consultationKey}/anamnese/${anamnesisKey}/chat`}
        >
          <HealthInsuranceCardButton
            style={{ width: '100%', padding: '7px 0px' }}
          >
            {t('Pré-atendimento')}
          </HealthInsuranceCardButton>
        </Link>
      </div>
    );
  }

  function handleDeclineButtonAction(e) {
    e.preventDefault();

    setDeleteConfirmationOpen(true);
  }

  function renderInvitationContent(doctorName, date, time) {
    if (sending) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <CircularProgress
            className={classes.spinner}
            style={{ width: '3em' }}
          />
        </div>
      );
    }

    if (success) {
      return (
        <>
          <h1
            className="text-lg font-bold"
            style={{ marginBottom: '0px', marginTop: '20px', color: '#666' }}
          >
            {t('Consulta Confirmada!')}
          </h1>
          {renderCardButton()}
        </>
      );
    }

    return (
      <>
        <ConfirmationTop>
          <h1>Confirmação de consulta</h1>
        </ConfirmationTop>
        <ConfirmationContainer>
          <h1>{doctorName}</h1>
          <h3>Data: {date}</h3>
          <h3>Horário: {time}</h3>
          <HealthInsuranceInvitationAcceptButton type="submit">
            {t('Confirmar')}
          </HealthInsuranceInvitationAcceptButton>
          <HealthInsuranceInvitationDeclineButton
            onClick={handleDeclineButtonAction}
          >
            <u>{t('Recusar')}</u>
          </HealthInsuranceInvitationDeclineButton>
        </ConfirmationContainer>
      </>
    );
  }

  function acceptInvitation(e) {
    e.preventDefault();
    maybeCreateTeleconsultation();
  }

  function renderHealthInsuranceCard() {
    const doctorName = doctor?.name
      ? `${getDoctorTitle(doctor)} ${doctor.name}`
      : 'Carregando...';
    const date = formattedDate(scheduledDatetime);
    const time = moment
      .utc(scheduledDatetime)
      .local()
      .format(FORMAT['hour-and-minute']);

    if (isInvitation)
      return (
        <HealthInsuranceInvitationCard onSubmit={acceptInvitation}>
          {renderInvitationContent(doctorName, date, time)}
        </HealthInsuranceInvitationCard>
      );

    return (
      <div>
        <p>{t('Dados do Plano de Saúde')}</p>
        <HealthInsuranceCard flip={cardFlip}>
          <HealthInsuranceCardContent>
            <HealthInsuranceCardFront onSubmit={handleValidateInsurance}>
              <ImageOrName
                src={insurancePartner.banner}
                title={insurancePartner.name}
                titleSize={30}
                imageStyle={{
                  borderRadius: '0%'
                }}
                backgroundStyle={{
                  width: 35,
                  height: 35,
                  backgroundColor: 'var(--caren-image-fallback)'
                }}
                iconSyle={{
                  width: 20,
                  height: 20
                }}
              />
              {sending && !cardFlip ? (
                <Grid container direction="column" alignItems="center">
                  <CircularProgress className={classes.spinner} />
                </Grid>
              ) : (
                <input
                  type="text"
                  value={partnerCode}
                  onChange={handleChangePartnerCode}
                  placeholder={t('Nº do cartão do plano')}
                />
              )}
              <div>
                {!cardFlip && (
                  <HealthInsuranceCardButton
                    type="submit"
                    disabled={partnerCode.length < 3 || sending}
                  >
                    {t('Validar')}
                  </HealthInsuranceCardButton>
                )}
              </div>
            </HealthInsuranceCardFront>
            <HealthInsuranceCardBack>
              {cardFlip && (
                <>
                  <Grid container justify="space-between">
                    <Grid item>
                      <ImageOrName
                        src={insurancePartner.banner}
                        title={insurancePartner.name}
                        titleSize={45}
                        imageStyle={{
                          borderRadius: '0%'
                        }}
                        backgroundStyle={{
                          width: 35,
                          height: 35,
                          backgroundColor: 'var(--caren-image-fallback)'
                        }}
                        iconSyle={{
                          width: 20,
                          height: 20
                        }}
                      />
                    </Grid>
                    {!success && (
                      <IconButton
                        className={classes.backButton}
                        onClick={handleBackButton}
                      >
                        <BiUndo />
                      </IconButton>
                    )}
                  </Grid>
                  {success ? (
                    <>{renderCardButton()}</>
                  ) : (
                    <>
                      <div>
                        <p style={{ fontSize: '0.8em' }}>
                          {t('Nº do cartão informado')}:
                        </p>
                        <p>
                          <b>{partnerCode}</b>
                        </p>
                      </div>
                      <p>
                        {t('Enviamos um Token para o seu e-mail.')}
                        <br />
                        {t('Informe abaixo para validar o seu acesso.')}
                      </p>
                      {sending ? (
                        <Grid container direction="column" alignItems="center">
                          <CircularProgress className={classes.spinner} />
                        </Grid>
                      ) : (
                        <OtpInput
                          value={token}
                          onChange={handleChangeToken}
                          numInputs={6}
                          containerStyle={classes.otpContainer}
                          inputStyle={classes.otpInput}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </HealthInsuranceCardBack>
          </HealthInsuranceCardContent>
        </HealthInsuranceCard>
      </div>
    );
  }

  function renderFooterText() {
    if (isInvitation) return null;

    return (
      <FooterText>
        {t(
          'Digite o número do seu cartão do plano de saúde acima para confirmar a sua consulta.'
        )}{' '}
        <FaQuestionCircle />
      </FooterText>
    );
  }

  async function handleTeleconsultationInvitationDecline(confirmed) {
    if (!confirmed) {
      setDeleteConfirmationOpen(false);
      return;
    }

    try {
      await doctorDeclineInvitationService(invitationKey);

      toast(t('Convite recusado com sucesso!'), {
        variant: 'success'
      });
      history.push('/consultas');
    } catch (error) {
      toast(t('Infelizmente, não foi possível recusar o convite.'), {
        variant: 'error'
      });
    }
  }

  function renderInsuranceIcon(insurancePartner) {
    if (insurancePartner === 'private') {
      return null;
    }

    return <MainIcon src={insurancePartner.icon} success={success} />;
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header
        rightAction={isInvitation ? null : 'notifications'}
      />
      <FindADoctorContainer>
        {!isInvitation && (
          <Link to={handleLinkTo()}>
            <BackButtonContainer>
              <IoIosArrowRoundBack size={25} color="var(--caren-darkred)" />
            </BackButtonContainer>
          </Link>
        )}
        <FindADoctor>{renderTeleconsultationInfo()}</FindADoctor>
      </FindADoctorContainer>
      <Container>
        <MainText>{t('Confirme a sua consulta')}</MainText>
        <ProcessContainer>
          <UserProcessContainer>
            <UserProcessBackground>
              {credentials?.session?.name !== 'Paciente' ? (
                <UserProcessImg
                  opacity={1}
                  imgUrl={credentials?.session?.avatar_url}
                  title={credentials?.session?.name}
                  titleSize={50}
                  backgroundStyle={{
                    width: 160,
                    height: 160,
                    borderRadius: 140,
                    backgroundColor: 'var(--caren-image-fallback)',
                    marginRight: 5
                  }}
                  iconSyle={{
                    width: 130,
                    height: 130
                  }}
                />
              ) : (
                <div className="relative flex h-24 w-24 items-center justify-center overflow-hidden rounded-full bg-gray-150 md:h-40 md:w-40">
                  <UserIcon className="absolute h-28 w-28 text-gray-350" />
                </div>
              )}
            </UserProcessBackground>
          </UserProcessContainer>
          {dots.map((_, index) => (
            <ProcessLine
              color="var(--caren-lightgrey)"
              key={index.toString()}
            />
          ))}
          <MainIconContainer>
            {renderInsuranceIcon(insurancePartner)}
          </MainIconContainer>
          {dots.map((_, index) => {
            const color = success ? 'var(--caren-lightgrey)' : '#e8e8e8';
            return <ProcessLine color={color} key={index.toString()} />;
          })}
          <UserProcessContainer>
            <UserProcessBackground>{renderDoctorImage()}</UserProcessBackground>
          </UserProcessContainer>
        </ProcessContainer>
        <Border />
        <HealthInsuranceCardCContainer>
          {renderHealthInsuranceCard()}
        </HealthInsuranceCardCContainer>
        <Border />
        <ProcessFooter>{renderFooterText()}</ProcessFooter>
      </Container>
      <UnreversibleConfirmationModal
        title={t(
          'Após confirmar a recusa do convite não será possível desfazer essa ação.\n\nVocê tem certeza de que deseja recusar o convite?'
        )}
        openModal={deleteConfirmationOpen}
        setOpenModal={setDeleteConfirmationOpen}
        handleConfirmation={handleTeleconsultationInvitationDecline}
      />
      <ValidateCpfModal
        invitationKey={invitationKey}
        responseToken={responseToken}
        open={fiscalCodeAuthenticationOpen}
        setOpen={setFiscalCodeAuthenticationOpen}
      />
    </>
  );
}
