import { type AxiosResponse } from 'axios';
import { InviteSchema } from '../v2/entities/invite';
import { type TeamSignup } from '../v2/entities/team_signup';
import api from './api';

export const getTeamInvitations = async () => {
  const { data } = await api.get<unknown, AxiosResponse<{ data: unknown[] }>>(
    '/secretaries/invitations'
  );

  return data.data.map(item => InviteSchema.parse(item));
};

export const sendTeamInvitation = async (email: string) => {
  const { data } = await api.post('/secretaries/invitations', null, {
    params: { email }
  });

  return data;
};

export const deleteTeamInvitation = async (key: string) => {
  const { data } = await api.delete(`/secretaries/invitations/${key}`);

  return data;
};

export const resendTeamInvitation = async (key: string) => {
  const { data } = await api.put(`/secretaries/invitations/${key}/resend`);

  return data;
};

export const getDataFromTeamInvitation = async (token: string) => {
  try {
    const { data } = await api.get('/secretaries-invitations', {
      params: { token }
    });

    return data;
  } catch (e) {
    return null;
  }
};

export const acceptTeamInvitation = async (key: string, token?: string) => {
  const { data } = await api.post('/employees/secretaries', {
    secretary_invitation_key: key,
    response_token: token
  });

  return data;
};

export const denyTeamInvitation = async (key: string, token?: string) => {
  const { data } = await api.put('/employees/secretaries/reject', {
    secretary_invitation_key: key,
    response_token: token
  });

  return data;
};

export const deleteTeamConnection = async (secretaryKey: string) => {
  const { data } = await api.delete('/employees/secretaries', {
    params: { secretary_key: secretaryKey }
  });

  return data;
};

export const teamSignupService = async (params: TeamSignup) =>
  await api.post('/secretaries', { ...params });
