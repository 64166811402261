import React, { useRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles, TextField, IconButton } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../contexts/ToastContext';
import { changeUserPasswordService } from '../../../../services/user.service';
import LoadingComponent from '../../../Loading';
import { FormContainer } from '../DataFormDoctor/form.styles';
import {
  ChangePasswordButton,
  ErrorMessageText,
  PasswordHints
} from './styles';

const PasswordTab = () => {
  const useStyles = makeStyles(_theme => ({
    showPasswordButton: {
      position: 'relative'
    },

    passwordFieldMargin: {
      marginRight: '-40px'
    }
  }));

  const classes = useStyles();

  const { t } = useTranslation(['doctor_profile']);

  const validationErrorMessages = {
    required: t('Este campo é obrigatório'),
    passwordMinLength: t('Senha deve ter no mínimo 8 caracteres'),
    passwordsMustMatch: t('As senhas devem ser iguais')
  };

  const {
    handleSubmit,
    getValues,
    control,
    setError,
    formState: { errors }
  } = useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const hints = useRef(null);
  const [passwordValidation, setPasswordValidation] = useState({
    valid: false
  });

  const onSubmit = async data => {
    const { currentPassword, newPassword } = data;
    setLoading(true);

    try {
      await changeUserPasswordService(currentPassword, newPassword);
      toast(t('Senha alterada com sucesso!'), {
        variant: 'success'
      });
    } catch (error) {
      setError('currentPassword', {
        type: 'custom',
        message: t('Senha atual errada!')
      });
    }

    setLoading(false);
  };

  function handlePasswordType() {
    if (showPassword) {
      return 'text';
    }

    return 'password';
  }

  function showPasswordOnClick() {
    setShowPassword(!showPassword);
  }

  function validateConfirmPassword(confirmPassword) {
    if (confirmPassword !== getValues('newPassword')) {
      return t('As senhas devem ser iguais');
    }

    return null;
  }

  function renderShowPasswordButton() {
    if (!showPassword) {
      return (
        <IconButton
          className={classes.showPasswordButton}
          onClick={showPasswordOnClick}
        >
          <FaEyeSlash size={16} />
        </IconButton>
      );
    }

    return (
      <IconButton
        className={classes.showPasswordButton}
        onClick={showPasswordOnClick}
      >
        <FaEye size={16} color="red" />
      </IconButton>
    );
  }

  const validPassword = e => {
    const value = e?.target?.value;

    const regAplhabetic = /[a-zA-Z]/g;
    const regNumeric = /[0-9]/;

    const step1 = value.length >= 8;
    const step2 = regAplhabetic.test(value);
    const step3 = regNumeric.test(value);
    const valid = step1 && step2 && step3;

    setPasswordValidation({
      valid,
      step1,
      step2,
      step3
    });
  };

  useEffect(() => {
    if (!hints.current) return;

    const field = hints.current.querySelector('input');

    field.addEventListener('keyup', e => {
      validPassword(e);
    });
  }, [hints]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        type="password"
        rules={{
          required: validationErrorMessages.required
        }}
        render={fields => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <TextField
              className={classes.passwordFieldMargin}
              label={t('Senha Atual')}
              type={handlePasswordType()}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            />
            {renderShowPasswordButton()}
          </div>
        )}
        name="currentPassword"
      />

      <ErrorMessage
        errors={errors}
        name="currentPassword"
        render={({ message }) => <ErrorMessageText>{message}</ErrorMessageText>}
      />

      <Controller
        control={control}
        type="password"
        rules={{
          required: validationErrorMessages.required,
          minLength: {
            value: 8,
            message: validationErrorMessages.passwordMinLength
          }
        }}
        render={fields => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
            ref={hints}
          >
            <TextField
              className={classes.passwordFieldMargin}
              label={t('Nova Senha')}
              type={handlePasswordType()}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            />
            {renderShowPasswordButton()}
          </div>
        )}
        name="newPassword"
      />

      <PasswordHints>
        <div>
          {passwordValidation.step1 === true ? (
            <CheckIcon className="valid" fontSize="small" />
          ) : (
            <>
              {passwordValidation?.step1 === false ? (
                <CloseIcon className="invalid" fontSize="small" />
              ) : (
                <ErrorOutlineIcon className="info" fontSize="small" />
              )}
            </>
          )}

          <span>Pelo menos 8 caracteres</span>
        </div>

        <div>
          {passwordValidation.step2 === true ? (
            <CheckIcon className="valid" fontSize="small" />
          ) : (
            <>
              {passwordValidation?.step2 === false ? (
                <CloseIcon className="invalid" fontSize="small" />
              ) : (
                <ErrorOutlineIcon className="info" fontSize="small" />
              )}
            </>
          )}

          <span>Pelo menos um caractere alfabético</span>
        </div>

        <div>
          {passwordValidation.step3 === true ? (
            <CheckIcon className="valid" fontSize="small" />
          ) : (
            <>
              {passwordValidation?.step3 === false ? (
                <CloseIcon className="invalid" fontSize="small" />
              ) : (
                <ErrorOutlineIcon className="info" fontSize="small" />
              )}
            </>
          )}

          <span>Pelo menos um caractere numérico</span>
        </div>
      </PasswordHints>

      <Controller
        className={classes.formInput}
        control={control}
        type="password"
        render={fields => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <TextField
              className={classes.passwordFieldMargin}
              label={t('Confirme a senha')}
              type={handlePasswordType()}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            />
            {renderShowPasswordButton()}
          </div>
        )}
        name="passwordConfirmation"
        rules={{
          required: validationErrorMessages.required,
          validate: validateConfirmPassword,
          minLength: {
            value: 8,
            message: validationErrorMessages.passwordMinLength
          }
        }}
      />

      <ErrorMessage
        errors={errors}
        name="passwordConfirmation"
        render={({ message }) => <ErrorMessageText>{message}</ErrorMessageText>}
      />

      <ChangePasswordButton type="submit" disabled={!passwordValidation.valid}>
        {loading ? <LoadingComponent /> : t('Alterar')}
      </ChangePasswordButton>
    </FormContainer>
  );
};

export default PasswordTab;
