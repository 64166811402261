import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../inputs/buttons/Button';
import { useAuth } from '../../../../hooks/AuthContext';
import { useToast } from '../../../../contexts/ToastContext';
import { type DoctorCreate } from '../../../entities/doctor';
import PersonImage from '../../data/personImage';
import onlyDigits from '../../../../utils/onlyDigits';
import validateCpf from '../../../../utils/validateCpf';
import DoctorForm, { FormTypes } from '../../forms/doctor';
import useCheckoutForm from '../../../hooks/checkout/useCheckoutForm';
type PersonStepProps = {
  step: number;
};

export default function PersonStep({ step }: PersonStepProps) {
  const { actualStep, forwardStep, setStepData } = useCheckoutForm();
  const [actualState, setActualState] = useState(FormTypes.Register);
  const history = useHistory();
  const toast = useToast();
  const { credentials, signOut } = useAuth();

  const saveData = (data: DoctorCreate) => {
    setStepData(step, data);
    forwardStep();
  };

  useEffect(() => {
    if (
      credentials?.token &&
      credentials.session &&
      credentials.token.session_type === 'authenticated' &&
      step
    ) {
      if (credentials.token.user_type === 'employee') {
        if (onlyDigits(credentials.session.phone).length < 10) {
          toast(
            'Antes de prosseguir, por favor informe o seu número de telefone para contato.',
            {
              variant: 'info'
            }
          );
          localStorage.setItem('doctor-profile-tab', 'data');
          history.push('/perfil');
        } else if (!validateCpf(credentials.session.fiscal_code)) {
          toast(
            'Antes de prosseguir, por favor verifique se o seu CPF está preenchido corretamente.',
            {
              variant: 'info'
            }
          );
          localStorage.setItem('doctor-profile-tab', 'data');
          history.push('/perfil');
        } else {
          setStepData(step, {
            name: credentials.session.name,
            fiscal_code: credentials.session.fiscal_code,
            isAuthenticated: true
          });
        }
      } else {
        toast(
          'Somente profissionais da saúde podem se registrar em um plano Caren.',
          {
            variant: 'error'
          }
        );
        history.push('/');
      }
    }
  }, [credentials, step]);

  if (actualStep !== step) return null;

  if (credentials && credentials.token.session_type === 'authenticated') {
    return (
      <div className="mt-7 flex h-full w-full max-w-sm flex-grow flex-col items-center md:mt-20">
        <div className="mb-8 h-40 w-40">
          <PersonImage
            name={credentials.session.name}
            image={credentials.session.avatar_url}
          />
        </div>
        <p className="mb-10 max-w-xs text-center font-nunito text-base text-blue-650">
          Você está conectado(a) como{' '}
          <strong className="text-lg">{credentials.session.name}</strong>, ao
          prosseguir o plano será adicionado à sua conta.
        </p>

        <button
          onClick={async () => {
            await signOut();
            setActualState(FormTypes.Login);
          }}
          type="button"
          className="mb-10 max-w-xs font-nunito text-sm text-gray-350"
        >
          <strong>Não é você?</strong>
          <br />
          Clique aqui para conectar a outra conta.
        </button>

        <Button
          onClick={() => {
            setStepData(step, {
              name: credentials.session.name,
              fiscal_code: credentials.session.fiscal_code,
              isAuthenticated: true
            });
            forwardStep();
          }}
          label="Prosseguir"
          type="button"
          colors="yellow"
          size="large"
        />
      </div>
    );
  }

  return (
    <DoctorForm
      className="mt-7 flex h-full w-full max-w-sm flex-grow flex-col items-center md:mt-20"
      saveData={saveData}
      buttonText="Prosseguir"
      type={actualState}
      allowLogin
    />
  );
}
