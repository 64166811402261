import combineReducers from './combinedReducers';
import sidebarStatus from './Sidebar/reducer';
import user from './User/reducer';
import query from './Query/reducer';
import doctor from './Doctor/reducer';
import notifications from './Notification/reducer';

export default combineReducers({
  sidebarStatus,
  user,
  query,
  doctor,
  notifications
});
