import React from 'react';
import useListOfSchedules from '../../hooks/doctorTeleconsultations.hook';
import {
  DivRelativeFlex,
  DoctorScheduleIcon,
  DoctorScheduleIconNotification,
} from './styles';

function DoctorBadgeCounter() {
  const [consultations] = useListOfSchedules({
    fromDate: new Date(),
    toDate: new Date(),
  });

  return (
    <DivRelativeFlex>
      <DoctorScheduleIcon />
      <DoctorScheduleIconNotification>
        <p>{consultations.length}</p>
      </DoctorScheduleIconNotification>
    </DivRelativeFlex>
  );
}

export default DoctorBadgeCounter;
