import React, { useEffect, useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export default function Tawk() {
  const tawkMessengerRef = useRef(null);

  useEffect(() => {
    const { current } = tawkMessengerRef;

    try {
      current.showWidget();
    } catch (error) {
      // Ignore if API is not initialized
      // Widget starts in the 'show' state, so this
      // call is only needed for navigation between pages
    }

    return () => {
      if (current?.minimize) {
        current.minimize();
      }

      if (current?.hideWidget) {
        current.hideWidget();
      }
    };
  }, []);

  return (
    // <TawkMessengerReact
    //   propertyId={import.meta.env.VITE_TAWK_PROPERTY_ID}
    //   widgetId={import.meta.env.VITE_TAWK_WIDGET_ID}
    //   ref={tawkMessengerRef}
    // />
    null
  );
}
