import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { Container } from './preappointment.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import history from '../../services/history';
import { useAuth } from '../../hooks/AuthContext';
import { authorizeAnamnesis } from '../../services/anamnesis-detail.service';
import { useToast } from '../../contexts/ToastContext';

export default function PreAppointment({
  computedMatch: {
    params: { appointment_id, consult_id, emergency_id, insurance_partner }
  }
}) {
  const {
    i18n: { language }
  } = useTranslation(['common']);
  const iframeElement = useRef(null);
  const { clearSession } = useAuth();
  const toast = useToast();

  const TITLE = 'Pré-Atendimento - Caren';
  const [IframeSrc, setIframeSrc] = useState(null);
  const IFRAME_ORIGIN = new URL(import.meta.env.VITE_IFRAME_DOMAIN).origin;

  const [progress, setProgress] = useState(-1);

  const decideRedirection = () => {
    if (emergency_id) {
      history.push(
        `/emergencia/${emergency_id}/plano/${insurance_partner}/sala-de-espera`
      );
      return;
    }

    history.push(`/consultas/${consult_id}/sala-de-espera`);
  };

  useEffect(() => {
    getAppointmentToken()
    const handleMessage = async event => {
      if (event.origin !== IFRAME_ORIGIN) return;

      const { data: message } = event;
      switch (message.type) {
        case 'LOGOUT': {
          clearSession();
          return;
        }

        case 'REDIRECTION': {
          history.push('/');
          return;
        }

        case 'EXTERNAL_REDIRECTION':
          decideRedirection();
          break;

        case 'FLOW_FULFILLMENT_PROGRESS':
          setProgress(message?.progress || 0);
          break;

        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const getAppointmentToken = async () => {
    try {
      const res = await authorizeAnamnesis(appointment_id);
      setIframeSrc(combineURLs(
          import.meta.env.VITE_IFRAME_DOMAIN,
          `/partner-appointment/${appointment_id}?theme=telemed-patient&lng=${language}&t=${res.token}`
      ));
    } catch (error) {
      toast('Não foi possível iniciar a anamnese, por favor tente novamente.', {
        variant: 'error'
      });
      decideRedirection()
    }
  }

  const onClickBack = () => {
    const targetWindow = iframeElement?.current?.contentWindow;
    if (targetWindow == null) return;

    const message = { type: 'RETURN_TO_PREVIOUS_QUESTION' };

    targetWindow.postMessage(message, IFRAME_ORIGIN);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      {progress > 0 ? (
        <Header rightAction="back" backAction={onClickBack} />
      ) : (
        <Header />
      )}
      <Container>
        <iframe
          ref={iframeElement}
          src={IframeSrc}
          title={TITLE}
          style={{ flex: 1, width: '100%', border: 'none' }}
        />
      </Container>
    </>
  );
}
