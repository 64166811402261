import { AiFillCamera } from 'react-icons/ai';
import styled from 'styled-components/macro';
import ImageOrName from '../ImageOrName';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  position: relative;

  .errorsContainer {
    position: absolute;
    top: calc(100% - 10px);
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 767px) {
      left: calc(50% - 50px);
    }

    .errorMessage {
      background-color: white;
      width: 200px;
      padding: 15px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const ImageContainerLoading = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.5s;
  pointer-events: none;
  z-index: 50;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`;

export const ImageContainerSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: relative;

  @keyframes doctor_panel_progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: doctor_panel_progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const DoctorLogo = styled(ImageOrName).attrs(({ src }) => ({
  src
}))``;

export const CameraIcon = styled(AiFillCamera).attrs({
  size: 20,
  color: '#262C2E'
})`
  background-color: #d1dee2;
  height: 35px;
  width: 35px;
  padding: 5px;
  border-radius: 50%;

  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const InfoContainer = styled.div`
  margin-top: 10px;

  p {
    &:nth-child(2) {
      display: block;
      min-height: 35px;
    }
  }
`;

export const DoctorName = styled.p`
  color: var(--caren-icongrey);
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
`;

export const DoctorSpec = styled.p`
  color: var(--caren-clocklightgrey);
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
`;

export const NextPatientContainer = styled.div<{ isInHome: boolean }>`
  width: 100%;
  margin-top: 20px;

  @media (max-width: 767px) {
    display: ${props => (props.isInHome ? 'block' : 'none')};
  }
`;

export const SeeMore = styled.div`
  margin-top: 10px;
  color: var(--caren-lightgrey);
  cursor: pointer;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const InfoIncomplete = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: black;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 15px;
  }

  a {
    background-color: var(--caren-orange);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    svg {
      display: inline-block;
      margin-right: 14px;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease;

      path {
        fill: white;
      }
    }

    span {
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
    }

    &:hover {
      background-color: #077a94;

      svg {
        transform: translateX(5px);
      }
    }
  }
`;

export const CTAOpenInvitationModal = styled.div<{ isInHome: boolean }>`
  margin-top: 16px;
  width: 100%;

  button {
    background-color: var(--caren-icongrey);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    svg {
      display: inline-block;
      margin-right: 14px;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease;

      path {
        fill: white;
      }
    }

    span {
      color: white;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
    }

    &:hover {
      background-color: var(--caren-lightgrey);

      svg {
        transform: translateX(5px);
      }
    }
  }

  @media (max-width: 767px) {
    display: ${props => (props.isInHome ? 'block' : 'none')};
  }
`;

export const AlertCert = styled.div`
  background-color: white;
  padding: 15px 10px;
  border-radius: 15px;
  margin-top: 16px;
`;

export const AlertCertLogo = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 140px;
    display: block;
  }
`;

export const AlertCertTitle = styled.div`
  color: var(--caren-icongrey);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 0 10px;
  margin: 10px auto 0;
  max-width: 240px;
`;

export const AlertCertDescription = styled.div`
  margin-top: 10px;

  p {
    color: black;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
  }
`;

export const AlertCertCTA = styled.div`
  margin-top: 10px;

  button {
    background-color: var(--caren-greenblue);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    svg {
      display: inline-block;
      margin-right: 14px;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease;

      path {
        fill: white;
      }
    }

    span {
      color: var(--caren-icongrey);
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
    }

    &:hover {
      background-color: var(--caren-darkgreen);

      svg {
        transform: translateX(5px);
      }
    }
  }
`;
