import React from 'react';
import { useTranslation } from 'react-i18next';
import { HealthPlanCard, HealthPlanContainer, ModalMsg } from './styles';
import ImageOrName from '../ImageOrName';
import Modal from '../Modal';

function HealthInsuranceSelectorDialog({
  handleToggleModal,
  openModal,
  onClickFunc,
  insurancePartners,
  modalMsg,
  setModalmsg,
}) {
  const { t } = useTranslation(['doctor_details']);

  return (
    <Modal
      handleOpenModal={handleToggleModal}
      openModal={openModal}
      modalTitle={`${t('Selecione o seu Plano de Saúde')}:`}
      modalTitleColor="black"
      setModalmsg={setModalmsg}
    >
      <HealthPlanContainer>
        {insurancePartners.map((Partner) => (
          <HealthPlanCard
            key={Partner.key}
            onClick={() => onClickFunc(Partner)}
          >
            <ImageOrName
              src={Partner.banner}
              title={Partner.name}
              titleSize={35}
              imageStyle={{
                borderRadius: '0%',
              }}
              backgroundStyle={{
                height: 50,
                backgroundColor: 'var(--caren-image-fallback)',
              }}
            />
          </HealthPlanCard>
        ))}
      </HealthPlanContainer>
      <ModalMsg>
        <span>{modalMsg}</span>
      </ModalMsg>
    </Modal>
  );
}

export default HealthInsuranceSelectorDialog;
