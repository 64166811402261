import { useEffect, useState, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiCalendar } from 'react-icons/fi';
import { MdFileUpload } from 'react-icons/md';
import { Chat, Input } from '@material-ui/icons';
import { useToast } from '../../contexts/ToastContext';
import Header from '../../components/Header';
import ImageOrName from '../../components/ImageOrName';
import { listPatientAppointmentsService } from '../../services/patient-service';
import PendingQuestionnaireModal from '../../components/Modal/PendingQuestionnaireModal';
import { ReactComponent as TimerIcon } from '../../assets/icons/timer.svg';
import {
  Container,
  FirstGrid,
  SecondGrid,
  SecondHeader,
  SearchInputContainer,
  SearchInputIcon,
  SearchInput,
  HistoryContainer,
  PendingQuestionnaireModalButton,
  PendingQuestionnaireModalDiv,
  Wrapper,
  SectionTitle,
  ConsultsTable
} from './consults.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import PatientPanel from '../../components/PatientPanel';
import Button from '../../v2/components/inputs/buttons/Button';
import { FORMAT } from '../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';
import {
  type Consult,
  type TeleconsultationComplete
} from '../../v2/entities/consult';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PATIENT } from '../../routes/constants';

export default function DoctorRecord() {
  const { t } = useTranslation(['consults']);
  const { credentials, signOut } = useAuth();
  const toast = useToast();

  const [appointments, setAppointments] = useState<Consult[] | null>(null);
  const [firstAvailable, setFirstAvailable] =
    useState<TeleconsultationComplete | null>(null);
  const setPendingQuestionnaireModal = useState(firstAvailable)[1];
  const [searchField, setSearchField] = useState<Consult[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [allContentLoaded, setAllContentLoaded] = useState(false);

  const TITLE = 'Consultas - Caren';

  const CONSULTS_PEER_REQUEST = 20;

  const consultParams = useMemo(() => {
    if (firstAvailable?.confirmation_at === null) {
      return {
        icon: <MdFileUpload />,
        action: t('Comprovante'),
        link: `/consultas/${firstAvailable?.key}`
      };
    }
    if (firstAvailable?.flow_concluded) {
      return {
        icon: <Input />,
        action: t('Sala de espera'),
        link: `/consultas/${firstAvailable?.key}/sala-de-espera`
      };
    }
    return {
      icon: <Chat />,
      action: t('Pré-atendimento'),
      link: `/consultas/${firstAvailable?.key}/sala-de-espera`
    };
  }, [
    firstAvailable?.confirmation_at,
    firstAvailable?.flow_concluded,
    firstAvailable?.key,
    t
  ]);

  const loadMoreConsults = useCallback(async () => {
    if (appointments === null || appointments.length <= 0) return;

    setLoadingMore(true);

    const lastKey = appointments[appointments.length - 1].key;

    const { data } = await listPatientAppointmentsService(lastKey);

    if (data.length <= 0) {
      setAllContentLoaded(true);
    } else {
      setAppointments(old => (old ? old.concat(data) : null));
    }

    setLoadingMore(false);
  }, [appointments]);

  useEffect(() => {
    async function getAllSchedules() {
      try {
        const { data } = await listPatientAppointmentsService();

        if (data) {
          const first = data.find(
            (consult: Consult) =>
              !consult?.teleconsultation?.concluded &&
              !consult?.teleconsultation?.expired
          );

          setAppointments([...data]);
          setFirstAvailable({ ...first });
        }
      } catch (error) {
        toast(t('Ops, não foi possível carregar o histórico...'), {
          variant: 'error'
        });
      }
    }

    if (
      credentials &&
      credentials.token.user_type === ROLE_PATIENT &&
      credentials.token.session_type === 'authenticated' &&
      appointments === null
    ) {
      getAllSchedules();
    }
  }, [credentials, t, toast, appointments]);

  useEffect(() => {
    if (appointments !== null) {
      setSearchField(() => [...appointments]);
    }
  }, [appointments]);

  useEffect(() => {
    if (credentials && credentials.token.session_type === 'limited') {
      signOut();
    }
  }, [credentials, signOut]);

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (appointments !== null) {
      const foundAppointments = appointments.filter(entry => {
        const toLower = (text: string) => text.toLowerCase();
        const specialties = entry?.employee?.specialties
          ?.map(spec => spec.name)
          .join(', ');
        const search = `${entry?.employee?.name} ${specialties}`;
        return toLower(search).includes(toLower(value));
      });

      setSearchField([...foundAppointments]);
    }
  }

  function formatHour(date: string) {
    return momentUtcLocal(date).format(FORMAT['pt-datetime']);
  }

  function renderCancelledMessage(
    cancellationReason: string,
    cancelledAt: string
  ) {
    switch (cancellationReason) {
      case 'employee':
        return `Cancelada pelo médico em ${formatHour(cancelledAt)}`;
      case 'patient':
        return `Recusada por você em ${formatHour(cancelledAt)}`;
      default:
        return `Cancelada pelo sistema em ${formatHour(cancelledAt)}`;
    }
  }

  function renderStatus(consult: Consult) {
    const teleconsultation = consult.teleconsultation;

    if (consult.cancellation_reason && consult.cancelled_at) {
      return (
        <p className="text-error">
          {renderCancelledMessage(
            consult.cancellation_reason,
            consult.cancelled_at
          )}
        </p>
      );
    }

    if (
      teleconsultation?.room_opened_at &&
      teleconsultation?.room_closed_at !== null
    ) {
      return <p>Concluída</p>;
    }

    if (teleconsultation) {
      if (!teleconsultation.flow_concluded) {
        return (
          <p className="text-success">
            Confirmada
            <span>Você precisa preencher a anamnese</span>
          </p>
        );
      }
      if (teleconsultation.open && teleconsultation.room_opened_at) {
        return <p className="progress">Em progresso</p>;
      }
      return <p className="text-success">Confirmada</p>;
    }

    return (
      <p className="text-warning">
        Pendente <span>Você precisa confirmar a consulta</span>
      </p>
    );
  }

  function renderAction(consultation: Consult) {
    if (
      consultation.teleconsultation?.room_closed_at ??
      consultation?.cancelled_at
    ) {
      return null;
    }

    if (
      !consultation?.teleconsultation &&
      !consultation?.invitation?.rejected_at
    ) {
      return (
        <Link
          to={consultation?.invitation?.link ?? '/'}
          key="confirm-consult"
          className="blue-button"
          title={t('Confirmar consulta').toString()}
        >
          Confirmar consulta
        </Link>
      );
    }

    if (
      consultation?.teleconsultation?.open &&
      consultation?.teleconsultation?.room_opened_at
    ) {
      return (
        <Link
          to={{
            pathname: `/consultas/${consultation?.key}/sala-de-espera`
          }}
          key="wait-room"
          className="blue-button"
          title={t('Voltar para Teleconsulta').toString()}
        >
          Voltar para Teleconsulta
        </Link>
      );
    }

    if (!consultation?.teleconsultation?.flow_concluded) {
      return (
        <Link
          className="warning-button"
          to={`/consultas/${consultation?.teleconsultation?.key}/sala-de-espera`}
        >
          Entrar na Teleconsulta
        </Link>
      );
    }

    return (
      <Link
        className="green-button"
        to={`/consultas/${consultation?.teleconsultation?.key}/sala-de-espera`}
      >
        Entrar na Teleconsulta
      </Link>
    );
  }

  return (
    <>
      <Helmet title={TITLE} />
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <Container>
        <PendingQuestionnaireModal
          handleOpenModal={setPendingQuestionnaireModal}
          openModal={false}
          modalTitle={t('PRÉ-ATENDIMENTO')}
          modalWidth="55%"
          modalTitleColor="black"
          containerStyles={{ textAlign: 'center' }}
        >
          <PendingQuestionnaireModalDiv>
            <Link to={consultParams.link}>
              <PendingQuestionnaireModalButton>
                <span>
                  <FiCalendar />
                </span>
                Responder
              </PendingQuestionnaireModalButton>
            </Link>
          </PendingQuestionnaireModalDiv>
        </PendingQuestionnaireModal>
        <FirstGrid>
          <PatientPanel />
        </FirstGrid>
        <SecondGrid>
          <SecondHeader>
            <SearchInputContainer>
              <SearchInputIcon />
              <SearchInput
                className="border-none focus:ring-yellow-750"
                placeholder={t('Buscar na agenda').toString()}
                onChange={handleSearch}
              />
            </SearchInputContainer>
          </SecondHeader>

          <HistoryContainer>
            <Wrapper>
              <SectionTitle>Minhas Consultas</SectionTitle>
              {searchField.length !== 0 ? (
                <>
                  <ConsultsTable>
                    <thead>
                      <tr>
                        <th scope="col">Médico</th>
                        <th scope="col" className="center">
                          Especialidade
                        </th>
                        <th scope="col" className="center">
                          Data
                        </th>
                        <th scope="col" className="center">
                          Status
                        </th>
                        <th scope="col" className="center">
                          Ação
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {searchField.map(consultation => (
                        <tr key={consultation.key}>
                          <td>
                            <div className="perfil">
                              <ImageOrName
                                src={consultation?.employee?.avatar_url}
                                title={consultation?.employee?.name}
                                titleSize={16}
                                backgroundStyle={{
                                  width: 55,
                                  height: 55,
                                  borderRadius: '100%',
                                  backgroundColor: 'var(--caren-image-fallback)'
                                }}
                                iconSyle={{
                                  width: 45,
                                  height: 45
                                }}
                              />{' '}
                              <p>{consultation?.employee?.name}</p>
                            </div>
                          </td>
                          <td className="center">
                            <span>Especialidade</span>
                            <p>
                              {consultation?.employee?.specialties
                                ?.map(spec => spec.name)
                                .join(', ')}
                            </p>
                          </td>
                          <td className="center">
                            <span>Data</span>
                            <p>
                              {formatHour(
                                consultation?.teleconsultation
                                  ?.room_patient_start_time ??
                                  consultation.start_datetime
                              )}
                            </p>
                          </td>
                          <td className="center">
                            <span>Status</span>
                            <p>{renderStatus(consultation)}</p>
                          </td>
                          <td>
                            {consultation &&
                            (consultation?.teleconsultation?.room_closed_at ??
                              consultation?.teleconsultation
                                ?.cancelled_at) ? null : (
                              <span>Ação</span>
                            )}
                            <p>{renderAction(consultation)}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ConsultsTable>
                  {loadingMore && (
                    <div className="mt-10 flex animate-pulse items-center gap-x-4 self-center text-gray-450">
                      <TimerIcon className="h-6 w-6 animate-spin" />
                      <p className="font-nunito">
                        Carregando mais resultados...
                      </p>
                    </div>
                  )}

                  {appointments &&
                    appointments.length > 0 &&
                    appointments.length % CONSULTS_PEER_REQUEST === 0 &&
                    !allContentLoaded && (
                      <div className="mt-10 w-full max-w-[14rem] self-center">
                        <Button
                          type="button"
                          onClick={async () => {
                            await loadMoreConsults();
                          }}
                          disabled={loadingMore}
                          label="Mostrar mais"
                          colors="yellow"
                          size="large"
                        />
                      </div>
                    )}
                </>
              ) : (
                <span>Nenhuma consulta encontrada</span>
              )}
            </Wrapper>
          </HistoryContainer>
        </SecondGrid>
      </Container>
    </>
  );
}
