import React from 'react';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { FaUserMd, FaSyringe } from 'react-icons/fa';
import { GiPill } from 'react-icons/gi';

export default function VerticalTime() {
  return (
    <VerticalTimeline
      layout="1-column"
      className="vertical-timeline-custom-line"
    >
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'var(--caren-green)', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  var(--caren-green)' }}
        date="23/04/2020 - 11:37"
        iconStyle={{ background: 'var(--caren-green)', color: '#fff' }}
        icon={<FaUserMd />}
      >
        <h3 className="vertical-timeline-element-title">
          Check-Up - Carlos Jarbas
        </h3>
        <h5 className="vertical-timeline-element-subtitle">
          <i>Dr. Henry Seven</i>
        </h5>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="23/04/2020 - 13:05"
        iconStyle={{ background: 'var(--caren-lightgrey)', color: '#fff' }}
        icon={<GiPill />}
      >
        <h3 className="vertical-timeline-element-title">
          Medicamentação - Maria Severina
        </h3>
        <h5 className="vertical-timeline-element-subtitle">
          <i>Dra. Luciana Silveira</i>
        </h5>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="24/04/2020 - 14:53"
        iconStyle={{ background: 'var(--caren-lightgrey)', color: '#fff' }}
        icon={<FaUserMd />}
      >
        <h3>Medição de Pressao</h3>
        <h5 className="vertical-timeline-element-subtitle">
          <i>Enfermeira Cátia Ramalho</i>
        </h5>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="24/04/2020 - 17:37"
        iconStyle={{ background: 'var(--caren-red)', color: '#fff' }}
        icon={<FaSyringe />}
      >
        <h3>Vacina da Gripe</h3>
        <h5 className="vertical-timeline-element-subtitle">
          <i>Injeção intramuscular</i>
        </h5>
        <p>User Experience, Visual Design</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="25/04/2020 - 18:29"
        iconStyle={{ background: 'var(--caren-lightgrey)', color: '#fff' }}
        icon={<FaUserMd />}
      >
        <h3 className="vertical-timeline-element-title">
          Exame de Acuidade Visual
        </h3>
        <h5 className="vertical-timeline-element-subtitle">
          <i>Dr. Patrício Marlon</i>
        </h5>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="26/04/2020 - 19:15"
        iconStyle={{ background: 'var(--caren-lightgrey)', color: '#fff' }}
        icon={<GiPill />}
      >
        <h3 className="vertical-timeline-element-title">
          Alteração de Medicamentos
        </h3>
        <h5 className="vertical-timeline-element-subtitle">
          <i>Dra. Larissa Souza</i>
        </h5>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="26/04/2020 - 22:01"
        iconStyle={{ background: 'var(--caren-lightgrey)', color: '#fff' }}
        icon={<FaSyringe />}
      >
        <h3 className="vertical-timeline-element-title">
          Vacina preventiva de H1N1
        </h3>
        <h5 className="vertical-timeline-element-subtitle">
          <i>Enfermeiro Paulo Jeremias</i>
        </h5>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
}
