import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-circle.svg';
import SearchIcon from '../../../../assets/images/search.png';

type SearchInputProps = {
  label?: string;
  error?: string;
  defaultValue?: string;
  onUpdateValue?: (value: string) => void;
}

export default function SearchInput({
  label,
  error,
  defaultValue,
  onUpdateValue
}: SearchInputProps) {
  const [value, setValue] = useState(defaultValue ?? '');

  return (
    <div className="relative flex w-full flex-col items-start">
      {label && (
        <label className="mb-1 font-nunito text-sm font-semibold text-gray-350">
          {label}
        </label>
      )}

      <div className="relative flex w-full items-center">
        <img
          src={SearchIcon}
          alt="Search Icon"
          className="pointer-events-none absolute bottom-2.5 left-3 h-5 w-5 text-warning"
        />
        {value.length > 0 && (
          <button
            type="button"
            tabIndex={0}
            title="Limpar campo"
            onClick={() => {
              setValue('');
              if (onUpdateValue) onUpdateValue('');
            }}
            className="absolute bottom-0 right-0 grid h-10 w-10 place-items-center text-gray-400"
          >
            <CloseIcon className="h-4 w-4" />
          </button>
        )}
        <input
          className={twMerge(
            'h-10 w-full rounded-md px-4 py-3 pl-10 text-sm text-gray-450 ring-transparent focus:border-yellow-650 focus:ring-yellow-650',
            value.length > 0 ? 'pr-10' : '',
            error ? 'border-error bg-error/10' : 'border-blue-650/50 bg-white'
          )}
          value={value}
          onChange={e => {
            setValue(e.target.value);
            if (onUpdateValue) onUpdateValue(e.target.value);
          }}
          type="text"
        />
      </div>
      {error && (
        <span className="mt-1 font-nunito text-xs text-error">{error}</span>
      )}
    </div>
  );
}
