import styled from 'styled-components/macro';

export const ProcessFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RemoveReceipt = styled.button`
  height: 50px;
  border: 1px solid var(--caren-white);
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 5px;
  font-weight: bold;
  background-color: var(--caren-red);
  color: white;
`;
