import React from 'react';
import { momentUtcLocal } from '../../../../../utils/moment/momentHelpers';
import { Table } from '../../../../../v2/components/table';

const PlanTableList = (props) => {
  const { title, data, isInvoice, loading } = props;

  const renderPaymentStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Pendente';
      case 'paid':
        return 'Pago';
      case 'canceled':
        return 'Cancelado';
      case 'scheduled':
        return 'Agendado';
      case 'processing':
        return 'Processando';
      case 'failed':
        return 'Erro';
      case 'overpaid':
        return 'Pagto em excesso';
      case 'underpaid':
        return 'Pagto em falta';
      case 'chargedback':
        return 'Estornado';
      default:
        return '';
    }
  };

  const getPaymentStatusClassName = (status) => {
    switch (status) {
      case 'paid':
        return 'font-bold text-success';
      case 'pending':
      case 'scheduled':
      case 'processing':
        return 'font-bold text-warning';
      case 'failed':
      case 'canceled':
      case 'overpaid':
      case 'underpaid':
      case 'chargedback':
        return 'font-bold text-error';
      default:
        return 'font-bold';
    }
  };

  return (
    <>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.Cell.Header title={title} />
            {isInvoice && <Table.Cell.Header title="Plano" />}
            <Table.Cell.Header title="Valor" />
            <Table.Cell.Header title="Status" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!loading &&
            React.Children.toArray(
              data.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell.Text
                    text={momentUtcLocal(item.inserted_at).format('LLL')}
                  />
                  {isInvoice && (
                    <Table.Cell.Text text={item?.subscription?.plan?.name} />
                  )}
                  <Table.Cell.Text
                    text={(item?.amount / 100).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: item?.currency || 'BRL',
                    })}
                  />
                  <Table.Cell.Text
                    className={getPaymentStatusClassName(item?.status)}
                    text={renderPaymentStatus(item?.status)}
                  />
                </Table.Row>
              ))
            )}
          {loading && (
            <>
              <Table.Row>
                <Table.Cell.Loading />
                <Table.Cell.Loading />
              </Table.Row>
              <Table.Row>
                <Table.Cell.Loading />
                <Table.Cell.Loading />
              </Table.Row>
            </>
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
};

export default PlanTableList;
