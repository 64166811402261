import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';
import Header from '../../components/Header';
import { Container } from './signup.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import HomeSidebar from '../../components/Sidebar/HomeSidebar';
import { Store } from '../../store';
import history from '../../services/history';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PATIENT, ROLE_PROFESSIONAL } from '../../routes/constants';
import { sidebarClose } from '../../store/modules/Sidebar/actions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Signup() {
  const toast = useToast();
  const { persistSession, signOut } = useAuth();
  const [, dispatch] = useContext(Store);
  const location = useLocation();
  const query = useQuery();

  const comingFromInvitation =
    query.get('d') != null && // Doctor ID
    query.get('i') != null && // Invitation
    query.get('rt') != null && // Response token
    query.get('p') != null; // Partner key

  const {
    t,
    i18n: { language },
  } = useTranslation(['signup']);

  const TITLE = 'Cadastro - Caren';
  const IFRAME_SRC = combineURLs(
    import.meta.env.VITE_IFRAME_DOMAIN,
    `/signup?theme=telemed-patient&lng=${language}&em=${query.get(
      'em'
    )}&i=${query.get('i')}&rt=${query.get('rt')}`
  );
  const IFRAME_ORIGIN = new URL(import.meta.env.VITE_IFRAME_DOMAIN).origin;

  useEffect(() => {
    dispatch(sidebarClose());
  }, []);

  useEffect(() => {
    if (signOut) signOut();
  }, [signOut]);

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.origin !== IFRAME_ORIGIN) return;

      const { data: message } = event;

      switch (message.type) {
        case 'REDIRECTION': {
          const referrer = comingFromInvitation
            ? `/plano/${query.get('d')}${location.search}`
            : null;

          if (comingFromInvitation) {
            toast(
              t(
                'Para confirmar a sua consulta é preciso que faça o login em sua conta.'
              ),
              { variant: 'info' }
            );
          }

          history.push('/login', { referrer, noRedirect: true });
          return;
        }

        case 'LOGIN': {
          const { token, user_type: userType } = message;

          try {
            await persistSession({
              token,
              user_type:
                userType === 'patient' ? ROLE_PATIENT : ROLE_PROFESSIONAL,
            });

            if (userType === ROLE_PROFESSIONAL) {
              history.push('/consultorio');
              return;
            }

            if (comingFromInvitation) {
              history.push(`/plano/${query.get('d')}${location.search}`);
              return;
            }

            history.push('/consultas');
          } catch (error) {
            toast(t('Ops, estamos passando por instabilidades...'), {
              variant: 'error',
            });
          }
          break;
        }

        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <HomeSidebar />
      <Header leftAction={comingFromInvitation ? null : 'back'} />
      <Container>
        <iframe
          src={IFRAME_SRC}
          title={TITLE}
          style={{ flex: 1, width: '100%', border: 'none' }}
        />
      </Container>
    </>
  );
}
