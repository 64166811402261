import api from './api';

export const telemedStatsService = async () => {
  const { data } = await api.get('/employees/telemed-stats');

  return data;
};

export const telemedStatsCountService = async () => {
  const { data } = await api.get('/employees/new-telemed-stats');

  return data;
};

export const profileCompletenessService = async () => {
  const { data } = await api.get('/employees/profile-completeness');

  return data;
};
