import { type Doctor } from '../../../v2/entities/doctor';
import ImageOrName from '../../ImageOrName';
import { Card } from '../styles';

export default function DoctorTitleCard({ doctor }: { doctor: Doctor }) {
  return (
    <Card>
      <div className="profile-pic">
        <ImageOrName
          src={doctor.avatar_url}
          title={doctor?.name ?? 'Desconhecido'}
          titleSize={16}
          backgroundStyle={{
            width: 60,
            height: 60,
            borderRadius: '100%',
            backgroundColor: 'var(--caren-image-fallback)'
          }}
        />
      </div>
      <p className="profile-name">
        Estatísticas de {doctor?.name ?? 'Desconhecido'}
      </p>
    </Card>
  );
}
