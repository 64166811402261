import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Banner, PrimaryText, SecondText, BannerImage } from './home.styles';
import HomeSidebar from '../../components/Sidebar/HomeSidebar';
import ContainerOpacity from '../../components/ContainerOpacity';
import { enableHome } from '../../constants/featureToggles';

const TITLE = 'Home - Caren';

export default function Home() {
  const { t } = useTranslation(['home']);
  const bannerIndex = useMemo(() => Math.floor(Math.random() * 3), []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <HomeSidebar />
      <BannerImage
        index={bannerIndex}
        beginColor="rgba(2, 29, 16, 0.6)"
        endColor="rgb(104, 198, 178)"
      >
        <Header />
        <Banner>
          <PrimaryText>{t('Consultas Online')}</PrimaryText>
          <br />
          <SecondText>{t('Escolha seu médico')}</SecondText>
        </Banner>
        <Footer />
      </BannerImage>
      {!enableHome && <Redirect to="/login" />}
    </>
  );
}
