import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

const ReadOnlyClinicalEvaluationField = ({ field, clinicalEvaluation }) => {
  const value = clinicalEvaluation?.[field?.key];

  if (value == null) {
    return <Typography variant="body2">Não informado</Typography>;
  }

  switch (field?.type) {
    case 'text':
      return (
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {value}
        </Typography>
      );

    case 'mevo':
      return (
        <List dense>
          {value.map((entry) => (
            <ListItem>
              <ListItemText primary={field?.meta?.buildOptionLabel(entry)} />
            </ListItem>
          ))}
        </List>
      );

    case 'autocomplete':
      return (
        <List dense>
          {value.map((entry) => (
            <ListItem>
              <ListItemText primary={field?.meta?.buildOptionLabel(entry)} />
            </ListItem>
          ))}
        </List>
      );

    default:
      return null;
  }
};

export default ReadOnlyClinicalEvaluationField;
