import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoBlack } from '../../assets/images/logo-black.svg';

import { useToast } from '../../contexts/ToastContext';
import LoadingComponent from '../../components/Loading';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { useHistory, useLocation } from 'react-router-dom';
import { confirmEmail } from '../../v2/services/user';
import { AxiosError } from 'axios';
import { useAuth } from '../../hooks/AuthContext';
import { useProfileCompleteness } from '../../v2/hooks/user/useProfileCompleteness';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ConfirmEmail() {
  const { t } = useTranslation(['emailConfirmation']);
  const toast = useToast();
  const query = useQuery();
  const token = query.get('t') ?? '';
  const history = useHistory();
  const { credentials, refreshSession } = useAuth();
  const { mutate: profileCompletenessMutate } = useProfileCompleteness();

  const [loading, setLoading] = useState(false);

  async function handleConfirmEmail() {
    setLoading(true);

    try {
      await confirmEmail(token);

      if (credentials) {
        await refreshSession(credentials.token);
      }

      await profileCompletenessMutate();

      toast(t('E-mail confirmado com sucesso!'), {
        variant: 'success'
      });
      history.push('/login/profissional/doctor');
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;

        if (response?.status === 404) {
          toast(t('Seu e-mail já foi confirmado!'), {
            variant: 'error'
          });
          history.push('/login/profissional/doctor');
          setLoading(false);
          return;
        }

        if (response?.status === 401) {
          toast(t('Não foi possível confirmar o seu e-mail.'), {
            variant: 'error'
          });
          history.push('/login/profissional/doctor');
          setLoading(false);
          return;
        }
      }

      toast(
        t(
          'Não foi possível confirmar o seu e-mail, por favor tente novamente.'
        ),
        {
          variant: 'error'
        }
      );
      history.push('/login/profissional/doctor');
    }
    setLoading(false);
  }

  return (
    <>
      <Helmet title="Telessaúde Caren" />
      <div className="flex min-h-screen w-full flex-col bg-yellow-150">
        <div className="flex items-center justify-between px-4 pt-4 lg:px-16 lg:pt-10">
          <a href="https://www.caren.app/tele">
            <LogoBlack className="w-32" />
          </a>
        </div>
        <div className="lg:mt-22 mt-10 flex flex-1 flex-col items-center">
          <h1 className="max-w-[95%] text-balance text-center font-nunito text-xl sm:max-w-xl sm:text-2xl lg:text-3xl">
            Confirmação de e-mail
          </h1>
          <div className="mx-auto mb-8 mt-8 flex w-[512px] max-w-[95%] flex-col items-center justify-between space-y-8 rounded-xl bg-white px-8 py-8">
            <EnvelopeIcon className="h-20 w-20" />
            <h2 className="mb-4 flex w-full flex-col gap-y-5 text-center text-2xl sm:mb-8">
              Valide o seu e-mail e tenha acesso completo a plataforma de
              telessaúde Caren
            </h2>
            <button
              onClick={handleConfirmEmail}
              className="group flex h-14 w-full items-center justify-center gap-x-2 rounded-md bg-yellow-650 px-2.5 py-3 font-nunito  text-base font-bold transition-all duration-300 disabled:cursor-not-allowed"
            >
              {loading ? <LoadingComponent /> : t('Confirmar e-mail')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
