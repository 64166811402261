import { Female, Male } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { CardGrid, GridItem } from '../styles';
import { type Doctor } from '../../../v2/entities/doctor';
import { getDoctorTelemedStats } from '../../../services/secretary';
import { type Statistics } from '../../../v2/entities/statistics';
import getPercentage from '../../../utils/getPercentage';

export default function DoctorStatisticsGrid({ doctor }: { doctor: Doctor }) {
  const [stats, setStats] = useState<Statistics | null>(null);

  useEffect(() => {
    getDoctorTelemedStats(doctor.key).then(result => {
      setStats(result);
    });
  }, []);

  return (
    <CardGrid>
      {stats?.patients_count && (
        <GridItem>
          <p className="title">Meus pacientes</p>
          <div className="bottom">
            <p className="count">{stats.patients_count.total_patients}</p>
            <div className="count-mid-block">
              <div className="count-item female">
                <Female />
                <p>Mulheres</p>
                <p>
                  {getPercentage(
                    stats.patients_count.female_patients,
                    stats.patients_count.total_patients
                  )}
                  %
                </p>
              </div>
              <div className="count-item male">
                <Male />
                <p>Homens</p>
                <p>
                  {getPercentage(
                    stats.patients_count.male_patients,
                    stats.patients_count.total_patients
                  )}
                  %
                </p>
              </div>
            </div>
          </div>
        </GridItem>
      )}
      {stats?.teleconsultations && (
        <GridItem>
          <p className="title">Consultas criadas</p>
          <div className="bottom">
            <p className="count">
              {stats.teleconsultations.total}{' '}
              <span className="count-text">consultas</span>
            </p>
          </div>
        </GridItem>
      )}

      {stats?.teleconsultations && (
        <GridItem>
          <p className="title">Meus dados</p>
          <div className="bottom">
            <p className="count">
              {stats.profile_completeness ? stats.profile_completeness : '0'}{' '}
              <span className="count-text">%</span>
            </p>
            <div className="count-mid-block">
              <p className="completed">Completo</p>
            </div>
          </div>
        </GridItem>
      )}
      {stats?.teleconsultations && (
        <GridItem>
          <p className="title">Consultas realizadas</p>
          <div className="bottom">
            <p className="count">
              {stats.teleconsultations.concluded}{' '}
              <span className="count-text">consultas</span>
            </p>
          </div>
        </GridItem>
      )}
    </CardGrid>
  );
}
