import React from 'react';

import logoImg from '../../assets/images/logo.png';
import { Image } from './logo.styles';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PATIENT, ROLE_PROFESSIONAL } from '../../routes/constants';

export default function Logo(props) {
  const {
    credentials,
  } = useAuth();

  function HandleLogo() {
    let logo = logoImg;

    if (credentials && credentials.token) {
      if (credentials.token.user_type === ROLE_PATIENT) {
        logo = logoImg;
      }

      if (credentials.token.user_type === ROLE_PROFESSIONAL) {
        logo = logoImg;
      }
    }

    return <Image src={logo} alt="Logo Caren.App" {...props} />;
  }

  return <HandleLogo />;
}
