import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { } from '@heroicons/react/24/solid';

import { AxiosError } from 'axios';
import Modal from '../index';
import { Wrapper, ConfirmationButton } from './styles';
import { sendTeamInvitation } from '../../../services/team';
import { UsersIcon } from '@heroicons/react/20/solid';

const invitationTeamFormSchema = z.object({
  email: z
    .string({
      invalid_type_error: 'Digite um e-mail válido',
      required_error: 'Digite um e-mail'
    })
    .email('Digite um e-mail válido')
    .toLowerCase()
});

type InvitationTeamFormData = z.infer<typeof invitationTeamFormSchema>;

type TeamInviteModalProps = {
  open: boolean
  onClose: (success?: boolean) => void
}

export default function TeamInviteModal({
  open,
  onClose
}: TeamInviteModalProps) {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors }
  } = useForm<InvitationTeamFormData>({
    resolver: zodResolver(invitationTeamFormSchema),
    mode: 'onChange'
  });

  const emailText = useWatch({ control, name: 'email', defaultValue: '' });

  const onSubmit = async (data: InvitationTeamFormData) => {
    setLoading(true);

    try {
      await sendTeamInvitation(data.email);

      onClose(true);

      reset();

      setLoading(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 422) {
          setError('email', {
            message: 'O e-mail já faz parte da sua equipe!',
            type: 'value'
          });

          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <Modal
      handleOpenModal={() => {
        reset();
        onClose();
      }}
      openModal={open}
      modalTitle={
        <div className="flex flex-col items-center">
          <UsersIcon className="w-8 h-8 mb-4" />
          <span>
            Preencha abaixo o e-mail do novo integrante de sua equipe:{' '}
          </span>
        </div>
      }
      modalTitleColor="black"
      modalWidth="760px"
    >
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <input
          type="email"
          placeholder="Digite aqui o e-mail para enviar o convite"
          autoComplete="none"
          {...register('email')}
        />
        {errors && errors.email && <span>{errors.email.message}</span>}
        <ConfirmationButton
          type="submit"
          disabled={
            loading ||
            emailText === undefined ||
            emailText.length <= 0 ||
            errors.email !== undefined
          }
        >
          {loading ? 'ENVIANDO...' : 'CONFIRMAR'}
        </ConfirmationButton>
      </Wrapper>
    </Modal>
  );
}
