import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  ModalIcon,
  ModalVidaasIcon,
  ModalProcessIcon,
  ModalSuccessIcon,
  ActionButton,
  Loader
} from './styles';
import Modal from '../Modal';
import history from '../../services/history';
import { useAuth } from '../../hooks/AuthContext';
import { vidaasUserDiscovery } from '../../services/vidaas-user-discovery.service';
import { useToast } from '../../contexts/ToastContext';
import AutoRefresher from '../AutoRefresher';
import { authorize } from '../../services/vidaas-authorize.service';
import { NotificationsActive, QrCode } from '@mui/icons-material';

const REQUEST_INTERVAL_SECONDS = 60;

const DigitalSignatureDialog = props => {
  const {
    open,
    toggleDialog,
    reportStatus,
    refreshReportStatus,
    teleconsultationKey
  } = props;

  const { t } = useTranslation(['common']);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [redirectUri, setRedirectUri] = useState(null);
  const [secondsUntilNextRequest, setSecondsUntilNextRequest] = useState(0);
  const [requestTimestamp, setRequestTimestamp] = useState(null);
  const [userDiscoveryFiscalCode, setUserDiscoveryFiscalCode] = useState(null);
  const [userDiscovery, setUserDiscovery] = useState(null);

  const { credentials } = useAuth();

  useEffect(() => {
    async function getVidaasUserDiscovery() {
      try {
        setLoading(true);
        const { data } = await vidaasUserDiscovery();

        setUserDiscoveryFiscalCode(credentials.session.fiscal_code);
        setUserDiscovery(data);
        setLoading(false);
      } catch (error) {
        toast(t('Não foi possível fazer a busca de CPF no VIDaaS.'), {
          variant: 'error'
        });
      }
    }

    if (
      open &&
      reportStatus === 'signature_pending' &&
      credentials &&
      credentials.session.fiscal_code !== userDiscoveryFiscalCode
    ) {
      getVidaasUserDiscovery();
    }
  }, [open, reportStatus, credentials]);

  function timeUntilNextRequest() {
    if (!requestTimestamp) return 0;

    return Math.max(
      Math.trunc(
        REQUEST_INTERVAL_SECONDS -
          moment().diff(requestTimestamp, 'seconds', true)
      ),
      0
    );
  }

  function refreshSecondsUntilNextRequest() {
    setSecondsUntilNextRequest(timeUntilNextRequest());
  }

  useEffect(() => {
    refreshSecondsUntilNextRequest();
  }, [requestTimestamp]);

  const requestSignature = async mode => {
    if (timeUntilNextRequest() > 0) {
      return;
    }

    setRedirectUri(null);

    try {
      setLoading(true);
      setRequestTimestamp(moment());
      const response = await authorize(teleconsultationKey, mode);

      if (response?.redirect_uri) {
        setRedirectUri(response?.redirect_uri);
        window.open(response?.redirect_uri, '_blank');
      }

      setLoading(false);
    } catch (error) {
      toast(t('Não foi possível solicitar a assinatura no VIDaaS.'), {
        variant: 'error'
      });
      setLoading(false);
    }
  };

  const onClickRedirect = () => {
    history.push('/consultorio');
  };

  const renderTitle = () => {
    switch (reportStatus) {
      case 'signature_pending':
      case 'signature_in_progress':
        return t('Assinatura Digital');

      default:
        return t('Conclusão');
    }
  };

  const renderStep = () => {
    if (loading) {
      return (
        <>
          <ModalIcon />
          <Loader />
        </>
      );
    }

    switch (reportStatus) {
      case 'signature_pending':
        if (!userDiscovery?.has_certificates) {
          return (
            <>
              <ModalVidaasIcon />
              <p>
                {t(
                  'Para concluir a consulta, é necessário assiná-la digitalmente com o seu '
                )}
                <b>{t('Certificado ICP-Brasil em nuvem (VIDaaS)')}</b>.
              </p>
              <br />
              <p>
                {t(
                  'Não foram detectados certificados digitais VIDaaS para o CPF cadastrado.'
                )}
              </p>
              <p>
                {t('Verifique se o seu CPF informado está correto em ')}
                <b>{t('Meus Dados.')}</b>
              </p>
              <ActionButton onClick={toggleDialog}>{t('Fechar')}</ActionButton>
            </>
          );
        }

        return (
          <>
            {requestTimestamp == null ? (
              <ModalVidaasIcon />
            ) : (
              <ModalProcessIcon />
            )}
            {requestTimestamp == null ? (
              <>
                <p>
                  {t(
                    'Para concluir a consulta, é necessário assiná-la digitalmente com o seu '
                  )}
                  <b>{t('Certificado ICP-Brasil em nuvem')}</b>.
                </p>
                <br />
                <p>
                  {t('Tenha em mãos o seu ')}
                  <b>{t('aplicativo VIDaaS')}</b>
                  {t(' com um certificado vinculado ao seu CPF.')}
                </p>
                <br />
                <p>
                  {t(
                    'Para prosseguir, podemos te enviar uma notificação que solicitará a confirmação da assinatura. Caso isto não funcione, você também tem a opção de assinar escaneando um código QR pelo aplicativo.'
                  )}
                </p>
              </>
            ) : (
              <>
                <p>
                  {redirectUri == null
                    ? t('Solicitação enviada para o seu aplicativo VIDaaS!')
                    : 'QR Code gerado! Caso não tenha sido redirecionado, clique no botão abaixo.'}
                </p>
                <p>
                  {redirectUri == null
                    ? t(
                        'Agora basta seguir as instruções pelo aplicativo para aprovar a assinatura.'
                      )
                    : 'Lembre-se de escanear utilizando o app VIDaaS e não o app de Câmera do seu celular!'}
                </p>
              </>
            )}
            {redirectUri && secondsUntilNextRequest > 0 ? (
              <ActionButton
                onClick={async () => {
                  window.open(redirectUri, '_blank');
                }}
              >
                {t('Abrir QR CODE')}
              </ActionButton>
            ) : null}
            <ActionButton
              startIcon={
                secondsUntilNextRequest > 0 ? undefined : (
                  <NotificationsActive />
                )
              }
              disabled={secondsUntilNextRequest > 0}
              onClick={async () => {
                await requestSignature('push');
              }}
            >
              {secondsUntilNextRequest > 0 ? (
                <span>
                  {t('Aguarde para nova tentativa')}
                  {` (${secondsUntilNextRequest} seg.)`}
                </span>
              ) : (
                t('Assinar via notificação')
              )}
            </ActionButton>
            {secondsUntilNextRequest > 0 ? null : (
              <ActionButton
                startIcon={<QrCode />}
                disabled={secondsUntilNextRequest > 0}
                onClick={async () => {
                  await requestSignature('qr');
                }}
              >
                <span>{t('Assinar via QR CODE')}</span>
                <span className="ml-4 font-bold">{t('NOVO!')}</span>
              </ActionButton>
            )}
            <AutoRefresher
              callback={refreshSecondsUntilNextRequest}
              delay={1000}
            />
          </>
        );

      case 'signature_in_progress':
        return (
          <>
            <ModalProcessIcon />
            <p>{t('Assinando...')}</p>
          </>
        );

      case 'report_pending':
        return (
          <>
            <ModalProcessIcon />
            <p>{t('Gerando relatório...')}</p>
          </>
        );

      case 'event_pending':
        return (
          <>
            <ModalProcessIcon />
            <p>{t('Registrando...')}</p>
          </>
        );

      case 'sync_pending':
        return (
          <>
            <ModalProcessIcon />
            <p>{t('Sincronizando...')}</p>
          </>
        );

      case 'synced':
      case 'event_created':
        return (
          <>
            <ModalSuccessIcon />
            <p>{t('Tudo pronto! A teleconsulta foi concluída com sucesso.')}</p>
            <ActionButton onClick={onClickRedirect}>{t('Home')}</ActionButton>
          </>
        );

      default:
        return (
          <>
            <ModalProcessIcon />
            <p>{t('Concluindo...')}</p>
            <ActionButton onClick={toggleDialog}>{t('Fechar')}</ActionButton>
          </>
        );
    }
  };

  return (
    <Modal
      handleOpenModal={toggleDialog}
      openModal={open}
      modalTitle={renderTitle()}
      modalWidth="40%"
      modalTitleColor="black"
      containerStyles={{ textAlign: 'center' }}
    >
      {renderStep()}
      {open &&
        reportStatus &&
        (requestTimestamp != null || reportStatus !== 'signature_pending') && (
          <AutoRefresher callback={refreshReportStatus} delay={5000} />
        )}
    </Modal>
  );
};

export default DigitalSignatureDialog;
