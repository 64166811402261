import React, { type InputHTMLAttributes } from 'react';

type CheckboxInputProps = {
  children: React.ReactNode;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ children, error, ...rest }, ref) => {
    return (
      <div className="relative flex w-full flex-col items-start">
        <p className="flex items-start text-sm text-gray-350">
          <input
            ref={ref}
            data-error={error !== undefined}
            className="mr-2 h-5 w-5 rounded-md border-blue-650/50 bg-white text-yellow-750 ring-transparent focus:ring-transparent data-[error=true]:border-error data-[error=true]:bg-error/10"
            {...rest}
          />
          {children}
        </p>
        {error && (
          <span className="mt-1 font-nunito text-xs text-error">{error}</span>
        )}
      </div>
    );
  }
);

CheckboxInput.displayName = 'CheckboxInput';
export default CheckboxInput;
