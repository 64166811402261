import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { ExtraErrorData, CaptureConsole } from '@sentry/integrations';
import { StrictMode } from 'react';
import App from './App';
import moment from 'moment';
import 'moment/dist/locale/pt-br';
import './i18n';

import './index.css';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing(),
    new ExtraErrorData({
      depth: 5
    }),
    new CaptureConsole({
      levels: ['error']
    }),
    new Sentry.Replay()
  ],
  ignoreErrors: ['/notifications/unread - Request failed with status code 403'],
  normalizeDepth: 5,
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.75
});

moment.locale('pt-br');

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <div>
          <p>
            Um erro ocorreu ao exibir esta página, por favor tente atualizá-la.
          </p>
          <p>
            Nossa equipe já foi notificada e estamos trabalhando numa solução.
          </p>
        </div>
      }
    >
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root')
);
