import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { setUser } from '@sentry/react';
import { useToast } from '../contexts/ToastContext';
import {
  sessionService,
  signIn as signInService,
  signOut as signOutService
} from '../services/session';
import { type Session, type Token } from '../v2/entities/session';
import { AxiosError, type AxiosResponse } from 'axios';

type AuthContextValues = {
  credentials: {
    token: Token;
    session: Session;
  } | null;
  signIn: ({
    login,
    password,
    recaptchaResponse
  }: {
    login: string;
    password: string;
    recaptchaResponse: string | null;
  }) => Promise<{
    error: boolean;
    response?: AxiosResponse | null;
  }>;
  signOut: () => Promise<void>;
  persistSession: (token: Token) => Promise<void>;
  reloadSession: () => void;
  refreshSession: (token: Token) => Promise<void>;
  clearSession: () => void;
}

const AuthContext = createContext<AuthContextValues | null>(null);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['common']);
  const toast = useToast();

  const loadSessionFromStorage = () => {
    const storedSession = localStorage.getItem('@Caren:session');
    const storedToken = localStorage.getItem('@Caren:token');

    if (!storedSession || !storedToken) {
      return null;
    }

    const session = JSON.parse(storedSession);
    const token = JSON.parse(storedToken);

    if (token && session) {
      return { ...token, ...session };
    }

    return null;
  };

  const [authData, setAuthData] = useState<{
    token: Token;
    session: Session;
  } | null>(loadSessionFromStorage);
  const [requestedCachedSession, setRequestedCachedSession] = useState(false);

  const reloadSession = useCallback(() => {
    setAuthData(loadSessionFromStorage());
  }, [setAuthData, loadSessionFromStorage]);

  const refreshSession = useCallback(async (token: Token) => {
    try {
      localStorage.setItem(
        '@Caren:token',
        JSON.stringify({ token, ts: Date.now().toString() })
      );
      const { data: session } = await sessionService();

      if (!session) throw Error('Session Error');

      localStorage.setItem(
        '@Caren:session',
        JSON.stringify({ session: { key: session.key, cached: true } })
      );

      setAuthData({ token, session });
    } catch (e) {
      toast('Não foi possível atualizar a sessão, tente novamente!', {
        variant: 'error'
      });
    }
  }, []);

  const persistSession = useCallback(async (token: Token) => {
    try {
      localStorage.setItem(
        '@Caren:token',
        JSON.stringify({ token, ts: Date.now().toString() })
      );
      const { data: session } = await sessionService();

      if (!session) throw Error('Session Error');

      localStorage.setItem(
        '@Caren:session',
        JSON.stringify({ session: { key: session.key, cached: true } })
      );

      setAuthData({ token, session });
      // Update Sentry metadata
      setUser({
        id: session.key,
        segment: token.user_type
      });

      toast(`Que bom ver você aqui, ${session.name}!`, {
        variant: 'success'
      });
    } catch (e) {
      toast(t('Não foi possível atualizar a sessão, tente novamente!'), {
        variant: 'error'
      });
    }
  }, []);

  const clearSession = useCallback(() => {
    localStorage.removeItem('@Caren:token');
    localStorage.removeItem('@Caren:session');
    localStorage.removeItem('doctor-profile-tab');
    localStorage.removeItem('doctor-emergency-insurance-code');

    setAuthData(null);
  }, [setAuthData]);

  const signOut = useCallback(async () => {
    try {
      await signOutService();
    } catch (error) { }

    clearSession();
  }, [clearSession]);

  const loadCachedSession = useCallback(async () => {
    if (!requestedCachedSession && authData && authData.session.cached) {
      setRequestedCachedSession(true);

      try {
        const { data: session } = await sessionService();

        if (session.key !== authData.token.user_key) {
          throw new Error('Mismatched user key');
        }

        setAuthData(current => {
          if (current === null) throw Error('Current Session is Empty');

          return { ...current, session };
        });
      } catch (error) {
        signOut();
      }
    }
  }, [requestedCachedSession, authData, signOut]);

  const signIn = useCallback(
    async ({
      login,
      password,
      recaptchaResponse
    }: {
      login: string;
      password: string;
      recaptchaResponse: string | null;
    }) => {
      clearSession();
      try {
        const token = await signInService(login, password, recaptchaResponse);
        if (token) {
          await persistSession(token);
          return { error: false };
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          return { error: true, response: error.response };
        }

        return { error: true, response: null };
      }
      return { error: true, response: null };
    },
    []
  );

  useEffect(() => {
    loadCachedSession();
  }, [loadCachedSession]);

  return (
    <AuthContext.Provider
      value={{
        credentials: authData,
        signIn,
        signOut,
        persistSession,
        reloadSession,
        refreshSession,
        clearSession
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
