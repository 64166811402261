import React, { useEffect, useState } from 'react';
import { RiCoupon3Line } from 'react-icons/ri';
import { Helmet } from 'react-helmet-async';
import { IoIosAddCircle } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import { BackButton } from '../../components/BackButton';
import {
  Container,
  FirstGrid,
  SecondGrid,
  ResponseContainer,
  SubHeaderContainer,
  AddButtonContainer,
  TitlePage,
  VoucherButton,
} from './styles';

import Schedules from './Schedules';
import Header from '../../components/Header';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import ContainerOpacity from '../../components/ContainerOpacity';
import { doctorGetInvitationsService } from '../../services/doctors.service';

import LoadingComponent from '../../components/Loading';

import DoctorPanel from '../../components/DoctorPanel';
import CreateConsultDialog from '../../v2/dialogs/consult/create';

export default function DoctorScheduling() {
  const [doctorInvitations, setDoctorInvitations] = useState([]);
  const createConsultDialogRef = useRef();

  const { t } = useTranslation(['doctor_schedules']);

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const TITLE = 'Meus agendamentos - Caren';

  useEffect(() => {
    async function getDoctorInvitationsService() {
      setLoading(true);
      try {
        const { data: invitationData } = await doctorGetInvitationsService();

        setDoctorInvitations([...invitationData]);
      } catch (error) {
        toast(t('Ops, não foi possível carregar os agendamentos...'), {
          variant: 'error',
        });
      }
      setLoading(false);
    }

    getDoctorInvitationsService();
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar bgColor="var(--caren-greenblue)" />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel inviteFriend />
        </FirstGrid>
        <SecondGrid>
          <SubHeaderContainer>
            <AddButtonContainer>
              <VoucherButton
                onClick={() => createConsultDialogRef.current.open()}
              >
                <IoIosAddCircle
                  size={40}
                  color="var(--caren-greenblue)"
                  cursor="pointer"
                />
                <span>{t('Enviar Convite')}</span>
              </VoucherButton>
            </AddButtonContainer>
            <BackButton path="/consultorio" title={t('voltar')} />
            <TitlePage>{t('Meus convites')}</TitlePage>
          </SubHeaderContainer>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <ResponseContainer>
              <LoadingComponent size={80} color="var(--caren-greenblue)" />
            </ResponseContainer>
          ) : doctorInvitations.length ? (
            <Schedules
              doctorInvitations={doctorInvitations}
              setDoctorInvitations={setDoctorInvitations}
            />
          ) : (
            <ResponseContainer>
              <div>
                <p>{t('Nenhum agendamento até o momento.')}</p>
                <RiCoupon3Line size={60} color="var(--caren-icongrey)" />
              </div>
            </ResponseContainer>
          )}
        </SecondGrid>
      </Container>

      <CreateConsultDialog ref={createConsultDialogRef} />
    </>
  );
}
