import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useToast } from '../../../contexts/ToastContext';
import UnreversibleConfirmationModal from '../../../components/UnreversibleConfirmationModal';
import {
  CustomTable,
  TableHeaderColumn,
  TableColumn,
  TableRow,
  PatientBox,
  PatientImage,
  PatientName,
  TableColumnText,
  UploadIcon,
  UploadIconEvent,
  ReceiptImage,
  ButtonsContainer,
  DenyButton,
  ApproveButton,
  ApprovedIcon,
  RefusedIcon,
} from './table.styles';
import Modal from '../../../components/Modal';
import DownloadOverlay from '../../../components/DownloadOverlay';
import { updateReceipt } from '../../../services/schedule-process.service';
import { FORMAT } from '../../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../../utils/moment/momentHelpers';

function PatientReceipts({ doctorReceipts }) {
  const toast = useToast();
  const { t } = useTranslation(['doctor_receipts']);
  const [openModal, setOpenModal] = useState(false);
  const [receiptImage, setReceiptImage] = useState('');
  const [clickedReceipt, setClickedReceipt] = useState({});
  const [allowButton, setAllowButton] = useState(false);
  const [modalTitle, setModalTitle] = useState('Comprovante');

  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [receiptStatus, setReceiptStatus] = useState('');
  const [receipts, setReceipts] = useState([...doctorReceipts]);

  function formatHour(date) {
    return momentUtcLocal(date).format(FORMAT['pt-datetime']);
  }

  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  function handleOpenReceipt(receipt) {
    if (!receipt.approved_at && !receipt.rejected_at) {
      setClickedReceipt(receipt);
      setOpenModal(!openModal);
      setReceiptImage(receipt.url);
      setAllowButton(true);
      setModalTitle(t('Verificar Comprovante'));

      return;
    }

    setClickedReceipt(receipt);
    setOpenModal(!openModal);
    setReceiptImage(receipt.url);
    setModalTitle(t('Comprovante'));
    setAllowButton(false);
  }

  function handleUnreversibleAction(status) {
    setOpenUnreversible(true);
    setReceiptStatus(status);
  }

  async function handleUpdateReceipt(state) {
    setOpenModal(false);
    try {
      const receiptUpdated = await updateReceipt(
        clickedReceipt.teleconsultation_key,
        clickedReceipt.key,
        state
      );

      const filteredReceipts = receipts.filter(
        (eachReceipt) => eachReceipt.key !== receiptUpdated.key
      );
      setReceipts([...filteredReceipts, { ...receiptUpdated }]);

      let toastText = '';
      if (state === 'approved') {
        toastText = t('Comprovante aprovado com sucesso!');
      } else {
        toastText = t('Comprovante negado com sucesso!');
      }

      toast(toastText, {
        variant: 'success',
      });
    } catch (error) {
      // Not shown
    }
  }

  function handleConfirmation(status) {
    if (status) {
      handleUpdateReceipt(receiptStatus);
    }
    setOpenUnreversible(false);
  }

  function HandleIcons({ receipt: { approved_at, rejected_at } }) {
    if (approved_at) return <ApprovedIcon />;
    if (rejected_at) return <RefusedIcon />;

    return <UploadIcon />;
  }

  function HandleValidateButtons() {
    if (allowButton) {
      return (
        <ButtonsContainer>
          <DenyButton onClick={() => handleUnreversibleAction('rejected')}>
            {t('Negar')}
          </DenyButton>
          <ApproveButton onClick={() => handleUnreversibleAction('approved')}>
            {t('Aprovar')}
          </ApproveButton>
        </ButtonsContainer>
      );
    }

    return null;
  }

  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            <TableHeaderColumn>{t('PACIENTE')}</TableHeaderColumn>
            <TableHeaderColumn>{t('CPF')}</TableHeaderColumn>
            <TableHeaderColumn>{t('CONSULTA')}</TableHeaderColumn>
            <TableHeaderColumn align="center">
              {t('COMPROVANTE')}
            </TableHeaderColumn>
          </tr>
        </thead>
        <tbody>
          {receipts?.map((receipt) => (
            <TableRow key={receipt.key}>
              <TableColumn>
                <PatientBox>
                  <PatientImage
                    imgUrl={receipt.patient.avatar_url}
                    title={receipt.patient.name}
                    titleSize={20}
                    backgroundStyle={{
                      width: 50,
                      height: 50,
                      borderRadius: '50%',
                      backgroundColor: 'var(--caren-image-fallback)',
                    }}
                  />
                  <PatientName>{receipt.patient.name}</PatientName>
                </PatientBox>
              </TableColumn>
              <TableColumn>
                <TableColumnText>{receipt.patient.fiscal_code}</TableColumnText>
              </TableColumn>
              <TableColumn>
                <TableColumnText>
                  {formatHour(receipt.scheduled_datetime)}
                </TableColumnText>
              </TableColumn>
              <TableColumn align="center">
                <UploadIconEvent onClick={() => handleOpenReceipt(receipt)}>
                  <HandleIcons receipt={receipt} />
                </UploadIconEvent>
              </TableColumn>
            </TableRow>
          ))}
        </tbody>
      </CustomTable>
      <Modal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        modalTitle={modalTitle}
        modalWidth="40%"
        modalTitleColor="var(--caren-icongrey)"
      >
        <DownloadOverlay url={receiptImage}>
          <ReceiptImage imgUrl={receiptImage} />
        </DownloadOverlay>
        <HandleValidateButtons />
      </Modal>
      <UnreversibleConfirmationModal
        title={t('UnreversibleConfirmationModal title')}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
}
export default PatientReceipts;
