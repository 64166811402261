import { z } from 'zod';
import { SecretarySchema } from './secretary';
import { DoctorSchema } from './doctor';

const InviteSchema = z.object({
  key: z.string(),
  email: z.string().email(),
  accepted_at: z.string().nullable(),
  rejected_at: z.string().nullable(),
  secretary: SecretarySchema.nullable(),
  employee: DoctorSchema.pick({
    name: true,
    key: true
  })
});

type Invite = z.infer<typeof InviteSchema>;

export { InviteSchema };
export type { Invite };
