import styled from 'styled-components/macro';

export const Container = styled.div<{ heightArea: string }>`
  margin: 10px 40px;
  height: ${({ heightArea }) => heightArea};

  div {
    text-align: center;
  }

  p {
    color: var(--caren-white);
    font-size: 16px;
    text-align: center;
  }

  form button {
    background-color: var(--caren-lightgreen);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    color: var(--caren-white);
    font-weight: bold;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form p {
    color: var(--caren-white);
    margin-top: 15px;
    font-size: 13px;
    text-align: center;
  }
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
