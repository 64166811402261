import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { List, BulletList, Code } from 'react-content-loader';
import { useToast } from '../../contexts/ToastContext';
import Modal from '../../components/Modal';
// import PaymentModal from '../../components/Modal/PaymentModal';
import Header from '../../components/Header';
// import Switch from './switch';
import {
  FindADoctor,
  Container,
  FirstGrid,
  SecondGrid,
  DoctorCard,
  DoctorImage,
  DoctorImageContainer,
  DoctorName,
  DoctorSpec,
  DoctorNumber,
  BorderGrey,
  DoctorSpecifications,
  DoctorTime,
  DoctorPrice,
  DoctorPaymentMethods,
  DoctorBio,
  DoctorSkills,
  DoctorSpecialties,
  DoctorSeeMore,
  DatePickerText,
  DoctorDate,
  DoctorHours,
  DoctorHoursHeader,
  DoctorHoursBox,
  DoctorInsuranceIcon as _DoctorInsuranceIcon,
  ModalSubtitle,
  Input,
  Textarea,
  ModalButton,
  Form,
  DoctorPickerContainer,
  SeeMore,
  PayContainerOpacity,
  NoAvailabilityStyled,
} from './doctor-details.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import PayBar from '../../components/PayBar';
import { doctorDetailsService } from '../../services/doctors.service';
import history from '../../services/history';
import useCurrentHour from '../../hooks/currentHour.hook';

import { Store } from '../../store';
import { setDoctorStore } from '../../store/modules/Doctor/actions';
import CustomCalendar from '../../components/Calendar';
import TimezoneAndHour from '../../components/TimezoneAndHour';
import getDoctorTitle from '../../utils/getDoctorTitle';
import { BackButton } from '../../components/BackButton';
import { DisplayFlex } from '../../components/DisplayFlex';
import { FORMAT } from '../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PROFESSIONAL } from '../../routes/constants';
import ImageOrName from '../../components/ImageOrName';
import HealthInsuranceSelectorDialog from '../../components/HealthInsuranceSelectorDialog';

export default function DoctorDetails({
  computedMatch: {
    params: { id },
  },
}) {
  const {
    credentials,
  } = useAuth();

  const { i18n, t } = useTranslation(['doctor_details']);

  const toast = useToast();
  const [doctor, setDoctor] = useState(null);
  const [insurancePartners, setinsurancePartners] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const currentHourState = useCurrentHour();
  const [, dispatch] = useContext(Store);
  const [type, _setType] = useState('default');
  const TITLE = 'Detalhes do médico - Caren';

  useEffect(() => {
    async function getDoctorDetailService() {
      try {
        const { data } = await doctorDetailsService(id);
        dispatch(setDoctorStore(data));
        setDoctor(data);
        setinsurancePartners(data.insurance_partners);
        setPaymentMethods(data.payment_methods);
        if (data.slug && data.slug !== id) {
          history.replace({ pathname: `/medicos/${data.slug}` });
        }
      } catch (error) {
        toast(t('Ops, não foi possível carregar os dados deste médico.'), {
          variant: 'error',
        });
      }
    }

    if (id === doctor?.slug) return; // Already loaded
    if (id) getDoctorDetailService();
  }, [dispatch, id]);

  const [slotCards, setSlotCards] = useState({ loading: true });

  const [openModal, setOpenModal] = useState(false);
  const [openSeeMoreModal, setOpenSeeMoreModal] = useState(false);

  function handleToggleModal() {
    setOpenModal(!openModal);
  }

  function handleOpenSeeMoreModal() {
    setOpenSeeMoreModal(!openSeeMoreModal);
  }

  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [choosedHour, setChoosedHour] = useState('');

  function handlePaymenthMethod(hour) {
    setChoosedHour(() => hour);
    if (credentials && credentials.token.user_type !== ROLE_PROFESSIONAL) setOpenPaymentMethod(true);
  }

  function handleHealthPlan(partner) {
    const params = new URLSearchParams({
      sd: choosedHour,
      t: type,
      p: partner.key,
    });

    history.push(`/plano/${id}?${params.toString()}`);
  }

  function handleVoucher() {
    const params = new URLSearchParams({
      sd: choosedHour,
      t: type,
    });

    history.push(`/voucher/${id}?${params.toString()}`);
  }

  function NoAvailability() {
    return (
      <NoAvailabilityStyled>
        <p>
          {slotCards?.loading
            ? t('Carregando...')
            : t('Não há horários disponíveis para esta data.')}
        </p>
      </NoAvailabilityStyled>
    );
  }

  function showDoctorSpecialties(specialties) {
    if (!specialties) {
      return null;
    }
    if (specialties.length > 1) {
      return (
        <DoctorSpec>
          {specialties[0].name} +{specialties.length - 1}
        </DoctorSpec>
      );
    }
    return (
      <DoctorSpec>{specialties?.map(({ name }) => name).join(', ')}</DoctorSpec>
    );
  }

  function healthInsuranceDisabled() {
    return !doctor?.payment_methods.insurance_partner;
  }

  function voucherDisabled() {
    if (doctor?.phone && doctor?.payment_methods.voucher) {
      return false;
    }

    return true;
  }

  function showInsurancePartners() {
    if (paymentMethods.insurance_partner) {
      return insurancePartners.map((Partner) => (
        <div key={Partner.code}>
          <ImageOrName
            src={Partner.icon}
            title={Partner.name}
            titleSize={35}
            backgroundStyle={{
              width: 35,
              height: 35,
              borderRadius: '100%',
              backgroundColor: 'var(--caren-image-fallback)',
            }}
          />
        </div>
      ));
    }
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <FindADoctor>
        <DisplayFlex
          customStyles={{ marginLeft: 10 }}
          justify="center"
          align="center"
        >
          <BackButton path="/consultas" />
          <p>{t('Médico')}</p>
        </DisplayFlex>
        <p>{currentHourState}</p>
      </FindADoctor>
      <Container>
        <FirstGrid>
          <DoctorCard>
            <DoctorImageContainer>
              <DoctorImage
                imgUrl={doctor?.avatar_url}
                title={doctor?.name}
                titleSize={50}
                backgroundStyle={{
                  width: 140,
                  height: 140,
                  borderRadius: 140,
                  backgroundColor: 'var(--caren-image-fallback)',
                }}
              />
            </DoctorImageContainer>
            {doctor === null ? (
              <List backgroundColor="var(--caren-image-fallback)" />
            ) : (
              <div>
                <DoctorName>
                  {getDoctorTitle(doctor)} {doctor?.name}
                </DoctorName>
                {showDoctorSpecialties(doctor?.specialties)}
                <DoctorNumber>{doctor?.professional_code}</DoctorNumber>
                <SeeMore onClick={() => setOpenSeeMoreModal(true)}>
                  {t('Ver perfil')}
                </SeeMore>
              </div>
            )}
            <Modal
              handleOpenModal={handleOpenSeeMoreModal}
              openModal={openSeeMoreModal}
              modalTitle={`${t('Mais de Dr')} ${doctor?.name}`}
              modalWidth="40%"
            >
              <DoctorNumber>{doctor?.professional_code}</DoctorNumber>
              <DoctorImageContainer style={{ textAlign: 'center' }}>
                <DoctorImage
                  imgUrl={doctor?.avatar_url}
                  title={doctor?.name}
                  titleSize={50}
                  backgroundStyle={{
                    height: 140,
                    borderRadius: 140,
                    backgroundColor: 'var(--caren-image-fallback)',
                  }}
                />
              </DoctorImageContainer>
              <DoctorBio>
                <p>{t('Currículo Resumido')}:</p>
                <span>
                  <p>{doctor?.bio}</p>
                  <DoctorSeeMore>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {/* <a href="#">Veja mais</a> */}
                  </DoctorSeeMore>
                </span>
              </DoctorBio>
              <DoctorSkills>
                <p>{t('Especialidades')}:</p>
                <ul>
                  {doctor?.specialties?.map((specialty) => (
                    <DoctorSpecialties key={specialty.key}>
                      {specialty.name}
                    </DoctorSpecialties>
                  ))}
                </ul>
              </DoctorSkills>
              <DoctorTime>
                <p>{t('Duração')}</p>
                <span>
                  {!!doctor?.teleconsultation_settings?.default?.duration && (
                    <p>
                      {t('Consulta')}:{' '}
                      {doctor?.teleconsultation_settings?.default?.duration}{' '}
                      min.
                    </p>
                  )}
                  {!!doctor?.teleconsultation_settings?.returning?.duration && (
                    <p>
                      {t('Retorno')}:{' '}
                      {doctor?.teleconsultation_settings?.returning?.duration}{' '}
                      min.
                    </p>
                  )}
                </span>
              </DoctorTime>
              <DoctorPrice>
                <p>{t('Valor')}</p>
                <span>
                  {!!doctor?.teleconsultation_settings?.default?.duration && (
                    <p>
                      {t('Consulta')}:{' '}
                      {new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: doctor?.billing_currency || 'BRL',
                      }).format(
                        doctor?.teleconsultation_settings?.default
                          ?.billing_amount
                      )}
                    </p>
                  )}
                  {!!doctor?.teleconsultation_settings?.returning?.duration && (
                    <p>
                      {t('Retorno')}:{' '}
                      {new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: doctor?.billing_currency || 'BRL',
                      }).format(
                        doctor?.teleconsultation_settings?.returning
                          ?.billing_amount
                      )}
                    </p>
                  )}
                </span>
              </DoctorPrice>
              <DoctorPaymentMethods>
                <p>{t('Forma de Pagamento')}</p>
                <span>
                  {/* <p>{t('Transferência Bancária')}</p> */}
                  {/* <p>Cartão de Crédito</p> */}
                  {paymentMethods.insurance_partner && (
                    <p>{t('Planos de Saúde')}</p>
                  )}
                  {paymentMethods.voucher && <p>{t('Voucher')}</p>}
                </span>
              </DoctorPaymentMethods>
              {paymentMethods.insurance_partner && (
                <DoctorPaymentMethods>
                  <p>{t('Planos de saúde aceitos:')}</p>
                  {showInsurancePartners()}
                </DoctorPaymentMethods>
              )}
            </Modal>
          </DoctorCard>
          <BorderGrey />
          {doctor === null ? (
            <DoctorSpecifications>
              <BulletList backgroundColor="var(--caren-image-fallback)" />
            </DoctorSpecifications>
          ) : (
            <DoctorSpecifications>
              <DoctorTime>
                <p>{t('Duração')}</p>
                <span>
                  {!!doctor?.teleconsultation_settings?.default?.duration && (
                    <p>
                      {t('Consulta')}:{' '}
                      {doctor?.teleconsultation_settings?.default?.duration}{' '}
                      min.
                    </p>
                  )}
                  {!!doctor?.teleconsultation_settings?.returning?.duration && (
                    <p>
                      {t('Retorno')}:{' '}
                      {doctor?.teleconsultation_settings?.returning?.duration}{' '}
                      min.
                    </p>
                  )}
                </span>
              </DoctorTime>
              <DoctorPrice>
                <p>{t('Valor')}</p>
                <span>
                  {!!doctor?.teleconsultation_settings?.default?.duration && (
                    <p>
                      {t('Consulta')}:{' '}
                      {new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: doctor?.billing_currency || 'BRL',
                      }).format(
                        doctor?.teleconsultation_settings?.default
                          ?.billing_amount
                      )}
                    </p>
                  )}
                  {!!doctor?.teleconsultation_settings?.returning?.duration && (
                    <p>
                      {t('Retorno')}:{' '}
                      {new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: doctor?.billing_currency || 'BRL',
                      }).format(
                        doctor?.teleconsultation_settings?.returning
                          ?.billing_amount
                      )}
                    </p>
                  )}
                </span>
              </DoctorPrice>
              <DoctorPaymentMethods>
                <p>{t('Forma de Pagamento')}:</p>
                <span>
                  {/* <p>{t('Transferência Bancária')}</p> */}
                  {/* <p>Cartão de Crédito</p> */}
                  {paymentMethods.insurance_partner && (
                    <p>{t('Planos de Saúde')}</p>
                  )}
                  {paymentMethods.voucher && <p>{t('Voucher')}</p>}
                </span>
              </DoctorPaymentMethods>
              {paymentMethods.insurance_partner && (
                <DoctorPaymentMethods>
                  <p>{t('Planos de saúde aceitos')}</p>
                  {showInsurancePartners()}
                </DoctorPaymentMethods>
              )}
            </DoctorSpecifications>
          )}
          <BorderGrey />
          {doctor === null ? (
            <DoctorSpecifications>
              <Code backgroundColor="var(--caren-image-fallback)" />
            </DoctorSpecifications>
          ) : (
            <DoctorSpecifications>
              <DoctorBio>
                <p>{t('Currículo Resumido')}:</p>
                <span>
                  <p>{doctor?.bio}</p>
                  <DoctorSeeMore>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {/* <a href="#">Veja mais</a> */}
                  </DoctorSeeMore>
                </span>
              </DoctorBio>
              <DoctorSkills>
                <p>{t('Especialidades')}:</p>
                <ul>
                  {doctor?.specialties?.map((specialty) => (
                    <li key={specialty.key}>{specialty.name}</li>
                  ))}
                </ul>
              </DoctorSkills>
            </DoctorSpecifications>
          )}
        </FirstGrid>
        <SecondGrid>
          <DatePickerText>{t('Selecione um dia')}</DatePickerText>
          <DoctorDate>
            <DoctorPickerContainer>
              <CustomCalendar
                setSlotCards={setSlotCards}
                futureOnly
                doctorId={doctor?.key}
                type={type}
              />
              <TimezoneAndHour />
            </DoctorPickerContainer>
            <DoctorHoursBox>
              {/* <Switch
                type={type}
                setType={setType}
                setSlotCards={setSlotCards}
              /> */}
              {slotCards?.selectedDate && (
                <DoctorHoursHeader>
                  {t('Horários para')}{' '}
                  {moment(slotCards?.selectedDate).format(FORMAT['pt-date'])}
                </DoctorHoursHeader>
              )}
              {(slotCards?.availabilities || []).length ? (
                slotCards?.availabilities?.map((each) => (
                  <DoctorHours
                    key={each}
                    isChosen={each === choosedHour}
                    onClick={() => handlePaymenthMethod(each)}
                  >
                    {momentUtcLocal(each).format(FORMAT['hour-and-minute'])}
                  </DoctorHours>
                ))
              ) : (
                <NoAvailability />
              )}
            </DoctorHoursBox>
          </DoctorDate>
        </SecondGrid>
      </Container>
      <PayContainerOpacity
        open={openPaymentMethod}
        onClick={() => {
          if (openPaymentMethod) {
            setTimeout(() => setChoosedHour(''), 300);
            setOpenPaymentMethod(false);
          }
        }}
      />
      <PayBar
        open={openPaymentMethod}
        type={type}
        doctorId={id}
        doctor={doctor}
        payBarState={setOpenPaymentMethod}
        choosedHour={choosedHour}
        onHealthInsurance={handleToggleModal}
        onVoucher={handleVoucher}
        healthInsuranceDisabled={healthInsuranceDisabled()}
        voucherDisabled={voucherDisabled()}
      />
      {/* <PaymentModal handleOpenModal={handleOpenModal} openModal={0} /> */}
      <HealthInsuranceSelectorDialog
        handleToggleModal={handleToggleModal}
        openModal={openModal}
        onClickFunc={handleHealthPlan}
        insurancePartners={insurancePartners}
      />
      <Modal
        handleOpenModal={handleToggleModal}
        openModal={false}
        modalTitle={`${t(
          'Seu Plano de Saúde ainda não utiliza a'
        )} Caren.app :(`}
      >
        <ModalSubtitle>
          {t(
            'Preencha o formulário abaixo que enviaremos uma mensagem para o seu plano pedindo o cadastramento da Caren'
          )}
        </ModalSubtitle>
        <Form>
          <Input type="text" placeholder={t('Nº da Carteira do Plano')} />
          <Input
            type="text"
            placeholder={t('Nome como na Carteira do Plano')}
          />
          <Textarea
            rows="8"
            placeholder={t(
              'Olá, como usuário do "VC Saúde", gostaria de ter acesso a plataforma de telemedicina da Caren.app para meus atendimentos. Obrigado!'
            )}
          />
          <ModalButton type="button">{t('Enviar')}</ModalButton>
        </Form>
      </Modal>
    </>
  );
}
