import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useToast } from '../contexts/ToastContext';
import { listOfSchedulesService } from '../services/patient-service';
import { Store } from '../store';
import { FORMAT } from '../utils/moment/momentFormat';
import { momentUtcLocal } from '../utils/moment/momentHelpers';

export default function useListOfSchedules({
  fromDate = moment().toDate(),
  toDate = moment().endOf('month').toDate(),
  sortedByDate = true,
  confirmedOnly = true,
  futureOnly = false
} = {}) {
  function formatDate(date) {
    return momentUtcLocal(date).format(FORMAT['us-date']);
  }

  const { t } = useTranslation(['common']);
  const toast = useToast();
  const [state] = useContext(Store);
  const [fromDateState, setFromDateState] = useState(fromDate);
  const [toDateState, setToDateState] = useState(toDate);
  const [consultations, setConsultations] = useState([]);
  const [firstAvailable, setFirstAvailable] = useState({});

  useEffect(() => {
    if (state.query.from) {
      setFromDateState(() => state.query.from);
      setToDateState(() => state.query.to);
    }
  }, [state.query.from, state.query.to]);

  useEffect(() => {
    async function getListOfSchedulesService() {
      try {
        let response;

        if (fromDateState) {
          response = await listOfSchedulesService({
            fromDate: formatDate(fromDateState),
            toDate: formatDate(toDateState)
          });
        } else {
          response = await listOfSchedulesService();
        }

        if (futureOnly && response.data) {
          response.data = response?.data?.filter(({ end_datetime: date }) =>
            moment.utc(date).local().isAfter(moment())
          );
        }

        if (sortedByDate && response.data) {
          response.data = response?.data?.sort(
            (a, b) =>
              new Date(a?.scheduled_datetime) - new Date(b?.scheduled_datetime)
          );
        }

        if (confirmedOnly && response.data) {
          response.data = response?.data?.filter(
            consultation => consultation.confirmed === true
          );
        }

        if (response.data) setConsultations(() => [...response.data]);
      } catch (error) {
        toast(t('Ops, não foi possível carregar o histórico...'), {
          variant: 'error'
        });
      }
    }

    getListOfSchedulesService();
  }, [fromDateState, sortedByDate, toDateState]);

  useEffect(() => {
    const allConsultations = [...consultations];
    const firstWaiting = allConsultations.find(
      consultation => !consultation.concluded && !consultation.expired
    );
    setFirstAvailable(() => ({ ...firstWaiting }));
  }, [consultations]);

  return [consultations, firstAvailable, setConsultations];
}
