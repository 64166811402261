import styled from 'styled-components/macro';

function isOpen() {
  return `
  opacity: 0.4;
  background: #343f4b;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  `;
}

export const Container = styled.div<{ sidebarIsOpen: boolean }>`
  ${({ sidebarIsOpen }) => sidebarIsOpen && isOpen()};
`;
