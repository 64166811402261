import { type InputHTMLAttributes } from 'react';

type CalendarInputProps = {
  label?: string
} & InputHTMLAttributes<HTMLInputElement>

export default function CalendarInput({ label, ...rest }: CalendarInputProps) {
  return (
    <div className="relative flex items-start w-full flex-col justify-end">
      {label && (
        <label className="mb-1 font-nunito text-sm font-semibold text-blue-650">
          {label}
        </label>
      )}
      <input
        className="h-10 w-full appearance-none rounded-md border-none text-left px-4 py-3 accent-yellow-650 text-sm text-gray-450 ring-transparent focus:ring-yellow-650"
        type="date"
        {...rest}
      />
    </div>
  );
}
