import api from './api';

export const confirmVidaasQR = async (teleconsultationKey, token) => {
  const { data } = await api.post('/integrations/vidaas/qr-confirm', {
    key: teleconsultationKey,
    token
  });

  return data;
};
