import styled from 'styled-components/macro';

export const DoctorClockContainer = styled.div`
  margin-top: 10px;
`;

export const DoctorClockDays = styled.div`
  background-color: var(--caren-clockgrey);
  color: var(--caren-white);
  text-align: right;
  font-size: 12px;
  padding: 2px 4px;
  height: 24px;
  line-height: 23px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const DoctorClockHours = styled.div`
  background-color: var(--caren-clocklightgrey);
  color: var(--caren-white);
  text-align: center;
  font-size: 28px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
