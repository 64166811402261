import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import AudioLevelIndicator from '../AudioLevelIndicator';
import NetworkQualityLevel from '../NetworkQualityLevel';
// import PinIcon from './PinIcon/PinIcon';
// import ScreenShareIcon from '../../icons/ScreenShareIcon';

import usePublications from '../../../hooks/video/usePublications';
import useTrack from '../../../hooks/video/useTrack';
import useParticipantIsReconnecting from '../../../hooks/video/useParticipantIsReconnecting';
import ImageOrName from '../../ImageOrName';
import { AvatarContainer } from './styles';
// import { useAppState } from '../../state';

const borderWidth = 2;

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      isolation: 'isolate',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      '& video': {
        objectFit: 'cover !important'
      },
      borderRadius: '10px',
      border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      paddingTop: `calc(${(9 / 16) * 100}% - ${
        theme.participantBorderWidth
      }px)`,
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginBottom: '0',
        fontSize: '12px',
        paddingTop: `${theme.sidebarMobileHeight - 2}px`
      }
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white'
      }
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em 0.18em 0',
      margin: 0,
      display: 'flex',
      alignItems: 'center'
    },
    infoRowTop: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      top: 0,
      left: 0
    },
    typography: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem'
      }
    },
    hideParticipant: {
      display: 'none'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    galleryView: {
      border: `${theme.participantBorderWidth}px solid ${theme.galleryViewBackgroundColor}`,
      borderRadius: '8px',
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: '0',
        fontSize: '12px',
        margin: '0',
        '& video': {
          objectFit: 'cover !important'
        }
      }
    },
    dominantSpeaker: {
      border: `solid ${borderWidth}px #7BEAA5`
    }
  })
);

export default function ParticipantInfo({
  participant,
  name,
  avatarUrl,
  children,
  isLocalParticipant
}) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(
    p => !p.trackName.includes('screen') && p.kind === 'video'
  );

  const videoTrack = useTrack(videoPublication);

  const audioTrack = useTrack(audioPublication);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {})}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <NetworkQualityLevel participant={participant} />
        <div className={classes.infoRowTop}>
          <span className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography
              variant="body1"
              className={classes.typography}
              component="span"
            >
              {name}
              {isLocalParticipant && ' (Você)'}
            </Typography>
          </span>
        </div>
      </div>

      <div className={classes.innerContainer}>
        {(videoTrack === undefined ||
          videoTrack === null ||
          (videoTrack && !videoTrack.isEnabled)) && (
          <AvatarContainer>
            <ImageOrName
              src={avatarUrl}
              title={name}
              titleSize={45}
              backgroundStyle={
                isLocalParticipant
                  ? {
                      width: 80,
                      height: 80,
                      borderRadius: '100%',
                      backgroundColor: 'var(--caren-image-fallback)'
                    }
                  : {
                      width: 124,
                      height: 124,
                      borderRadius: '100%',
                      backgroundColor: 'var(--caren-image-fallback)'
                    }
              }
            />
          </AvatarContainer>
        )}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typography}>
              Reconectando...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
