import React, { type InputHTMLAttributes, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../../../assets/icons/eye-slash.svg';

type PasswordInputProps = {
  label?: string;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement>

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ label, error, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="relative flex w-full flex-col items-start">
        {label && (
          <label className="mb-1 font-nunito text-sm font-semibold text-gray-350">
            {label}
          </label>
        )}
        <button
          type="button"
          tabIndex={0}
          title={showPassword ? 'Esconder senha' : 'Mostrar senha'}
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          className="absolute right-0 top-6 grid h-10 w-10 place-items-center text-warning"
        >
          {showPassword ? (
            <EyeIcon className="h-5 w-5" />
          ) : (
            <EyeSlashIcon className="h-5 w-5" />
          )}
        </button>
        <input
          ref={ref}
          className={twJoin(
            'h-10 w-full rounded-md px-4 py-3 pr-10 text-sm text-gray-450 ring-transparent focus:border-yellow-650 focus:ring-yellow-650',
            error ? 'border-error bg-error/10' : 'border-blue-650/50 bg-white'
          )}
          type={showPassword ? 'text' : 'password'}
          {...rest}
        />
        {error && (
          <span className="mt-1 font-nunito text-xs text-error">{error}</span>
        )}
      </div>
    );
  }
);

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
