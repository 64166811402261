import { Helmet } from 'react-helmet-async';
import Header from '../../../components/Header';
import ContainerOpacity from '../../../components/ContainerOpacity';
import UserSidebar from '../../../components/Sidebar/UserSidebar';
import TabMenu from '../../../components/TabMenu';

import DoctorPanel from '../../../components/DoctorPanel';
import Chatwoot from '../../../components/Chatwoot';
import Tawk from '../../../components/Tawk';

type DoctorProfileProps = {
  view?: string;
};

export default function DoctorProfile({ view }: DoctorProfileProps) {
  return (
    <>
      <Helmet title="Perfil Médico - Caren"></Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <div className="flex min-h-[calc(100vh_-_95px)] flex-col items-center px-6 py-12 lg:flex-row lg:items-start lg:pl-16 lg:pr-10">
        <div className="mb-8 mr-6 w-full max-w-none shrink-0 md:max-w-80 lg:mb-0 lg:mr-16 lg:w-64 lg:max-w-none">
          <DoctorPanel inviteFriend />
        </div>
        <div className="w-full flex-grow">
          <TabMenu view={view} isDoctor />
        </div>
      </div>
      <Chatwoot />
      <Tawk />
    </>
  );
}
