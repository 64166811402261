import styled from 'styled-components/macro';

export const ClockContainer = styled.div`
  margin: 2px 10px 0 0;
`;

export const AwaitingReceipt = styled.div`
  background-color: var(--caren-clockgrey);
  text-align: center;
  font-size: 12px;
  padding: 2px 4px;
  height: 100%;
  line-height: 26px;
`;

export const ReceiptConfirmationCountdown = styled.div`
  background-color: var(--caren-clocklightgrey);
  color: var(--caren-white);
  text-align: center;
  font-size: 28px;
  line-height: 250%;
`;
