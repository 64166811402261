import styled from 'styled-components/macro';
import {
  AiOutlineFileDone,
  AiOutlineFileText,
  AiOutlineClockCircle,
  AiOutlineVideoCamera
} from 'react-icons/ai';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import { FaSearchPlus } from 'react-icons/fa';

import ImageOrName from '../../../components/ImageOrName';

export const CustomTable = styled.table`
  width: 100%;
  border-spacing: 0 8px;
  th,
  td {
    text-align: left;
    padding: 10px;
  }
`;

export const TableHeaderColumn = styled.th`
  text-align: ${({ align }) => align && align} !important;
  color: var(--caren-icongrey);
  font-size: 10px;
`;

export const TableColumn = styled.td`
  text-align: ${({ align }) => align && align} !important;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export const TableRow = styled.tr`
  background-color: var(--caren-white);
  padding: 2px;

  td {
    opacity: ${({ inactive }) => (inactive ? '0.5' : '1')};
  }
`;

export const PatientBox = styled.div`
  display: flex;
  align-items: center;
`;

export const PatientImage = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))``;

export const PatientName = styled.p`
  margin-left: 15px;
  color: var(--caren-greenblue);
  font-weight: bold;
  text-transform: uppercase;
`;

export const TableColumnText = styled.p`
  color: var(--caren-icongrey);
`;

export const UploadIcon = styled(FaSearchPlus).attrs({
  size: 20,
  color: 'var(--caren-orange)'
})``;

export const ApprovedIcon = styled(BiCheckCircle).attrs({
  size: 20,
  color: 'var(--caren-green)'
})``;

export const RefusedIcon = styled(BiXCircle).attrs({
  size: 20,
  color: 'var(--caren-red)'
})``;

export const AnamneseIcon = styled(AiOutlineFileText).attrs({
  color: 'var(--caren-icongrey)',
  size: 23
})`
  cursor: pointer;
`;

export const EnterRoomIcon = styled(AiOutlineVideoCamera).attrs({
  color: 'var(--caren-icongrey)',
  size: 23
})`
  cursor: pointer;
`;

export const WaitRoomIcon = styled(AiOutlineFileDone).attrs({
  color: 'var(--caren-green)',
  size: 23
})`
  cursor: pointer;
`;

export const PendingIcon = styled(AiOutlineClockCircle).attrs({
  color: 'var(--caren-icongrey)',
  size: 23
})``;

export const ActionIcon = styled.span`
  cursor: pointer;
`;

export const ReceiptImage = styled.img.attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  border: 1px solid var(--caren-lightgrey);
  width: 100%;
  border-radius: 5px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    cursor: pointer;
  }
`;

export const DenyButton = styled.button`
  background-color: var(--caren-red);
  color: var(--caren-white);
  font-weight: bold;
  font-size: 24px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  border-radius: 5px;
`;

export const ApproveButton = styled.button`
  background-color: var(--caren-green);
  color: var(--caren-white);
  font-weight: bold;
  font-size: 24px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  border-radius: 5px;
`;
