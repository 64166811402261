import React, { useEffect } from 'react';

import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AudioInputList from './AudioInputList';
import AudioOutputList from './AudioOutputList';
import VideoInputList from './VideoInputList';
import useVideoContext from '../../../hooks/video/useVideoContext';
import { useVideoAppState } from '../../../hooks/video/useVideoAppState';
import ToggleVideoButton from '../Buttons/ToggleVideoButton';
import ToggleAudioButton from '../Buttons/ToggleAudioButton';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  buttonText: {
    color: 'var(--caren-white) !important',
    fontWeight: 'bold',
    fontSize: '1em',
    textTransform: 'uppercase',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

export default function DeviceSelectionDialog({ open, onClose, standalone }) {
  const classes = useStyles();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { mediaError, setMediaError } = useVideoAppState();

  useEffect(() => {
    if (standalone && !mediaError) {
      if (open === true) {
        getAudioAndVideoTracks().catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error acquiring local media:', error);
          // eslint-disable-next-line no-console
          console.dir(error);
          setMediaError(error);
        });
      }
    }
  }, [open, standalone, getAudioAndVideoTracks, mediaError]);

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>Ajustes de Áudio e Vídeo</DialogTitle>
      <Divider />
      <DialogContent className={classes.container}>
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Vídeo
          </Typography>
          <VideoInputList />
        </div>
        {standalone && (
          <ToggleVideoButton
            className={classes.mobileButton}
            disabled={false}
          />
        )}
        <Divider />
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Áudio
          </Typography>
          <AudioInputList />
        </div>
        {standalone && (
          <ToggleAudioButton
            className={classes.mobileButton}
            disabled={false}
          />
        )}
        <div className={classes.listSection}>
          <AudioOutputList />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          classes={{
            root: classes.button,
            label: classes.buttonText,
          }}
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
