import api from './api';

export const unreadNotificationsService = async () => {
  const { data } = await api.get('/notifications/unread');

  return data;
};

export const readAllNotificationsService = async () => {
  const { data } = await api.put('/notifications/read-all');

  return data;
};

export const readOnlyOneNotificationService = async (key) => {
  const { data } = await api.put('/notifications/read', {
    notification_key: key
  });

  return data;
};

export const readNotificationsService = async () => {
  const { data } = await api.get('/notifications/read');

  return data;
};
