import api from './api';

export const loginByInvitationFiscalCode = async (
  invitationKey,
  responseToken,
  fiscalCode
) => {
  const { data } = await api.post(
    '/teleconsultations/invitations/fiscal-code',
    {
      invitation_key: invitationKey,
      response_token: responseToken,
      fiscal_code: fiscalCode
    }
  );

  return data;
};
