import styled from 'styled-components';

export const DialogContentDescription = styled.div`
  color: black;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 4px;
  text-align: left;
`;

export const DialogContentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;

  button {
    background-color: #fdbf71;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 20px;
    border-radius: 5px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    > svg {
      display: inline-block;
      margin-right: 14px;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease;

      path {
        fill: white;
      }
    }

    span {
      color: var(--caren-icongrey);
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
    }

    &:hover {
      background-color: #077a94;

      > svg {
        transform: translateX(5px);
      }
    }

    &:nth-child(1) {
      background-color: var(--caren-lightgrey);
      margin-right: 20px;

      span {
        color: var(--caren-white);
      }

      &:hover {
        filter: brightness(115%);
      }
    }
  }
`;

export const Dialog = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  z-index: 2;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }
`;

export const DialogContent = styled.div`
  background-color: white;
  position: relative;
  width: 400px;
  padding: 30px;
  border-radius: 10px;

  @media (max-width: 636px) {
    max-width: 90%;
    padding: 15px;
  }
`;

export const DialogContentClose = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  color: var(--caren-lightgrey);
  font-size: 25px;
  font-weight: bold;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
    color: var(--caren-grey);
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const DialogContentTitle = styled.div`
  color: black;
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
`;

export const DialogContentInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;

  > span {
    color: black;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    flex-shrink: 0;
    margin-right: 10px;
  }

  input {
    width: 95px;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      color: transparent;
      background: transparent;
    }

    &::-webkit-inner-spin-button {
      opacity: 0;
      display: none;
    }

    &::-webkit-clear-button {
      opacity: 0;
      display: none;
    }
  }
`;

export const WrapperCalendar = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: -10px;

  .fc {
    z-index: 1 !important;

    .fc-header-toolbar {
      @media (max-width: 636px) {
        flex-direction: column;
      }
    }

    .fc-toolbar-chunk {
      &:not(:last-child) {
        @media (max-width: 636px) {
          margin-bottom: 10px;
        }
      }

      .fc-today-button {
        background-color: #fdbf71;
        border-color: #fdbf71;
        color: var(--caren-icongrey);
        height: 40px;
      }

      .fc-button-group {
        button {
          background-color: #fdbf71;
          border-color: #fdbf71;
          color: var(--caren-icongrey);
          padding-top: 2px;
          height: 40px;
        }
      }
    }

    .fc-toolbar-title {
      font-size: 18px;

      @media (max-width: 636px) {
        font-size: 14px;
      }
    }

    .fc-event {
      &:not(.fc-added-now) {
        cursor: default !important;
      }

      &-time {
        &::after {
          display: none;
        }
      }

      &-title-container {
        display: none;
      }
    }

    .fc-timegrid-slots tbody {
      tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  padding: 8px 15px;
  border-radius: 8px;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  z-index: 10;

  &.active {
    opacity: 1;
  }
`;
