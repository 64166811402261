import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { TextField } from '@mui/material';
import '@fullcalendar/react/dist/vdom';
import FullCalendar, {
  type DatesSetArg,
  type EventClickArg
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {
  type DateClickArg
} from '@fullcalendar/interaction';

import { z } from 'zod';
import { isMobile } from '../../../../utils/twilio';
import { momentUtcLocal } from '../../../../utils/moment/momentHelpers';
import { useToast } from '../../../../contexts/ToastContext';
import { CALENDAR_DEFAULT_DISPLAY_DURATION } from '../../../../constants/common';
import { useAuth } from '../../../../hooks/AuthContext';
import { type Doctor } from '../../../entities/doctor';
import {
  Dialog,
  DialogContent,
  DialogContentActions,
  DialogContentClose,
  DialogContentDescription,
  DialogContentInput,
  DialogContentTitle,
  TooltipWrapper,
  WrapperCalendar
} from './styles';
import { getDoctorSchedule } from '../../../../services/schedule';
import { getDoctorInformation } from '../../../../services/secretary';

const CalendarConsultSchema = z.object({
  edit: z.boolean().optional(),
  text: z.object({
    day: z.string(),
    startTime: z.string(),
    endTime: z.string()
  }),
  date: z.object({
    startDate: z.date(),
    startTime: z.date(),
    endTime: z.date()
  })
});

const CalendarEventSchema = z.object({
  id: z.string(),
  start: z.date(),
  end: z.date(),
  title: z.string(),
  editable: z.boolean(),
  overlap: z.boolean(),
  color: z.string(),
  classNames: z.string().array().optional(),
  durationEditable: z.boolean().optional()
});

export const CalendarEventResumeSchema = z.object({
  date: z.object({
    day: z.string(),
    time: z.string()
  }),
  text: z.object({
    day: z.string(),
    startTime: z.string(),
    endTime: z.string()
  })
});

type CalendarConsult = z.infer<typeof CalendarConsultSchema>;
type CalendarEvent = z.infer<typeof CalendarEventSchema>;
export type CalendarEventResume = z.infer<typeof CalendarEventResumeSchema>;

type CalendarProps = {
  selectedDoctor: Doctor | null;
  editedEvent?: string;
  onSelectDate: (
    event: {
      date: {
        day: string;
        time: string;
      };
      text: {
        day: string;
        startTime: string;
        endTime: string;
      };
    } | null
  ) => void;
}

export default function Calendar({
  selectedDoctor,
  editedEvent,
  onSelectDate
}: CalendarProps) {
  const { credentials } = useAuth();
  const toast = useToast();

  const dialogElement = useRef<HTMLDivElement | null>(null);
  const [week, setWeek] = useState({
    from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    to: moment().add(6, 'days').format('YYYY-MM-DD')
  });
  const [recurringEvents, setRecurringEvents] = useState<CalendarEvent[]>([]);
  const [consult, setConsult] = useState<CalendarConsult | null>(null);
  const [newEvent, setNewEvent] = useState<CalendarConsult | null>(null);
  const [blockSchedule, setBlockSchedule] = useState(false);
  const [tooltip, setTooltip] = useState({
    open: false,
    title: ''
  });
  const [consultDuration, setConsultDuration] = useState(
    credentials?.session?.teleconsultation_settings?.default?.duration ?? 0
  );

  function validOverlap(start: moment.Moment, end: moment.Moment) {
    let overlap: {
      invalid: boolean;
      maybeFit: boolean;
      newTime: null | moment.Moment;
    } = {
      invalid: false,
      maybeFit: false,
      newTime: null
    };
    let lastEvent: CalendarEvent;
    if (recurringEvents) {
      recurringEvents.forEach(event => {
        const eventStart = event.start;
        const eventEnd = event.end;
        if (start.isSame(eventStart, 'day') && event.id !== 'new-event') {
          if (
            end.isAfter(eventStart, 'minutes') &&
            end.isBefore(eventEnd, 'minutes')
          ) {
            const lastEventStart = lastEvent.start;
            const lastEventEnd = lastEvent.end;
            const newStartTime = momentUtcLocal(eventStart).subtract(
              consultDuration,
              'minutes'
            );
            if (
              newStartTime.isAfter(lastEventStart, 'minutes') &&
              newStartTime.isBefore(lastEventEnd, 'minutes')
            ) {
              overlap = {
                invalid: true,
                maybeFit: false,
                newTime: null
              };
            } else {
              overlap = {
                invalid: true,
                maybeFit: true,
                newTime: newStartTime
              };
            }
          }

          if (start.isSame(eventStart))
            overlap = {
              invalid: true,
              maybeFit: false,
              newTime: null
            };

          if (
            start.isAfter(eventStart, 'minutes') &&
            start.isBefore(eventEnd, 'minutes')
          ) {
            overlap = {
              invalid: true,
              maybeFit: false,
              newTime: null
            };
          }
        }
        lastEvent = event;
      });
    }

    return overlap;
  }

  const validateSchedules = (selected: moment.Moment) => {
    const today = moment().local();

    if (selected.isBefore(today)) {
      toast('Não é possível agendar em datas passadas.', {
        variant: 'error'
      });

      return true;
    }

    return false;
  };

  const formatDay = (date: Date) => {
    const today = moment().local();
    const tomorrow = today.clone().add(1, 'days');
    const selected = momentUtcLocal(date);

    if (selected.isSame(today, 'day')) return 'Hoje';
    if (selected.isSame(tomorrow, 'day')) return 'Amanhã';

    return momentUtcLocal(date).format('DD/MM/YYYY');
  };

  function insertEvent(info: DateClickArg) {
    const selectedDate = info.date;
    let startTime = selectedDate;
    let endTime = momentUtcLocal(selectedDate)
      .add(consultDuration, 'minutes')
      .toDate();

    const overlap = validOverlap(moment(startTime), moment(endTime));

    if (overlap.invalid && !overlap.maybeFit) {
      toast('Esse horário irá sobrepor uma consulta existente.', {
        variant: 'error'
      });
      return;
    }
    if (overlap.invalid && overlap.maybeFit && overlap.newTime !== null) {
      startTime = momentUtcLocal(overlap.newTime.toDate()).toDate();
      endTime = momentUtcLocal(overlap.newTime.toDate())
        .add(consultDuration, 'minutes')
        .toDate();
    }
    const day = formatDay(startTime);
    const selected = momentUtcLocal(startTime);
    const blockedTime = validateSchedules(selected);
    if (blockedTime) return;
    const data = {
      edit: false,
      text: {
        day,
        startTime: momentUtcLocal(startTime).format('HH:mm'),
        endTime: momentUtcLocal(endTime).format('HH:mm')
      },
      date: {
        startDate: startTime,
        startTime,
        endTime
      }
    };

    if (blockSchedule) {
      setNewEvent(data);
      updateEvent(startTime);
    } else {
      setConsult(data);
      setBlockSchedule(true);

      onSelectDate({
        text: {
          day,
          startTime: momentUtcLocal(startTime).format('HH:mm'),
          endTime: momentUtcLocal(endTime).format('HH:mm')
        },
        date: {
          day: selected.format('YYYY-MM-DD'),
          time: selected.format('HH:mm:ss')
        }
      });
    }
  }

  function editEvent(info: EventClickArg) {
    if (info.event.id !== 'new-event') return;
    const selectedDate = info.event.start;
    if (!selectedDate) return;

    const startTime = selectedDate;
    const endTime = momentUtcLocal(selectedDate)
      .add(consultDuration, 'minutes')
      .toDate();

    const day = formatDay(startTime);
    const data = {
      edit: true,
      text: {
        day,
        startTime: momentUtcLocal(startTime).format('HH:mm'),
        endTime: momentUtcLocal(endTime).format('HH:mm')
      },
      date: {
        startDate: selectedDate,
        startTime,
        endTime
      }
    };

    setConsult(data);
    setBlockSchedule(true);
  }

  function updateEvent(start: Date | null) {
    if (!start) return;

    const selectedDate = start;
    const startTime = selectedDate;
    const endTime = momentUtcLocal(selectedDate)
      .add(consultDuration, 'minutes')
      .toDate();

    const selected = momentUtcLocal(startTime);
    const day = formatDay(startTime);

    onSelectDate({
      text: {
        day,
        startTime: momentUtcLocal(startTime).format('HH:mm'),
        endTime: momentUtcLocal(endTime).format('HH:mm')
      },
      date: {
        day: selected.format('YYYY-MM-DD'),
        time: selected.format('HH:mm:ss')
      }
    });
  }

  function closeDialog(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (dialogElement.current === e.target) {
      if (!consult?.edit) onSelectDate(null);
      setBlockSchedule(consult?.edit ?? false);
      setConsult(null);
    }
  }

  function updateWeek(info: DatesSetArg) {
    setWeek({
      from: moment(info.start).format('YYYY-MM-DD'),
      to: moment(info.end).format('YYYY-MM-DD')
    });
  }

  const changeInputTime = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    info: CalendarConsult | null
  ) => {
    if (!info) return;

    const value = e?.target?.value;
    const startDate = moment(info.date.startTime).format('YYYY-MM-DD');
    let newStart = moment(`${startDate} ${value}`).toDate();
    let newEnd = moment(newStart).add(consultDuration, 'minutes').toDate();

    const overlap = validOverlap(moment(newStart), moment(newEnd));

    if (overlap.invalid) {
      toast('Esse horário irá sobrepor uma consulta existente.', {
        variant: 'error'
      });
      if (overlap.maybeFit && overlap.newTime !== null) {
        newStart = momentUtcLocal(overlap.newTime.toDate()).toDate();
        newEnd = momentUtcLocal(overlap.newTime.toDate())
          .add(consultDuration, 'minutes')
          .toDate();
      } else {
        return;
      }
    }
    const selected = momentUtcLocal(newStart);
    const blockedTime = validateSchedules(selected);
    if (blockedTime) return;

    const data = {
      text: {
        day: info.text.day,
        startTime: momentUtcLocal(newStart).format('HH:mm'),
        endTime: momentUtcLocal(newEnd).format('HH:mm')
      },
      date: {
        startDate: newStart,
        startTime: newStart,
        endTime: newEnd
      }
    };
    setConsult(data);
    onSelectDate({
      text: {
        day: info.text.day,
        startTime: momentUtcLocal(newStart).format('HH:mm'),
        endTime: momentUtcLocal(newEnd).format('HH:mm')
      },
      date: {
        day: selected.format('YYYY-MM-DD'),
        time: selected.format('HH:mm:ss')
      }
    });
  };

  useEffect(() => {
    getDoctorSchedule(week.from, week.to, selectedDoctor?.key).then(result => {
      if (!result) return;

      const schedule: CalendarEvent[] = [];

      result.forEach(item => {
        const insurancePartner =
          item.invitation.insurance_partner ??
          item?.teleconsultation?.insurance_coverage ??
          'Consulta particular';

        const patientName =
          item.patient_name === 'Paciente' ? null : item.patient_name;
        const titleName = patientName ?? item.invitation.email;
        const title = `${titleName} - ${insurancePartner}`;
        const color = item.teleconsultation ? '#009D79' : '#FFBA5C';

        const options = {
          id: item.key,
          start: momentUtcLocal(item.start_datetime).toDate(),
          end: momentUtcLocal(item.end_datetime).toDate(),
          title,
          editable: false,
          overlap: false,
          color
        };

        if (
          editedEvent &&
          item.teleconsultation &&
          item.teleconsultation.key === editedEvent
        ) {
          options.color = '#B0B0B0';
          options.title = `${title} (Editando)`;
        }

        schedule.push(options);
      });

      const seen = new Set();
      const newEvents = schedule;
      const filteredEvents = newEvents.filter(el => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);

        return !duplicate;
      });

      setRecurringEvents(filteredEvents);
    });
  }, [week, editedEvent]);

  useEffect(() => {
    if (!newEvent) return;

    const seen = new Set();
    const filteredEvents = recurringEvents.filter(el => {
      let duplicate = seen.has(el.id);
      seen.add(el.id);

      if (el.id === 'new-event') duplicate = true;

      return !duplicate;
    });

    setConsult(null);
    setRecurringEvents([
      ...filteredEvents,
      {
        id: 'new-event',
        start: newEvent.date.startTime,
        end: newEvent.date.endTime,
        classNames: ['fc-added-now'],
        editable: true,
        title: '-',
        durationEditable: false,
        overlap: false,
        color: '#B0B0B0'
      }
    ]);
  }, [newEvent]);

  useEffect(() => {
    if (selectedDoctor) {
      getDoctorInformation(selectedDoctor.key).then(response => {
        setConsultDuration(response.teleconsultation_settings.default.duration);
      });
    }
  }, [selectedDoctor]);

  return (
    <WrapperCalendar>
      <TooltipWrapper
        className={tooltip.open && tooltip.title !== '-' ? 'active' : ''}
      >
        {tooltip.title}
      </TooltipWrapper>

      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={isMobile ? 'timeGridDay' : 'timeGridWeek'}
        contentHeight="200px"
        scrollTime={moment().format('HH:mm:ss')}
        slotMinTime="06:00:00"
        slotMaxTime="24:00:00"
        slotDuration="00:30"
        snapDuration="00:05"
        firstDay={moment().subtract(1, 'days').day()}
        buttonText={{
          today: 'Hoje'
        }}
        dayHeaderFormat={{
          weekday: 'short',
          day: 'numeric',
          omitCommas: true
        }}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        }}
        slotLabelInterval="01:00"
        locale="pt-br"
        allDaySlot={false}
        nowIndicator
        events={recurringEvents}
        defaultTimedEventDuration={CALENDAR_DEFAULT_DISPLAY_DURATION}
        editable={false}
        selectable
        datesSet={info => {
          updateWeek(info);
        }}
        dateClick={info => {
          insertEvent(info);
        }}
        eventMouseEnter={info => {
          setTooltip({
            open: true,
            title: info.event.title
          });
        }}
        eventMouseLeave={info => {
          setTooltip({
            open: false,
            title: info.event.title
          });
        }}
        eventDrop={info => {
          updateEvent(info.event.start);
        }}
        eventClick={info => {
          editEvent(info);
        }}
        eventConstraint={{
          start: moment().add(60, 'minutes').format('YYYY-MM-DD HH:mm'),
          end: '2500-01-01'
        }}
      />

      <Dialog
        className={consult ? 'active' : ''}
        ref={dialogElement}
        onClick={e => {
          closeDialog(e);
        }}
      >
        <DialogContent>
          <DialogContentClose
            onClick={() => {
              if (!consult?.edit) onSelectDate(null);
              setBlockSchedule(consult?.edit ?? false);
              setConsult(null);
            }}
          >
            &times;
          </DialogContentClose>

          {consult?.edit ? (
            <DialogContentTitle>Editar agendamento</DialogContentTitle>
          ) : (
            <DialogContentTitle>Novo agendamento</DialogContentTitle>
          )}

          <DialogContentDescription>
            <strong>Dia:</strong> {consult?.text?.day ? consult.text.day : '-'}
          </DialogContentDescription>

          <DialogContentInput>
            <span>Horário de início:</span>

            <TextField
              hiddenLabel
              type="time"
              value={consult?.text?.startTime}
              onChange={e => {
                changeInputTime(e, consult);
              }}
              variant="filled"
              size="small"
            />
          </DialogContentInput>

          {consult?.text?.endTime !== consult?.text?.startTime && (
            <DialogContentDescription>
              <strong>Horário de término:</strong>{' '}
              {consult?.text?.endTime ? consult.text.endTime : '-'}
            </DialogContentDescription>
          )}

          <DialogContentActions>
            <button
              type="button"
              title="Voltar"
              aria-label="Cancelar"
              onClick={() => {
                onSelectDate(null);
                setConsult(null);
                setBlockSchedule(false);
              }}
            >
              <span>Voltar</span>
            </button>

            <button
              type="button"
              title="Continuar"
              aria-label="Continuar"
              onClick={() => {
                setNewEvent(consult);
              }}
            >
              <span>Continuar</span>
            </button>
          </DialogContentActions>
        </DialogContent>
      </Dialog>
    </WrapperCalendar>
  );
}
