import { useEffect, useState } from 'react';
import formattedHours from '../utils/formattedHours';

function useCurrentHour () {
  const [currentHour, setCurrentHour] = useState(() => false);

  useEffect(() => {
    setCurrentHour(formattedHours(new Date()));
    const interval = setInterval(() => {
      setCurrentHour(formattedHours(new Date()));
    }, 15000);
    return () => { clearInterval(interval); };
  }, []);

  return currentHour;
}

export default useCurrentHour;
