import { useEffect, useState } from 'react';
import moment from 'moment';

function useCountdownClock(userData, clockCompleted, color = '#00C597') {
  const [data, setData] = useState({});
  const [clockInterval, setClockInterval] = useState(null);

  useEffect(() => {
    if (
      !clockCompleted &&
      userData?.scheduled_datetime &&
      userData?.confirmation_at
    ) {
      const confirmationDatetime = moment
        .utc(userData?.confirmation_at)
        .local()
        .valueOf();
      const scheduleDatetime = moment
        .utc(userData?.scheduled_datetime)
        .local()
        .valueOf();

      const startDate = confirmationDatetime;
      const endDate = scheduleDatetime;
      const totalTime = endDate - startDate;

      const interval = setInterval(() => {
        const now = moment().valueOf();
        const percentage = Math.max(15, ((now - startDate) / totalTime) * 100);
        const reversePercentage = 100 - percentage;
        let chartData = [100, 0];

        if (percentage >= 0 && percentage <= 99) {
          chartData = [Math.round(percentage), Math.round(reversePercentage)];
        }

        setData({
          datasets: [
            {
              data: chartData,
              label: false,
              backgroundColor: [color, '#f8f8f8'],
              hoverBackgroundColor: [color, '#f8f8f8'],
              borderWidth: 0
            }
          ]
        });
      }, 1000);

      setClockInterval(interval);
      return () => {
        clearInterval(interval);
      };
    }

    if (clockInterval) {
      clearInterval(clockInterval);
      setClockInterval(null);
    }

    return null;
  }, [clockCompleted, userData]);

  return data;
}

export default useCountdownClock;
