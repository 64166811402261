import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiVideo } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import NoConsults from '../../NoConsults';
import { FORMAT } from '../../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../../utils/moment/momentHelpers';
import ImageOrName from '../../ImageOrName';

import { useToast } from '../../../contexts/ToastContext';
import { doctorResendInvitationService } from '../../../services/doctors.service';
import UnreversibleConfirmationModal from '../../UnreversibleConfirmationModal';
import Modal from '../../Modal';

import {
  DoctorSchedule,
  DoctorTitleBox,
  DoctorTitle,
  PatientContent,
  PatientContentImage,
  PatientContentName,
  PatientContentDate,
  PatientContentClock,
  PatientContentClockTitle,
  PatientContentClockHint,
  PatientContentCTA
} from './styles';
import DoctorClock from '../../PatientPanel/DoctorClock';
import SuccessStep from '../../../v2/dialogs/consult/steps/success';
import { AxiosError } from 'axios';
import ExceededAppointmentsDialog, {
  type ExceededAppointmentsDialogHandles
} from '../../../v2/dialogs/upgrade/exceededAppointments';
import { type Consult } from '../../../v2/entities/consult';
import { useFreePlan } from '../../../v2/hooks/plan/useFreePlan';
import { useAuth } from '../../../hooks/AuthContext';

type NextPatientProps = {
  firstAvailable: Consult | null;
};

function NextPatient({ firstAvailable }: NextPatientProps) {
  const { t } = useTranslation(['doctor_clinic']);
  const toast = useToast();
  const { credentials } = useAuth();
  const location = useLocation();
  const { data: freePlan } = useFreePlan();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [invitationLink, setInvitationLink] = useState(null);
  const [, setClockCompleted] = useState(false);

  const exceededAppointmentsDialogRef =
    useRef<ExceededAppointmentsDialogHandles>(null);

  function formatDate(date: string) {
    const today = momentUtcLocal(new Date());
    const tomorrow = today.clone().add(1, 'days');
    const consult = momentUtcLocal(date);
    const time = momentUtcLocal(date).format(FORMAT['hour-and-minute']);
    let stringDate = momentUtcLocal(date).format(FORMAT['pt-datetime']);

    if (consult.isSame(today, 'day')) {
      stringDate = `Hoje - ${time}`;
    } else if (consult.isSame(tomorrow, 'day')) {
      stringDate = `Amanhã - ${time}`;
    }

    return stringDate;
  }

  function handleToggleConfirmationModal() {
    setOpenConfirmation(!openConfirmation);
  }

  function handleResendClick() {
    setOpenUnreversible(true);
  }

  async function handleResendInvitation(key: string) {
    try {
      const { link } = await doctorResendInvitationService(key);

      setInvitationLink(link);

      toast(t('Convite reenviado com sucesso.'), {
        variant: 'success'
      });
      setOpenConfirmation(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;

        const exceededMessage = 'You have exceeded the free trial limit';
        const responseMessage = response?.data?.error?.message;

        if (response?.status === 403 && responseMessage === exceededMessage) {
          exceededAppointmentsDialogRef.current?.open();
          return;
        }
      }
      toast(t('Ops, houve um erro no reenvio do convite...'), {
        variant: 'error'
      });
    }
  }

  function handleConfirmation(status: boolean) {
    if (status && firstAvailable) {
      handleResendInvitation(firstAvailable.invitation.key);
    }

    setOpenUnreversible(false);
  }

  const validateFreeTrialAppointment = (
    appointmentInsurancePartner: string | null
  ) => {
    if (appointmentInsurancePartner != null) return true;

    if (freePlan) {
      return (
        credentials?.session?.active_subscription?.plan
          ?.enable_private_teleconsultation_invitations === true ||
        freePlan?.used < freePlan?.total
      );
    }

    return false;
  };

  const renderWaitingRoomIconButton = (consultation: Consult) => {
    const valid = validateFreeTrialAppointment(
      consultation.invitation.insurance_partner
    );

    if (valid) {
      return (
        <Link
          to={{
            pathname: `/consultas/${firstAvailable?.teleconsultation?.key}/sala-de-espera`,
            state: {
              referrer: location.pathname
            }
          }}
          title="Entrar na sala de espera"
          aria-label="Entrar na sala de espera"
        >
          Entrar na sala de espera
        </Link>
      );
    }

    return (
      <button
        title="Entrar na sala de espera"
        aria-label="Entrar na sala de espera"
        onClick={() => {
          exceededAppointmentsDialogRef.current?.open();
        }}
      >
        Entrar na sala de espera
      </button>
    );
  };

  return (
    <DoctorSchedule
      data-tour="Você poderá ver detalhes da sua próxima consulta, acessar a página do paciente e entrar na sala de espera."
      data-tour-index="4"
    >
      <DoctorTitleBox>
        <DoctorTitle>{t('Próximo atendimento')}</DoctorTitle>
        {/* <DoctorBadgeCounter /> */}
      </DoctorTitleBox>
      {firstAvailable === null ? (
        <NoConsults
          title={t('Nenhum paciente agendado')}
          data-tour="Este é o painel do seu próximo atendimento."
          data-tour-index="3"
        />
      ) : (
        <>
          <PatientContent
            data-tour="Este é o painel do seu próximo atendimento."
            data-tour-index="3"
          >
            <PatientContentImage>
              {firstAvailable?.teleconsultation && firstAvailable?.patient ? (
                <Link
                  to={{
                    pathname: `/pacientes/${firstAvailable.patient.key}`,
                    state: {
                      referrer: location.pathname
                    }
                  }}
                  title="Ver informações do paciente"
                  aria-label="Ver informações do paciente"
                >
                  <ImageOrName
                    src={firstAvailable.patient.avatar_url}
                    title={firstAvailable.patient.name}
                    titleSize={45}
                    backgroundStyle={{
                      width: 100,
                      height: 100,
                      borderRadius: '100%',
                      backgroundColor: 'var(--caren-image-fallback)'
                    }}
                    iconSyle={{
                      width: 80,
                      height: 80
                    }}
                  />
                </Link>
              ) : (
                <ImageOrName
                  src={firstAvailable?.patient?.avatar_url}
                  title={firstAvailable.patient_name ?? 'Não Definido'}
                  titleSize={45}
                  backgroundStyle={{
                    width: 100,
                    height: 100,
                    borderRadius: '100%',
                    backgroundColor: 'var(--caren-image-fallback)'
                  }}
                  iconSyle={{
                    width: 80,
                    height: 80
                  }}
                />
              )}
            </PatientContentImage>

            <PatientContentName>
              {firstAvailable.teleconsultation && firstAvailable?.patient ? (
                <>
                  <Link
                    to={{
                      pathname: `/pacientes/${firstAvailable.patient.key}`,
                      state: {
                        referrer: location.pathname
                      }
                    }}
                    title={firstAvailable.patient.name}
                    aria-label="Ver informações do paciente"
                  >
                    <span>{firstAvailable.patient.name}</span>
                  </Link>
                </>
              ) : (
                <span>{firstAvailable.patient_name}</span>
              )}
            </PatientContentName>

            <PatientContentDate>
              {formatDate(firstAvailable.invitation.scheduled_for)}
            </PatientContentDate>

            {firstAvailable.teleconsultation?.room_opened_at ? (
              <PatientContentCTA>
                <Link
                  to={`/consultas/${firstAvailable?.teleconsultation?.key}/sala-de-espera`}
                  target="_blank"
                >
                  <FiVideo />
                  <span>Voltar para teleconsulta</span>
                </Link>
              </PatientContentCTA>
            ) : (
              <PatientContentClock>
                {!firstAvailable.teleconsultation && (
                  <>
                    <PatientContentClockTitle>
                      Pendente de confirmação
                      <span>Aguardando confirmação do paciente</span>
                    </PatientContentClockTitle>

                    <PatientContentClockHint>
                      Aguardando confirmação do paciente
                    </PatientContentClockHint>
                  </>
                )}

                <DoctorClock
                  consultation={firstAvailable}
                  onComplete={() => {
                    setClockCompleted(true);
                  }}
                  className="patient-clock-orange"
                />

                {firstAvailable.teleconsultation ? (
                  renderWaitingRoomIconButton(firstAvailable)
                ) : (
                  <button
                    type="button"
                    title="Reenviar convite"
                    aria-label="Reenviar convite"
                    onClick={() => {
                      handleResendClick();
                    }}
                  >
                    Reenviar convite
                  </button>
                )}
              </PatientContentClock>
            )}
          </PatientContent>

          <UnreversibleConfirmationModal
            title="Você tem certeza de que deseja reenviar esse convite?"
            openModal={openUnreversible}
            setOpenModal={setOpenUnreversible}
            handleConfirmation={handleConfirmation}
            cancelButtonText={undefined}
            confirmButtonText={undefined}
          />

          <Modal
            handleOpenModal={() => {
              setOpenConfirmation(!openConfirmation);
            }}
            openModal={openConfirmation}
            modalTitle={t('Convite Reenviado!')}
            modalTitleColor="black"
            containerStyles={{ textAlign: 'center' }}
            modalWidth="760px"
            noClosingOutside={true}
          >
            <SuccessStep
              invitationLink={invitationLink ?? ''}
              onClose={() => {
                handleToggleConfirmationModal();
              }}
            />
          </Modal>
          <ExceededAppointmentsDialog ref={exceededAppointmentsDialogRef} />
        </>
      )}
    </DoctorSchedule>
  );
}

export default NextPatient;
