import styled from 'styled-components/macro';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubHeaderContainer = styled.div`
  width: 100%;
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    max-width: 95vw;
  }
`;

export const TitlePage = styled.h2`
  font-weight: bold;
  margin: 20px 0;
  color: #64727f;
`;

export const MainContent = styled.div`
  width: 100%;
  max-width: 70vw;

  @media (max-width: 768px) {
    max-width: 95vw;
  }
`;

export const PatientInfoContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 150px;
  padding: 32px;
  margin-top: 15px;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 1140px) {
    flex-direction: column;
    padding: 25px;
    height: auto;
    border-radius: 10px;
  }
`;

export const ConsultationContainer = styled.div`
  margin-top: 20px;

  .MuiInputBase-input:focus {
    box-shadow: none;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 80px;

  > div {
    &:nth-child(1) {
      order: 2;
      margin-top: 20px;
    }

    &:nth-child(2) {
      order: 1;
      width: 100%;
    }
  }
`;

export const BackButtonContainer = styled.button`
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const BackButtonText = styled.div`
  margin-left: 10px;
  color: var(--caren-icongrey);
  font-weight: bold;
`;
