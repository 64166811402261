import { BadgeSchema } from '../v2/entities/badges';
import api from './api';

export const getPatientBadges = async (teleconsultationKey: string) => {
  const { data } = await api.get<{
    data: {
      Alergia?: unknown[];
      Comorbidades?: unknown[];
    };
  }>(
    `/teleconsultations/${teleconsultationKey}/insurance-authorizations/patient-badges`
  );

  console.log(data);

  return {
    allergy: data.data.Alergia
      ? BadgeSchema.array().parse(data.data.Alergia)
      : undefined,
    comorbidities: data.data.Comorbidades
      ? BadgeSchema.array().parse(data.data.Comorbidades)
      : undefined
  };
};
