import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import {
  ConsultationContainer,
  HistoryContainer,
  Container,
  MainContent,
  PatientInfoContainer,
  SubHeaderContainer,
  TitlePage,
  BackButtonContainer
} from './styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import {
  patientHistoryService,
  patientService
} from '../../services/patient-service';
import { useToast } from '../../contexts/ToastContext';
import PatientInfo from '../../components/Consultation/PatientInfo';
import History from '../../components/Twilio/Room/types/Professional/Tabs/History';
import { BackButton } from '../../components/BackButton';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';

export default function Patient({
  computedMatch: {
    params: { patient_id }
  }
}) {
  const toast = useToast();
  const location = useLocation();
  const { t } = useTranslation('patient');
  const [title, setTitle] = useState('Paciente - Caren');
  const [patient, setPatient] = useState(null);
  const [history, setHistory] = useState(null);

  useEffect(() => {
    async function retrievePatient() {
      try {
        const { data } = await patientService(patient_id);

        setPatient(data);
        setTitle(data.name);
      } catch (error) {
        toast(t('Não foi possível carregar os dados desse paciente.'), {
          variant: 'error'
        });
      }
    }

    async function retrievePatientHistory() {
      try {
        const { data } = await patientHistoryService(patient_id);

        setHistory(data);
      } catch (error) {
        toast(t('Não foi possível carregar o histórico desse paciente.'), {
          variant: 'error'
        });
      }
    }

    retrievePatient();
    retrievePatientHistory();
  }, [patient_id]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header rightAction={null} />
      <Container>
        <SubHeaderContainer>
          <BackButtonContainer>
            <BackButton
              path={{
                pathname: location?.state?.referrer || '/consultorio',
                state: {
                  tab: location?.state?.tab || 'scheduled'
                }
              }}
            />
          </BackButtonContainer>
          <TitlePage>{t('Histórico do Paciente')}</TitlePage>
        </SubHeaderContainer>
        {patient && (
          <MainContent>
            <PatientInfoContainer>
              <PatientInfo patient={patient} />
            </PatientInfoContainer>
            <ConsultationContainer>
              <HistoryContainer>
                <History detail={{ history }} />
              </HistoryContainer>
            </ConsultationContainer>
          </MainContent>
        )}
      </Container>
      <Tawk />
      <Chatwoot />
    </>
  );
}
