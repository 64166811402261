import styled from 'styled-components/macro';
import { AiFillCamera } from 'react-icons/ai';
import logoGreen from '../../../assets/images/logo-green.png';
import clublogo from '../../../assets/images/clubmark.png';
import ImageOrName from '../../../components/ImageOrName';

export const Container = styled.div`
  padding: 40px 0;
  display: grid;
  grid-template-columns: 30% 60%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const FirstGrid = styled.div`
  padding: 20px;
  border-right: 1px solid #d7d7d7;
`;

export const SecondGrid = styled.div`
  padding: 20px;
`;

export const UserProfileCard = styled.div`
  position: relative;

  background-color: var(--caren-white);
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  max-width: 392px;
  height: 274px;
  padding: 15px;
  margin: 0 auto;
`;

export const UserImage = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  width: 130px;
  height: 130px;
  border-radius: 100%;
`;

export const CameraIcon = styled(AiFillCamera).attrs({
  size: 20,
  color: 'var(--caren-white)'
})`
  background-color: var(--caren-darkgreen);
  height: 35px;
  width: 35px;
  padding: 5px;
  border-radius: 50%;

  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const UserChangePhoto = styled.div`
  position: absolute;
  bottom: 5px;
  right: 0;
  background-color: var(--caren-greenblue);
  border-radius: 100%;
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 2px;
  }
`;

export const UserImageContainer = styled.div`
  position: absolute;
`;

export const LogoGreen = styled.img.attrs({ src: logoGreen })`
  width: 120px;
  position: absolute;
  right: 0;
`;

export const SpecialText = styled.p`
  color: var(--caren-lightgrey);
  font-size: 10px;
  font-weight: 900;
`;

export const LogoContainer = styled.div``;

export const UserPanel = styled.div`
  color: var(--caren-lightgrey);
`;

export const UserName = styled.p`
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  position: absolute;
  bottom: 8px;
`;

export const UserDetails = styled.p`
  font-size: 14px;
  line-height: 31px;
`;

export const CarenClubPanel = styled.div`
  color: #82d7ca;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  opacity: 0.51;
  text-align: left;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;

export const CarenClubText = styled.p``;

export const ClubmarkLogo = styled.img.attrs({
  src: clublogo
})``;
