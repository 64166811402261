import styled from 'styled-components/macro';
import { IoIosVideocam } from 'react-icons/io';
import searchIcon from '../../assets/images/search.png';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 50px 40px 50px 65px;
  min-height: calc(100vh - 95px);

  @media (max-width: 1140px) {
    padding: 50px 25px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FirstGrid = styled.div`
  width: 260px;
  flex-shrink: 0;
  margin-right: 70px;

  @media (max-width: 1140px) {
    margin-right: 25px;
  }
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 320px;
    margin: 0 0 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SecondGrid = styled.div`
  flex-grow: 1;

  @media (max-width: 1023px) {
    flex-grow: unset;
    width: 100%;
  }
`;

export const SearchInputContainer = styled.span`
  position: relative;
  width: calc(33% - 7px);

  @media (max-width: 1023px) {
    width: 100%;
  }

  img {
    position: absolute;
    top: 11px;
    left: 10px;
    pointer-events: none;
  }

  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`;

export const SearchInputIcon = styled.img.attrs({
  src: searchIcon
})`
  height: 20px;
  margin-top: 4px;
`;

export const SearchInput = styled.input.attrs({
  type: 'text'
})`
  background-color: white;
  color: var(--caren-lightgrey);
  width: 100%;
  font-size: 15px;
  padding-left: 38px;
  height: 50px;
  border-radius: 15px;

  &::placeholder {
    color: var(--caren-lightgrey);
  }
`;

export const DoctorName = styled.p`
  color: var(--caren-icongrey);
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
`;

export const DoctorSpec = styled.p`
  color: var(--caren-icongrey);
  font-size: 16px;
  text-align: center;
`;

export const DoctorSchedule = styled.div`
  background-color: #ffffff;
  max-width: 250px;
  border-radius: 3px;
  padding: 15px 10px;
  height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    color: var(--caren-icongrey);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const DoctorTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 15px;
  width: 250px;
`;

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DoctorTitle = styled.div`
  color: var(--caren-icongrey);
  font-size: 16px;
  font-weight: bold;
`;

export const DoctorTimeBox = styled.div`
  canvas {
    margin: 30px 0;
  }
`;

export const DoctorCamIcon = styled(IoIosVideocam).attrs({
  color: '#969faa',
  size: 50
})``;

export const DoctorTimeTitle = styled.p`
  color: var(--caren-icongrey);
  font-size: 14px;
`;

export const SecondHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 40px;

  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0 0 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    color: var(--caren-icongrey);
    font-size: 16px;
    font-weight: bold;
  }
`;

export const HistoryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

export const PendingQuestionnaireModalButton = styled.button`
  padding: 5px 25px;
  background: #ff9052;
  border-radius: 5px;
  color: #ffff;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin-left: 35px;

  span {
    position: relative;
    top: 2px;
    margin-right: 7px;
  }
`;

export const PendingQuestionnaireModalDiv = styled.div`
  width: 50%;
  align-self: center;
`;

export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 22px;
  border-radius: 15px;

  > span {
    color: #47525e;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 1140px) {
      font-size: 14px;
    }
  }
`;

export const WrapperLoading = styled.div<{ size?: number }>`
  display: inline-block;
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  margin: 0 auto;
  position: relative;

  @keyframes progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: #3995a9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1140px) {
    display: none;
  }
`;

export const WrapperBody = styled.div`
  width: 100%;
  margin-top: 30px;

  @media (max-width: 1140px) {
    margin: 0;
  }
`;

export const WrapperBodyPatient = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  &[data-inactive='true'] {
    opacity: 0.3;
    pointer-events: none;
  }

  @media (max-width: 1140px) {
    flex-direction: column;
    width: 100%;
  }

  .patient-link {
    position: absolute;
    top: -16px;
    right: 0;
    bottom: -16px;
    left: 0;
    z-index: 5;
  }

  &::before {
    content: '';
    background-color: #f8f8f8;
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -22px;
    left: -22px;
    transform-origin: 0% 50%;
    transform: scaleX(0);
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    z-index: 1;

    @media (max-width: 1140px) {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    &:hover {
      &::before {
        transform: scaleX(1);
      }

      .patient-name {
        opacity: 0;
        user-select: none;
        pointer-events: initial;
      }

      .patient-email {
        opacity: 1;
        user-select: initial;
        pointer-events: initial;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d7d7d7;
  }
`;

export const WrapperItem = styled.div<{ actions?: boolean }>`
  color: #969faa;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  z-index: ${props => (props?.actions ? 10 : 2)};

  @media (max-width: 1140px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &:nth-child(1),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    flex-shrink: 0;
  }

  @media (min-width: 1024px) {
    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      flex-grow: 1;
      padding-right: 10px;
      white-space: pre-wrap;
    }

    &:nth-child(3) {
      min-width: 165px;
      width: 18%;
    }

    &:nth-child(4) {
      min-width: 140px;
      width: 11%;
    }

    &:nth-child(5) {
      width: 100px;
    }
  }

  @media (min-width: 1140px) {
    &:nth-child(5) {
      z-index: 10;

      a,
      button {
        pointer-events: initial;
      }
    }
  }

  > * {
    &:not(:last-child) {
      margin-right: 10px;

      @media (max-width: 1140px) {
        margin-right: 30px;
      }
    }
  }

  > span {
    color: #969faa;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    display: none;
    width: 100%;
    margin: 0 0 4px !important;
    text-align: center;

    @media (max-width: 1140px) {
      display: block;
    }
  }

  button,
  a {
    background-color: transparent;

    svg {
      &[data-warning='true'] {
        path {
          fill: var(--caren-orange);
        }
      }

      path {
        fill: #969faa;
        transition-property: fill;
        transition-duration: 0.5s;
        transition-timing-function: ease;
      }
    }

    &:hover svg {
      &[data-warning='true'] {
        path {
          fill: var(--caren-warning);
        }
      }

      path {
        fill: #53d3b0;
      }
    }
  }
`;

export const WrapperItemPatient = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 25px;

  @media (max-width: 1140px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const WrapperItemPatientImage = styled.div`
  display: flex;
  align-items: center;
  margin-right: 18px;

  @media (max-width: 1140px) {
    margin: 0 0 10px;
  }

  span {
    color: #47525e;
  }
`;

export const WrapperItemPatientName = styled.div`
  color: #47525e;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  font-weight: 600;
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  @media (max-width: 1140px) {
    max-width: 100%;
    text-align: center;
  }
`;

export const SectionTitle = styled.p`
  width: 100%;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 10px;
  color: var(${props => props.color});
  border-bottom: 1px solid rgba(215, 215, 215, 0.5);
`;

export const ConsultsTable = styled.table`
  table-layout: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  font-family: 'Nunito Sans', sans-serif;
  color: #969faa;
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 640px) {
    display: inline-table;
  }

  > thead {
    display: none;

    @media (min-width: 640px) {
      display: table-header-group;
    }

    th {
      padding: 4px 6px;

      &.center {
        text-align: center;
      }
    }
  }

  > tbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (min-width: 640px) {
      display: table-row-group;
    }

    tr {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      border-bottom: 1px solid var(--caren-input-border);
      padding-bottom: 10px;

      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      @media (min-width: 640px) {
        display: table-row;
        border: none;
        padding-bottom: 0;
      }
    }

    td {
      padding: 10px 6px;

      &.center {
        text-align: center;
      }

      > span {
        display: block;
        font-weight: bold;
        width: 100%;
        text-align: center;

        @media (min-width: 640px) {
          display: none;
        }
      }

      p {
        position: relative;

        span {
          background-color: var(--caren-clockgrey);
          color: white;
          padding: 8px 10px;
          border-radius: 8px;
          white-space: nowrap;
          position: absolute;
          top: calc(50% - 2px);
          right: calc(100% + 10px);
          pointer-events: none;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 10;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &:hover span {
          opacity: 1;
        }
      }

      p.warning {
        color: var(--caren-orange);
      }

      p.error {
        color: var(--caren-red);
      }

      p.check,
      button.check svg path {
        color: var(--caren-action-button);
        fill: var(--caren-action-button);
      }

      p.progress {
        color: var(--caren-bluebtn);
      }

      div.anamnese {
        color: var(--caren-orange);
        fill: var(--caren-orange);
      }

      button,
      a {
        background-color: transparent;

        svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-orange);
            }
          }

          path {
            fill: #969faa;
            transition-property: fill;
            transition-duration: 0.5s;
            transition-timing-function: ease;
          }
        }

        &:hover svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-warning);
            }
          }

          path {
            fill: #53d3b0;
          }
        }
      }

      a.warning-button {
        color: var(--caren-orange);
        border: 2px solid var(--caren-orange);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        border-radius: 10px;
        padding: 0 4px;
        text-align: center;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-warning);
          border-color: var(--caren-warning);
          color: var(--caren-white);
        }
      }

      a.green-button {
        color: var(--caren-action-button);
        border: 2px solid var(--caren-action-button);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: #53d3b0;
          border-color: #53d3b0;
          color: var(--caren-white);
        }
      }

      a.blue-button {
        color: var(--caren-bluebtn);
        border: 2px solid var(--caren-bluebtn);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-lightblue);
          border-color: var(--caren-lightblue);
          color: var(--caren-white);
        }
      }

      div.perfil {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        @media (min-width: 640px) {
          flex-direction: row;
        }
      }
    }
  }
`;
