import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Modal from '../index';
import {
  ModalWrapper,
  ModalButton,
  MaskInput,
  ErrorMessageText
} from './styles';
import history from '../../../services/history';
import { useToast } from '../../../contexts/ToastContext';
import onlyDigits from '../../../utils/onlyDigits';
import { loginByInvitationFiscalCode } from '../../../services/login-by-invitation-fiscal-code';
import { useAuth } from '../../../hooks/AuthContext';

export default function ValidateCpfModal({
  invitationKey,
  responseToken,
  open,
  setOpen
}) {
  const { persistSession } = useAuth();
  const { t } = useTranslation(['common']);
  const toast = useToast();

  const [hasExpired, setHasExpired] = useState(false);
  const [modalTitle, setModalTitle] = useState(
    'Para continuar, por favor confirme\no número de CPF do paciente.'
  );
  const [fiscalCode, setFiscalCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [sending, setSending] = useState(false);

  function handleToggleModal() {
    setOpen(!open);
  }

  function onChangeFiscalCode(e) {
    setFiscalCode(e.target.value);
  }

  function validateFiscalCode() {
    return onlyDigits(fiscalCode).length >= 11;
  }

  async function onSubmit(_e) {
    setSending(true);
    setErrorMessage(null);
    try {
      const token = await loginByInvitationFiscalCode(
        invitationKey,
        responseToken,
        onlyDigits(fiscalCode)
      );

      persistSession(token);
    } catch (error) {
      if (error?.response?.status === 401) {
        const remainingTries = error?.response?.data?.error?.remaining_attempts;
        if (remainingTries === 0) {
          setHasExpired(true);
          setModalTitle(
            'Este link foi bloqueado por excesso de tentativas, por favor entre em contato com seu médico.'
          );
        } else if (remainingTries === 1) {
          setErrorMessage(
            'O CPF informado está incorreto, tente novamente ou entre em contato com seu médico.\nPor questões de segurança esse link será bloqueado caso o CPF informado esteja incorreto.'
          );
        } else {
          setErrorMessage(
            `O CPF informado está incorreto, tente novamente ou entre em contato com seu médico.\nVocê possui mais ${remainingTries} tentativas.`
          );
        }
      } else {
        const responseError = error?.response?.data?.error?.message || '';

        if (responseError.includes('reached the maximum attempts')) {
          setHasExpired(true);
          setModalTitle(
            'Este link foi bloqueado por excesso de tentativas, por favor entre em contato com seu médico.'
          );
        } else {
          toast(
            t(
              'Ocorreu um erro ao verificar se o CPF estava correto, por favor tente novamente.'
            ),
            { variant: 'error' }
          );
        }
      }
    }
    setSending(false);
  }

  function onQuit() {
    history.push('/');
  }

  const renderContent = () => (
    <ModalWrapper>
      {!hasExpired ? (
        <>
          <div className="mx-auto w-1/2">
            <MaskInput
              mask="999.999.999-99"
              value={fiscalCode}
              onChange={onChangeFiscalCode}
            />
          </div>
          {errorMessage && <ErrorMessageText>{errorMessage}</ErrorMessageText>}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {sending ? (
              <ModalButton bgColor="var(--caren-greenblue)" disabled>
                <CircularProgress
                  size={14}
                  style={{ color: 'var(--caren-white)' }}
                />
              </ModalButton>
            ) : (
              <ModalButton
                bgColor="var(--caren-greenblue)"
                disabled={!validateFiscalCode()}
                onClick={onSubmit}
              >
                Enviar
              </ModalButton>
            )}
          </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ModalButton bgColor="var(--caren-greenblue)" onClick={onQuit}>
              Sair
            </ModalButton>
          </div>
        </>
      )}
    </ModalWrapper>
  );

  return (
    <Modal
      handleOpenModal={handleToggleModal}
      openModal={open}
      modalTitle={modalTitle}
      modalTitleColor="black"
      modalWidth="660px"
      noClosing
      noClosingOutside
    >
      {renderContent()}
    </Modal>
  );
}
