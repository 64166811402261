import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MdFileDownload } from 'react-icons/md';

const useStyles = makeStyles({
  overlay: {
    position: 'relative',
    margin: '10px 0',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: 0,
      transition: 'opacity 0.2s',
    },

    '&:hover, &:focus': {
      '&:before': {
        opacity: 0.2,
      },

      '& svg': {
        opacity: 1,
      },
    },

    '& svg': {
      opacity: 0,
      transition: 'opacity 0.2s',
    },
  },

  overlayIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    height: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const DownloadOverlay = ({ url, children }) => {
  const classes = useStyles();

  return (
    <a target="_blank" download href={url} rel="noreferrer">
      <div className={classes.overlay}>
        {children}
        <MdFileDownload className={classes.overlayIcon} color="white" />
      </div>
    </a>
  );
};

export default DownloadOverlay;
