import styled from 'styled-components/macro';
import { IoIosVideocam } from 'react-icons/io';
import { TiMessageTyping } from 'react-icons/ti';

export const DoctorSchedule = styled.div`
  background-color: #ffffff;
  max-width: 100%;
  border-radius: 15px;
  padding: 15px 10px;
  margin: 0 auto;
  flex: 1;
`;

export const DoctorTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DoctorTitle = styled.div`
  color: var(--caren-icongrey);
  font-size: 14px;
`;

export const DoctorCamIcon = styled(IoIosVideocam).attrs({
  color: '#969faa',
  size: '35%'
})`
  position: absolute;
  margin-left: 1px;
`;

export const PreAnswer = styled.div`
  width: 250px;
  margin-top: 40px;
  background-color: #969faa22;
  border: 1px solid #969faa;
  color: #969faa;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 5px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PreAnswerIcon = styled(TiMessageTyping).attrs({
  size: 25,
  color: 'var(--caren-green)'
})`
  margin-left: 10px;
`;

export const PatientContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 10px;
  padding: 22px 0;
`;

export const PatientContentImage = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`;

export const PatientContentName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  a,
  button {
    background-color: transparent;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 5px;
    }

    svg {
      display: block;
      position: relative;
      top: -3px;

      path {
        fill: #077a94;
        transition-property: fill;
        transition-duration: 0.3s;
        transition-timing-function: ease;
      }
    }

    span {
      color: #077a94;
      font-size: 16px;
      font-weight: bold;
      max-width: 150px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition-property: fill;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }

    &:hover {
      svg path {
        fill: #3995a9;
      }

      span {
        color: #3995a9;
      }
    }
  }
`;

export const PatientContentDate = styled.div`
  color: #455054;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const PatientContentClock = styled.div`
  margin-top: 10px;

  .patient-clock-orange {
    div:nth-child(1) {
      background-color: transparent;
      color: #ff9052;
      font-size: 12px;
      font-weight: bold;
      padding: 0;
      height: auto;
      text-align: center;
      line-height: unset;
    }

    div:nth-child(2) {
      background-color: transparent;
      color: #ff9052;
      font-size: 26px;
      font-weight: bold;
    }
  }

  > a {
    color: #ff9052;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid #ff9052;
    transition-property: color, border-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    &:hover {
      color: #3995a9;
      border-color: #3995a9;
    }
  }
`;

export const PatientContentCTA = styled.div`
  margin-top: 15px;
  width: 100%;
  padding: 0 18px;

  a {
    background-color: #ff9052;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 38px;
    border-radius: 8px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    svg {
      display: inline-block;
      font-size: 20px;
      flex-shrink: 0;
      margin-right: 5px;

      polygon,
      rect {
        fill: white;
        stroke: white;
      }
    }

    span {
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
    }

    &:hover {
      background-color: #3995a9;
    }
  }
`;
