import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';

import { ConfirmButton, DenyButton, ButtonsContainer } from './styles';

export default function UnreversibleConfirmationModal({
  openModal,
  setOpenModal,
  handleConfirmation,
  title,
  cancelButtonText,
  confirmButtonText,
}) {
  const { t } = useTranslation(['common']);

  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  return (
    <Modal
      handleOpenModal={handleOpenModal}
      openModal={openModal}
      modalTitle={title}
      modalWidth="40%"
      modalTitleColor="var(--caren-icongrey)"
    >
      <ButtonsContainer>
        <DenyButton onClick={() => handleConfirmation(false)}>
          {cancelButtonText || t('UnreversibleConfirmationModal Voltar')}
        </DenyButton>
        <ConfirmButton onClick={() => handleConfirmation(true)}>
          {confirmButtonText || t('UnreversibleConfirmationModal Confirmar')}
        </ConfirmButton>
      </ButtonsContainer>
    </Modal>
  );
}
