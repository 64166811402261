import styled from 'styled-components/macro';
import searchIcon from '../../assets/images/search.png';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const FirstGrid = styled.div`
  background-color: #f6f7f8;
  padding: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SecondGrid = styled.div`
  padding: 20px;
`;

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubgridTitle = styled.div`
  min-height: 32px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor && 'var(--caren-subheader)'};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 5px;
  margin-bottom: 15px;
  p {
    color: var(--caren-white);
    font-size: 12px;
  }
`;

export const ResponseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;

  text-align: center;

  p {
    font-size: 28px;
    color: var(--caren-icongrey);
  }
`;

export const SearchIcon = styled.img.attrs({
  src: searchIcon
})`
  height: 40px;
  cursor: pointer;
`;

export const SubHeaderContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SearchButtonContainer = styled.div`
  text-align: right;
`;

export const SelectBox = styled.div`
  position: relative;
  display: inline-block;
  height: 30px;
  top: 10px;
  background-color: transparent;

  span {
    position: absolute;
    font-size: 14px;
    top: -15px;
    color: var(--caren-grey);
    font-weight: bold;
    margin-left: 8px;
  }
`;

export const SelectIcon = styled.img``;

export const Select = styled.select`
  border-color: transparent;
  height: 30px;
  font-size: 15px;
  background-color: transparent;
  width: 100%;
  color: var(--caren-lightgrey);
`;
