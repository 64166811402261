import React, { useEffect, useState } from 'react';
import moment from 'moment';
import '@fullcalendar/react/dist/vdom';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../contexts/ToastContext';
import formattedTime from '../../../../utils/formattedTime';
import convertWeekdayToNumber from '../../../../utils/convertWeekdayToNumber';
import convertNumberToWeekday from '../../../../utils/convertNumberToWeekday';
import Slider from '../../../Slider';
import { DisplayFlex } from '../../../DisplayFlex';
import UnreversibleConfirmationModal from '../../../UnreversibleConfirmationModal';
import {
  doctorRecurringHours,
  doctorRemoveRecurringHour,
  doctorUpdateRecurringHour,
  doctorCreateRecurring,
  doctorRecurringAvailabilities,
  doctorUpdateTeleconsultationSettings,
} from '../../../../services/doctors.service';

import CreateNewRecurrence from '../../../CreateNewRecurrence';

import {
  FormContainer,
  UpdateDurationButton,
  CalendarContainer,
  SectionContainer,
  RemoveIcon,
  // InlineInputPresential,
} from './styles';

import { useAuth } from '../../../../hooks/AuthContext';
import { FORMAT } from '../../../../utils/moment/momentFormat';
import {
  WEEKDAY_NEXT,
  WEEKDAY_PREVIOUS,
  WEEKDAY_TRANSLATION,
} from '../../../../utils/weekdaysTranslation';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--caren-greenblue)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function MoreDetails() {
  const {
    credentials,
    refreshSession,
  } = useAuth();

  const { t } = useTranslation(['doctor_profile']);

  const useStyles = makeStyles({
    root: {
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
    inputLabel: {
      textTransform: 'uppercase',
      marginRight: 20,
      display: 'inline-block',
    },
  });

  const classes = useStyles();

  // newDialogStep
  const STEP_SLOT_RANGE = 0;
  const STEP_SCHEDULE = 1;

  // editDialogStep
  const STEP_DETAIL = 0;
  const STEP_EDIT = 1;
  const STEP_DELETE = 2;

  const [activeDefault, _setActiveDefault] = useState(true);
  const [activeReturning, setActiveReturning] = useState(false);

  const toast = useToast();
  const [recurringEvents, setRecurringEvents] = useState([]);
  const [reRenderHours, setReRenderHours] = useState(false);
  const [openNewRecurrenceDialog, setOpenNewRecurrenceDialog] = useState(false);
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [newDialogStep, setNewDialogStep] = useState(STEP_SLOT_RANGE);
  const [step, setStep] = useState(STEP_DETAIL);
  const [newRecurrence, setNewRecurrence] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [
    teleconsultationSettingsPayload,
    setTeleconsultationSettingsPayload,
  ] = useState({});

  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const [fetchPreviews, setFetchPreviews] = useState([]);

  // Make sure weekdays account for UTC-shifts
  // Example:
  //   An availability created for FRIDAYS at 21:30 UTC-3, is actually an
  //   availability created for SATURDAYS at 00:30 UTC
  const shiftWeekdays = (recurrence, mapping) => {
    if (recurrence?.weekdays?.length > 0) {
      return {
        ...recurrence,
        weekdays: recurrence.weekdays.map((weekday) => mapping[weekday]),
      };
    }
    if (recurrence?.weekday != null) {
      return { ...recurrence, weekday: mapping[recurrence.weekday] };
    }
    return recurrence;
  };

  const convertUTCWeekdayToLocal = (
    recurrence,
    startTimeField = 'start_time'
  ) => {
    const localOffset = moment().utcOffset();

    const localStart = moment
      .utc(recurrence[startTimeField], FORMAT['hour-minute-second'])
      .local();
    const utcStart = localStart.clone().subtract(localOffset, 'minutes');

    if (utcStart.isBefore(localStart, 'day'))
      return shiftWeekdays(recurrence, WEEKDAY_NEXT);

    if (utcStart.isAfter(localStart, 'day'))
      return shiftWeekdays(recurrence, WEEKDAY_PREVIOUS);

    return recurrence;
  };

  const convertLocalWeekdayToUTC = (
    recurrence,
    startTimeField = 'start_time'
  ) => {
    const localOffset = moment().utcOffset();

    const localStart = moment
      .utc(recurrence[startTimeField], FORMAT['hour-minute-second'])
      .local();
    const utcStart = localStart.clone().subtract(localOffset, 'minutes');

    if (utcStart.isAfter(localStart, 'day'))
      return shiftWeekdays(recurrence, WEEKDAY_NEXT);

    if (utcStart.isBefore(localStart, 'day'))
      return shiftWeekdays(recurrence, WEEKDAY_PREVIOUS);

    return recurrence;
  };

  async function handleEnsureSalve() {
    try {
      await doctorCreateRecurring({ availabilities: fetchPreviews });
      setReRenderHours((prevState) => !prevState);
      toast(t('Horários cadastrados com sucesso'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Ops, estamos passando por instabilidades...'), {
        variant: 'error',
      });
    }
  }

  async function handleEditRecurring(recurrence) {
    try {
      await doctorUpdateRecurringHour(selectedEvent?.id, recurrence);
      setReRenderHours((prevState) => !prevState);
      toast(t('Horário atualizado com sucesso.'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Este horário já está alocado.'), {
        variant: 'error',
      });
    }
  }

  async function handleRemoveHour(recurringKey) {
    try {
      await doctorRemoveRecurringHour(recurringKey);
      setReRenderHours((prevState) => !prevState);
      toast(t('Horário removido com sucesso.'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Ops, estamos passando por instabilidades...'), {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    // setActiveDefault(
    //   Boolean(session?.teleconsultation_settings?.default?.duration)
    // );
    setActiveReturning(
      Boolean(credentials?.session?.teleconsultation_settings?.returning?.duration)
    );
  }, [credentials]);

  const calculateEndTime = (duration, start_datetime) => {
    const formattedStart = moment.utc(start_datetime).local();
    const startOfDay = moment(formattedStart).startOf('day');
    const endDatetime = moment(formattedStart).add(duration, 'seconds');

    const ms = endDatetime.diff(startOfDay);
    const d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  };

  useEffect(() => {
    async function getDoctorHours() {
      const { data } = await doctorRecurringHours();
      if (data) {
        const adjustedHours = [...data.data.map(convertUTCWeekdayToLocal)];
        const events = adjustedHours.map((event) => ({
          // start: formattedDate(event.start_date),
          startTime: formattedTime(event.start_time),
          endTime: calculateEndTime(event.duration, event.start_datetime),
          daysOfWeek: [`${convertWeekdayToNumber(event.weekday)}`],
          id: event.key,
          isValid: event.is_valid,
          color: event.is_valid ? '#00A0B1' : '#FF450C',
          title: event.is_valid ? '' : '⚠️',
        }));
        setRecurringEvents(events);
      }
    }

    getDoctorHours();
  }, [reRenderHours]);

  // const formattedType = {
  //   default: 'consulta',
  //   returning: 'retorno',
  // };

  function confirmUpdateTeleconsultationSettings(dataParams) {
    const formData = { ...dataParams };

    ['default', 'returning', 'base'].forEach((type) => {
      if (formData.teleconsultation_settings?.[type]?.billing_amount) {
        formData.teleconsultation_settings[
          type
        ].billing_amount = formData.teleconsultation_settings?.[
          type
        ]?.billing_amount
          .replace(/\./g, '')
          .replace(/,/g, '.')
          .replace(/[^0-9.]+/g, '');
      }
    });

    // formData.teleconsultation_settings.base.duration =
    //   formData.teleconsultation_settings.default.duration || 0;

    if (!activeDefault) {
      formData.teleconsultation_settings.default = null;
    }
    if (!activeReturning) {
      formData.teleconsultation_settings.returning = null;
    }

    const payload = {
      default: formData.teleconsultation_settings.default,
      returning: formData.teleconsultation_settings.returning,
      base: formData.teleconsultation_settings.base,
    };

    setDeleteConfirmationOpen(true);
    setTeleconsultationSettingsPayload(payload);
  }

  async function handleUpdateTeleconsultationSettings(confirmed) {
    if (confirmed) {
      try {
        await doctorUpdateTeleconsultationSettings(
          teleconsultationSettingsPayload
        );
        if (credentials) {
          refreshSession(credentials.token);
        }
        toast(t('Configurações atualizadas com sucesso!'), {
          variant: 'success',
        });
        setReRenderHours((prevState) => !prevState);
      } catch (error) {
        toast(t('Verifique se os campos estão preenchidos corretamente.'), {
          variant: 'error',
        });
      }
    }

    setDeleteConfirmationOpen(false);
    setTeleconsultationSettingsPayload({});
  }

  const formattedCurrency = (currency) =>
    (currency || '').replace(/^(R\$\s*)/g, '').replace(/\./g, ',');

  // const changeActiveDefault = () => setActiveDefault((prev) => !prev);
  // const changeActiveReturning = () => setActiveReturning((prev) => !prev);

  const { handleSubmit: handleMoreDetailsSubmit, control } = useForm();

  async function handleSave() {
    setFetchPreviews([]);

    const adjusted = convertLocalWeekdayToUTC(newRecurrence, 'startTime');

    const payload = {
      date: new Date(),
      from: adjusted.startTime,
      to: adjusted.endTime,
      weekdays: adjusted.weekdays,
      appointment_type: '',
      interval: adjusted.interval,
    };
    return doctorRecurringAvailabilities(payload);
  }

  function handleRemovePreviewRow(row, _id) {
    setFetchPreviews((oldPreviews) => [
      ...oldPreviews.filter((prev) => prev !== row),
    ]);
  }

  function renderSchedule() {
    return (
      <Paper className={classes.root}>
        <TableContainer>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('Dia da Semana')}</StyledTableCell>
                <StyledTableCell align="center">
                  {t('Hora de Início')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t('Hora de Término')}
                </StyledTableCell>
                <StyledTableCell align="center">{t('Remover')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchPreviews.map((sub, subIndex) => (
                <StyledTableRow key={subIndex.toString()}>
                  <StyledTableCell component="th" scope="row">
                    {WEEKDAY_TRANSLATION[sub.weekday]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {formattedTime(sub.start_time)}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center"> */}
                  {/*   {formattedTime(sub.end_time)} */}
                  {/* </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <RemoveIcon
                      onClick={() => handleRemovePreviewRow(sub, subIndex)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  const handleClickOpenNewRecurrenceDialog = () => {
    setNewDialogStep(STEP_DETAIL);
    setOpenNewRecurrenceDialog(true);
  };

  const handleCloseNewRecurrenceDialog = () => {
    setOpenNewRecurrenceDialog(false);
  };

  const handleConcludeNewRecurrence = () => {
    setConfirmButtonDisabled(true);
    handleEnsureSalve();
    setOpenNewRecurrenceDialog(false);
  };

  function handleUpdateNewRecurrence(updated) {
    setNewRecurrence({
      startDate: updated.date,
      startTime: updated.from,
      endTime: updated.to,
      weekdays: updated.weekdays,
      interval: updated.interval,
    });
  }

  const handleConcludeEditRecurrence = () => {
    const hour = (_date) =>
      moment.utc(_date).format(FORMAT['hour-minute-second']);

    const recurrence = {
      appointment_type: 'default',
      start_time: hour(selectedEvent?.startTime),
      end_time: hour(selectedEvent?.endTime),
    };

    handleEditRecurring(recurrence);
    setOpenEditEventDialog(false);
  };

  function handleUpdateSelectedEvent(updated) {
    setSelectedEvent({
      id: selectedEvent.id,
      startTime: updated.from,
      endTime: updated.to,
      weekdays: updated.weekdays,
    });
  }

  async function handleScheduleStep() {
    try {
      const { data } = await handleSave();
      setFetchPreviews(
        data.data.map((preview) => convertUTCWeekdayToLocal(preview))
      );
      setConfirmButtonDisabled(false);
      setNewDialogStep(STEP_SCHEDULE);
    } catch (error) {
      toast(t('Selecione uma disponibilidade válida'), {
        variant: 'warning',
      });
    }
  }

  function renderNewRecurrenceDialog() {
    if (newDialogStep === STEP_SLOT_RANGE) {
      return (
        <Dialog
          open={openNewRecurrenceDialog}
          onClose={handleCloseNewRecurrenceDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('Cadastrar Recorrência')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <CreateNewRecurrence
                recurrence={{
                  from: newRecurrence.startTime,
                  to: newRecurrence.endTime,
                  weekdays: newRecurrence.weekdays,
                  interval: newRecurrence.interval,
                }}
                handleUpdateRecurrence={handleUpdateNewRecurrence}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewRecurrenceDialog}>
              {t('Cancelar')}
            </Button>
            <Button
              disabled={
                newRecurrence?.weekdays?.length === 0 ||
                !newRecurrence?.interval ||
                !newRecurrence.startTime ||
                !newRecurrence.endTime
              }
              onClick={handleScheduleStep}
              autoFocus
            >
              {t('Prosseguir')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    if (newDialogStep === STEP_SCHEDULE) {
      return (
        <Dialog
          maxWidth="lg"
          open={openNewRecurrenceDialog}
          onClose={handleCloseNewRecurrenceDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('Cadastrar Recorrência')}
          </DialogTitle>
          <DialogContent>{renderSchedule()}</DialogContent>
          <DialogActions>
            <Button onClick={() => setNewDialogStep(STEP_SLOT_RANGE)}>
              {t('Voltar')}
            </Button>
            <Button
              disabled={confirmButtonDisabled || fetchPreviews.length === 0}
              onClick={handleConcludeNewRecurrence}
              autoFocus
            >
              {t('Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  }

  const handleClickOpenEditEventDialog = () => {
    setStep(STEP_DETAIL);
    setOpenEditEventDialog(true);
  };

  const handleCloseEditEventDialog = () => {
    setOpenEditEventDialog(false);
  };

  const handleRemoveEvent = () => {
    handleRemoveHour(selectedEvent.id);
    setOpenEditEventDialog(false);
  };

  function renderEditEventDialog() {
    if (step === STEP_DETAIL) {
      return (
        <Dialog
          open={openEditEventDialog}
          onClose={handleCloseEditEventDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{ marginRight: '80px' }}>
              {selectedEvent?.isValid
                ? t('Horário disponível')
                : t('Horário inválido')}
            </span>
            {/* <Tooltip title={t('Editar evento')}>
              <IconButton onClick={() => setStep(STEP_EDIT)}>
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title={t('Deletar evento')}>
              <IconButton onClick={() => setStep(STEP_DELETE)}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
            <IconButton onClick={handleCloseEditEventDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText />
            <DialogContentText>
              {moment(selectedEvent?.startTime).format('dddd')} -{' '}
              {moment(selectedEvent?.startTime).format('HH:mm')} até{' '}
              {moment(selectedEvent?.endTime).format('HH:mm')}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      );
    }

    if (step === STEP_EDIT) {
      return (
        <Dialog
          open={openEditEventDialog}
          onClose={handleCloseEditEventDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span>{t('Editar Recorrência')}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <CreateNewRecurrence
                recurrence={{
                  from: selectedEvent?.startTime,
                  to: selectedEvent?.endTime,
                  weekdays: selectedEvent?.weekdays,
                }}
                handleUpdateRecurrence={handleUpdateSelectedEvent}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditEventDialog}>Cancelar</Button>
            <Button
              disabled={newRecurrence?.weekdays?.length === 0}
              onClick={handleConcludeEditRecurrence}
              autoFocus
            >
              {t('Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    if (step === STEP_DELETE) {
      return (
        <Dialog
          open={openEditEventDialog}
          onClose={handleCloseEditEventDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span>{t('Deletar Recorrência')}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText />
            <DialogContentText>
              {moment(selectedEvent?.startTime).format('dddd')} -{' '}
              {moment(selectedEvent?.startTime).format('HH:mm')} até{' '}
              {moment(selectedEvent?.endTime).format('HH:mm')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditEventDialog}>Cancelar</Button>
            <Button onClick={handleRemoveEvent} autoFocus>
              {t('Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  }

  return (
    <>
      <FormContainer
        onSubmit={handleMoreDetailsSubmit(
          confirmUpdateTeleconsultationSettings
        )}
      >
        <DisplayFlex align="center" justify="space-between">
          <p>{t('HORÁRIOS')}</p>
        </DisplayFlex>
        <Controller
          control={control}
          as={
            <Slider isActived labelTitle={t('Consulta')}>
              <Controller
                control={control}
                as={
                  <UpdateDurationButton>{t('Atualizar')}</UpdateDurationButton>
                }
                name="teleconsultation_settings.default.billing_amount"
                defaultValue={formattedCurrency(
                  credentials?.session.teleconsultation_settings?.default?.billing_amount
                )}
              />
            </Slider>
          }
          name="teleconsultation_settings.default.duration"
          defaultValue={
            credentials?.session.teleconsultation_settings?.default?.duration || 40
          }
        />
        {/* <Controller
          control={control}
          as={
            <Slider
              isActived={activeReturning}
              changeActiveReturning={changeActiveReturning}
              labelTitle="Retorno "
            >
              <Controller
                control={control}
                as={
                  <InlineInput
                    placeholder="Valor da Consulta de retorno"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$"
                    allowNegative={false}
                    decimalScale={2}
                  />
                }
                name="teleconsultation_settings.returning.billing_amount"
                defaultValue={formattedCurrency(
                  session.teleconsultation_settings?.returning?.billing_amount
                )}
              />
            </Slider>
          }
          name="teleconsultation_settings.returning.duration"
          defaultValue={
            session.teleconsultation_settings?.returning?.duration || 20
          }
        /> */}
        <DisplayFlex align="flex-end">
          {/* <div>
            <p className={classes.inputLabel}>
              Valor de referência para consulta presencial:
            </p>
            <Controller
              control={control}
              as={
                <InlineInputPresential
                  placeholder="Valor da Consulta presencial"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$"
                  allowNegative={false}
                  decimalScale={2}
                />
              }
              name="teleconsultation_settings.base.billing_amount"
              defaultValue={formattedCurrency(
                session.teleconsultation_settings?.base?.billing_amount
              )}
            />
          </div> */}
        </DisplayFlex>
      </FormContainer>
      <SectionContainer>
        <CalendarContainer>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            themeSystem="bootstrap"
            contentHeight="420px"
            stickyFooterScrollbar
            scrollTime={moment().format('HH:mm:ss')}
            dayHeaderFormat={{
              weekday: 'long',
              // month: 'numeric',
              // day: 'numeric',
              omitCommas: true,
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            }}
            locale="pt-br"
            allDaySlot={false}
            nowIndicator
            headerToolbar={{
              start: '',
              center: '',
              end: '',
            }}
            events={recurringEvents}
            selectable
            dateClick={(info) => {
              const selectedDate = info.date;
              const startTime = selectedDate;
              const endTime = moment(selectedDate).add(1, 'hour').toDate();
              const weekdays = [
                convertNumberToWeekday(moment(selectedDate).day()),
              ];
              setNewRecurrence({
                startDate: selectedDate,
                startTime,
                endTime,
                weekdays,
                interval: '0',
              });
              handleClickOpenNewRecurrenceDialog();
            }}
            eventClick={(info) => {
              // console.log('Event clicked', moment(info.event.start).day());
              setSelectedEvent({
                id: info.event.id,
                startTime: info.event.start,
                endTime: info.event.end,
                weekdays: convertNumberToWeekday(
                  moment(info.event.start).day()
                ),
                isValid: info.event.extendedProps.isValid,
              });
              handleClickOpenEditEventDialog();
            }}
          />
          {renderNewRecurrenceDialog()}
          {renderEditEventDialog()}
        </CalendarContainer>
      </SectionContainer>
      <UnreversibleConfirmationModal
        title={t(
          'Ao atualizar esta configuração, será necessário cadastrar seus horários novamente.'
        )}
        openModal={deleteConfirmationOpen}
        setOpenModal={setDeleteConfirmationOpen}
        handleConfirmation={handleUpdateTeleconsultationSettings}
      />
    </>
  );
}

export default MoreDetails;
