import api from './api';

export const rateTeleconsultation = async (teleconsultationKey, payload) => {
  const { data } = api.put(
    `/teleconsultations/${teleconsultationKey}/rating`,
    payload
  );

  return data;
};
