import api from './api';

export const upsertClinicalEvaluationService = async (
  anamnesisKey,
  payload
) => {
  const { data } = await api.put(
    `/partner-appointments/${anamnesisKey}/clinical-evaluation`,
    payload
  );

  return data;
};
