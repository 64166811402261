import { useEffect, useState } from 'react';
import {
  telemedStatsCountService,
  telemedStatsService
} from '../../services/telemed-stats.service';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../hooks/AuthContext';
import StatsBox from './StatsBox';
import { useCurrentPlan } from '../../v2/hooks/plan/useCurrentPlan';
import { useFreePlan } from '../../v2/hooks/plan/useFreePlan';
import { Link } from 'react-router-dom';

type StatsCount = {
  total_confirmed_count: number;
  total_pending_appointment_count: number;
  total_pending_assessment_count: number;
  total_pending_subs_count: number;
  total_concluded_count: number;
};

export default function DoctorStatsCounter() {
  const { credentials } = useAuth();
  const { t } = useTranslation(['schedule']);
  const toast = useToast();

  const { data: currentPlan } = useCurrentPlan();
  const { data: freePlan } = useFreePlan();

  const [telemedStatsCount, setTelemedStatsCount] = useState<StatsCount | null>(
    null
  );
  const [telemedStats, setTelemedStats] = useState<{
    profile_completeness: number;
  } | null>(null);

  const canShowPartnersCards =
    credentials?.session.insurance_partners &&
    credentials.session.insurance_partners.length > 0;

  const firstPartnerBanner =
    credentials?.session.insurance_partners?.at(0)?.banner;

  useEffect(() => {
    async function getTelemedStatsService() {
      try {
        const {
          data: { counts: telemedStatsData }
        } = await telemedStatsCountService();
        setTelemedStatsCount(telemedStatsData);
        const { data: telemedData } = await telemedStatsService();
        setTelemedStats(telemedData);
      } catch (error) {
        toast(t('Ops, não foi possível carregar as informações...'), {
          variant: 'error'
        });
      }
    }

    getTelemedStatsService();
  }, []);

  return (
    <div className="flex flex-row flex-wrap justify-center md:justify-end">
      {canShowPartnersCards && firstPartnerBanner !== undefined && (
        <StatsBox title="Convênio Ativo" disabled>
          <img className="mt-5" src={firstPartnerBanner} alt="Partner" />
        </StatsBox>
      )}
      {freePlan && currentPlan && (
        <StatsBox
          title="Plano Atual"
          type={currentPlan && 'plan' in currentPlan ? 'full' : 'normal'}
          value={
            currentPlan && 'plan' in currentPlan
              ? currentPlan.plan.name.split(/\s/)[0]
              : 'Free'
          }
          size="small"
          disabled
        >
          {currentPlan && 'plan' in currentPlan ? undefined : (
            <>
              {freePlan.used === freePlan.total ? (
                <>
                  <p className="text-sm leading-tight text-gray-350">
                    0 consultas
                  </p>
                  <Link
                    className="text-xs text-success underline"
                    to={{ pathname: '/perfil', state: { view: 'plans' } }}
                  >
                    Assine agora
                  </Link>
                </>
              ) : (
                <p className="text-sm leading-tight text-gray-350">
                  {freePlan.total - freePlan.used} consultas grátis
                </p>
              )}
            </>
          )}
        </StatsBox>
      )}
      {telemedStats && telemedStats.profile_completeness !== 100 && (
        <StatsBox
          title="Status Perfil"
          link={
            telemedStats.profile_completeness !== 100
              ? { pathname: '/perfil' }
              : undefined
          }
          value={telemedStats.profile_completeness}
          subValue={'%'}
          size="small"
        />
      )}
      {telemedStatsCount && (
        <>
          <StatsBox
            title="Consultas Realizadas"
            link={
              telemedStatsCount.total_concluded_count > 0
                ? { pathname: '/' }
                : undefined
            }
            value={telemedStatsCount.total_concluded_count ?? 0}
            size={
              telemedStatsCount.total_concluded_count.toString().length > 3
                ? 'small'
                : 'medium'
            }
            color="green"
          />
          <StatsBox
            title="Consultas Criadas"
            link={
              telemedStatsCount.total_confirmed_count > 0
                ? { pathname: '/' }
                : undefined
            }
            value={telemedStatsCount.total_confirmed_count ?? 0}
            size={
              telemedStatsCount.total_confirmed_count.toString().length > 3
                ? 'small'
                : 'medium'
            }
            color="green"
          />
          {/* <StatsBox
            title="Consultas Pendentes"
            link={
              telemedStatsCount.total_pending_appointment_count > 0
                ? { pathname: '/agenda' }
                : undefined
            }
            value={telemedStatsCount.total_pending_appointment_count ?? 0}
            size={
              telemedStatsCount.total_pending_appointment_count.toString()
                .length > 3
                ? 'small'
                : 'medium'
            }
          /> */}
        </>
      )}
      {telemedStatsCount && canShowPartnersCards && (
        <>
          <StatsBox
            title="Assinaturas Pendentes"
            link={
              telemedStatsCount.total_pending_subs_count > 0
                ? { pathname: '/' }
                : undefined
            }
            value={telemedStatsCount.total_pending_subs_count ?? 0}
            size={
              telemedStatsCount.total_pending_subs_count.toString().length > 3
                ? 'small'
                : 'medium'
            }
          />
          <StatsBox
            title="Avaliações Pendentes"
            link={
              telemedStatsCount.total_pending_assessment_count > 0
                ? { pathname: '/' }
                : undefined
            }
            value={telemedStatsCount.total_pending_assessment_count ?? 0}
            size={
              telemedStatsCount.total_pending_assessment_count.toString()
                .length > 3
                ? 'small'
                : 'medium'
            }
          />
        </>
      )}
    </div>
  );
}
