import React, { useEffect, useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';
import { useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Lottie from 'react-lottie';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import Header from '../../components/Header';
import {
  Container,
  FindADoctorContainer,
  BackButtonContainer,
  FindADoctor,
  MainText,
  ProcessContainer,
  ProcessFooter,
  UserProcessContainer,
  UserProcessBackground,
  UserProcessImg,
  ProcessLine,
  Border,
  FooterText,
  VoucherCard,
  VoucherCardContent,
  VoucherCardFront,
  VoucherCardButton,
  VoucherCardContainer,
  MainIconContainer,
  MainIcon,
  NurseLogo,
} from './styles';
import CheckOutlineAnimation from '../../assets/animations/check-outline.json';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import { doctorDetailsService } from '../../services/doctors.service';
import { createConsultationService } from '../../services/create-consultation.service';
import { useAuth } from '../../hooks/AuthContext';
import formattedDate from '../../utils/formattedDate';
import getDoctorTitle from '../../utils/getDoctorTitle';
import { FORMAT } from '../../utils/moment/momentFormat';
import { createEmergencyConsultationService } from '../../services/create-emergency-consultation.service';
import { insurancePartnersApi } from '../../services/insurance-partners.service';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles({
  spinner: {
    color: 'var(--caren-darkgreen)',
  },

  otpContainer: {
    justifyContent: 'space-around',
  },

  otpInput: {
    lineHeight: '2.4em',
    width: '1.5em !important',
    fontSize: '1.5em',
    border: '1px solid var(--caren-lightgrey)',
    borderRadius: '5px',
  },
});

export default function TeleconsultationVoucher({
  computedMatch: {
    params: { id, doctor_id },
  },
}) {
  const {
    credentials,
  } = useAuth();

  const { t } = useTranslation(['teleconsultation_voucher']);
  const query = useQuery();
  const classes = useStyles();
  const scheduledDatetime = query.get('sd');
  const appointmentType = query.get('t');

  const dots = new Array(15).fill(true);

  const toast = useToast();
  const [doctor, setDoctor] = useState({});
  const [insurancePartner, setInsurancePartner] = useState({});
  const [voucherCode, setVoucherCode] = useState('');
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [consultationKey, setConsultationKey] = useState('');
  const [appointmentKey, setAppointmentKey] = useState('');
  const [emergencyKey, setEmergencyKey] = useState('');
  const TITLE = 'Sala de espera - Caren';
  const location = useLocation();
  const isEmergency = location.pathname.includes('/emergencia/');

  useEffect(() => {
    async function getDoctorDetailService() {
      const { data } = await doctorDetailsService(doctor_id);
      setDoctor(data);
    }

    if (doctor_id) getDoctorDetailService();
  }, [doctor_id]);

  useEffect(() => {
    const getInsurancePartners = async () => {
      const { data } = await insurancePartnersApi();
      setInsurancePartner(data.find((insurance) => insurance.key === id));
    };
    if (isEmergency) getInsurancePartners();
  }, [id]);

  async function handleSendVoucher(e) {
    e.preventDefault();

    setSending(true);
    try {
      await maybeCreateTeleconsultation();
    } catch (error) {
      setVoucherCode('');
      const message = error?.response?.data?.error?.message;

      if (message?.start_datetime) {
        if (
          message?.start_datetime?.join(', ').includes('Overlapping patient')
        ) {
          toast(t('Você já tem uma consulta neste horário!'), {
            variant: 'error',
          });
        } else {
          toast(t('Este horário não está mais disponível.'), {
            variant: 'error',
          });
        }
      } else {
        toast(t('Não foi possível validar o voucher.'), {
          variant: 'error',
        });
      }
    }
    setSending(false);
  }

  async function maybeCreateTeleconsultation() {
    if (isEmergency) {
      return handleEmergencyConsultationCreation();
    }

    return handleTeleconsultationCreation();
  }

  async function handleTeleconsultationCreation() {
    const { data } = await createConsultationService({
      doctor_key: doctor?.key,
      voucher: voucherCode,
      scheduled_datetime: scheduledDatetime,
      type: appointmentType,
    });
    toast(t('Consulta agendada com sucesso!'), {
      variant: 'success',
    });
    setSuccess(true);
    setConsultationKey(data.key);
  }

  async function handleEmergencyConsultationCreation() {
    const data = await createEmergencyConsultationService(
      insurancePartner.code,
      { voucher: voucherCode }
    );
    toast(t('Consulta agendada com sucesso!'), {
      variant: 'success',
    });
    setSuccess(true);
    setAppointmentKey(data.partner_appointment_key);
    setEmergencyKey(data.key);
  }

  const handleChangeVoucherCode = (newToken) => {
    setVoucherCode(newToken.toUpperCase());
  };

  const handleWhenNotSending = () => {
    if (!success) {
      return (
        <>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <OtpInput
            value={voucherCode}
            onChange={handleChangeVoucherCode}
            numInputs={10}
            containerStyle={classes.otpContainer}
            inputStyle={classes.otpInput}
          />
          <div>
            <VoucherCardButton
              type="submit"
              disabled={voucherCode.length < 10 || sending}
            >
              {t('Validar')}
            </VoucherCardButton>
          </div>
        </>
      );
    }

    return (
      <>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: CheckOutlineAnimation,
            rendererSettings: {
              viewBoxSize: '100 100 245 245',
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          width={100}
          height={100}
        />
        {renderCardButton()}
      </>
    );
  };

  const handleRenderWhenSending = () => {
    if (sending) {
      return (
        <Grid container direction="column" alignItems="center">
          <CircularProgress className={classes.spinner} />
        </Grid>
      );
    }

    return handleWhenNotSending();
  };

  function renderTeleconsultationTime() {
    if (isEmergency) {
      return (
        <span>
          {t('Horário')}: {t('Imediato')}
        </span>
      );
    }

    return (
      <div>
        <span>{t('Horário')}: </span>
        <span>{formattedDate(scheduledDatetime)}</span>
        <span>
          {moment
            .utc(scheduledDatetime)
            .local()
            .format(FORMAT['hour-and-minute'])}
        </span>
      </div>
    );
  }

  function renderTeleconsultationWith() {
    if (isEmergency) {
      return <p>{t('Pronto atendimento')}</p>;
    }

    return (
      <p>
        {t('Teleconsulta')}: {getDoctorTitle(doctor)} {doctor.name}
      </p>
    );
  }

  function renderTeleconsultationValue() {
    if (isEmergency) {
      return (
        <p>
          <b>{t('Valor')}</b>: {t('Coberto pelo plano')}
        </p>
      );
    }

    return (
      <p>
        <b>{t('Valor')}</b>: {t('Pagamento por voucher')}
      </p>
    );
  }

  function renderTeleconsultationInfo() {
    return (
      <>
        {renderTeleconsultationWith()}
        {renderTeleconsultationTime()}
        {renderTeleconsultationValue()}
      </>
    );
  }

  function handleLinkTo() {
    if (isEmergency) {
      return '/medicos';
    }

    return `/medicos/${doctor_id}`;
  }

  function renderDoctorImage() {
    if (isEmergency) {
      return <NurseLogo />;
    }

    return (
      <UserProcessImg
        opacity={1}
        imgUrl={doctor?.avatar_url}
        title={doctor?.name}
        titleSize={50}
        backgroundStyle={{
          width: 160,
          height: 160,
          borderRadius: 140,
          backgroundColor: 'var(--caren-image-fallback)',
          marginRight: 5,
        }}
      />
    );
  }

  function renderCardButton() {
    if (isEmergency) {
      return (
        <div>
          <Link
            to={`/emergencia/${emergencyKey}/plano/${insurancePartner.code}/anamnese/${appointmentKey}/chat`}
          >
            <VoucherCardButton style={{ width: '100%' }}>
              {t('Pré-atendimento')}
            </VoucherCardButton>
          </Link>
        </div>
      );
    }

    return (
      <div>
        <Link to={`/consultas/${consultationKey}/sala-de-espera`}>
          <VoucherCardButton style={{ width: '100%' }}>
            {t('Entrar na sala de espera')}
          </VoucherCardButton>
        </Link>
      </div>
    );
  }

  return (
    <>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <FindADoctorContainer>
        <Link to={handleLinkTo()}>
          <BackButtonContainer>
            <IoIosArrowRoundBack size={25} color="var(--caren-darkred)" />
          </BackButtonContainer>
        </Link>
        <FindADoctor>{renderTeleconsultationInfo()}</FindADoctor>
      </FindADoctorContainer>
      <Container>
        <MainText>{t('Informe seu Voucher')}</MainText>
        <ProcessContainer>
          <UserProcessContainer>
            <UserProcessBackground>
              <UserProcessBackground>
                <UserProcessImg
                  opacity={1}
                  imgUrl={credentials?.session?.avatar_url}
                  title={credentials?.session?.name}
                  titleSize={50}
                  backgroundStyle={{
                    width: 160,
                    height: 160,
                    borderRadius: 140,
                    backgroundColor: 'var(--caren-image-fallback)',
                    marginRight: 5,
                  }}
                />
              </UserProcessBackground>
            </UserProcessBackground>
          </UserProcessContainer>
          {dots.map((_, index) => (
            <ProcessLine
              color="var(--caren-lightgrey)"
              key={index.toString()}
            />
          ))}
          <MainIconContainer>
            <MainIcon success={success} />
          </MainIconContainer>
          {dots.map((_, index) => {
            const color = success ? 'var(--caren-lightgrey)' : '#e8e8e8';
            return <ProcessLine color={color} key={index.toString()} />;
          })}
          <UserProcessContainer>
            <UserProcessBackground>{renderDoctorImage()}</UserProcessBackground>
          </UserProcessContainer>
        </ProcessContainer>
        <Border />
        <VoucherCardContainer>
          <div>
            <p>{t('Voucher')}</p>
            <VoucherCard>
              <VoucherCardContent>
                <VoucherCardFront onSubmit={handleSendVoucher}>
                  {handleRenderWhenSending()}
                </VoucherCardFront>
              </VoucherCardContent>
            </VoucherCard>
          </div>
        </VoucherCardContainer>
        <Border />
        <ProcessFooter>
          <FooterText>
            {t(
              'Digite o código de 10 caracteres do seu voucher acima para confirmar a sua consulta.'
            )}{' '}
            <FaQuestionCircle />
          </FooterText>
        </ProcessFooter>
      </Container>
    </>
  );
}
