import React from 'react';
import styled from 'styled-components/macro';
import { BsExclamationCircle } from 'react-icons/bs';

export const NoScheduleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 285px;
  margin-top: 15px;
  border-radius: 10px;
  padding: 40px 0 30px;

  p {
    color: var(--caren-clocklightgrey);
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.6;
    text-align: center;
  }
`;

export const NoScheduleIcon = styled(BsExclamationCircle).attrs({
  size: 45,
  color: 'var(--caren-clocklightgrey)',
})`
  margin: 0 0 35px;
`;

function NoConsults({ title }) {
  return (
    <NoScheduleContainer>
      <NoScheduleIcon />
      <p>{title}</p>
    </NoScheduleContainer>
  );
}

export default NoConsults;
