import { createContext, useState } from 'react';

type ConsultDialogContextValues = {
  actualStep: number;
  maxSteps: number;
  isManager: boolean;
  stepsHeader: Record<number, string>;
  steps: Map<number, { header: null | string; value: unknown }>;
  setStepData: (
    step: number,
    data: { header: null | string; value: unknown }
  ) => void;
  removeStepData: (step: number) => void;
  forwardStep: () => void;
  backwardStep: () => void;
  send: () => Promise<void>;
};

export type ConsultDialogControl = {
  forwardStep: () => void;
  backwardStep: () => void;
};

const ConsultDialogContext = createContext<ConsultDialogContextValues | null>(
  null
);

type ConsultDialogContextProviderProps = {
  children: React.ReactNode;
  maxSteps: number;
  stepsHeader: Record<number, string>;
  isManager?: boolean;
  onFinished: (
    data: unknown[],
    dialogControl: ConsultDialogControl
  ) => Promise<void>;
  initialStep?: number;
  initialData?: Map<number, { header: null | string; value: unknown }>;
};

const ConsultDialogContextProvider = ({
  children,
  maxSteps,
  stepsHeader,
  isManager = false,
  onFinished,
  initialData,
  initialStep
}: ConsultDialogContextProviderProps) => {
  const [step, setStep] = useState(initialStep ?? 1);
  const [stepsData, setStepsData] = useState<
    Map<number, { header: null | string; value: unknown }>
  >(initialData ?? new Map());

  const forwardStep = () => {
    setStep(old => (old >= maxSteps ? maxSteps : old + 1));
  };

  const backwardStep = () => {
    setStep(old => (old <= 1 ? 1 : old + -1));
  };

  const setStepData = (
    stepNumber: number,
    data: { header: null | string; value: unknown }
  ) => {
    setStepsData(old => {
      old.set(stepNumber, data);
      return new Map(old);
    });
  };

  const removeStepData = (stepNumber: number) => {
    setStepsData(old => {
      old.delete(stepNumber);
      return new Map(old);
    });
  };

  const dialogControl = {
    forwardStep,
    backwardStep
  };

  const send = async () => {
    const sendData = Array.from(stepsData).map(item => item[1].value);
    await onFinished(sendData, dialogControl);
  };

  return (
    <ConsultDialogContext.Provider
      value={{
        isManager,
        maxSteps,
        stepsHeader,
        actualStep: step,
        steps: stepsData,
        forwardStep,
        backwardStep,
        setStepData,
        removeStepData,
        send
      }}
    >
      {children}
    </ConsultDialogContext.Provider>
  );
};

export { ConsultDialogContext };
export default ConsultDialogContextProvider;
