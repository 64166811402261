import useSWR from 'swr';
import { getProfileCompleteness } from '../../services/user';
import type { ProfileCompleteness } from '../../entities/user';

export function useProfileCompleteness(isManager?: boolean) {
  const { data, error, isLoading, mutate } = useSWR<
    undefined | ProfileCompleteness
  >(
    isManager ? null : '/employees/profile-completeness',
    async () => await getProfileCompleteness(),
    { refreshInterval: 60000 }
  );

  return {
    data,
    isComplete: data?.profile_completeness === 100,
    isLoading,
    error,
    mutate
  };
}
