import { useContext } from 'react';

import { Container } from './container.styles';
import { Store } from '../../store';
import { sidebarClose } from '../../store/modules/Sidebar/actions';

export default function ContainerOpacity() {
  const [state, dispatch] = useContext(Store);

  function handleCloseSidebar() {
    dispatch(sidebarClose());
  }

  return (
    <Container
      onClick={handleCloseSidebar}
      sidebarIsOpen={state.sidebarStatus}
    />
  );
}
