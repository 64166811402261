import React from 'react';
import { FaGlobeAmericas } from 'react-icons/fa';
import styled from 'styled-components/macro';
import useCurrentHour from '../../hooks/currentHour.hook';
import getUserCurrentTimezone from '../../utils/getUserCurrentTimezone';

const TimeZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 100px;
  margin-left: 40px;
  color: #47525e;
  div {
    svg {
      justify-content: center;
      align-items: center;
    }
  }
`;

function TimezoneAndHour() {
  const currentHourState = useCurrentHour();

  return (
    <TimeZone>
      <FaGlobeAmericas />
      {getUserCurrentTimezone().split('_').join(' ')} ({currentHourState})
    </TimeZone>
  );
}

export default TimezoneAndHour;
