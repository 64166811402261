import React from 'react';

import { LoadingIcon } from './styles';

export default function LoadingComponent({
  size = 30,
  color = 'var(--caren-white)',
}) {
  return <LoadingIcon size={size} color={color} />;
}
