import React, { useEffect, useState } from 'react';
import { GoClock } from 'react-icons/go';
import { Helmet } from 'react-helmet-async';
import { MdPhone } from 'react-icons/md';
import './image-styles.css';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../contexts/ToastContext';
import { removeReceiptService } from '../../../services/schedule-process.service';
import {
  MainText,
  ProcessContainer,
  ProcessFooter,
  UserProcessContainer,
  UserProcessBackground,
  UserProcessImg,
  ProcessLine,
  UploadIcon,
  Border,
  ProcessTime,
  Text,
  BorderBox,
  FooterText,
  UploadContainer,
  CloseButton,
} from '../consult.styles';
import ConfirmedSchedule from '../ConsultConfirmed';
import {
  ProcessFooterCenter,
  RemoveReceipt,
} from './consult-verificarion.styles';
import Modal from '../../../components/Modal';
import history from '../../../services/history';
import { scheduleDetailsService } from '../../../services/patient-service';

export default function CurrentConsult({
  scheduleDetails,
  doctorBankDetails,
  setVerification,
  consultId,
}) {
  const { t } = useTranslation(['consult_verification']);
  const dots = new Array(15).fill(true);

  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const TITLE = 'Sala de espera - Caren';

  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  useEffect(() => {
    if (!confirmed) {
      const interval = setInterval(async () => {
        const { data } = await scheduleDetailsService(consultId);

        if (data.confirmed) {
          setConfirmed(true);
        }
      }, 10000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [consultId, confirmed]);

  function handleDeleteReceipt() {
    setOpenModal(!openModal);
  }

  async function handleRemoveReceipt(receipt) {
    await removeReceiptService(consultId, receipt.key);
    setOpenModal(!openModal);
    history.push(`/consultas/${consultId}`);
    setVerification(false);
    toast(t('Comprovante removido com sucesso.'), {
      variant: 'success',
    });
  }

  function RemoveOrNotReceiptButtons() {
    return (
      <RemoveReceipt onClick={handleRemoveReceipt}>
        {t('EXCLUIR')}
      </RemoveReceipt>
    );
  }

  return confirmed ? (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ConfirmedSchedule
        consultId={consultId}
        setVerification={setVerification}
        scheduleDetails={scheduleDetails}
        doctorBankDetails={doctorBankDetails}
      />
    </>
  ) : (
    <>
      <Modal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        modalTitle={t('Deseja excluir o comprovante enviado?')}
        modalWidth="60%"
      >
        <RemoveOrNotReceiptButtons />
      </Modal>
      <MainText>
        {t('Aguarde a verificação do comprovante pelo médico')}
      </MainText>
      <ProcessContainer>
        <UserProcessContainer>
          <UserProcessBackground>
            <UserProcessImg
              opacity={1}
              imgUrl={scheduleDetails?.patient?.avatar_url}
              backgroundStyle={{ width: 160 }}
            />
          </UserProcessBackground>
        </UserProcessContainer>

        {dots.map((_, index) => (
          <ProcessLine color="var(--caren-lightgrey)" key={index.toString()} />
        ))}
        <UploadContainer>
          <UploadIcon color="var(--caren-green)" />
          <CloseButton onClick={handleDeleteReceipt}>&times;</CloseButton>
        </UploadContainer>
        {dots.map((_, index) => (
          <ProcessLine color="#e8e8e8" key={index.toString()} />
        ))}
        <UserProcessContainer>
          <UserProcessBackground>
            <UserProcessImg
              opacity={0.5}
              imgUrl={scheduleDetails?.doctor?.avatar_url}
              backgroundStyle={{ width: 160 }}
            />
          </UserProcessBackground>
        </UserProcessContainer>
      </ProcessContainer>
      <Border />
      <ProcessFooterCenter>
        <ProcessTime>
          <Text>{t('Tempo para confirmação')}</Text>
          <BorderBox>
            <div className="time-title">
              <p>
                {t('Verificando Comprovante')} <GoClock />
              </p>
            </div>
            <div className="time">
              {scheduleDetails?.confirmation_deadline && (
                <Countdown
                  date={moment
                    .utc(scheduleDetails?.confirmation_deadline)
                    .local()
                    .valueOf()}
                  renderer={({ formatted: { hours, minutes, seconds } }) => (
                    <p>
                      {hours}:{minutes}:{seconds}
                    </p>
                  )}
                />
              )}
            </div>
          </BorderBox>
        </ProcessTime>
      </ProcessFooterCenter>
      <Border />
      <ProcessFooter>
        <FooterText>
          {t(
            'Nosso sistema exige uma verificação manual por parte do seu médico. Dúvidas? Faça contato.'
          )}{' '}
          <MdPhone size={18} />
        </FooterText>
      </ProcessFooter>
    </>
  );
}
