import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import styled, { keyframes } from 'styled-components/macro';

const rotate = keyframes`
  from {transform: rotate(0deg)};
  to {transform: rotate(360deg)};
`;

export const LoadingIcon = styled(AiOutlineLoading3Quarters).attrs(
  ({ size, color }) => ({
    size,
    color
  })
)`
  animation: ${rotate} 2s linear infinite;
`;
