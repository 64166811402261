import React, { useEffect } from 'react';

import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  CloseButton,
  ModalInfoContainer,
  ModalInfo,
  ModalInfoTitle,
  CarenModalContent,
  Image,
} from './styles';

export default function PendingQuestionnaireModal({
  openModal,
  handleOpenModal,
  modalTitle,
  children,
  modalWidth,
  modalTitleColor,
  containerStyles = {},
  noClosing = false,
}) {
  useEffect(() => {
    function handleKeyPress(event) {
      if (!noClosing && (event.key === 'Escape' || event.key === 'Esc')) {
        handleOpenModal();
      }
    }

    if (openModal) {
      document.onkeydown = (e) => handleKeyPress(e);
    }
  }, [handleOpenModal, openModal]);

  const stopPropagation = (e) => e.stopPropagation();

  const closeModal = () => {
    if (!openModal) return;
    handleOpenModal();
  };

  return (
    <>
      <ModalContainer openModal={openModal} onClick={!noClosing && closeModal}>
        <ModalContent modalWidth={modalWidth} onClick={stopPropagation}>
          <ModalHeader>
            {noClosing ? (
              ''
            ) : (
              <CloseButton onClick={closeModal}>&times;</CloseButton>
            )}
          </ModalHeader>
          <ModalInfoContainer>
            <ModalInfo style={{ ...containerStyles }}>
              <ModalInfoTitle modalTitleColor={modalTitleColor}>
                {modalTitle}
                <p>Questionário pendente</p>
              </ModalInfoTitle>
              {children}
            </ModalInfo>
          </ModalInfoContainer>
        </ModalContent>
        <CarenModalContent>
          <Image />
        </CarenModalContent>
      </ModalContainer>
    </>
  );
}
