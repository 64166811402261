import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Check, Create, Done, Sync, Warning } from '@mui/icons-material';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Header from '../../components/Header';
import {
  ActionsContainer,
  ConsultationContainer,
  Container,
  MainContent,
  PatientInfoContainer,
  SubHeaderContainer,
  TitlePage,
  BackButtonContainer,
  BackButtonText
} from './styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import { scheduleDetailsService } from '../../services/patient-service';
import { useToast } from '../../contexts/ToastContext';
import { CLINICAL_EVALUATION_FIELDS } from '../../constants/common';
import ClinicalEvaluationInfo from '../../components/Consultation/ClinicalEvaluationInfo';
import PatientInfo from '../../components/Consultation/PatientInfo';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
import DigitalSignatureDialog from '../../components/DigitalSignatureDialog';
import { concludeConsultationService } from '../../services/conclude-consultation.service';
import { getReportStatus } from '../../services/teleconsultation-report-status.service';
import Chatwoot from '../../components/Chatwoot';

export default function PostAppointment({
  computedMatch: {
    params: { consultation_id }
  }
}) {
  const TITLE = 'Pós Atendimento - Caren';

  const toast = useToast();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation('post_appointment');
  const [consultation, setConsultation] = useState(null);
  const [anamnesisDetail, setAnamnesisDetail] = useState(null);
  const [reportStatus, setReportStatus] = useState(null);
  const [hasClinicalEvaluation, setHasClinicalEvaluation] = useState(false);
  const [backConfirmationDialogOpen, setBackConfirmationDialogOpen] =
    useState(false);
  const [submitConfirmationDialogOpen, setSubmitConfirmationDialogOpen] =
    useState(false);
  const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);

  const isEditable = consultation?.evaluation_concluded_at == null;
  const [hideActions, setHideActions] = useState(false);
  const [editingField, setEditingField] = useState(null);

  const refreshReportStatus = async () => {
    try {
      const {
        data: { status }
      } = await getReportStatus(consultation_id);

      setReportStatus(status);
    } catch (error) {
      toast(
        t('Não foi possível verificar o status da conclusão da consulta.'),
        {
          variant: 'error'
        }
      );
    }
  };

  useEffect(() => {
    async function retrieveTeleconsultation() {
      try {
        const { data } = await scheduleDetailsService(consultation_id);

        if (data.cancelled_at !== null || data.cancellation_reason !== null) {
          toast(
            t('Esta consulta foi cancelada, a avaliação está indisponível.'),
            {
              variant: 'error'
            }
          );
          history.replace('/');
        }

        setConsultation(data);
      } catch (error) {
        toast(t('Não foi possível carregar a consulta no momento.'), {
          variant: 'error'
        });
      }
    }

    retrieveTeleconsultation();
    refreshReportStatus();
  }, [consultation_id]);

  const isFieldEmpty = field => {
    const isEmptyFunc = field?.meta?.isEmpty;

    const value = anamnesisDetail?.clinical_evaluation?.[field.key];
    if (isEmptyFunc) {
      return isEmptyFunc(value);
    }

    return !value;
  };

  const isFieldRequired = field =>
    field.required(anamnesisDetail?.clinical_evaluation?.[field.key]);

  useEffect(() => {
    if (anamnesisDetail) {
      const requiredFields = CLINICAL_EVALUATION_FIELDS.filter(isFieldRequired);

      const result = requiredFields.every(field => !isFieldEmpty(field));

      setHasClinicalEvaluation(result);
    }
  }, [anamnesisDetail]);

  const onClickBack = () => {
    if (isEditable) {
      setBackConfirmationDialogOpen(true);
      return;
    }

    history.push(location?.state?.referrer || '/consultorio', {
      tab: location?.state?.tab || 'scheduled'
    });
  };

  const onClickSubmit = () => {
    setSubmitConfirmationDialogOpen(true);
  };

  const goToClinicalEvaluationFields = () => {
    const firstField = CLINICAL_EVALUATION_FIELDS.find(
      field => isFieldRequired(field) && isFieldEmpty(field)
    );

    setEditingField(firstField);
  };

  const onConfirmBack = confirmation => {
    if (!confirmation) {
      setBackConfirmationDialogOpen(false);
      return;
    }

    history.push(location?.state?.referrer || '/consultorio', {
      tab: location?.state?.tab || 'scheduled'
    });
  };

  const onConfirmSubmit = async confirmation => {
    if (!confirmation) {
      setSubmitConfirmationDialogOpen(false);
      return;
    }

    try {
      await concludeConsultationService(consultation?.key);

      setConsultation(current => ({
        ...current,
        evaluation_concluded_at: 'now' // TODO: Improve this
      }));

      toast(
        t(
          'Sua avaliação foi registrada! A consulta foi concluída com sucesso.'
        ),
        {
          variant: 'success'
        }
      );

      setSubmitConfirmationDialogOpen(false);
      setSignatureDialogOpen(true);
    } catch (error) {
      toast(
        t(
          'Não foi possível encerrar a consulta, verifique se os campos estão corretamente preenchidos.'
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  const onReceiveDetail = newDetail => {
    setAnamnesisDetail(newDetail);
  };

  const onFinishEditing = () => {
    setEditingField(null);
  };

  const onToggleSignatureDialog = () => {
    setSignatureDialogOpen(open => !open);
  };

  const renderActions = () => {
    const actions = [
      {
        key: 'back',
        element: (
          <BackButtonContainer
            onClick={() => {
              onClickBack();
            }}
          >
            <IoIosArrowRoundBack size={25} color="var(--caren-icongrey)" />
            <BackButtonText>
              {isEditable ? t('Preencher depois') : t('Voltar')}
            </BackButtonText>
          </BackButtonContainer>
        )
      }
    ];

    if (isEditable) {
      let startIcon = <Warning />;
      let submit = false;
      let buttonText = t('Preencha a avaliação');

      if (hasClinicalEvaluation) {
        startIcon = <Done />;
        submit = true;
        buttonText = t('Concluir');
      }

      actions.push({
        key: 'conclude',
        element: (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={startIcon}
            onClick={submit ? onClickSubmit : goToClinicalEvaluationFields}
            sx={{ marginTop: 4 }}
          >
            {buttonText}
          </Button>
        )
      });
    }

    switch (reportStatus) {
      case null:
      case 'conclusion_pending':
        break;

      case 'signature_pending':
        actions.push({
          key: 'signature_pending',
          element: (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                setSignatureDialogOpen(true);
              }}
              startIcon={<Create />}
              sx={{ marginTop: 4 }}
            >
              {t('Assinatura Digital')}
            </Button>
          )
        });
        break;

      case 'synced':
      case 'event_created':
        actions.push({
          key: 'synced',
          element: (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<Check />}
              disabled
              sx={{ marginTop: 4 }}
            >
              {t('Sincronizado')}
            </Button>
          )
        });
        break;

      default:
        actions.push({
          key: 'syncing',
          element: (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<Sync />}
              disabled
              sx={{ marginTop: 4 }}
            >
              {t('Sincronizando...')}
            </Button>
          )
        });
        break;
    }

    return actions.map(({ key, element }) => (
      <Grid item xs key={key}>
        {element}
      </Grid>
    ));
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header rightAction={null} />
      <Container>
        <SubHeaderContainer>
          <TitlePage>{t('Resumo da teleconsulta')}</TitlePage>
        </SubHeaderContainer>
        {consultation && (
          <MainContent>
            <PatientInfoContainer>
              <PatientInfo
                patient={consultation.patient}
                teleconsultationKey={consultation.key}
                scheduledDatetime={consultation.scheduled_datetime}
              />
            </PatientInfoContainer>
            <ConsultationContainer>
              <ClinicalEvaluationInfo
                consultation={consultation}
                onDetail={onReceiveDetail}
                editingField={editingField}
                onClickBack={onFinishEditing}
                setHideActions={setHideActions}
              />
            </ConsultationContainer>

            {!hideActions && (
              <ActionsContainer>{renderActions()}</ActionsContainer>
            )}
          </MainContent>
        )}
        <UnreversibleConfirmationModal
          title={t(
            'Tem certeza de que deseja sair?\nVocê ainda não concluiu a avaliação.'
          )}
          openModal={backConfirmationDialogOpen}
          setOpenModal={setBackConfirmationDialogOpen}
          handleConfirmation={onConfirmBack}
          cancelButtonText={t('Cancelar')}
        />
        <UnreversibleConfirmationModal
          title={t(
            'Encerrar a consulta?\nA avaliação médica não poderá ser atualizada depois.'
          )}
          openModal={submitConfirmationDialogOpen}
          setOpenModal={setSubmitConfirmationDialogOpen}
          handleConfirmation={onConfirmSubmit}
          cancelButtonText={t('Cancelar')}
        />
        <DigitalSignatureDialog
          open={signatureDialogOpen}
          toggleDialog={onToggleSignatureDialog}
          reportStatus={reportStatus}
          refreshReportStatus={refreshReportStatus}
          teleconsultationKey={consultation_id}
        />
      </Container>
      <Chatwoot />
    </>
  );
}
