import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RiMoneyDollarCircleLine, RiCoupon3Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import Tooltip from '@mui/material/Tooltip';
import { useToast } from '../../contexts/ToastContext';
import Modal from '../Modal';
import {
  Container,
  Content,
  Summary,
  ActionButtons,
  // CreditButton,
  ActionButton,
  TransferIcon,
  // CardIcon,
  ShieldIcon,
  ConfirmButton,
  SummaryHeader,
  SummaryHighlight,
  SummaryBilling,
  // SummaryBillingPromo,
  UserPadlockIcon,
  PhoneInfo,
  PhoneInfoPaybar,
} from './paybar.styles';

import { useAuth } from '../../hooks/AuthContext';
import { createConsultationService } from '../../services/create-consultation.service';
import history from '../../services/history';
import { Store } from '../../store';
import { sidebarOpen } from '../../store/modules/Sidebar/actions';
import getDoctorTitle from '../../utils/getDoctorTitle';
import { FORMAT } from '../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';

const useStyles = makeStyles({
  buttonRoot: {
    display: 'block',
    margin: '10px auto',
  },
  buttonText: {
    color: 'var(--caren-white) !important',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const MODAL_BANK_TRANSFER = 'bank-transfer';
const MODAL_VOUCHER = 'voucher';

export default function PayBar({
  open,
  payBarState,
  doctor,
  choosedHour,
  type,
  onHealthInsurance,
  onVoucher,
  healthInsuranceDisabled,
  voucherDisabled,
}) {
  const toast = useToast();
  const [, dispatch] = useContext(Store);
  const {
    credentials,
  } = useAuth();

  const { i18n, t } = useTranslation(['doctor_details']);

  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(MODAL_BANK_TRANSFER);
  const [openAuthWall, setOpenAuthWall] = useState(false);

  useEffect(() => {
    if (credentials && credentials.session.key) {
      setOpenAuthWall(false);
    }
  }, [credentials]);

  function handleOpenSidebar() {
    handleOpenAuthWall();
    dispatch(sidebarOpen());
  }

  function handleOpenModal(_e, openModalType = MODAL_BANK_TRANSFER) {
    setOpenModal(!openModal);
    setModalType(openModalType);
  }

  function handleOpenAuthWall() {
    setOpenAuthWall(!openAuthWall);
  }

  // function handleBankTransfer(e) {
  //   if (session?.key) {
  //     return handleOpenModal(e, MODAL_BANK_TRANSFER);
  //   }

  //   return handleOpenAuthWall(e);
  // }

  function handleHealthInsurance(e) {
    if (credentials && credentials.session.key) {
      return onHealthInsurance(e);
    }

    return handleOpenAuthWall(e);
  }

  function handleVoucher(e) {
    if (credentials && credentials.session.key) {
      return handleOpenModal(e, MODAL_VOUCHER);
    }

    return handleOpenAuthWall(e);
  }

  async function handleCreateConsultation() {
    setOpenModal(!openModal);

    try {
      const { data } = await createConsultationService({
        doctor_key: doctor?.key,
        insurance_token: '',
        scheduled_datetime: choosedHour,
        type,
      });
      toast(t('Consulta criada com sucesso'), {
        variant: 'success',
      });
      history.push(`/consultas/${data.key}`);
    } catch (error) {
      toast(t('Ops, algo não está correto'), {
        variant: 'error',
      });
    }
    payBarState(false);
  }

  const renderConfirmationModal = () => {
    switch (modalType) {
      case MODAL_BANK_TRANSFER:
        return (
          <>
            <RiMoneyDollarCircleLine
              size={80}
              style={{ margin: '15px auto', display: 'block' }}
            />
            <p style={{ textAlign: 'center' }}>
              {t('Tem certeza que deseja confirmar a consulta no valor de')}{' '}
              <b>
                {new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: doctor?.billing_currency || 'BRL',
                }).format(
                  doctor?.teleconsultation_settings?.default?.billing_amount
                )}
              </b>
              ?
            </p>
            <div>
              <ConfirmButton onClick={handleCreateConsultation}>
                {t('CONFIRMAR')}
              </ConfirmButton>
            </div>
          </>
        );

      case MODAL_VOUCHER:
        return (
          <>
            <RiCoupon3Line
              size={80}
              style={{ margin: '15px auto', display: 'block' }}
            />
            <p style={{ textAlign: 'center' }}>
              {t('Para este agendamento, certifique-se de ter em mãos o')}{' '}
              <strong>{t('código do voucher')}</strong>{' '}
              {t('enviado pelo seu médico.')}
            </p>
            <p style={{ textAlign: 'center' }}>
              <strong>{t('Atenção')}:</strong>{' '}
              {t('Cada código pode ser utilizado apenas uma vez.')}
            </p>
            <p style={{ textAlign: 'center', marginTop: '30px' }}>
              {t(
                'Caso não tenha, entre em contato com o médico pelo número abaixo antes de prosseguir:'
              )}
            </p>
            <PhoneInfo>
              <div>
                <FaPhoneAlt /> <span>{doctor.phone}</span>
              </div>
              {doctor.whatsapp && (
                <div>
                  <IoLogoWhatsapp />
                  <span>{doctor.cellphone}</span>
                </div>
              )}
            </PhoneInfo>
            <div>
              <ConfirmButton onClick={onVoucher}>CONFIRMAR</ConfirmButton>
            </div>
          </>
        );

      default:
        return modalType;
    }
  };

  return (
    <Container hide={!open}>
      <Content open={open}>
        <Summary>
          <SummaryHeader>
            {t('Teleconsulta com')}{' '}
            <SummaryHighlight>
              {getDoctorTitle(doctor)} {doctor?.name}
            </SummaryHighlight>{' '}
            {t('em')}{' '}
            <SummaryHighlight>
              {momentUtcLocal(choosedHour).format(FORMAT['pt-datetime'])}{' '}
            </SummaryHighlight>
          </SummaryHeader>
          <SummaryBilling>
            {new Intl.NumberFormat(i18n.language, {
              style: 'currency',
              currency: doctor?.billing_currency || 'BRL',
            }).format(
              doctor?.teleconsultation_settings?.default?.billing_amount
            )}{' '}
            {/* <SummaryBillingPromo>
              <s>
                Presencial:{' '}
                {doctor.teleconsultation_settings?.base?.billing_amount}
              </s>
            </SummaryBillingPromo> */}
          </SummaryBilling>
          <PhoneInfoPaybar show={doctor?.phone}>
            <div>
              <span>
                <FaPhoneAlt />
              </span>{' '}
              <span>{doctor?.phone}</span>
            </div>
            {doctor?.whatsapp && (
              <div>
                <span>
                  <IoLogoWhatsapp />
                </span>
                <span>{doctor?.cellphone}</span>
              </div>
            )}
          </PhoneInfoPaybar>
        </Summary>
        <ActionButtons>
          <Tooltip
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  background: '#9cece3',
                  position: 'relative',
                  right: '225px',
                  top: '15px',
                },
              },
            }}
            title={
              <span style={{ color: '#333', fontSize: '12px' }}>
                {' '}
                {t(
                  'Para finalizar esse agendamento você precisará de um código (voucher). Caso não tenha, entre em contato com o médico'
                )}{' '}
              </span>
            }
          >
            <ActionButton
              disabled={voucherDisabled}
              onClick={voucherDisabled ? null : handleVoucher}
            >
              <TransferIcon />
              <p>{t('Voucher')}</p>
            </ActionButton>
          </Tooltip>

          <ActionButton
            onClick={healthInsuranceDisabled ? null : handleHealthInsurance}
            disabled={healthInsuranceDisabled}
          >
            <ShieldIcon />
            <p>{t('Plano de Saúde')}</p>
          </ActionButton>
        </ActionButtons>
      </Content>
      <Modal
        handleOpenModal={handleOpenAuthWall}
        openModal={openAuthWall}
        modalTitle={t('Estamos quase lá!')}
        modalWidth="40%"
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
      >
        <UserPadlockIcon />
        <p>{t('Para agendar uma teleconsulta, é necessário estar logado.')}</p>
        <p>
          {t(
            'Entre com seu login e senha ou cadastre-se gratuitamente pelo botão abaixo!'
          )}
        </p>
        <Button
          classes={{ root: classes.buttonRoot, label: classes.buttonText }}
          color="primary"
          variant="contained"
          onClick={handleOpenSidebar}
          fullWidth
        >
          {t('Entrar')}
        </Button>
      </Modal>
      <Modal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        modalTitle={t('Confirmar consulta')}
        modalWidth="40%"
      >
        {renderConfirmationModal()}
      </Modal>
    </Container>
  );
}
