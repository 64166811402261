import {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect
} from 'react';
// @ts-ignore
import combineURLs from 'axios/lib/helpers/combineURLs';
import { Dialog } from '@headlessui/react';

type LiveviewDialogProps = {
  path: string;
  title?: string;
  disableBackdropClick?: boolean;
};

type LiveviewDialogHandles = {
  open: () => void;
};

const LiveviewDialog = forwardRef<LiveviewDialogHandles, LiveviewDialogProps>(
  (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const iframeElement = useRef(null);

    const IFRAME_SRC = combineURLs(
      import.meta.env.VITE_LIVEVIEW_PATH,
      props.path
    );
    const IFRAME_ORIGIN = new URL(import.meta.env.VITE_LIVEVIEW_PATH).origin;

    const onDialogClose = () => {
      if (!props.disableBackdropClick) setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
      }
    }));

    useEffect(() => {
      const handleMessage = async (event: MessageEvent) => {
        if (event.origin !== IFRAME_ORIGIN) return;

        const { data: message } = event;

        switch (message.type) {
          case 'CLOSE_MODAL':
            setIsOpen(false);
            break;

          default:
            break;
        }
      };

      window.addEventListener('message', handleMessage);
    });

    return (
      <Dialog className="relative z-50" open={isOpen} onClose={onDialogClose}>
        <div className="fixed inset-0 bg-gray-450/50" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <iframe
            ref={iframeElement}
            src={IFRAME_SRC}
            title={props.title ?? 'Liveview'}
            className="h-full w-full border-none"
            allowTransparency
          />
        </div>
      </Dialog>
    );
  }
);

LiveviewDialog.displayName = 'LiveviewDialog';
export type { LiveviewDialogHandles };
export default LiveviewDialog;
