import { useState } from 'react';
import { momentUtcLocal } from '../../../../../utils/moment/momentHelpers';
import { ReactComponent as PlanLogo } from '../../../../../assets/images/plan.svg';
import { ReactComponent as PlanProLogo } from '../../../../../assets/images/plan_pro.svg';
import Modal from '../../../../Modal';
import subscriptionIsPro from '../../../../../utils/subscriptionIsPro';
import { cancelSubscription } from '../../../../../services/plan';
import { useToast } from '../../../../../contexts/ToastContext';
import { useTranslation } from 'react-i18next';
import { type Subscription } from '../../../../../v2/entities/subscription';
import { useFreePlan } from '../../../../../v2/hooks/plan/useFreePlan';
import { FaInfoCircle } from 'react-icons/fa';

type PlanMenu = 'plans' | 'details' | 'payments' | 'paymentCard';

type PlanDetailProps = {
  subscription: Subscription | null;
  goToMenu: (menu: PlanMenu) => void;
};

const STATUS_TEXT: Record<string, { text: string; color: string }> = {
  paid: {
    text: 'Ativo',
    color: 'text-success'
  },
  pending: {
    text: 'Processando...',
    color: 'text-warning'
  },
  scheduled: {
    text: 'Pagamento agendado',
    color: 'text-warning'
  },
  failed: {
    text: 'Erro no pagamento',
    color: 'text-error'
  },
  canceled: {
    text: 'Fatura cancelada',
    color: 'text-error'
  }
};

export default function PlanDetail({
  subscription,
  goToMenu
}: PlanDetailProps) {
  const { t } = useTranslation(['doctor_profile']);

  const toast = useToast();

  const { data: freePlanData } = useFreePlan();

  const [showMoreButtons, setShowMoreButtons] = useState(false);
  const [selectedButton, setSelectedButton] = useState(1);
  const [showModalCancel, setShowModalCancel] = useState(false);

  const hasCanceledInvoice =
    subscription?.current_invoice?.status === 'canceled';

  const hasPendingInvoice = subscription?.current_invoice?.status !== 'paid';

  const cancelPlan = async () => {
    try {
      if (subscription === null) throw Error();

      await cancelSubscription(subscription?.key);

      toast(
        t(
          'Seu pedido de cancelamento foi registrado. Obrigado por ter utilizado a nossa plataforma!'
        ),
        {
          variant: 'success'
        }
      );

      setShowModalCancel(!showModalCancel);
    } catch (error) {
      toast(
        t(
          'Ocorreu um erro na sua requisição. Por favor tente novamente ou envie um e-mail para contato@caren.app.'
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  return (
    <>
      <div className="rounded-t-md border-x border-t border-gray-200 bg-white p-2">
        <div className="mb-5 flex p-2">
          {/* {subscriptionIsPro(subscription) ? (
            <PlanProLogo className="mr-2 h-[80px] w-[80px]" />
          ) : (
            <PlanLogo className="mr-2 h-[80px] w-[80px]" />
          )} */}
          <div className="flex flex-col">
            <h2 className="mb-1 text-xl font-bold text-gray-450">
              {subscription?.plan?.name ?? 'Plano Free'}
              {!subscription?.plan?.name && (
                <FaInfoCircle
                  className="ml-2 inline text-sm text-gray-350"
                  title="O plano Free te dá o direito de realizar 10 consultas de telessaúde gratuitamente. Caso queira continuar utilizando o nosso sistema, escolha um dos nossos planos."
                />
              )}
            </h2>
            <p className="text-sm font-normal">
              {subscription?.plan?.description}
              {!subscription?.plan.description &&
                freePlanData &&
                `${freePlanData.used} / ${freePlanData.total} Teleconsultas grátis realizadas`}
            </p>
          </div>
        </div>
      </div>
      <div className="plan-status border-x border-b border-gray-200 py-2 pl-16 pr-2">
        {subscription?.current_invoice && (
          <p
            className={`font-bold ${
              STATUS_TEXT[subscription.current_invoice.status].color
            }`}
          >
            {STATUS_TEXT[subscription.current_invoice.status].text}
          </p>
        )}
        {subscription?.key && (
          <p className="text-sm font-thin text-gray-350">
            Desde {momentUtcLocal(subscription.start_at).format('LL')}
          </p>
        )}
      </div>
      {subscription !== null && (
        <div className="button-list rounded-b-md border-x border-b border-gray-200 py-5">
          <button
            type="button"
            onClick={() => {
              setSelectedButton(1);
              goToMenu('details');
            }}
            data-active={selectedButton === 1}
            className="text-md w-full py-3 pl-10 text-start text-gray-950 hover:bg-gray-180 data-[active=true]:font-bold"
          >
            Detalhes do plano
          </button>
          {!hasCanceledInvoice && !hasPendingInvoice && (
            <button
              type="button"
              onClick={() => {
                setSelectedButton(2);
                goToMenu('plans');
              }}
              data-active={selectedButton === 2}
              className="text-md w-full py-3 pl-10 text-start text-gray-950 hover:bg-gray-180 data-[active=true]:font-bold"
            >
              Trocar de plano
            </button>
          )}
          <button
            type="button"
            onClick={() => {
              setSelectedButton(3);
              goToMenu('payments');
            }}
            data-active={selectedButton === 3}
            className="text-md w-full py-3 pl-10 text-start text-gray-950 hover:bg-gray-180 data-[active=true]:font-bold"
          >
            Histórico de pagamentos
          </button>
          {!hasCanceledInvoice && (
            <>
              <button
                type="button"
                onClick={() => {
                  setSelectedButton(4);
                  goToMenu('paymentCard');
                }}
                data-active={selectedButton === 4}
                className="text-md w-full py-3 pl-10 text-start text-gray-950 hover:bg-gray-180 data-[active=true]:font-bold"
              >
                Atualizar meio de pagamento
              </button>
              {showMoreButtons ? (
                <button
                  type="button"
                  onClick={() => {
                    setShowModalCancel(true);
                  }}
                  className="text-md w-full py-3 pl-10 text-start text-gray-950 hover:bg-gray-180"
                >
                  Cancelar
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    setShowMoreButtons(true);
                  }}
                  className="text-md w-full py-3 pl-10 text-start text-gray-950 hover:bg-gray-180"
                >
                  Mais
                </button>
              )}
            </>
          )}
        </div>
      )}

      {(subscription?.status === 'not_found' ||
        hasCanceledInvoice ||
        subscription === undefined ||
        subscription === null) && (
        <button
          type="button"
          onClick={() => {
            goToMenu('plans');
          }}
          className="mt-5 w-full rounded-md bg-gray-950 p-2 text-lg font-bold text-white hover:bg-gray-450 active:bg-gray-400"
        >
          Escolha seu plano
        </button>
      )}

      <Modal
        handleOpenModal={() => {
          setShowModalCancel(!showModalCancel);
        }}
        openModal={showModalCancel}
        containerStyles={{ textAlign: 'center' }}
        modalWidth="360px"
        modalTitle="Cancelamento"
        modalTitleColor="text-black"
      >
        <h2 className="text-md mb-5 font-bold">
          Você tem certeza que deseja cancelar?
        </h2>
        <button
          type="button"
          onClick={() => {
            setShowModalCancel(!showModalCancel);
          }}
          className="text-md mb-3 w-full rounded-md bg-yellow-650 p-2 font-bold hover:bg-yellow-750 active:bg-yellow-650"
        >
          Manter Plano
        </button>
        <button
          type="button"
          onClick={async () => {
            await cancelPlan();
          }}
          className="w-full rounded-md p-2 text-sm hover:bg-gray-200 active:bg-gray-250"
        >
          Cancelar Plano
        </button>
      </Modal>
    </>
  );
}
