import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { TextField, MenuItem } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../contexts/ToastContext';
import { useAuth } from '../../../../hooks/AuthContext';
import LoadingComponent from '../../../Loading';
import Modal from '../../../Modal';
import {
  FormContainer,
  UpdateProfileButton,
  ErrorMessageText,
} from './form.styles';
import { availableCountries } from '../../../../utils/countryLocaleUtils';
import { updateProfile } from '../../../../services/secretary';
import { SecretaryProfileSchema } from '../../../../v2/entities/secretary';

function DataForm() {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    credentials,
    refreshSession,
  } = useAuth();

  const { t } = useTranslation(['secretary_profile']);

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  async function handleUpdateProfile(dataParams) {
    setLoading(true);
    try {
      const parsedForm = SecretaryProfileSchema.parse(dataParams);
      const { data: _data } = await updateProfile(parsedForm);
      if(credentials){
        refreshSession(credentials.token);
      }
      toast(t('Perfil atualizado com sucesso!'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Verifique se os campos estão preenchidos corretamente.'), {
        variant: 'error',
      });
    }
    setLoading(false);
  }

  return (
    <>
      <Modal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        modalTitle={t('Alterar senha?')}
        modalWidth="80%"
      />
      <FormContainer onSubmit={handleSubmit(handleUpdateProfile)}>
        <Controller
          name="name"
          control={control}
          defaultValue={credentials?.session.name}
          render={(fields) => (
            <TextField
              label={t('Nome Completo')}
              type="text"
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            />
          )}
        />
        <Controller
          control={control}
          name="fiscal_code"
          defaultValue={credentials?.session.fiscal_code}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <InputMask
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              <TextField
                name={name}
                label={t('CPF')}
                fullWidth
                variant="outlined"
                inputRef={ref}
              />
            </InputMask>
          )}
        />
        <Controller
          control={control}
          name="phone"
          defaultValue={credentials?.session.phone}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <InputMask
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              <TextField
                name={name}
                label={t('Telefone')}
                fullWidth
                variant="outlined"
                inputRef={ref}
              />
            </InputMask>
          )}
        />
        <Controller
          control={control}
          name="cellphone"
          defaultValue={credentials?.session.cellphone}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <InputMask
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              <TextField
                name={name}
                label={t('Celular')}
                fullWidth
                variant="outlined"
                inputRef={ref}
              />
            </InputMask>
          )}
        />
        <Controller
          control={control}
          name="country"
          defaultValue={credentials?.session.country}
          render={(fields) => (
            <TextField
              select
              label={t('País')}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            >
              {availableCountries.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          rules={{
            validate: {
              notEmpty: (value) => {
                const values = [getValues('state'), getValues('city')];

                const emptyAddressInfo = !values.some(Boolean);
                return (
                  emptyAddressInfo ||
                  Boolean(value) ||
                  t('Por favor informe o endereço completo.')
                );
              },
            },
          }}
        />
        <ErrorMessage
          errors={errors}
          name="country"
          as={<ErrorMessageText />}
        />
        <Controller
          control={control}
          name="state"
          defaultValue={credentials?.session.state}
          render={(fields) => (
            <TextField
              type="text"
              label={t('Estado')}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            />
          )}
          rules={{
            validate: {
              notEmpty: (value) => {
                const values = [getValues('country'), getValues('city')];

                const emptyAddressInfo = !values.some(Boolean);
                return (
                  emptyAddressInfo ||
                  Boolean(value) ||
                  t('Por favor informe o endereço completo.')
                );
              },
            },
          }}
        />
        <ErrorMessage errors={errors} name="state" as={<ErrorMessageText />} />
        <Controller
          control={control}
          name="city"
          defaultValue={credentials?.session.city}
          render={(fields) => (
            <TextField
              type="text"
              label={t('Cidade')}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            />
          )}
          rules={{
            validate: {
              notEmpty: (value) => {
                const values = [getValues('country'), getValues('state')];

                const emptyAddressInfo = !values.some(Boolean);
                return (
                  emptyAddressInfo ||
                  Boolean(value) ||
                  t('Por favor informe o endereço completo.')
                );
              },
            },
          }}
        />
        <ErrorMessage errors={errors} name="city" as={<ErrorMessageText />} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <label htmlFor="loading"> </label>
          <UpdateProfileButton bgColor="var(--caren-greenblue)" type="submit">
            {loading ? <LoadingComponent /> : t('Atualizar Perfil')}
          </UpdateProfileButton>
        </div>
      </FormContainer>
    </>
  );
}

export default DataForm;
