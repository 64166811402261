import api from './api';

export const medicalAdviceService = async (query) => {
  const { data } = await api.get('/medical-advice', {
    params: {
      q: query
    }
  });

  return data;
};
