import React, { useEffect, useState } from 'react';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import { BackButton } from '../../components/BackButton';
import {
  Container,
  FirstGrid,
  SecondGrid,
  ResponseContainer,
  SearchIcon,
  SubHeaderContainer,
  SearchButtonContainer,
  SelectBox,
  SelectIcon,
  Select,
} from './styles';

import PatientReceipts from './PatientReceipts';
import Header from '../../components/Header';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import ContainerOpacity from '../../components/ContainerOpacity';

import { doctorReceiptService } from '../../services/doctors.service';

import LoadingComponent from '../../components/Loading';

import DoctorPanel from '../../components/DoctorPanel';

export default function DoctorReceipts() {
  const [doctorReceipts, setDoctorReceipts] = useState([]);

  const toast = useToast();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const TITLE = 'Meus recibos - Caren';

  const { t } = useTranslation(['doctor_receipts']);

  useEffect(() => {
    async function getDoctorReceiptsService() {
      setLoading(true);
      try {
        const { data: receiptData } = await doctorReceiptService(filterQuery);

        setDoctorReceipts([...receiptData]);
      } catch (error) {
        toast(t('Ops, não foi possível carregar os comprovantes...'), {
          variant: 'error',
        });
      }
      setLoading(false);
    }

    getDoctorReceiptsService();
  }, [filterQuery]);

  function handleShowSearchInput() {
    setShowSearchInput(true);
  }

  function handleFilterSelected(e) {
    const { value } = e.target;

    switch (value) {
      case 'all':
        setFilterQuery({});
        break;
      case 'verified':
        setFilterQuery({ pending: false });
        break;
      case 'pending':
        setFilterQuery({ pending: true });
        break;
      default:
        setFilterQuery({});
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar bgColor="var(--caren-greenblue)" />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel inviteFriend />
        </FirstGrid>
        <SecondGrid>
          <SubHeaderContainer>
            <SearchButtonContainer>
              {showSearchInput ? (
                <SelectBox>
                  <SelectIcon />
                  <Select
                    name="status"
                    defaultValue="0"
                    onChange={handleFilterSelected}
                  >
                    <option disabled value="0">
                      {t('Filtrar por Status')}
                    </option>
                    <option value="all" key={0}>
                      {t('Todos')}
                    </option>
                    <option value="verified" key={1}>
                      {t('Verificados')}
                    </option>
                    <option value="pending" key={2}>
                      {t('Pendentes')}
                    </option>
                  </Select>
                </SelectBox>
              ) : (
                <SearchIcon onClick={handleShowSearchInput} />
              )}
            </SearchButtonContainer>
            <BackButton
              path="/consultorio"
              title={t('Comprovantes de Pagamento')}
            />
          </SubHeaderContainer>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <ResponseContainer>
              <LoadingComponent size={80} color="var(--caren-greenblue)" />
            </ResponseContainer>
          ) : doctorReceipts.length ? (
            <PatientReceipts doctorReceipts={doctorReceipts} />
          ) : (
            <ResponseContainer>
              <div>
                <p>{t('Ops! Nenhum comprovante recebido até o momento.')}</p>
                <AiOutlineFolderOpen size={60} color="var(--caren-icongrey)" />
              </div>
            </ResponseContainer>
          )}
        </SecondGrid>
      </Container>
    </>
  );
}
