import React, { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { Helmet } from 'react-helmet-async';
import { FaQuestionCircle } from 'react-icons/fa';
import Countdown from 'react-countdown';
// import ImageUploader from 'react-images-upload';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  ClockContainer,
  ReceiptConfirmationCountdown,
  AwaitingReceipt,
} from '../../components/PatientPanel/ConsultConfirmation/styles';

import { useToast } from '../../contexts/ToastContext';
import formattedDate from '../../utils/formattedDate';
import VerificationStep from './ConsultVerification';
import Header from '../../components/Header';
import {
  Container,
  FindADoctor,
  MainText,
  ProcessContainer,
  ProcessFooter,
  UserProcessContainer,
  UserProcessBackground,
  UserProcessImg,
  ProcessLine,
  UploadIcon,
  Border,
  ProcessTime,
  DepositData,
  Text,
  BankDetails,
  BankLogo,
  BankInfo,
  SendReceipt,
  ReceiptLogo,
  FooterText,
  ButtonUpload,
} from './consult.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import { scheduleDetailsService } from '../../services/patient-service';
import Modal from '../../components/Modal';
import {
  bankTransferReceipt,
  bankTransferUpload,
} from '../../services/schedule-process.service';
import api from '../../services/api';
import LoadingComponent from '../../components/Loading';
import getDoctorTitle from '../../utils/getDoctorTitle';
import { FORMAT } from '../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';

const useStyles = makeStyles({
  dropzoneGrid: {
    justifyContent: 'center',
  },
});

export default function CurrentConsult({
  computedMatch: {
    params: { consult_id },
  },
}) {
  const { t } = useTranslation(['current_consult']);
  const toast = useToast();
  const classes = useStyles();
  const dots = new Array(15).fill(true);
  const [scheduleDetails, setScheduleDetails] = useState({});
  const [doctorBankDetails, setDoctorBankDetails] = useState({});
  const [preAuthUpload, setPreAuthUpload] = useState('');
  const [image, setImage] = useState({});
  const [verification, setVerification] = useState(false);
  const onDrop = (img) => {
    const newImage = img[0];
    setImage(newImage);
  };
  const TITLE = 'Sala de espera - Caren';

  useEffect(() => {
    async function getDataFromService() {
      const { data } = await scheduleDetailsService(consult_id);
      setScheduleDetails(data);
      const hasVerification = data?.confirmation_at != null;
      setVerification(hasVerification);

      if (!hasVerification) {
        const bankData = await bankTransferUpload(consult_id);
        setPreAuthUpload(bankData?.path);
        bankData.payment_info.pix = bankData.payment_info?.pix || [];
        setDoctorBankDetails(bankData);
      }
    }

    getDataFromService();
  }, [consult_id]);

  const [openModal, setOpenModal] = useState(false);
  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  function handleOpenBankModal() {
    setOpenModal(!openModal);
  }

  const [loading, setLoading] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const { status } = await api.put(preAuthUpload, image, {
        withCredentials: false,
      });
      if (status === 200) {
        await bankTransferReceipt(consult_id, preAuthUpload);
        setVerification(true);
        setOpenModal(!openModal);
      }
    } catch (error) {
      toast(t('Ocorreu um erro ao enviar o comprovante.'), {
        variant: 'error',
      });
    }
    setLoading(false);
  };

  const renderPixfieldTypeName = (pixFieldType) => {
    switch (pixFieldType) {
      case 'email':
        return t('E-mail');
      case 'fiscal_code':
        return t('CPF');
      case 'phone':
        return t('Celular');
      default:
        return t('Chave');
    }
  };

  const renderPixFields = (bankDetails) => {
    const pixFields = bankDetails.payment_info?.pix || [];

    if (pixFields.length) {
      return pixFields.map((pix) => (
        <BankDetails>
          <BankLogo
            title={bankDetails?.payment_info?.bank_transfer?.bank?.name}
            titleSize={18}
            backgroundStyle={{
              width: 50,
              height: 50,
              borderRadius: '100%',
              backgroundColor: 'var(--caren-image-fallback)',
            }}
            imgUrl="https://cdn.media.caren.app/vendor/img/pix.png"
          />
          <BankInfo>
            <p>{t('Pix')}</p>
            <p>
              {renderPixfieldTypeName(pix.type)}: <b>{pix.key}</b>
            </p>
          </BankInfo>
        </BankDetails>
      ));
    }

    return null;
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Modal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        modalTitle={t('Enviar comprovante')}
        modalWidth="600px"
      >
        <form onSubmit={onSubmit}>
          <DropzoneArea
            maxFileSize={5242880}
            filesLimit={1}
            acceptedFiles={['image/*']}
            dropzoneText={t('Arraste ou selecione uma imagem')}
            previewGridClasses={{
              container: classes.dropzoneGrid,
            }}
            getFileAddedMessage={(fileName) =>
              `${t('Anexo')} ${fileName} ${t('adicionado com sucesso.')}`
            }
            getFileRemovedMessage={(fileName) =>
              `${t('Anexo')} ${fileName} ${t('removido.')}`
            }
            getFileLimitExceedMessage={(_filesLimit) =>
              t('Apenas um anexo é permitido.')
            }
            onChange={(files) => onDrop(files)}
          />
          {image?.name?.length && (
            <ButtonUpload type="submit">
              {loading ? <LoadingComponent /> : t('Enviar')}
            </ButtonUpload>
          )}
        </form>
      </Modal>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <FindADoctor>
        <p>
          {t('Teleconsulta')}: {getDoctorTitle(scheduleDetails?.doctor)}{' '}
          {scheduleDetails?.doctor?.name}
        </p>
        <div>
          <span>{t('Horário')}: </span>
          <span>{formattedDate(scheduleDetails.scheduled_datetime)}</span>
          <span>
            {momentUtcLocal(scheduleDetails.scheduled_datetime).format(
              FORMAT['hour-and-minute']
            )}
          </span>
        </div>
        <p>
          <b>{t('Valor')}</b>: {scheduleDetails.billing_amount}
        </p>
      </FindADoctor>
      <Container>
        {verification ? (
          <VerificationStep
            consultId={consult_id}
            setVerification={setVerification}
            scheduleDetails={scheduleDetails}
            doctorBankDetails={doctorBankDetails}
          />
        ) : (
          <>
            <MainText>{t('Enviar comprovante de depósito')}</MainText>
            <ProcessContainer>
              <UserProcessContainer>
                <UserProcessBackground>
                  <UserProcessImg
                    opacity={1}
                    imgUrl={scheduleDetails?.patient?.avatar_url}
                    title={scheduleDetails?.patient?.name}
                    titleSize={50}
                    backgroundStyle={{
                      width: 160,
                      height: 160,
                      borderRadius: 140,
                      backgroundColor: 'var(--caren-image-fallback)',
                      marginRight: 5,
                    }}
                  />
                </UserProcessBackground>
              </UserProcessContainer>

              {dots.map((_, index) => (
                <ProcessLine
                  color="var(--caren-lightgrey)"
                  key={index.toString()}
                />
              ))}
              <UploadIcon animated />
              {dots.map((_, index) => (
                <ProcessLine color="#e8e8e8" key={index.toString()} />
              ))}
              <UserProcessContainer>
                <UserProcessBackground>
                  <UserProcessImg
                    opacity={0.5}
                    imgUrl={scheduleDetails?.doctor?.avatar_url}
                    title={scheduleDetails?.doctor?.name}
                    titleSize={50}
                    backgroundStyle={{
                      width: 160,
                      height: 160,
                      borderRadius: 140,
                      backgroundColor: 'var(--caren-image-fallback)',
                      marginRight: 5,
                    }}
                  />
                </UserProcessBackground>
              </UserProcessContainer>
            </ProcessContainer>
            <Border />
            <ProcessFooter>
              <ProcessTime>
                <Text>{t('Tempo para confirmação')}</Text>
                <ClockContainer className="time">
                  {scheduleDetails?.confirmation_deadline && (
                    <Countdown
                      date={`${scheduleDetails?.confirmation_deadline}z`}
                      renderer={({
                        formatted: { hours, minutes, seconds },
                      }) => (
                        <>
                          <AwaitingReceipt>
                            {t('Aguardando comprovante')} <FiAlertTriangle />
                          </AwaitingReceipt>
                          <ReceiptConfirmationCountdown>
                            {hours}:{minutes}:{seconds}
                          </ReceiptConfirmationCountdown>
                        </>
                      )}
                    />
                  )}
                </ClockContainer>
              </ProcessTime>
              <DepositData>
                <Text>{t('Dados para depósito')}</Text>
                <BankDetails>
                  <BankLogo
                    title={
                      doctorBankDetails?.payment_info?.bank_transfer?.bank?.name
                    }
                    titleSize={18}
                    backgroundStyle={{
                      width: 50,
                      height: 50,
                      borderRadius: '100%',
                      backgroundColor: 'var(--caren-image-fallback)',
                    }}
                    imgUrl={
                      doctorBankDetails?.payment_info?.bank_transfer?.bank?.icon
                    }
                  />
                  <BankInfo>
                    <p>
                      {t('Nome')}:{' '}
                      {doctorBankDetails?.payment_info?.bank_transfer?.name}
                    </p>
                    <p>
                      {t('CPF')}:{' '}
                      {
                        doctorBankDetails?.payment_info?.bank_transfer
                          ?.fiscal_code
                      }
                    </p>
                    <p>
                      {t('Ag.')}:{' '}
                      {
                        doctorBankDetails?.payment_info?.bank_transfer?.account
                          ?.branch
                      }{' '}
                      / {t('Cc.')}{' '}
                      {
                        doctorBankDetails?.payment_info?.bank_transfer?.account
                          ?.code
                      }{' '}
                    </p>
                  </BankInfo>
                </BankDetails>
                {renderPixFields(doctorBankDetails)}
                <SendReceipt onClick={handleOpenBankModal}>
                  <ReceiptLogo />
                  <p>{t('Enviar Comprovante')}</p>
                </SendReceipt>
              </DepositData>
            </ProcessFooter>
            <Border />
            <ProcessFooter>
              <FooterText>
                {t(
                  'Envie o comprovante de pagamento clicando no botão acima, para confirmar a sua consulta.'
                )}{' '}
                <FaQuestionCircle />
              </FooterText>
            </ProcessFooter>
          </>
        )}
      </Container>
    </>
  );
}
