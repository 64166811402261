import React, { useState, useEffect, useRef } from 'react';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { useAuth } from '../../hooks/AuthContext';

const CarenIFrameAgent = () => {
  const {
    credentials,
  } = useAuth();

  const iframeElement = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const TITLE = 'CarenIFrameAgent';
  const IFRAME_SRC = combineURLs(
    import.meta.env.VITE_IFRAME_DOMAIN,
    '/iframe-agent'
  );
  const IFRAME_ORIGIN = new URL(import.meta.env.VITE_IFRAME_DOMAIN).origin;

  useEffect(() => {
    if (!iframeLoaded) return;
    const targetWindow = iframeElement?.current?.contentWindow;
    if (targetWindow == null) return;

    let message = {};

    if (!credentials || credentials.token.token == null) {
      message = {
        type: 'LOGOUT',
      };
    } else {
      message = {
        type: 'LOGIN',
        key: credentials.session.key,
        userToken: credentials.token.token,
        userType: credentials.token.user_type,
      };
    }

    targetWindow.postMessage(message, IFRAME_ORIGIN);
  }, [iframeElement, iframeLoaded, credentials]);

  const onLoadFrame = () => setIframeLoaded(true);

  return (
    <iframe
      ref={iframeElement}
      src={IFRAME_SRC}
      title={TITLE}
      onLoad={onLoadFrame}
      style={{ position: 'absolute', width: 0, height: 0, border: 0 }}
    />
  );
};

export default CarenIFrameAgent;
