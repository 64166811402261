import { useEffect, useState } from 'react';
import useVideoContext from './useVideoContext';

export default function useRoomState () {
  const { room, roomCompleted } = useVideoContext();
  const [state, setState] = useState('disconnected');

  useEffect(() => {
    if (room) {
      const setRoomState = () => { setState(room.state); };
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }
    return () => {};
  }, [room]);

  if (roomCompleted) return 'completed';

  return state;
}
