import { useEffect, useState, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SmsIcon } from '../../assets/icons/sms-tracking.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { useToast } from '../../contexts/ToastContext';
import ImageOrName from '../../components/ImageOrName';
import Header from '../../components/Header';
import {
  Container,
  FirstGrid,
  SecondGrid,
  SecondHeader,
  Wrapper,
  WrapperLoading,
  SectionTitle,
  TeamTable
} from './team.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import DoctorPanel from '../../components/DoctorPanel';
import {
  deleteTeamConnection,
  deleteTeamInvitation,
  getTeamInvitations,
  resendTeamInvitation
} from '../../services/team';
import TeamInviteModal from '../../components/Modal/TeamInvite';
import { type Invite } from '../../v2/entities/invite';
import formattedDatetime from '../../utils/formattedDatetime';
import Modal from '../../components/Modal';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';

import { useAuth } from '../../hooks/AuthContext';
import hasTeamAccess from '../../utils/hasTeamAccess';
import IconButton from '../../v2/components/inputs/buttons/IconButton';
import UpgradeTeamDialog, {
  type UpgradeTeamDialogHandles
} from '../../v2/dialogs/upgrade/team';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
import SuccessStep from '../../v2/dialogs/consult/steps/success';
import { getFreePlanDetails } from '../../v2/services/plan';

const TITLE = 'Equipe - Caren';

export default function Team() {
  const { t } = useTranslation(['team']);
  const toast = useToast();
  const { credentials, refreshSession } = useAuth();
  const upgradeTeamDialogRef = useRef<UpgradeTeamDialogHandles>(null);

  const [loading, setLoading] = useState(true);
  const [freePlan, setFreePlan] = useState<null | Awaited<
    ReturnType<typeof getFreePlanDetails>
  >>(null);
  const [invites, setInvites] = useState<Invite[] | null>(null);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [invitationLink, setInvitationLink] = useState<string | null>(null);

  const [unreversibleType, setUnreversibleType] = useState<
    null | 'delete' | 'resend' | 'cancel'
  >(null);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  const getTeam = useCallback(async () => {
    try {
      setLoading(true);

      const data = await getTeamInvitations();

      setInvites(data);
      setLoading(false);
    } catch (error) {
      toast(t('Ops, não foi possível carregar os convites...'), {
        variant: 'error'
      });
    }
  }, []);

  const getFreePlan = useCallback(async () => {
    getFreePlanDetails().then(response => {
      setFreePlan(response);
    });
  }, []);

  const deleteInvite = useCallback(async (key: string) => {
    if (key.length <= 0) return;

    try {
      await deleteTeamInvitation(key);

      setInvites(old => old?.filter(item => item.key !== key) ?? null);

      toast(t('Convite deletado com sucesso!'), {
        variant: 'success'
      });

      getTeam();
    } catch (e) {
      toast(t('Ops, não foi possível deletar o convite :('), {
        variant: 'error'
      });
    }
  }, []);

  const deleteConnection = useCallback(async (key: string) => {
    if (key.length <= 0) return;

    try {
      await deleteTeamConnection(key);

      setInvites(old => old?.filter(item => item.key !== key) ?? null);

      toast(t('Membro removido da equipe com sucesso!'), {
        variant: 'success'
      });

      getTeam();
    } catch (e) {
      toast(t('Ops, não foi possível remover o membro :('), {
        variant: 'error'
      });
    }
  }, []);

  const resendInvite = useCallback(async (key: string) => {
    if (key.length <= 0) return;

    try {
      const { link } = await resendTeamInvitation(key);

      setInvitationLink(link);

      toast(t('Convite reenviado com sucesso!'), {
        variant: 'success'
      });

      setOpenConfirmation(true);
    } catch (e) {
      toast(t('Ops, não foi possível reenviar o convite :('), {
        variant: 'error'
      });
    }
  }, []);

  const handleToggleConfirmationModal = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const decideUnreversibleTitle = useCallback(() => {
    if (unreversibleType === 'delete') {
      return t(
        'Você tem certeza que deseja remover este membro da sua equipe?'
      );
    }
    if (unreversibleType === 'cancel') {
      return t('Você tem certeza que deseja cancelar esse convite?');
    }
    if (unreversibleType === 'resend') {
      return t('Você tem certeza que deseja reenviar esse convite?');
    }

    return null;
  }, [unreversibleType]);

  const handleConfirmation = useCallback(
    (status: boolean) => {
      if (status && selectedKey) {
        if (unreversibleType === 'resend') {
          resendInvite(selectedKey);
        } else if (unreversibleType === 'cancel') {
          deleteInvite(selectedKey);
        } else if (unreversibleType === 'delete') {
          deleteConnection(selectedKey);
        }
      }

      setUnreversibleType(null);
      setSelectedKey(null);
      setOpenUnreversible(false);
    },
    [selectedKey, unreversibleType]
  );

  const decideClickFunc = () => {
    if (
      hasTeamAccess(credentials?.session) === true ||
      (freePlan && freePlan.used < freePlan.total)
    ) {
      return () => {
        setInviteModalOpen(true);
      };
    }

    return () => upgradeTeamDialogRef.current?.open();
  };

  useEffect(() => {
    getTeam();
    getFreePlan();

    if (credentials?.token) {
      refreshSession(credentials.token);
    }
  }, []);

  return (
    <>
      <Helmet title={TITLE}></Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel />
        </FirstGrid>

        <SecondGrid>
          <SecondHeader>
            <button
              onClick={decideClickFunc()}
              type="button"
              title="Convidar novas pessoas à equipe"
            >
              Novo integrante
            </button>
          </SecondHeader>

          {loading ? (
            <Wrapper>
              <WrapperLoading>
                <span />
                <span />
              </WrapperLoading>
            </Wrapper>
          ) : (
            <Wrapper>
              <SectionTitle>Minha Equipe</SectionTitle>
              {invites && invites.length > 0 ? (
                <TeamTable>
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col" className="center">
                        Último Acesso
                      </th>
                      <th scope="col" className="center">
                        Ações
                      </th>
                      <th scope="col" className="center">
                        Contatos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invites.map(item => (
                      <tr key={item.key}>
                        <td>
                          {item.secretary ? (
                            <div className="perfil">
                              <ImageOrName
                                src={item.secretary.avatar_url}
                                title={item.secretary.name}
                                titleSize={16}
                                backgroundStyle={{
                                  width: 48,
                                  height: 48,
                                  borderRadius: '100%',
                                  backgroundColor: 'var(--caren-image-fallback)'
                                }}
                              />{' '}
                              <p>{item.secretary.name}</p>
                            </div>
                          ) : (
                            <div className="perfil">
                              <div className="empty" />
                            </div>
                          )}
                        </td>
                        <td className="center">
                          <span>Último Acesso</span>
                          {item.accepted_at === null &&
                          item.rejected_at === null ? (
                            <p className="warning">
                              Convite enviado: Aguardando usuário aceitar
                            </p>
                          ) : (
                            <p>
                              {item.secretary?.last_login
                                ? formattedDatetime(item.secretary.last_login)
                                : 'Sem Informação'}
                            </p>
                          )}
                        </td>
                        <td className="center">
                          <span>Ações</span>
                          <div className="buttons">
                            {item.accepted_at === null &&
                            item.rejected_at === null ? (
                              <>
                                <IconButton
                                  title="Reenviar Convite"
                                  onClick={() => {
                                    setSelectedKey(item.key);
                                    setUnreversibleType('resend');
                                    setOpenUnreversible(true);
                                  }}
                                  icon={SmsIcon}
                                />
                                <IconButton
                                  title="Deletar Convite"
                                  onClick={() => {
                                    setSelectedKey(item.key);
                                    setUnreversibleType('cancel');
                                    setOpenUnreversible(true);
                                  }}
                                  icon={TrashIcon}
                                />
                              </>
                            ) : (
                              <>
                                <IconButton
                                  title="Remover da Equipe"
                                  onClick={() => {
                                    if (item?.secretary?.key) {
                                      setSelectedKey(item.secretary.key);
                                      setUnreversibleType('delete');
                                      setOpenUnreversible(true);
                                    }
                                  }}
                                  icon={TrashIcon}
                                />
                              </>
                            )}
                          </div>
                        </td>
                        <td className="center">
                          <span>Contatos</span>
                          <p>{item.email}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TeamTable>
              ) : (
                <p className="empty text-xl">
                  Você ainda não possui membros cadastrados em sua equipe,
                  convide um novo integrante!
                </p>
              )}
            </Wrapper>
          )}
        </SecondGrid>
      </Container>

      <TeamInviteModal
        open={inviteModalOpen}
        onClose={success => {
          setInviteModalOpen(false);

          if (success) {
            toast(t('Convite enviado para o email!'), {
              variant: 'success'
            });
            getTeam();
          }
        }}
      />

      <UnreversibleConfirmationModal
        title={decideUnreversibleTitle()}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
        cancelButtonText={null}
        confirmButtonText={null}
      />

      <Modal
        handleOpenModal={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        openModal={openConfirmation}
        modalTitle={t('Convite Enviado!')}
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside
      >
        <SuccessStep
          title="Tudo pronto! O convite para o funcionário foi enviado por e-mail."
          subTitle="Você receberá uma notificação caso ele seja aceito."
          enableWhatsapp={false}
          invitationLink={invitationLink ?? ''}
          onClose={handleToggleConfirmationModal}
        />
      </Modal>
      <UpgradeTeamDialog ref={upgradeTeamDialogRef} />
      <Tawk />
      <Chatwoot />
    </>
  );
}
