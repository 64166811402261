import { BiBody } from 'react-icons/bi';
import {
  FaRegGrin,
  FaRegMeh,
  FaRegFrown,
  FaRegFrownOpen,
  FaRegSadCry,
  FaRegSadTear,
  FaHospital,
  FaQuestion,
  FaRegTired,
  FaRegSmile,
  FaRegLaugh,
  FaHeart,
  FaHome,
  FaChurch,
  FaUser,
  FaUserFriends,
  FaUserInjured,
  FaRegLaughBeam,
  FaRegCircle,
  FaRegDotCircle,
  FaRegCalendarCheck,
  FaRegSquare,
  FaRegCheckSquare,
  FaUserMd,
  FaSyringe,
  FaFileAlt,
  FaRegFileAlt,
  FaHandsHelping,
  FaClipboardCheck,
  FaSkullCrossbones,
  FaUserNurse,
  FaNotesMedical,
  FaStethoscope,
  FaCheckCircle,
  FaArrowAltCircleDown,
  FaTimesCircle,
  FaDiagnoses,
  FaExclamationCircle,
  FaRibbon,
  FaRegIdCard,
  FaVideo
} from 'react-icons/fa';
import { RiVirusFill } from 'react-icons/ri';
import {
  MdAccessibility,
  MdAssignmentInd,
  MdCheck,
  MdClose,
  MdDirectionsBike,
  MdDirectionsRun,
  MdDirectionsWalk,
  MdFindInPage,
  MdFolder,
  MdInfoOutline,
  MdThumbUp,
  MdThumbDown,
  MdWork
} from 'react-icons/md';
import { GiPill, GiMedicines } from 'react-icons/gi';
import { BsCircle } from 'react-icons/bs';
import styled from 'styled-components/macro';

export const FLOW_ICONS = {
  BiBody,
  FaArrowAltCircleDown,
  FaChurch,
  FaCheckCircle,
  FaClipboardCheck,
  FaDiagnoses,
  FaExclamationCircle,
  FaFileAlt,
  FaHandsHelping,
  FaHeart,
  FaHome,
  FaHospital,
  FaNotesMedical,
  FaQuestion,
  FaRegFileAlt,
  FaRegFrown,
  FaRegFrownOpen,
  FaRegGrin,
  FaRegSmile,
  FaRegLaugh,
  FaRegLaughBeam,
  FaRegMeh,
  FaRegSadCry,
  FaRegSadTear,
  FaRegTired,
  FaRegCircle,
  FaRegDotCircle,
  FaRegSquare,
  FaRegCheckSquare,
  FaRegCalendarCheck,
  FaRibbon,
  FaSkullCrossbones,
  FaStethoscope,
  FaSyringe,
  FaTimesCircle,
  FaUser,
  FaUserMd,
  FaUserNurse,
  FaUserFriends,
  FaUserInjured,
  FaVideo,
  FaRegIdCard,
  GiMedicines,
  GiPill,
  MdAccessibility,
  MdAssignmentInd,
  MdCheck,
  MdClose,
  MdDirectionsBike,
  MdDirectionsRun,
  MdDirectionsWalk,
  MdFindInPage,
  MdFolder,
  MdInfoOutline,
  MdThumbDown,
  MdThumbUp,
  MdWork,
  BsCircle,
  RiVirusFill
};

export const NotificationBox = styled.div`
  background-color: white;
  position: absolute;
  top: 40px;
  right: 0px;
  width: 440px;
  max-width: 90vw;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  z-index: 4000;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  [role='separator'] {
    &:last-child {
      display: none;
    }
  }

  .notification-item {
    position: relative;
    transition: background-color 0.5s;

    .notification-read-tooltip {
      color: #00cdd6;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      right: 16px;
      bottom: 15px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &.read .notification-read-tooltip {
      opacity: 1;
    }

    .notification-date-secondary {
      display: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.12);

      .notification-date-primary {
        display: none;
      }

      .notification-date-secondary {
        display: block;
      }
    }
  }
`;

export const NotificationBoxLoading = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  z-index: 50;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`;

export const NotificationBoxLoadingSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: relative;

  @keyframes notifications_progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: notifications_progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const NotificationBoxTitle = styled.div`
  color: black;
  font-size: 21px;
  margin-bottom: 14px;
`;

export const NotificationBoxCTA = styled.button`
  background-color: transparent;
  position: relative;
  color: #077a94;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 14px;
  transition: color 0.3s;

  &.disabled {
    display: none;
  }

  &:hover {
    color: black;
  }
`;

export const NotificationTabs = styled.div`
  position: relative;
  width: 100%;
`;

export const NotificationTab = styled.div`
  position: relative;
  display: none;

  &.active {
    display: block;
  }

  .no-results {
    padding: 20px 0;
    display: flex;
    align-items: center;

    svg {
      display: block;
      margin-right: 8px;
    }
  }
`;

export const NotificationNavigation = styled.div`
  position: relative;

  ul {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 20px;
      }

      button {
        background-color: transparent;
        color: #47525e;
        font-size: 11px;
        font-weight: 'bold';
        text-transform: uppercase;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s;
        padding: 0 3px 3px;
      }

      &.active button {
        border-color: #fdbf71;
      }
    }
  }
`;

export const NotificationBoxContent = styled.div`
  position: relative;
  width: 100%;
  max-height: 350px;
  overflow: auto;

  .notification-list-action {
    background-color: transparent;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }
`;
