import { z } from 'zod';
import validateCpf from '../../utils/validateCpf';
import { ActiveSubscriptionSchema } from './active_subscription';
import moment from 'moment';

export const DoctorSchema = z.object({
  key: z.string(),
  name: z.string(),
  gender: z.enum(['female', 'male', 'unknown']).nullable(),
  avatar_url: z.string().nullable(),
  specialties: z
    .object({
      name: z.string()
    })
    .array()
    .nullable(),
  active_subscription: ActiveSubscriptionSchema.nullable().optional(),
  insurance_partners: z
    .object({
      key: z.string(),
      name: z.string(),
      code: z.string()
    })
    .array()
    .nullable()
    .optional()
});

export type Doctor = z.infer<typeof DoctorSchema>;

export const DoctorCreateSchema = z
  .object({
    name: z
      .string({ required_error: 'Digite seu nome e sobrenome' })
      .trim()
      .nonempty('Digite seu nome e sobrenome')
      .regex(/[A-Za-z]\s+[A-Za-z]/g, 'Por favor informe nome e sobrenome'),
    email: z
      .string({ required_error: 'Digite um email' })
      .trim()
      .nonempty('Digite um email')
      .email('Digite um email válido'),
    phone: z
      .string({ required_error: 'Digite um telefone' })
      .nonempty('Digite um telefone')
      .regex(
        /\(\d{2}\)\s\d{5}-\d{4}/,
        'Digite o telefone no formato: (xx) xxxxx-xxxx'
      ),
    fiscal_code: z
      .string({ required_error: 'Digite um CPF' })
      .nonempty('Digite um CPF')
      .regex(/\d{3}\.\d{3}\.\d{3}-\d{2}/, 'Digite um CPF válido')
      .refine(validateCpf, {
        message: 'Verifique se o CPF foi digitado corretamente'
      }),
    birthdate: z
      .string({ required_error: 'Digite uma data válida' })
      .regex(/\d{4}-\d{2}-\d{2}/, 'Digite uma data válida')
      .refine(
        val => moment(val).startOf('day').isBefore(moment().startOf('day')),
        {
          message: 'A data não pode ser futura'
        }
      ),
    password: z
      .string({ required_error: 'Digite uma senha válida' })
      .nonempty('Digite uma senha válida')
      .min(8, 'Pelo menos 8 caracteres')
      .regex(/[0-9]/g, 'Digite pelo menos 1 caractere numérico')
      .regex(/[A-Za-z]/g, 'Digite pelo menos 1 caractere alfabético'),
    password_confirmation: z
      .string({ required_error: 'Digite uma senha válida' })
      .nonempty('Digite uma senha válida')
      .min(8, 'Digite pelo menos 8 caracteres')
      .regex(/[0-9]/g, 'Digite pelo menos 1 caractere numérico')
      .regex(/[A-Za-z]/g, 'Digite pelo menos 1 caractere alfabético'),
    accept_terms: z
      .boolean({
        required_error:
          'É necessário aceitar os Termos de Uso e Políticas de Privacidade'
      })
      .refine(
        value => value,
        'É necessário aceitar os Termos de Uso e Políticas de Privacidade'
      ),
    veracity: z
      .boolean({
        required_error: 'É necessário declarar a veracidade dos dados'
      })
      .refine(value => value, 'É necessário declarar a veracidade dos dados')
  })
  .refine(data => data.password === data.password_confirmation, {
    message: 'Digite a mesma senha do campo anterior',
    path: ['password_confirmation']
  });

export type DoctorCreate = z.infer<typeof DoctorCreateSchema>;

export const SpecialtiesSchema = z.object({
  key: z.string(),
  name: z.string()
});

export type Specialties = z.infer<typeof SpecialtiesSchema>;

export const StatsSchema = z.object({
  counts: z.object({
    total_concluded_count: z.number(),
    total_pending_assessment_count: z.number(),
    total_pending_subs_count: z.number(),
    total_pending_appointment_count: z.number(),
    total_confirmed_count: z.number()
  })
});

export type Stats = z.infer<typeof StatsSchema>;
