import { RiCoupon3Line } from 'react-icons/ri';
import styled from 'styled-components/macro';
import ImageOrName from '../../components/ImageOrName';
import nurseLogo from '../../assets/images/nurse-colored-old.png';

export const Container = styled.div`
  background-color: var(--caren-white);
  padding: 40px;
`;

export const FindADoctorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const BackButtonContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 0 8px;
`;

export const FindADoctor = styled.div`
  background-color: #f8f8f8;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  p {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  span {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  div {
    display: flex;
  }
`;

export const MainText = styled.p`
  color: var(--caren-grey);
  font-size: 27px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const ProcessContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const ProcessFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

export const UserProcessContainer = styled.div``;

export const UserProcessBackground = styled.div`
  background-color: #f8f8f8;
  border-radius: 50%;
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 110px;
    height: 110px;
  }
`;

export const UserProcessImg = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  background-color: var(--caren-white);
  border-radius: 50%;
  width: 160px;
  height: 160px;
  opacity: ${({ opacity }) => opacity || 1};

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const ProcessLine = styled.div`
  min-width: 5px;
  min-height: 5px;
  background-color: ${({ color }) => color ?? '#888'};
  border-radius: 100%;

  min-width: 5px;
  min-height: 5px;
  background-color: ${({ color }) => color ?? '#888'};
  border-radius: 100%;

  @media (max-width: 1220px) {
    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 1110px) {
    margin: 0 3px;
    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin: 3px 0;
    &:nth-child(3n + 0) {
      display: none;
    }
  }
`;

export const FooterText = styled.p`
  width: 100%;
  text-align: center;
  color: var(--caren-grey);
`;

export const VoucherCardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const VoucherCard = styled.div`
  margin-top: 10px;
  width: 399px;
  height: 150px;
  perspective: 1000px;
`;

export const VoucherCardContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

export const VoucherCardFront = styled.form`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--caren-white);
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input {
    border: 1px solid var(--caren-lightgrey);
    height: 35px;
    border-radius: 5px;
    width: 1.5em;
  }

  div {
    text-align: center;
  }
`;

export const VoucherCardButton = styled.button`
  background-color: var(--caren-action-button);
  border: 1px solid rgba(199, 199, 199, 0);
  border-radius: 5px;
  width: 95px;
  height: 43px;
  color: var(--caren-white);
  font-size: bold;
`;

export const MainIconContainer = styled.div`
  background-color: #f3f3f3;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainIcon = styled(RiCoupon3Line).attrs({
  size: 90,
  color: 'var(--caren-green)'
})`
  filter: ${({ success }) => (success ? 'none' : 'grayscale(100%)')};
  animation: ${({ animated }) => animated && 'wait 1.5s infinite 0s ease-out;'}
    @keyframes wait {
    70% {
      transform: rotate(0deg) scale(0.95);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;

export const Border = styled.div`
  border-bottom: 1px solid var(--caren-lightgrey);
  margin: 50px 200px;

  @media (max-width: 768px) {
    margin: 50px 0;
  }
`;

export const NurseLogo = styled.img.attrs({
  src: nurseLogo
})`
  width: 80%;
  height: 80%;
`;
