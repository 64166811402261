import React from 'react';

type TableHeaderProps = {
  children: React.ReactNode
}

export default function TableHeader({ children }: TableHeaderProps) {
  return (
    <thead className="w-full bg-gray-150 hidden lg:table-header-group">
      {children}
    </thead>
  );
}
