import styled from 'styled-components/macro';

function activeStyle(_isDoctor: boolean) {
  return `
  background-color: var(--caren-white);
  color: var(--caren-green);
  font-weight: bold;
  `;
}

export const Container = styled.div`
  ul {
    display: flex;
    margin: 0;
    overflow-x: auto;
  }
`;

export const List = styled.li<{ isActive: boolean, isDoctor: boolean }>`
  cursor: pointer;
  background-color: var(--caren-image-fallback);
  color: #262c2e;
  width: auto;
  padding: 5px 15px;
  text-align: center;
  border-radius: 5px 5px 0 0;

  ${({ isActive, isDoctor }) => isActive && activeStyle(isDoctor)}

  &:not(:first-child) {
    margin: 0 3px;
  }
`;

export const TabBody = styled.div`
  padding: 20px;
  background-color: var(--caren-white);
`;

export const BackButtonContainer = styled.div`
  margin-bottom: 10px;
`;
