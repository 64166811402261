import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  CircularProgress,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Tooltip
} from '@mui/material';
import debounce from 'lodash.debounce';
import { CheckCircle, ArrowBack, Cancel } from '@mui/icons-material';
import {
  AUTOCOMPLETE_DEFAULT_FILTER_OPTIONS,
  AUTOCOMPLETE_MINIMUM_LENGTH,
  CLINICAL_EVALUATION_FIELDS
} from '../../../../../../../constants/common';
import { upsertClinicalEvaluationService } from '../../../../../../../services/clinical-evaluation.service';
import { useToast } from '../../../../../../../contexts/ToastContext';
import ReadOnlyClinicalEvaluationField from './ReadOnlyClinicalEvaluationField';
import MevoContainer from './MevoContainer';
import { teleconsultationKeyService } from '../../../../../../../services/anamnesis-detail.service';
import { deletePrescriptionService } from '../../../../../../../services/mevo.service';
import UnreversibleConfirmationModal from '../../../../../../UnreversibleConfirmationModal';

const ClinicalEvaluation = ({
  detail,
  anamnesisKey,
  onUpdate,
  onClickBack,
  editable,
  editingField = null,
  setHideActions = false
}) => {
  const toast = useToast();
  const { t } = useTranslation(['video_call']);
  const location = useLocation();
  const isVideoCall = location.pathname.includes('/video-call/');

  const [editing, setEditing] = useState(null);
  const [input, setInput] = useState(null);
  const [sending, setSending] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteQuery, setAutocompleteQuery] = useState('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  let loadAutocompleteOptions;

  useEffect(() => {
    if (editing?.type === 'autocomplete' && editing?.meta?.initialLoad) {
      loadAutocompleteOptions();
    }
  }, [editing]);

  const onChangeClinicalEvaluationInputText = e => {
    setInput(e.target.value);
  };

  const onChangeClinicalEvaluationInputAutocomplete = (e, newValue) => {
    setInput(newValue);
  };

  const formatSubmitPayload = () => {
    switch (editing.type) {
      case 'autocomplete': {
        const { codeField } = editing.meta;

        return input.map(entry => entry[codeField]);
      }

      case 'mevo':
        return null;

      default:
        return input;
    }
  };

  const isFieldRequired = field =>
    field.required(detail?.clinical_evaluation?.[field.key]);

  const isFieldError = field => {
    const isErrorFunc = field?.meta?.isError;

    const value = detail?.clinical_evaluation?.[field.key];
    if (isErrorFunc) {
      return isErrorFunc(value);
    }

    return false;
  };

  const isFieldEmpty = field => {
    const isEmptyFunc = field?.meta?.isEmpty;

    const value = detail?.clinical_evaluation?.[field.key];
    if (isEmptyFunc) {
      return isEmptyFunc(value);
    }

    return !value;
  };

  const onClickEvaluationField = (_e, field) => {
    if (setHideActions) setHideActions(true);

    setEditing(field);
    setInput(
      isFieldEmpty(field)
        ? field.default
        : detail?.clinical_evaluation?.[field.key]
    );
  };

  useEffect(() => {
    if (editingField == null) return;

    onClickEvaluationField(null, editingField);
  }, [editingField]);

  const upsertClinicalEvaluation = async () => {
    try {
      setSending(true);
      const updated = await upsertClinicalEvaluationService(anamnesisKey, {
        [editing.key]: formatSubmitPayload()
      });

      toast(t(`O preenchimento de '${editing.label}' foi salvo.`), {
        variant: 'success'
      });

      onUpdate(updated);

      setEditing(null);
      setAutocompleteOptions([]);

      if (setHideActions) setHideActions(false);
    } catch (error) {
      toast(t('Ops, não foi possível salvar a resposta...'), {
        variant: 'error'
      });
    }

    setSending(false);
  };

  const onSubmitClinicalEvaluationInput = async _e => {
    if (input != null) {
      upsertClinicalEvaluation();
    }
  };

  loadAutocompleteOptions = async query => {
    try {
      setAutocompleteLoading(true);

      const { service: autocompleteSearchService } = editing?.meta;
      const { data } = await autocompleteSearchService(query);

      setAutocompleteOptions(data);
      setAutocompleteLoading(false);
    } catch (error) {
      toast(t('Ops, não foi possível carregar o autopreenchimento...'), {
        variant: 'error'
      });

      setAutocompleteLoading(false);
    }
  };

  const handleAutocompleteSearch = useCallback(
    debounce(async query => {
      if (query.length >= AUTOCOMPLETE_MINIMUM_LENGTH || query === '') {
        loadAutocompleteOptions(query);
      }
    }, 500),
    [editing]
  );

  const onChangeAutocompleteQuery = (_e, query, _reason) => {
    setAutocompleteQuery(query);
    handleAutocompleteSearch(query);
  };

  const renderClinicalEvaluationFormHeader = () => (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ marginBottom: 4 }}
      >
        <Grid item xs>
          <Typography variant="h6">{editing.label}</Typography>
        </Grid>
      </Grid>
    </>
  );

  const renderClinicalEvaluationFormSubmit = () => (
    <>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        startIcon={<ArrowBack />}
        onClick={onSubmitClinicalEvaluationInput}
        disabled={sending}
        sx={{ marginTop: 4 }}
      >
        {sending ? t('Voltando...') : t('Voltar')}
      </Button>

      <span
        style={{
          display: 'block',
          fontSize: '12px',
          textAlign: 'center',
          marginTop: '10px'
        }}
      >
        Todas as alterações feitas serão salvas automaticamente ao clicar no
        botão acima.
      </span>
    </>
  );

  const autocompleteGetOptionLabel = option => {
    const { buildOptionLabel } = editing?.meta;

    return buildOptionLabel(option);
  };

  const autocompleteIsOptionEqualToValue = (option, value) => {
    const { codeField } = editing?.meta;

    return option?.[codeField] === value?.[codeField];
  };

  const renderClinicalEvaluationAutocompleteForm = () => (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      sx={{ flex: 1 }}
    >
      <Grid item>{renderClinicalEvaluationFormHeader()}</Grid>
      <Grid item xs sx={{ width: '100%' }}>
        <Autocomplete
          multiple
          options={autocompleteOptions}
          value={input}
          onChange={onChangeClinicalEvaluationInputAutocomplete}
          inputValue={autocompleteQuery}
          onInputChange={onChangeAutocompleteQuery}
          loading={autocompleteLoading}
          getOptionLabel={autocompleteGetOptionLabel}
          isOptionEqualToValue={autocompleteIsOptionEqualToValue}
          loadingText={t('Carregando...')}
          noOptionsText={t('Nenhuma opção encontrada.')}
          filterOptions={AUTOCOMPLETE_DEFAULT_FILTER_OPTIONS}
          renderInput={params => (
            <TextField
              {...params}
              label="Preencha aqui"
              fullWidth
              variant="outlined"
            />
          )}
          ChipProps={{
            variant: 'filled'
          }}
        />
      </Grid>
      <Grid item>{renderClinicalEvaluationFormSubmit()}</Grid>
    </Grid>
  );

  const renderClinicalEvaluationTextForm = () => (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      sx={{ flex: 1 }}
    >
      <Grid item>{renderClinicalEvaluationFormHeader()}</Grid>
      <Grid item xs>
        <TextField
          label={t('Preencha aqui')}
          type="text"
          value={input}
          onChange={onChangeClinicalEvaluationInputText}
          variant="outlined"
          multiline
          minRows={4}
          maxRows={20}
          fullWidth
        />
      </Grid>
      <Grid item>{renderClinicalEvaluationFormSubmit()}</Grid>
    </Grid>
  );

  const finishEditing = () => {
    setEditing(null);
    setAutocompleteOptions([]);
    if (onClickBack) onClickBack();

    if (setHideActions) setHideActions(false);
  };

  const renderClinicalEvaluationEditForm = () => {
    switch (editing.type) {
      case 'text':
        return renderClinicalEvaluationTextForm();

      case 'autocomplete':
        return renderClinicalEvaluationAutocompleteForm();

      case 'mevo':
        return (
          <MevoContainer
            anamnesisKey={anamnesisKey}
            detail={detail}
            popOut={isVideoCall}
            onCancel={finishEditing}
            onSubmit={upsertClinicalEvaluation}
          />
        );

      default:
        return null;
    }
  };

  const renderFieldPreview = field => {
    switch (field.type) {
      case 'text':
        return detail?.clinical_evaluation?.[field.key];

      case 'mevo': {
        const values = detail?.clinical_evaluation?.[field.key] || [];

        return (
          <>
            {values.map(item => (
              <Chip
                key={item.key}
                label={item?.[field?.meta?.previewField]}
                onDelete={handleOnDeleteChip(item)}
              />
            ))}
          </>
        );
      }

      case 'autocomplete': {
        const values = detail?.clinical_evaluation?.[field.key] || [];
        const previewText = values
          .map(item => item?.[field?.meta?.previewField])
          .join(', ');

        const tooltip = values.map(field?.meta?.buildOptionLabel).join('\n');

        return (
          <Tooltip
            title={tooltip}
            PopperProps={{ sx: { whiteSpace: 'pre-wrap' } }}
          >
            <span>{previewText}</span>
          </Tooltip>
        );
      }

      default:
        return null;
    }
  };

  const handleOnDeleteChip = (prescription) => {
    if (prescription.concluded_at == null) {
      return () => { setConfirmationDialogOpen(true) }
    }

    return null;
  }

  const handleDeletePrescription = async (confirmed) => {
    if (confirmed) {
      const {
        teleconsultation: { key }
      } = await teleconsultationKeyService(anamnesisKey);

      try {
        await deletePrescriptionService(key);
        setConfirmationDialogOpen(false);
        window.location.reload();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Erro ao deletar a prescrição.');
      }
    } else {
      setConfirmationDialogOpen(false);
    }
  }

  const renderFieldItem = (field, index) => {
    if (isFieldError(field)) {
      return <Cancel sx={{ color: 'var(--caren-red)' }} />;
    }

    if (isFieldEmpty(field)) {
      return (
        <span
          style={{
            backgroundColor: '#ececec',
            color: 'var(--caren-icongrey)',
            fontWeight: 'bold',
            fontSize: '0.785rem',
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {index + 1}
        </span>
      );
    }

    return <CheckCircle sx={{ color: 'var(--caren-success)' }} />;
  };

  const renderEditableClinicalEvaluation = () => (
    <>
      <List>
        {CLINICAL_EVALUATION_FIELDS.map((field, index) => (
          <ListItem sx={{ padding: 0, marginBottom: '10px' }}>
            <ListItemButton
              disabled={field?.meta?.disableOnConclude && !isFieldEmpty(field)}
              onClick={e => onClickEvaluationField(e, field)}
              sx={{ overflow: 'hidden' }}
            >
              <ListItemIcon>{renderFieldItem(field, index)}</ListItemIcon>
              <ListItemText
                primary={`${field.label}${isFieldRequired(field) ? ' *' : ''}`}
                secondary={renderFieldPreview(field)}
                secondaryTypographyProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
                sx={{ overflow: 'hidden' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <UnreversibleConfirmationModal
        title={t('Tem certeza de que deseja excluir esta receita?')}
        openModal={confirmationDialogOpen}
        setOpenModal={setConfirmationDialogOpen}
        handleConfirmation={handleDeletePrescription}
        cancelButtonText={t('Cancelar')}
      />
    </>
  );

  const renderClinicalEvaluation = () => (
    <List>
      {CLINICAL_EVALUATION_FIELDS.map(field => (
        <ListItem>
          <ListItemText
            primary={field.label}
            secondary={
              <ReadOnlyClinicalEvaluationField
                field={field}
                clinicalEvaluation={detail?.clinical_evaluation}
              />
            }
          />
        </ListItem>
      ))}
    </List>
  );

  if (editing) {
    return renderClinicalEvaluationEditForm();
  }

  if (detail) {
    if (editable) {
      return (
        <>
          {renderEditableClinicalEvaluation()}
          <Typography variant="subtitle2">* Obrigatório</Typography>
        </>
      );
    }

    return renderClinicalEvaluation();
  }

  return <CircularProgress />;
};

export default ClinicalEvaluation;
