import { UserIcon } from '@heroicons/react/24/solid';

type PersonImageProps = {
  name: string | null;
  image?: string | null;
}

export default function PersonImage({ name, image }: PersonImageProps) {
  return (
    <div className="grid h-full w-full shrink-0 place-items-center overflow-hidden rounded-full bg-gray-200">
      {image ? (
        <img
          src={image}
          className="object-cover object-center"
          alt={name ?? 'Imagem do usuário'}
        />
      ) : name ? (
        <p className="text-base font-semibold text-blue-650">
          {name.substring(0, 2).toUpperCase()}
        </p>
      ) : (
        <UserIcon className="h-[90%] w-[90%]" />
      )}
    </div>
  );
}
