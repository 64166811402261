import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Typography,
  Grid,
  IconButton,
} from '@mui/material';
import { ArrowBack, Description } from '@mui/icons-material';
import { FORMAT } from '../../../../../../../utils/moment/momentFormat';
import Anamnesis from '../Anamnesis';
import { useToast } from '../../../../../../../contexts/ToastContext';
import { anamnesisDetailService } from '../../../../../../../services/anamnesis-detail.service';

const History = ({ detail }) => {
  const toast = useToast();
  const { t } = useTranslation(['video_call']);

  const [selected, setSelected] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);

  useEffect(async () => {
    if (selected != null) {
      try {
        const { data } = await anamnesisDetailService(selected.key);

        setSelectedDetail(data);
      } catch (error) {
        toast(
          t('Ops, não foi possível carregar o pré-atendimento histórico...'),
          {
            variant: 'error',
          }
        );
        setSelected(null);
      }
    }
  }, [selected]);

  const renderPartnerAppointmentTitle = () => 'Teleconsulta';

  const renderPartnerAppointmentDate = (appointment) =>
    moment
      .utc(appointment.appointment_date)
      .local()
      .format(FORMAT['pt-datetime']);

  const renderSelectedDetail = () => (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            {renderPartnerAppointmentDate(selected)}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              setSelected(null);
              setSelectedDetail(null);
            }}
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
      <Anamnesis detail={selectedDetail} />
    </>
  );

  const onClickAppointment = (_e, key) => {
    setSelected(key);
  };

  const renderPartnerAppointmentList = (entries) => (
    <List>
      {entries.map((appointment) => (
        <ListItem>
          <ListItemButton
            onClick={(e) => {
              onClickAppointment(e, appointment);
            }}
          >
            <ListItemIcon>
              <Description />
            </ListItemIcon>
            <ListItemText
              primary={renderPartnerAppointmentTitle(appointment)}
              secondary={renderPartnerAppointmentDate(appointment)}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  if (selected != null) {
    if (selectedDetail != null) {
      return renderSelectedDetail();
    }

    return (
      <div className="w-full text-center">
        <CircularProgress />
      </div>
    );
  }

  if (detail?.history) {
    return renderPartnerAppointmentList(detail?.history);
  }

  return <CircularProgress />;
};

export default History;
