import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import useCheckoutForm from '../../hooks/checkout/useCheckoutForm';

const stepsTitles = [
  '1. Informações',
  '2. Endereço',
  '3. Pagamento',
  '4. Confirmação'
];

export default function Header() {
  const { actualStep } = useCheckoutForm();

  return (
    <>
      <div className="hidden w-full items-start md:flex">
        <div
          data-active={actualStep === 1}
          data-filled={actualStep > 1}
          className="group relative flex w-full flex-grow flex-col items-center gap-y-5 after:absolute after:left-1/2 after:top-10 after:h-[1px] after:w-1/2 after:bg-blue-650/20 data-[filled=true]:after:bg-success"
        >
          <p className="font-nunito text-sm font-semibold text-blue-650">
            {stepsTitles[0]}
          </p>
          <div className="absolute top-8 z-10 rounded-full bg-success opacity-0 transition-all duration-300 group-data-[active=true]:h-2 group-data-[filled=true]:h-5 group-data-[active=true]:w-2 group-data-[filled=true]:w-5 group-data-[active=true]:translate-y-1 group-data-[active=true]:opacity-100 group-data-[filled=true]:opacity-100" />
          <CheckIcon className="absolute top-[2.4rem] z-10 text-white opacity-0 transition-all duration-300 group-data-[filled=true]:opacity-100" />
        </div>
        <div
          data-active={actualStep === 2}
          data-filled={actualStep > 2}
          className="group relative flex w-full flex-grow flex-col items-center gap-y-5 before:absolute before:right-1/2 before:top-10 before:h-[1px] before:w-1/2 before:bg-blue-650/20 after:absolute after:left-1/2 after:top-10 after:h-[1px] after:w-1/2 after:bg-blue-650/20 data-[active=true]:before:bg-success data-[filled=true]:before:bg-success data-[filled=true]:after:bg-success"
        >
          <p className="font-nunito text-sm font-semibold text-blue-650">
            {stepsTitles[1]}
          </p>
          <div className="absolute top-8 z-10 rounded-full bg-success opacity-0 transition-all duration-300 group-data-[active=true]:h-2 group-data-[filled=true]:h-5 group-data-[active=true]:w-2 group-data-[filled=true]:w-5 group-data-[active=true]:translate-y-1 group-data-[active=true]:opacity-100 group-data-[filled=true]:opacity-100" />
          <CheckIcon className="absolute top-[2.4rem] z-10 text-white opacity-0 transition-all duration-300 group-data-[filled=true]:opacity-100" />
        </div>
        <div
          data-active={actualStep === 3}
          data-filled={actualStep > 3}
          className="group relative flex w-full flex-grow flex-col items-center gap-y-5 before:absolute before:right-1/2 before:top-10 before:h-[1px] before:w-1/2 before:bg-blue-650/20 after:absolute after:left-1/2 after:top-10 after:h-[1px] after:w-1/2 after:bg-blue-650/20 data-[active=true]:before:bg-success data-[filled=true]:before:bg-success data-[filled=true]:after:bg-success"
        >
          <p className="font-nunito text-sm font-semibold text-blue-650">
            {stepsTitles[2]}
          </p>
          <div className="absolute top-8 z-10 rounded-full bg-success opacity-0 transition-all duration-300 group-data-[active=true]:h-2 group-data-[filled=true]:h-5 group-data-[active=true]:w-2 group-data-[filled=true]:w-5 group-data-[active=true]:translate-y-1 group-data-[active=true]:opacity-100 group-data-[filled=true]:opacity-100" />
          <CheckIcon className="absolute top-[2.4rem] z-10 text-white opacity-0 transition-all duration-300 group-data-[filled=true]:opacity-100" />
        </div>
        <div
          data-active={actualStep === 4}
          data-filled={actualStep > 4}
          className="group relative flex w-full flex-grow flex-col items-center gap-y-5 before:absolute before:right-1/2 before:top-10 before:h-[1px] before:w-1/2 before:bg-blue-650/20 data-[active=true]:before:bg-success"
        >
          <p className="font-nunito text-sm font-semibold text-blue-650">
            {stepsTitles[3]}
          </p>
          <div className="absolute top-8 z-10 rounded-full bg-success opacity-0 transition-all duration-300 group-data-[active=true]:h-2 group-data-[filled=true]:h-5 group-data-[active=true]:w-2 group-data-[filled=true]:w-5 group-data-[active=true]:translate-y-1 group-data-[active=true]:opacity-100 group-data-[filled=true]:opacity-100" />
          <CheckIcon className="absolute top-[2.4rem] z-10 text-white opacity-0 transition-all duration-300 group-data-[filled=true]:opacity-100" />
        </div>
      </div>
      <div className="flex w-full items-start px-5 md:hidden">
        <p>{stepsTitles[actualStep - 1]}</p>
      </div>
    </>
  );
}
