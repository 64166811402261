import React, { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../../../components/Header';
import { Container, FirstGrid, SecondGrid } from './profile.styles';
import ContainerOpacity from '../../../components/ContainerOpacity';
import UserSidebar from '../../../components/Sidebar/UserSidebar';
import TabMenu from '../../../components/TabMenu';
import SecretaryPanel from '../../../components/SecretaryPanel';
import UnreversibleConfirmationModal from '../../../components/UnreversibleConfirmationModal';
import { resendConsultInvitation } from '../../../services/secretary';
import { useToast } from '../../../contexts/ToastContext';
import Modal from '../../../components/Modal';
import SuccessStep from '../../../v2/dialogs/consult/steps/success';
import Tawk from '../../../components/Tawk';
import Chatwoot from '../../../components/Chatwoot';

const TITLE = 'Perfil Médico - Caren';

export default function Secretary() {
  const toast = useToast();
  const copyTextRef = useRef();

  const [selectedKey, setSelectedKey] = useState(null);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [unreversibleType, setUnreversibleType] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [invitationLink, setInvitationLink] = useState(null);

  function handleToggleConfirmationModal() {
    setOpenConfirmation(!openConfirmation);
  }

  const resendInvite = useCallback(async key => {
    if (key.length <= 0) return;

    try {
      const { link } = await resendConsultInvitation(key);

      setInvitationLink(link);

      toast('Convite reenviado com sucesso!', {
        variant: 'success'
      });

      setOpenConfirmation(true);
    } catch (e) {
      toast('Ops, não foi possível reenviar o convite :(', {
        variant: 'error'
      });
    }
  }, []);

  function handleConfirmation(status) {
    if (status && selectedKey) {
      if (unreversibleType === 'resend') {
        resendInvite(selectedKey);
      }
    }

    setOpenUnreversible(false);
  }

  function decideUnreversibleTitle() {
    if (unreversibleType === 'resend') {
      return 'Você tem certeza de que deseja reenviar esse convite?';
    }

    return null;
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar bgColor="var(--caren-greenblue)" />
      <Header />
      <Container>
        <FirstGrid>
          <SecretaryPanel
            onResendClick={key => {
              setSelectedKey(key);
              setUnreversibleType('resend');
              setOpenUnreversible(true);
            }}
          />
        </FirstGrid>
        <SecondGrid>
          <TabMenu isSecretary />
        </SecondGrid>
      </Container>

      <UnreversibleConfirmationModal
        title={decideUnreversibleTitle()}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
      />

      <Modal
        handleOpenModal={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        openModal={openConfirmation}
        modalTitle="Convite Reenviado!"
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside="true"
      >
        <SuccessStep
          invitationLink={invitationLink ?? ''}
          onClose={handleToggleConfirmationModal}
        />
      </Modal>
      <Chatwoot />
      <Tawk />
    </>
  );
}
