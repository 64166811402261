import { cid10Service } from '../services/cid10.service';
import { medicalAdviceService } from '../services/medical-advice.service';
// import { tussService } from '../services/tuss.service';

export const DOCTOR_OFFICE_CODE = 'Telemed';
export const CALENDAR_DEFAULT_DISPLAY_DURATION = '00:30';

export const AUTOCOMPLETE_MINIMUM_LENGTH = 3;
export const AUTOCOMPLETE_DEFAULT_FILTER_OPTIONS = (x) => x;

export const CLINICAL_EVALUATION_FIELDS = [
  {
    label: 'Qual a queixa principal e os sintomas?',
    key: 'symptoms',
    type: 'text',
    default: '',
    required: () => true
  },
  {
    label: 'Qual a História de Doença Atual (HDA)?',
    key: 'history',
    type: 'text',
    default: '',
    required: () => true
  },
  {
    label: 'Qual a Hipótese Diagnóstica (CID-10)?',
    key: 'cid10',
    type: 'autocomplete',
    default: [],
    meta: {
      codeField: 'code',
      previewField: 'code',
      isEmpty: (value) => !value?.length,
      buildOptionLabel: (option) => `${option.code} - ${option.name}`,
      service: cid10Service
    },
    required: () => true
  },
  // {
  //   label: 'Quais exames o paciente realizará?',
  //   key: 'tuss',
  //   type: 'autocomplete',
  //   default: [],
  //   meta: {
  //     codeField: 'code',
  //     buildOptionLabel: (option) => `${option.code} - ${option.name}`,
  //     service: tussService,
  //   },
  //   required: false,
  // },
  {
    label: 'Deseja incluir prescrição, exames, atestado e/ou encaminhamento?',
    key: 'mevo_prescriptions',
    type: 'mevo',
    default: null,
    meta: {
      // disableOnConclude: true,
      isEmpty: (value) =>
        !value?.length || !value?.every((entry) => entry.concluded_at),
      isError: (value) =>
        value?.length && !value?.every((entry) => entry.concluded_at),
      previewField: 'validation_code',
      buildOptionLabel: (option) => option.validation_code
    },
    required: (value) => value?.length
  },
  {
    label: 'Quais são as Orientações/Plano de cuidados?',
    key: 'medical_advice',
    type: 'autocomplete',
    default: [],
    meta: {
      codeField: 'code',
      previewField: 'name',
      initialLoad: true,
      isEmpty: (value) => !value?.length,
      buildOptionLabel: (option) => option.name,
      service: medicalAdviceService
    },
    required: () => true
  },
  {
    label: 'Conduta',
    key: 'conduct',
    type: 'text',
    default: '',
    required: () => false
  },
  {
    label: 'Anotações',
    key: 'notes',
    type: 'text',
    default: '',
    required: () => false
  }
];

// TODO: Extract these to shared lib
export const ALLOWED_MARKDOWN = ['**', 'p', 'ol', 'ul', 'li', 'strong'];

export const CHAT_MARKDOWN = [
  'p',
  'em',
  'strong',
  'hr',
  'blockquote',
  'del',
  'img',
  'table',
  'thead',
  'tbody',
  'tr',
  'td',
  'th',
  'ol',
  'ul',
  'li',
  'code',
  'pre',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'a',
  'div'
];

export const SYMPTOM_NAMES = {
  fever: 'Febre',
  shivers: 'Calafrio',
  'sore-throat': 'Dor de garganta',
  coryza: 'Coriza',
  cough: 'Tosse',
  fatigue: 'Fadiga',
  dyspnea: 'Dispneia',
  nausea: 'Náuseas',
  vomiting: 'Vômito',
  mucositis: 'Mucosite',
  diarrhea: 'Diarreia',
  constipation: 'Obstipação',
  'motor-neuropathy': 'Neuropatia Motora',
  'sensory-neuropathy': 'Neuropatia Sensorial',
  'hand-foot-disease': 'Síndrome Mão Pé',
  'nail-changes': 'Alterações nas Unhas',

  hypertension: 'Hipertensão',
  diabetes: 'Diabetes',
  'lung-disease': 'Doença Pulmonar',
  'heart-disease': 'Doença cardíaca',
  'gastric-disease': 'Doença gástrica',
  thrombosis: 'Trombose',
  cancer: 'Câncer',
  'psychiatric-illness': 'Doença psiquiátrica',
  stroke: 'AVC',
  'kidney-disease': 'Doença renal',
  obesity: 'Obesidade',
  'thyroid-disease': 'Doença da Tireoide',
  physiotherapy: 'Fisioterapia',
  psychotherapy: 'Psicoterapia',
  nutritionist: 'Nutricionista',
  'speech-therapy': 'Fonoaudiologia',
  hemodialysis: 'Hemodiálise',
  'occupational-therapy': 'Terapia ocupacional',
  psychiatry: 'Psiquiatria',
  'cancer-treatment': 'Tratamento para câncer',
  'home-care': 'Assistência ou Internação Domiciliar',
  cardiologist: 'Cardiologista',
  'general practitioner': 'Clínico geral',
  endocrinologist: 'Endocrinologista',
  gastroenterologist: 'Gastroenterologista',
  geriatric: 'Geriatra',
  allergist: 'Alergista',
  obstetrician: 'Ginecologista / Obstetra',
  nephrologist: 'Nefrologista',
  oncologist: 'Oncologista',
  orthopedist: 'Ortopedista',
  pediatrician: 'Pediatra',
  pulmonologist: 'Pneumologista',
  psychiatrist: 'Psiquiatra',
  rheumatologist: 'Reumatologista',
  'tonsil-removal': 'Retirada de amígdalas',
  'prostate-removal': 'Retirada de próstata',
  'uterus-removal': 'Retirada do útero',
  'appendix-removal': 'Retirada de apêndice',
  'bone-fracture': 'Cirurgia devido a fratura óssea',
  'cancer-surgery': 'Cirurgia oncológica',
  'cardiac-surgery': 'Cirurgia cardíaca',
  'vascular-surgery': 'Cirurgia vascular',
  'neurological-surgery': 'Cirurgia neurológica',
  'gallbladder-removal': 'Retirada de vesícula',
  'renal-stone-removal': 'Retirada de cálculo renal',
  'spine-surgery': 'Cirurgia de hérnia de disco (coluna)',
  walking: 'Caminhar',
  eyesight: 'Visão',
  hearing: 'Audição',
  anxiety: 'Ansiedade ou medo',
  'orthopedic-problem': 'Problema Ortopédico',
  cane: 'Bengala ou andador',
  wheelchair: 'Cadeira de rodas',
  'hearing-aid': 'Aparelho auditivo',
  parents: 'Pais',
  siblings: 'Irmãos',
  grandparents: 'Avós',

  'weight-changes': 'Alterações de peso',
  'recent-smoking': 'Fumante recente',
  'away-from-job': 'Afastamento do trabalho',
  'past-indemnity': 'Indenização por acidente ou doença',
  'heart-problem': 'Problema Cardíaco',
  'respiratory-disorder': 'Transtorno Respiratório',
  'endocrine-disorder': 'Distúrbio Endócrino',
  'neurological-disorder': 'Distúrbio Neurológico',
  'gastrointestinal-problem': 'Problema Gastrointestinal',
  'rheumatic-or-orthopedic-disorder': 'Transtorno Reumático ou Ortopédico',
  'blood-related-disease': 'Doença relacionada ao sangue',
  'skin-disease': 'Doença de Pele',
  'visual-disturbance': 'Distúrbio Visual',
  'hearing-loss': 'Perda Auditiva',
  'kidney-or-urinary-tract-disease': 'Doença renal ou das vias urinárias'
};

export const HUMAN_BODY_REGEX = /^@HMN\{([a-z_;]*)\}$/;
export const HUMAN_BODY_PARTS = {
  front_head: 'Cabeça',
  front_left_eye: 'Olho esquerdo',
  front_right_eye: 'Olho direito',
  front_left_ear: 'Orelha esquerda',
  front_right_ear: 'Orelha direita',
  front_nose: 'Nariz',
  front_mouth: 'Boca',
  front_neck: 'Pescoço',
  front_chest: 'Tórax',
  front_belly: 'Região abdominal',
  front_pelvis: 'Quadril',
  front_groin: 'Região pubiana',
  front_left_shoulder: 'Ombro esquerdo',
  front_right_shoulder: 'Ombro direito',
  front_left_upper_arm: 'Bíceps esquerdo',
  front_right_upper_arm: 'Bíceps direito',
  front_left_elbow: 'Frente do cotovelo esquerdo',
  front_right_elbow: 'Frente do cotovelo direito',
  front_left_forearm: 'Antebraço esquerdo',
  front_right_forearm: 'Antebraço direito',
  front_left_wrist: 'Pulso esquerdo',
  front_right_wrist: 'Pulso direito',
  front_left_hand: 'Mão esquerda',
  front_right_hand: 'Mão direita',
  front_left_thigh: 'Coxa esquerda',
  front_right_thigh: 'Coxa direita',
  front_left_knee: 'Joelho esquerdo',
  front_right_knee: 'Joelho direito',
  front_left_shin: 'Canela esquerda',
  front_right_shin: 'Canela direita',
  front_left_ankle: 'Tornozelo esquerdo',
  front_right_ankle: 'Tornozelo direito',
  front_left_foot: 'Pé esquerdo',
  front_right_foot: 'Pé direito',

  back_head: 'Cabeça (posterior)',
  back_neck: 'Pescoço (posterior)',
  back: 'Costas',
  back_waist: 'Lombar',
  back_buttocks: 'Glúteos',
  back_left_shoulder: 'Ombro esquerdo (posterior)',
  back_right_shoulder: 'Ombro direito (posterior)',
  back_left_upper_arm: 'Tríceps esquerdo',
  back_right_upper_arm: 'Tríceps direito',
  back_left_elbow: 'Cotovelo esquerdo',
  back_right_elbow: 'Cotovelo direito',
  back_left_forearm: 'Antebraço esquerdo (posterior)',
  back_right_forearm: 'Antebraço direito (posterior)',
  back_left_wrist: 'Pulso esquerdo (posterior)',
  back_right_wrist: 'Pulso direito (posterior)',
  back_left_hand: 'Mão esquerda',
  back_right_hand: 'Mão direita',
  back_left_thigh: 'Coxa esquerda (posterior)',
  back_right_thigh: 'Coxa direita (posterior)',
  back_left_knee: 'Joelho esquerdo (posterior)',
  back_right_knee: 'Joelho direito (posterior)',
  back_left_calf: 'Panturrilha esquerda',
  back_right_calf: 'Panturrilha direita',
  back_left_ankle: 'Tornozelo esquerdo',
  back_right_ankle: 'Tornozelo direito',
  back_left_heel: 'Calcanhar esquerdo',
  back_right_heel: 'Calcanhar direito',
  back_left_instep: 'Peito do pé esquerdo',
  back_right_instep: 'Peito do pé direito'
};

export const PRIVATE_PARTNER_ICON =
  'https://cdn.media.caren.app/email/caren_icon_yellow.png';
