import React, { useState, useEffect } from 'react';

import styled from 'styled-components/macro';
import getNameInitials from '../../utils/getNameInitials';
import { UserIcon } from '@heroicons/react/24/solid';

const TitleLetters = styled.span`
  color: '#262C2E';
  font-size: ${({ titleSize }) => `${titleSize}px`};
  font-weight: bold;
`;

const TitleContainer = styled.div`
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  aspect-ratio: 1;
`;

function ImageOrName({
  src,
  title = '',
  alt = 'an image',
  titleSize = 18,
  backgroundStyle = {},
  iconSyle = {},
  imageStyle = {},
}) {
  const [isValid, setIsValid] = useState(true);

  function handleImageError() {
    setIsValid(false);
  }

  useEffect(() => {
    setIsValid(!!src);
  }, [src]);

  if (isValid) {
    return (
      <img
        style={{ borderRadius: '100%', ...imageStyle }}
        width={backgroundStyle.width}
        height={backgroundStyle.height}
        onError={handleImageError}
        src={src}
        title={title}
        className="image-or-name-group"
        alt={alt}
      />
    );
  }

  return (
    <TitleContainer
      className="image-or-name-group"
      style={{ ...backgroundStyle }}
    >
      {title && title !== 'Paciente' ? (
        <TitleLetters titleSize={titleSize}>
          {getNameInitials(title || '').toUpperCase()}
        </TitleLetters>
      ) : (
        <UserIcon
          style={{ ...iconSyle }}
          className="absolute text-gray-350 clear"
        />
      )}
    </TitleContainer>
  );
}

export default ImageOrName;
