export default (doctor) => {
  switch (doctor?.gender) {
    case 'female':
      return 'Dra.';

    case 'male':
      return 'Dr.';

    default:
      return '';
  }
};
