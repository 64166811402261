import styled from 'styled-components/macro';
import logo from '../../../assets/images/logo.png';

import nurseLogo from '../../../assets/images/nurse.png';
import chatLogo from '../../../assets/images/chat.svg';

export const Forgot = styled.span`
  cursor: pointer;
`;

export const InputField = styled.input<{ marginBottom?: string }>`
  padding-left: 20px;
  background-color: var(--caren-white);
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin-right: 5px;
  font-size: 15px;

  margin-bottom: ${({ marginBottom }) => marginBottom && '10px'};
`;

export const LogoSidebar = styled.img.attrs({
  src: logo,
  width: '50%'
})`
  margin: 0 auto;
`;

export const Image = styled.img.attrs({
  src: nurseLogo
})`
  opacity: 0.4;
  height: 100px;
  margin: 0px 0 25px;
`;

export const SignupButton = styled.button`
  height: 40px;
  width: 240px;
  background-color: var(--caren-lightgreen);
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 5px;
`;

export const ChatLogo = styled.img.attrs({
  src: chatLogo
})`
  height: 22px;
  color: white;
`;

export const SignupContainer = styled.div`
  padding: 15px;
  background-color: #87ece0;
  margin-top: 10px;
  border-radius: 15px;
`;

export const SignupButtonText = styled.p`
  font-weight: bold;
  margin: 0 5px !important;
`;

export const Copyright = styled.p`
  padding: 5px;
  text-align: center;
  color: var(--caren-white);
`;

export const CaptchaContainer = styled.div`
  margin-bottom: 8px;
`;

export const ErrorMessage = styled.span`
  color: var(--caren-darkred);
  font-weight: bold;
`;
