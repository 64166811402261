import React from 'react';

type TableRootProps = {
  children: React.ReactNode
}

export default function TableRoot({ children }: TableRootProps) {
  return (
    <table className="table-auto w-full rounded-lg overflow-hidden shadow bg-white">
      {children}
    </table>
  );
}
