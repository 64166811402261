import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlanDetail from './components/PlanDetail';
import PlanSummary from './components/PlanSummary';
import PlanTableList from './components/PlanTableList';
import PlansList from './components/PlansList';
import { getPaymentCharges, getPlans } from '../../../../services/plan';
import PaymentForm from './components/PaymentForm';

const PaymentDetails = ({ subscription, reloadSubscription, view }) => {
  const { t } = useTranslation(['doctor_profile']);

  const [menu, setMenu] = useState(view || 'details');
  const [payments, setPayments] = useState([]);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPlans, setLoadingPlans] = useState(true);

  const getPayments = async () => {
    setLoading(true);
    try {
      const res = await getPaymentCharges(100);
      setPayments(res.data);
      setLoading(false);
    } catch (error) {
      toast(t('Não foi possível buscar as informações de pagamentos.'), {
        variant: 'error'
      });
    }
  };

  const getPlansList = async (filter = '') => {
    setLoadingPlans(true);
    try {
      const res = await getPlans(100);
      setPlans(res.filter(item => item.name.toLowerCase().includes(filter)));
      setLoadingPlans(false);
    } catch (error) {
      toast(t('Não foi possível buscar os planos.'), {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    getPlansList();
  }, []);

  useEffect(() => {
    if (menu === 'payments') {
      getPayments();
    }
  }, [menu]);

  useEffect(() => {
    if (subscription?.status === 'not_found') {
      setMenu('payments');
    }
  }, [subscription]);

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 lg:col-span-1">
          <PlanDetail
            subscription={
              // TODO make subscription more easy to understand, this type work with more than 3 different response
              subscription?.status === 'not_found' ? null : subscription
            }
            goToMenu={menu => {
              setMenu(menu);
            }}
          />
        </div>
        {menu === 'details' && (
          <div className="col-span-4 lg:col-span-3">
            {subscription?.key && <PlanSummary subscription={subscription} />}
          </div>
        )}
        {menu === 'payments' && (
          <div className="col-span-4 lg:col-span-3">
            <div className="rounded-md border border-gray-200 bg-white px-7 py-4">
              <h3 className="mb-2 text-lg font-bold text-gray-450">
                Histórico de Pagamentos
              </h3>
              <PlanTableList title={'Data'} data={payments} loading={loading} />
            </div>
          </div>
        )}
        {menu === 'plans' && (
          <div className="col-span-4 lg:col-span-3">
            <div className="rounded-md border border-gray-200 bg-white px-7 py-4">
              <h3 className="invisible mb-2 text-lg font-bold text-gray-450">
                Escolha o melhor plano para você
              </h3>
              <PlansList
                plans={plans}
                loading={loadingPlans}
                currentPlan={subscription?.plan}
              />
            </div>
          </div>
        )}
        {menu === 'paymentCard' && (
          <div className="col-span-4 lg:col-span-3">
            <div className="rounded-md border border-gray-200 bg-white px-7 py-4">
              <h3 className="mb-2 text-lg font-bold text-gray-450">
                Atualizar meio de pagamento
              </h3>
              {subscription?.key && (
                <PaymentForm
                  currentSubscription={subscription.key}
                  reloadSubscription={reloadSubscription}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentDetails;
