import styled from 'styled-components/macro';

import cardIcon from '../../assets/images/iconfinder-icon.svg';
import transferIcon from '../../assets/images/iconfinder-icon2.svg';
import shieldIcon from '../../assets/images/shield.svg';
import userPadlockIcon from '../../assets/images/user_padlock.svg';

export const ConfirmButton = styled.button`
  background-color: var(--caren-green);
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px 15px;
  color: var(--caren-white);
  font-weight: bold;
  margin-top: 20px;
  width: 100%;
`;

export const Container = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  width: 100%;
  position: fixed;
  bottom: 0;
`;

export const Content = styled.div<{ open: boolean }>`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--caren-white);
  transform: ${({ open }) => (open ? 'translateY(0%)' : 'translateY(100%)')};
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SummaryHeader = styled.p`
  color: var(--caren-lightgrey);
  font-size: 14px;
  font-weight: normal;
`;

export const SummaryHighlight = styled.span`
  font-weight: bold;
  color: var(--caren-green);
`;

export const SummaryBilling = styled.p`
  color: var(--caren-green);
  font-size: 22px;
  font-weight: bold;
  margin-right: 8px;
`;

export const SummaryBillingPromo = styled.span`
  color: var(--caren-lightgrey);
  font-size: 14px;
  font-weight: normal;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-evenly;

    & div {
      width: 100%;
      margin-top: 5px;
    }
  }
`;

export const CreditButton = styled.div`
  padding: 10px 20px;
  height: 50px;
  background-color: var(--caren-green);
  font-weight: bold;
  color: var(--caren-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  margin: 0 10px;
  p {
    margin-left: 15px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ActionButton = styled.div<{ disabled: boolean }>`
  padding: 10px 20px;
  height: 50px;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--caren-disabled-text)' : 'var(--caren-green)'};
  font-weight: bold;
  color: var(--caren-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  margin: 0 10px;
  p {
    margin-left: 5px;
  }

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const CardIcon = styled.img.attrs({
  src: cardIcon
})``;

export const TransferIcon = styled.img.attrs({
  src: transferIcon
})`
  width: 26px;
`;

export const ShieldIcon = styled.img.attrs({
  src: shieldIcon
})``;

export const UserPadlockIcon = styled.img.attrs({
  src: userPadlockIcon
})`
  width: 50%;
  max-width: 100px;
  margin: 10px auto;
  display: block;
`;

export const PhoneInfoPaybar = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};

  div {
    margin-right: 20px;
  }
  span {
    margin-right: 5px;
  }
`;

export const PhoneInfo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  div {
    padding-left: 20px;
    padding-right: 20px;
  }
  span {
    margin-left: 10px;
  }
`;
