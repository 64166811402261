import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { Banner, PrimaryText, SecondText, BannerImage } from '../home.styles';
import ContainerOpacity from '../../../components/ContainerOpacity';

const TITLE = 'Home - GloballCare';

export default function GloballCare() {
  const { t, i18n } = useTranslation(['home']);

  useEffect(() => {
    i18n.changeLanguage('pt-AO');
  });

  const bannerIndex = useMemo(() => Math.floor(Math.random() * 3), []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta property="og:site_name" content={TITLE} />
        <meta
          name="og:image"
          content="https://cdn.media.caren.app/partner/icon_globallcare.png"
        />
        <meta name="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta name="twitter:title" content={TITLE} />
        <meta name="twitter:description" content="GloballCare" />
        <meta
          name="twitter:image"
          content="https://cdn.media.caren.app/partner/icon_globallcare.png"
        />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <ContainerOpacity />
      <BannerImage
        index={bannerIndex}
        beginColor="#e6903778"
        endColor="#d75e3efa"
      >
        <Header leftAction={null} rightAction={null} />
        <Banner>
          <PrimaryText withTopMargin>{t('Consultas Online')}</PrimaryText>
          <br />
          <SecondText>{t('Escolha seu médico')}</SecondText>
        </Banner>
        <Footer enableLanguagePicker={false} />
      </BannerImage>
    </>
  );
}
