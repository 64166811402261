import useSWR from 'swr';
import api from '../../../services/api';
import { type Specialties } from '../../entities/doctor';

export function useSpecialties() {
  const { data, error, isLoading } = useSWR<Specialties[]>(
    '/doctors/specialty/list',
    async () =>
      await api
        .get('/doctors/specialty/list')
        .then(res => res.data.specialties),
    { refreshInterval: 60000 }
  );

  return {
    data,
    isLoading,
    isError: error
  };
}
