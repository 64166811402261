import { useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import { makeStyles, TextField } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { useToast } from '../../../../contexts/ToastContext';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../../../../components/Loading';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { patientResumeSignup } from '../../../../services/patient';
import onlyDigits from '../../../../utils/onlyDigits';
import Stepper from '../stepper';
import usePatientSignupForm from '../../../hooks/patient/usePatientSignupForm';

const useStyles = makeStyles(_theme => ({
  passwordFieldMargin: {
    marginRight: '-40px'
  },

  legend: {
    width: '51%',
    padding: '18.5px 14px',
    marginBottom: '0px'
  },

  v2TextFieldBorder: {
    '&.MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#FDBF71' // yellow-650
    }
  }
}));

type FiscalCodeStepProps = {
  step: number;
};

const FiscalCodeFormSchema = z.object({
  login: z
    .string({ required_error: 'Digite um CPF' })
    .nonempty('Digite um CPF')
    .regex(/\d{3}\.\d{3}\.\d{3}-\d{2}/, 'Digite um CPF válido')
});

export type FiscalCodeFormData = z.infer<typeof FiscalCodeFormSchema>;

export default function FiscalCode({ step }: FiscalCodeStepProps) {
  const { actualStep, setStepData, forwardStep } = usePatientSignupForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors }
  } = useForm<FiscalCodeFormData>({
    resolver: zodResolver(FiscalCodeFormSchema),
    mode: 'onBlur'
  });

  const { t } = useTranslation(['doctor_signup']);

  const classes = useStyles();

  const startOrResumeSignup = useCallback(
    async (formData: FiscalCodeFormData) => {
      try {
        setLoading(true);
        const { data } = await patientResumeSignup(onlyDigits(formData.login));

        if (data.result === 'not_found') {
          setStepData(step, formData);
          forwardStep();
        } else if (data.result === 'sent' || data.result === 'resent') {
          setVerifyEmail(true);
          toast(
            'Enviamos um e-mail de confirmação para o endereço utilizado em sua última teleconsulta.\nCaso não tenha recebido entre em contato com o nosso suporte.',
            {
              variant: 'info'
            }
          );
        }
      } catch (error: any) {
        setLoading(false);
        const errorBody = error?.response?.data?.error;

        if (errorBody?.message === 'Forbidden') {
          setError('login', {
            type: 'custom',
            message:
              'Este CPF não pode ser utilizado.\nCaso já possua uma conta, clique em VOLTAR para fazer o login.'
          });
        } else {
          setError('login', {
            type: 'custom',
            message:
              'No momento não foi possível prosseguir com o cadastro.\nTente novamente mais tarde.'
          });
        }
      }
    },
    [setLoading]
  );

  if (actualStep !== step) return null;

  return (
    <form
      className="mx-auto mt-8 flex w-[512px] max-w-[95%] flex-col items-center justify-between gap-y-4 rounded-xl bg-white px-12 py-8"
      onSubmit={handleSubmit(startOrResumeSignup)}
    >
      <Controller
        control={control}
        defaultValue=""
        render={fields => (
          <InputMask {...fields.field} mask="999.999.999-99" type="text">
            <TextField
              placeholder="CPF"
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              InputProps={{
                classes: {
                  focused: classes.v2TextFieldBorder
                }
              }}
            />
          </InputMask>
        )}
        name="login"
      />
      <ErrorMessage
        errors={errors}
        name="login"
        render={({ message }: { message: string }) => (
          <p className="whitespace-pre text-xs text-error">{message}</p>
        )}
      />
      <div className="flex w-full justify-center">
        <button
          type="submit"
          disabled={verifyEmail}
          className="mt-6 flex w-full items-center justify-center rounded-xl bg-gray-950 p-4 font-nunito text-xl text-white transition ease-in-out hover:scale-105 disabled:opacity-50"
        >
          {verifyEmail ? (
            t('Verifique seu e-mail')
          ) : loading ? (
            <LoadingComponent />
          ) : (
            t('Seguir')
          )}
        </button>
      </div>
      <Stepper />
    </form>
  );
}
