import React, { useState } from 'react';
import { Delete, Send } from '@mui/icons-material';
import { IconButton } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useToast } from '../../../contexts/ToastContext';
import UnreversibleConfirmationModal from '../../../components/UnreversibleConfirmationModal';
import {
  CustomTable,
  TableHeaderColumn,
  TableColumn,
  TableRow,
  PatientBox,
  PatientName,
  TableColumnText,
} from './table.styles';
import { FORMAT } from '../../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../../utils/moment/momentHelpers';
import {
  doctorDeleteInvitationService,
  doctorResendInvitationService,
} from '../../../services/doctors.service';

function Schedules({ doctorInvitations, setDoctorInvitations }) {
  const toast = useToast();
  const { t } = useTranslation(['doctor_schedules']);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [unreversibleType, setUnreversibleType] = useState('');
  const [selectedInvitation, setSelectedInvitation] = useState({});

  function handleDeletionClick(schedule) {
    setUnreversibleType('delete');
    setSelectedInvitation(schedule);
    setOpenUnreversible(true);
  }

  function handleResendClick(schedule) {
    setUnreversibleType('resend');
    setSelectedInvitation(schedule);
    setOpenUnreversible(true);
  }

  function formatDate(date) {
    if (date) {
      return momentUtcLocal(date).format(FORMAT['pt-datetime']);
    }

    return t('-');
  }

  async function handleDeleteSchedule() {
    try {
      const deletedInvitation = await doctorDeleteInvitationService(
        selectedInvitation.key
      );
      const invitations = [...doctorInvitations];

      const index = invitations.findIndex(
        (invitation) => invitation.key === deletedInvitation.key
      );
      invitations.splice(index, 1);
      setDoctorInvitations(invitations);
      toast(t('Agendamento deletado com sucesso.'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Ops, houve um erro na exclusão...'), {
        variant: 'error',
      });
    }
  }

  async function handleResendInvitation() {
    try {
      await doctorResendInvitationService(selectedInvitation.key);

      toast(t('Convite reenviado com sucesso.'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Ops, houve um erro no reenvio do convite...'), {
        variant: 'error',
      });
    }
  }

  function handleConfirmation(status) {
    if (status) {
      doUnreversibleAction();
    }
    setOpenUnreversible(false);
  }

  function doUnreversibleAction() {
    if (unreversibleType === 'delete') {
      handleDeleteSchedule();
    }
    if (unreversibleType === 'resend') {
      handleResendInvitation();
    }
  }

  function decideUnreversibleTitle() {
    if (unreversibleType === 'delete') {
      return t('Você tem certeza de que deseja cancelar esse convite?');
    }
    if (unreversibleType === 'resend') {
      return t('Você tem certeza de que deseja reenviar esse convite?');
    }

    return null;
  }

  function maybeDisableInvitationButtons(invitation) {
    const scheduledForIsAfter = moment
      .utc(invitation.scheduled_for)
      .local()
      .isAfter(moment().local());

    if (
      scheduledForIsAfter &&
      invitation?.rejected_at == null &&
      invitation?.accepted_at == null
    ) {
      return false;
    }

    return true;
  }

  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            <TableHeaderColumn>{t('E-mail')}</TableHeaderColumn>
            <TableHeaderColumn>{t('Agendamento')}</TableHeaderColumn>
            <TableHeaderColumn>{t('Data de envio')}</TableHeaderColumn>
            <TableHeaderColumn>{t('Data de confirmação')}</TableHeaderColumn>
            <TableHeaderColumn>{t('Data de recusa')}</TableHeaderColumn>
            <TableHeaderColumn align="center">{t('Ações')}</TableHeaderColumn>
          </tr>
        </thead>
        <tbody>
          {doctorInvitations?.map((invitation) => (
            <TableRow key={invitation.key}>
              <TableColumn>
                <PatientBox>
                  <PatientName>{invitation?.email}</PatientName>
                </PatientBox>
              </TableColumn>
              <TableColumn>
                <TableColumnText>
                  {formatDate(invitation?.scheduled_for)}
                </TableColumnText>
              </TableColumn>
              <TableColumn>
                <TableColumnText>
                  {formatDate(invitation?.inserted_at)}
                </TableColumnText>
              </TableColumn>
              <TableColumn>
                <TableColumnText>
                  {formatDate(invitation?.accepted_at)}
                </TableColumnText>
              </TableColumn>
              <TableColumn>
                <TableColumnText>
                  {formatDate(invitation?.rejected_at)}
                </TableColumnText>
              </TableColumn>
              <TableColumn align="center">
                <Tooltip title={t('Deletar')}>
                  <IconButton
                    disabled={maybeDisableInvitationButtons(invitation)}
                    onClick={() => handleDeletionClick(invitation)}
                  >
                    <Delete size={20} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Reenviar')}>
                  <IconButton
                    disabled={maybeDisableInvitationButtons(invitation)}
                    onClick={() => handleResendClick(invitation)}
                  >
                    <Send size={20} />
                  </IconButton>
                </Tooltip>
              </TableColumn>
            </TableRow>
          ))}
        </tbody>
      </CustomTable>
      <UnreversibleConfirmationModal
        title={decideUnreversibleTitle()}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
}
export default Schedules;
