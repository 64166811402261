import { type ConsultInvite } from '../v2/entities/consult';
import type { Doctor } from '../v2/entities/doctor';
import { type Invite } from '../v2/entities/invite';
import { type SearchTeleconsultation } from '../v2/entities/search';
import { type SecretaryProfile } from '../v2/entities/secretary';
import { type Statistics } from '../v2/entities/statistics';
import api from './api';

export const resendConsultInvitation = async (key: string, doctor?: Doctor) => {
  const params: {
    employee_key?: string;
  } = {};

  if (doctor) params.employee_key = doctor.key;

  const { data } = await api.put(
    `teleconsultations/invitations/${key}/resend`,
    undefined,
    { params }
  );

  return data;
};

export const getDoctors = async () => {
  const { data } = await api.get('/secretaries/employees');

  return data.data;
};

export const getDoctorsInvitations = async () => {
  const { data } = await api.get<{ data: Invite[] }>(
    '/secretaries/invitations'
  );

  return data.data;
};

export const getDoctorInformation = async (key: string) => {
  const { data } = await api.get(`/doctors/${key}`);

  return data.data;
};

export const getPatientEmail = async (key: string, cpf: string) => {
  const { data } = await api.get(
    `/teleconsultations/invitations/fiscal-code/${cpf}/email?employee_key=${key}`
  );

  return data;
};

export const getDoctorSchedule = async (
  employee: string,
  from: string,
  to: string
) => {
  const { data } = await api.get(
    `/employees/schedule?from=${from}&to=${to}&limit=50&employee_key=${employee}`
  );

  return data.data;
};

export const createInvitation = async (params: ConsultInvite) => {
  const { data } = await api.post('/teleconsultations/invitations', params);

  return data;
};

export const getEmployeTeleconsultations = async (
  params: SearchTeleconsultation
) => {
  const { data } = await api.get('/employees/teleconsultations', {
    params: { ...params }
  });

  return data;
};

export const deleteConsultInvitation = async (
  key: string,
  employee: string
) => {
  const { data } = await api.delete(
    `/teleconsultations/invitations/${key}?employee_key=${employee}`
  );

  return data;
};

export const cancelConsultation = async (key: string, employee: string) => {
  const { data } = await api.post(
    `/teleconsultations/${key}/cancel?employee_key=${employee}`
  );

  return data;
};

export const updateProfile = async (params: SecretaryProfile) => {
  const { data } = await api.put('/secretaries', { secretary: params });

  return data;
};

export const getDoctorTelemedStats = async (key: string) => {
  const { data } = await api.get<{ data: Statistics }>(
    `/employees/telemed-stats?employee_key=${key}`
  );

  return data.data;
};
