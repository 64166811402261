import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { ConsultDialogContext } from './context';

const bar = keyframes`
  0% {
    transform-origin: 0% 50%;
    transform: scaleX(0);
  }
  50% {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }
  50.1% {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scaleX(0);
  }
`;

const Steps = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  @media (max-width: 636px) {
    overflow-x: hidden;
    justify-content: start;
  }

  > .step {
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;

    @media (max-width: 636px) {
      flex-shrink: 0;
      width: 100%;
      display: none;
    }

    .bar {
      position: relative;
      display: flex;
      width: 100%;
      height: 4px;
      background-color: var(--caren-input);
      opacity: 0.5;
      border-radius: 4px;
      margin-top: 2px;
      margin-bottom: 4px;
    }

    .value {
      width: 100%;
      height: 30px;
    }
  }

  > .step.active {
    @media (max-width: 636px) {
      display: flex;
    }
    .bar {
      opacity: 1;
      ::after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: var(--caren-green);
        animation-name: ${bar};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
        border-radius: 4px;
      }
    }
  }

  > .step.completed {
    .bar {
      background-color: var(--caren-green);
      opacity: 1;
    }
  }
`;

export default function Header() {
  const dialogContext = useContext(ConsultDialogContext);

  const getStepClass = (step: number, actualStep: number) => {
    if (step === actualStep) {
      return 'active';
    }
    if (step < actualStep) return 'completed';
    return '';
  };

  if (dialogContext === null) return null;

  return (
    <Steps>
      {React.Children.toArray(
        Object.entries(dialogContext.stepsHeader).map(step => (
          <div
            className={`step ${getStepClass(
              Number(step[0]),
              dialogContext.actualStep
            )}`}
          >
            <p>{step[1]}</p>
            <div className="bar" />
            <div className="value">
              {dialogContext.steps.get(Number(step[0]))?.header}
            </div>
          </div>
        ))
      )}
    </Steps>
  );
}
