import styled from 'styled-components/macro';

export const SliderContainer = styled.div<{ isActived: boolean }>`
  margin-top: 10px;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    justify-content: ${({ isActived }) =>
      isActived ? 'space-between' : 'flex-start'};
    width: 100%;
    flex-wrap: wrap;
  }

  .MuiFormControlLabel-root {
    width: 100px !important;
  }
`;

export const SliderText = styled.p`
  color: var(--caren-lightgrey);
  font-weight: bold;
`;
