export default (state, action) => {
  switch (action.type) {
    case 'SET_UNREAD_COUNT':
      return {
        ...state,
        unread: action.count,
        lastRefresh: Date.now()
      };

    default:
      return state;
  }
};
