import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import { BsFileEarmarkArrowUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import WhiteCard from '../../components/WhiteCard';
import Header from '../../components/Header';
// import inviteFriendLogo from '../../assets/images/invite-friend.png';
import getPercentage from '../../utils/getPercentage';
// import NoConsults from '../../components/NoConsults';
import {
  Container,
  FirstGrid,
  SecondGrid,
  SecondHeader,
  BackButtonContainer,
  Subgrid,
  CardDefaultContent,
  CardNumber,
  CardText,
  CardWomanMan,
  WomanData,
  WomenIcon,
  Porcentage,
  Border,
  ManData,
  ManIcon,
  CardUserData
  // InviteFooter,
} from './statistics.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import { BackButton } from '../../components/BackButton';
import UserSidebar from '../../components/Sidebar/UserSidebar';
// import { changeUserPictureService } from '../../services/user.service';
import { telemedStatsService } from '../../services/telemed-stats.service';
// import useCountdownClock from '../../hooks/countdownBlueClock.hook';
// import DoctorBadgeCounter from '../../components/DoctorBadgeCounter';
import DoctorPanel from '../../components/DoctorPanel';
import { EmergencyPatientGlobalState } from '../../store';
import HealthInsuranceSelectorDialog from '../../components/HealthInsuranceSelectorDialog';
import { useAuth } from '../../hooks/AuthContext';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';

export default function DoctorClinic() {
  const { credentials } = useAuth();

  const { t } = useTranslation(['doctor_clinic']);

  const toast = useToast();
  const { setAvailable, setInsurancePartner } = useContext(
    EmergencyPatientGlobalState
  );
  const [telemedStats, setTelemedStats] = useState({});
  const [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);

  const TITLE = 'Consultório Virtual - Caren';

  useEffect(() => {
    async function getTelemedStatsService() {
      try {
        const { data: telemedStatsData } = await telemedStatsService({});
        setTelemedStats(telemedStatsData);
      } catch (error) {
        toast(t('Ops, não foi possível carregar as informações...'), {
          variant: 'error'
        });
      }
    }

    getTelemedStatsService();
  }, []);

  function toggleAvailabilityModal() {
    setAvailabilityModalOpen(!availabilityModalOpen);
  }

  function handleBecomeAvailable(insurancePartner) {
    setInsurancePartner(insurancePartner.code);
    setAvailable(true);
    setAvailabilityModalOpen(false);
    toast(
      `Você habilitou disponibilidade para atendimentos ${insurancePartner.name}.`
    );
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar bgColor="var(--caren-greenblue)" />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel />
        </FirstGrid>

        <SecondGrid>
          <SecondHeader>
            <div>
              <BackButtonContainer>
                <BackButton path="/consultorio" title="Voltar" />
              </BackButtonContainer>

              <p>{t('Minhas estatísticas')}</p>
            </div>
          </SecondHeader>

          <Subgrid>
            <div>
              <div
                data-tour="Aqui você acessa a lista dos pacientes que já realizou consulta."
                data-tour-index="7"
              >
                <Link
                  to={{
                    pathname: '/agenda',
                    state: {
                      tab: 'concluded'
                    }
                  }}
                >
                  <WhiteCard title={t('Meus pacientes')}>
                    <CardDefaultContent>
                      <CardNumber>
                        {telemedStats.patients_count?.total_patients
                          ? telemedStats.patients_count?.total_patients
                          : '0'}
                      </CardNumber>
                      <CardWomanMan>
                        <WomanData>
                          <WomenIcon />
                          <p>{t('Mulheres')}</p>
                          <Porcentage>
                            {getPercentage(
                              telemedStats.patients_count?.female_patients,
                              telemedStats.patients_count?.total_patients
                            )}
                            %
                          </Porcentage>
                        </WomanData>
                        <Border />
                        <ManData>
                          <ManIcon />
                          <p>{t('Homens')}</p>
                          <Porcentage>
                            {getPercentage(
                              telemedStats.patients_count?.male_patients,
                              telemedStats.patients_count?.total_patients
                            )}
                            %
                          </Porcentage>
                        </ManData>
                      </CardWomanMan>
                    </CardDefaultContent>
                  </WhiteCard>
                </Link>
              </div>

              <div
                data-tour="Em meus dados, você poderá alterar seus dados pessoais, profisionais e redefinir a sua senha."
                data-tour-index="6"
              >
                <Link
                  to={{
                    pathname: '/perfil'
                  }}
                >
                  <WhiteCard title={t('Meus dados')}>
                    <CardDefaultContent>
                      <CardNumber>
                        {telemedStats.profile_completeness
                          ? telemedStats.profile_completeness
                          : '0'}
                        <span>%</span>
                      </CardNumber>
                      <CardUserData>
                        <p>{t('COMPLETO')}</p>
                        <Link to="/perfil">({t('Editar dados pessoais')})</Link>
                      </CardUserData>
                    </CardDefaultContent>
                  </WhiteCard>
                </Link>
              </div>
            </div>

            <div>
              <div
                data-tour="Clicando aqui, você visualizará os detalhes de todas as suas consultas criadas."
                data-tour-index="8"
              >
                <Link
                  to={{
                    pathname: '/agenda',
                    state: {
                      tab: 'pending'
                    }
                  }}
                >
                  <WhiteCard title={t('Consultas criadas e confirmadas')}>
                    <CardDefaultContent>
                      <CardText>
                        {telemedStats.teleconsultations &&
                          `${telemedStats.teleconsultations.teleconsultation_invitations} consultas`}
                      </CardText>
                    </CardDefaultContent>
                  </WhiteCard>
                </Link>
              </div>

              <div
                data-tour="E aqui verá detalhes das consultas que já realizou."
                data-tour-index="9"
              >
                <Link
                  to={{
                    pathname: '/agenda',
                    state: {
                      tab: 'concluded'
                    }
                  }}
                >
                  <WhiteCard title={t('Consultas concluídas')}>
                    <CardDefaultContent>
                      <CardText>
                        {telemedStats.teleconsultations &&
                          `${telemedStats.teleconsultations.concluded} consultas`}
                      </CardText>
                    </CardDefaultContent>
                  </WhiteCard>
                </Link>
              </div>
            </div>
          </Subgrid>
        </SecondGrid>
      </Container>
      <HealthInsuranceSelectorDialog
        handleToggleModal={toggleAvailabilityModal}
        openModal={availabilityModalOpen}
        onClickFunc={handleBecomeAvailable}
        insurancePartners={credentials?.session.insurance_partners}
      />
      <Tawk />
      <Chatwoot />
    </>
  );
}
