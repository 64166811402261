import { Helmet } from 'react-helmet-async';
import { useCallback, useState } from 'react';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import ContainerOpacity from '../../components/ContainerOpacity';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
import { resendConsultInvitation } from '../../services/secretary';
import { useToast } from '../../contexts/ToastContext';
import { SecretaryHome, ReturnButton, StatisticsBox } from './styles';
import SecretaryPanel from '../../components/SecretaryPanel';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
import { type Doctor } from '../../v2/entities/doctor';
import DoctorTitleCard from '../../components/SecretaryStatistics/DoctorTitleCard/DoctorTitleCard';
import DoctorStatisticsGrid from '../../components/SecretaryStatistics/DoctorStatisticsGrid/DoctorStatisticsGrid';
import SuccessStep from '../../v2/dialogs/consult/steps/success';
import SelectDoctors from '../../v2/components/secretary/SelectDoctors';

export default function SecretaryStatistics() {
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [unreversibleType, setUnreversibleType] = useState<
    null | 'delete' | 'resend' | 'cancel'
  >(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [invitationLink, setInvitationLink] = useState<string | null>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [tab, setTab] = useState(0);
  const toast = useToast();
  const handleToggleConfirmationModal = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const decideUnreversibleTitle = useCallback(() => {
    if (unreversibleType === 'resend') {
      return 'Você tem certeza de que deseja reenviar esse convite?';
    }

    return null;
  }, [unreversibleType]);

  const resendInvite = useCallback(
    async (key: string) => {
      if (key.length <= 0) return;

      try {
        const { link } = await resendConsultInvitation(key);

        setInvitationLink(link);

        toast('Convite reenviado com sucesso!', {
          variant: 'success'
        });

        setOpenConfirmation(true);
      } catch (e) {
        toast('Ops, não foi possível reenviar o convite :(', {
          variant: 'error'
        });
      }
    },
    [toast]
  );

  const handleConfirmation = useCallback(
    (status: boolean) => {
      if (status && selectedKey) {
        if (unreversibleType === 'resend') {
          resendInvite(selectedKey);
        }
      }

      setUnreversibleType(null);
      setSelectedKey(null);
      setOpenUnreversible(false);
    },
    [resendInvite, selectedKey, unreversibleType]
  );

  return (
    <>
      <Helmet title="Estatísticas - Caren"></Helmet>
      <ContainerOpacity />
      <Header />
      <UserSidebar />
      <SecretaryHome>
        <SecretaryPanel
          onResendClick={key => {
            setSelectedKey(key);
            setUnreversibleType('resend');
            setOpenUnreversible(true);
          }}
        />
        {tab === 0 && (
          <SelectDoctors
            onSelectDoctor={doctor => {
              setSelectedDoctor(doctor);
              setTab(1);
            }}
          />
        )}
        {tab === 1 && selectedDoctor && (
          <StatisticsBox>
            <ReturnButton
              onClick={() => {
                setTab(0);
              }}
            >
              <IoIosArrowRoundBack className="icon-container" /> Voltar
            </ReturnButton>
            <DoctorTitleCard doctor={selectedDoctor} />
            <DoctorStatisticsGrid doctor={selectedDoctor} />
          </StatisticsBox>
        )}
      </SecretaryHome>

      <UnreversibleConfirmationModal
        title={decideUnreversibleTitle()}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
        cancelButtonText={null}
        confirmButtonText={null}
      />

      <Modal
        handleOpenModal={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        openModal={openConfirmation}
        modalTitle="Convite Enviado!"
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside
      >
        <SuccessStep
          invitationLink={invitationLink ?? ''}
          onClose={handleToggleConfirmationModal}
        />
      </Modal>

      <Tawk />
      <Chatwoot />
    </>
  );
}
