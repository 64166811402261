import React from 'react';
import Publication from '../Publication';
import usePublications from '../../../hooks/video/usePublications';

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  participant,
  videoPriority,
  isLocalParticipant,
}) {
  const publications = usePublications(participant);

  let filteredPublications = publications.filter(
    (p) => !p.trackName.includes('screen')
  );

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoPriority={videoPriority}
        />
      ))}
    </>
  );
}
