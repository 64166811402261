import Home from '../pages/Home';
import HomeGloballCare from '../pages/Home/GloballCare';
import Doctors from '../pages/Doctors';
import DoctorDetails from '../pages/DoctorDetails';
import DoctorRecord from '../pages/DoctorRecord';
import DoctorClinic from '../pages/DoctorClinic';
import DoctorVouchers from '../pages/DoctorVouchers';
import DoctorScheduling from '../pages/DoctorScheduling';
import Consults from '../pages/Consults';
import CurrentConsult from '../pages/CurrentConsult';
import CurrentConsultVerification from '../pages/CurrentConsult/ConsultVerification';
import CurrentConsultConfirmed from '../pages/CurrentConsult/ConsultConfirmed';
import ConsultWaitRoom from '../pages/ConsultWaitRoom';
import PreAppointment from '../pages/PreAppointment';
import PreAppointmentDetail from '../pages/PreAppointmentDetail';
import EmergencyWaitingQueue from '../pages/ConsultWaitRoom/EmergencyWaitingQueue';
import Notifications from '../pages/Notifications';
import AccountRecovery from '../pages/AccountRecovery';
import PostAppointment from '../pages/PostAppointment';
import Patient from '../pages/Patient';
import DoctorReceipts from '../pages/DoctorReceipts';
import Profile from '../pages/Profile';
import V2Login from '../v2/pages/Login';
import V2LoginPatient from '../v2/pages/Login/Patient';
import V2LoginProfessional from '../v2/pages/Login/Professional';
import V2PatientSignup from '../v2/pages/PatientSignup';
import V2DoctorSignup from '../v2/pages/DoctorSignup';
import Signup from '../pages/Signup';
import Schedule from '../pages/Schedule';
import Statistics from '../pages/Statistics';
import HealthInsurance from '../pages/HealthInsurance';
import InvitationConfirmationRedirect from '../pages/InvitationConfirmationRedirect';
import TeleconsultationVoucher from '../pages/TeleconsultationVoucher';
import TeamSignup from '../pages/TeamSignup';
import VideoCall from '../pages/VideoCall';
import PatientSignup from '../pages/PatientSignup';
import SecretaryClinic from '../pages/SecretaryClinic';
import SecretaryStatistics from '../pages/SecretaryStatistics';
import SecretaryInvites from '../pages/SecretaryInvites';
import Checkout from '../pages/Checkout';
import Team from '../pages/Team';
import Patients from '../pages/Patients';
import CalendarPage from '../pages/Calendar';
import ConfirmEmail from '../pages/ConfirmeEmail';
import VidaasConfirmationRedirect from '../pages/VidaasConfirmationRedirect';

import {
  ROLE_GUEST,
  ROLE_PATIENT,
  ROLE_PROFESSIONAL,
  ROLE_SECRETARY
} from '../routes/constants';

export const ROUTES = [
  {
    userRole: [ROLE_GUEST, ROLE_PROFESSIONAL],
    exact: true,
    path: '/confirmar-email',
    render: ConfirmEmail
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/',
    render: Home
  },
  {
    userRole: [ROLE_GUEST, ROLE_PATIENT],
    exact: true,
    path: '/home/globallcare',
    render: HomeGloballCare
  },
  {
    userRole: [ROLE_GUEST, ROLE_PATIENT],
    exact: true,
    path: '/home/fapes',
    render: Home
  },
  {
    userRole: [ROLE_GUEST, ROLE_PATIENT],
    exact: true,
    path: '/login',
    render: V2Login
  },
  {
    userRole: [ROLE_GUEST, ROLE_PATIENT],
    exact: true,
    path: '/login/paciente',
    render: V2LoginPatient
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/cadastro/paciente',
    render: V2PatientSignup
  },
  {
    userRole: [ROLE_GUEST, ROLE_PATIENT],
    exact: true,
    path: '/login/profissional/:type',
    render: V2LoginProfessional
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/cadastro',
    render: Signup
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/cadastro/profissional',
    render: V2DoctorSignup
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/cadastro-equipe',
    render: TeamSignup
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/cadastro-equipe/:invite_token',
    render: TeamSignup
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/recuperar-acesso',
    render: AccountRecovery
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    path: '/video-call/:consult_id',
    render: VideoCall
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/medicos',
    render: Doctors
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/medicos/:id',
    render: DoctorDetails
  },
  {
    userRole: [ROLE_GUEST],
    path: '/emergencia/plano/:id/token',
    render: HealthInsurance
  },
  {
    userRole: [ROLE_GUEST],
    path: '/plano/:doctor_id',
    render: HealthInsurance
  },
  {
    userRole: [ROLE_GUEST],
    path: '/confirme-sua-consulta/:rt',
    render: InvitationConfirmationRedirect
  },
  {
    userRole: [ROLE_GUEST],
    path: '/vidaas/assinatura',
    render: VidaasConfirmationRedirect
  },
  {
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    path: '/medicos/prontuario/:pacient',
    render: DoctorRecord
  },
  {
    userRole: [ROLE_PROFESSIONAL],
    isPrivate: true,
    path: '/consultorio',
    render: DoctorClinic
  },
  {
    userRole: [ROLE_PATIENT],
    isPrivate: true,
    exact: true,
    path: '/consultas',
    render: Consults
  },
  {
    userRole: [ROLE_PATIENT],
    isPrivate: true,
    exact: true,
    path: '/consultas/:consult_id/anamnese/:appointment_id/chat',
    render: PreAppointment
  },
  {
    userRole: [ROLE_PATIENT],
    isPrivate: true,
    exact: true,
    path: '/emergencia/:emergency_id/plano/:insurance_partner/anamnese/:appointment_id/chat',
    render: PreAppointment
  },
  {
    userRole: [ROLE_PROFESSIONAL],
    isPrivate: true,
    exact: true,
    path: '/consultas/:consult_id/anamnese/:appointment_id',
    render: PreAppointmentDetail
  },
  {
    userRole: [ROLE_PROFESSIONAL],
    isPrivate: true,
    exact: true,
    path: '/pacientes/:patient_id',
    render: Patient
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL],
    exact: true,
    path: '/estatisticas',
    render: Statistics
  },
  {
    isPrivate: true,
    userRole: [ROLE_SECRETARY, ROLE_PROFESSIONAL],
    exact: true,
    path: '/agenda',
    render: Schedule
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL, ROLE_SECRETARY],
    exact: true,
    path: '/calendar',
    render: CalendarPage
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL],
    exact: true,
    path: '/equipe',
    render: Team
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    exact: true,
    path: '/notifications',
    render: Notifications
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    path: '/consultas/:consult_id',
    exact: true,
    render: CurrentConsult
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    path: '/consultas/:consult_id/verification',
    render: CurrentConsultVerification
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    path: '/consultas/:consult_id/confirmed',
    render: CurrentConsultConfirmed
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    exact: true,
    path: '/consultas/:consult_id/sala-de-espera',
    render: ConsultWaitRoom
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT, ROLE_PROFESSIONAL],
    exact: true,
    path: '/emergencia/:emergency_id/plano/:insurance_partner/sala-de-espera',
    render: EmergencyWaitingQueue
  },
  {
    userRole: [ROLE_GUEST],
    path: '/emergencia/plano/:id',
    render: TeleconsultationVoucher
  },
  {
    isPrivate: true,
    userRole: [ROLE_PATIENT],
    path: '/voucher/:doctor_id',
    render: TeleconsultationVoucher
  },
  {
    userRole: [ROLE_PATIENT, ROLE_SECRETARY, ROLE_PROFESSIONAL],
    path: '/perfil',
    render: Profile
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL],
    path: '/meus-recibos',
    render: DoctorReceipts
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL],
    path: '/vouchers',
    render: DoctorVouchers
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL],
    path: '/gerar-agendamento',
    render: DoctorScheduling
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL],
    path: '/pos-atendimento/:consultation_id',
    render: PostAppointment
  },
  {
    userRole: [ROLE_GUEST],
    exact: true,
    path: '/completar-cadastro/:invite_token',
    render: PatientSignup
  },
  {
    userRole: [ROLE_SECRETARY],
    isPrivate: true,
    path: '/clinic/statistics',
    render: SecretaryStatistics
  },
  {
    userRole: [ROLE_SECRETARY],
    isPrivate: true,
    path: '/clinic/invitations',
    render: SecretaryInvites
  },
  {
    userRole: [ROLE_SECRETARY],
    isPrivate: true,
    path: '/clinic',
    render: SecretaryClinic
  },
  {
    userRole: [ROLE_GUEST, ROLE_PROFESSIONAL],
    exact: true,
    path: '/checkout/:plan_key',
    render: Checkout
  },
  {
    isPrivate: true,
    userRole: [ROLE_PROFESSIONAL],
    exact: true,
    path: '/pacientes',
    render: Patients
  }
];

export const GUEST_ROUTES = ROUTES.filter(route =>
  (route?.userRole || []).includes(ROLE_GUEST)
);
