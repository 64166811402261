import styled from 'styled-components/macro';

import vcSaudeLogo from '../../assets/images/vcsaude_logo.png';
import ImageOrName from '../ImageOrName';

export const LogoVcSaude = styled.img.attrs({
  src: vcSaudeLogo
})`
  width: 200px;
  background-color: var(--caren-white);
`;

export const DoctorImage = styled(ImageOrName).attrs(({ src }) => ({
  src
}))`
  height: 110px;
  width: 110px;
  border-radius: 50%;
  margin-right: 20px;

  @media (max-width: 1600px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const ModalMsg = styled.div`
  text-align: center;
  span {
    color: red;
  }
`;

export const HealthPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const HealthPlanCard = styled.button.attrs({
  type: 'button'
})`
  background-color: var(--caren-white);
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 2px 2px 0px 2px rgba(170, 170, 170, 0.5);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translate(-3px, -3px);
    box-shadow: 5px 5px 0px 2px rgba(170, 170, 170, 0.5);
  }
`;
