import React, { useState } from 'react';

import moment from 'moment';

// import Datepicker from '../Datepicker';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import {
  CardWeekDay,
  CardWeekText,
  CardWeekContainer,
  CardDatepickerContainer,
  CardIntervalContainer,
  // CardDatepickerContainerDay,
} from './styles';

function CreateNewRecurrence({ recurrence, handleUpdateRecurrence }) {
  const { t } = useTranslation(['doctor_profile']);
  const [selectedDays, _setSelectedDays] = useState([
    { day: t('Dom'), weekday: 'sunday' },
    { day: t('Seg'), weekday: 'monday' },
    { day: t('Ter'), weekday: 'tuesday' },
    { day: t('Qua'), weekday: 'wednesday' },
    { day: t('Qui'), weekday: 'thursday' },
    { day: t('Sex'), weekday: 'friday' },
    { day: t('Sab'), weekday: 'saturday' },
  ]);

  function handleSelectedDays(selected) {
    const newItem = selected.weekday;
    const { weekdays } = recurrence;
    const updated = { ...recurrence };
    const newWeekdays =
      weekdays.indexOf(newItem) === -1
        ? weekdays.concat([newItem])
        : weekdays.filter((value) => value !== newItem);
    updated.weekdays = newWeekdays;
    handleUpdateRecurrence(updated);
  }

  function handleValues(type, value) {
    const updated = { ...recurrence };

    if (type === 'from') {
      const diff = moment(recurrence.to).diff(moment(recurrence.from));

      updated.to = moment(value).add(diff, 'milliseconds').toDate();
    }
    updated[type] = value;

    handleUpdateRecurrence(updated);
  }

  return (
    <>
      <CardWeekText>{t('Dia da semana')}:</CardWeekText>
      <CardWeekContainer>
        {selectedDays.map((selectDay) => (
          <CardWeekDay
            key={selectDay}
            onClick={() => handleSelectedDays(selectDay)}
            isSelected={recurrence.weekdays.includes(selectDay.weekday)}
          >
            {selectDay.day}
          </CardWeekDay>
        ))}
      </CardWeekContainer>
      {/* <CardDatepickerContainerDay>
        <Datepicker
          value={recurrence.from}
          handleValues={handleValues}
          type="date"
          labelText="dia"
        />
        <span />
      </CardDatepickerContainerDay> */}
      <CardDatepickerContainer></CardDatepickerContainer>
      <CardIntervalContainer>
        <TextField
          type="number"
          InputProps={{ inputProps: { min: '0' } }}
          label={t('Intervalo entre as consultas')}
          variant="standard"
          value={recurrence.interval}
          onChange={(e) => handleValues('interval', e.target.value)}
        />
        <span>{t('minutos')}</span>
      </CardIntervalContainer>
    </>
  );
}

export default CreateNewRecurrence;
