import { useState, useRef, useMemo } from 'react';
import moment from 'moment';
import { TextField } from '@mui/material';
import '@fullcalendar/react/dist/vdom';
import FullCalendar, { type EventClickArg } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {
  type DateClickArg
} from '@fullcalendar/interaction';
import { z } from 'zod';
import { isMobile } from '../../../../utils/twilio';
import { momentUtcLocal } from '../../../../utils/moment/momentHelpers';
import { useToast } from '../../../../contexts/ToastContext';
import { CALENDAR_DEFAULT_DISPLAY_DURATION } from '../../../../constants/common';
import './styles.css';

const CalendarConsultSchema = z.object({
  edit: z.boolean().optional(),
  text: z.object({
    day: z.string(),
    startTime: z.string(),
    endTime: z.string()
  }),
  date: z.object({
    startDate: z.date(),
    startTime: z.date(),
    endTime: z.date()
  })
});

const CalendarEventSchema = z.object({
  id: z.string(),
  start: z.date(),
  end: z.date(),
  title: z.string(),
  editable: z.boolean(),
  overlap: z.boolean(),
  color: z.string(),
  classNames: z.string().array().optional(),
  durationEditable: z.boolean().optional()
});

export const CalendarEventResumeSchema = z.object({
  date: z.object({
    day: z.string(),
    time: z.string()
  }),
  text: z.object({
    day: z.string(),
    startTime: z.string(),
    endTime: z.string()
  })
});

type CalendarConsult = z.infer<typeof CalendarConsultSchema>;
export type CalendarEvent = z.infer<typeof CalendarEventSchema>;
export type CalendarEventResume = z.infer<typeof CalendarEventResumeSchema>;

type CalendarProps = {
  consultDuration: number;
  schedules: CalendarEvent[];
  onUpdateWeek: (from: string, to: string) => void;
  onSelectDate: (
    event: {
      date: {
        day: string;
        time: string;
      };
      text: {
        day: string;
        startTime: string;
        endTime: string;
      };
    } | null
  ) => void;
};

export default function Calendar({
  consultDuration,
  schedules,
  onUpdateWeek,
  onSelectDate
}: CalendarProps) {
  const toast = useToast();

  const dialogElement = useRef<HTMLDivElement | null>(null);

  const [consult, setConsult] = useState<CalendarConsult | null>(null);
  const [newEvent, setNewEvent] = useState<CalendarConsult | null>(null);
  const [blockSchedule, setBlockSchedule] = useState(false);
  const [tooltip, setTooltip] = useState({
    open: false,
    title: ''
  });

  const events = useMemo(() => {
    if (!newEvent) return schedules;

    const hasNewEventAlready = schedules.find(
      scheduleEvent =>
        moment(scheduleEvent.start).isSame(moment(newEvent.date.startTime)) &&
        moment(scheduleEvent.end).isSame(moment(newEvent.date.endTime))
    );

    if (hasNewEventAlready) return schedules;

    return [
      ...schedules,
      {
        id: 'new-event',
        start: newEvent.date.startTime,
        end: newEvent.date.endTime,
        classNames: ['fc-added-now'],
        editable: true,
        title: 'Novo Agendamento',
        durationEditable: false,
        overlap: false,
        color: '#B0B0B0'
      }
    ];
  }, [newEvent, schedules]);

  function validOverlap(start: moment.Moment, end: moment.Moment) {
    let overlap: {
      invalid: boolean;
      maybeFit: boolean;
      newTime: null | moment.Moment;
    } = {
      invalid: false,
      maybeFit: false,
      newTime: null
    };
    let lastEvent: CalendarEvent;
    if (schedules) {
      schedules.forEach(event => {
        const eventStart = event.start;
        const eventEnd = event.end;
        if (start.isSame(eventStart, 'day') && event.id !== 'new-event') {
          if (
            end.isAfter(eventStart, 'minutes') &&
            end.isBefore(eventEnd, 'minutes')
          ) {
            const lastEventStart = lastEvent.start;
            const lastEventEnd = lastEvent.end;
            const newStartTime = momentUtcLocal(eventStart).subtract(
              consultDuration,
              'minutes'
            );
            if (
              newStartTime.isAfter(lastEventStart, 'minutes') &&
              newStartTime.isBefore(lastEventEnd, 'minutes')
            ) {
              overlap = {
                invalid: true,
                maybeFit: false,
                newTime: null
              };
            } else {
              overlap = {
                invalid: true,
                maybeFit: true,
                newTime: newStartTime
              };
            }
          }

          if (start.isSame(eventStart))
            overlap = {
              invalid: true,
              maybeFit: false,
              newTime: null
            };

          if (
            start.isAfter(eventStart, 'minutes') &&
            start.isBefore(eventEnd, 'minutes')
          ) {
            overlap = {
              invalid: true,
              maybeFit: false,
              newTime: null
            };
          }
        }
        lastEvent = event;
      });
    }

    return overlap;
  }

  const validateSchedules = (selected: moment.Moment) => {
    const today = moment().local();

    if (selected.isBefore(today)) {
      toast('Não é possível agendar em datas passadas.', {
        variant: 'error'
      });

      return true;
    }

    return false;
  };

  const formatDay = (date: Date) => {
    const today = moment().local();
    const tomorrow = today.clone().add(1, 'days');
    const selected = momentUtcLocal(date);

    if (selected.isSame(today, 'day')) return 'Hoje';
    if (selected.isSame(tomorrow, 'day')) return 'Amanhã';

    return momentUtcLocal(date).format('DD/MM/YYYY');
  };

  function insertEvent(info: DateClickArg) {
    const selectedDate = info.date;
    let startTime = selectedDate;
    let endTime = momentUtcLocal(selectedDate)
      .add(consultDuration, 'minutes')
      .toDate();

    const overlap = validOverlap(moment(startTime), moment(endTime));

    if (overlap.invalid && !overlap.maybeFit) {
      toast('Esse horário irá sobrepor uma consulta existente.', {
        variant: 'error'
      });
      return;
    }
    if (overlap.invalid && overlap.maybeFit && overlap.newTime !== null) {
      startTime = momentUtcLocal(overlap.newTime.toDate()).toDate();
      endTime = momentUtcLocal(overlap.newTime.toDate())
        .add(consultDuration, 'minutes')
        .toDate();
    }
    const day = formatDay(startTime);
    const selected = momentUtcLocal(startTime);
    const blockedTime = validateSchedules(selected);
    if (blockedTime) return;
    const data = {
      edit: false,
      text: {
        day,
        startTime: momentUtcLocal(startTime).format('HH:mm'),
        endTime: momentUtcLocal(endTime).format('HH:mm')
      },
      date: {
        startDate: startTime,
        startTime,
        endTime
      }
    };

    if (blockSchedule) {
      setNewEvent(data);
      setConsult(null);
      updateEvent(startTime);
    } else {
      setConsult(data);
      setBlockSchedule(true);

      onSelectDate({
        text: {
          day,
          startTime: momentUtcLocal(startTime).format('HH:mm'),
          endTime: momentUtcLocal(endTime).format('HH:mm')
        },
        date: {
          day: selected.format('YYYY-MM-DD'),
          time: selected.format('HH:mm:ss')
        }
      });
    }
  }

  function editEvent(info: EventClickArg) {
    if (info.event.id !== 'new-event') return;
    const selectedDate = info.event.start;
    if (!selectedDate) return;

    const startTime = selectedDate;
    const endTime = momentUtcLocal(selectedDate)
      .add(consultDuration, 'minutes')
      .toDate();

    const day = formatDay(startTime);
    const data = {
      edit: true,
      text: {
        day,
        startTime: momentUtcLocal(startTime).format('HH:mm'),
        endTime: momentUtcLocal(endTime).format('HH:mm')
      },
      date: {
        startDate: selectedDate,
        startTime,
        endTime
      }
    };

    setConsult(data);
    setBlockSchedule(true);
  }

  function updateEvent(start: Date | null) {
    if (!start) return;

    const selectedDate = start;
    let startTime = selectedDate;
    let endTime = momentUtcLocal(selectedDate)
      .add(consultDuration, 'minutes')
      .toDate();

    const overlap = validOverlap(moment(startTime), moment(endTime));

    if (overlap.invalid && !overlap.maybeFit) {
      toast('Esse horário irá sobrepor uma consulta existente.', {
        variant: 'error'
      });
      return;
    }
    if (overlap.invalid && overlap.maybeFit && overlap.newTime !== null) {
      startTime = momentUtcLocal(overlap.newTime.toDate()).toDate();
      endTime = momentUtcLocal(overlap.newTime.toDate())
        .add(consultDuration, 'minutes')
        .toDate();
    }
    const day = formatDay(startTime);
    const selected = momentUtcLocal(startTime);
    const blockedTime = validateSchedules(selected);
    if (blockedTime) return;
    const data = {
      edit: false,
      text: {
        day,
        startTime: momentUtcLocal(startTime).format('HH:mm'),
        endTime: momentUtcLocal(endTime).format('HH:mm')
      },
      date: {
        startDate: startTime,
        startTime,
        endTime
      }
    };

    setNewEvent(data);
    setConsult(null);
    onSelectDate({
      text: {
        day,
        startTime: momentUtcLocal(startTime).format('HH:mm'),
        endTime: momentUtcLocal(endTime).format('HH:mm')
      },
      date: {
        day: selected.format('YYYY-MM-DD'),
        time: selected.format('HH:mm:ss')
      }
    });
  }

  function closeDialog(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (dialogElement.current === e.target) {
      if (!consult?.edit) onSelectDate(null);
      setBlockSchedule(consult?.edit ?? false);
      setConsult(null);
    }
  }

  const changeInputTime = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    info: CalendarConsult | null
  ) => {
    if (!info) return;

    const value = e?.target?.value;
    const startDate = moment(info.date.startTime).format('YYYY-MM-DD');
    let newStart = moment(`${startDate} ${value}`).toDate();
    let newEnd = moment(newStart).add(consultDuration, 'minutes').toDate();

    const overlap = validOverlap(moment(newStart), moment(newEnd));

    if (overlap.invalid) {
      toast('Esse horário irá sobrepor uma consulta existente.', {
        variant: 'error'
      });
      if (overlap.maybeFit && overlap.newTime !== null) {
        newStart = momentUtcLocal(overlap.newTime.toDate()).toDate();
        newEnd = momentUtcLocal(overlap.newTime.toDate())
          .add(consultDuration, 'minutes')
          .toDate();
      } else {
        return;
      }
    }
    const selected = momentUtcLocal(newStart);
    const blockedTime = validateSchedules(selected);
    if (blockedTime) return;

    const data = {
      text: {
        day: info.text.day,
        startTime: momentUtcLocal(newStart).format('HH:mm'),
        endTime: momentUtcLocal(newEnd).format('HH:mm')
      },
      date: {
        startDate: newStart,
        startTime: newStart,
        endTime: newEnd
      }
    };
    setConsult(data);
    onSelectDate({
      text: {
        day: info.text.day,
        startTime: momentUtcLocal(newStart).format('HH:mm'),
        endTime: momentUtcLocal(newEnd).format('HH:mm')
      },
      date: {
        day: selected.format('YYYY-MM-DD'),
        time: selected.format('HH:mm:ss')
      }
    });
  };

  return (
    <div className="relative h-full w-full">
      <div
        data-active={tooltip.open && tooltip.title !== '-'}
        className="pointer-events-none absolute bottom-2.5 left-1/2 z-10 -translate-x-1/2 rounded-lg bg-black/60 px-4 py-2 text-sm text-white opacity-0 transition-all duration-300 data-[active=true]:opacity-100"
      >
        {tooltip.title}
      </div>
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={isMobile ? 'timeGridDay' : 'timeGridWeek'}
        height="650px"
        scrollTime={moment().format('HH:mm:ss')}
        slotMinTime="06:00:00"
        slotMaxTime="24:00:00"
        slotDuration="00:30"
        snapDuration="00:05"
        firstDay={moment().subtract(1, 'days').day()}
        buttonText={{
          today: 'Hoje'
        }}
        dayHeaderFormat={{
          weekday: 'short',
          day: 'numeric',
          omitCommas: true
        }}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        }}
        slotLabelInterval="01:00"
        locale="pt-br"
        allDaySlot={false}
        nowIndicator
        events={events}
        defaultTimedEventDuration={CALENDAR_DEFAULT_DISPLAY_DURATION}
        editable={false}
        selectable
        datesSet={info => {
          onUpdateWeek(
            moment(info.start).format('YYYY-MM-DD'),
            moment(info.end).format('YYYY-MM-DD')
          );
        }}
        dateClick={info => {
          insertEvent(info);
        }}
        eventMouseEnter={info => {
          setTooltip({
            open: true,
            title: info.event.title
          });
        }}
        eventMouseLeave={info => {
          setTooltip({
            open: false,
            title: info.event.title
          });
        }}
        eventDrop={info => {
          updateEvent(info.event.start);
        }}
        eventClick={info => {
          editEvent(info);
        }}
        eventConstraint={{
          start: moment().add(60, 'minutes').format('YYYY-MM-DD HH:mm'),
          end: '2500-01-01'
        }}
      />

      <div
        data-active={consult !== null}
        className="pointer-events-none fixed inset-0 z-20 flex items-center justify-center bg-black/60 opacity-0 transition-all duration-300 data-[active=true]:pointer-events-auto data-[active=true]:opacity-100"
        ref={dialogElement}
        onClick={e => {
          closeDialog(e);
        }}
        role="presentation"
      >
        <div className="relative w-full max-w-md rounded-lg bg-white p-4 md:p-7">
          <button
            className="absolute right-2.5 top-0 text-2xl font-bold text-gray-350 transition-all duration-300 hover:text-gray-400"
            type="button"
            onClick={() => {
              if (!consult?.edit) onSelectDate(null);
              setBlockSchedule(consult?.edit ?? false);
              setConsult(null);
            }}
          >
            &times;
          </button>

          {consult?.edit ? (
            <p className="mb-2.5 text-left text-lg text-black">
              Editar agendamento
            </p>
          ) : (
            <p className="mb-2.5 text-left text-lg text-black">
              Novo agendamento
            </p>
          )}

          <p className="mb-1 text-left text-sm leading-normal text-black">
            <strong>Dia:</strong> {consult?.text?.day ? consult.text.day : '-'}
          </p>

          <div className="my-2.5 flex items-center justify-start">
            <span className="mr-2.5 shrink-0 text-left text-sm font-bold leading-normal text-black">
              Horário de início:
            </span>

            <TextField
              hiddenLabel
              type="time"
              value={consult?.text?.startTime}
              onChange={e => {
                changeInputTime(e, consult);
              }}
              variant="filled"
              size="small"
            />
          </div>

          {consult?.text?.endTime !== consult?.text?.startTime && (
            <p className="mb-1 text-left text-sm leading-normal text-black">
              <strong>Horário de término:</strong>{' '}
              {consult?.text?.endTime ? consult.text.endTime : '-'}
            </p>
          )}

          <div className="mt-6 flex items-center justify-center gap-x-5">
            <button
              className="flex h-12 items-center justify-center rounded bg-gray-350 px-5 text-sm font-bold uppercase text-white transition-all duration-300 hover:bg-gray-450"
              type="button"
              title="Voltar"
              aria-label="Cancelar"
              onClick={() => {
                onSelectDate(null);
                setConsult(null);
                setBlockSchedule(false);
              }}
            >
              Voltar
            </button>

            <button
              className="flex h-12 items-center justify-center rounded bg-yellow-600 px-5 text-sm font-bold uppercase text-gray-950 transition-all duration-300 hover:bg-yellow-750"
              type="button"
              title="Continuar"
              aria-label="Continuar"
              onClick={() => {
                setNewEvent(consult);
                setConsult(null);
              }}
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
