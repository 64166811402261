import { Switch, Router } from 'react-router-dom';

import ChannelTest from '../pages/ChannelTest';

import Route from './route';

import history from '../services/history';

import { ROLE_PATIENT, ROLE_PROFESSIONAL } from './constants';
import WithAxios from '../components/WithAxios';
import CarenIFrameAgent from '../components/CarenIFrameAgent';
import { ROUTES } from '../constants/routes';

export default function Routes() {
  return (
    <WithAxios>
      <Router history={history}>
        <Switch>
          {ROUTES.map((props) => (
            <Route key={props.path} {...props} />
          ))}
          {import.meta.env.VITE_MODE === 'development' && (
            <Route
              isPrivate
              userRole={[ROLE_PATIENT, ROLE_PROFESSIONAL]}
              path="/channel-test"
              render={ChannelTest}
            />
          )}
        </Switch>
      </Router>
      <CarenIFrameAgent />
    </WithAxios>
  );
}
