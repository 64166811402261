import styled from 'styled-components/macro';

export const SecretaryHome = styled.section`
  width: 100%;
  height: 100%;
  padding: 30px;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 20px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    row-gap: 20px;

    @media (max-width: 1024px) {
      padding-left: 0;
    }
  }
`;

export const SecretaryHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .info {
    display: flex;
    flex-direction: column;

    & > h2 {
      margin: 0;
      font-weight: bold;
      font-size: 18px;
      color: var(--caren-grey);
    }

    & > p {
      margin: 0;
      font-weight: normal;
      font-size: 12px;
      color: #9a9fa5;
    }
  }

  & .search {
    display: flex;
    position: relative;
    max-width: 300px;
    height: 50px;
    width: 100%;
    align-items: center;

    & > svg {
      left: 18px;
      width: 16px;
      height: 16px;
      position: absolute;
      color: #969faa;
    }

    & > input {
      padding-left: 45px;
      padding-right: 10px;
      width: 100%;
      height: 100%;
      border-radius: 14px;

      ::placeholder {
        color: #969faa;
      }
    }
  }
`;

export const SecretaryConsults = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @media (min-width: 1536px) {
    grid-template-columns: 2fr 1fr;
  }

  & > div {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 15px;

    & > span {
      color: #47525e;
      font-size: 16px;
      font-weight: bold;
      text-align: center;

      @media (max-width: 1140px) {
        font-size: 14px;
      }
    }

    & > p {
      width: 100%;
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      text-align: left;
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(215, 215, 215, 0.5);
    }

    :last-child > p {
      color: var(--caren-grey);
    }

    :first-child > p {
      color: var(--caren-darkgreen);
    }
  }
`;

export const ConsultsTable = styled.table`
  table-layout: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  font-family: 'Montserrat', sans-serif;
  color: #969faa;
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 640px) {
    display: inline-table;
  }

  > thead {
    display: none;

    @media (min-width: 640px) {
      display: table-header-group;
    }

    th {
      padding: 4px 6px;

      &.center {
        text-align: center;
      }
    }
  }

  > tbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (min-width: 640px) {
      row-gap: none;
      display: table-row-group;
    }

    tr {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      border-bottom: 1px solid var(--caren-input-border);
      padding-bottom: 10px;

      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      @media (min-width: 640px) {
        display: table-row;
        row-gap: none;
        border: none;
        padding-bottom: 0;
      }
    }

    td {
      padding: 4px 6px;

      &.center {
        text-align: center;
      }

      > span {
        display: block;
        font-weight: bold;
        width: 100%;
        text-align: center;

        @media (min-width: 640px) {
          display: none;
        }
      }

      p {
        position: relative;

        span {
          background-color: var(--caren-clockgrey);
          color: white;
          padding: 8px 10px;
          border-radius: 8px;
          white-space: nowrap;
          position: absolute;
          top: calc(50% - 2px);
          right: calc(100% + 10px);
          pointer-events: none;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 10;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &:hover span {
          opacity: 1;
        }
      }

      p.warning {
        color: var(--caren-lightgrey);
      }

      p.check,
      button.check svg path {
        color: var(--caren-success);
        fill: var(--caren-success);
      }

      p.progress {
        color: var(--caren-bluebtn);
      }

      button.anamnese svg path {
        color: #b0b0b0;
        fill: #b0b0b0;
      }

      button,
      a {
        svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-orange);
            }
          }

          path {
            fill: var(--caren-darkgreen);
            transition-property: fill;
            transition-duration: 0.5s;
            transition-timing-function: ease;
          }
        }

        svg.clear {
          path {
            fill: inherit;
          }
        }

        &:hover svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-warning);
            }
          }

          path {
            fill: #53d3b0;
          }
        }

        &:hover svg.clear {
          path {
            fill: inherit;
          }
        }
      }

      button.warning-button {
        color: var(--caren-orange);
        border: 2px solid var(--caren-orange);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        border-radius: 10px;
        padding: 0 4px;
        text-align: center;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-warning);
          border-color: var(--caren-warning);
          color: var(--caren-white);
        }
      }

      button.green-button {
        color: var(--caren-action-button);
        border: 2px solid var(--caren-action-button);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: #53d3b0;
          border-color: #53d3b0;
          color: var(--caren-white);
        }
      }

      a.blue-button {
        color: var(--caren-bluebtn);
        border: 2px solid var(--caren-bluebtn);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-lightblue);
          border-color: var(--caren-lightblue);
          color: var(--caren-white);
        }
      }

      div.perfil {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        @media (min-width: 640px) {
          flex-direction: row;
        }
      }
    }
  }
`;
