import { IoIosArrowRoundBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { type LocationDescriptor } from 'history';
import history from '../../services/history';

export function BackButton({
  path,
  title = 'Voltar'
}: {
  path?: LocationDescriptor<unknown>;
  title?: string;
}) {
  const { t } = useTranslation(['common']);
  return (
    <button
      type="button"
      className="flex items-center"
      onClick={() => {
        history.goBack();
      }}
    >
      <IoIosArrowRoundBack size={25} color="var(--caren-icongrey)" />
      <div className="ml-2 font-bold text-gray-950">{t(title).toString()}</div>
    </button>
  );
}
