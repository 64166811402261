import styled from 'styled-components/macro';
import {
  ContactSupport,
  Security,
  VerifiedUser,
  SafetyCheck
} from '@mui/icons-material';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@mui/material';

export const ActionButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
  fullWidth: true
})`
  && {
    margin-top: 1rem;
  }
`;

export const ModalIcon = styled(Security).attrs({})`
  && {
    display: block;
    font-size: 90px;
    margin: 0 auto 1rem;
  }
`;

export const ModalVidaasIcon = styled.img.attrs({
  src: 'https://cdn.media.caren.app/vendor/img/logo-vidaas.png'
})`
  && {
    display: block;
    width: 284px;
    height: 90px;
    margin: 0 auto 1rem;
  }
`;

export const ModalHelpIcon = styled(ContactSupport).attrs({})`
  && {
    display: block;
    font-size: 90px;
    margin: 0 auto 1rem;
  }
`;

export const ModalProcessIcon = styled(SafetyCheck).attrs({})`
  && {
    display: block;
    font-size: 90px;
    margin: 0 auto 1rem;
  }
`;

export const ModalSuccessIcon = styled(VerifiedUser).attrs({})`
  && {
    display: block;
    font-size: 90px;
    margin: 0 auto 1rem;
  }
`;

export const Loader = styled(CircularProgress).attrs({ size: 30 })`
  color: var(--caren-dark);
  margin: 0 auto;
`;
