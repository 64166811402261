import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback
} from 'react';

import { ReactComponent as ReceiveIcon } from '../../../assets/icons/receive-square.svg';
import { ReactComponent as TimerIcon } from '../../../assets/icons/timer.svg';
import { ReactComponent as DocumentDownloadIcon } from '../../../assets/icons/document-download.svg';

import BaseDialog from '../base';
import {
  getConsultationsReports,
  getReportsStatus
} from '../../../services/consults';
import { API_BASEURL } from '../../../services/api';

type ConsultsFilters = {
  tab: 'upcoming' | 'historic';
  search?: string;
  start?: string;
  end?: string;
  status?: string;
}

type ConsultsReportsDialogHandles = {
  open: (filters: ConsultsFilters) => void;
}

type ConsultsReportsDialogProps = {
  employeeKey?: string;
}

const ConsultsReportsDialog = forwardRef<
  ConsultsReportsDialogHandles,
  ConsultsReportsDialogProps
>(({ employeeKey }, ref) => {
  const [filters, setFilters] = useState<ConsultsFilters>();
  const [isOpen, setIsOpen] = useState(false);
  const [isProceed, setIsProceed] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const [fileKey, setFileKey] = useState<string | null>(null);
  const [readyToDownload, setReadyToDownload] = useState(false);

  const isFiltersEmpty =
    filters === undefined ||
    (!filters.end && !filters.start && !filters.search && !filters.status);

  useImperativeHandle(ref, () => ({
    open: newFilters => {
      setIsOpen(true);
      setFilters(newFilters);
      setReadyToDownload(false);
      setFileKey(null);
      setError(null);
      setIsGenerating(false);

      if (
        !newFilters.end &&
        !newFilters.start &&
        !newFilters.search &&
        !newFilters.status
      ) {
        setIsProceed(false);
      } else {
        setIsProceed(true);
      }
    }
  }));

  const getReports = useCallback(async () => {
    if (!filters) return;

    setIsGenerating(true);

    try {
      const result = await getConsultationsReports({
        ...filters,
        employee_key: employeeKey
      });

      if (result) setFileKey(result);
    } catch (e) {
      setError(
        'Não foi possível gerar o relatório, tente novamente mais tarde.'
      );
    }
  }, [filters, employeeKey]);

  const getFileStatus = useCallback(
    async (key: string) => {
      if (!isOpen) return;

      try {
        const result = await getReportsStatus(key, employeeKey);

        if (result) {
          setReadyToDownload(true);
        } else {
          setTimeout(() => {
            getFileStatus(key);
          }, 1000);
        }
      } catch (e) {
        setError(
          'Não foi possível gerar o relatório, tente novamente mais tarde.'
        );
      }
    },
    [employeeKey, isOpen]
  );

  useEffect(() => {
    if (isProceed && !isGenerating) {
      getReports();
    }
  }, [isProceed, isGenerating, getReports]);

  useEffect(() => {
    if (fileKey) {
      getFileStatus(fileKey);
    }
  }, [fileKey, getFileStatus]);

  return (
    <BaseDialog
      title={
        !isProceed && isFiltersEmpty
          ? 'Confirmação de download'
          : 'Relatório de consultas'
      }
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className="mt-6 flex w-full flex-col items-center">
        {!isProceed && isFiltersEmpty && (
          <>
            <p className="mb-6 text-base text-blue-650">
              Vimos que você não selecionou:
            </p>
            <ul className="mb-7 flex list-disc flex-col text-base text-blue-650">
              <li>Nome do paciente</li>
              <li>Data de início e/ou fim</li>
              <li>Tipo de consulta</li>
            </ul>
            <p className="mb-16 max-w-md text-center text-base text-blue-650">
              <strong>Atenção:</strong> Você irá baixar todas as consultas
              realizadas nos últimos 30 dias, tem certeza disso?
            </p>
            <div className="flex items-center gap-x-10">
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                }}
                className="font-nunito text-base font-bold text-blue-650"
              >
                Quero cancelar
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsProceed(true);
                }}
                className="h-14 w-fit rounded-md bg-yellow-650 px-10 font-nunito text-base font-bold text-white transition-all duration-200 hover:bg-yellow-750"
              >
                <ReceiveIcon className="mr-2 inline" />
                Quero baixar
              </button>
            </div>
          </>
        )}
        {isProceed && !error && !readyToDownload && (
          <>
            <p className="mb-6 text-base text-blue-650">Tudo certo!</p>
            <p className="mb-6 text-base text-blue-650">
              Estamos gerando o relatório para você!
            </p>
            <p className="mb-10 max-w-md text-center text-base text-blue-650">
              Não saia dessa janela, pois assim que estiver pronto o download
              iniciará automaticamente.
            </p>
            <div className="mb-10 animate-pulse">
              <TimerIcon className="h-6 w-6 animate-spin text-gray-450" />
            </div>
          </>
        )}
        {error && !readyToDownload && (
          <p className="mb-16 text-base text-blue-650">{error}</p>
        )}
        {readyToDownload && (
          <>
            <p className="mb-6 text-base text-blue-650">
              Relatório gerado com sucesso!
            </p>
            <p className="mb-6 text-base text-blue-650">
              Clique no botão abaixo para baixar o relatório.
            </p>
            <a
              className="flex h-14 w-fit items-center rounded-md bg-yellow-650 px-10 font-nunito text-base font-bold text-white transition-all duration-200 hover:bg-yellow-750 hover:text-white"
              href={`${API_BASEURL}/reports/on-demand/${fileKey}/download${
                employeeKey ? `?employee_key=${employeeKey}` : ''
              }`}
              target="_blank"
              download
              rel="noreferrer"
            >
              <DocumentDownloadIcon className="mr-2 inline" />
              Baixar Arquivo
            </a>
          </>
        )}
      </div>
    </BaseDialog>
  );
});

ConsultsReportsDialog.displayName = 'ConsultsReportsDialog';

export type { ConsultsReportsDialogHandles };
export default ConsultsReportsDialog;
