import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { SliderContainer } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  minutesLabel: {
    flex: 1,
    marginLeft: '20px',
  },
}));

const CustomSlider = withStyles({
  root: {
    color: 'var(--caren-greenblue) !important',
    width: 'auto',
    maxWidth: '800px',
    height: 8,
    display: 'flex',
    flex: 3,
    marginRight: 10,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    // backgroundColor: 'var(--caren-orange)',
  },
})(Slider);

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: 'var(--caren-greenblue) !important',
      display: 'none',
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

export default function CustomizedSlider({
  children,
  onChange,
  labelTitle = '',
  value = 0,
  isActived = true,
  changeActiveReturning,
}) {
  const classes = useStyles();

  return (
    <SliderContainer>
      <div className={classes.root}>
        <div className={classes.margin} />
        <FormControlLabel
          value="end"
          onChange={changeActiveReturning}
          control={<CustomCheckbox checked={isActived} />}
          label={labelTitle}
          labelPlacement="end"
        />
        {isActived && (
          <>
            <CustomSlider
              valueLabelDisplay="auto"
              value={value}
              onChange={(_, newValue) => onChange(newValue)}
              min={10}
              max={60}
              step={5}
            />
            <span className={classes.minutesLabel}>{value} min.</span>
            {children}
          </>
        )}
      </div>
    </SliderContainer>
  );
}
