import { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../contexts/ToastContext';
import { FORMAT } from '../../../utils/moment/momentFormat';
import Modal from '../../../components/Modal';
import history from '../../../services/history';
import { patientSignup } from '../../../services/patient';

import HomeSidebar from '../../../components/Sidebar/HomeSidebar';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoBlack } from '../../../assets/images/logo-black.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/arrow-left.svg';
import { type PatientCreate } from '../../entities/patient';
import { useAuth } from '../../../hooks/AuthContext';
import PatientSignupContextProvider from './context';
import PersonalInfo from './steps/personalInfo';
import FiscalCode from './steps/fiscalCode';

const useStyles = makeStyles(_theme => ({
  buttonText: {
    color: 'var(--caren-white) !important',
    fontWeight: 'bold',
    fontSize: '1em',
    textTransform: 'uppercase'
  }
}));

export default function PatientSignup() {
  const { persistSession } = useAuth();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation(['doctor_signup']);

  async function handleCreatePatient(formData: PatientCreate) {
    formData.birthdate = moment(formData.birthdate).format(FORMAT['us-date']);

    if (formData.password === formData.password_confirmation) {
      setLoading(true);
      try {
        const signup = await patientSignup(formData);
        persistSession(signup.data);

        setOpenModal(true);
      } catch (error: any) {
        if (error?.response?.data?.error?.message?.login) {
          toast(`CPF ${error.response.data.error.message.login}.`, {
            variant: 'error'
          });
        } else
          toast(t('Não foi possível efetuar o cadastro, tente novamente.'), {
            variant: 'error'
          });
      }
      setLoading(false);
    }
  }

  const classes = useStyles();

  return (
    <>
      <Helmet title="Cadastro - Telessaúde Caren" />
      <HomeSidebar onCloseCallback={() => {}} />
      {openModal && (
        <Modal
          openModal={openModal}
          handleOpenModal={() => ''}
          modalTitle="Sua conta foi criada com sucesso!"
          modalTitleColor="black"
          modalWidth="40%"
          noClosing
        >
          <Button
            onClick={() => {
              history.push('/login');
            }}
            color="primary"
            fullWidth
            variant="contained"
            classes={{
              label: classes.buttonText
            }}
          >
            OK
          </Button>
        </Modal>
      )}
      <div className="flex min-h-screen w-full flex-col bg-yellow-150">
        <div className="flex items-center justify-between px-4 pt-4 lg:px-16 lg:pt-10">
          <LogoBlack className="w-32" />
        </div>
        <div className="mt-10 flex-1 lg:mt-24">
          <PatientSignupContextProvider>
            <h1 className="text-center font-nunito text-5xl">
              Cadastro de usuário
            </h1>
            <FiscalCode step={1} />
            <PersonalInfo
              step={2}
              loading={loading}
              onComplete={handleCreatePatient}
            />
          </PatientSignupContextProvider>
          <div className="mt-4 flex flex-row justify-center">
            <Link
              to="/login/paciente"
              className="flex items-center gap-x-3 p-4"
            >
              <BackArrow className="w-4 text-black" />
              <span className="uppercase text-black">Voltar</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
