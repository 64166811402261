import { useState, forwardRef, useImperativeHandle } from 'react';
import BaseDialog from '../base';

type StartFirstConsultDialogHandles = {
  open: () => void;
};

type StartFirstConsultDialogProps = {
  onClose: () => void;
};

const StartFirstConsultDialog = forwardRef<
  StartFirstConsultDialogHandles,
  StartFirstConsultDialogProps
>(({ onClose }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    }
  }));

  return (
    <BaseDialog
      title="Iniciando sua primeira consulta"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      disableBackdropClick
    >
      <div className="flex w-full flex-col items-center">
        <p className="mx-8 mb-6 mt-10 text-center text-base font-bold text-blue-650">
          Parabéns, suas informações estão completas!
        </p>
        <p className="mx-8 mb-6 text-center text-base text-blue-650">
          Crie, agora, a sua <strong>primeira consulta</strong> através do botão
          abaixo.
        </p>
        <div className="mt-5 flex items-center gap-x-10">
          <button
            type="button"
            onClick={() => {
              setIsOpen(false);
              onClose();
            }}
            className="h-14 w-fit rounded-md bg-yellow-650 px-10 font-nunito text-base font-bold text-white transition-all duration-200 hover:bg-yellow-750"
          >
            Criar teleconsulta
          </button>
        </div>
      </div>
    </BaseDialog>
  );
});

StartFirstConsultDialog.displayName = 'StartFirstConsultDialog';
export type { StartFirstConsultDialogHandles };
export default StartFirstConsultDialog;
