import { useEffect } from 'react';
import { WrapperLoading } from './styles';
import history from '../../services/history';
import { getInvitationUrlByResponseToken } from '../../services/get-invitation-url-by-token';
import { useAuth } from '../../hooks/AuthContext';

export default function InvitationConfirmationRedirect({
  computedMatch: {
    params: { rt },
  },
}) {
  useEffect(() => {
    async function getInvitationUrl() {
      try {
        const { url: fullpath } = await getInvitationUrlByResponseToken(rt);

        history.push(fullpath);
      } catch (error) {
        history.push('/consultas');
      }
    }

    getInvitationUrl();
  }, []);
  return (
    <WrapperLoading>
      <span />
      <span />
    </WrapperLoading>
  );
}
