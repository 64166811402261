import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 50px 40px 50px 65px;
  min-height: calc(100vh - 95px);

  @media (max-width: 1140px) {
    padding: 50px 25px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FirstGrid = styled.div`
  width: 300px;
  flex-shrink: 0;
  margin-right: 20px;

  @media (max-width: 1140px) {
    margin-right: 25px;
  }
  @media (max-width: 1140px) {
    width: 100%;
    max-width: 320px;
    margin: 0 0 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SecondGrid = styled.div`
  flex-grow: 1;

  @media (max-width: 1140px) {
    flex-grow: unset;
    width: 100%;
  }
`;
