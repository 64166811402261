import { useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';

import BaseDialog from '../base';

type UpgradeTeamDialogHandles = {
  open: () => void;
}

const UpgradeTeamDialog = forwardRef<UpgradeTeamDialogHandles>((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    }
  }));

  return (
    <BaseDialog
      title="Esta é uma funcionalidade do Plano Pro"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className="flex w-full flex-col items-center">
        <img
          className="mb-6 h-48"
          alt="Doctor Icon"
          src="https://cdn.media.caren.app/email/doctor_doodle_black.png"
        />
        <p className="mx-28 mb-6 text-left text-base text-blue-650">
          Para utilizar a funcionalidade de <strong>equipe</strong>, você
          precisa contratar um dos nossos <strong>Planos Pro</strong>, mensal ou
          anual.
        </p>
        <p className="mx-28 mb-6 text-left text-base text-blue-650">
          Membros da sua equipe podem realizar agendamentos, interagir com seus
          pacientes e te apoiar no seu trabalho do dia-a-dia.
        </p>
        <p className="mx-28 mb-6 text-left text-base text-blue-650">
          Para contratar o Plano Pro vá até o menu, escolha a opção{' '}
          <strong>meus dados</strong> clique na <strong>aba de Planos</strong> e
          contrate um Plano da sua preferência.
        </p>
        <div className="my-4 flex items-center gap-x-10">
          <button
            type="button"
            onClick={() => {
              localStorage.setItem('doctor-profile-tab', 'plan_details');
              history.push('/perfil', { view: 'plans' });
            }}
            className="text-gray-850 h-14 w-fit rounded-md bg-yellow-650 px-10 font-nunito text-base font-bold uppercase transition-all duration-200 hover:bg-yellow-750"
          >
            Contrate agora
          </button>
        </div>
      </div>
    </BaseDialog>
  );
});

UpgradeTeamDialog.displayName = 'UpgradeTeamDialog';
export type { UpgradeTeamDialogHandles };
export default UpgradeTeamDialog;
