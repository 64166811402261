import styled from 'styled-components/macro';
import { IoIosRemoveCircle } from 'react-icons/io';
import NumberFormat from 'react-number-format';

export const RedirectButton = styled.button`
  border-radius: 4px;
  background-color: var(--caren-greenblue);
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  width: 150px;
  color: var(--caren-white);
  margin-left: auto;
  display: block;
  cursor: pointer;
`;

export const UpdateDurationButton = styled(RedirectButton).attrs({
  type: 'submit'
})``;

export const RemoveIcon = styled(IoIosRemoveCircle).attrs({
  size: 25,
  color: 'var(--caren-red)'
})`
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    transform: rotate(360deg) scale(1.2);
  }
`;

export const FormContainer = styled.form`
  margin-bottom: 10px;
  background: #f7f7f7;
  padding: 25px;
  border-radius: 5px;

  input {
    text-align: center;
    background-color: var(--caren-white);
    border: 1px solid var(--caren-icongrey);
    border-radius: 5px;
    padding-left: 5px;
    height: 35px;
    width: 100%;
    font-size: 16px;
  }

  margin: 10px 0;
`;

export const Label = styled.label`
  color: var(--caren-icongrey);
  margin: 10px 0 5px 0;
`;

export const CalendarContainer = styled.div`
  min-width: 670px;
`;

export const SectionContainer = styled.div`
  margin-bottom: 10px;
  background: #f7f7f7;
  padding: 25px;
  border-radius: 5px;
  width: 100%;
  overflow-x: auto;
`;

export const InlineInput = styled(NumberFormat)`
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--caren-lightgrey) !important;
  border-radius: 0 !important;
  width: auto !important;
  margin-left: 0%;
  margin-bottom: 10px;
`;

export const InlineInputPresential = styled(NumberFormat)`
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--caren-lightgrey) !important;
  border-radius: 0 !important;
  display: inline-block;
  width: 20% !important;
  min-width: 160px;
  margin: 0;
`;
