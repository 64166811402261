import onlyDigits from './onlyDigits';

export default rawValue => {
  if (!rawValue) {
    return false;
  }

  const value = onlyDigits(rawValue);

  if (value.toString().length !== 11 || /^(\d)\1{10}$/.test(value))
    return false;

  let result = true;

  [9, 10].forEach(j => {
    let sum = 0;
    let r = 0;
    value
      .split(/(?=)/)
      .splice(0, j)
      .forEach((e, i) => {
        sum += parseInt(e, 10) * (j + 2 - (i + 1));
      });
    r = sum % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r !== parseInt(value.substring(j, j + 1), 10)) result = false;
  });

  return result;
};
