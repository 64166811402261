import api from './api';

export const getInvitationUrlByResponseToken = async (
  responseToken,
  partnerKey
) => {
  const { data } = await api.get(
    `/teleconsultations/invitations/token/${responseToken}`,
    { private: partnerKey === 'private' }
  );

  return data;
};
