import { Helmet } from 'react-helmet-async';
import Header from '../../components/Header';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import DoctorPanel from '../../components/DoctorPanel';
import Calendar from '../../v2/components/common/calendar';
import { useAuth } from '../../hooks/AuthContext';
import { useDoctorSchedulesCalendar } from '../../v2/hooks/schedules/useDoctorSchedulesCalendar';
import moment from 'moment';
import { useRef, useState } from 'react';
import Button from '../../v2/components/inputs/buttons/Button';
import CreateConsultDialog, {
  type CreateConsultDialogHandles
} from '../../v2/dialogs/consult/create';
import SecretaryPanel from '../../components/SecretaryPanel';
import { ROLE_SECRETARY } from '../../routes/constants';
import type { Doctor } from '../../v2/entities/doctor';
import SelectDoctors from '../../v2/components/secretary/SelectDoctors';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
import { doctorResendInvitationService } from '../../services/doctors.service';
import { useToast } from '../../contexts/ToastContext';
import { AxiosError } from 'axios';
import type { ExceededAppointmentsDialogHandles } from '../../v2/dialogs/upgrade/exceededAppointments';
import Modal from '../../components/Modal';
import SuccessStep from '../../v2/dialogs/consult/steps/success';
import ImageOrName from '../../components/ImageOrName';
import Chatwoot from '../../components/Chatwoot';

export default function CalendarPage() {
  const { credentials } = useAuth();
  const toast = useToast();
  const isSecretary = credentials?.token?.user_type === ROLE_SECRETARY;

  const createConsultDialogRef = useRef<CreateConsultDialogHandles>(null);
  const exceededAppointmentsDialogRef =
    useRef<ExceededAppointmentsDialogHandles>(null);

  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    to: moment().add(6, 'days').format('YYYY-MM-DD')
  });
  const { data, mutate } = useDoctorSchedulesCalendar({
    ...dateRange,
    employee: selectedDoctor?.key
  });

  const [newEvent, setNewEvent] = useState<{
    date: {
      day: string;
      time: string;
    };
    text: {
      day: string;
      startTime: string;
      endTime: string;
    };
  } | null>(null);
  const [unreversibleDialog, setUnreversibleDialog] = useState<{
    open: boolean;
    type: null | 'resend';
    callback?: () => void;
  }>({
    open: false,
    type: null
  });
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean;
    link?: string;
  }>({
    open: false
  });

  const resendInvitation = async (key: string) => {
    try {
      const { link } = await doctorResendInvitationService(key, selectedDoctor);

      toast('Convite reenviado com sucesso.', {
        variant: 'success'
      });

      setConfirmationDialog({ open: true, link });
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;

        const exceededMessage = 'You have exceeded the free trial limit';
        const responseMessage = response?.data?.error?.message;

        if (response?.status === 403 && responseMessage === exceededMessage) {
          exceededAppointmentsDialogRef.current?.open();
          return;
        }
      }

      toast('Ops, houve um erro no reenvio do convite...', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Helmet title="Agenda - Caren" />
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <div className="flex flex-col items-center gap-16 px-6 py-12 xl:flex-row xl:items-stretch xl:px-10">
        <div className="w-full max-w-xs shrink-0 xl:w-64 xl:max-w-none">
          {isSecretary ? (
            <SecretaryPanel
              onResendClick={key => {
                setUnreversibleDialog({
                  open: true,
                  type: 'resend',
                  callback: async () => {
                    await resendInvitation(key);
                  }
                });
              }}
            />
          ) : (
            <DoctorPanel />
          )}
        </div>
        {isSecretary && selectedDoctor === null ? (
          <div className="w-full flex-grow">
            <SelectDoctors
              onSelectDoctor={doctor => {
                setSelectedDoctor(doctor);
              }}
            />
          </div>
        ) : (
          <div className="flex h-full w-full flex-grow flex-col items-center">
            {isSecretary && selectedDoctor !== null && (
              <button
                type="button"
                onClick={() => {
                  setSelectedDoctor(null);
                }}
                className="mb-8 mr-auto flex w-full flex-col items-center gap-2 rounded border-b border-gray-200 bg-white p-2 transition-all duration-300 hover:shadow-md md:max-w-80"
              >
                <p className="text-sm text-yellow-750">
                  Agenda do Profissional
                </p>
                <div className="flex items-center gap-2">
                  <ImageOrName
                    src={selectedDoctor.avatar_url}
                    title={selectedDoctor.name}
                    titleSize={16}
                    backgroundStyle={{
                      width: 40,
                      height: 40,
                      borderRadius: '100%',
                      backgroundColor: 'var(--caren-image-fallback)'
                    }}
                  />
                  <p>{selectedDoctor.name}</p>
                </div>

                <span className="text-xs text-gray-950">
                  Clique aqui para alterar
                </span>
              </button>
            )}
            <Calendar
              consultDuration={
                credentials?.session?.teleconsultation_settings?.default
                  ?.duration ?? 0
              }
              schedules={data ?? []}
              onSelectDate={event => {
                setNewEvent(event);
              }}
              onUpdateWeek={(from, to) => {
                setDateRange({ from, to });
              }}
            />
            {newEvent !== null && (
              <>
                <div className="mt-8 w-full max-w-40">
                  <Button
                    label="Marcar Consulta"
                    type="button"
                    colors="yellow"
                    size="medium"
                    onClick={() => {
                      createConsultDialogRef.current?.open(
                        new Map(
                          selectedDoctor
                            ? [
                                [
                                  1,
                                  {
                                    header: selectedDoctor.name,
                                    value: selectedDoctor
                                  }
                                ],
                                [
                                  2,
                                  {
                                    header: `${newEvent.text.day} - ${newEvent.text.startTime} até ${newEvent.text.endTime}`,
                                    value: newEvent
                                  }
                                ]
                              ]
                            : [
                                [
                                  1,
                                  {
                                    header: `${newEvent.text.day} - ${newEvent.text.startTime} até ${newEvent.text.endTime}`,
                                    value: newEvent
                                  }
                                ]
                              ]
                        ),
                        selectedDoctor ? 3 : 2
                      );
                    }}
                  />
                </div>
                <p className="mt-6 text-center text-sm text-gray-450">
                  Ao prosseguir a consulta será registrada em:{' '}
                  {newEvent.text.day} - {newEvent.text.startTime} até{' '}
                  {newEvent.text.endTime}
                </p>
              </>
            )}
          </div>
        )}
      </div>
      <CreateConsultDialog
        isManager={isSecretary}
        ref={createConsultDialogRef}
        onSuccess={() => {
          setNewEvent(null);
          mutate();
        }}
      />
      <UnreversibleConfirmationModal
        title="Você tem certeza de que deseja reenviar esse convite?"
        openModal={unreversibleDialog.open}
        setOpenModal={() => {
          setUnreversibleDialog({ open: false, type: null });
        }}
        handleConfirmation={(status: boolean) => {
          if (status && unreversibleDialog.callback !== undefined) {
            unreversibleDialog.callback();
          }
          setUnreversibleDialog({ open: false, type: null });
        }}
        cancelButtonText="Não"
        confirmButtonText="Sim"
      />
      <Modal
        handleOpenModal={() => {
          setConfirmationDialog({ open: false });
        }}
        openModal={confirmationDialog.open}
        modalTitle="Convite Reenviado!"
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside={true}
      >
        <SuccessStep
          invitationLink={confirmationDialog.link ?? ''}
          onClose={() => {
            setConfirmationDialog({ open: false });
          }}
        />
      </Modal>
      <Chatwoot />
    </>
  );
}
