import { useState, useContext } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sidebarOpen } from '../../store/modules/Sidebar/actions';
import { ReactComponent as CodeCircleIcon } from '../../assets/icons/code-circle.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as Logo } from '../../assets/images/logo-black.svg';
import { Store } from '../../store';
import { useAuth } from '../../hooks/AuthContext';
import useNotificationCount from '../../hooks/notificationCount.hook';
import { ROLE_PROFESSIONAL } from '../../routes/constants';
import Notifications from './elements/Notifications';
import Tour from '../Tour';
import { Menu, Popover } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';
import { IoIosNotifications } from 'react-icons/io';

type HeaderProps = {
  leftAction?: 'sidebar' | 'back' | null;
  rightAction?: 'notifications' | 'back' | null;
  backAction?: () => void;
};

export default function Header({
  leftAction = 'sidebar',
  rightAction = 'notifications',
  backAction
}: HeaderProps) {
  const { t } = useTranslation(['header']);

  const [, dispatch] = useContext(Store);
  const unreadNotificationCount = useNotificationCount();
  const { credentials } = useAuth();
  const history = useHistory();

  const isLogged = credentials?.token;
  const isProfessional =
    credentials && credentials.token.user_type === ROLE_PROFESSIONAL;

  const [removeCounter, setRemoveCounter] = useState(false);
  const [openTour, setTourOpen] = useState(false);

  return (
    <>
      <div
        className="relative flex w-full flex-col items-center justify-center bg-yellow-180 px-6 py-4"
        data-tour="Em casos de dúvidas, entre em contato com a nossa equipe através do e-mail contato@caren.app."
        data-tour-index="12"
      >
        <div className="absolute left-6 flex items-center">
          {leftAction === 'sidebar' && (
            <button
              type="button"
              onClick={() => dispatch(sidebarOpen())}
              className="text-gray-950"
              data-tour="Clicando aqui você acessa o menu do seu consultório virtual e também o botão para encerrar a sua sessão na plataforma."
              data-tour-index="1"
            >
              <MenuIcon className="h-5 w-5" />
            </button>
          )}
          {leftAction === 'back' && (
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="text-gray-950"
            >
              <ArrowLeftIcon className="h-6 w-6" />
            </button>
          )}
        </div>

        <div className="relative flex items-center justify-center">
          <Link to="/">
            <Logo
              className="h-auto w-20 text-gray-950"
              data-tour="Bem vindo(a) ao nosso Tour Virtual!<br/><br/>Você conhecerá as principais funcionalidades do seu consultório virtual."
              data-tour-index="0"
            />
          </Link>
        </div>

        <div className="absolute right-6 flex items-center gap-x-4">
          {import.meta.env.VITE_MODE === 'development' && (
            <div
              title="Você está utilizando uma versão de testes do Telemedicina"
              className="mr-5 flex select-none items-center justify-center gap-x-1.5 rounded-md bg-success px-2 py-1 text-white"
            >
              <CodeCircleIcon className="h-6 w-6" />
              <p className="font-nunito text-sm font-semibold">Staging</p>
            </div>
          )}
          {rightAction === 'notifications' && (
            <>
              {isProfessional && (
                <>
                  <Menu
                    as="div"
                    className="relative flex w-fit flex-col items-end justify-end"
                  >
                    <Menu.Items className="absolute top-[120%] flex w-40 flex-col items-start rounded-md bg-white shadow-md">
                      <Menu.Item>
                        <button
                          className="px-4 py-2 font-nunito font-semibold text-gray-950 transition-all duration-200 hover:text-yellow-750"
                          type="button"
                          title="Abrir tour"
                          aria-label="Abrir tour"
                          onClick={() => {
                            setTourOpen(true);
                          }}
                        >
                          Tour virtual
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          className="px-4 py-2 font-nunito font-semibold text-gray-950 transition-all  duration-200 hover:text-yellow-750"
                          href="https://carenapp.notion.site/Manual-de-uso-Profissional-da-Sa-de-vers-o-1-0-680a64fdc5804360b030d006c9482773"
                          title="Abrir manual de uso"
                          aria-label="Abrir manual de uso"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Manual de uso
                        </a>
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                  <Tour openTour={openTour} setTourOpen={setTourOpen} />
                </>
              )}

              {isLogged && credentials.token.session_type !== 'limited' && (
                <Popover className="relative">
                  <Popover.Button
                    title="Notificações"
                    data-count={unreadNotificationCount}
                    className={twJoin(
                      'relative grid place-items-center rounded-full text-gray-950 outline-none ring-0 transition-all duration-200',
                      !removeCounter && unreadNotificationCount > 0
                        ? 'before:absolute before:-top-1 before:left-[50%] before:rounded-md before:bg-error before:px-1.5 before:text-right before:text-xs before:font-semibold before:text-white before:content-[attr(data-count)]'
                        : ''
                    )}
                    data-tour="Alertas sobre eventos importantes do seu consultório serão sinalizados aqui."
                    data-tour-index="11"
                  >
                    <IoIosNotifications className="text-3xl" />
                  </Popover.Button>

                  <Popover.Panel className="absolute right-0 top-[120%] z-10 rounded-md">
                    <Notifications
                      onUpdateCounter={() => {
                        setRemoveCounter(true);
                      }}
                    />
                  </Popover.Panel>
                </Popover>
              )}
            </>
          )}
          {rightAction === 'back' && (
            <button
              type="button"
              onClick={() => {
                if (backAction) {
                  backAction();
                  return;
                }
                history.goBack();
              }}
              className="flex items-center rounded-lg bg-gray-950 px-2 py-1 text-xs font-semibold text-white sm:text-sm"
            >
              <ArrowLeftIcon className="mr-1 h-5 w-5 sm:mr-2" />
              <p className="">
                Voltar <br className="flex sm:hidden" /> Resposta
              </p>
            </button>
          )}
        </div>
      </div>
      {isLogged &&
        !credentials.session.approved &&
        credentials.token.user_type === ROLE_PROFESSIONAL && (
          <div className="flex w-full items-center justify-center bg-warning py-1 font-nunito text-sm font-bold text-white">
            {t('Sua conta está sob revisão')}
          </div>
        )}
    </>
  );
}
