import { z } from 'zod';

const SecretarySchema = z.object({
  key: z.string(),
  name: z.string(),
  avatar_url: z.string().nullable(),
  last_login: z.string().nullable()
});

const SecretaryProfileSchema = z.object({
  name: z.string(),
  birthdate: z.string().optional(),
  gender: z.string().optional(),
  fiscal_code: z.string(),
  phone: z.string().nullable(),
  bio: z.string().optional(),
  country: z.string(),
  state: z.string(),
  city: z.string()
});

type Secretary = z.infer<typeof SecretarySchema>;
type SecretaryProfile = z.infer<typeof SecretaryProfileSchema>;

export { SecretarySchema, SecretaryProfileSchema };
export type { Secretary, SecretaryProfile };
