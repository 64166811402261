import React from 'react';

// import { Container } from './styles';

export function DisplayFlex({
  children,
  justify = '',
  align = '',
  customStyles = {},
}) {
  const styles = {
    display: 'flex',
    justifyContent: justify,
    alignItems: align,
  };

  // eslint-disable-next-line react/destructuring-assignment
  return <div style={{ ...styles, ...customStyles }}>{children}</div>;
}
