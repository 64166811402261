import styled from 'styled-components/macro';

import fapesLogo from '../../assets/images/fapes_logo.jpg';
import vcSaudeLogo from '../../assets/images/vcsaude_logo.png';
import ImageOrName from '../../components/ImageOrName';
import fapesIcon from '../../assets/images/plano_vc.png';

export const NoAvailabilityStyled = styled.div`
  color: var(--caren-green);
  border: 1px solid var(--caren-green);
  margin-top: 8px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  padding: 15px 15px;
  width: 290px;
`;

export const Container = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const FindADoctor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    padding: 20px;
    font-size: 26px;
    color: var(--caren-grey);
    font-weight: bold;
  }
`;

export const FirstGrid = styled.div`
  border-right: 1px solid #cfd2d6;

  @media (max-width: 768px) {
    border: none;
  }
`;

export const SecondGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DoctorCard = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

export const DoctorImageContainer = styled.div`
  margin-right: 20px;
  text-align: 'center';

  @media (max-width: 1600px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const DoctorSpecialties = styled.li`
  display: inline-block;
  width: max-content;
  border: 1px solid #333;
  border-radius: 10px;
  margin: 5px 7px 0 0;
  padding: 5px;
`;

export const DoctorImage = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  height: 110px;
  width: 110px;
  border-radius: 50%;
  margin-right: 20px;

  @media (max-width: 1600px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const DoctorName = styled.div`
  color: #47525e;
  font-size: 23px;
  font-weight: bold;
  line-height: 33px;
`;

export const DoctorSpec = styled.div`
  color: #47525e;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
`;

export const SeeMore = styled.div`
  margin-top: 10px;
  color: var(--caren-lightgrey);
  cursor: pointer;
  text-align: center;
`;

export const DoctorNumber = DoctorSpec;

export const BorderGrey = styled.div`
  border: 1px solid #cfd2d6;
  width: 90%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DoctorSpecifications = styled.div`
  padding: 20px 10px;
  width: 90%;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DoctorTime = styled.div`
  margin-bottom: 10px;

  p {
    color: #47525e;
    font-size: 18px;
    font-weight: bold;
    line-height: 29px;
  }

  span {
    p {
      color: #47525e;
      font-weight: normal;
    }
  }
`;

export const DoctorPrice = DoctorTime;

export const DoctorPaymentMethods = DoctorTime;

export const DoctorBio = styled.div`
  margin-bottom: 10px;

  p {
    color: #47525e;
    font-size: 18px;
    font-weight: bold;
    line-height: 29px;
  }

  span {
    p {
      color: #47525e;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;

export const DoctorSkills = styled.div`
  p {
    color: #47525e;
    font-size: 18px;
    font-weight: bold;
    line-height: 29px;
  }

  li {
    color: #47525e;
    font-size: 18px;
    line-height: 29px;
    font-weight: normal;
  }
`;

export const DoctorSeeMore = styled.p`
  text-align: right;

  a {
    margin-right: 10px;
  }
`;

export const DatePickerText = styled.p`
  padding: 20px;
  font-size: 26px;
  color: var(--caren-grey);
  font-weight: bold;
`;

export const DoctorDate = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const DoctorHoursHeader = styled.p`
  margin-top: 12px;
  color: #bbb;
  text-align: center;
`;

export const DoctorHours = styled.div`
  color: ${({ isChosen }) =>
    isChosen ? 'var(--caren-white)' : 'var(--caren-green)'};
  background-color: ${({ isChosen }) =>
    isChosen ? 'var(--caren-calendar-selected-day)' : 'var(--caren-white)'};
  border: 1px solid var(--caren-green);
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  padding: 15px 15px;
  width: 290px;

  &:hover {
    cursor: pointer;
  }

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const DoctorHoursBox = styled.div`
  border-left: 1px solid #cfd2d6;
  padding-left: 20px;
  margin-right: 20px;
  height: 100%;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const DoctorInsuranceIcon = styled.img.attrs({
  src: fapesIcon,
  title: 'VC Saúde'
})`
  height: 20px;
  margin-left: 10px;
  color: var(--caren-red);
`;

export const LogoFapes = styled.img.attrs({
  src: fapesLogo
})`
  width: 200px;
`;

export const LogoVcSaude = styled.img.attrs({
  src: vcSaudeLogo
})`
  width: 200px;
  background-color: var(--caren-white);
`;

export const ModalSubtitle = styled.p`
  margin: 20px 0;
  text-align: center;
  font-size: 14px;
  color: var(--caren-grey);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  padding-left: 10px;
  margin: 5px 0;
  height: 40px;
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
`;

export const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
`;

export const ModalButton = styled.button`
  height: 40px;
  background-color: var(--caren-green);
  color: var(--caren-white);
  font-size: 20px;
  margin-top: 20px;
  border-radius: 10px;
`;

export const DoctorPickerContainer = styled.div`
  margin: 0 10%;
  width: 80%;
  z-index: 0;
  .MuiPickersCalendarHeader-daysHeader,
  .MuiPickersCalendar-week {
    justify-content: space-evenly;
  }
`;

export const PayContainerOpacity = styled.div`
  opacity: ${({ open }) => (open ? 0.4 : 0)};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  background: #343f4b;
  height: 100%;
  width: 100%;
  position: fixed;
  /* z-index: 0; */
  top: 0;
  transition: opacity 0.2s;
`;
