import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiVideo } from 'react-icons/fi';
import isEmpty from '../../../utils/isEmpty';
import NoConsults from '../../NoConsults';
import { FORMAT } from '../../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../../utils/moment/momentHelpers';
import {
  DoctorSchedule,
  DoctorTitleBox,
  DoctorTitle,
  PatientContent,
  PatientContentImage,
  PatientContentName,
  PatientContentDate,
  PatientContentClock,
  PatientContentCTA
  // PreAnswer,
  // PreAnswerIcon,
} from './styles';
import DoctorClock from '../DoctorClock';
import ImageOrName from '../../ImageOrName';

function NextDoctor({ firstAvailable }) {
  const { t } = useTranslation(['next_doctor']);
  const location = useLocation();

  const [_clockCompleted, setClockCompleted] = useState(false);

  function formatDate(date) {
    const today = momentUtcLocal();
    const tomorrow = today.clone().add(1, 'days');
    const consult = momentUtcLocal(date);
    const time = momentUtcLocal(date).format(FORMAT['hour-and-minute']);
    let stringDate = momentUtcLocal(date).format(FORMAT['pt-datetime']);

    if (consult.isSame(today, 'day')) {
      stringDate = `Hoje - ${time}`;
    } else if (consult.isSame(tomorrow, 'day')) {
      stringDate = `Amanhã - ${time}`;
    }

    return stringDate;
  }

  function maybeRenderInvitation() {
    if (isEmpty(firstAvailable)) {
      return <NoConsults title={t('Nenhuma consulta agendada')} />;
    }

    return (
      <PatientContent>
        <PatientContentImage>
          <Link
            to={firstAvailable?.invitation?.link}
            title="Confirmar consulta"
            aria-label="Confirmar consulta"
          >
            <ImageOrName
              src={firstAvailable?.employee?.avatar_url}
              title={firstAvailable?.employee?.name}
              titleSize={45}
              backgroundStyle={{
                width: 100,
                height: 100,
                borderRadius: '100%',
                backgroundColor: 'var(--caren-image-fallback)'
              }}
            />
          </Link>
        </PatientContentImage>
        <PatientContentName>
          <Link
            to={firstAvailable?.invitation?.link}
            title="Confirmar consulta"
            aria-label="Confirmar consulta"
          >
            <span>{firstAvailable?.employee?.name}</span>
          </Link>
        </PatientContentName>
        <PatientContentDate>
          {formatDate(firstAvailable.start_datetime)}
        </PatientContentDate>
        <PatientContentClock>
          <DoctorClock
            appointment={firstAvailable}
            onComplete={() => setClockCompleted(true)}
            className="patient-clock-orange"
          />

          <Link
            to={firstAvailable?.invitation?.link}
            title="Confirmar consulta"
            aria-label="Confirmar consulta"
          >
            Confirmar consulta
          </Link>
        </PatientContentClock>
      </PatientContent>
    );
  }

  return (
    <DoctorSchedule>
      <DoctorTitleBox>
        <DoctorTitle>{t('Próximo atendimento')}</DoctorTitle>
        {/* <DoctorBadgeCounter /> */}
      </DoctorTitleBox>

      {!firstAvailable.has_teleconsultation ? (
        maybeRenderInvitation()
      ) : (
        <PatientContent>
          <PatientContentImage>
            <Link
              to={{
                pathname: `/consultas/${firstAvailable.teleconsultation.key}/sala-de-espera`,
                state: { referrer: location.pathname }
              }}
              title="Entrar na sala de espera"
              aria-label="Entrar na sala de espera"
            >
              <ImageOrName
                src={firstAvailable?.employee?.avatar_url}
                title={firstAvailable?.employee?.name}
                titleSize={45}
                backgroundStyle={{
                  width: 100,
                  height: 100,
                  borderRadius: '100%',
                  backgroundColor: 'var(--caren-image-fallback)'
                }}
              />
            </Link>
          </PatientContentImage>
          <PatientContentName>
            <Link
              to={{
                pathname: `/consultas/${firstAvailable.teleconsultation.key}/sala-de-espera`,
                state: {
                  referrer: location.pathname
                }
              }}
              title="Entrar na sala de espera"
              aria-label="Entrar na sala de espera"
            >
              <span>{firstAvailable?.employee?.name}</span>
            </Link>
          </PatientContentName>
          <PatientContentDate>
            {formatDate(firstAvailable?.teleconsultation?.scheduled_datetime)}
          </PatientContentDate>
          {firstAvailable?.teleconsultation?.room_opened_at &&
            !firstAvailable?.teleconsultation?.room_closed_at && (
              <PatientContentCTA>
                <Link
                  to={`/consultas/${firstAvailable.teleconsultation.key}/sala-de-espera`}
                  target="_blank"
                >
                  <FiVideo />
                  <span>Voltar para Teleconsulta</span>
                </Link>
              </PatientContentCTA>
            )}

          {!firstAvailable?.teleconsultation?.room_opened_at &&
            !firstAvailable?.teleconsultation?.room_closed_at && (
              <PatientContentClock>
                <DoctorClock
                  consultation={firstAvailable.teleconsultation}
                  onComplete={() => setClockCompleted(true)}
                  className="patient-clock-orange"
                />

                <Link
                  to={{
                    pathname: `/consultas/${firstAvailable?.teleconsultation?.key}/sala-de-espera`,
                    state: {
                      referrer: location.pathname
                    }
                  }}
                  title="Entrar na sala de espera"
                  aria-label="Entrar na sala de espera"
                >
                  Entrar na sala de espera
                </Link>
              </PatientContentClock>
            )}
        </PatientContent>
      )}
    </DoctorSchedule>
  );
}

export default NextDoctor;
