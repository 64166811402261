import React, { createContext, useCallback, useEffect, useRef } from 'react';
// import { SelectedParticipantProvider } from './VideoProvider/useSelectedParticipant/useSelectedParticipant';

// eslint-disable-next-line import/no-cycle
import AttachVisibilityHandler from './VideoProvider/AttachVisibilityHandler/AttachVisibilityHandler';
// import useBackgroundSettings from './VideoProvider/useBackgroundSettings/useBackgroundSettings';
import useHandleRoomDisconnection from './VideoProvider/useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './VideoProvider/useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './VideoProvider/useLocalTracks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './VideoProvider/useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange';
import useRoom from './VideoProvider/useRoom/useRoom';
// import useScreenShareToggle from './VideoProvider/useScreenShareToggle/useScreenShareToggle';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export const VideoContext = createContext(null);

export function VideoProvider({ options, children, onError = () => {} }) {
  const onErrorCallback = useCallback(
    (error) => {
      onError(error);
    },
    [onError]
  );

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    audioTrackEnabled,
    videoTrackEnabled,
    setAudioTrackEnabled,
    setVideoTrackEnabled,
  } = useLocalTracks();
  const { room, roomCompleted, setRoomCompleted, isConnecting, connect } =
    useRoom(localTracks, onErrorCallback, options);

  /* Custom aggressive cleanup logic */
  // Store everything needed for the unmount logic in a ref
  // We cannot otherwise use them in the cleanup function of useEffect,
  // because they would need to be dependencies and the effect would trigger
  // every time they changed.
  const unmountDeps = useRef(null);

  useEffect(() => {
    unmountDeps.current = {
      room,
      localTracks,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
    };
  }, [room, localTracks, removeLocalVideoTrack, removeLocalAudioTrack]);

  // Unpublish and remove tracks when unmounting this component
  useEffect(
    () => () => {
      if (unmountDeps.current) {
        const localParticipant = unmountDeps.current.room?.localParticipant;
        const videoTrack = unmountDeps.current.localTracks.find(
          (track) => !track.name.includes('screen') && track.kind === 'video'
        );

        const audioTrack = unmountDeps.current.localTracks.find(
          (track) => track.kind === 'audio'
        );
        if (audioTrack) {
          const localAudioTrackPublication =
            localParticipant?.unpublishTrack(audioTrack);

          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant?.emit(
            'trackUnpublished',
            localAudioTrackPublication
          );
          unmountDeps.current.removeLocalAudioTrack();
        }

        if (videoTrack) {
          const localVideoTrackPublication =
            localParticipant?.unpublishTrack(videoTrack);

          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant?.emit(
            'trackUnpublished',
            localVideoTrackPublication
          );
          unmountDeps.current.removeLocalVideoTrack();
        }
      }
    },
    []
  );

  // const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack
    // isSharingScreen,
    // toggleScreenShare
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  // const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(
  //   false
  // );
  // const videoTrack = localTracks.find(
  //   (track) => !track.name.includes('screen') && track.kind === 'video'
  // );
  // const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(
  //   videoTrack,
  //   room
  // );

  return (
    <VideoContext.Provider
      value={{
        room,
        roomCompleted,
        setRoomCompleted,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        // isSharingScreen,
        // toggleScreenShare,
        getAudioAndVideoTracks,
        // isBackgroundSelectionOpen,
        // setIsBackgroundSelectionOpen,
        // backgroundSettings,
        // setBackgroundSettings,
        audioTrackEnabled,
        videoTrackEnabled,
        setAudioTrackEnabled,
        setVideoTrackEnabled,
      }}
    >
      {/* <SelectedParticipantProvider room={room}> */}
      {children}
      {/* </SelectedParticipantProvider> */}
      {/*
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler room={room} />
    </VideoContext.Provider>
  );
}
