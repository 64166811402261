import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useMediaQuery } from '@material-ui/core';

import ConnectionOptionsDialog from '../../ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../DeviceSelectionDialog';
import { useVideoAppState } from '../../../../hooks/video/useVideoAppState';

export default function SettingsMenu({ mobileButtonClass }) {
  const { roomType } = useVideoAppState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);

  const anchorRef = useRef(null);

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setMenuOpen(true)}
        startIcon={<MoreIcon style={{ fill: 'white' }} color="inherit" />}
        className={mobileButtonClass}
      />
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setDeviceSettingsOpen(true)}>
          <Typography variant="body1">Ajustes de Áudio e Vídeo</Typography>
        </MenuItem>
        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem onClick={() => setConnectionSettingsOpen(true)}>
            <Typography variant="body1">Ajustes de Conexão</Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
