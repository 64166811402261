import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../../../../../contexts/ToastContext';
import useEventListener from '../../../../../../../../hooks/useEventListener';
import { teleconsultationKeyService } from '../../../../../../../../services/anamnesis-detail.service';
import {
  concludePrescriptionService,
  deletePrescriptionService,
  startOrResumePrescriptionService
} from '../../../../../../../../services/mevo.service';
import {
  Container,
  WrapperLoading,
  MevoFrame,
  ModalContainer,
  ModalContent
} from './styles';
import { createPortal } from 'react-dom';

const MevoContainer = props => {
  const [loading, setLoading] = useState(true);
  const [frameLoading, setFrameLoading] = useState(true);
  const [modalUrl, setModalUrl] = useState('');
  const [teleconsultationKey, setTeleconsultationKey] = useState(null);
  const [prescriptionKey, setPrescriptionKey] = useState(null);

  const { t } = useTranslation(['mevo']);

  const { anamnesisKey, onCancel, onSubmit, popOut } = props;

  const toast = useToast();

  const handleMessage = useCallback(
    async event => {
      const validEvents = ['cancel', 'excluded', 'prescricao'];
      const { data: eventData } = event;

      if (!validEvents.includes(eventData.type)) return;

      switch (eventData.type) {
        case 'cancel': {
          onCancel();
          return;
        }

        case 'excluded':
          try {
            setLoading(true);
            await deletePrescriptionService(teleconsultationKey);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Erro ao deletar a prescrição.');
          }
          onCancel();
          return;

        case 'prescricao':
          try {
            setLoading(true);
            await concludePrescriptionService(prescriptionKey, eventData);
          } catch (error) {
            toast(t('Não foi possível registrar a prescrição.'), {
              variant: 'error'
            });
          }
          onSubmit();
          break;

        default:
          break;
      }
    },
    [
      onCancel,
      setLoading,
      toast,
      onSubmit,
      prescriptionKey,
      teleconsultationKey
    ]
  );

  useEventListener('message', handleMessage);

  const handleStartPrescription = async () => {
    try {
      const {
        teleconsultation: { key }
      } = await teleconsultationKeyService(anamnesisKey);

      setTeleconsultationKey(key);

      const {
        data: { key: createdPrescriptionKey, modal_url: newModalUrl }
      } = await startOrResumePrescriptionService(key);

      setPrescriptionKey(createdPrescriptionKey);
      setLoading(false);
      setModalUrl(newModalUrl);
    } catch (error) {
      const errorMessage = error?.response?.data?.error?.message || '';
      if (errorMessage.includes('CPF do médico')) {
        toast(
          t(
            "Não foi possível iniciar a prescrição: CPF inválido. Verifique em 'Meus Dados' se a informação está preenchida corretamente."
          ),
          {
            variant: 'error'
          }
        );
      } else {
        toast(
          t(
            "Não foi possível iniciar a prescrição. Verifique em 'Meus Dados' se possui CRM e Endereço cadastrados."
          ),
          {
            variant: 'error'
          }
        );
      }
      onCancel();
    }
  };

  useEffect(() => {
    handleStartPrescription();
  }, []);

  const onFrameLoaded = () => {
    setFrameLoading(false);
  };

  const renderMevoFrame = () => {
    if (loading) {
      return (
        <Container>
          <WrapperLoading>
            <span />
            <span />
          </WrapperLoading>
        </Container>
      );
    }

    if (popOut) {
      return createPortal(
        <ModalContainer openModal={!frameLoading}>
          <ModalContent>
            <MevoFrame src={modalUrl} onLoad={onFrameLoaded} />
          </ModalContent>
        </ModalContainer>,
        document.body
      );
    }

    return (
      <Container>
        <MevoFrame src={modalUrl} />
      </Container>
    );
  };

  return renderMevoFrame();
};

export default MevoContainer;
