import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DoctorClockContainer,
  DoctorClockDays,
  DoctorClockHours
} from './styles';

export default function DoctorClock({
  consultation,
  appointment = null,
  onComplete = () => {},
  className
}) {
  const [countdownCompleted, setCountdownCompleted] = useState(false);
  const { t } = useTranslation(['patient_panel']);
  const consultationTarget = consultation?.confirmation_at
    ? consultation?.scheduled_datetime
    : consultation?.confirmation_deadline;
  const target = consultationTarget ?? appointment?.start_datetime;

  useEffect(() => {
    if (!countdownCompleted) return;

    onComplete();
  }, [countdownCompleted]);

  if (target) {
    return (
      <DoctorClockContainer className={className}>
        <Countdown
          date={moment.utc(target).local().valueOf()}
          renderer={({
            formatted: { days, hours, minutes, seconds },
            completed
          }) => {
            if (!countdownCompleted && completed) {
              setCountdownCompleted(true);
            }

            return (
              <>
                <DoctorClockDays>
                  {days !== '00' ? `${days} ${t('dias')}` : ''}
                </DoctorClockDays>
                <DoctorClockHours>
                  {hours}:{minutes}:{seconds}
                </DoctorClockHours>
              </>
            );
          }}
        />
      </DoctorClockContainer>
    );
  }
  return null;
}
