import { Helmet } from 'react-helmet-async';
import CheckoutContextProvider from '../../v2/components/checkout/context';
import Header from '../../v2/components/checkout/header';
import PersonStep from '../../v2/components/checkout/steps/person';
import PaymentStep from '../../v2/components/checkout/steps/payment';
import ReviewStep from '../../v2/components/checkout/steps/review';
import { ReactComponent as LogoBlack } from '../../assets/images/logo-black.svg';
import { ReactComponent as PlanLogo } from '../../assets/images/plan.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../v2/components/inputs/buttons/Button';
import { getPlan, comparePlan } from '../../services/plan';
import { type Plan } from '../../v2/entities/plan';
import AddressStep from '../../v2/components/checkout/steps/address';
import formattedPrice from '../../utils/formattedPrice';
import ResumeDialog from '../../v2/dialogs/checkout/resume';
import { useAuth } from '../../hooks/AuthContext';

type CheckoutProps = {
  computedMatch: { params: { plan_key: string } };
};

type PlanComparison = {
  discount: number;
  discount_code: string | null;
  discount_code_applied: boolean | null;
  discount_code_exists: boolean | null;
  discount_refreshes_at: string;
  first_cycle_price: number | null;
  subscription_starts_at: string;
  current_subscription_key: string | null;
  type: 'regular' | 'upgrade' | 'downgrade' | 'equivalent';
  total: number;
  details: string[];
  discounts: Array<{
    name: string;
    type: 'discount_code' | 'upgrade';
    value: number;
  }>;
};

export default function Checkout({
  computedMatch: {
    params: { plan_key: planKey }
  }
}: CheckoutProps) {
  const { credentials } = useAuth();
  const history = useHistory();

  const [success, setSuccess] = useState(false);
  const [subscriptionKey, setSubscriptionKey] = useState<string | null>(null);
  const [discountCode, setDiscountCode] = useState<string | null>(null);
  const [plan, setPlan] = useState<Plan | null>(null);
  const [planComparison, setPlanComparison] = useState<PlanComparison | null>(
    null
  );

  const getPlanData = useCallback(async (key: string) => {
    try {
      const result = await getPlan(key);
      setPlan(result);
    } catch (e) {
      history.push('/');
    }
  }, []);

  const getPlanComparison = useCallback(
    async (key: string, discountCode: string | null) => {
      try {
        const params = {
          discount_code: discountCode,
          new_user: credentials?.token?.session_type !== 'authenticated'
        };
        const { data: result } = await comparePlan(key, params);
        setPlanComparison({ ...result.data, discount_code: discountCode });
      } catch (e) {
        history.push('/');
      }
    },
    [credentials]
  );

  const checkDiscountCode = (discountCode: string) => {
    // Changing discount code triggers recompare plan
    setDiscountCode(discountCode);
  };

  useEffect(() => {
    if (!planKey) {
      history.push('/');
    } else {
      if (plan === null) {
        getPlanData(planKey);
      }
    }
  }, [planKey, plan, getPlanData]);

  useEffect(() => {
    if (plan !== null) {
      getPlanComparison(planKey, discountCode);
    }
  }, [planKey, subscriptionKey, plan, discountCode, getPlanComparison]);

  const totalValue = planComparison?.total ?? plan?.price ?? 0;
  const totalValueString = (totalValue / 100).toLocaleString('pt-BR', {
    minimumFractionDigits: 2
  });

  return (
    <>
      <Helmet title="Checkout - Caren" />
      <div className="flex min-h-screen w-full flex-col items-stretch md:flex-row">
        <div className="flex h-full w-full flex-grow flex-col items-center p-5 md:p-14">
          {plan && (
            <>
              <a
                href="https://www.caren.app/tele"
                target="_blank"
                rel="noreferrer"
              >
                <LogoBlack className="hidden h-auto w-32 md:block md:self-start" />
              </a>
              <div className="flex items-center justify-between gap-x-4 sm:items-start sm:justify-start md:mt-14 md:flex-col md:items-center md:gap-x-0">
                {!success && (
                  <div className="flex gap-x-5 gap-y-2 md:mb-8 md:flex-col md:items-center">
                    <PlanLogo className="block h-16 w-auto shrink-0 md:h-24" />
                    <div className="flex flex-col gap-y-2 sm:gap-y-0 md:items-center">
                      <p className="block font-nunito text-xl font-semibold text-gray-950 sm:text-3xl md:text-4xl">
                        {plan.name}
                      </p>
                      <p className="max-w-xs font-nunito text-sm font-semibold text-gray-950 md:text-center md:text-lg">
                        {plan.description}
                      </p>
                    </div>
                  </div>
                )}
                {!success && (
                  <div className="flex flex-col md:items-center">
                    <p className="mb-2 font-nunito text-xl text-gray-950 sm:text-3xl md:text-center md:text-4xl">
                      {plan.interval === 'month'
                        ? formattedPrice(plan.price, plan.currency)
                        : formattedPrice(plan.price / 12, plan.currency)}
                    </p>
                    <p className="max-w-[14rem] font-nunito text-sm text-gray-350 md:mb-5  md:text-center md:text-base">
                      {plan.interval === 'month'
                        ? 'por profissional por mês'
                        : 'por profissional por mês, cobrado anualmente'}
                    </p>

                    <div className="mb-4 hidden w-full max-w-md flex-col rounded-lg border border-gray-200 bg-white pb-4 pt-6 font-nunito md:flex">
                      <div className="flex w-full items-center justify-between px-6 pb-4 text-sm text-gray-450">
                        <p>Valor do Plano</p>
                        <p>{formattedPrice(plan.price, plan.currency)}</p>
                      </div>
                      {planComparison &&
                        React.Children.toArray(
                          planComparison.discounts.map(discount => (
                            <div className="flex w-full items-center justify-between px-6 pb-4 text-sm text-success-light">
                              <p>
                                {discount.type === 'upgrade'
                                  ? 'Plano Anterior'
                                  : 'Desconto Cupom'}{' '}
                                {discount.type === 'discount_code' && (
                                  <>
                                    <br />
                                    {discount.name}
                                  </>
                                )}
                              </p>
                              <p>
                                -{' '}
                                {formattedPrice(discount.value, plan.currency)}
                              </p>
                            </div>
                          ))
                        )}
                      <footer className="flex w-full items-center justify-between border-t border-gray-200 px-6 pt-4 text-gray-450">
                        <p className="mt-2 text-sm font-bold uppercase">
                          Total
                        </p>
                        {planComparison && (
                          <p className="text-md font-medium">
                            <span>R$</span>
                            &nbsp;
                            <span className="text-2xl font-bold">
                              {totalValueString}
                            </span>
                          </p>
                        )}
                      </footer>
                    </div>

                    {planComparison && (
                      <div className="mb-4 hidden w-full max-w-md flex-col gap-y-4 rounded-lg border border-gray-200 bg-white px-6 py-5 font-nunito text-gray-450 md:flex">
                        <p className="text-base font-semibold">Detalhes</p>
                        {React.Children.toArray(
                          planComparison.details.map(item => (
                            <p className="text-sm">{item}</p>
                          ))
                        )}
                      </div>
                    )}

                    <p className="hidden max-w-xs text-center font-nunito text-sm text-blue-650 md:block">
                      Ao criar uma conta, você concorda com os nossos&nbsp;
                      <a
                        className="underline hover:text-yellow-750 hover:underline"
                        href="https://www.caren.app/paginas/termos-de-uso-telessaude"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Termos de Uso
                      </a>
                      &nbsp;e&nbsp;
                      <a
                        className="underline hover:text-yellow-750 hover:underline"
                        href="https://www.caren.app/paginas/aviso-de-privacidade"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Políticas de Privacidade
                      </a>
                    </p>
                  </div>
                )}
                {success && (
                  <div className="flex flex-col items-center">
                    <p className="mb-10 mt-10 max-w-sm text-center font-nunito text-5xl font-semibold text-blue-650">
                      {planComparison?.type === 'equivalent'
                        ? 'Informações atualizadas com sucesso!'
                        : 'Compra realizada com sucesso!'}
                    </p>
                    <p className="mb-20 max-w-sm text-center font-nunito text-base text-blue-650">
                      Enviaremos os dados da compra para seu e-mail. Agora é
                      só&nbsp;
                      <Link className="font-bold" to="/">
                        acessar a conta
                      </Link>
                      &nbsp;e iniciar os atendimentos com a nossa tecnologia.
                    </p>
                    <Button
                      label="Acessar minha conta!"
                      colors="success"
                      type="button"
                      size="large"
                      onClick={() => {
                        history.push('/');
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {!success && (
          <div className="flex h-full w-full shrink-0 flex-col items-center bg-white px-5 py-8 pb-32 md:min-h-screen md:w-[30rem] md:py-20 md:pb-20 xl:w-[42rem]">
            <CheckoutContextProvider>
              <Header />
              <p className="mt-12 font-nunito text-xl font-semibold text-blue-650 sm:mt-20 sm:text-4xl">
                Checkout Seguro
              </p>
              <PersonStep step={1} />
              <AddressStep step={2} />
              <PaymentStep
                planComparison={planComparison}
                step={3}
                onCheckDiscountCode={checkDiscountCode}
              />
              <ReviewStep
                step={4}
                plan={planKey}
                planComparison={planComparison}
                discountCode={discountCode}
                onSubscriptionCreate={data => {
                  setSubscriptionKey(data.key);
                }}
                onComplete={() => {
                  setSuccess(true);
                }}
              />
            </CheckoutContextProvider>
          </div>
        )}

        {plan && planComparison && (
          <ResumeDialog
            price={plan.price}
            discounts={planComparison.discounts}
            currency={plan.currency}
            total={planComparison.total}
            details={planComparison.details}
          />
        )}
      </div>
    </>
  );
}
