import useSWR from 'swr';
import { getCurrentPlan } from '../../services/plan';
import { SubscriptionSchema } from '../../entities/subscription';
import { z } from 'zod';

export function useCurrentPlan(isManager?: boolean) {
  const {
    data: response,
    error,
    isLoading
  } = useSWR<unknown>(
    isManager ? null : '/subscriptions/current',
    async () => await getCurrentPlan(),
    { refreshInterval: 60000 }
  );

  return {
    data:
      response !== undefined
        ? SubscriptionSchema.or(
            z.object({ status: z.literal('not_found') })
          ).parse(response)
        : undefined,
    isLoading,
    error
  };
}
