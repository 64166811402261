import styled from 'styled-components/macro';
import Logo from '../Logo';
import rightImage from '../../assets/images/footer-photo.png';

export const FooterContainer = styled.footer`
  padding: 0 3% 0 5%;
  height: 150px;
  /* background-color: #3b3d40; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .footer-text {
    color: var(--caren-white);
    opacity: 0.5;
    font-size: 11px !important;
    font-family: 'Nunito Sans', sans-serif;
  }

  .icons-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
`;

export const LogoFooter = styled(Logo).attrs({
  height: 40
})`
  margin-bottom: 10px 0;
  margin-left: -20px;
`;

export const RightContainer = styled.div`
  display: flex;
`;

export const RightMainContent = styled.div`
  position: relative;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    border-color: var(--caren-white);
    color: var(--caren-white);
    background-color: transparent;
    border-radius: 5px;
    height: 40px;
    min-width: 128px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;

    &:after {
      border-top-color: var(--caren-white);
    }

    &[aria-expanded='true']:after {
      border-bottom-color: var(--caren-white);
    }
  }

  ul {
    bottom: 40px;
  }
`;

export const RightImage = styled.img.attrs({
  src: rightImage
})`
  height: 75px;

  @media (max-width: 430px) {
    & {
      display: none;
    }
  }
`;
