import styled from 'styled-components/macro';

export const DoctorsList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  & > div.title {
    grid-column: 1 / -1;
  }

  & > button.doctor {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border-bottom: 1px solid var(--caren-input-border);
    border-radius: 4px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    background-color: #fff;

    &:hover {
      border-color: var(--caren-green);
      background-color: #ededed;
    }

    & > p {
      font-size: 16px;
    }
  }
`;

export const Card = styled.div`
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  padding: 7px 40px 7px 7px;

  .profile-pic {
    margin-right: 25px;
  }

  .profile-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #969faa;
  }
`;

export const CardGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

export const GridItem = styled.div`
  width: 100%;
  background: white;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;

  .title {
    color: #969faa;
    font-size: 12px;
    margin-bottom: 35px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  .count {
    color: #969faa;
    font-size: 28px;
  }

  .count-text {
    font-size: 24px;
  }

  .completed {
    color: #969faa;
    font-size: 20px;
  }

  .count-item {
    display: flex;
    justify-content: space-between;
    color: #969faa;
    font-size: 12px;
    align-items: center;
  }

  .female {
    border-bottom: 3px solid #969faa;
  }

  .count-mid-block {
    width: 50%;
  }
`;
