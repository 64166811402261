import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import EmojiFoodBeverage from '@mui/icons-material/EmojiFoodBeverage';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import timeAgoPTBRStrings from 'react-timeago/lib/language-strings/pt-br';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import {
  Avatar,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';

import {
  readNotificationsService,
  readAllNotificationsService,
  unreadNotificationsService,
  readOnlyOneNotificationService
} from '../../../services/notification.service';
import { teleconsultationKeyService } from '../../../services/anamnesis-detail.service';
import history from '../../../services/history';

import { useToast } from '../../../contexts/ToastContext';
import { useAuth } from '../../../hooks/AuthContext';
import { ROLE_PROFESSIONAL } from '../../../routes/constants';

import UnreversibleConfirmationModal from '../../UnreversibleConfirmationModal';

import {
  FLOW_ICONS,
  NotificationBox,
  NotificationBoxTitle,
  NotificationBoxContent,
  NotificationBoxCTA,
  NotificationBoxLoading,
  NotificationBoxLoadingSpinner,
  NotificationTabs,
  NotificationTab,
  NotificationNavigation
} from '../header.styles';

const Notifications = ({ onUpdateCounter }) => {
  const { credentials } = useAuth();

  const toast = useToast();
  const location = useLocation();
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [loadedAll, setLoadedAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readNotifications, setReadNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [tab, setTab] = useState(0);
  const [counter, setCounter] = useState(0);

  const readAllNotifications = confirmation => {
    if (confirmation) {
      setCounter(0);
      onUpdateCounter();
      setLoading(true);
      setTimeout(() => setLoading(false), 1000);

      let content = [];

      if (readNotifications) {
        content = [...unreadNotifications, ...readNotifications];
      } else {
        content = [...unreadNotifications];
      }

      setOpenUnreversible(false);
      readAllNotificationsService();
      setReadNotifications(content);
      setUnreadNotifications(false);
    } else {
      setOpenUnreversible(false);
    }
  };

  useEffect(() => {
    if (!unreadNotifications && !loadedAll) {
      setLoading(true);

      unreadNotificationsService().then(result => {
        if (result.counter !== 0) {
          setCounter(result.counter);
          setUnreadNotifications(result.data);
        }

        setTimeout(() => setLoading(false), 1000);
      });
    }
  }, []);

  useEffect(() => {
    if (tab === 1 && !loadedAll) {
      setLoading(true);
      setLoadedAll(true);

      readNotificationsService().then(result => {
        setReadNotifications(result.data);

        setTimeout(() => setLoading(false), 1000);
      });
    }
  }, [tab]);

  const parseUtcTimestamps = (string, format = 'DD/MM/YYYY [-] HH:mm') => {
    const time = string.replace(
      /\[UTC\](.*?)\[\/UTC\]/,
      (_match, isoTimestamp) => moment.utc(isoTimestamp).local().format(format)
    );

    return time;
  };

  const requestKey = id => {
    setLoading(true);

    teleconsultationKeyService(id)
      .then(result => {
        const key = result?.teleconsultation?.key;
        let url;

        if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
          url = `/consultas/${key}/anamnese/${id}`;
        } else {
          url = `/consultas/${key}/sala-de-espera`;
        }

        setTimeout(() => history.push(url), 1000);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch(() => {
        toast('Ops, não foi possível carregar as informações...', {
          variant: 'error'
        });

        setTimeout(() => setLoading(false), 1000);
      });
  };

  const renderNotificationPaper = (notification, type) => {
    const IconComponent = FLOW_ICONS[notification.icon];
    const notificationTextLines = parseUtcTimestamps(notification.body)
      .split('\n')
      .filter(Boolean);
    const fullDate = moment
      .utc(notification.inserted_at)
      .local()
      .format('DD/MM/YYYY [-] HH:mm');

    const readNotification = () => {
      if (type === 'unread') {
        const unreadContent = [];
        let readContent = [];

        unreadNotifications.forEach(item => {
          if (item.key === notification.key && !item.read) {
            item.read = true;

            setCounter(counter - 1);
            onUpdateCounter();
          }

          unreadContent.push(item);
        });

        if (readNotifications) {
          readContent = [notification, ...readNotifications];
        } else {
          readContent = [notification];
        }

        setReadNotifications(readContent);
        setUnreadNotifications(unreadContent);
        readOnlyOneNotificationService(notification.key);
      }
    };

    let action;
    if (notification.link) {
      if (notification.link.includes('appointment')) {
        const id = notification.link.replace('appointment::', '');

        action = (
          <button
            type="button"
            className="notification-list-action"
            title="Ir para a página"
            aria-label="Ir para a página"
            onClick={() => requestKey(id)}
          />
        );
      }
      if (notification.link.includes('teleconsultation')) {
        const id = notification.link.replace('teleconsultation::', '');

        action = (
          <Link
            to={{
              pathname: `/consultas/${id}/sala-de-espera`,
              state: {
                referrer: location.pathname
              }
            }}
            className="notification-list-action"
            title="Entrar na sala de espera"
            aria-label="Entrar na sala de espera"
          />
        );
      }
    }

    return (
      <>
        <ListItem
          className={`notification-item ${notification.read ? 'read' : ''}`}
          alignItems="flex-start"
          onClick={() => readNotification()}
        >
          {action}

          <ListItemAvatar>
            <Avatar>
              <IconComponent />
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            primary={notificationTextLines.map(textLine => (
              <Typography variant="body1" color="textPrimary">
                {textLine}
              </Typography>
            ))}
            secondary={
              <>
                <div className="notification-date-primary">
                  <TimeAgo
                    date={moment.utc(notification.inserted_at).local()}
                    formatter={buildFormatter(timeAgoPTBRStrings)}
                  />
                </div>

                <div className="notification-read-tooltip">
                  Marcada como lida
                </div>

                <div className="notification-date-secondary">{fullDate}</div>

                {notification?.forwarded_from && (
                  <div>(encaminhada de {notification?.forwarded_from})</div>
                )}
              </>
            }
          />
        </ListItem>

        <Divider variant="inset" component="li" />
      </>
    );
  };

  return (
    <NotificationBox className={'active'}>
      <NotificationBoxLoading className={loading ? 'active' : ''}>
        <NotificationBoxLoadingSpinner>
          <span />
          <span />
        </NotificationBoxLoadingSpinner>
      </NotificationBoxLoading>

      {counter === 0 ? (
        <NotificationBoxTitle>Notificações</NotificationBoxTitle>
      ) : (
        <NotificationBoxTitle>Notificações ({counter})</NotificationBoxTitle>
      )}

      <NotificationNavigation>
        <ul>
          <li className={tab === 0 ? 'active' : ''}>
            <button
              type="button"
              title="Selecionar opção"
              aria-label="Selecionar opção"
              onClick={() => setTab(0)}
            >
              Não lidas
            </button>
          </li>

          <li className={tab === 1 ? 'active' : ''}>
            <button
              type="button"
              title="Selecionar opção"
              aria-label="Selecionar opção"
              onClick={() => setTab(1)}
            >
              Lidas
            </button>
          </li>
        </ul>
      </NotificationNavigation>

      <NotificationTabs>
        <NotificationTab className={tab === 0 ? 'active' : ''}>
          {unreadNotifications ? (
            <NotificationBoxContent>
              <List>
                {unreadNotifications.map(notification =>
                  renderNotificationPaper(notification, 'unread')
                )}
              </List>
            </NotificationBoxContent>
          ) : (
            <div className="no-results">
              <EmojiFoodBeverage />
              Todas as notificações foram lidas!
            </div>
          )}
        </NotificationTab>

        <NotificationTab className={tab === 1 ? 'active' : ''}>
          {readNotifications ? (
            <NotificationBoxContent>
              <List>
                {readNotifications.map(notification =>
                  renderNotificationPaper(notification, 'read')
                )}
              </List>
            </NotificationBoxContent>
          ) : (
            <div className="no-results">Nenhuma notificação até o momento.</div>
          )}
        </NotificationTab>
      </NotificationTabs>

      <NotificationBoxCTA
        type="button"
        title="Ler todas"
        aria-label="Ler todas"
        className={tab === 0 && counter >= 1 ? '' : 'disabled'}
        onClick={() => setOpenUnreversible(true)}
      >
        Ler todas
      </NotificationBoxCTA>

      <UnreversibleConfirmationModal
        title="Tem certeza que deseja ler todas as notificações?"
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={readAllNotifications}
      />
    </NotificationBox>
  );
};

export default Notifications;
