import React, { useReducer } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import ptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

import { ToastProvider } from './contexts/ToastContext';
import { AuthProvider } from './hooks/AuthContext';
import CustomMuiThemeProvider from './components/CustomMuiThemeProvider';
import { Store } from './store';
import modules from './store/modules';
import Routes from './routes';
import GlobalStyle from './styles/global';
import EmergencyPatientProvider from './components/EmergencyPatient/emergencyPatientProvider';
import { SocketProvider } from './hooks/SocketContext';
import SessionUpdatedDialog from './components/SessionUpdatedDialog';

const { Provider } = Store;

function App() {
  const store = useReducer(modules, {
    sidebarStatus: false,
    user: {},
    query: {},
    doctor: {},
    notifications: {
      unread: 0,
      lastRefresh: null
    }
  });

  return (
    <>
      <Provider value={store}>
        <ToastProvider
          maxSnack={2}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          preventDuplicate
        >
          <AuthProvider>
            <SocketProvider>
              <CustomMuiThemeProvider>
                <EmergencyPatientProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <Routes />
                  </MuiPickersUtilsProvider>
                </EmergencyPatientProvider>
              </CustomMuiThemeProvider>
            </SocketProvider>
            <SessionUpdatedDialog />
          </AuthProvider>
        </ToastProvider>
      </Provider>
      <GlobalStyle />
    </>
  );
}

export default App;
