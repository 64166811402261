import { z } from 'zod';

export const BadgeSchema = z.object({
  badge: z.object({
    render_color: z.string().nullable(),
    render_label: z.string().nullable()
  }),
  category: z.object({
    allow_multiple: z.boolean(),
    code: z.string(),
    editable: z.boolean(),
    name: z.string(),
    render_color: z.string().nullable(),
    render_type: z.string(),
    type: z.string()
  }),
  code: z.string(),
  description: z.string().nullable(),
  detail: z.string().nullable(),
  inserted_at: z.string(),
  key: z.string().uuid(),
  name: z.string(),
  render_color: z.string().nullable(),
  short_name: z.string().nullable()
});

export type Badge = z.infer<typeof BadgeSchema>;
