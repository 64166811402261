import React, { useEffect, useState } from 'react';
import ImageUploader from 'react-images-upload';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';

import {
  Container,
  ImageContainer,
  DoctorLogo,
  CameraIcon,
  InfoContainer,
  ImageContainerLoading,
  ImageContainerSpinner,
  DoctorName,
  // DoctorSpec,
  NextDoctorContainer
} from './styles';
import { changeUserPictureService } from '../../services/user.service';
import NextDoctor from './NextDoctor';
import { useAuth } from '../../hooks/AuthContext';
import InviteFooter from '../InviteFooter';
import { listPatientAppointmentsService } from '../../services/patient-service';
import { ROLE_PATIENT } from '../../routes/constants';
import moment from 'moment';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';

function PatientPanel({ inviteFriend = false }) {
  const { credentials, refreshSession } = useAuth();

  const { t } = useTranslation(['patient_panel']);

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(
    credentials?.session?.avatar_url
  );

  async function onDrop(img) {
    const newImage = img[0];

    setLoading(true);

    try {
      const { data: picData } = await changeUserPictureService(newImage);
      toast(t('Foto atualizada com sucesso!'), { variant: 'success' });
      if (credentials) {
        refreshSession(credentials.token);
      }
      setProfileImage(picData.avatar_url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Not shown
    }
  }

  const [firstAvailable, setFirstAvailable] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function getAllSchedules() {
      try {
        const { data } = await listPatientAppointmentsService();

        if (data) {
          const first = data
            .sort(
              (a, b) =>
                new Date(a?.start_datetime) - new Date(b?.start_datetime)
            )
            .find(
              item =>
                !item?.teleconsultation?.room_closed_at &&
                !item?.teleconsultation?.expired &&
                !item.cancelled_at &&
                momentUtcLocal(item.end_datetime).isAfter(moment())
            );
          setFirstAvailable({ ...first });
          setLoaded(true);
        }
      } catch (error) {
        toast(t('Ops, não foi possível carregar o histórico...'), {
          variant: 'error'
        });
      }
    }

    if (
      credentials &&
      credentials.token.user_type === ROLE_PATIENT &&
      credentials.token.session_type === 'authenticated'
    ) {
      getAllSchedules();
    }
  }, []);

  return (
    <Container>
      <ImageContainer>
        <DoctorLogo
          src={profileImage ?? credentials?.session.avatar_url}
          title={credentials?.session.name}
          titleSize={45}
          backgroundStyle={{
            width: 124,
            height: 124,
            borderRadius: '100%',
            backgroundColor: 'var(--caren-image-fallback)'
          }}
        />

        <ImageUploader
          withIcon={false}
          onChange={onDrop}
          buttonText={loading ? '' : <CameraIcon />}
          buttonStyles={{
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0
          }}
          fileContainerStyle={{
            position: 'absolute',
            right: 0,
            bottom: -32,
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
          withLabel={false}
          imgExtension={['.jpg', '.png', 'jpeg']}
          singleImage
          fileSizeError={t('Arquivo é muito grande')}
          fileTypeError={t('Este arquivo não é válido')}
        />

        <ImageContainerLoading className={loading ? 'active' : ''}>
          <ImageContainerSpinner>
            <span />
            <span />
          </ImageContainerSpinner>
        </ImageContainerLoading>
      </ImageContainer>
      <InfoContainer>
        <DoctorName>{credentials?.session.name}</DoctorName>
        {/* <DoctorSpec>{formattedSpecialties(session.specialties)}</DoctorSpec> */}
      </InfoContainer>
      <NextDoctorContainer>
        <NextDoctor loaded={loaded} firstAvailable={firstAvailable} />
        {inviteFriend && <InviteFooter />}
      </NextDoctorContainer>
    </Container>
  );
}

export default PatientPanel;
