import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { CalendarIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { ConsultDialogContext } from '../context';
import ImageOrName from '../../../../components/ImageOrName';
import { useAuth } from '../../../../hooks/AuthContext';
import { type Doctor } from '../../../entities/doctor';
import { type PatientFormData } from '../../../entities/consult';
import { type CalendarEventResume } from '../elements/calendar';

const Step = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      margin: 50px 40px 0;
      font-size: 14px;
      font-weight: bold;
      color: var(--caren-grey);
      text-align: center;
    }
  }

  > footer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (max-width: 636px) {
      width: 100%;
    }

    > div {
      display: flex;
      align-items: center;
      column-gap: 20px;

      @media (max-width: 636px) {
        width: 100%;
        column-gap: 10px;
      }

      button {
        color: var(--color-icongrey);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 250px;
        height: 40px;
        padding: 0 20px;
        border-radius: 5px;
        text-transform: uppercase;
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        @media (max-width: 636px) {
          min-width: unset;
          width: 100%;
          padding: 6px 10px;
          font-size: 14px;
        }

        &:disabled {
          filter: grayscale(1);
          cursor: not-allowed;
        }

        &.next {
          background-color: #fdbf71;
          &:hover {
            background-color: #077a94;
          }
        }

        &.back {
          min-width: 150px;
          background-color: var(--caren-lightgrey);
          color: var(--caren-white);

          @media (max-width: 636px) {
            min-width: unset;
          }

          &:hover {
            filter: brightness(115%);
          }
        }
      }
    }
  }
`;

const InformationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  padding: 10px;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 10px;

  @media (max-width: 636px){
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;

    &.user {
      width: 32px;
      height: 32px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;

    .doctor-title {
      margin-top 10px;
    }
  }
`;

type ReviewStepProps = {
  step: number;
};

export default function ReviewStep({ step }: ReviewStepProps) {
  const dialogContext = useContext(ConsultDialogContext);

  const { credentials } = useAuth();

  const [doctorInfo, setDoctorInfo] = useState<{
    name: string;
    avatar?: string | null;
  } | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dialogContext && doctorInfo === null && credentials) {
      if (dialogContext.isManager) {
        if (dialogContext.steps.has(1)) {
          const doctor = dialogContext.steps.get(1)?.value as Doctor;
          setDoctorInfo({ avatar: doctor.avatar_url, name: doctor.name });
        }
      } else {
        setDoctorInfo({
          avatar: credentials.session.avatar_url,
          name: credentials.session.name
        });
      }
    }
  }, [credentials, dialogContext, doctorInfo]);

  if (dialogContext === null || dialogContext.actualStep !== step) return null;

  const calendarEventResume = dialogContext.steps.get(
    dialogContext.isManager ? 2 : 1
  )?.value as CalendarEventResume;

  return (
    <Step>
      <div>
        <p>
          Antes de criar o agendamento revise as informações da consulta e em
          seguida clique no botão &quot;Enviar Convite&quot; logo abaixo.
        </p>
        <InformationWrapper className="bg-gray-200">
          {doctorInfo && (
            <div>
              <ImageOrName
                src={doctorInfo.avatar}
                title={doctorInfo.name}
                titleSize={16}
                backgroundStyle={{
                  width: 50,
                  height: 50,
                  borderRadius: '100%',
                  backgroundColor: 'var(--caren-image-fallback)'
                }}
              />
              <p className="doctor-title">
                <strong>{doctorInfo.name}</strong>
              </p>
            </div>
          )}
          <div>
            <CalendarIcon />
            <p>
              <strong>Dia: </strong>
              {calendarEventResume.text.day}
            </p>
            <p>
              <strong>Horário de início: </strong>
              {calendarEventResume.text.startTime}
            </p>
            {calendarEventResume.text.startTime !==
              calendarEventResume.text.endTime && (
              <p>
                <strong>Horário de término: </strong>
                {calendarEventResume.text.endTime}
              </p>
            )}
          </div>
          <div>
            <UserCircleIcon className="user" />
            <p>
              <strong>CPF: </strong>
              {
                (
                  dialogContext.steps.get(dialogContext.isManager ? 3 : 2)
                    ?.value as PatientFormData
                ).fiscal_code
              }
            </p>
            <p>
              <strong>Email: </strong>
              {
                (
                  dialogContext.steps.get(dialogContext.isManager ? 3 : 2)
                    ?.value as PatientFormData
                ).email
              }
            </p>
          </div>
        </InformationWrapper>
      </div>
      <footer>
        <div>
          <button
            className="back"
            type="button"
            onClick={() => {
              dialogContext.backwardStep();
            }}
          >
            Voltar
          </button>
          <button
            className="next"
            type="button"
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              await dialogContext.send();
              setLoading(false);
            }}
          >
            Enviar Convite
          </button>
        </div>
      </footer>
    </Step>
  );
}
