import { z } from 'zod';
import { PlanSchema } from './plan';

export const SubscriptionSchema = z.object({
  key: z.string(),
  plan: PlanSchema,
  status: z.string(),
  start_at: z.string(),
  next_billing_at: z.string(),
  payment_method: z.string(),
  current_invoice: z.object({
    status: z.string(),
    amount: z.number(),
    total_discount: z.number(),
    total_increment: z.number(),
    subscription_cycle: z.number(),
    due_at: z.string(),
    inserted_at: z.string()
  })
});

export type Subscription = z.infer<typeof SubscriptionSchema>;
