import React, { useMemo } from 'react';
import {
  MuiThemeProvider as LegacyThemeProvider,
  createTheme as legacyCreateTheme,
} from '@material-ui/core/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PROFESSIONAL } from '../../routes/constants';
import { CAREN_BLUE, CAREN_GREEN, CAREN_GREENBLUE } from '../../styles/global';
import { BASE_THEME } from '../../constants/style';

export default function CustomMuiThemeProvider({ children }){
  const {
    credentials,
  } = useAuth();

  const theme = useMemo(() => {
    if (credentials && credentials.token.user_type === ROLE_PROFESSIONAL) {
      return createTheme(
        {
          ...BASE_THEME,
          palette: {
            primary: { main: CAREN_GREENBLUE },
            secondary: { main: CAREN_BLUE },
          },
        },
        ptBR
      );
    }

    return createTheme(
      {
        palette: {
          ...BASE_THEME,
          primary: { main: CAREN_GREEN },
          secondary: { main: CAREN_BLUE },
        },
      },
      ptBR
    );
  }, [credentials]);

  const legacyTheme = useMemo(() => {
    if (credentials && credentials.token.user_type === ROLE_PROFESSIONAL) {
      return legacyCreateTheme({
        ...BASE_THEME,
        palette: {
          primary: { main: CAREN_GREENBLUE },
          secondary: { main: CAREN_BLUE },
        },
      });
    }

    return legacyCreateTheme({
      palette: {
        ...BASE_THEME,
        primary: { main: CAREN_GREEN },
        secondary: { main: CAREN_BLUE },
      },
    });
  }, [credentials]);

  return (
    <ThemeProvider theme={theme}>
      <LegacyThemeProvider theme={legacyTheme}>{children}</LegacyThemeProvider>
    </ThemeProvider>
  );
}
