import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import moment from 'moment';
import Participant from '../Participant';
import { WaitingForRemote } from './styles';
import { ROLE_PROFESSIONAL } from '../../../routes/constants';
import { useToast } from '../../../contexts/ToastContext';
import UnreversibleConfirmationModal from '../../UnreversibleConfirmationModal';
import { useAuth } from '../../../hooks/AuthContext';
import { closeConsultationService } from '../../../services/close-consultation.service';
import { ParticipantAudioTracks } from '../ParticipantAudioTracks';
import useVideoContext from '../../../hooks/video/useVideoContext';
import useParticipants from '../../../hooks/video/useParticipants';
import { useVideoAppState } from '../../../hooks/video/useVideoAppState';
import ParticipantRoom from './room';
import { getTeleconsultationRunningDuration } from '../../../services/consults';

export const Room = ({ onUserLeave }) => {
  const { credentials } = useAuth();
  const { t } = useTranslation(['video_call']);
  const toast = useToast();
  const { room, setRoomCompleted } = useVideoContext();
  const { consultation, refreshConsultation } = useVideoAppState();
  const participants = useParticipants();

  const [endConfirmationOpen, setEndConfirmationOpen] = useState(false);
  const [roomRunningDuration, setRoomRunningDuration] = useState(null);

  const handleDisconnect = () => {
    room.disconnect();
  };

  const RemoteParticipant = () => {
    const participantData =
      credentials?.token?.user_type === ROLE_PROFESSIONAL
        ? consultation?.patient
        : consultation?.doctor;

    if (
      participants &&
      ((participants[0] && participants[0].state === 'disconnected') ||
        (participants.length === 0 &&
          consultation.close_by_absence_in !== null))
    ) {
      return (
        <WaitingForRemote>
          {`${participantData?.name} se desconectou.`}
          <br />
          <div className="mx-auto w-2/3">
            Essa consulta irá se encerrar automaticamente se não houver
            reconexão em&nbsp;
          </div>
          <div className="mt-6 text-7xl text-error">
            <Countdown
              date={moment(consultation.refreshAt)
                .add(consultation.close_by_absence_in, 'milliseconds')
                .valueOf()}
              renderer={({ formatted: { minutes, seconds } }) => (
                <>
                  {minutes}:{seconds}
                </>
              )}
            />
          </div>
        </WaitingForRemote>
      );
    }

    if (participants && participants.length === 0) {
      return (
        <WaitingForRemote>
          {`${t('Aguardando a entrada de')} \n${participantData?.name}...`}
        </WaitingForRemote>
      );
    }

    const onlyFirstRemote =
      participants?.map(participant => (
        <Participant
          key={participant.sid}
          name={participantData?.name}
          avatarUrl={participantData?.avatar_url}
          participant={participant}
        />
      )) ?? [];

    return [onlyFirstRemote];
  };

  const handleLogOut = () => {
    setEndConfirmationOpen(true);
  };

  const handleCallEnd = async confirmed => {
    if (!confirmed) {
      return setEndConfirmationOpen(false);
    }

    if (credentials && credentials.token?.user_type === ROLE_PROFESSIONAL) {
      try {
        const { data: _data } = await closeConsultationService(
          consultation.key
        );
        setRoomCompleted(true);
        return handleDisconnect();
      } catch (error) {
        toast(
          t('Não foi possível encerrar a consulta. Por favor tente novamente.'),
          {
            variant: 'error'
          }
        );
        return null;
      }
    }

    onUserLeave();
    return handleDisconnect();
  };

  const getRoomRunningDuration = useCallback(async () => {
    if (
      credentials?.token &&
      credentials.token.user_type === ROLE_PROFESSIONAL
    ) {
      try {
        const { data } = await getTeleconsultationRunningDuration(
          consultation.key
        );

        setRoomRunningDuration(data.duration);
      } catch (error) {
        toast(t('Não foi possível carregar a duração atual da consulta.'), {
          variant: 'error'
        });
        setRoomRunningDuration(0);
      }
    }
  }, [consultation.key, credentials, t, toast]);

  useEffect(() => {
    if (
      participants &&
      participants[0] &&
      participants[0].state === 'disconnected'
    ) {
      refreshConsultation();
    }

    if (
      participants &&
      participants[0] &&
      participants[0].state !== 'disconnected' &&
      consultation.close_by_absence_at !== null
    ) {
      refreshConsultation();
    }
  }, [consultation.close_by_absence_at, participants, refreshConsultation]);

  useEffect(() => {
    if (roomRunningDuration === null) {
      getRoomRunningDuration();
    }
  }, [getRoomRunningDuration, roomRunningDuration]);

  return (
    <div className="relative flex h-screen w-full items-center justify-center bg-yellow-180">
      <ParticipantAudioTracks />
      <ParticipantRoom
        isDoctor={credentials?.token?.user_type === ROLE_PROFESSIONAL}
        room={room}
        session={credentials?.session}
        sessionToken={credentials?.token}
        handleDisconnectCall={handleLogOut}
        consultation={consultation}
        remoteParticipantCam={RemoteParticipant}
        remoteIsConnected={
          participants &&
          participants[0] &&
          participants[0].state === 'connected'
        }
        roomRunningDuration={roomRunningDuration}
      />

      <UnreversibleConfirmationModal
        title={
          credentials?.token?.user_type === ROLE_PROFESSIONAL
            ? t(
                'Ao clicar em confirmar, a consulta será ENCERRADA e não será possível retomá-la.\n\nVocê tem certeza?'
              )
            : t('Você tem certeza de que deseja sair da consulta?')
        }
        openModal={endConfirmationOpen}
        setOpenModal={setEndConfirmationOpen}
        handleConfirmation={handleCallEnd}
      />
    </div>
  );
};
