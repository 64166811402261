import React, { createContext, useContext, useReducer, useState } from 'react';
import { settingsReducer, initialSettings } from './settings';
import useActiveSinkId from '../useActiveSinkId';
import { type Consult } from '../../../v2/entities/consult';

type VideoAppContext = {
  error: null
  setError: React.Dispatch<React.SetStateAction<null>>
  mediaError: undefined
  setMediaError: React.Dispatch<React.SetStateAction<undefined>>
  token?: string
  consultation?: Consult
  refreshConsultation?: () => Promise<void>
  roomType: string
  activeSinkId: string | ((sinkId: any) => void)
  setActiveSinkId: string | ((sinkId: any) => void)
  settings: any
  dispatchSetting: React.DispatchWithoutAction
}

export const StateContext = createContext<null | VideoAppContext>(null);

type VideoAppStateProviderProps = {
  children: React.ReactNode
  token?: string
  consultation?: Consult
  refreshConsultation?: () => Promise<void>
}

export default function VideoAppStateProvider({
  children,
  token,
  consultation,
  refreshConsultation
}: VideoAppStateProviderProps) {
  const [error, setError] = useState(null);
  const [mediaError, setMediaError] = useState();
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );

  const roomType = 'peer-to-peer';

  const contextValue = {
    error,
    setError,
    mediaError,
    setMediaError,
    token,
    consultation,
    refreshConsultation,
    roomType,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {children}
    </StateContext.Provider>
  );
}

export function useVideoAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(
      'useVideoAppState must be used within the VideoAppStateProvider'
    );
  }
  return context;
}
