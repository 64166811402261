import React, { useContext, useEffect, useState } from 'react';
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import ptBR from 'date-fns/locale/pt-BR';
import styled from 'styled-components/macro';
import groupBy from 'lodash.groupby';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import { doctorFutureAvailabilitiesService } from '../../services/future-availabilities.service';
import { Store } from '../../store';
import { setQuery } from '../../store/modules/Query/actions';
import { FORMAT } from '../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';

const DayButton = styled.div``;

const useStyles = makeStyles({
  day: {
    '& button': {
      color: '#000',
    },
    margin: '5px 0',
  },
  available: {
    '& button': {
      backgroundColor: 'var(--caren-calendar-available-day)',
      borderRadius: '50%',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
  selected: {
    '& button': {
      color: '#fff',
      backgroundColor: 'var(--caren-calendar-selected-day)',

      '&:hover': {
        backgroundColor: 'var(--caren-lightgreen)',
      },
    },
  },
});

function formatDate(date) {
  return momentUtcLocal(date).format(FORMAT['us-date']);
}

function startEndMonthDays(date) {
  const today = new Date();
  const tomorrow = new Date(Number(today));
  tomorrow.setDate(today.getDate() + 1);

  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = moment(firstDay).endOf('month').toDate();

  // Prevent showing dates in the past
  const effectiveFirst = moment
    .utc(date)
    .local()
    .startOf('day')
    .format(FORMAT['us-date']);

  return [effectiveFirst, formatDate(lastDay)];
}

function CustomCalendar({
  setSlotCards = false,
  doctorId = false,
  futureOnly = false,
  sortedByDate = true,
  type = 'default',
}) {
  const { t } = useTranslation(['common']);
  const [date, changeDate] = useState(new Date());
  const [daysWithDot, setDaysWithDot] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(() => new Date());
  const classes = useStyles();

  const [, dispatch] = useContext(Store);
  const toast = useToast();

  useEffect(() => {
    async function getDoctorFutureAvailabilitiesService() {
      const [from, to] = startEndMonthDays(currentMonth);
      dispatch(setQuery({ from, to }));

      try {
        if (setSlotCards) setSlotCards({ loading: true });

        let { data } = await doctorFutureAvailabilitiesService(
          doctorId,
          type,
          from,
          to
        );

        if (futureOnly && data) {
          data = data?.filter((availability) =>
            moment.utc(availability).local().isAfter(moment())
          );
        }

        if (sortedByDate && data) {
          data = data?.sort((a, b) => new Date(a) - new Date(b));
        }

        const daysMap = groupBy(data || [], (utcAvailability) =>
          moment.utc(utcAvailability).local().format(FORMAT['us-date'])
        );

        setDaysWithDot({ ...daysMap });
      } catch (error) {
        toast(t('Ops, não foi possível carregar o calendário...'), {
          variant: 'error',
        });
      }
    }

    if (doctorId) getDoctorFutureAvailabilitiesService();
  }, [currentMonth, dispatch, doctorId, type]);

  useEffect(() => {
    const isThisMonth = currentMonth.getMonth() === new Date().getMonth();

    let firstDay = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );

    if (isThisMonth) firstDay = new Date();

    changeDate(firstDay);
  }, [currentMonth]);

  function updateSlotCards(selectedDate) {
    const formatted = formatDate(selectedDate);
    const search = daysWithDot?.[formatted] || [];

    if (setSlotCards)
      setSlotCards({ selectedDate, availabilities: [...search] });

    dispatch(
      setQuery({ from: new Date(selectedDate), to: new Date(selectedDate) })
    );
  }

  useEffect(() => {
    updateSlotCards(date);
  }, [daysWithDot]);

  function handleChange(newDate) {
    if (!doctorId) return false;

    changeDate(newDate);
    return updateSlotCards(newDate);
  }

  function handleDays(day, selectedDate, dayInCurrentMonth, DayComponent) {
    let selectedClass = '';
    if (day.getTime() === selectedDate.getTime()) {
      selectedClass = classes.selected;
    }

    if (formatDate(day) in daysWithDot) {
      return (
        <DayButton
          className={`${classes.available} ${classes.day} ${selectedClass}`}
        >
          {DayComponent}
        </DayButton>
      );
    }

    return (
      <div className={`${selectedClass} ${classes.day}`}>{DayComponent}</div>
    );
  }

  return (
    <MuiPickersUtilsProvider locale={ptBR} fullWidth utils={DateFnsUtils}>
      <Calendar
        date={date}
        onChange={handleChange}
        renderDay={handleDays}
        onMonthChange={(day) => setCurrentMonth(day)}
      />
    </MuiPickersUtilsProvider>
  );
}

export default CustomCalendar;
