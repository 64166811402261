import React, { useState } from 'react';
import { ContentCopy, Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../contexts/ToastContext';
import UnreversibleConfirmationModal from '../../../components/UnreversibleConfirmationModal';
import {
  CustomTable,
  TableHeaderColumn,
  TableColumn,
  TableRow,
  PatientBox,
  PatientName,
  TableColumnText,
  ActionIcon,
} from './table.styles';
import { FORMAT } from '../../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../../utils/moment/momentHelpers';
import { doctorDeleteVoucherService } from '../../../services/doctors.service';

function Vouchers({ doctorVouchers, setDoctorVouchers }) {
  const toast = useToast();
  const { t } = useTranslation(['doctor_vouchers']);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});

  function handleCopyCode(voucher) {
    navigator.clipboard.writeText(voucher.code).then(() => {
      toast(t('Código copiado'), { variant: 'success' });
    });
  }

  function handleDeletionClick(voucher) {
    setSelectedVoucher(voucher);
    setOpenUnreversible(true);
  }

  function formatDate(date) {
    if (date) {
      return momentUtcLocal(date).format(FORMAT['pt-date']);
    }

    return t('Não utilizado');
  }

  async function handleDeleteVoucher() {
    try {
      const deletedVoucher = await doctorDeleteVoucherService(
        selectedVoucher.key
      );
      const vouchers = [...doctorVouchers];

      const index = vouchers.findIndex(
        (voucher) => voucher.key === deletedVoucher.key
      );
      vouchers.splice(index, 1);
      setDoctorVouchers(vouchers);
      toast(t('Voucher deletado'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Ops, houve um erro na exclusão...'), {
        variant: 'error',
      });
    }
  }

  function handleConfirmation(status) {
    if (status) {
      handleDeleteVoucher();
    }
    setOpenUnreversible(false);
  }

  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            <TableHeaderColumn>{t('Código')}</TableHeaderColumn>
            <TableHeaderColumn>{t('Apelido')}</TableHeaderColumn>
            <TableHeaderColumn>{t('Data de expiração')}</TableHeaderColumn>
            <TableHeaderColumn>{t('Data de utilização')}</TableHeaderColumn>
            <TableHeaderColumn align="center">{t('Ações')}</TableHeaderColumn>
          </tr>
        </thead>
        <tbody>
          {doctorVouchers?.map((voucher) => (
            <TableRow key={voucher.key}>
              <TableColumn>
                <PatientBox>
                  <ActionIcon onClick={() => handleCopyCode(voucher)}>
                    <ContentCopy size={20} />
                  </ActionIcon>
                  <PatientName>{voucher.code}</PatientName>
                </PatientBox>
              </TableColumn>
              <TableColumn>
                <TableColumnText>{voucher.label}</TableColumnText>
              </TableColumn>
              <TableColumn>
                <TableColumnText>
                  {formatDate(voucher.expires_at)}
                </TableColumnText>
              </TableColumn>
              <TableColumn>
                <TableColumnText>{formatDate(voucher.used_at)}</TableColumnText>
              </TableColumn>
              <TableColumn align="center">
                <ActionIcon onClick={() => handleDeletionClick(voucher)}>
                  <Delete size={20} />
                </ActionIcon>
              </TableColumn>
            </TableRow>
          ))}
        </tbody>
      </CustomTable>
      <UnreversibleConfirmationModal
        title={t('Você tem certeza de que deseja excluir este voucher?')}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
}
export default Vouchers;
