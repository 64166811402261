import PersonImage from '../../data/personImage';

type PersonCellProps = {
  name: string;
  image?: string | null;
};

export default function PersonCell({ name, image }: PersonCellProps) {
  return (
    <td className="p-4 text-left font-nunito text-sm font-bold text-blue-650 lg:px-8 lg:py-1.5">
      <div className="flex h-full w-fit items-center gap-x-4 lg:gap-x-7">
        <div className="h-12 w-12 shrink-0">
          <PersonImage name={name} image={image} />
        </div>
        <p>{name}</p>
      </div>
    </td>
  );
}
