import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Wrapper,
  WrapperLoading,
  FirstGrid,
  SecondGrid,
  WelcomePanel,
  WelcomeTitle,
  WelcomeInfo
} from './patient.styles';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';

import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import DoctorPanel from '../../components/DoctorPanel';
import Header from '../../components/Header';
import ImageOrName from '../../components/ImageOrName';

import { getPatientList } from '../../services/patient';
import { Link } from 'react-router-dom';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
import { Tooltip } from '@material-ui/core';

const TITLE = 'Pacientes - Caren';

export default function Patients() {
  const { t } = useTranslation(['team']);
  const [loading, setLoading] = useState(true);
  const [patients, setPatients] = useState([]);
  const toast = useToast();

  const getPatients = async () => {
    try {
      const res = await getPatientList();

      setPatients(res.data);

      setLoading(false);
    } catch (error) {
      toast(t('Não foi possível listar os pacientes atendidos.'), {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    getPatients();
  }, []);

  return (
    <>
      <Helmet title={TITLE}></Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel />
        </FirstGrid>

        <SecondGrid>
          <WelcomePanel className="mb-4">
            <WelcomeInfo>
              <WelcomeTitle>{t('Meus pacientes').toString()}</WelcomeTitle>
            </WelcomeInfo>
          </WelcomePanel>

          {loading ? (
            <Wrapper>
              <WrapperLoading>
                <span />
                <span />
              </WrapperLoading>
            </Wrapper>
          ) : (
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
              {patients.length <= 0 && (
                <p className="col-span-full w-full py-10 text-center text-base text-gray-350">
                  Você ainda não possui pacientes cadastrados, ao realizar uma
                  consulta os pacientes consultados poderão ser encontrados
                  aqui!
                </p>
              )}
              {patients.length > 0 &&
                React.Children.toArray(
                  patients.map(item => (
                    <Link
                      to={`/pacientes/${item.key}`}
                      className="col-span-1 flex rounded-md border-b-2 bg-white px-5 py-3 drop-shadow-sm hover:border-yellow-650 hover:drop-shadow-md"
                    >
                      <div className="mr-5 flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-gray-200 font-bold tracking-widest">
                        <ImageOrName
                          src={item.avatar_url}
                          title={item.name}
                          iconSyle={{ height: '2.5rem', width: '2.5rem' }}
                        />
                      </div>
                      <div className="flex grow flex-col justify-items-center">
                        <Tooltip
                          placement="top"
                          className="text-gray-350"
                          title={
                            <h1 style={{ fontSize: '1.3em' }}>{item.name}</h1>
                          }
                        >
                          <p className="line-clamp-1 h-2/4 text-lg text-gray-400">
                            {item.name}
                          </p>
                        </Tooltip>
                        <p className="line-clamp-2 h-2/4 text-xs text-gray-350">
                          {item.used_coverages
                            .map(coverage => coverage.name)
                            .join(' | ')}
                        </p>
                      </div>
                    </Link>
                  ))
                )}
            </div>
          )}
        </SecondGrid>
      </Container>
      <Tawk />
      <Chatwoot />
    </>
  );
}
