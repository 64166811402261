import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import {
  ConsultationContainer,
  Container,
  MainContent,
  PatientInfoContainer,
  SubHeaderContainer,
  TitlePage
} from './styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import { scheduleDetailsService } from '../../services/patient-service';
import { anamnesisDetailService } from '../../services/anamnesis-detail.service';
import { useToast } from '../../contexts/ToastContext';
import Anamnesis from '../../components/Twilio/Room/types/Professional/Tabs/Anamnesis';
import PatientInfo from '../../components/Consultation/PatientInfo';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import Chatwoot from '../../components/Chatwoot';
import Tawk from '../../components/Tawk';

export default function PreAppointmentDetail({
  computedMatch: {
    params: { consult_id }
  }
}) {
  const TITLE = 'Detalhe do Pré-Atendimento - Caren';

  const toast = useToast();
  const history = useHistory();
  const { t } = useTranslation('post_appointment');
  const [consultation, setConsultation] = useState(null);
  const [anamnesisDetail, setAnamnesisDetail] = useState(null);

  useEffect(() => {
    async function retrieveTeleconsultation() {
      try {
        const { data } = await scheduleDetailsService(consult_id);

        setConsultation(data);
      } catch (error) {
        toast(t('Não foi possível carregar a consulta no momento.'), {
          variant: 'error'
        });
      }
    }

    retrieveTeleconsultation();
  }, [consult_id]);

  useEffect(async () => {
    if (consultation?.anamnesis_key) {
      try {
        const { data } = await anamnesisDetailService(
          consultation.anamnesis_key,
          {
            with_history: false
          }
        );

        setAnamnesisDetail(data);
      } catch (error) {
        toast(t('Ops, não foi possível carregar o pré-atendimento...'), {
          variant: 'error'
        });
      }
    }
  }, [consultation]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header rightAction={null} />
      <Container>
        <SubHeaderContainer>
          <button
            type="button"
            onClick={() => {
              history.goBack();
            }}
            className="flex items-center gap-x-2 font-nunito text-sm font-semibold text-gray-450"
          >
            <ArrowLeftIcon className="h-5 w-5 text-yellow-750" /> Voltar
          </button>
          <TitlePage>Anamnese da teleconsulta</TitlePage>
        </SubHeaderContainer>

        {consultation && (
          <MainContent>
            <PatientInfoContainer>
              <PatientInfo
                patient={consultation.patient}
                teleconsultationKey={consultation.key}
                scheduledDatetime={consultation.scheduled_datetime}
              />
            </PatientInfoContainer>

            <ConsultationContainer>
              <Anamnesis
                detail={anamnesisDetail}
                showClinicalEvaluation={false}
              />
            </ConsultationContainer>
          </MainContent>
        )}
      </Container>
      <Tawk />
      <Chatwoot />
    </>
  );
}
