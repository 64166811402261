import { useCallback, useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { type PaymentFormData } from './payment';
import { ReactComponent as TimerIcon } from '../../../../assets/icons/timer.svg';
import { ReactComponent as CardSlashIcon } from '../../../../assets/icons/card-slash.svg';
import Button from '../../inputs/buttons/Button';
import {
  createPlanSubscribe,
  getTokenizePaymentCard,
  updateSubscriptionCard
} from '../../../../services/pagarme';
import { type DoctorCreate } from '../../../entities/doctor';
import { type Subscription } from '../../../entities/subscription';
import { doctorSignup } from '../../../../services/doctor';
import { getSubscription } from '../../../../services/plan';
import { useAuth } from '../../../../hooks/AuthContext';
import { type AddressFormData } from './address';
import onlyDigits from '../../../../utils/onlyDigits';
import useCheckoutForm from '../../../hooks/checkout/useCheckoutForm';

type ReviewStepProps = {
  step: number;
  plan: string;
  planComparison: {
    type: 'regular' | 'upgrade' | 'downgrade' | 'equivalent';
    discount_code_applied: boolean | null;
    current_subscription_key: string | null;
  } | null;
  discountCode: string | null;
  onSubscriptionCreate: (subscription: Subscription) => void;
  onComplete: () => void;
}

export default function ReviewStep({
  step,
  plan,
  planComparison,
  discountCode,
  onSubscriptionCreate,
  onComplete
}: ReviewStepProps) {
  const { actualStep, steps, setStepData, jumpToStep } = useCheckoutForm();
  const { credentials, persistSession } = useAuth();

  const [error, setError] = useState<string | null>(null);
  const [targetStep, setTargetStep] = useState<number>(3);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [creditCardToken, setCreditCardToken] = useState<string | null>(null);

  const personData = steps[1] as DoctorCreate & { isAuthenticated?: boolean };
  const addressData = steps[2] as AddressFormData;
  const paymentData = steps[3] as PaymentFormData;

  const isCardUpdate = () =>
    planComparison?.type === 'equivalent' &&
    planComparison?.current_subscription_key;

  const formatAddressData = () => ({
    zip_code: addressData.zipCode.replace(/\D/g, ''),
    line_1: `${addressData.street} ${addressData.number}`,
    line_2: addressData.complement ?? '',
    city: addressData.city,
    country: addressData.country,
    state: addressData.state
  });

  const getSubscriptionStatus = useCallback(async () => {
    if (error !== null || !subscription || !credentials?.token) return;

    try {
      const { data: result }: { data: Subscription } = await getSubscription();

      if (result?.current_invoice?.status === 'paid') {
        onComplete();
        return;
      }

      if (['canceled', 'failed'].includes(result?.current_invoice?.status)) {
        setLoading(false);
        setError(
          'A transação não pôde ser efetuada. Por favor verifique os dados do cartão e tente novamente.'
        );
        return;
      }

      setTimeout(() => {
        getSubscriptionStatus();
      }, 3000);
    } catch (e) {
      setLoading(false);
      setError(
        'Seu pedido foi registrado, mas tivemos um problema ao verificar o status. Por favor revise as informações na área Meus Dados, na aba Pagamentos.'
      );
    }
  }, [subscription, error, credentials]);

  const subscribeToPlan = useCallback(async () => {
    if (
      paymentData &&
      error === null &&
      subscription === null &&
      creditCardToken &&
      plan &&
      personData
    ) {
      try {
        if (!personData.isAuthenticated) {
          const user = await doctorSignup(personData);
          localStorage.setItem('@Caren:firstAccess', 'true');

          persistSession(user.data);
          return;
        }

        const { data: subscription }: { data: Subscription } = isCardUpdate()
          ? await updateSubscriptionCard(
              planComparison?.current_subscription_key ?? '',
              {
                card_token: creditCardToken,
                billing_address: formatAddressData()
              }
            )
          : await createPlanSubscribe({
              plan_key: plan,
              card_token: creditCardToken,
              discount_code: planComparison?.discount_code_applied
                ? discountCode
                : null,
              billing_address: formatAddressData()
            });

        if (subscription.status === 'failed') {
          setLoading(false);
          setError(
            'A transação não foi autorizada. Por favor verifique os dados do cartão e tente novamente.'
          );
        } else {
          setSubscription(subscription);
          onSubscriptionCreate(subscription);
        }
      } catch (error: any) {
        setLoading(false);
        setTargetStep(isCardUpdate() ? 3 : 1);
        if (error?.response?.data?.error?.message?.login) {
          setError(
            'Este e-mail já foi usado. Por favor faça login antes de prosseguir ou utilize um endereço de e-mail diferente.'
          );
        } else {
          setError(
            'Tivemos um problema ao registrar sua conta no plano selecionado. Verifique os dados e tente novamente.'
          );
        }
      }
    }
  }, [
    paymentData,
    creditCardToken,
    error,
    plan,
    personData,
    persistSession,
    subscription
  ]);

  const getCreditCardToken = useCallback(async () => {
    if (
      paymentData &&
      addressData &&
      error === null &&
      creditCardToken === null
    ) {
      const expiration = paymentData.cardExpiration.split('/');

      try {
        const data = await getTokenizePaymentCard({
          number: paymentData.cardNumber.replace(/\D/g, ''),
          cvv: paymentData.cardVerification,
          name: paymentData.cardName,
          cpf: onlyDigits(paymentData.cpf),
          exp_month: Number(expiration[0]),
          exp_year: Number(expiration[1].slice(-2))
        });

        setCreditCardToken(data);
      } catch (e) {
        setLoading(false);
        setError(
          'Houve um erro ao processar os dados do seu cartão. Verifique os dados e tente novamente.'
        );
      }
    }
  }, [paymentData, addressData, creditCardToken, error]);

  useEffect(() => {
    getCreditCardToken();
  }, [getCreditCardToken]);

  useEffect(() => {
    subscribeToPlan();
  }, [subscribeToPlan]);

  useEffect(() => {
    if (subscription) {
      setTimeout(() => {
        getSubscriptionStatus();
      }, 1000);
    }
  }, [subscription, getSubscriptionStatus]);

  if (actualStep !== step) return null;

  return (
    <div className="mt-7 flex h-full w-full max-w-sm flex-grow flex-col items-center md:mt-10">
      <Cards
        cvc={paymentData?.cardVerification ?? ''}
        expiry={paymentData?.cardExpiration ?? ''}
        focused={'number'}
        name={paymentData?.cardName ?? ''}
        number={paymentData?.cardNumber ?? ''}
        locale={{ valid: 'Válido até' }}
        placeholders={{ name: 'NOME SOBRENOME' }}
      />
      {loading && (
        <div className="mt-10 flex flex-col items-center gap-y-5 sm:mt-20">
          <div className="animate-pulse">
            <TimerIcon className="h-10 w-10 animate-spin text-blue-650" />
          </div>
          <p className="text-center font-nunito text-sm font-semibold text-blue-650 sm:text-base">
            Olá <strong>{personData.name}</strong> seu pagamento está sendo
            processado, aguarde uns instantes...
          </p>
        </div>
      )}
      {error && (
        <>
          <div className="mb-10 mt-10 flex flex-col items-center gap-y-5 sm:mt-20">
            <CardSlashIcon className="h-10 w-10 text-error" />
            <p className="text-center font-nunito text-sm font-semibold text-blue-650 sm:text-base">
              {error}
            </p>
          </div>
          <Button
            label="Tentar Novamente"
            type="button"
            colors="yellow"
            size="large"
            onClick={() => {
              setStepData(3, null);
              jumpToStep(targetStep);
              setCreditCardToken(null);
              setSubscription(null);
              setError(null);
              setLoading(true);
              setTargetStep(3);
            }}
          />
        </>
      )}
    </div>
  );
}
