/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Socket } from 'phoenix';
import api from '../services/api';

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [socket, setSocket] = useState(null);
  const [channel, setChannel] = useState(null);
  const [shouldDisconnect, setShouldDisconnect] = useState(false);

  async function authenticateWebSocket() {
    try {
      const { data } = await api.post('/authorize-ws', {});
      setToken(data.token);
      return Promise.resolve(data.token);
    } catch (error) {
      console.error('error: ', error);
      return Promise.reject(error);
    }
  }

  function handleConnect(newOpts) {
    if (socket != null) {
      cleanupSocket();
    }

    const newSocket = new Socket(import.meta.env.VITE_API_WEBSOCKET, {});
    newSocket.connect();
    newSocket.onClose((closeResponse) => {
      console.log('Closed socket');
      if (newOpts?.afterClose != null) {
        newOpts?.afterClose(newSocket, closeResponse);
      }
    });
    setSocket(newSocket);

    return newSocket;
  }

  function handleDisconnect() {
    // Workaround: When called from cleanup functions, the
    // context's state is not accessible for some reason and disconnection does not work.
    // So we signal for disconnection and handle it in an useEffect clause.
    setShouldDisconnect(true);
  }

  useEffect(() => {
    if (shouldDisconnect) {
      if (socket) {
        cleanupSocket();
      }

      setShouldDisconnect(false);
    }
  }, [shouldDisconnect]);

  function cleanupSocket() {
    handleLeaveChannel();
    socket.disconnect(null, 1000, 'unknown');
    setSocket(null);
  }

  async function handleJoinChannel(newTopic, payload, newOpts) {
    const authenticated =
      newOpts?.authenticated == null ? true : newOpts?.authenticated;
    const enableJoin = newOpts?.enableJoin == null ? true : newOpts?.enableJoin;

    if (!socket || !enableJoin) return;

    let newToken = token;
    if (authenticated) {
      newToken = await authenticateWebSocket();
    }

    const newChannel = socket.channel(newTopic, {
      ...payload,
      token: newToken,
    });

    newChannel
      .join()
      .receive('ok', (joinResponse) => {
        console.log('Joined successfully');
        if (newOpts?.afterJoin != null) {
          newOpts?.afterJoin(newChannel, joinResponse);
        }

        setChannel(newChannel);
      })
      .receive('error', (reason) => console.error('failed join', reason))
      .receive('timeout', () => {
        console.log('Networking issue. Trying again...');
        handleJoinChannel(newTopic, payload, newOpts);
      });
  }

  function handleLeaveChannel() {
    if (!channel) return;

    channel.off();
    channel
      .leave()
      .receive('ok', (resp) => {
        console.log('Left from channel successfully', resp);
        setChannel(null);
      })
      .receive('error', (resp) => {
        console.error('Unable to leave the channel', resp);
      });
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        channel,
        connect: handleConnect,
        disconnect: handleDisconnect,
        joinChannel: handleJoinChannel,
        leaveChannel: handleLeaveChannel,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = (topic, payload, opts) => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within an SocketProvider');
  }

  const {
    socket,
    channel,
    connect,
    disconnect,
    joinChannel,
    leaveChannel,
  } = context;

  const hasSocket = socket instanceof Socket;

  useEffect(() => {
    connect(opts);

    // Closes the Socket connection when component unmounts
    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (!hasSocket) return null;
    if (channel) {
      leaveChannel();
    }

    joinChannel(topic, payload, opts);

    return () => {
      leaveChannel();
    };
  }, [hasSocket, topic, opts?.enableJoin]);

  return context;
};

export { SocketProvider, useSocket };
