import { isToday } from 'date-fns';
import { FORMAT } from './moment/momentFormat';
import { momentUtcLocal } from './moment/momentHelpers';

export default (date) => {
  if (!isToday(new Date(date)))
    return momentUtcLocal(date).format('DD/MM - HH:mm');

  return momentUtcLocal(date).format(FORMAT['hour-and-minute']);
};
