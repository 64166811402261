import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import { VideoOffIcon } from '../../Icons/VideoOffIcon';
import { VideoOnIcon } from '../../Icons/VideoOnIcon';

import useDevices from '../../../../hooks/video/useDevices';
import useLocalVideoToggle from '../../../../hooks/video/useLocalVideoToggle';
import { useVideoAppState } from '../../../../hooks/video/useVideoAppState';

export default function ToggleVideoButton({ className, disabled }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const { mediaError } = useVideoAppState();
  const permissionError =
    mediaError?.name === 'NotAllowedError' ||
    mediaError?.message === 'CameraPermissionsDenied';

  const renderButtonCaption = () => {
    if (permissionError) return 'Habilite a permissão no navegador';

    if (!hasVideoInputDevices) return 'Sem vídeo';

    if (isVideoEnabled) return 'Parar vídeo';

    return 'Iniciar vídeo';
  };

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button
      className={className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || permissionError || disabled}
      startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
    >
      {renderButtonCaption()}
    </Button>
  );
}
