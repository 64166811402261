import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { type AxiosResponse } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { type DoctorCreate, DoctorCreateSchema } from '../../entities/doctor';
import { useToast } from '../../../contexts/ToastContext';
import { forgotPasswordService } from '../../../services/forgot-password.service';
import LoadingComponent from '../../../components/Loading';
import CheckboxInput from '../inputs/input/checkbox';
import TextInput from '../inputs/input/text';
import PasswordInput from '../inputs/input/password';
import MaskInput from '../inputs/input/mask';
import { useAuth } from '../../../hooks/AuthContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CarenReCaptcha from '../../../components/reCaptcha';

export const enum FormTypes {
  Login = 'login',
  Register = 'register',
  Forget = 'forget'
}
type DoctorFormProps = {
  className: string;
  saveData: (data: DoctorCreate) => void;
  buttonText: string;
  type: FormTypes;
  allowLogin: boolean;
};

type SignInData = {
  login: string;
  password: string;
};

type ForgotPasswordData = {
  email: string;
};
type SignInError = {
  error: boolean;
  response: AxiosResponse | null | undefined;
  message?: string | null;
};

export default function DoctorForm({
  saveData,
  className,
  buttonText,
  type,
  allowLogin
}: DoctorFormProps) {
  const history = useHistory();
  const toast = useToast();

  const [actualState, setActualState] = useState(type);
  const [loading, setLoading] = useState(false);
  const [signInError, setSignInError] = useState<SignInError>({
    response: null,
    error: false
  });
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const [captchaReloadTime, setCaptchaReloadTime] = useState(
    new Date().getTime()
  );
  const captchaEnabled = import.meta.env.VITE_RECAPTCHA_KEY != null;
  const { signIn } = useAuth();
  const { t } = useTranslation(['login']);

  const {
    handleSubmit: forgotHandleSubmit,
    control: forgotControl,
    register: forgotRegister,
    formState: { errors: forgotErrors }
  } = useForm<ForgotPasswordData>();

  const {
    handleSubmit: loginHandleSubmit,
    control: loginControl,
    register: loginRegister,
    formState: { errors: loginErrors }
  } = useForm<SignInData>();

  const {
    handleSubmit: signUpHandleSubmit,
    register: signUpRegister,
    control: signUpControl,
    formState: { errors: signUpErrors }
  } = useForm<DoctorCreate>({
    resolver: zodResolver(DoctorCreateSchema)
  });

  function handleSignInResponse(response: SignInError) {
    if (response.error) {
      setCaptchaReloadTime(new Date().getTime());
    }
    setSignInError(response);
  }

  const login = async (data: { login: string; password: any }) => {
    if (!captchaEnabled || captchaResponse) {
      setLoading(true);
      handleSignInResponse({ response: null, error: false });
      try {
        const result = await signIn({
          login: data.login,
          password: data.password,
          recaptchaResponse: captchaResponse
        });
        handleSignInResponse({
          error: result.error,
          response: result.response
        });
      } catch (error) {
        toast(t('No momento, não foi possível realizar o login.'), {
          variant: 'error'
        });
      }
      setLoading(false);
    } else {
      handleSignInResponse({
        error: true,
        response: null,
        message: 'Por favor tente novamente.'
      });
    }
  };

  const forgotPasswordSubmit = async (data: { email: string }) => {
    try {
      await forgotPasswordService({
        email: data.email
      });
      toast(t('Enviamos instruções para o seu endereço de e-mail.'), {
        variant: 'success'
      });
      setActualState(FormTypes.Login);
    } catch (error) {
      toast(
        t('Não foi possível solicitar no momento. Por favor, tente novamente.'),
        {
          variant: 'error'
        }
      );
    }
  };

  const handleCaptchaVerification = (response: string | null) => {
    if (response) {
      setCaptchaResponse(response);
    }
  };

  const renderSignInError = () => {
    if (signInError?.message) {
      return <span className="text-sm text-error">{signInError?.message}</span>;
    }

    if (signInError?.response?.status === 401) {
      return (
        <span className="text-sm text-error">
          {t('Login ou senha inválida.')}
        </span>
      );
    }

    if (signInError?.response?.status === 403) {
      return (
        <span className="text-sm text-error">
          {t('Por favor verifique que você não é um robô.')}
        </span>
      );
    }

    if (signInError?.error)
      return (
        <span className="text-sm text-error">
          {t('Ocorreu um erro ao entrar, por favor tente novamente.')}
        </span>
      );

    return null;
  };

  const handleSaveData = async (data: DoctorCreate) => {
    setLoading(true);
    saveData(data);
    setLoading(false);
  };

  // Futuramente separar em dois componentes de FORM

  return (
    <>
      {actualState === FormTypes.Register && (
        <form
          onSubmit={signUpHandleSubmit(handleSaveData)}
          className={className}
        >
          <div className="mb-4 flex w-full flex-col gap-y-5 sm:mb-8">
            <TextInput
              label="Seu nome completo:"
              type="text"
              {...signUpRegister('name')}
              error={signUpErrors.name?.message}
            />
            <TextInput
              label="Seu e-mail:"
              type="email"
              {...signUpRegister('email')}
              error={signUpErrors.email?.message}
            />
            <Controller
              control={signUpControl}
              name="phone"
              render={({ field: { onChange, ref, value } }) => (
                <MaskInput
                  mask="(99) 99999-9999"
                  label="Telefone para contato:"
                  error={signUpErrors.phone?.message}
                  onChange={e => {
                    onChange(e);
                  }}
                  defaultValue={value}
                  ref={ref}
                />
              )}
            />
            <Controller
              control={signUpControl}
              name="fiscal_code"
              render={({ field: { onChange, ref, value } }) => (
                <MaskInput
                  mask="999.999.999-99"
                  label="CPF:"
                  error={signUpErrors.fiscal_code?.message}
                  onChange={e => {
                    onChange(e);
                  }}
                  defaultValue={value}
                  ref={ref}
                />
              )}
            />
            <TextInput
              label="Data de Nascimento:"
              type="date"
              {...signUpRegister('birthdate')}
              error={signUpErrors.birthdate?.message}
            />
            <PasswordInput
              label="Escolha uma senha:"
              {...signUpRegister('password')}
              error={signUpErrors.password?.message}
            />
            <PasswordInput
              label="Confirme sua senha:"
              {...signUpRegister('password_confirmation')}
              error={signUpErrors.password_confirmation?.message}
            />
            <CheckboxInput
              type="checkbox"
              {...signUpRegister('accept_terms')}
              error={signUpErrors.accept_terms?.message}
            >
              <div>
                <span>Li e aceito os&nbsp;</span>
                <a
                  className="underline hover:text-yellow-750 hover:underline"
                  href="https://www.caren.app/paginas/termos-de-uso-telessaude"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termos de Uso
                </a>
                <span>&nbsp;e&nbsp;</span>
                <a
                  className="underline hover:text-yellow-750 hover:underline"
                  href="https://www.caren.app/paginas/aviso-de-privacidade"
                  target="_blank"
                  rel="noreferrer"
                >
                  Políticas de Privacidade
                </a>
                <span>.</span>
              </div>
            </CheckboxInput>
            <CheckboxInput
              type="checkbox"
              {...signUpRegister('veracity')}
              error={signUpErrors.veracity?.message}
            >
              Declaro que sou profissional da saúde com registro de trabalho
              válido e ativo. Estou ciente das normas profissionais que me
              incumbem e reconheço a obrigatoriedade de incluir meus dados de
              registro profissional e de especialidade verdadeiros, quando
              aplicável.
            </CheckboxInput>
          </div>
          {allowLogin ? (
            <button
              type="button"
              onClick={() => {
                setActualState(FormTypes.Login);
              }}
              className="mb-8 cursor-pointer font-nunito text-sm text-blue-650 hover:text-blue-650 sm:mb-14"
            >
              Já possui conta? <strong>Clique aqui.</strong>
            </button>
          ) : null}
          <button
            type="submit"
            className="group flex h-14 w-full items-center justify-center gap-x-2 rounded-md bg-yellow-650 px-2.5 py-3 font-nunito  text-base font-bold transition-all duration-300 disabled:cursor-not-allowed"
          >
            {loading ? <LoadingComponent /> : buttonText}
          </button>
          <button
            className="mt-10 font-nunito text-sm font-semibold text-blue-650"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </button>
        </form>
      )}
      {actualState === FormTypes.Login && allowLogin && (
        <form
          onSubmit={loginHandleSubmit(login)}
          className="mt-7 flex h-full w-full max-w-sm flex-grow flex-col items-center md:mt-20"
        >
          <div className="mb-4 flex w-full flex-col gap-y-5 sm:mb-8">
            <Controller
              control={loginControl}
              name="login"
              defaultValue=""
              render={({ field: { ...fields } }) => (
                <TextInput
                  label="E-mail:"
                  type="email"
                  {...loginRegister('login')}
                  error={loginErrors.login?.message}
                />
              )}
              rules={{ required: true }}
            />
            {loginErrors && loginErrors.login && (
              <span className="text-sm text-error">
                Por favor digite o seu e-mail.
              </span>
            )}
            <Controller
              control={loginControl}
              name="password"
              defaultValue=""
              render={({ field: { ...fields } }) => (
                <PasswordInput
                  label="Senha:"
                  {...loginRegister('password')}
                  error={loginErrors.password?.message}
                />
              )}
              rules={{ required: true }}
            />

            {loginErrors && loginErrors.password && (
              <span className="text-sm text-error">
                Por favor digite a sua senha.
              </span>
            )}
            {renderSignInError()}
            {captchaEnabled && (
              <GoogleReCaptchaProvider
                reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
              >
                <CarenReCaptcha
                  handleCaptcha={handleCaptchaVerification}
                  captchaReloadTime={captchaReloadTime}
                />
              </GoogleReCaptchaProvider>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              setActualState(FormTypes.Forget);
            }}
            className="mb-8 cursor-pointer font-nunito text-sm text-blue-650 hover:text-blue-650 sm:mb-8"
          >
            Esqueceu sua senha? <strong>Clique aqui.</strong>
          </button>
          <button
            type="submit"
            className="group flex h-14 w-full items-center justify-center gap-x-2 rounded-md bg-yellow-650 px-2.5 py-3 font-nunito  text-base font-bold transition-all duration-300 disabled:cursor-not-allowed"
          >
            {loading ? <LoadingComponent /> : t('Entrar')}
          </button>
          <button
            className="mt-5 w-full py-2 font-nunito text-sm font-semibold text-blue-650"
            type="button"
            onClick={() => {
              setActualState(FormTypes.Register);
            }}
          >
            Voltar
          </button>
        </form>
      )}
      {actualState === FormTypes.Forget && allowLogin && (
        <form
          onSubmit={forgotHandleSubmit(forgotPasswordSubmit)}
          className="mt-7 flex h-full w-full max-w-sm flex-grow flex-col items-center md:mt-20"
        >
          <div className="mb-4 flex w-full flex-col gap-y-5 sm:mb-8">
            <h3 className="mb-5 text-center font-nunito text-xl font-semibold text-gray-400">
              Esqueceu a senha?
            </h3>
            <Controller
              control={forgotControl}
              name="email"
              defaultValue=""
              render={({ field: { ...fields } }) => (
                <TextInput
                  label="E-mail:"
                  type="email"
                  placeholder="Por favor digite o seu e-mail"
                  {...forgotRegister('email')}
                  error={forgotErrors.email?.message}
                />
              )}
              rules={{ required: true }}
            />
            {forgotErrors && forgotErrors.email && (
              <span className="text-sm text-error">
                Por favor digite o seu e-mail.
              </span>
            )}
          </div>
          <button
            type="submit"
            className="group flex h-14 w-full items-center justify-center gap-x-2 rounded-md bg-yellow-650 px-2.5 py-3 font-nunito  text-base font-bold transition-all duration-300 disabled:cursor-not-allowed"
          >
            {loading ? <LoadingComponent /> : t('Enviar')}
          </button>
          <button
            className="mt-5 w-full py-2 font-nunito text-sm font-semibold text-blue-650"
            type="button"
            onClick={() => {
              setActualState(FormTypes.Login);
            }}
          >
            Voltar
          </button>
        </form>
      )}
    </>
  );
}
