import React, { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import {
  MainTour,
  TourItem,
  TourItemContent,
  TourItemContentBG,
  TourItemContentBlock,
  TourItemContentBlockClose,
  TourItemContentBlockCount,
  TourItemContentBlockDescription,
  TourItemContentBlockActions,
  TourItemContentBlockAction,
} from './tour.styles';

const Tour = ({ openTour, setTourOpen }) => {
  const [tourContent, setTourContent] = useState([]);
  const [tourIndex, setTourIndex] = useState(false);
  const [nextIndex, setNextIndex] = useState(false);
  const [tourVisible, setTourVisible] = useState(false);

  const finishTour = () => {
    setTourOpen(false);

    setTourIndex(false);
    setNextIndex(false);
    setTimeout(() => setTourContent([]), 600);
  };

  const calculateClass = (width, height, top, left) => {
    const newTop = height / 2 + top;
    const newLeft = width / 2 + left;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const percentageTop = Number(((100 * newTop) / windowHeight).toFixed(0));
    const percentageLeft = Number(((100 * newLeft) / windowWidth).toFixed(0));
    let classname = '';

    if (percentageTop > 69 && percentageLeft < 20) {
      classname = 'top-right';
    } else if (percentageTop > 20 && percentageLeft > 69) {
      classname = 'top-left';
    } else if (percentageTop < 20 && percentageLeft < 20) {
      classname = 'bottom-right';
    } else if (percentageTop < 20 && percentageLeft > 69) {
      classname = 'bottom-left';
    } else if (
      percentageTop > 69 &&
      percentageLeft <= 69 &&
      percentageLeft >= 20
    ) {
      classname = 'top';
    } else if (
      percentageTop < 69 &&
      percentageLeft <= 69 &&
      percentageLeft >= 20
    ) {
      classname = 'bottom';
    } else if (percentageLeft <= 50) {
      classname = 'right';
    } else if (percentageLeft >= 50) {
      classname = 'left';
    }

    return classname;
  };

  const formatDescription = (description) => {
    const newDescription = description
      .replaceAll('<br/>', '\n')
      .replaceAll('<br>', '\n');

    return newDescription;
  };

  useEffect(() => {
    if (nextIndex === false) return;

    const data = tourContent;
    const item = data[nextIndex];

    setTourVisible(false);

    window.scrollTo({
      top: item.top - 30,
      behavior: 'smooth',
    });

    setTimeout(() => {
      const { element } = item;
      const elementRect = element.getBoundingClientRect();
      const top = Number(elementRect.top) - 10;
      const classname = calculateClass(item.width, item.height, top, item.left);

      item.top = top;
      item.classname = classname;

      data[nextIndex] = item;

      setTourVisible(true);
      setTourContent(data);
      setTimeout(() => setTourIndex(nextIndex), 10);
    }, 200);
  }, [nextIndex]);

  useEffect(() => {
    if (!openTour) return;

    setTimeout(() => {
      const elements = document.querySelectorAll('[data-tour]');
      let data = [];

      elements.forEach((element) => {
        const elementRect = element.getBoundingClientRect();
        const index = Number(element.getAttribute('data-tour-index'));
        const description = element.getAttribute('data-tour');
        const top = elementRect.top - 10;
        const width = elementRect.width + 20;
        const height = elementRect.height + 20;
        const left = elementRect.left - 10;
        const content = {
          index,
          top,
          element,
          description,
          width,
          height,
          left,
        };

        data[index] = content;
      });

      data = data.filter((entry) => entry);

      setTourContent(data);
      setNextIndex(0);
    }, 50);
  }, [openTour]);

  return (
    <MainTour>
      {tourContent.length !== 0 &&
        tourContent.map((item, index) => (
          <TourItem
            className={tourIndex === index && tourVisible ? 'open' : ''}
          >
            <TourItemContent
              style={{
                top: `${item.top}px`,
                left: `${item.left}px`,
                width: `${item.width}px`,
                height: `${item.height}px`,
              }}
            >
              <TourItemContentBG className="one" />
              <TourItemContentBG className="two" />

              <TourItemContentBlock className={item.classname}>
                <TourItemContentBlockClose onClick={() => finishTour()}>
                  <CloseIcon />
                </TourItemContentBlockClose>

                {tourContent.length > 1 && (
                  <TourItemContentBlockCount>
                    Passo {tourIndex + 1} de {tourContent.length}
                  </TourItemContentBlockCount>
                )}

                <TourItemContentBlockDescription>
                  <p>{formatDescription(item.description)}</p>
                </TourItemContentBlockDescription>

                <TourItemContentBlockActions>
                  {index === tourContent.length - 1 ? (
                    <TourItemContentBlockAction>
                      <button
                        type="button"
                        title="Concluir"
                        aria-label="Concluir"
                        onClick={() => finishTour()}
                      >
                        <span>Finalizar</span>
                      </button>
                    </TourItemContentBlockAction>
                  ) : (
                    <>
                      {index > 0 && (
                        <TourItemContentBlockAction>
                          <button
                            type="button"
                            title="Voltar"
                            aria-label="Voltar"
                            onClick={() => setNextIndex(tourIndex - 1)}
                          >
                            <span>Voltar</span>
                          </button>
                        </TourItemContentBlockAction>
                      )}

                      <TourItemContentBlockAction>
                        <button
                          type="button"
                          title="Avançar"
                          aria-label="Avançar"
                          onClick={() => setNextIndex(tourIndex + 1)}
                        >
                          {index === 0 ? (
                            <span>Começar</span>
                          ) : (
                            <span>Avançar</span>
                          )}
                        </button>
                      </TourItemContentBlockAction>
                    </>
                  )}
                </TourItemContentBlockActions>
              </TourItemContentBlock>
            </TourItemContent>
          </TourItem>
        ))}
    </MainTour>
  );
};

export default Tour;
