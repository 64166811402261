import styled from 'styled-components/macro';
import { AiFillCamera } from 'react-icons/ai';
import searchIcon from '../../assets/images/search.png';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 50px 40px 50px 65px;
  min-height: calc(100vh - 95px);

  @media (max-width: 1140px) {
    padding: 50px 25px;
  }
  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FirstGrid = styled.div`
  width: 260px;
  flex-shrink: 0;
  margin-right: 70px;

  @media (max-width: 1140px) {
    margin-right: 25px;
  }
  @media (max-width: 1140px) {
    width: 100%;
    max-width: 320px;
    margin: 0 0 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SecondGrid = styled.div`
  flex-grow: 1;

  @media (max-width: 1140px) {
    flex-grow: unset;
    width: 100%;
  }
`;

export const SecondHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 13px;
  margin: 0 0 41px;

  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0 0 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const WelcomePanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const WelcomeInfo = styled.div``;

export const WelcomeTitle = styled.p`
  color: var(--caren-icongrey);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
`;

export const WelcomeSubtitle = styled.p`
  color: var(--caren-lightgrey);
  font-size: 14px;
`;

export const SearchContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
`;

export const SearchInputContainer = styled.span`
  position: relative;
  width: calc(33% - 7px);

  @media (max-width: 1023px) {
    width: 100%;
  }

  img {
    position: absolute;
    top: 11px;
    left: 10px;
    pointer-events: none;
  }

  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`;

export const SearchInputIcon = styled.img.attrs({
  src: searchIcon
})`
  height: 20px;
  margin-top: 4px;
`;

export const SearchInput = styled.input.attrs({
  type: 'text'
})`
  background-color: white;
  color: var(--caren-lightgrey);
  width: 100%;
  font-size: 15px;
  padding-left: 38px;
  height: 50px;
  border-radius: 15px;

  &::placeholder {
    color: var(--caren-lightgrey);
  }
`;

export const CameraIcon = styled(AiFillCamera).attrs({
  size: 20,
  color: 'var(--caren-white)'
})``;

export const Navigation = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    background-color: white;
    flex-direction: column;
    border-radius: 5px 5px 0 0;
    padding: 10px;
  }

  button {
    background-color: #e3e6e5;
    color: #47525e;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    padding: 4px 20px;
    border-radius: 5px 5px 0 0;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;

    @media (max-width: 1023px) {
      background-color: #00cdd6;
      color: white;
      font-size: 13px;
      font-weight: bold;
      padding: 10px 20px;
      width: 100%;
      flex-shrink: 0;
      border-radius: 10px;
    }

    &:not(:last-child) {
      margin-right: 4px;

      @media (max-width: 1023px) {
        margin: 0 0 10px;
      }
    }

    @media (max-width: 1023px) {
      &.active {
        background-color: #53d3b0;
      }
    }

    @media (min-width: 1024px) {
      &:hover,
      &.active {
        background-color: white;
      }

      &.active {
        font-weight: bold;
      }
    }
  }
`;

export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 15px;

  > span {
    color: #47525e;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 1140px) {
      font-size: 14px;
    }
  }
`;

export const WrapperLoading = styled.div<{ size?: number }>`
  display: inline-block;
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  margin: 0 auto;
  position: relative;

  @keyframes progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: #3995a9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const Consults = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @media (min-width: 1536px) {
    grid-template-columns: 2fr 1fr;
  }
`;

export const SectionTitle = styled.p`
  width: 100%;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 10px;
  color: var(${props => props.color});
  border-bottom: 1px solid rgba(215, 215, 215, 0.5);
`;

export const ConsultsTable = styled.table`
  table-layout: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  font-family: 'Nunito Sans', sans-serif;
  color: #455054;
  font-size: 0.875rem;
  font-weight: 500;

  @media (min-width: 640px) {
    display: inline-table;
  }

  > thead {
    display: none;

    @media (min-width: 640px) {
      display: table-header-group;
    }

    th {
      padding: 4px 6px;

      &.center {
        text-align: center;
      }
    }
  }

  > tbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (min-width: 640px) {
      row-gap: none;
      display: table-row-group;
    }

    tr {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      border-bottom: 1px solid var(--caren-input-border);
      padding-bottom: 10px;

      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      @media (min-width: 640px) {
        display: table-row;
        row-gap: none;
        border: none;
        padding-bottom: 0;
      }
    }

    td {
      padding: 4px 6px;

      &.center {
        text-align: center;
      }

      > span {
        display: block;
        font-weight: bold;
        width: 100%;
        text-align: center;

        @media (min-width: 640px) {
          display: none;
        }
      }

      p {
        position: relative;

        span {
          background-color: var(--caren-clockgrey);
          color: white;
          padding: 8px 10px;
          border-radius: 8px;
          white-space: nowrap;
          position: absolute;
          top: calc(50% - 2px);
          right: calc(100% + 10px);
          pointer-events: none;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 10;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &:hover span {
          opacity: 1;
        }
      }

      p.warning {
        color: var(--caren-lightgrey);
      }

      p.check,
      button.check svg path {
        color: var(--caren-success);
        fill: var(--caren-success);
      }

      p.progress {
        color: var(--caren-bluebtn);
      }

      div.anamnese {
        color: #b0b0b0;
        fill: #b0b0b0;
      }

      button,
      a {
        svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-orange);
            }
          }

          path {
            fill: var(--caren-darkgreen);
            transition-property: fill;
            transition-duration: 0.5s;
            transition-timing-function: ease;
          }
        }

        svg.clear {
          path {
            fill: inherit;
          }
        }

        &:hover svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-warning);
            }
          }

          path {
            fill: var(--caren-greenblue);
          }
        }

        &:hover svg.clear {
          path {
            fill: inherit;
          }
        }
      }

      button.warning-button {
        color: var(--caren-lightgrey);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        border-radius: 10px;
        padding: 0 4px;
        text-align: center;
        text-decoration: underline;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-lightgrey);
          border-color: var(--caren-lightgrey);
          color: var(--caren-white);
        }
      }

      a.green-button {
        background-color: var(--caren-greenblue);
        color: var(--caren-icongrey);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-darkgreen);
          color: var(--caren-icongrey);
        }
      }

      a.blue-button {
        color: var(--caren-bluebtn);
        border: 2px solid var(--caren-bluebtn);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-lightblue);
          border-color: var(--caren-lightblue);
          color: var(--caren-white);
        }
      }

      div.perfil {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        @media (min-width: 640px) {
          flex-direction: row;
        }
      }
    }
  }
`;
