import React from 'react';
import { type Invite } from '../../../v2/entities/invite';
import ImageOrName from '../../ImageOrName';
import { CardGrid, GridItem } from '../styles';

export default function DoctorInvitesGrid({
  data,
  onClickInvite
}: {
  data: Invite[];
  onClickInvite: (invite: Invite) => void;
}) {
  return (
    <CardGrid>
      {React.Children.toArray(
        data.map(invite => (
          <GridItem
            type="button"
            onClick={() => {
              if (!invite.accepted_at && !invite.rejected_at) {
                onClickInvite(invite);
              }
            }}
            disabled={
              invite.accepted_at !== null || invite.rejected_at !== null
            }
          >
            <div>
              <ImageOrName
                src={null}
                title={invite.employee.name}
                titleSize={16}
                backgroundStyle={{
                  width: 60,
                  height: 60,
                  borderRadius: '100%',
                  backgroundColor: 'var(--caren-image-fallback)'
                }}
              />
              <p className="title">{invite.employee.name}</p>
            </div>
            <p
              className={`${
                invite.accepted_at === null && invite.rejected_at === null
                  ? 'pending'
                  : ''
              } ${
                invite.accepted_at !== null && invite.rejected_at === null
                  ? 'accepted'
                  : ''
              } ${
                invite.accepted_at === null && invite.rejected_at !== null
                  ? 'rejected'
                  : ''
              }`}
            >
              {invite.accepted_at === null &&
                invite.rejected_at === null &&
                'Pendente'}
              {invite.accepted_at !== null &&
                invite.rejected_at === null &&
                'Confirmado'}
              {invite.accepted_at === null &&
                invite.rejected_at !== null &&
                'Rejeitado'}
            </p>
          </GridItem>
        ))
      )}
    </CardGrid>
  );
}
