import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import history from '../../../services/history';
import Modal from '../../Modal';
import ReconnectingNotification from '../ReconnectingNotification';
import { Room } from '../Room';
import { Rating } from '@mui/material';
import useRoomState from '../../../hooks/video/useRoomState';
import { ROLE_PROFESSIONAL } from '../../../routes/constants';
import { useAuth } from '../../../hooks/AuthContext';
import { useVideoAppState } from '../../../hooks/video/useVideoAppState';
import { useToast } from '../../../contexts/ToastContext';
import useVideoContext from '../../../hooks/video/useVideoContext';
import StarIcon from '@mui/icons-material/Star';
import TextareaInput from '../../../v2/components/inputs/input/textarea';
import { ReactComponent as LogoIconBlack } from '../../../assets/images/logo-icon-black.svg';
import { rateTeleconsultation } from '../../../services/teleconsultation.service';

const Container = styled('div')({
  flex: 1,
  display: 'grid',
  gridTemplateRows: '1fr auto'
});

const Main = styled('main')(({ _theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  background: 'white'
}));

const Button = styled('button')(({ _theme }) => ({
  color: 'var(--caren-icongray)',
  backgroundColor: 'var(--caren-greenblue)',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '250px',
  height: '40px',
  margin: '0 auto',
  padding: '0 20px',
  borderRadius: '5px',
  textTransform: 'uppercase',
  transitionProperty: 'background-color',
  transitionDuration: '0.3s',
  transitionTimingFunction: 'ease',
  '&:hover': {
    backgroundColor: 'var(--caren-darkgreen)'
  }
}));

export default function App() {
  const { credentials } = useAuth();
  const [modalState, setModalState] = useState(1);
  const toast = useToast();
  const { t } = useTranslation(['video_call']);
  const { consultation, token } = useVideoAppState();
  const roomState = useRoomState();

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FFC476'
    }
  });

  const [ratingServiceScore, setRatingServiceScore] = useState(5);
  const [patientServiceHover, setPatientServiceHover] = useState(-1);

  const [ratingPlatformScore, setRatingPlatformScore] = useState(5);
  const [platformHover, setPlatformHover] = useState(-1);

  const [ratingProfessionalScore, setRatingProfessionalScore] = useState(5);
  const [professionalHover, setProfessionalHover] = useState(-1);

  const [ratingDoctorRecomendationScore, setRatingDoctorRecomendationScore] =
    useState(5);
  const [doctorRecomendationHover, setDoctorRecomendationHover] = useState(-1);

  const [doctorComment, setDoctorComment] = useState('');
  const [patientComment, setPatientComment] = useState('');

  const labels = [
    ['Muito ruim', 'Ruim', 'Regular', 'Bom', 'Muito bom'],
    [
      'Não recomendo',
      'Pouco provável',
      'Provável',
      'Muito provável',
      'Vou recomendar'
    ]
  ];

  const {
    connect: videoConnect,
    getAudioAndVideoTracks,
    isAcquiringLocalTracks,
    localTracks
  } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const handleRedirectOut = () => {
    if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
      history.push(`/pos-atendimento/${consultation?.key}`);
    } else if (credentials?.token?.session_type === 'limited') {
      if (credentials?.token?.validated === true) {
        history.push('/login/paciente');
      } else {
        history.push('/cadastro/paciente');
      }
    } else {
      history.push('/consultas');
    }
  };

  const handleEndVideo = () => {
    toast(t('A consulta foi encerrada.'), {
      variant: 'success'
    });

    handleRedirectOut();
  };

  const sendReview = async () => {
    let payload;

    if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
      payload = {
        doctor_recommendation_score: ratingDoctorRecomendationScore,
        doctor_comment: doctorComment
      };
    } else {
      payload = {
        patient_service_score: ratingServiceScore,
        patient_platform_score: ratingPlatformScore,
        patient_comment: patientComment,
        patient_doctor_score: ratingProfessionalScore
      };
    }

    await rateTeleconsultation(consultation.key, payload);

    handleEndVideo();
  };

  const handleUserLeft = () => {
    toast(t('Você saiu da sala de vídeo.'), {
      variant: 'success'
    });
    handleRedirectOut();
  };

  const renderModalCTA = () => {
    if (credentials?.token?.session_type === 'limited') {
      if (credentials?.token?.validated === true) {
        return '\nAproveite para fazer o seu login e acompanhe todas as suas informações.';
      }

      return '\nAproveite para fazer o seu cadastro e acompanhe todas as suas informações.';
    }

    return '';
  };

  const renderState = () => {
    switch (roomState) {
      case 'completed':
        return (
          <>
            {modalState == 1 && (
              <Modal
                handleOpenModal={() => handleEndVideo()}
                noClosing="true"
                openModal="true"
                modalTitle={
                  <div className="flex flex-col items-center gap-y-4">
                    <LogoIconBlack />A consulta foi encerrada.
                  </div>
                }
                modalTitleColor="var(--caren-icongrey)"
                modalWidth="490px"
              >
                <p className="mb-8 whitespace-pre-wrap text-center text-lg">{`Obrigado por usar a Telessaúde Caren!${renderModalCTA()}`}</p>
                <Button
                  onClick={() => setModalState(2)}
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  OK
                </Button>
              </Modal>
            )}
            {modalState == 2 && (
              <Modal
                handleOpenModal={() => handleEndVideo()}
                noClosing="true"
                openModal="true"
                modalTitle={
                  credentials?.token?.user_type === ROLE_PROFESSIONAL
                    ? 'Avalie a plataforma'
                    : 'Avalie o médico'
                }
                modalTitleColor="var(--caren-icongrey)"
                modalWidth="490px"
              >
                <div
                  onClick={() => handleEndVideo()}
                  className="absolute right-2 top-0 flex h-14 w-14 cursor-pointer items-center justify-center text-3xl"
                >
                  &times;
                </div>
                {credentials?.token?.user_type != ROLE_PROFESSIONAL && (
                  <div className="mb-10">
                    <p className="text-center text-lg text-gray-950">
                      Como você classifica o atendimento com o(a) profissional{' '}
                      {consultation.doctor?.name}?
                    </p>
                    <div className="mb-2 flex flex-col items-center py-2 md:flex-row md:items-start md:justify-center">
                      <StyledRating
                        className="mb-2 md:mb-0"
                        value={ratingServiceScore}
                        onChange={(event, newValue) => {
                          if (newValue != null) setRatingServiceScore(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setPatientServiceHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55, color: '#B0B0B0' }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {ratingServiceScore !== null && (
                        <div className="md:absolute md:right-12">
                          {
                            labels[0][
                              patientServiceHover !== -1
                                ? patientServiceHover - 1
                                : ratingServiceScore - 1
                            ]
                          }
                        </div>
                      )}
                    </div>
                    <p className="text-center text-lg text-gray-950">
                      Qual a chance de você recomendar esse profissional para um
                      amigo ou familiar?
                    </p>
                    <div className="mb-2 flex flex-col items-center py-2 md:flex-row md:items-start md:justify-center">
                      <StyledRating
                        className="mb-2 md:mb-0"
                        value={ratingProfessionalScore}
                        onChange={(event, newValue) => {
                          if (newValue != null)
                            setRatingProfessionalScore(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setProfessionalHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55, color: '#B0B0B0' }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {ratingProfessionalScore !== null && (
                        <div className="md:absolute md:right-12">
                          {
                            labels[1][
                              professionalHover !== -1
                                ? professionalHover - 1
                                : ratingProfessionalScore - 1
                            ]
                          }
                        </div>
                      )}
                    </div>
                    <p className="text-center text-lg text-gray-950">
                      Como você classifica a plataforma de Telessaúde Caren?
                    </p>
                    <div className="mb-2 flex flex-col items-center py-2 md:flex-row md:items-start md:justify-center">
                      <StyledRating
                        className="mb-2 md:mb-0"
                        value={ratingPlatformScore}
                        onChange={(event, newValue) => {
                          if (newValue != null)
                            setRatingPlatformScore(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setPlatformHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55, color: '#B0B0B0' }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {ratingPlatformScore !== null && (
                        <div className="md:absolute md:right-12">
                          {
                            labels[0][
                              platformHover !== -1
                                ? platformHover - 1
                                : ratingPlatformScore - 1
                            ]
                          }
                        </div>
                      )}
                    </div>
                    <p className="mb-2 text-center text-lg text-gray-950">
                      Gostaria de comentar algo?
                    </p>
                    <TextareaInput
                      onChange={event => setPatientComment(event.target.value)}
                      maxLength={300}
                    ></TextareaInput>
                  </div>
                )}
                {credentials?.token?.user_type === ROLE_PROFESSIONAL && (
                  <div className="mb-5">
                    <p className="text-center text-lg text-gray-950">
                      Quais as chances de você recomendar a Caren para um
                      colega?
                    </p>
                    <div className="mb-2 flex flex-col items-center py-2 md:flex-row md:items-start md:justify-center">
                      <StyledRating
                        className="mb-2 md:mb-0"
                        value={ratingDoctorRecomendationScore}
                        onChange={(event, newValue) => {
                          if (newValue != null)
                            setRatingDoctorRecomendationScore(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setDoctorRecomendationHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55, color: '#B0B0B0' }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {ratingDoctorRecomendationScore !== null && (
                        <div className="md:absolute md:right-12">
                          {
                            labels[1][
                              doctorRecomendationHover !== -1
                                ? doctorRecomendationHover - 1
                                : ratingDoctorRecomendationScore - 1
                            ]
                          }
                        </div>
                      )}
                    </div>
                    <p className="mb-2 text-center text-lg text-gray-950">
                      Gostaria de comentar algo?
                    </p>
                    <TextareaInput
                      onChange={event => setDoctorComment(event.target.value)}
                      maxLength={300}
                    ></TextareaInput>
                  </div>
                )}
                <Button
                  onClick={() => sendReview()}
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  Enviar
                </Button>
              </Modal>
            )}
          </>
        );

      case 'disconnected':
        return <></>;

      default:
        return (
          <Main>
            <ReconnectingNotification />
            <Room onUserLeave={handleUserLeft} />
          </Main>
        );
    }
  };

  useEffect(() => {
    if (!isAcquiringLocalTracks && hasAudioTrack) {
      videoConnect(token);
    }
  }, [token, isAcquiringLocalTracks, hasAudioTrack]);

  useEffect(() => {
    getAudioAndVideoTracks().catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error acquiring local media:', error);
      // eslint-disable-next-line no-console
      console.dir(error);
    });
  }, [getAudioAndVideoTracks]);

  return <Container>{renderState()}</Container>;
}
