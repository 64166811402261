import styled from 'styled-components/macro';

export const ModalContainer = styled.div<{ openModal: boolean }>`
  transition: 1s;
  opacity: ${({ openModal }) => (openModal ? '1' : '0')};
  pointer-events: ${({ openModal }) => (openModal ? 'auto' : 'none')};
  position: fixed;
  z-index: 30;
  padding: 100px 0 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.2s;

  * {
    pointer-events: ${({ openModal }) => (openModal ? 'auto' : 'none')};
  }

  @media (max-height: 815px) {
    padding: 25px 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalInfoContainer = styled.div`
  margin-top: 10px;
  padding: 40px 20px;

  @media (max-width: 720px) {
    display: block;
  }

  @media screen and (min-width: 720px) and (max-width: 960px) {
    display: block;
  }
`;

export const ModalInfo = styled.div`
  padding: 0 20px;

  @media (max-width: 720px) {
    width: 100%;
    padding: 0;
  }

  @media screen and (min-width: 720px) and (max-width: 960px) {
    width: 100%;
  }
`;

function justifyContent() {
  return `
    display: flex;
    justify-content: space-between;
    align-items: center;
    `;
}

export const ModalInfoTitle = styled.h2<{
  modalTitleColor?: string;
  flexTitle?: string;
}>`
  color: ${({ modalTitleColor }) => modalTitleColor ?? 'var(--caren-green)'};
  margin-bottom: 30px;
  font-size: 22px;
  text-align: center;
  white-space: pre-wrap;

  ${({ flexTitle }) => flexTitle && justifyContent()}
`;

export const ModalContent = styled.div<{ modalWidth?: string }>`
  position: relative;
  background-color: #f0f0f0;
  margin: auto;
  border-radius: 10px;
  width: ${({ modalWidth }) => modalWidth ?? '50%'};
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  color: var(--caren-lightgrey);
  font-size: 25px;
  font-weight: bold;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
    color: var(--caren-grey);
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;
