import { type ElementType } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const BUTTON_STYLES = {
  success: {
    icon: 'text-success group-hover:text-white',
    background: 'hover:bg-success'
  },
  primary: {
    icon: 'text-gray-950',
    background: 'bg-yellow-180 hover:bg-yellow-400'
  },
  neutral: {
    icon: 'text-gray-350 group-hover:text-white',
    background: 'hover:bg-gray-350'
  },
  warning: {
    icon: 'text-yellow-750 group-hover:text-white',
    background: 'hover:bg-yellow-750'
  }
};

type IconButtonProps = {
  icon: ElementType;
  title: string;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  type?: 'success' | 'neutral' | 'primary' | 'warning';
  target?: '_blank';
};

export default function IconButton({
  icon: Icon,
  title,
  onClick,
  href,
  target,
  type = 'neutral',
  disabled = false
}: IconButtonProps) {
  if (href) {
    if (disabled) {
      return (
        <div
          className={twMerge(
            'group grid h-9 w-9 cursor-not-allowed place-items-center rounded-xl p-1.5 opacity-30 transition-all duration-300',
            BUTTON_STYLES[type].background
          )}
          title={title}
        >
          <Icon
            className={twMerge(
              'clear h-6 w-6 shrink-0 transition-all duration-300',
              BUTTON_STYLES[type].icon
            )}
          />
        </div>
      );
    }

    return (
      <Link
        title={title}
        to={href}
        target={target}
        className={twMerge(
          'group grid h-9 w-9 place-items-center rounded-xl p-1.5 transition-all duration-300',
          BUTTON_STYLES[type].background
        )}
      >
        <Icon
          className={twMerge(
            'clear h-6 w-6 shrink-0 transition-all duration-300',
            BUTTON_STYLES[type].icon
          )}
        />
      </Link>
    );
  }

  return (
    <button
      title={title}
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={twMerge(
        'group grid h-9 w-9 place-items-center rounded-xl p-1.5 transition-all duration-300 disabled:cursor-not-allowed disabled:opacity-30',
        BUTTON_STYLES[type].background
      )}
    >
      <Icon
        className={twMerge(
          'clear h-6 w-6 shrink-0 transition-all duration-300',
          BUTTON_STYLES[type].icon
        )}
      />
    </button>
  );
}
