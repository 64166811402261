import { useContext } from 'react';
import { CheckoutContext } from '../../components/checkout/context';

export default function useCheckoutForm() {
  const context = useContext(CheckoutContext);

  if (!context)
    throw Error(
      'CheckoutContext should be used within CheckoutContextProvider'
    );

  return context;
}
