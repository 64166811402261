import styled from 'styled-components/macro';

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  width: 100%;
  bottom: 0;
  transform: ;
  transition: transform 0.2s ease-in;
  z-index: 999;
  transform: ${(props) => (props.show ? 'translateY(0%)' : 'translateY(150%)')};
`;

export const NotificationBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: var(--caren-white);
  border: 1px solid var(--caren-input-border);
  bottom: 0;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 2px 2px 2px 0px rgba(170, 170, 170, 0.5);

  @media (max-width: 768px) {
    padding: 5px;
  }

  h2 {
    color: var(--caren-red);
    font-weight: bold;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
`;

export const NotificationBarLeft = styled.div``;

export const NotificationBarRight = styled.div`
  margin-right: 20px;
`;

export const CallButton = styled.button<{ reject: boolean }>`
  color: #fff;
  font-weight: bold;
  min-width: 60px;
  background: ${(props) =>
    props.reject ? 'var(--caren-red)' : 'var(--caren-green)'};
  border-radius: 5px;
  padding: 8px 20px;
  margin: 0 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }

  span {
    position: relative;
    top: 6px;
    font-size: 20px;
    margin-right: 5px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  color: var(--caren-lightgrey);
  font-size: 25px;
  font-weight: bold;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
    color: var(--caren-grey);
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;
