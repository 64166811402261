import React, { useState, useEffect, useRef } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { getConsentTermVersion } from '../../../../services/patient-service';
import { useToast } from '../../../../contexts/ToastContext';
import { CHAT_MARKDOWN } from '../../../../constants/common';
import { FORMAT } from '../../../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../../../utils/moment/momentHelpers';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: '50vw',
    width: 800,
    maxWidth: '100%',
    textAlign: 'left',
  },

  consentTermTitle: {
    textAlign: 'center',
    borderBottom: `1px solid var(--caren-lightgrey)`,
    paddingBottom: theme.spacing(1),
  },

  consentTermActions: {
    justifyContent: 'center',
  },

  loader: {
    margin: '1em',
  },

  actionButton: {
    color: 'white !important',
    fontWeight: 'bold',
  },

  consentmentDateDiv: {
    fontSize: '0.8em',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  buttonDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ConsentTermDialog = ({
  open,
  closeFunc,
  consentTermVersionKey,
  patientConsentTerm,
}) => {
  const { t } = useTranslation(['dialogs']);
  const classes = useStyles();
  const toast = useToast();

  const [consentTermVersion, setConsentTermVersion] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const prevVersionKey = usePrevious(consentTermVersionKey);

  useEffect(() => {
    async function loadConsentTerm() {
      try {
        const data = await getConsentTermVersion(consentTermVersionKey);
        setConsentTermVersion(data);
        setLoaded(true);
      } catch (error) {
        toast(t('Ops, não foi possível carregar o termo de consentimento...'), {
          variant: 'error',
        });
      }
    }

    if (consentTermVersionKey && prevVersionKey !== consentTermVersionKey) {
      loadConsentTerm();
    }
  }, [consentTermVersionKey]);

  function renderConsentTermTitle() {
    const { major, minor, name } = consentTermVersion;

    return `${name} v(${major}.${minor})`;
  }

  function handleClose() {
    setLoaded(false);
    setConsentTermVersion(null);
    closeFunc();
  }

  function formatDate(date) {
    return momentUtcLocal(date).format(FORMAT['pt-datetime']);
  }

  function renderConsentmentDate() {
    const {
      inserted_at: insertedAt,
      updated_at: updatedAt,
    } = patientConsentTerm;

    if (updatedAt) {
      return (
        <div className={classes.consentmentDateDiv}>
          <p>
            {t('Termo consentido em: ')}
            {formatDate(updatedAt)}
          </p>
        </div>
      );
    }

    if (insertedAt) {
      return (
        <div className={classes.consentmentDateDiv}>
          <p>
            {t('Termo consentido em: ')}
            {formatDate(insertedAt)}
          </p>
        </div>
      );
    }

    return null;
  }

  function renderActionButtons() {
    return <div style={{ width: '100%' }}>{renderConsentmentDate()}</div>;
  }

  function renderContent() {
    return (
      <>
        <DialogTitle className={classes.consentTermTitle}>
          {renderConsentTermTitle()}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ReactMarkdown
            className={classes.announcementBody}
            allowedElements={CHAT_MARKDOWN}
          >
            {consentTermVersion.body}
          </ReactMarkdown>
        </DialogContent>
        <DialogActions className={classes.consentTermActions}>
          {renderActionButtons()}
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
    >
      {loaded ? (
        renderContent()
      ) : (
        <DialogContent
          style={{ textAlign: 'center' }}
          className={classes.dialogContent}
        >
          <div style={{ margin: '1em' }}>
            <CircularProgress className={classes.loader} size={100} />
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export default ConsentTermDialog;
