import api from './api';

export const createEmergencyConsultationService = async (
  insurancePartnerCode,
  opts
) => {
  const { data } = await api.post('/teleconsultations/emergency-appointments', {
    ...opts,
    insurance_partner_code: insurancePartnerCode
  });

  return data;
};
