import React from 'react';

type TableRowProps = {
  children: React.ReactNode
}

export default function TableRow({ children }: TableRowProps) {
  return (
    <tr className="w-full flex flex-col items-center lg:table-row">
      {children}
    </tr>
  );
}
