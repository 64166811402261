import moment from 'moment';
import { FORMAT } from '../utils/moment/momentFormat';
import api from './api';

export const doctorsService = async (params) => {
  const { data } = await api.get('/doctors', {
    params: { ...params }
  });

  return data;
};

export const doctorDetailsService = async (key) => {
  const { data } = await api.get(`/pages/${key}`);

  return data;
};

export const doctorValidateSlugService = async (suffix) => {
  const { status } = await api.head(`/employees/slug/${suffix}`);

  return Math.floor(status / 100) === 2;
};

export const doctorReceiptService = async (query) => {
  const { data } = await api.get(
    '/doctors/teleconsultations/bank-transfer-receipts',
    {
      params: { ...query }
    }
  );

  return data;
};

export const doctorRecurringHours = async () =>
  await api.get('/recurring-availabilities');

export const doctorRemoveRecurringHour = async (key) =>
  await api.delete(`/recurring-availabilities/${key}`);

export const doctorUpdateRecurringHour = async (key, payload) =>
  await api.put(`/recurring-availabilities/${key}`, {
    ...payload
  });

export const doctorUpdateTeleconsultationSettings = async (payload) =>
  await api.put('/employees/teleconsultation-settings', {
    ...payload
  });

export const doctorCreateRecurring = async (payload) => {
  const { data } = await api.post('/recurring-availabilities/batch', {
    ...payload
  });

  return data;
};

export const doctorRecurringAvailabilities = async (params) => {
  const hour = (_date) =>
    moment.utc(_date).format(FORMAT['hour-minute-second']);
  const date = (_date) => moment.utc(_date).format(FORMAT['us-date']);

  return await api.get('/recurring-availabilities/previews', {
    params: {
      weekdays: params.weekdays,
      start_date: date(params.date),
      start_time: hour(params.from),
      end_time: hour(params.to),
      end_date: params.end_date ? date(params?.end_date) : null,
      interval: params.interval
    }
  });
};

export const doctorCreateVoucherService = async (params) => {
  const { data } = await api.post('/teleconsultations/vouchers', params);

  return data;
};

export const doctorGetVoucherService = async (query) => {
  const { data } = await api.get('/teleconsultations/vouchers', {
    params: { ...query }
  });

  return data;
};

export const doctorDeleteVoucherService = async (key) => {
  const { data } = await api.delete(`/teleconsultations/vouchers/${key}`);

  return data;
};

export const doctorCreateInvitationService = async (params) => {
  const { data } = await api.post('/teleconsultations/invitations', params);

  return data;
};

export const doctorGetInvitationsService = async (query) => {
  const { data } = await api.get('/teleconsultations/invitations', {
    params: { ...query }
  });

  return data;
};

export const doctorGetSchedule = async (params) => {
  let url = `/employees/schedule?from=${params.from}`;

  if (params.to) url += `&to=${params.to}`;
  if (params.search) url += `&search=${params.search}`;
  if (params.limit) url += `&limit=${params.limit}`;

  const { data } = await api.get(url);

  return data;
};

export const doctorCancelConsultationService = async (key, selectedDoctor) => {
  const params = {};
  if (selectedDoctor) params.employee_key = selectedDoctor.key;

  const { data } = await api.post(
    `/teleconsultations/${key}/cancel`,
    {},
    { params }
  );

  return data;
};

export const doctorDeleteInvitationService = async (key, selectedDoctor) => {
  const params = {};
  if (selectedDoctor) params.employee_key = selectedDoctor.key;

  const { data } = await api.delete(`/teleconsultations/invitations/${key}`, {
    params
  });

  return data;
};

export const doctorDeclineInvitationService = async (key) => {
  const { data } = await api.put(
    `/teleconsultations/invitations/${key}/reject`
  );

  return data;
};

export const doctorResendInvitationService = async (key, selectedDoctor, send_email = true) => {
  const params = {};
  if (selectedDoctor) params.employee_key = selectedDoctor.key;

  const { data } = await api.put(
    `/teleconsultations/invitations/${key}/resend`,
    { send_email: send_email },
    { params }
  );

  return data;
};

export const doctorSignupService = async (params) =>
  await api.post('/doctors/signup', { ...params });
