import { useCallback, useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/candle.svg';

type HeaderCellProps = {
  title: string;
  onChangeOrder?: (order: null | 'asc' | 'desc') => void;
}

export default function HeaderCell({ title, onChangeOrder }: HeaderCellProps) {
  const [order, setOrder] = useState<null | 'asc' | 'desc'>(null);

  const onOrderClik = useCallback(() => {
    let newOrder: null | 'asc' | 'desc';

    switch (order) {
      case null:
        newOrder = 'asc';
        break;
      case 'asc':
        newOrder = 'desc';
        break;
      case 'desc':
        newOrder = null;
        break;
    }

    setOrder(newOrder);
    if (onChangeOrder) onChangeOrder(newOrder);
  }, [onChangeOrder, order]);

  return (
    <th className="px-8 py-5 text-center font-nunito text-xs font-bold uppercase text-gray-450 first:text-left">
      {title}
      {onChangeOrder && (
        <SettingsIcon
          role="button"
          tabIndex={0}
          onClick={onOrderClik}
          aria-label="Order Change"
          className="ml-1.5 inline text-gray-450"
        />
      )}
    </th>
  );
}
