// Available Countries
const AVAILABLE_COUNTRIES = ['Brasil'];

// The order in which country flags appear in the selector
const COUNTRIES = ['AO', 'BR'];

// Mapping of 'country code' -> 'display name'
const COUNTRY_LABELS = {
  GB: 'EN',
  BR: 'PT-BR',
  AO: 'PT-AO'
};

// Mapping of 'country code' -> 'locale code'
const COUNTRY_LOCALES: Record<string, string> = {
  BR: 'pt-BR',
  // GB: 'en',
  AO: 'pt-AO'
};

// Mapping of 'locale code' -> 'country code'
const LOCALE_COUNTRIES: Record<string, string> = Object.fromEntries(
  Object.entries(COUNTRY_LOCALES).map((e) => [...e].reverse())
);

// Mapping of 'country acronym' -> 'country name'
const COUNTRY_NAMES: Record<string, string> = {
  // GB: 'United Kingdom',
  BR: 'Brasil',
  AO: 'Angola'
};

// Mapping of 'country acronym/name' -> 'country preposition'
const COUNTRY_PREPOSITIONS: Record<string, string> = {
  GB: 'na',
  // 'United Kingdom': 'na',
  BR: 'no',
  Brasil: 'no',
  AO: 'em',
  Angola: 'em'
};

// Public API
export const availableCountries = AVAILABLE_COUNTRIES;
export const countries = COUNTRIES;
export const locales = Object.values(COUNTRY_LOCALES);
export const countryLabels = COUNTRY_LABELS;
export const countryNames = COUNTRY_NAMES;
export const countryPreposition = COUNTRY_PREPOSITIONS;
export const countryToLocale = (country: string) => COUNTRY_LOCALES[country]
export const localeToCountryAcronym = (locale: string) => LOCALE_COUNTRIES[locale];
export const countryAcronymToName = (acronym: string) => COUNTRY_NAMES[acronym];
export const countryAcronymToPreposition = (acronym: string) =>
  COUNTRY_PREPOSITIONS[acronym];
export const countryNameToPreposition = (name: string) => COUNTRY_PREPOSITIONS[name];
