import React, { useEffect, useState } from 'react';
import { type Doctor } from '../../entities/doctor';
import { getDoctors } from '../../../services/secretary';
import PersonImage from '../data/personImage';
import { type ActiveSubscription } from '../../entities/active_subscription';

type SelectDoctorsProps = {
  onSelectDoctor: (doctor: Doctor) => void;
}

type DoctorsWithSubscription = {
  employee: Doctor;
  active_subscription: ActiveSubscription;
}

const conformDoctorAndSubscription = (response: DoctorsWithSubscription[]) => {
  const completeDoctors: Doctor[] = [];

  response.forEach(doctor => {
    const newEmployee = {
      ...doctor.employee,
      active_subscription: doctor.active_subscription
    };
    completeDoctors.push(newEmployee);
  });

  return completeDoctors;
};

export default function SelectDoctors({ onSelectDoctor }: SelectDoctorsProps) {
  const [doctors, setDoctors] = useState<Doctor[] | null>(null);

  useEffect(() => {
    if (doctors === null) {
      getDoctors().then(result => {
        setDoctors(conformDoctorAndSubscription(result));
      });
    }
  }, [doctors]);

  return (
    <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      <div className="col-span-full flex items-center justify-center">
        <p className="font-nunito text-lg font-semibold text-blue-650">
          Selecione um Profissional de Saúde
        </p>
      </div>
      {doctors === null && (
        <>
          <div className="h-20 w-full animate-pulse rounded-md bg-gray-200" />
          <div className="h-20 w-full animate-pulse rounded-md bg-gray-200" />
          <div className="h-20 w-full animate-pulse rounded-md bg-gray-200" />
          <div className="h-20 w-full animate-pulse rounded-md bg-gray-200" />
        </>
      )}
      {doctors && doctors.length <= 0 && (
        <>
          <div className="col-span-full flex items-center justify-center py-10">
            <p className="font-nunito text-base text-gray-250">
              Você não possui vínculo com nenhum profissional.
            </p>
          </div>
        </>
      )}
      {doctors &&
        doctors.length > 0 &&
        React.Children.toArray(
          doctors.map(doctor => (
            <button
              type="button"
              onClick={() => {
                onSelectDoctor(doctor);
              }}
              className="flex h-20 w-full items-center gap-x-4 rounded-md bg-white p-4"
            >
              <div className="h-14 w-14">
                <PersonImage name={doctor.name} image={doctor.avatar_url} />
              </div>
              <p className="font-nunito text-base text-gray-950">
                {doctor.name}
              </p>
            </button>
          ))
        )}
    </div>
  );
}
