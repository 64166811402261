import React, { useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Hidden,
  IconButton
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview';
import SettingsMenu from './SettingsMenu';
import ToggleAudioButton from '../Buttons/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton';
import { useVideoAppState } from '../../../hooks/video/useVideoAppState';
import useVideoContext from '../../../hooks/video/useVideoContext';
import useLocalAudioToggle from '../../../hooks/video/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/video/useLocalVideoToggle';
import { Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'var(--caren-grey)',
    fontSize: 21,
    fonWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  marginTop: {
    marginTop: '1em'
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0'
  },
  localPreviewContainer: {
    overflow: 'hidden',
    borderRadius: 6,
    position: 'relative',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'flex-end'
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0'
      }
    }
  },
  mobileButtonBar: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
    columnGap: 50
  },
  mobileAdjustButton: {
    position: 'absolute',
    color: 'var(--caren-white)',
    fill: 'var(--caren-white)',
    padding: 0,
    margin: 0,
    minWidth: 0,
    top: 10,
    right: 0
  },
  buttonHover: {
    '& svg path': {
      fill: 'var(--caren-icongrey) !important'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    backgroundColor: 'var(--caren-white)',
    borderRadius: '100%',
    width: 60,
    height: 60,
    padding: 0,
    margin: 0,
    minWidth: 'unset',
    display: 'grid',
    placeItems: 'center'
  },
  buttonHoverDisabled: {
    '& svg path': {
      fill: 'var(--caren-red) !important'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    backgroundColor: 'var(--caren-white)',
    borderRadius: '100%',
    width: 60,
    height: 60,
    padding: 0,
    margin: 0,
    minWidth: 'unset',
    display: 'grid',
    placeItems: 'center'
  }
}));

export default function DeviceSelectionScreen({ name, haveAudioPermission }) {
  const classes = useStyles();
  const { mediaError, setMediaError } = useVideoAppState();

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  const {
    isAcquiringLocalTracks,
    getAudioAndVideoTracks,
    localTracks,
    isConnecting
  } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  const disableButtons =
    isAcquiringLocalTracks || !hasAudioTrack || isConnecting;

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  useEffect(() => {
    haveAudioPermission(!disableButtons);
  }, [disableButtons]);

  return (
    <div className="hidden flex-col md:flex">
      <Typography variant="h5" className={classes.title}>
        Teste suas configurações de áudio e vídeo antes da consulta
      </Typography>

      <div className={classes.localPreviewContainer}>
        <LocalVideoPreview identity={name} />
        <SettingsMenu mobileButtonClass={classes.mobileAdjustButton} />
      </div>
      <div className={classes.mobileButtonBar}>
        <IconButton
          className={
            isAudioEnabled ? classes.buttonHover : classes.buttonHoverDisabled
          }
          onClick={toggleAudioEnabled}
          disabled={disableButtons}
        >
          {isAudioEnabled ? <Mic /> : <MicOff />}
        </IconButton>
        <IconButton
          className={
            isVideoEnabled ? classes.buttonHover : classes.buttonHoverDisabled
          }
          onClick={toggleVideoEnabled}
          disabled={disableButtons}
        >
          {isVideoEnabled ? <Videocam /> : <VideocamOff />}
        </IconButton>
      </div>
    </div>
  );
}
