import React, { createContext, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  SnackbarProvider,
  useSnackbar,
  type SnackbarMessage,
  type SnackbarKey,
  type SnackbarProviderProps,
 type OptionsObject
} from 'notistack';
import throttle from 'lodash.throttle';

const ToastContext = createContext<any | null>(null);

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 500000,
    paddingBottom: 'env(safe-area-inset-bottom)',
    pointerEvents: 'auto',
    cursor: 'pointer'
  },
  success: { backgroundColor: '#009C78' },
  error: { backgroundColor: 'var(--caren-red)' },
  warning: { backgroundColor: 'var(--caren-orange)' },
  info: { backgroundColor: '#3485CB' },
  buttonOverlay: {
    background: 'transparent',
    border: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  }
}));

function ThrottleProvider({ children }: { children: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();

  const persistentEnqueue = useCallback(
    (text: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(text, { ...options }),
    []
  );

  const value = throttle(persistentEnqueue, 200);

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
}

type ToastProviderProps = {
  children: React.ReactNode
} & SnackbarProviderProps

function ToastProvider({ children, ...props }: ToastProviderProps) {
  const classes = useStyles();
  const notistackRef = React.createRef<SnackbarProvider>();

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      {...props}
      action={(key) => (
        <button
          className={classes.buttonOverlay}
          onClick={onClickDismiss(key)}
          type="button"
          aria-label="Fechar"
        />
      )}
      classes={{
        containerRoot: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info
      }}
      autoHideDuration={5000}
    >
      <ThrottleProvider>{children}</ThrottleProvider>
    </SnackbarProvider>
  );
}

function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}

export { ToastProvider, useToast };
