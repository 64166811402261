import { useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import BaseDialog from '../base';

type FirstAccessDialogHandles = {
  open: () => void;
};

const FirstAccessDialog = forwardRef<FirstAccessDialogHandles>((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    }
  }));

  return (
    <BaseDialog
      title="Seja bem-vindo(a) à Caren!"
      isOpen={isOpen}
      onClose={() => {
        localStorage.removeItem('@Caren:firstAccess');
        setIsOpen(false);
      }}
      disableBackdropClick
    >
      <div className="flex w-full flex-col items-center">
        <img
          className="mb-6 h-48"
          alt="Doctor Icon"
          src="https://cdn.media.caren.app/email/doctor_doodle_black.png"
        />
        <p className="mx-8 mb-6 text-center text-base text-blue-650">
          Complete o seu cadastro e realize <strong>10 consultas grátis</strong>
          !
        </p>
        <p className="mx-8 mb-6 text-center text-base text-blue-650">
          Antes do seu primeiro atendimento, é importante que você{' '}
          <strong>inclua uma foto</strong> e <strong>preencha os dados</strong>{' '}
          do seu perfil na área &quot;Meus Dados&quot;.
        </p>
        <p className="mb-6 text-base text-blue-650">
          Para preencher seus dados agora, clique no botão abaixo.
        </p>
        <div className="flex items-center gap-x-10">
          <button
            type="button"
            onClick={() => {
              localStorage.removeItem('@Caren:firstAccess');
              history.push('/perfil');
            }}
            className="h-14 w-fit rounded-md bg-yellow-650 px-10 font-nunito text-base font-bold text-white transition-all duration-200 hover:bg-yellow-750"
          >
            Preencher dados
          </button>
        </div>
      </div>
    </BaseDialog>
  );
});

FirstAccessDialog.displayName = 'FirstAccessDialog';
export type { FirstAccessDialogHandles };
export default FirstAccessDialog;
