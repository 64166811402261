import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  makeStyles,
  TextField,
  IconButton,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button,
  MenuItem
} from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import LoadingComponent from '../../components/Loading';
import history from '../../services/history';
import Modal from '../../components/Modal';
import { availableCountries } from '../../utils/countryLocaleUtils';
import { ReactComponent as LogoBlack } from '../../assets/images/logo-black.svg';

import {
  FormContainer,
  CreateDoctorButton,
  CreateDoctorContainer,
  ErrorMessageText,
  LabelInputContainer,
  GlobalStyle
} from './form.styles';
import {
  teamSignupService,
  acceptTeamInvitation,
  getDataFromTeamInvitation,
  denyTeamInvitation
} from '../../services/team';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_SECRETARY } from '../../routes/constants';
import CheckboxInput from '../../v2/components/inputs/input/checkbox';

const useStyles = makeStyles(_theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },

  title: {
    color: 'var(--caren-lightgrey)',
    fontWeight: 'normal',
    fontSize: '1.4em'
  },

  inviteTitle: {
    color: 'var(--caren-lightgrey)',
    fontWeight: 'normal',
    fontSize: '1.4em',
    textAlign: 'center'
  },

  inviteButtons: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    columnGap: '20px',
    marginTop: '20px',

    '& button': {
      padding: '5px 20px',
      width: '100%',
      height: '100%',
      maxWidth: '150px',
      borderRadius: '8px',
      fontSize: '14px',
      fontWeight: '600',
      color: 'var(--caren-white)',
      transition: 'all ease-in-out 200ms',

      '&.deny': {
        backgroundColor: 'var(--caren-lightgrey)',

        '&:hover': {
          filter: 'brightness(115%)'
        }
      },

      '&.accept': {
        backgroundColor: 'var(--caren-darkgreen)',
        color: 'var(--caren-icongrey)',

        '&:hover': {
          filter: 'brightness(115%)'
        }
      }
    }
  },

  showPasswordButton: {
    position: 'relative'
  },

  passwordFieldMargin: {
    marginRight: '-40px'
  },

  legend: {
    width: '51%',
    padding: '18.5px 14px',
    marginBottom: '0px'
  },

  radioGroup: {
    width: '100%'
  },

  buttonText: {
    color: 'var(--caren-white) !important',
    fontWeight: 'bold',
    fontSize: '1em',
    textTransform: 'uppercase'
  }
}));

function TeamSignup({
  computedMatch: {
    params: { invite_token: inviteToken }
  }
}) {
  const { credentials } = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [haveAccount, setHaveAccount] = useState(false);
  const [invite, setInvite] = useState(null);

  const TITLE = 'Cadastro de equipe - Caren';

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue
  } = useForm();
  const { t } = useTranslation(['doctor_signup']);

  const validationErrorMessages = {
    required: t('Este campo é obrigatório'),
    passwordRules: t('A senha deve cumprir as regras de segurança'),
    passwordMinLength: t('Senha deve ter no mínimo 8 caracteres'),
    specialtiesMax: t('No máximo 3 especialidades'),
    passwordsMustMatch: t('As senhas devem ser iguais'),
    invalidEmail: t('E-mail inválido'),
    invalidCPF: t('CPF inválido'),
    phoneTooShort: t('Celular inválido')
  };

  async function handleCreateTeam(dataParams) {
    const formData = dataParams;

    formData.login = formData.email;

    if (formData.password === formData.password_confirmation) {
      setLoading(true);
      try {
        await teamSignupService(formData);

        setOpenModal(true);
      } catch (error) {
        if (error?.response?.data?.error?.message?.login) {
          toast(`Email ${error.response.data.error.message.login}`, {
            variant: 'error'
          });
        } else if (error?.response?.data?.error?.message?.fiscal_code) {
          toast(`CPF ${error.response.data.error.message.fiscal_code}`, {
            variant: 'error'
          });
        } else
          toast(t('Não foi possível efetuar o cadastro, tente novamente.'), {
            variant: 'error'
          });
      }
      setLoading(false);
    }
  }

  const classes = useStyles();

  function handlePasswordType() {
    if (showPassword) {
      return 'text';
    }

    return 'password';
  }

  function showPasswordOnClick() {
    setShowPassword(!showPassword);
  }

  function renderShowPasswordButton() {
    if (!showPassword) {
      return (
        <IconButton
          className={classes.showPasswordButton}
          onClick={showPasswordOnClick}
        >
          <FaEyeSlash size={16} />
        </IconButton>
      );
    }

    return (
      <IconButton
        className={classes.showPasswordButton}
        onClick={showPasswordOnClick}
      >
        <FaEye size={16} color="red" />
      </IconButton>
    );
  }

  function validateConfirmPassword(confirmPassword) {
    if (confirmPassword !== getValues('password')) {
      return validationErrorMessages.passwordsMustMatch;
    }

    return null;
  }

  const getInviteInitialInformations = useCallback(async () => {
    const inviteData = await getDataFromTeamInvitation(inviteToken);

    if (inviteData === null) {
      toast(
        t(
          'Convite inválido ou expirado, solicite novamente o convite para o médico.'
        ),
        {
          variant: 'error'
        }
      );
      history.push('/');
    } else {
      setInvite(inviteData);
      setValue('email', inviteData.email);

      if (credentials?.token) {
        if (
          credentials &&
          credentials.token.user_type === ROLE_SECRETARY &&
          inviteData.accepted_at === null &&
          inviteData.rejected_at === null
        ) {
          if (inviteData.email === credentials?.session.email) {
            setHaveAccount(true);
          } else {
            toast(t('Este convite não faz parte da sua conta.'), {
              variant: 'error'
            });
            history.push('/');
          }
        } else {
          toast(t('Este convite só pode ser aceito por uma conta de equipe.'), {
            variant: 'error'
          });
          history.push('/');
        }
      } else if (inviteData.secretary !== null) {
        toast(t('Realize o login antes de aceitar o convite.'), {
          variant: 'error'
        });
        history.push('/login', {
          referrer: `/cadastro-equipe/${inviteToken}`
        });
      }
    }
  }, []);

  const updateInviteStatus = useCallback(
    async accept => {
      if (accept) {
        await acceptTeamInvitation(invite.key, inviteToken);
        history.replace('/');
      } else {
        await denyTeamInvitation(invite.key, inviteToken);
        history.replace('/');
      }
    },
    [invite]
  );

  const handleCPF = value => {
    const onlyNumbers = value.replace(/\D/g, '').slice(0, 11);
    const formatted = onlyNumbers.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      (_, one, two, three, four) => `${one}.${two}.${three}-${four}`
    );

    setValue('fiscal_code', formatted, { shouldValidate: true });
  };

  useEffect(() => {
    if (inviteToken) {
      getInviteInitialInformations();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <GlobalStyle />
      {openModal && (
        <Modal
          openModal={openModal}
          handleOpenModal={() => ''}
          modalTitle="Sua conta foi criada com sucesso!"
          modalWidth="40%"
          modalTitleColor="#000"
          noClosing
        >
          <Button
            onClick={() => {
              history.push('/login', {
                referrer: `/cadastro-equipe/${inviteToken}`
              });
            }}
            color="primary"
            fullWidth
            variant="contained"
            classes={{
              label: classes.buttonText
            }}
          >
            Fazer Login
          </Button>
        </Modal>
      )}
      <CreateDoctorContainer>
        {haveAccount ? (
          <section>
            <h1 className={classes.inviteTitle}>
              Deseja aceitar o convite de {invite?.employee?.name ?? ''} para
              fazer parte da equipe?
            </h1>
            <div className={classes.inviteButtons}>
              <button
                onClick={async () => {
                  await updateInviteStatus(true);
                }}
                className="accept"
                type="button"
              >
                SIM
              </button>
              <button
                onClick={async () => {
                  await updateInviteStatus(false);
                }}
                className="deny"
                type="button"
              >
                NÃO
              </button>
            </div>
          </section>
        ) : (
          <section>
            <LogoBlack className="w-32" />
            <h1 className={classes.title}>{t('Cadastro de equipe')}</h1>
            <FormContainer onSubmit={handleSubmit(handleCreateTeam)}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: validationErrorMessages.required }}
                render={fields => (
                  <TextField
                    label={t('Nome Completo')}
                    type="text"
                    variant="outlined"
                    fullWidth
                    inputRef={fields.field.ref}
                    {...fields.field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <ErrorMessageText>{message}</ErrorMessageText>
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                render={fields => (
                  <TextField
                    label="CPF"
                    variant="outlined"
                    type="text"
                    fullWidth
                    inputRef={fields.field.ref}
                    {...fields.field}
                    onBlur={e => {
                      handleCPF(e.target.value);
                    }}
                  />
                )}
                name="fiscal_code"
                rules={{
                  required: validationErrorMessages.required,
                  pattern: {
                    value: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                    message: validationErrorMessages.invalidCPF
                  }
                }}
              />
              <ErrorMessage
                errors={errors}
                name="fiscal_code"
                render={({ message }) => (
                  <ErrorMessageText>{message}</ErrorMessageText>
                )}
              />
              <LabelInputContainer>
                <FormLabel className={classes.legend} component="legend">
                  {t('Sexo biológico')}
                </FormLabel>
                <Controller
                  control={control}
                  defaultValue=""
                  render={fields => (
                    <RadioGroup
                      className={classes.radioGroup}
                      {...fields.field}
                      row
                    >
                      <FormControlLabel
                        name="gender"
                        value="female"
                        control={<Radio />}
                        label={t('Feminino')}
                        inputRef={fields.field.ref}
                      />
                      <FormControlLabel
                        name="gender"
                        value="male"
                        control={<Radio />}
                        label={t('Masculino')}
                        inputRef={fields.field.ref}
                      />
                    </RadioGroup>
                  )}
                  name="gender"
                  rules={{ required: validationErrorMessages.required }}
                />
              </LabelInputContainer>
              <ErrorMessage
                errors={errors}
                name="gender"
                render={({ message }) => (
                  <ErrorMessageText>{message}</ErrorMessageText>
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                render={fields => (
                  <TextField
                    label="E-mail"
                    variant="outlined"
                    type="email"
                    fullWidth
                    InputProps={{
                      readOnly: !!inviteToken
                    }}
                    inputRef={fields.field.ref}
                    {...fields.field}
                  />
                )}
                name="email"
                rules={{
                  required: validationErrorMessages.required,
                  pattern: {
                    // value: emailRegex,
                    message: validationErrorMessages.invalidEmail
                  }
                }}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <ErrorMessageText>{message}</ErrorMessageText>
                )}
              />
              <Controller
                control={control}
                defaultValue="Brasil"
                name="country"
                render={fields => (
                  <TextField
                    select
                    label={t('País')}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                    inputRef={fields.field.ref}
                    {...fields.field}
                  >
                    {availableCountries.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: t('Por favor informe o país.')
                }}
              />
              <ErrorMessage
                errors={errors}
                name="country"
                as={<ErrorMessageText />}
              />
              <Controller
                control={control}
                defaultValue=""
                name="state"
                render={fields => (
                  <TextField
                    type="text"
                    label={t('Estado')}
                    variant="outlined"
                    fullWidth
                    inputRef={fields.field.ref}
                    {...fields.field}
                  />
                )}
                rules={{
                  required: validationErrorMessages.required
                }}
              />
              <ErrorMessage
                errors={errors}
                name="state"
                as={<ErrorMessageText />}
              />
              <Controller
                control={control}
                defaultValue=""
                name="city"
                render={fields => (
                  <TextField
                    type="text"
                    label={t('Cidade')}
                    variant="outlined"
                    fullWidth
                    inputRef={fields.field.ref}
                    {...fields.field}
                  />
                )}
                rules={{
                  required: validationErrorMessages.required
                }}
              />
              <ErrorMessage
                errors={errors}
                name="city"
                as={<ErrorMessageText />}
              />

              <Controller
                control={control}
                defaultValue=""
                type="password"
                rules={{
                  required: validationErrorMessages.required,
                  minLength: {
                    value: 8,
                    message: validationErrorMessages.passwordMinLength
                  }
                }}
                render={fields => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <TextField
                      className={classes.passwordFieldMargin}
                      label={t('Senha')}
                      type={handlePasswordType()}
                      variant="outlined"
                      fullWidth
                      inputRef={fields.field.ref}
                      {...fields.field}
                    />
                    {renderShowPasswordButton()}
                  </div>
                )}
                name="password"
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <ErrorMessageText>{message}</ErrorMessageText>
                )}
              />
              <Controller
                className={classes.formInput}
                control={control}
                defaultValue=""
                type="password"
                render={fields => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <TextField
                      className={classes.passwordFieldMargin}
                      label={t('Confirme a senha')}
                      type={handlePasswordType()}
                      variant="outlined"
                      fullWidth
                      inputRef={fields.field.ref}
                      {...fields.field}
                    />
                    {renderShowPasswordButton()}
                  </div>
                )}
                name="password_confirmation"
                rules={{
                  required: validationErrorMessages.required,
                  minLength: {
                    value: 8,
                    message: validationErrorMessages.passwordMinLength
                  },
                  validate: validateConfirmPassword
                }}
              />
              <ErrorMessage
                errors={errors}
                name="password_confirmation"
                render={({ message }) => (
                  <ErrorMessageText>{message}</ErrorMessageText>
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="accept_terms"
                render={fields => (
                  <CheckboxInput
                    type="checkbox"
                    inputRef={fields.field.ref}
                    {...fields.field}
                  >
                    <span>Li e aceito os&nbsp;</span>
                    <a
                      className="underline hover:text-yellow-750 hover:underline"
                      href="https://www.caren.app/paginas/termos-de-uso-telessaude"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Termos de Uso
                    </a>
                    <span>&nbsp;e&nbsp;</span>
                    <a
                      className="underline hover:text-yellow-750 hover:underline"
                      href="https://www.caren.app/paginas/aviso-de-privacidade"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Políticas de Privacidade
                    </a>
                    <span>.</span>
                  </CheckboxInput>
                )}
                rules={{
                  required: validationErrorMessages.required
                }}
              />
              <ErrorMessage
                errors={errors}
                name="accept_terms"
                as={<ErrorMessageText />}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CreateDoctorButton
                  bgColor="var(--caren-greenblue)"
                  type="submit"
                >
                  {loading ? <LoadingComponent /> : t('Enviar')}
                </CreateDoctorButton>
              </div>
            </FormContainer>
          </section>
        )}
      </CreateDoctorContainer>
    </>
  );
}

export default TeamSignup;
