import { z } from 'zod';

export const ProfileCompletenessSchema = z.object({
  profile_completeness: z.number(),
  criteria: z.object({
    document: z.boolean(),
    office: z.boolean(),
    phone: z.boolean(),
    profile_picture: z.boolean(),
    fiscal_code: z.boolean(),
    professional_code: z.boolean(),
    specialty: z.boolean(),
    confirmed_email: z.boolean()
  })
});

export type ProfileCompleteness = z.infer<typeof ProfileCompletenessSchema>;
