import { AiOutlineCalendar } from 'react-icons/ai';
import styled from 'styled-components/macro';

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DoctorScheduleIcon = styled(AiOutlineCalendar).attrs({
  size: 25,
  color: 'var(--caren-icongrey)'
})``;

export const DoctorScheduleIconNotification = styled.div`
  background-color: var(--caren-orange);
  width: 13px;
  height: 13px;
  border-radius: 100%;

  position: absolute;
  right: -2px;
  bottom: -2px;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 7px;
    color: var(--caren-white);
    margin-bottom: 2px;
  }
`;
