import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import {
  Container,
  MainText,
  ProcessContainer,
  UserProcessContainer,
  ProcessLine,
  DoctorCamIcon,
  DivRelativeFlex,
  DoctorGraph,
  DoctorTimeBox,
  DoctorLogo,
  ReadyInfo,
  NurseLogo,
  GetInRoom,
  GetInIcon,
} from '../consult-wait-room.styles';
import ContainerOpacity from '../../../components/ContainerOpacity';
import UserSidebar from '../../../components/Sidebar/UserSidebar';
import ConsultWaitRoomHeader from './emergencyWaitingQueueHeader';
import history from '../../../services/history';
import { useAuth } from '../../../hooks/AuthContext';
import { useSocket } from '../../../hooks/SocketContext';

const DoctorTimeName = styled.p`
  position: absolute;
  bottom: -40px;
  left: auto;
  right: auto;
  color: var(--caren-icongrey);
  font-size: 22px;
  text-align: center;
  width: 110%;
  @media (max-width: 768px) {
    bottom: -125px;
  }
`;

export default function EmergencyWaitingQueue({
  computedMatch: {
    params: { emergency_id, insurance_partner },
  },
}) {
  const {
    credentials,
  } = useAuth();

  const [patientIndex, setPatientIndex] = useState(null);
  const [requestUpdate, setRequestUpdate] = useState(false);

  const CHANNEL_TOPIC = `emergency:${insurance_partner}`;

  const { channel } = useSocket(
    CHANNEL_TOPIC,
    { appointment_key: emergency_id },
    {
      enableJoin: !!insurance_partner && !!emergency_id,
      afterJoin: listenToMessages,
    }
  );

  let queuePositionTimer = null;

  useEffect(() => () => {
    if (queuePositionTimer != null) {
      clearInterval(queuePositionTimer);
    }
  });

  useEffect(() => {
    if (requestUpdate) {
      handleQueueUpdated();
      setRequestUpdate(false);
    }
  }, [requestUpdate]);

  function listenToMessages(joined, payload) {
    const { userPosition } = payload;

    setPatientIndex(userPosition);

    joined.on('queueUpdated', handleQueueUpdated);
    joined.on('queueMatch', handleQueueMatch);

    queuePositionTimer = setInterval(() => setRequestUpdate(true), 15000);
  }

  function handleQueueUpdated(_payload) {
    if (channel == null) return;

    channel
      .push('queuePosition', {})
      .receive('ok', ({ queuePosition }) => setPatientIndex(queuePosition));
  }

  function handleQueueMatch(payload) {
    const { teleconsultationKey } = payload;

    history.push(`/consultas/${teleconsultationKey}/sala-de-espera`);
  }

  const { t } = useTranslation(['consult_wait_room']);

  const dots = new Array(15).fill(true);
  const showInitButton = false;

  const options = {
    responsive: false,
    maintainAspectRatio: true,
    legend: {
      display: false,
      color: 'red',
    },
    tooltips: {
      enabled: false,
    },
    height: 100,
    cutoutPercentage: 70,
  };

  const TITLE = 'Fila de Espera do Pronto Atendimento - Caren';

  function renderQueuePosition() {
    if (patientIndex === 0) return 'Próximo';

    if (!patientIndex) return 'N/A';

    // Changing from index to position
    return patientIndex + 1;
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <ConsultWaitRoomHeader />
      <Container>
        <MainText>
          {t('Posição Fila de Espera do Pronto Atendimento:')}
        </MainText>
        <MainText>{renderQueuePosition()}</MainText>
        <ProcessContainer>
          <UserProcessContainer>
            <NurseLogo opacity={0.5} />
          </UserProcessContainer>

          {dots.map((_, index) => (
            <ProcessLine
              color="var(--caren-lightgrey)"
              key={index.toString()}
            />
          ))}
          <DoctorTimeBox>
            <DivRelativeFlex>
              <DoctorGraph height={200} zeroPadTime={0} options={options} />
              <DoctorLogo
                src={credentials?.session.avatar_url}
                title={credentials?.session.name}
                titleSize={52}
                backgroundStyle={{
                  width: 141,
                  height: 141,
                  borderRadius: 140,
                  backgroundColor: 'var(--caren-image-fallback)',
                  position: 'absolute',
                }}
                imageStyle={{ position: 'absolute' }}
              />
              <DoctorTimeName>
                Estamos aguardando um médico para o seu atendimento.
              </DoctorTimeName>
            </DivRelativeFlex>
          </DoctorTimeBox>
          {dots.map((_, index) => (
            <ProcessLine
              color={showInitButton ? 'var(--caren-lightgrey)' : '#e8e8e8'}
              key={index.toString()}
            />
          ))}
          <UserProcessContainer>
            <DoctorCamIcon opacity={showInitButton ? 1 : 0.5} />
            {showInitButton && (
              <GetInRoom>
                <GetInIcon />
              </GetInRoom>
            )}
          </UserProcessContainer>
        </ProcessContainer>
        <br />
        <ReadyInfo noFlex>
          <p>
            {/* {t('Seu tempo de espera médio é de X minutos.')} <br /> */}
            {t(
              'Fique tranquilo, seu plano só será cobrado caso um médico inicie o seu atendimento.'
            )}
            <br />
            {t(
              'Caso desista da consulta, é necessário apenas sair desta página.'
            )}
          </p>
        </ReadyInfo>
      </Container>
    </>
  );
}
