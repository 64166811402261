import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';
import history from '../../services/history';
import { useAuth } from '../../hooks/AuthContext';
import { GUEST_ROUTES } from '../../constants/routes';
import useEventListener from '../../hooks/useEventListener';

const WithAxios = ({ children }) => {
  const { credentials, refreshSession, clearSession } = useAuth();

  const { t } = useTranslation(['common']);
  const toast = useToast();

  const inGuestPath = pathname =>
    GUEST_ROUTES.some(route => matchPath(pathname, route));

  const handleMessage = useCallback(
    e => {
      if (e.origin === window.location.origin) {
        const message = e.data;

        switch (message.type) {
          case 'refreshSession': {
            if (credentials?.token != null) {
              const { token: csrfToken } = message.data;
              refreshSession({ ...credentials.token, token: csrfToken });
            }
            break;
          }

          case 'clearSession': {
            clearSession();
            break;
          }

          case 'axiosError': {
            const { status } = message.data;
            const referrer = history.location?.pathname;

            if (
              credentials?.token?.token != null &&
              status === 401 &&
              !inGuestPath(referrer)
            ) {
              toast(
                t('Sua sessão expirou. Por favor faça o login novamente.'),
                {
                  variant: 'warning'
                }
              );
              clearSession();
              history.push('/login', { referrer });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    },
    [credentials, refreshSession, clearSession]
  );

  useEventListener('message', handleMessage);

  return children;
};

export default WithAxios;
