import { createContext, useState } from 'react';

type PatientSignupContextValues = {
  actualStep: number
  maxSteps: number
  forwardStep: () => void
  backwardStep: () => void
  steps: Record<number, unknown>
  setStepData: (step: number, data: unknown) => void
}

const PatientSignupContext = createContext<PatientSignupContextValues | null>(
  null
);

const PatientSignupContextProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [step, setStep] = useState(1);
  const [stepsData, setStepsData] = useState<Record<number, unknown>>({});

  const maxSteps = 2;

  const forwardStep = () => {
    setStep((old) => (old >= maxSteps ? maxSteps : old + 1));
  };

  const backwardStep = () => {
    setStep((old) => (old <= 1 ? 1 : old + -1));
  };

  const setStepData = (stepNumber: number, data: unknown) => {
    setStepsData((old) => {
      old[stepNumber] = data;
      return { ...old };
    });
  };

  return (
    <PatientSignupContext.Provider
      value={{
        actualStep: step,
        forwardStep,
        backwardStep,
        steps: stepsData,
        maxSteps,
        setStepData
      }}
    >
      {children}
    </PatientSignupContext.Provider>
  );
};

export { PatientSignupContext };
export default PatientSignupContextProvider;
