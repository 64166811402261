import React, { useContext, useState, useEffect } from 'react';
import { MdCall } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useSocket } from '../../hooks/SocketContext';
import { EmergencyPatientGlobalState } from '../../store';
import history from '../../services/history';
import * as Styled from './styles';
import { useToast } from '../../contexts/ToastContext';
import UnreversibleConfirmationModal from '../UnreversibleConfirmationModal';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PROFESSIONAL } from '../../routes/constants';

export default function EmergencyPatient() {
  const {
    credentials,
  } = useAuth();
  const {
    insurancePartner,
    setInsurancePartner,
    available,
    setAvailable,
    setLoading,
  } = useContext(EmergencyPatientGlobalState);
  const [patientCount, setPatientCount] = useState(0);
  const [calling, setCalling] = useState(false);
  const [callConfirmationOpen, setCallConfirmationOpen] = useState(false);
  const toast = useToast();
  const { t } = useTranslation(['emergency_patient']);
  const currentURL = window.location.href;
  const onVideCall = currentURL.includes('/video-call/');
  const onWaitingRoom = currentURL.includes('/sala-de-espera');
  const onPostAppointment = currentURL.includes('/pos-atendimento/');

  useEffect(() => {
    if (credentials && credentials.token.user_type !== ROLE_PROFESSIONAL) {
      setAvailable(false);
      setLoading(false);
      setInsurancePartner('');
    }
  }, [credentials]);

  useEffect(() => {
    if (available) {
      setLoading(true);
    }
  }, [available]);

  const CHANNEL_TOPIC = `emergency:${insurancePartner}`;

  const { channel } = useSocket(
    CHANNEL_TOPIC,
    {},
    { enableJoin: insurancePartner != null, afterJoin: listenToMessages }
  );

  function listenToMessages(joined, payload) {
    const { queueLength } = payload;

    setPatientCount(queueLength);
    setLoading(false);

    joined.on('queueLength', handleQueueLength);
  }

  function handleQueueLength(payload) {
    const { value } = payload;

    setPatientCount(value);
  }

  function handleOpenCallConfirmation() {
    setCallConfirmationOpen(true);
  }

  function handleCallNext(confirmed) {
    if (!confirmed) {
      setCallConfirmationOpen(false);
      return;
    }

    setCalling(true);

    channel
      .push('queuePop', {})
      .receive('ok', ({ teleconsultationKey }) => {
        setCalling(false);
        setCallConfirmationOpen(false);
        history.push(`/consultas/${teleconsultationKey}/sala-de-espera`);
      })
      .receive('error', () => {
        setCalling(false);
        setCallConfirmationOpen(false);
        toast(t('Não é possível atender no momento, verifique sua agenda.'), {
          variant: 'error',
        });
      });
  }

  function handleBecomeUnavailable() {
    setAvailable(false);
  }

  return (
    <>
      <Styled.Container
        show={
          !onPostAppointment &&
          !onWaitingRoom &&
          !onVideCall &&
          !calling &&
          patientCount > 0
        }
      >
        <Styled.NotificationBar>
          <Styled.CloseButton onClick={handleBecomeUnavailable}>
            &times;
          </Styled.CloseButton>
          <Styled.NotificationBarLeft>
            <Styled.CallButton onClick={handleOpenCallConfirmation}>
              <span>
                <MdCall />
              </span>
              Atender agora
            </Styled.CallButton>
          </Styled.NotificationBarLeft>
          <Styled.NotificationBarRight>
            <h2>
              {patientCount === 1
                ? 'Paciente disponível para pronto atendimento'
                : `${patientCount} pacientes disponíveis para pronto atendimento`}
            </h2>
          </Styled.NotificationBarRight>
        </Styled.NotificationBar>
      </Styled.Container>
      <UnreversibleConfirmationModal
        title={t(
          'Atender o próximo paciente da fila? Será iniciada uma teleconsulta imediatamente.'
        )}
        openModal={callConfirmationOpen}
        setOpenModal={setCallConfirmationOpen}
        handleConfirmation={handleCallNext}
      />
    </>
  );
}
