import api from './api';

export const patientService = async patientKey => {
  const { data } = await api.get(`/patients/${patientKey}`);

  return data;
};

export const patientHistoryService = async patientKey => {
  const { data } = await api.get(
    `/employees/patients/${patientKey}/partner-appointments`
  );

  return data;
};

export const scheduleDetailsService = async consultationKey => {
  const { data } = await api.get(`/teleconsultations/${consultationKey}`);

  return data;
};

export const listOfSchedulesService = async query => {
  const { data } = await api.get('/teleconsultations', {
    params: { ...query }
  });

  return data;
};

export const listPatientAppointmentsService = async afterKey => {
  const { data } = await api.get('/patients/schedule', {
    params: { after: afterKey }
  });

  return data;
};

export const getPatientConsentTermVersion = async () => {
  const { data } = await api.get('/patients/consent-term-versions');

  return data;
};

export const getConsentTermVersion = async consentTermVersionKey => {
  const { data } = await api.get(
    `/consent-term-versions/${consentTermVersionKey}`
  );

  return data;
};

export const removePatientConsentTermVersion = async consentTermVersionKey => {
  const { data } = await api.delete(
    `/patients/consent-term-versions/${consentTermVersionKey}`
  );

  return data;
};

export const completeSignup = async formData => {
  const { data } = await api.put('/patients/complete-signup', formData);

  return data;
};

export const getSignUpInvitation = async responseToken => {
  const { data } = await api.get(`/patients/complete-signup/${responseToken}`);

  return data;
};
