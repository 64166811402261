// import React from 'react';
import { type PropsWithChildren, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import logoRecaptcha from '../../assets/images/logo-recaptcha.png';

type CarenReCaptchaProps = {
  handleCaptcha: (response: string | null) => void;
  captchaReloadTime: number;
};

export default function CarenReCaptcha({
  handleCaptcha,
  captchaReloadTime
}: PropsWithChildren<CarenReCaptchaProps>) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha();

    handleCaptcha(token);
  }, [executeRecaptcha, captchaReloadTime]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return null;
}
