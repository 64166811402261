import React from 'react';

import Button from '@material-ui/core/Button';
import { MicIcon } from '../../Icons/MicIcon';
import { MicOffIcon } from '../../Icons/MicOffIcon';

import useLocalAudioToggle from '../../../../hooks/video/useLocalAudioToggle';
import useVideoContext from '../../../../hooks/video/useVideoContext';
import { useVideoAppState } from '../../../../hooks/video/useVideoAppState';

export default function ToggleAudioButton({ className, disabled }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const { mediaError } = useVideoAppState();
  const permissionError =
    mediaError?.name === 'NotAllowedError' ||
    mediaError?.message === 'MicrophonePermissionsDenied';
  const hasAudioTrack =
    !permissionError && localTracks.some((track) => track.kind === 'audio');

  const renderButtonCaption = () => {
    if (permissionError) return 'Habilite a permissão no navegador';

    if (!hasAudioTrack) return 'Sem áudio';

    if (isAudioEnabled) return 'Ativar mudo';

    return 'Desativar mudo';
  };

  return (
    <Button
      className={className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || disabled}
      startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      data-cy-audio-toggle
    >
      {renderButtonCaption()}
    </Button>
  );
}
