import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { getPatientConsentTermVersion } from '../../../../services/patient-service';
import { useToast } from '../../../../contexts/ToastContext';
import ConsentTermDialog from '../ConsentTermDialog';

const useStyles = makeStyles((_theme) => ({
  mainDiv: {
    textAlign: 'center',
  },

  termName: {
    color: 'var(--caren-icongrey)',
  },

  loader: {
    margin: '0.5em',
  },
}));

export default function MyConsentmentTerms() {
  const toast = useToast();
  const classes = useStyles();

  const { t } = useTranslation(['tabs']);

  const [loaded, setLoaded] = useState(false);
  const [patientsConsentTerms, setPatientsConsentTerms] = useState([]);
  const [open, setOpen] = useState(false);
  const [consentTermVersionKey, setConsentTermVersionKey] = useState(null);
  const [patientConsentTermVersion, setPatientConsentTermVersion] =
    useState(null);

  useEffect(() => {
    async function getPatientVersios() {
      try {
        const { data } = await getPatientConsentTermVersion();
        setPatientsConsentTerms(data);
        setLoaded(true);
      } catch (error) {
        toast(t('Ops, não foi possível carregar o histórico...'), {
          variant: 'error',
        });
      }
    }

    if (!loaded) {
      getPatientVersios();
    }
  }, [patientsConsentTerms]);

  function handleClick(patientConsentVersion) {
    const { consent_term_version: consentTermVersion } = patientConsentVersion;
    setPatientConsentTermVersion(patientConsentVersion);
    setConsentTermVersionKey(consentTermVersion.key);
    setOpen(true);
  }

  function handleCloseDialog() {
    setConsentTermVersionKey(null);
    setOpen(false);
  }

  function submitCallback() {
    setLoaded(false);
    setPatientsConsentTerms([]);
  }

  function renderConsentTermTitle(consentTermVersion) {
    const { major, minor, name } = consentTermVersion;

    return `${name} v(${major}.${minor})`;
  }

  return (
    <div className={classes.mainDiv}>
      {loaded ? (
        <div>
          {patientsConsentTerms.map((pctv) => (
            <Button
              style={{ margin: '0.5em' }}
              onClick={() => handleClick(pctv)}
              variant="outlined"
              color="primary"
              fullWidth
              key={pctv.consent_term_version.key}
            >
              <p className={classes.termName}>
                {renderConsentTermTitle(pctv.consent_term_version)}
              </p>
            </Button>
          ))}
        </div>
      ) : (
        <CircularProgress className={classes.loader} size={100} />
      )}
      <ConsentTermDialog
        open={open}
        closeFunc={handleCloseDialog}
        consentTermVersionKey={consentTermVersionKey}
        submitCallback={submitCallback}
        patientConsentTerm={patientConsentTermVersion}
      />
    </div>
  );
}
