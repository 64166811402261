import styled from 'styled-components/macro';
import { MdFileUpload } from 'react-icons/md';
import UploadLogoDynamic from '../../components/UploadLogoDynamic';
import ImageOrName from '../../components/ImageOrName';

export const UploadContainer = styled.div`
  position: relative;
`;

export const CloseButton = styled.div`
  position: absolute;
  bottom: -3px;
  right: -3px;
  color: var(--caren-white);
  background-color: #444;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
    color: var(--caren-white);
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: var(--caren-white);
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ButtonUpload = styled.button`
  padding: 6px 23px;
  background: var(--caren-green);
  border-radius: 30px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
`;

export const Container = styled.div`
  background-color: var(--caren-white);
  padding: 40px;
`;

export const FindADoctor = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  p {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  span {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  div {
    display: flex;
  }
`;

export const MainText = styled.p`
  color: var(--caren-grey);
  font-size: 27px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const Text = styled.p`
  color: var(--caren-lightgrey);
  font-size: 18px;
  font-weight: bold;
`;

export const ProcessContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const ProcessFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

export const UserProcessContainer = styled.div``;

export const UserProcessBackground = styled.div`
  background-color: #f8f8f8;
  border-radius: 50%;
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 110px;
    height: 110px;
  }
`;

export const UserProcessImg = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  background-color: var(--caren-white);
  border-radius: 50%;
  width: 160px;
  height: 160px;
  opacity: ${({ opacity }) => opacity ?? 1};

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const ProcessLine = styled.div`
  min-width: 5px;
  min-height: 5px;
  background-color: ${({ color }) => color ?? '#888'};
  border-radius: 100%;

  min-width: 5px;
  min-height: 5px;
  background-color: ${({ color }) => color ?? '#888'};
  border-radius: 100%;

  @media (max-width: 1220px) {
    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 1110px) {
    margin: 0 3px;
    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin: 3px 0;
    &:nth-child(3n + 0) {
      display: none;
    }
  }
`;

export const UploadIcon = styled(UploadLogoDynamic).attrs(({ color }) => ({
  size: 50,
  color: color ? 'var(--caren-green)' : 'var(--caren-lightgrey)'
}))``;

export const Border = styled.div`
  border-bottom: 1px solid var(--caren-lightgrey);
  margin: 50px 200px;

  @media (max-width: 768px) {
    margin: 50px 0;
  }
`;

export const BorderBox = styled.div`
  margin-top: 10px;
  border: 1px solid var(--caren-lightgrey);
`;

export const ProcessTime = styled.div`
  margin: 0 24px;

  div:first-child {
    padding: 5px 10px;
  }

  .time-title {
    background-color: var(--caren-warning);

    p {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      svg {
        margin-left: 10px;
      }
    }
  }

  .time-success {
    background-color: var(--caren-green);

    p {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      svg {
        margin-left: 10px;
      }
    }
  }

  .time {
    font-size: 50px;
    color: var(--caren-grey);
    padding: 20px 0;
    text-align: center;
  }
`;

export const DepositData = styled.div`
  margin: 0 24px;
`;

export const BankDetails = styled.div`
  background-color: #f8f8f8;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
`;

export const BankLogo = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  height: 40px;
`;

export const BankInfo = styled.div`
  margin-left: 15px;
  color: var(--caren-grey);
`;

export const SendReceipt = styled.div`
  border: 2px solid var(--caren-grey);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 2px;
  border-radius: 15px;
  margin: 0 10px;
  margin-top: 10px;
  color: var(--caren-lightgrey);
  cursor: pointer;
`;

export const ReceiptLogo = styled(MdFileUpload).attrs({
  size: 30
})``;

export const FooterText = styled.p`
  width: 100%;
  text-align: center;
  color: var(--caren-grey);
`;
