import React, { useState, useEffect } from 'react';
import { EmergencyPatientGlobalState } from '../../store';
import EmergencyPatientComponent from './index';

const EmergencyPatientProvider = ({ children }) => {
  const LOCALSTORAGE_KEY = 'doctor-emergency-insurance-code';

  let savedAvailability = false;
  const savedInsurancePartner = localStorage.getItem(LOCALSTORAGE_KEY) || '';

  if (savedInsurancePartner !== '') {
    savedAvailability = true;
  }

  const [available, setAvailable] = useState(savedAvailability);
  const [insurancePartner, setInsurancePartner] = useState(
    savedInsurancePartner
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, insurancePartner);
  }, [insurancePartner]);

  return (
    <>
      <EmergencyPatientGlobalState.Provider
        value={{
          available,
          setAvailable,
          loading,
          setLoading,
          insurancePartner,
          setInsurancePartner,
        }}
      >
        {available && <EmergencyPatientComponent />}
        {children}
      </EmergencyPatientGlobalState.Provider>
    </>
  );
};

export default EmergencyPatientProvider;
