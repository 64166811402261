import api from './api';

export const doctorFutureAvailabilitiesService = async (
  key,
  type = 'default',
  from,
  to
) => {
  const { data } = await api.get(
    `/doctors/${key}/teleconsultation-slots?type=${type}&from=${from}&to=${to}`
  );

  return data;
};
