import { Link } from 'react-router-dom';
import { type VariantProps, tv } from 'tailwind-variants';
import { ArrowOutward } from '@mui/icons-material';
import { type LocationDescriptor } from 'history';
import React from 'react';

const statsBoxStyles = tv({
  slots: {
    title: 'text-balance border-b border-gray-200 pb-2 text-center text-xs',
    value: 'pt-2',
    arrow: 'rounded-full p-1 text-xs text-white'
  },
  variants: {
    size: {
      small: {
        value: 'text-xl 2xl:text-3xl'
      },
      medium: {
        value: 'text-2xl 2xl:text-4xl'
      }
    },
    color: {
      yellow: {
        arrow: 'bg-yellow-650'
      },
      green: {
        arrow: 'bg-success-light'
      }
    },
    type: {
      full: {
        title: 'px-4'
      },
      normal: {
        title: 'px-2'
      }
    }
  },
  defaultVariants: {
    size: 'medium',
    color: 'yellow',
    type: 'normal'
  }
});

const tailwindClasses = statsBoxStyles();

type StatsBoxVariants = VariantProps<typeof statsBoxStyles>;

type StatsBoxProps = {
  title: string;
  value?: string | number;
  subValue?: string | number;
  link?: LocationDescriptor;
  disabled?: boolean;
  children?: React.ReactNode;
} & StatsBoxVariants;

export default function StatsBox({
  title,
  value,
  subValue,
  link,
  disabled,
  children,
  ...props
}: StatsBoxProps) {
  return (
    <div className="relative m-1 min-h-[125px] w-28 rounded-lg bg-white px-4 py-3 text-center font-bold text-gray-400 xl:m-2 xl:w-[115px]">
      <p className={tailwindClasses.title(props)}>{title}</p>
      {value !== undefined && (
        <p className={tailwindClasses.value(props)}>
          {value}
          {subValue !== undefined && (
            <span className="text-lg">{subValue}</span>
          )}
        </p>
      )}
      {!disabled && (
        <div className="absolute bottom-1 right-2">
          {link !== undefined ? (
            <Link to={link}>
              <div className={tailwindClasses.arrow(props)}>
                <ArrowOutward fontSize="small" />
              </div>
            </Link>
          ) : (
            <div className="rounded-full bg-gray-200 p-1 text-xs text-white">
              <ArrowOutward fontSize="small" />
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
}
