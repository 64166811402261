import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { type AxiosResponse } from 'axios';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ReactComponent as LogoBlack } from '../../../../assets/images/logo-black.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/arrow-left.svg';

import LoadingComponent from '../../../../components/Loading';
import { useAuth } from '../../../../hooks/AuthContext';
import { useToast } from '../../../../contexts/ToastContext';
import Modal from '../../../../components/Modal';
import { forgotPasswordService } from '../../../../services/forgot-password.service';
import HomeSidebar from '../../../../components/Sidebar/HomeSidebar';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CarenReCaptcha from '../../../../components/reCaptcha';

type SignInError = {
  error: boolean;
  response: AxiosResponse | null | undefined;
  message?: string | null;
};

type SignInData = {
  login: string;
  password: string;
};

type ForgotPasswordData = {
  email: string;
};

type LoginProfessionalProps = {
  computedMatch: {
    params: {
      type: string;
    };
  };
};
export default function LoginProfessional({
  computedMatch: {
    params: { type: ProfessionalType }
  }
}: LoginProfessionalProps) {
  const [loading, setLoading] = useState(false);
  const [signInError, setSignInError] = useState<SignInError>({
    response: null,
    error: false
  });
  const [openModal, setOpenModal] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const [captchaReloadTime, setCaptchaReloadTime] = useState(
    new Date().getTime()
  );
  const captchaEnabled = import.meta.env.VITE_RECAPTCHA_KEY != null;

  const {
    handleSubmit,
    control: loginControl,
    formState: { errors: loginErrors },
    clearErrors: clearLoginErrors
  } = useForm<SignInData>();
  const {
    handleSubmit: handleForgetSubmit,
    formState: { errors: forgetErrors },
    control: forgetControl,
    clearErrors,
    reset: resetForgotPassword
  } = useForm<ForgotPasswordData>({ shouldFocusError: false });

  const { t } = useTranslation(['login']);
  const loginRef = useRef<HTMLInputElement>(null);
  const forgotPasswordRef = useRef<HTMLInputElement>(null);

  const { signIn } = useAuth();
  const toast = useToast();

  function handleOpenModal() {
    setOpenModal(!openModal);
    clearErrors();
  }

  async function handleForgotPassword(data: { email: string }) {
    try {
      await forgotPasswordService({
        email: data.email
      });
      toast(t('Enviamos instruções para o seu endereço de e-mail.'), {
        variant: 'success'
      });
      setOpenModal(!openModal);
      resetForgotPassword();
    } catch (error) {
      toast(
        t('Não foi possível solicitar no momento. Por favor, tente novamente.'),
        {
          variant: 'error'
        }
      );
    }
  }

  function handleSignInResponse(response: SignInError) {
    if (response.error) {
      setCaptchaReloadTime(new Date().getTime());
    }
    setSignInError(response);
  }

  const onSubmit = async (data: { login: string; password: any }) => {
    if (!captchaEnabled || captchaResponse) {
      setLoading(true);
      handleSignInResponse({ response: null, error: false });
      try {
        const result = await signIn({
          login: data.login,
          password: data.password,
          recaptchaResponse: captchaResponse
        });
        handleSignInResponse({
          error: result.error,
          response: result.response
        });
      } catch (error) {
        toast(t('No momento, não foi possível realizar o login.'), {
          variant: 'error'
        });
      }
      setLoading(false);
    } else {
      handleSignInResponse({
        error: true,
        response: null,
        message: 'Por favor tente novamente.'
      });
    }
  };

  const handleCaptchaVerification = (response: string | null) => {
    if (response) {
      setCaptchaResponse(response);
    }
  };

  const handleChangeLogin = (
    e: React.ChangeEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    const target = e.target as HTMLButtonElement;

    if (/^_*\.___\.___-__$/.test(target.value)) {
      if (ref.current) {
        ref.current.value = '';
      }
    }

    onChange(e);
  };

  const renderSignInError = () => {
    if (signInError?.message) {
      return (
        <span className="mt-4 font-bold text-error">
          {signInError?.message}
        </span>
      );
    }

    if (signInError?.response?.status === 401) {
      return (
        <span className="mt-4 font-bold text-error">
          {t('Login ou senha inválida.')}
        </span>
      );
    }

    if (signInError?.response?.status === 403) {
      return (
        <span className="mt-4 font-bold text-error">
          {t('Por favor verifique que você não é um robô.')}
        </span>
      );
    }

    if (signInError?.error)
      return (
        <span className="mt-4 font-bold text-error">
          {t('Ocorreu um erro ao entrar, por favor tente novamente.')}
        </span>
      );

    return null;
  };

  return (
    <>
      <Helmet title="Telessaúde Caren" />
      <HomeSidebar onCloseCallback={() => {}} />
      <div className="flex min-h-screen w-full flex-col bg-yellow-150">
        <div className="flex items-center justify-between px-4 pt-4 lg:px-16 lg:pt-10">
          <a href="https://www.caren.app/tele">
            <LogoBlack className="w-32" />
          </a>
          <Link
            to={
              ProfessionalType === 'doctor'
                ? '/cadastro/profissional'
                : '/cadastro-equipe'
            }
            className="text-end md:flex md:items-center md:gap-x-3 md:p-4"
          >
            <span
              className="flex w-24 font-nunito text-base
                           font-bold text-gray-950 underline
                           md:w-auto md:rounded-xl md:border-2
                             md:border-gray-950 md:px-6 md:py-3 md:no-underline"
            >
              Cadastro de&nbsp;usuário
            </span>
          </Link>
        </div>
        <div className="mt-10 flex-1 lg:mt-24">
          <h1 className="text-center font-nunito text-3xl lg:text-5xl">
            {ProfessionalType === 'doctor'
              ? 'Profissional de saúde'
              : 'Secretária de saúde'}
          </h1>
          <form
            className="mx-auto mt-8 flex w-[512px] max-w-[95%] flex-col items-center justify-between rounded-xl bg-white px-12 py-12"
            onSubmit={handleSubmit(onSubmit)}
          >
            <>
              <div className="flex w-full flex-col">
                <div>
                  <Controller
                    control={loginControl}
                    name="login"
                    defaultValue=""
                    render={({ field: { onChange, ...fields } }) => (
                      <input
                        className="h-8 w-full rounded border-solid border-gray-350 bg-transparent py-6 pr-10 text-base"
                        type="text"
                        placeholder="E-mail"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeLogin(e, loginRef, onChange);
                          clearLoginErrors('login');
                        }}
                        {...fields}
                        ref={loginRef}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                {loginErrors && loginErrors.login && (
                  <span className="text-error">
                    Por favor digite o seu e-mail.
                  </span>
                )}

                <div className="relative mt-4">
                  <Controller
                    control={loginControl}
                    name="password"
                    defaultValue=""
                    render={({ field: { ...fields } }) => (
                      <input
                        className="h-8 w-full rounded border-solid border-gray-350 bg-transparent py-6 pr-10 text-base"
                        type={visiblePassword ? 'text' : 'password'}
                        placeholder="Senha"
                        {...fields}
                      />
                    )}
                    rules={{ required: true }}
                  />

                  <button
                    type="button"
                    className="absolute right-1 top-1/2 -translate-y-1/2 p-2 text-gray-350"
                    onClick={() => {
                      setVisiblePassword(!visiblePassword);
                    }}
                  >
                    {visiblePassword ? (
                      <VisibilityOffIcon fontSize="small" />
                    ) : (
                      <VisibilityIcon fontSize="small" />
                    )}
                  </button>
                </div>

                {loginErrors && loginErrors.password && (
                  <span className="text-error">
                    Por favor digite a sua senha.
                  </span>
                )}
              </div>

              {captchaEnabled && (
                <GoogleReCaptchaProvider
                  reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
                >
                  <CarenReCaptcha
                    handleCaptcha={handleCaptchaVerification}
                    captchaReloadTime={captchaReloadTime}
                  />
                </GoogleReCaptchaProvider>
              )}

              {renderSignInError()}

              <button className="mt-6 flex w-full items-center justify-center rounded-xl bg-gray-950 p-4 font-nunito text-xl text-white transition ease-in-out hover:scale-105">
                {loading ? <LoadingComponent /> : t('Entrar')}
              </button>

              <div className="mt-6">
                <button
                  type="button"
                  className="text-gray-450"
                  onClick={handleOpenModal}
                >
                  {t('Esqueceu a senha?')}
                  <strong>{t(' Clique aqui!')}</strong>
                </button>
              </div>
            </>
          </form>

          <Modal
            handleOpenModal={handleOpenModal}
            openModal={openModal}
            modalTitle="Esqueceu a senha?"
            modalWidth="40%"
            modalTitleColor="black"
          >
            <form onSubmit={handleForgetSubmit(handleForgotPassword)}>
              <Controller
                control={forgetControl}
                name="email"
                defaultValue=""
                render={({ field: { onChange, ...fields } }) => (
                  <input
                    className="h-8 w-full rounded border-solid border-gray-350 bg-transparent py-6 pr-10 text-base"
                    type="text"
                    placeholder="Por favor digite o seu e-mail"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeLogin(e, forgotPasswordRef, onChange);
                    }}
                    {...fields}
                    ref={forgotPasswordRef}
                  />
                )}
                rules={{ required: true }}
              />

              {forgetErrors && forgetErrors.email && (
                <span className="text-error">
                  Por favor digite o seu e-mail.
                </span>
              )}

              <button
                type="submit"
                className="mt-6 flex w-full items-center justify-center rounded-xl bg-gray-950 p-4 font-nunito text-xl text-white transition ease-in-out hover:scale-105"
              >
                {t('Enviar')}
              </button>
            </form>
          </Modal>
          <div className="mt-4 flex flex-row justify-center">
            <Link to="/login" className="flex items-center gap-x-3 p-4">
              <BackArrow className="w-4 text-black" />
              <span className="uppercase text-black">Voltar</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
