import { type AxiosResponse } from 'axios';
import api from '../../services/api';
import { ProfileCompletenessSchema } from '../entities/user';

export async function confirmEmail(token: string) {
  const response = await api.put('/email-verifications', { token });

  return response.status === 200;
}

export async function resendEmailConfirmation() {
  const response = await api.post('/email-verifications');

  return response.status === 200;
}

export async function getProfileCompleteness() {
  const { data } = await api.get<any, AxiosResponse<{ data: unknown }>>(
    '/employees/profile-completeness'
  );

  if (data?.data) {
    return ProfileCompletenessSchema.parse(data.data);
  }

  return undefined;
}
