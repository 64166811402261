import styled from 'styled-components/macro';

export const Content = styled.div`
  background-color: var(--caren-white);
  padding: 10px;
  margin: 0 auto;

  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px #343f4b2a;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

export const Button = styled.button`
  background-color: var(--caren-green);
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px 15px;
  color: var(--caren-white);
  font-weight: bold;
  width: 100%;
`;
