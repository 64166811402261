import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { Container } from './account-recovery.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import history from '../../services/history';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PATIENT, ROLE_PROFESSIONAL } from '../../routes/constants';
import { useToast } from '../../contexts/ToastContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AccountRecovery() {
  const iframeElement = useRef(null);
  const toast = useToast();
  const {
    t,
    i18n: { language },
  } = useTranslation(['signup']);
  const { persistSession } = useAuth();
  const TITLE = 'Recuperação de Senha - Caren';
  const IFRAME_THEME = 'patient';
  const query = useQuery();
  const recoveryToken = query.get('t');
  const IFRAME_SRC = combineURLs(
    import.meta.env.VITE_IFRAME_DOMAIN,
    `/account_recovery?theme=telemed-${IFRAME_THEME}&t=${recoveryToken}&lng=${language}`
  );
  const IFRAME_ORIGIN = new URL(import.meta.env.VITE_IFRAME_DOMAIN).origin;

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.origin !== IFRAME_ORIGIN) return;

      const { data: message } = event;

      switch (message.type) {
        case 'REDIRECTION': {
          history.push('/login', { noRedirect: true });
          return;
        }

        case 'LOGIN': {
          const { token, user_type: userType } = message;

          try {
            await persistSession({
              token,
              user_type:
                userType === 'patient' ? ROLE_PATIENT : ROLE_PROFESSIONAL,
            });

            if (userType === ROLE_PROFESSIONAL) {
              history.push('/consultorio');
              return;
            }

            history.push('/consultas');
          } catch (error) {
            toast(t('Ops, estamos passando por instabilidades...'), {
              variant: 'error',
            });
          }
          break;
        }

        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header leftAction={null} rightAction={null} />
      <Container>
        <iframe
          ref={iframeElement}
          src={IFRAME_SRC}
          title={TITLE}
          style={{ flex: 1, width: '100%', border: 'none' }}
        />
      </Container>
    </>
  );
}
