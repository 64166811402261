import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import filesize from 'filesize';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Typography,
} from '@mui/material';
import { CallReceived, Attachment } from '@mui/icons-material';
import {
  ALLOWED_MARKDOWN,
  CHAT_MARKDOWN,
  CLINICAL_EVALUATION_FIELDS,
  HUMAN_BODY_REGEX,
} from '../../../../../../../constants/common';
import parseOptionsMessage from '../../../../../../../utils/caren/parseOptionsMessage';
import isUploadMessage from '../../../../../../../utils/caren/isUploadMessage';
import isOptionsMessage from '../../../../../../../utils/caren/isOptionsMessage';
import isSymptomsMessage from '../../../../../../../utils/caren/isSymptomsMessage';
import buildSymptomReport from '../../../../../../../utils/caren/buildSymptomReport';
import ReadOnlyClinicalEvaluationField from '../ClinicalEvaluation/ReadOnlyClinicalEvaluationField';
import { useToast } from '../../../../../../../contexts/ToastContext';
import { retrieveTimelineUpload } from '../../../../../../../services/timeline_upload.service';

const Anamnesis = ({ detail, showClinicalEvaluation = true }) => {
  const toast = useToast();
  const { t } = useTranslation(['human_body_parts']);
  const renderClinicalEvaluation = (evaluation) => (
    <List>
      {CLINICAL_EVALUATION_FIELDS.map((field) => (
        <ListItem>
          <ListItemText
            primary={field.label}
            secondary={
              <ReadOnlyClinicalEvaluationField
                field={field}
                clinicalEvaluation={evaluation}
              />
            }
          />
        </ListItem>
      ))}
    </List>
  );

  const renderQuestionText = (question) => (
    <ReactMarkdown allowedElements={CHAT_MARKDOWN}>{question}</ReactMarkdown>
  );

  const renderHumanBodyList = (list) =>
    list
      /* i18next-extract-disable-next-line */
      .map((part) => ` * ${t(part, { ns: 'human_body_parts' })}`)
      .filter(Boolean)
      .join('\n');

  const renderOptionsList = (answer) =>
    parseOptionsMessage(answer).join('\n\n');

  const renderAnswerText = (answer) => {
    const humanBodyMatch = answer.match(HUMAN_BODY_REGEX);

    if (humanBodyMatch) {
      const [_, humanBodyRawContent] = humanBodyMatch;
      const humanBodyList = humanBodyRawContent.split(';');

      return renderHumanBodyList(humanBodyList);
    }

    if (isSymptomsMessage(answer)) {
      return buildSymptomReport(answer);
    }

    if (isOptionsMessage(answer)) {
      return renderOptionsList(answer);
    }

    if (isUploadMessage(answer)) {
      return '📎 Arquivo(s) em anexo:';
    }

    return answer;
  };

  const redirectToFile = async ({ key }) => {
    try {
      const data = await retrieveTimelineUpload(key);

      window.location.href = data.url;
    } catch (error) {
      toast(t('Ops, não foi possível carregar o anexo...'), {
        variant: 'error',
      });
    }
  };

  const renderUploads = (uploads) => (
    <List dense>
      {uploads.map((upload) => (
        <ListItem>
          <ListItemIcon>
            <CallReceived fontSize="small" />
            <Attachment fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <button
              type="button"
              onClick={() => {
                redirectToFile(upload);
              }}
            >
              {upload.title} ({filesize(upload.size)})
            </button>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );

  const renderAnswerAndUploads = (question) => (
    <>
      <ReactMarkdown allowedElements={ALLOWED_MARKDOWN}>
        {renderAnswerText(question.answer)}
      </ReactMarkdown>
      {renderUploads(question.uploads)}
    </>
  );

  const renderQuestionList = (questions) => (
    <List>
      {questions.map((question) => (
        <ListItem>
          <ListItemText
            primary={renderQuestionText(question.text)}
            secondary={renderAnswerAndUploads(question)}
          />
        </ListItem>
      ))}
    </List>
  );

  if (detail?.flow?.detail) {
    return (
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', backgroundColor: '#f8f8f8' }}
          >
            Pré-atendimento
          </Typography>
        </Grid>
        <Grid item>{renderQuestionList(detail?.flow?.detail)}</Grid>

        {showClinicalEvaluation && detail?.clinical_evaluation != null && (
          <>
            <Grid item>
              <Typography
                variant="h6"
                sx={{ textAlign: 'center', backgroundColor: '#f8f8f8' }}
              >
                Avaliação
              </Typography>
            </Grid>
            <Grid item>
              {renderClinicalEvaluation(detail?.clinical_evaluation)}
            </Grid>
          </>
        )}
      </Grid>
    );
  }

  return <CircularProgress />;
};

export default Anamnesis;
