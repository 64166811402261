import useSWR from 'swr';
import { getDoctorSchedule } from '../../../services/schedule';
import { type CalendarEvent } from '../../components/common/calendar';
import { momentUtcLocal } from '../../../utils/moment/momentHelpers';
import { type Consult } from '../../entities/consult';

type UseDoctorSchedulesCalendarProps = {
  from: string;
  to: string;
  employee?: string;
};

export function useDoctorSchedulesCalendar({
  from,
  to,
  employee
}: UseDoctorSchedulesCalendarProps) {
  const {
    data: response,
    error,
    isLoading,
    mutate
  } = useSWR<Consult[]>(
    { url: '/employees/schedule?calendar=true', from, to, employee },
    async ({ from, to }) => await getDoctorSchedule(from, to, employee),
    { refreshInterval: 60000 }
  );

  const mapSchedules = (data: Consult[]) => {
    const schedule: CalendarEvent[] = [];

    data.forEach(item => {
      const insurancePartner =
        item.invitation.insurance_partner ??
        item.teleconsultation?.insurance_coverage ??
        'Consulta particular';

      const patientName =
        item.patient_name === 'Paciente' ? null : item.patient_name;
      const titleName = patientName ?? item.invitation.email;
      const title = `${titleName} - ${insurancePartner}`;
      const color = item.teleconsultation ? '#009D79' : '#FFBA5C';

      const options = {
        id: item.key,
        start: momentUtcLocal(item.start_datetime).toDate(),
        end: momentUtcLocal(item.end_datetime).toDate(),
        title,
        editable: false,
        overlap: false,
        color
      };

      schedule.push(options);
    });

    const seen = new Set();
    const newEvents = schedule;
    const filteredEvents = newEvents.filter(el => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });

    return filteredEvents;
  };

  return {
    data: response !== undefined ? mapSchedules(response) : undefined,
    isLoading,
    error,
    mutate
  };
}
