import { useCallback, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { scheduleDetailsService } from '../../services/patient-service';
import { roomAuthorization } from '../../services/video.service';
import TwilioVideoApp from '../../components/Twilio';
import history from '../../services/history';
import { useToast } from '../../contexts/ToastContext';
import moment from 'moment';
import ExceededAppointmentsDialog, {
  type ExceededAppointmentsDialogHandles
} from '../../v2/dialogs/upgrade/exceededAppointments';
import { AxiosError } from 'axios';

type VideoCallProps = {
  computedMatch: { params: { consult_id: string } };
};

export default function VideoCall({
  computedMatch: {
    params: { consult_id: consultId }
  }
}: VideoCallProps) {
  const { t } = useTranslation(['video_call']);
  const toast = useToast();
  const [consultation, setConsultation] = useState(null);
  const [token, setToken] = useState(null);

  const exceededAppointmentsDialogRef =
    useRef<ExceededAppointmentsDialogHandles>(null);

  const authorizeRoom = useCallback(async () => {
    try {
      const { data } = await roomAuthorization(consultId);
      const { data: consultationData } =
        await scheduleDetailsService(consultId);

      if (!consultationData.open) throw new Error('Room not open');

      setConsultation(consultationData);
      setToken(data.token);
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;

        const exceededMessage = 'You have exceeded the free trial limit';
        const responseMessage = response?.data?.error?.message;

        if (response?.status === 403 && responseMessage === exceededMessage) {
          exceededAppointmentsDialogRef.current?.open();
          return;
        }
      }

      toast(t('Esta sala não está aberta.'), {
        variant: 'error'
      });
      history.push('/');
    }
  }, [consultId]);

  const refreshConsultation = useCallback(async () => {
    const { data: consultationData } = await scheduleDetailsService(consultId);

    if (consultationData.open) {
      setConsultation({ ...consultationData, refreshAt: moment() });
    }
  }, [consultId]);

  useEffect(() => {
    if (consultation === null) {
      authorizeRoom();
    }
  }, [consultation, authorizeRoom]);

  return (
    <>
      <Helmet title="Teleconsulta - Caren" />
      {token && (
        <TwilioVideoApp
          consultation={consultation}
          token={token}
          refreshConsultation={refreshConsultation}
        />
      )}
      <ExceededAppointmentsDialog ref={exceededAppointmentsDialogRef} />
    </>
  );
}
