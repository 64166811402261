import useSWR from 'swr';
import { getFreePlanDetails } from '../../services/plan';

export function useFreePlan(isManager?: boolean) {
  const { data, error, isLoading } = useSWR<{ total: number; used: number }>(
    isManager ? null : '/employees/teleconsultations/free-trial-usage',
    async () => await getFreePlanDetails(),
    { refreshInterval: 60000 }
  );

  return {
    data,
    isLoading,
    error
  };
}
