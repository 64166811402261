import { type AxiosResponse } from 'axios';
import api from '../../services/api';

export async function getFreePlanDetails() {
  const response = await api.get<
    unknown,
    AxiosResponse<{ total: number; used: number }>
  >('/employees/teleconsultations/free-trial-usage');

  return response.data;
}

export async function getDoctorFreePlanDetails(doctorKey: string) {
  const response = await api.get<
    unknown,
    AxiosResponse<{ total: number; used: number }>
  >('/employees/teleconsultations/free-trial-usage',
    {
      params: {
        employee_key: doctorKey
      }
    }
  );
  return response.data;
}

export async function getCurrentPlan() {
  const response = await api.get<unknown, AxiosResponse<{ data: unknown }>>(
    '/subscriptions/current'
  );

  return response.data.data;
}
