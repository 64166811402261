import { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import BaseDialog from '../base';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const Container = styled.div`
  width: 100%;
  height: 100%;

  > .header {
    color: var(--caren-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: semibold;
    text-align: center;
    row-gap: 10px;
  }

  > .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;

    p {
      color: var(--caren-disabled-text);
      font-size: 12px;
    }

    button {
      padding: 10px 30px;
      background-color: var(--caren-bluebtn);
      border-radius: 6px;
      color: #262c2e;
      font-weight: bold;
      transition-property: background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

      :hover {
        background-color: var(--caren-greenblue);
      }
    }

    .action-button {
      padding: 10px 30px;
      background-color: #3995a9;
      border-radius: 6px;
      color: #262c2e;
      font-weight: bold;
      transition-property: background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

      :hover {
        background-color: #077a94;
        color: #262c2e;
      }
    }
  }

  > p {
    color: var(--caren-orange);
    font-size: 14px;
    text-align: center;
  }
`;

type InvalidPostalCodeDialogProps = {
  onProceed: () => void;
};

type InvalidPostalCodeDialogHandles = {
  open: () => void;
};

const InvalidPostalCodeDialog = forwardRef<
  InvalidPostalCodeDialogHandles,
  InvalidPostalCodeDialogProps
>(({ onProceed }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    }
  }));

  return (
    <BaseDialog
      title="CEP não validado"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Container>
        <div className="header">
          <ExclamationTriangleIcon className="h-16 w-16 shrink-0" />
          <p className="mb-4 w-2/3 text-lg">
            Não conseguimos verificar seu endereço
            <br />
            através do CEP que você informou.
          </p>
          <p className="mb-4 w-2/3 text-lg">
            Para prosseguir, por favor certifique-se
            <br />
            de que o CEP digitado está correto.
          </p>
        </div>
        <div className="footer my-2">
          <button
            type="button"
            className="action-button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Corrigir CEP
          </button>
        </div>
        <button
          type="button"
          onClick={async () => {
            setIsOpen(false);
            onProceed();
          }}
          className="w-full rounded-md p-2 text-sm text-blue-650 underline transition-all duration-200 hover:bg-gray-200 active:bg-gray-250"
        >
          O CEP digitado está correto
        </button>
      </Container>
    </BaseDialog>
  );
});

InvalidPostalCodeDialog.displayName = 'InvalidPostalCodeDialog';
export type { InvalidPostalCodeDialogHandles };
export default InvalidPostalCodeDialog;
