import { PlanSchema } from '../v2/entities/plan';
import api from './api';

const getPlan = async (key: string) => {
  const { data } = await api.get(`/subscription-plans/${key}`);

  return PlanSchema.parse(data.data);
};

const getPlans = async () => {
  const { data } = await api.get('/subscription-plans');

  return data.data;
};

const cancelSubscription = async (key: string) => {
  const { data } = await api.delete(`/subscriptions/${key}`);

  return data;
};

const comparePlan = async (
  key: string,
  params: { discount_code: string | null, new_user: boolean }
) =>
  await api.get(`/subscription-plans/${key}/compare`, {
    params
  });

const getSubscription = async () => {
  const { data } = await api.get('/subscriptions/current', {});

  return data;
};

const getPaymentCharges = async (limit: number = 10, offset: number = 0) => {
  const { data } = await api.get('/payments/charges', {
    params: {
      limit,
      offset
    }
  });

  return data;
};

const getInvoices = async (limit: number = 10, offset: number = 0) => {
  const { data } = await api.get('/subscriptions/invoices', {
    params: {
      limit,
      offset
    }
  });

  return data;
};

export {
  getPlan,
  comparePlan,
  getSubscription,
  getPaymentCharges,
  getInvoices,
  getPlans,
  cancelSubscription
};
