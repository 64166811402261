import styled from 'styled-components/macro';
import { IoIosClose } from 'react-icons/io';

export const SidebarContainer = styled.div<{
  bgColor: string
  collapsed: boolean
}>`
  transform: ${({ collapsed }) =>
    collapsed ? 'translateX(0)' : 'translateX(-400px)'};
  width: 320px;
  min-height: 100%;
  max-height: 100%;
  position: fixed;
  z-index: 25000;
  top: 0;
  left: 0;
  background-color: ${({ bgColor }) => bgColor};
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloseButton = styled(IoIosClose).attrs({
  size: 50
})`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #262c2e;
`;
