import useSWR from 'swr';
import moment from 'moment';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { type Consult } from '../../entities/consult';

export function useNextConsults() {
  const [doctorsNexts, setDoctorsNexts] = useState(new Map<string, Consult>());

  const todayFrom = moment().startOf('day').utc().toISOString();
  const todayTo = moment().endOf('day').utc().toISOString();

  const tomorrowFrom = moment()
    .add(1, 'days')
    .startOf('day')
    .utc()
    .toISOString();
  const tomorrowTo = moment()
    .add(1, 'days')
    .endOf('day')
    .add('1', 'month')
    .utc()
    .toISOString();

  const {
    data: dataToday,
    error: errorToday,
    isLoading: isLoadingToday
  } = useSWR<Consult[]>(
    '/employees/schedule?today=true',
    async () =>
      await api
        .get(`/employees/schedule?from=${todayFrom}&to=${todayTo}&limit=10`)
        .then((res) => res.data.data),
    { refreshInterval: 60000 }
  );

  const {
    data: dataTomorrow,
    error: errorTomorrow,
    isLoading: isLoadingTomorrow
  } = useSWR<Consult[]>(
    '/employees/schedule?tomorrow=true',
    async () =>
      await api
        .get(
          `/employees/schedule?from=${tomorrowFrom}&to=${tomorrowTo}&limit=10`
        )
        .then((res) => res.data.data),
    { refreshInterval: 60000 }
  );

  const { data: dataDoctors } = useSWR<Consult[]>(
    '/employees/schedule?next=true',
    async () =>
      await api
        .get(`/employees/schedule?from=${todayFrom}&limit=50`)
        .then((res) => res.data.data),
    { refreshInterval: 60000 }
  );

  useEffect(() => {
    if (dataDoctors && dataDoctors.length > 0) {
      const consultsAfterNow = dataDoctors
        .filter((item) =>
          moment.utc(item.end_datetime).local().isAfter(moment())
        )
        .sort(
          (a, b) =>
            moment(a.start_datetime).unix() - moment(b.start_datetime).unix()
        );

      const doctors = new Map<string, Consult>();

      consultsAfterNow.forEach((consult) => {
        if (!consult.employee) return;
        if (doctors.has(consult.employee.key)) return;

        doctors.set(consult.employee.key, consult);
      });

      setDoctorsNexts(doctors);
    }
  }, [dataDoctors]);

  return {
    consults: {
      today: dataToday,
      tomorrow: dataTomorrow
    },
    doctors: doctorsNexts,
    isLoading: isLoadingToday || isLoadingTomorrow,
    isError: errorToday || errorTomorrow
  };
}
