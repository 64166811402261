import api from './api';

export const authorize = async (id, mode) => {
  const { data } = await api.post(
    `/teleconsultations/${id}/digital-signatures/vidaas`,
    { mode }
  );

  return data;
};
