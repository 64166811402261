import styled from 'styled-components/macro';

export const PatientProfileInfo = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  margin-right: 120px;
  flex-shrink: 0;

  @media (max-width: 1140px) {
    margin: 0 0 30px;
  }
`;

export const PatientInfoPicture = styled.div`
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  margin-right: 32px;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 1140px) {
    margin-right: 20px;
  }

  .image-or-name-group span {
    font-size: 30px;
  }
`;

export const PatientInfoContent = styled.div`
  span {
    display: block;

    &:nth-child(1) {
      color: #47525e;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 20px;
      font-weight: normal;

      @media (max-width: 1140px) {
        font-size: 18px;
      }
      @media (max-width: 405px) {
        font-size: 16px;
      }
    }

    &:nth-child(2) {
      color: #47525e;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

export const PatientListing = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-height: 100%;
  // overflow: auto;
  // padding: 2px 6px;
  flex-grow: 1;

  @media (max-width: 1140px) {
    flex-direction: column;
    padding: 5px 0;
    overflow: unset;
    max-height: unset;
  }
`;

export const PatientListingColumn = styled.div`
  background-color: #f7f6f9;
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 7px);
  flex-shrink: 0;

  @media (max-width: 1140px) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const PatientListingColumnTitle = styled.div`
  background-color: #f7f6f9;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
  margin-top: -18px;
  padding: 10px 12px 0;

  svg {
    display: inline-block;
    position: relative;
    bottom: -3px;
    transform: scale(1.4);
    min-width: 14px;
    min-height: 14px;
    margin-right: 12px;

    path {
      fill: #ff9052;
      stroke: #ff9052;
    }

    line {
      stroke: white;
    }
  }

  span {
    color: #47525e;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
  }
`;

export const PatientListingItems = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 81px;
  overflow: auto;
  padding: 10px;
  flex-grow: 1;
  border-radius: 4px;

  @media (max-width: 1140px) {
    flex-direction: column;
    height: auto;
    overflow: unset;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(71, 82, 94, 0.2);
    outline: 1px solid rgba(71, 82, 94, 0.2);
  }
`;

export const PatientListingItem = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.3;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
