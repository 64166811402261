import api from './api';

export const startOrResumePrescriptionService = async (teleconsultationKey) => {
  const { data } = await api.post(
    `/teleconsultations/${teleconsultationKey}/prescriptions/mevo`
  );

  return data;
};

export const concludePrescriptionService = async (prescriptionKey, payload) => {
  const {
    data
  } = await api.post(
    `/teleconsultations/prescriptions/mevo/${prescriptionKey}/conclude`,
    { payload }
  );

  return data;
};

export const deletePrescriptionService = async (teleconsultationKey) =>
  await api.delete(`/teleconsultations/${teleconsultationKey}/prescriptions/mevo`);
