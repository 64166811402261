import { useEffect, useRef } from 'react';
import ExceededAppointmentsDialog, {
  type ExceededAppointmentsDialogHandles
} from '../../dialogs/upgrade/exceededAppointments';
import { useHistory } from 'react-router-dom';
import { useFreePlan } from '../../hooks/plan/useFreePlan';

type FreePlanVerifyProps = {
  insuranceCoverage: boolean;
  privateConsults: boolean;
};

export default function FreePlanVerify({
  insuranceCoverage,
  privateConsults
}: FreePlanVerifyProps) {
  const exceededAppointmentsDialogRef =
    useRef<ExceededAppointmentsDialogHandles>(null);

  const history = useHistory();

  const { data: freePlan } = useFreePlan();

  useEffect(() => {
    if (
      exceededAppointmentsDialogRef.current &&
      freePlan !== undefined &&
      freePlan.used >= freePlan.total &&
      !insuranceCoverage &&
      !privateConsults
    ) {
      exceededAppointmentsDialogRef.current.open();
    }
  }, [freePlan, insuranceCoverage, privateConsults]);

  return (
    <ExceededAppointmentsDialog
      ref={exceededAppointmentsDialogRef}
      disableBackdropClick
      onClose={() => {
        history.push('/consultorio');
      }}
    />
  );
}
