import styled from 'styled-components/macro';
import { AiFillCamera } from 'react-icons/ai';
import { IoIosVideocam } from 'react-icons/io';
import { Doughnut } from 'react-chartjs-2';
import { FaStar, FaTrophy } from 'react-icons/fa';
import searchIcon from '../../assets/images/search.png';

export const Container = styled.div`
  background-color: var(--caren-white);
  display: grid;
  grid-template-columns: 20% 80%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const Gifts = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StarIcon = styled(FaStar).attrs({
  size: 25
})`
  background: linear-gradient(45deg, #fffbd9, #ffdf34);
  margin: 0 4px;
  padding: 5px;
  border-radius: 50%;
  color: #c89500;
  box-shadow: 0px 0px 7px 1px gold;
`;

export const Trophy = styled(FaTrophy).attrs({
  size: 25
})`
  background: linear-gradient(45deg, #fffbd9, #ffdf34);
  margin: 0 4px;
  padding: 5px;
  border-radius: 50%;
  color: #c89500;
  box-shadow: 0px 0px 7px 1px gold;
`;

export const FirstGrid = styled.div`
  background-color: #f6f7f8;
  padding: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SecondGrid = styled.div`
  background-color: var(--caren-white);
  padding: 0 40px;
`;

export const SecondHeader = styled.div`
  border-bottom: 2px solid #f2f2f2;
  padding: 10px 0;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SecondSubHeader = styled.div`
  border-bottom: 2px solid #f2f2f2;
  padding: 10px 100px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }
`;

export const SearchButtons = styled.span`
  padding: 5px 10px;
  background: #f2f2f2;
  border-radius: 4px;
  margin: 0 2px;
  color: var(--caren-grey);
`;

export const UserPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const UserImage = styled.img.attrs({
  src: 'https://source.unsplash.com/100x100/?face'
})`
  height: 80px;
  width: 80px;
  border-radius: 80px;
`;

export const UserInfo = styled.div`
  margin-left: 15px;
`;

export const UserName = styled.p`
  color: var(--caren-icongrey);
  font-weight: bold;
  font-size: 18px;

  display: flex;
`;

export const UserAge = styled.p`
  color: var(--caren-lightgrey);
  font-size: 14px;
`;

export const SearchInputContainer = styled.span`
  position: relative;
  img {
    position: absolute;
    left: 0;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const SearchInputIcon = styled.img.attrs({
  src: searchIcon
})`
  height: 20px;
  margin-top: 4px;
`;

export const SearchInput = styled.input.attrs({
  type: 'text',
  placeholder: 'buscar'
})`
  height: 30px;
  font-size: 15px;
  background-color: transparent;
  padding-left: 23px;
  border-bottom: 1px solid var(--caren-grey);
  color: var(--caren-lightgrey);

  &::placeholder {
    color: var(--caren-lightgrey);
  }
`;

export const VerticalTimeContainer = styled.div`
  & div ::before {
    background-color: red;
  }
`;

export const DoctorLogo = styled.img.attrs({
  src: 'https://source.unsplash.com/500x500/?doctor'
})`
  width: 124px;
  height: 124px;
  border-radius: 100%;
`;

export const DoctorPanel = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 30px;
`;

export const DoctorChangePhoto = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
  background-color: var(--caren-greenblue);
  border-radius: 100%;
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 2px;
  }

  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const CameraIcon = styled(AiFillCamera).attrs({
  size: 20,
  color: 'var(--caren-white)'
})``;

export const DoctorName = styled.p`
  color: var(--caren-icongrey);
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
`;

export const DoctorSpec = styled.p`
  color: var(--caren-icongrey);
  font-size: 16px;
  text-align: center;
`;

export const DoctorSchedule = styled.div`
  background-color: #ffffff;
  max-width: 243px;
  border-radius: 3px;
  padding: 40px 22px;
`;

export const DoctorTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DoctorTitle = styled.div`
  color: var(--caren-icongrey);
  font-size: 14px;
`;

export const DoctorTimeBox = styled.div`
  canvas {
    margin: 30px 0;
  }
`;

export const DoctorGraph = styled(Doughnut)``;

export const DoctorCamIcon = styled(IoIosVideocam).attrs({
  color: '#969faa',
  size: '35%'
})`
  position: absolute;
  margin-left: 1px;
`;

export const DoctorTimeTitle = styled.p`
  color: var(--caren-icongrey);
  font-size: 14px;
`;

export const DoctorClock = styled.p`
  color: #969faa;
  font-size: 28px;
  text-align: center;
  margin-top: 10px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  input,
  textarea,
  select,
  option {
    color: var(--caren-grey);
  }
`;

export const Input = styled.input`
  padding-left: 10px;
  margin: 5px 0;
  height: 40px;
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
`;

export const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
`;

export const ModalButton = styled.button`
  height: 40px;
  background-color: var(--caren-green);
  color: var(--caren-white);
  font-size: 20px;
  margin-top: 20px;
  border-radius: 10px;
`;

export const SelectBox = styled.div`
  overflow: hidden;
  margin-top: 20px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--caren-lightgrey);
`;

export const SelectIcon = styled.img``;

export const Select = styled.select`
  border: none;
  height: 40px;
  width: 100%;
  color: #767676;
`;
