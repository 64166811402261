import React from 'react';

import { Container, DisplayFlex } from './sidebarbox.styles';

export default function SidebarBox({
  children,
  heightArea,
  style,
  flex = true,
}) {
  return (
    <Container heightArea={heightArea} style={style}>
      {flex ? <DisplayFlex>{children}</DisplayFlex> : children}
    </Container>
  );
}
