import { useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { UserIcon, CreditCardIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { mutate } from 'swr';
import BaseDialog from '../base';
import { useToast } from '../../../contexts/ToastContext';
import history from '../../../services/history';
import ConsultDialogContextProvider, {
  type ConsultDialogControl
} from './context';
import Header from './header';
import DoctorStep from './steps/doctor';
import ScheduleStep from './steps/schedule';
import PatientStep from './steps/patient';
import ReviewStep from './steps/review';
import SuccessStep from './steps/success';
import { FORMAT } from '../../../utils/moment/momentFormat';
import { type CalendarEventResume } from './elements/calendar';
import {
  type ConsultInvite,
  ConsultInviteSchema,
  type PatientFormData
} from '../../entities/consult';
import { createScheduleInvitation } from '../../../services/schedule';
import { type Doctor } from '../../entities/doctor';
import { useAuth } from '../../../hooks/AuthContext';
import { Link } from 'react-router-dom';
import { useProfileCompleteness } from '../../hooks/user/useProfileCompleteness';
import { useCurrentPlan } from '../../hooks/plan/useCurrentPlan';
import { useFreePlan } from '../../hooks/plan/useFreePlan';

type CreateConsultDialogHandles = {
  open: (
    defaultData?: Map<number, { header: null | string; value: unknown }>,
    defaultStep?: number
  ) => void;
};

type CreateConsultDialogProps = {
  isManager?: boolean;
  onSuccess?: () => void;
};

const CreateConsultDialog = forwardRef<
  CreateConsultDialogHandles,
  CreateConsultDialogProps
>(({ isManager = false, onSuccess }, ref) => {
  const { credentials } = useAuth();
  const toast = useToast();

  const {
    isComplete: isProfileComplete,
    isLoading: isProfileCompletenessLoading
  } = useProfileCompleteness(isManager);
  const { data: currentPlan, isLoading: isCurrentPlanLoading } =
    useCurrentPlan(isManager);
  const { data: freePlan, isLoading: isFreePlanLoading } =
    useFreePlan(isManager);

  const isLoading =
    isProfileCompletenessLoading || isCurrentPlanLoading || isFreePlanLoading;

  const [isOpen, setIsOpen] = useState(false);
  const [invitationLink, setInvitationLink] = useState<string | null>(null);
  const [initialData, setInitialData] = useState<
    Map<number, { header: null | string; value: unknown }> | undefined
  >(undefined);
  const [initialStep, setInitialStep] = useState<number | undefined>(undefined);

  const hasPartners =
    credentials?.session.insurance_partners &&
    credentials.session.insurance_partners.length > 0;
  const hasPlan = currentPlan?.status !== 'not_found';
  const hasFreePlanConsults = freePlan && freePlan.used < freePlan.total;

  const isEnabled =
    isManager ||
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (isProfileComplete && (hasPartners || hasPlan || hasFreePlanConsults));

  useImperativeHandle(ref, () => ({
    open: (defaultData, defaultStep) => {
      if (defaultData) setInitialData(defaultData);
      if (defaultStep) setInitialStep(defaultStep);
      setIsOpen(true);
    }
  }));

  const handleClose = useCallback(() => {
    if (invitationLink && onSuccess != null) {
      onSuccess();
    }
    setIsOpen(false);
    setInvitationLink(null);
    setInitialData(undefined);
    setInitialStep(undefined);
  }, [invitationLink, onSuccess]);

  function formatCPF(cpf: string) {
    let newCPF = cpf;
    newCPF = newCPF.replaceAll('.', '');
    newCPF = newCPF.replaceAll('-', '');
    newCPF = newCPF.replaceAll('_', '');

    return newCPF.trim();
  }

  const createConsult = useCallback(
    async (data: unknown[], dialogControl: ConsultDialogControl) => {
      const scheduledFor = moment(
        `${(data[isManager ? 1 : 0] as CalendarEventResume).date.day} ${
          (data[isManager ? 1 : 0] as CalendarEventResume).date.time
        }`,
        FORMAT['us-datetime']
      ).utc();

      const formData: ConsultInvite = {
        scheduled_for: scheduledFor.toISOString(),
        email: (data[isManager ? 2 : 1] as PatientFormData).email,
        insurance_partner_key: (data[isManager ? 2 : 1] as PatientFormData)
          .insurance_partner_key,
        fiscal_code: formatCPF(
          (data[isManager ? 2 : 1] as PatientFormData).fiscal_code
        )
      };

      if (isManager) {
        formData.employee_key = (data[0] as Doctor).key;
      }

      try {
        const parsedObject = ConsultInviteSchema.parse(formData);

        const dataCreation = await createScheduleInvitation(parsedObject);

        toast('Convite enviado com sucesso!', {
          variant: 'success'
        });

        mutate('/employees/schedule?today=true');
        mutate('/employees/schedule?tomorrow=true');
        mutate('/employees/schedule?next=true');
        setInvitationLink(dataCreation.link);
      } catch (error: any) {
        const errorBody = error?.response?.data?.error;
        dialogControl.backwardStep();

        if (errorBody?.error?.message === 'Forbidden') {
          toast(
            'Este beneficiário não foi encontrado, verifique se o CPF está digitado corretamente.',
            {
              variant: 'error'
            }
          );
        } else {
          toast(
            'Não foi possível enviar o convite, por favor verifique se os campos estão preenchidos corretamente.',
            {
              variant: 'error'
            }
          );
        }
      }
    },
    []
  );

  return (
    <BaseDialog
      title="Novo Agendamento"
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
    >
      {isLoading && !isManager ? (
        <div className="flex h-64 w-full animate-pulse items-center justify-center gap-2.5 text-gray-350">
          <DocumentMagnifyingGlassIcon className="h-6 w-6" />
          <p className="text-sm font-semibold">Verificando Dados...</p>
        </div>
      ) : (
        <div className="flex h-fit w-full flex-col items-center justify-center md:h-[26.5rem]">
          {isEnabled ? (
            <>
              {invitationLink ? (
                <SuccessStep
                  invitationLink={invitationLink}
                  onClose={() => {
                    handleClose();
                  }}
                />
              ) : (
                <ConsultDialogContextProvider
                  initialData={initialData}
                  initialStep={initialStep}
                  isManager={isManager ?? false}
                  maxSteps={isManager ? 4 : 3}
                  stepsHeader={
                    isManager
                      ? {
                          1: '1. Médico',
                          2: '2. Agenda',
                          3: '3. Dados do Paciente',
                          4: '4. Revisão'
                        }
                      : {
                          1: '1. Agenda',
                          2: '2. Dados do Paciente',
                          3: '3. Revisão'
                        }
                  }
                  onFinished={async (data, dialogControl) => {
                    await createConsult(data, dialogControl);
                  }}
                >
                  <Header />
                  {isManager ? (
                    <>
                      <DoctorStep step={1} />
                      <ScheduleStep step={2} />
                      <PatientStep step={3} />
                      <ReviewStep step={4} />
                    </>
                  ) : (
                    <>
                      <ScheduleStep step={1} />
                      <PatientStep step={2} />
                      <ReviewStep step={3} />
                    </>
                  )}
                </ConsultDialogContextProvider>
              )}
            </>
          ) : (
            <div className="flex h-full w-full flex-col justify-center gap-y-4">
              {!isProfileComplete && (
                <>
                  <div className="flex flex-col items-center gap-y-2.5 text-center text-sm font-semibold text-blue-650">
                    <UserIcon className="h-16 w-16 shrink-0" />
                    <p className="w-2/3 text-lg">
                      Antes de criar um agendamento, precisamos que preencha
                      completamente o seu perfil, escolha uma foto e verifique o
                      seu e-mail.
                    </p>
                  </div>
                  <p className="text-center text-sm text-warning">
                    Por favor clique no botão abaixo e preencha os campos
                    restantes.
                  </p>
                  <div className="flex flex-col items-center gap-y-1.5">
                    <button
                      className="rounded-md bg-yellow-600 px-8 py-2.5 font-bold text-gray-950 transition-all duration-300 hover:bg-yellow-750"
                      type="button"
                      onClick={() => {
                        handleClose();
                        localStorage.setItem('doctor-profile-tab', 'data');
                        history.push('/perfil');
                      }}
                    >
                      Meus Dados
                    </button>
                  </div>
                </>
              )}

              {isProfileComplete &&
                (!currentPlan ||
                  (currentPlan && currentPlan.status === 'not_found')) && (
                  <>
                    <div className="flex flex-col items-center gap-y-2.5 text-center text-sm font-semibold text-blue-650">
                      <CreditCardIcon className="h-16 w-16 shrink-0" />
                      <p className="mb-4 w-2/3 text-lg">
                        Você atingiu o limite máximo de agendamentos de
                        consultas no plano free.
                      </p>
                      <p className="w-2/3 text-lg">
                        Antes de criar um agendamento, é necessário adquirir um
                        de nossos planos.
                      </p>
                    </div>
                    <p className="text-center text-sm text-warning">
                      Clique no botão abaixo para conhecer o melhor plano para
                      você.
                    </p>
                    <div className="flex flex-col items-center gap-y-1.5">
                      <button
                        type="button"
                        className="rounded-md bg-yellow-600 px-8 py-2.5 font-bold text-gray-950 transition-all duration-300 hover:bg-yellow-750"
                        onClick={() => {
                          handleClose();
                          localStorage.setItem(
                            'doctor-profile-tab',
                            'plan_details'
                          );
                          history.push('/perfil', { view: 'plans' });
                        }}
                      >
                        Escolha seu plano
                      </button>
                    </div>
                  </>
                )}

              {isProfileComplete &&
                currentPlan &&
                currentPlan.status !== 'not_found' &&
                'plan' in currentPlan &&
                currentPlan.current_invoice.status === 'failed' && (
                  <>
                    <div className="flex flex-col items-center gap-y-2.5 text-center text-sm font-semibold text-blue-650">
                      <CreditCardIcon className="h-16 w-16 shrink-0" />
                      <p className="w-2/3 text-lg">
                        Notamos uma dificuldade na cobrança do seu plano
                        <br />
                        <strong>{currentPlan.plan.name ?? ''}.</strong>
                      </p>
                    </div>
                    <p className="text-center text-sm text-warning">
                      Por favor atualize seus&nbsp;
                      <Link
                        to="/perfil"
                        onClick={() => {
                          localStorage.setItem(
                            'doctor-profile-tab',
                            'plan_details'
                          );
                          handleClose();
                        }}
                        className="font-semibold underline"
                      >
                        dados de pagamento
                      </Link>{' '}
                      para criar consultas particulares.
                    </p>
                    <div className="flex flex-col items-center gap-y-1.5">
                      <button
                        className="rounded-md bg-yellow-600 px-8 py-2.5 font-bold text-gray-950 transition-all duration-300 hover:bg-yellow-750"
                        type="button"
                        onClick={() => {
                          localStorage.setItem(
                            'doctor-profile-tab',
                            'plan_details'
                          );
                          handleClose();
                          history.push('/perfil');
                        }}
                      >
                        Meus Dados
                      </button>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
      )}
    </BaseDialog>
  );
});

CreateConsultDialog.displayName = 'CreateConsultDialog';
export type { CreateConsultDialogHandles };
export default CreateConsultDialog;
