import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ShowMoreText from 'react-show-more-text';
import { useTranslation } from 'react-i18next';
import { BsClock } from 'react-icons/bs';
import ReactFlagsSelect from 'react-flags-select';
import { scroller, Element } from 'react-scroll';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@material-ui/core';
import { useToast } from '../../contexts/ToastContext';
import ScreenLoad from '../../components/Screenload';
import getDoctorTitle from '../../utils/getDoctorTitle';
import Header from '../../components/Header';
import formattedHours from '../../utils/formattedHours';
import {
  FindADoctor,
  FindADoctorClock,
  Container,
  CardImagePanel,
  CardInfoPanel,
  CardNextSlotsPanel,
  CardSchedulePanel,
  CardContainer,
  CardImage,
  CardBox,
  CardDoctorName,
  CardViewProfile,
  CardToolsIcon,
  CardGlobeIcon,
  CardBookIcon,
  CardInfosTitle,
  CardInfosCenteredCondensedTitle,
  CardSlotsList,
  CardNextTime,
  CardFavorite,
  CardNotFavorite,
  CardShare,
  CardShieldIcon as _CardShieldIcon,
  CardShieldImageIcon as _CardShieldImageIcon,
  CardInfosBadge,
  CardViewButtonBox,
  CardHeartIcon,
  NotFoundContainer,
  CardFavoriteContainer,
} from './doctors.styles';
import { UserPadlockIcon } from '../../components/PayBar/paybar.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import { doctorsService } from '../../services/doctors.service';
import { useAuth } from '../../hooks/AuthContext';
import Modal from '../../components/Modal';
import { Store } from '../../store';
import { doctorsFavoriteService } from '../../services/doctors-favorite.service';
import useCurrentHour from '../../hooks/currentHour.hook';
import { setQuery } from '../../store/modules/Query/actions';
import { ROLE_PROFESSIONAL } from '../../routes/constants';
import history from '../../services/history';
import ImageOrName from '../../components/ImageOrName';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';
import {
  countries,
  countryAcronymToName,
  countryNames,
  countryToLocale,
  localeToCountryAcronym,
} from '../../utils/countryLocaleUtils';
import { DOCTOR_OFFICE_CODE } from '../../constants/common';
import { sidebarOpen } from '../../store/modules/Sidebar/actions';

const useStyles = makeStyles((theme) => ({
  flagSelect: {
    '&&': {
      display: 'inline-flex',
    },
    padding: '0 10px',

    [theme.breakpoints.down('xs')]: {
      '& button': {
        padding: 0,
      },
    },
  },
  buttonRoot: {
    display: 'block',
    margin: '10px auto',
  },
  buttonText: {
    color: 'var(--caren-white) !important',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

export default function Doctors({ location }) {
  const toast = useToast();
  const [doctors, setDoctors] = useState(null);
  const [state, dispatch] = useContext(Store);
  const currentHourState = useCurrentHour();
  const [screenloading, setScreeloading] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { i18n, t } = useTranslation(['doctors']);
  const [country, setCountry] = useState(
    countryAcronymToName(localeToCountryAcronym(i18n.language))
  );

  useEffect(() => {
    setCountry(countryAcronymToName(localeToCountryAcronym(i18n.language)));
  }, [i18n.language]);

  const { credentials } = useAuth();

  useEffect(() => {
    if (credentials?.token?.user_type) {
      if (credentials?.token.user_type === ROLE_PROFESSIONAL)
        history.push('/consultorio');
    }
  }, [credentials]);

  const setSearchResults = (results) => {
    scroller.scrollTo('search-results', {
      duration: 1000,
      smooth: true,
      offset: -150,
    });
    setDoctors(results);
  };

  useEffect(() => {
    let isMounted = true;

    async function getDoctorsService() {
      try {
        const { data } = await doctorsService({
          favorite: location?.favorite,
          country,
          ...state.query,
        });
        if (isMounted) {
          setSearchResults(data);
        }
      } catch (error) {
        toast(t('Ops, houve um erro na busca...'), {
          variant: 'error',
        });
      }
      setScreeloading(false);
    }

    if (country) {
      getDoctorsService();
    }

    return () => {
      isMounted = false;
    };
  }, [location, country, state.query]);

  useEffect(() => {
    dispatch(setQuery({}));
  }, []);

  const [openAuthWall, setOpenAuthWall] = useState(false);
  function handleOpenAuthWall() {
    setOpenAuthWall(!openAuthWall);
  }

  function handleOpenSidebar() {
    dispatch(sidebarOpen());
    handleOpenAuthWall();
  }

  const handleToggleFavorite = async (doctor, favoriteParams) => {
    if (!credentials?.session) {
      setOpenAuthWall(true);
      return;
    }

    const { data } = await doctorsFavoriteService(doctor.key, !favoriteParams);
    doctor.favorite = data.favorite;
    setDoctors([...doctors]);
  };

  const getDoctorRoute = (doctor) => `/medicos/${doctor.slug || doctor.key}`;

  const handleShare = async (doctor) => {
    const text = `Agende uma consulta com ${getDoctorTitle(doctor)} ${
      doctor.name
    } na Caren`;
    const url = window.location.origin + getDoctorRoute(doctor);
    const shareData = {
      title: 'Telessaúde Caren',
      text,
      url,
    };

    const urlText = `${text}:\n\n${url}`;

    try {
      await navigator.share(shareData);
      toast(t('Link compartilhado!'), { variant: 'success' });
    } catch (err) {
      await navigator.clipboard.writeText(urlText);
      toast(t('Link copiado!'), { variant: 'success' });
    }
  };

  function showDoctorSpecialties(specialties) {
    if (specialties.length === 0) {
      return (
        <CardInfosTitle>
          <CardToolsIcon />
          <p>{t('Não Informado')}</p>
        </CardInfosTitle>
      );
    }

    if (specialties.length === 1) {
      return (
        <CardInfosTitle>
          <CardToolsIcon />
          <p>{specialties.map((each) => each.name).join(', ')}</p>
        </CardInfosTitle>
      );
    }

    const [firstSpecialty, ...otherSpecialties] = specialties;

    return (
      <CardInfosTitle>
        <CardToolsIcon />
        <p>
          <span>{firstSpecialty.name}, </span>
          <ShowMoreText
            /* Default options */
            lines={1}
            more={t('Mais')}
            less={t('Mostrar menos')}
            expanded={false}
            truncatedEndingComponent="... "
          >
            {otherSpecialties.map((each) => each.name).join(', ')}
          </ShowMoreText>
        </p>
      </CardInfosTitle>
    );
  }

  function showDoctorOffice(offices) {
    const telemedOffice = offices.find(
      (item) => item.code === DOCTOR_OFFICE_CODE
    );

    if (telemedOffice && telemedOffice.city && telemedOffice.state) {
      return (
        <p>
          {telemedOffice.city}, {telemedOffice.state}
        </p>
      );
    }

    return <p>{t('Não informado')}</p>;
  }

  function showTeleconsultationSlots(doctor) {
    const slots = doctor.teleconsultation_slots;
    slots.sort();

    return slots
      .map((slot) => momentUtcLocal(slot))
      .filter((slot) => slot.isAfter(moment()))
      .slice(0, 2)
      .map((slot, index) => (
        <CardNextTime key={index.toString()}>
          {formattedHours(slot)}
        </CardNextTime>
      ));
  }

  const renderFavoriteButton = (doctor) => (
    <CardFavoriteContainer>
      {doctor.favorite ? (
        <CardFavorite
          onClick={() => handleToggleFavorite(doctor, doctor.favorite)}
        />
      ) : (
        <CardNotFavorite
          onClick={() => handleToggleFavorite(doctor, doctor.favorite)}
        />
      )}
      <CardShare onClick={() => handleShare(doctor)} />
    </CardFavoriteContainer>
  );

  const TITLE = 'Médicos - Caren';
  const [searchInput, setSearchInput] = useState(null);

  return (
    <>
      <ScreenLoad screenLoading={screenloading} />
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <FindADoctor>
        <div>
          <h2>
            {t('Encontre médicos em: ')}
            <ReactFlagsSelect
              className={classes.flagSelect}
              placeholder="País"
              countries={countries}
              customLabels={countryNames}
              selected={localeToCountryAcronym(i18n.language)}
              onSelect={(code) => i18n.changeLanguage(countryToLocale(code))}
            />
          </h2>
        </div>
        <FindADoctorClock>
          <BsClock />
          {currentHourState}
        </FindADoctorClock>
      </FindADoctor>
      <Container>
        <Element name="search-results">
          <CardContainer>
            {doctors?.map((doctor) => (
              <CardBox key={doctor.key}>
                <CardImagePanel>
                  <CardImage
                    imgUrl={doctor.avatar_url}
                    title={doctor.name}
                    titleSize={50}
                    backgroundStyle={{
                      width: isSmallScreen ? 70 : 120,
                      height: isSmallScreen ? 70 : 120,
                      borderRadius: '50%',
                      backgroundColor: 'var(--caren-image-fallback)',
                      marginRight: 0,
                    }}
                  />
                  {renderFavoriteButton(doctor)}
                </CardImagePanel>
                <CardInfoPanel>
                  <CardDoctorName mode="single" max={34}>
                    {doctor.name}
                  </CardDoctorName>
                  {showDoctorSpecialties(doctor.specialties)}
                  {doctor.offices ? (
                    <CardInfosTitle>
                      <CardGlobeIcon />
                      {showDoctorOffice(doctor.offices)}
                    </CardInfosTitle>
                  ) : (
                    <CardInfosTitle>
                      <CardGlobeIcon />
                      {t('Não Informado')}
                    </CardInfosTitle>
                  )}
                  {doctor?.insurance_partners?.length > 0 &&
                  doctor?.payment_methods?.insurance_partner ? (
                    <CardInfosTitle>
                      <CardHeartIcon />
                      <CardInfosBadge>
                        <p>{t('Planos de saúde aceitos')}</p>
                        <div>
                          {doctor.insurance_partners
                            .slice(0, 2)
                            .map((insurance_partner, _index) => (
                              <ImageOrName
                                key={insurance_partner.key}
                                src={insurance_partner.icon}
                                title={insurance_partner.name}
                                titleSize={45}
                                backgroundStyle={{
                                  width: isSmallScreen ? 24 : 32,
                                  height: isSmallScreen ? 24 : 32,
                                  borderRadius: '100%',
                                  backgroundColor:
                                    'var(--caren-image-fallback)',
                                }}
                              />
                            ))}
                        </div>
                      </CardInfosBadge>
                    </CardInfosTitle>
                  ) : (
                    <CardInfosTitle>
                      <CardHeartIcon />
                      <CardInfosBadge>
                        <p>{t('Consultas particulares')}</p>
                      </CardInfosBadge>
                    </CardInfosTitle>
                  )}
                </CardInfoPanel>
                <CardNextSlotsPanel>
                  <CardInfosCenteredCondensedTitle>
                    <CardBookIcon />
                    <CardInfosBadge>
                      <p>{t('Próximos Horários')}</p>
                    </CardInfosBadge>
                  </CardInfosCenteredCondensedTitle>
                  <CardSlotsList>
                    {showTeleconsultationSlots(doctor)}
                  </CardSlotsList>
                </CardNextSlotsPanel>
                <CardSchedulePanel>
                  <CardViewButtonBox>
                    <CardViewProfile to={() => getDoctorRoute(doctor)}>
                      {t('AGENDAR CONSULTA')}
                    </CardViewProfile>
                  </CardViewButtonBox>
                </CardSchedulePanel>
              </CardBox>
            ))}
          </CardContainer>
        </Element>
        {doctors?.length === 0 && (
          <NotFoundContainer>
            <h1>{t('Desculpe, nenhum médico foi encontrado')}.</h1>
            <h3>Por favor altere os filtros e tente novamente.</h3>
          </NotFoundContainer>
        )}
      </Container>
      <Modal
        handleOpenModal={handleOpenAuthWall}
        openModal={openAuthWall}
        modalTitle={t('Estamos quase lá!')}
        modalWidth="40%"
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
      >
        <UserPadlockIcon />
        <p>
          {t('Para marcar um médico como favorito, é necessário estar logado.')}
        </p>
        <p>
          {t(
            'Entre com seu login e senha ou cadastre-se gratuitamente pelo botão abaixo!'
          )}
        </p>
        <Button
          classes={{ root: classes.buttonRoot, label: classes.buttonText }}
          color="primary"
          variant="contained"
          onClick={handleOpenSidebar}
          fullWidth
        >
          {t('Entrar')}
        </Button>
      </Modal>
    </>
  );
}
