import React, { useEffect, useState } from 'react';
import { RiCoupon3Line } from 'react-icons/ri';
import { Helmet } from 'react-helmet-async';
import { IoIosAddCircle } from 'react-icons/io';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';
import { useToast } from '../../contexts/ToastContext';
import { BackButton } from '../../components/BackButton';
import {
  Container,
  FirstGrid,
  SecondGrid,
  ResponseContainer,
  SubHeaderContainer,
  AddButtonContainer,
  Input,
  FormContainer,
  ModalButton,
} from './styles';

import Vouchers from './Vouchers';
import Header from '../../components/Header';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import ContainerOpacity from '../../components/ContainerOpacity';
import { FORMAT } from '../../utils/moment/momentFormat';

import {
  doctorGetVoucherService,
  doctorCreateVoucherService,
} from '../../services/doctors.service';

import LoadingComponent from '../../components/Loading';

import DoctorPanel from '../../components/DoctorPanel';

export default function DoctorVouchers() {
  const [doctorVouchers, setDoctorVouchers] = useState([]);

  const { t } = useTranslation(['doctor_vouchers']);

  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const TITLE = 'Meus vouchers - Caren';

  const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    async function getDoctorVouchersService() {
      setLoading(true);
      try {
        const { data: VoucherData } = await doctorGetVoucherService();

        setDoctorVouchers([...VoucherData]);
      } catch (error) {
        toast(t('Ops, não foi possível carregar os vouchers...'), {
          variant: 'error',
        });
      }
      setLoading(false);
    }

    getDoctorVouchersService();
  }, []);

  function handleToggleModal() {
    setOpenModal(!openModal);
  }

  async function handleCreateVoucher(dataParams) {
    const formData = {
      expires_at: moment(dataParams?.expires_at).toISOString(),
      label: dataParams?.label,
    };

    setLoading(true);
    try {
      const createdVoucher = await doctorCreateVoucherService(formData);
      setDoctorVouchers([createdVoucher, ...doctorVouchers]);

      toast(t('Voucher criado com sucesso!'), {
        variant: 'success',
      });
    } catch (error) {
      toast(error?.response, {
        variant: 'error',
      });
    }

    setLoading(false);
    setOpenModal(false);
    reset({
      label: '',
      expires_at: '',
    });
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar bgColor="var(--caren-greenblue)" />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel inviteFriend />
        </FirstGrid>
        <SecondGrid>
          <SubHeaderContainer>
            <AddButtonContainer>
              <IoIosAddCircle
                size={40}
                onClick={handleToggleModal}
                color="var(--caren-greenblue)"
                cursor="pointer"
              />
            </AddButtonContainer>
            <BackButton path="/consultorio" title={t('Consultório virtual')} />
          </SubHeaderContainer>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <ResponseContainer>
              <LoadingComponent size={80} color="var(--caren-greenblue)" />
            </ResponseContainer>
          ) : doctorVouchers.length ? (
            <Vouchers
              doctorVouchers={doctorVouchers}
              setDoctorVouchers={setDoctorVouchers}
            />
          ) : (
            <ResponseContainer>
              <div>
                <p>{t('Nenhum voucher até o momento.')}</p>
                <RiCoupon3Line size={60} color="var(--caren-icongrey)" />
              </div>
            </ResponseContainer>
          )}
        </SecondGrid>
      </Container>
      <Modal
        handleOpenModal={handleToggleModal}
        openModal={openModal}
        modalTitle={t('Criação de voucher')}
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
      >
        <FormContainer onSubmit={handleSubmit(handleCreateVoucher)}>
          <div>
            <label htmlFor="label">{t('Apelido')}</label>
            <Controller
              control={control}
              name="label"
              as={<Input type="text" />}
              rules={{ validate: { notEmpty: (value) => Boolean(value) } }}
            />
          </div>
          <div>
            <label htmlFor="expiry_at">{t('Expira em')}:</label>
            <Controller
              control={control}
              name="expires_at"
              as={
                <Input type="date" min={moment().format(FORMAT['us-date'])} />
              }
              defaultValue={moment().add(2, 'week').format(FORMAT['us-date'])}
            />
          </div>
          <ModalButton type="submit">{t('Criar')}</ModalButton>
        </FormContainer>
      </Modal>
    </>
  );
}
