/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import ImageUploader from 'react-images-upload';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../contexts/ToastContext';
import Header from '../../../components/Header';
import {
  Container,
  FirstGrid,
  SecondGrid,
  UserProfileCard,
  UserImage,
  UserImageContainer,
  CameraIcon,
  UserChangePhoto,
  LogoContainer,
  LogoGreen,
  // SpecialText,
  UserPanel,
  UserName,
  // UserDetails,
  // CarenClubPanel,
  // CarenClubText,
  // ClubmarkLogo,
} from './profile.styles';
import ContainerOpacity from '../../../components/ContainerOpacity';
import UserSidebar from '../../../components/Sidebar/UserSidebar';
import TabMenu from '../../../components/TabMenu';
import { useAuth } from '../../../hooks/AuthContext';
import { changeUserPictureService } from '../../../services/user.service';

export default function Patient() {
  const toast = useToast();
  const [profileImage, setProfileImage] = useState('');
  const TITLE = 'Perfil - Caren';

  const {
    credentials,
    refreshSession,
  } = useAuth();

  const { t } = useTranslation(['patient_profile']);

  async function onDrop([img]) {
    try {
      const { data } = await changeUserPictureService(img);
      toast(t('Foto atualizada com sucesso!'), {
        variant: 'success',
      });
      if(credentials){
        refreshSession(credentials.token);
      }
      setProfileImage(data.avatar_url);
    } catch (error) {
      // Not shown
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <Container>
        <FirstGrid>
          <UserProfileCard>
            <UserImageContainer>
              <UserImage
                title={credentials?.session.name}
                titleSize={35}
                imgUrl={profileImage || credentials?.session.avatar_url}
                backgroundStyle={{
                  width: 130,
                  height: 130,
                  borderRadius: '100%',
                  backgroundColor: 'var(--caren-image-fallback)',
                }}
              />

              <ImageUploader
                withIcon={false}
                onChange={onDrop}
                buttonText={<CameraIcon />}
                buttonStyles={{
                  backgroundColor: 'transparent',
                  margin: 0,
                  padding: 0,
                }}
                fileContainerStyle={{
                  position: 'absolute',
                  left: 92,
                  bottom: -32,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
                withLabel={false}
                imgExtension={['.jpg', '.png', 'jpeg']}
                singleImage
                fileSizeError={t('Arquivo é muito grande')}
                fileTypeError={t('Este arquivo não é válido')}
              />
            </UserImageContainer>
            <LogoContainer>
              <LogoGreen />
              {/* <SpecialText>PRIMEIRO 100 MEMBROS</SpecialText> */}
            </LogoContainer>
            <UserPanel>
              <UserName>{credentials?.session.name}</UserName>
              {/* <UserDetails>Usuário: 001 Desde: 04/20</UserDetails> */}
            </UserPanel>
            {/* <CarenClubPanel>
                <CarenClubText>
                  Caren
                  <br />
                  Club
                </CarenClubText>
                <ClubmarkLogo />
              </CarenClubPanel> */}
          </UserProfileCard>
        </FirstGrid>
        <SecondGrid>
          <TabMenu />
        </SecondGrid>
      </Container>
    </>
  );
}
