import styled from 'styled-components/macro';

export const ButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const ConfirmButton = styled.button`
  background-color: var(--caren-darkgreen);
  color: var(--caren-icongrey);
  font-weight: bold;
  font-size: 18px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  border-radius: 5px;
  text-transform: uppercase;

  transition: 0.4s;

  &:hover {
    filter: brightness(115%);
  }
`;

export const DenyButton = styled.button`
  background-color: var(--caren-lightgrey);
  color: var(--caren-white);
  font-weight: bold;
  font-size: 18px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  border-radius: 5px;
  text-transform: uppercase;

  transition: 0.4s;

  &:hover {
    filter: brightness(115%);
  }
`;
