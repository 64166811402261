import { z } from 'zod';
import { doctorValidateSlugService } from '../../services/doctors.service';
import { SpecialtiesSchema } from './doctor';

export const DoctorProfileSchema = z.object({
  display_name: z
    .string({ required_error: 'Digite seu nome e sobrenome' })
    .trim()
    .nonempty('Digite seu nome e sobrenome')
    .regex(/[A-Za-z]\s+[A-Za-z]/g, 'Por favor informe nome e sobrenome'),
  slug: z
    .string({ required_error: 'Digite um slug' })
    .trim()
    .nonempty('Digite um slug')
    .regex(/[a-z]/g, 'Por favor informe um slug válido')
    .refine(async val => await doctorValidateSlugService(val), {
      message: 'Este link não está disponível.'
    }),
  phone: z
    .string({ required_error: 'Digite um telefone' })
    .nonempty('Digite um telefone')
    .regex(
      /\(\d{2}\)\s\d{5}-\d{4}/,
      'Digite o telefone no formato: (xx) xxxxx-xxxx'
    ),
  bio: z
    .string({ required_error: 'Digite um resumo do seu currículo' })
    .optional(),
  fiscal_code: z
    .string({ required_error: 'Digite um CPF' })
    .nonempty('Digite um CPF')
    .regex(
      /\d{3}\.\d{3}\.\d{3}-\d{2}/,
      'Digite o CPF no formato: xxx.xxx.xxx-xx'
    ),
  doctor_profile: z.object({
    specialties: SpecialtiesSchema.array(),
    professional_code: z
      .string({ required_error: 'Digite seu número de registro profissional' })
      .trim()
      .nonempty('Digite seu número de registro profissional')
      .regex(/[A-Za-z0-9]/g, 'Digite seu número de registro profissional'),
    professional_code_region: z
      .string({ required_error: 'Selecione o estado do registro' })
      .trim()
      .nonempty('Selecione o estado do registro')
      .length(2)
      .regex(/[A-Za-z]{2}/g, 'Selecione o estado do registro'),
    office: z.object({
      name: z
        .string({ required_error: 'Digite um nome' })
        .nonempty('Digite um nome'),
      country: z
        .string({ required_error: 'Escolha um país' })
        .nonempty('Escolha um país'),
      state: z
        .string({ required_error: 'Digite um estado' })
        .nonempty('Digite um estado'),
      city: z
        .string({ required_error: 'Digite uma cidade' })
        .nonempty('Digite uma cidade'),
      street_name: z
        .string({ required_error: 'Digite o logradouro' })
        .nonempty('Digite o logradouro'),
      complement: z.string().optional(),
      code: z.string({ required_error: 'Digite o código' }).optional()
    })
  })
});

export type DoctorProfile = z.infer<typeof DoctorProfileSchema>;
