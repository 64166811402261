import React, { useRef, useState } from 'react';
import { Dialog } from '@headlessui/react';
import formattedPrice from '../../../utils/formattedPrice';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

type ResumeDialogProps = {
  price: number;
  total: number;
  currency: string;
  details: string[];
  discounts: Array<{
    name: string;
    type: 'discount_code' | 'upgrade';
    value: number;
  }>;
};

export default function ResumeDialog({
  currency,
  price,
  discounts,
  details,
  total
}: ResumeDialogProps) {
  const closeButtonRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setIsOpen(true);
        }}
        className="fixed bottom-0 z-40 flex w-full items-center justify-between rounded-lg border-l border-r border-t border-gray-200 bg-white px-8 py-6 md:hidden"
      >
        <p className="font-nunito text-sm font-semibold text-gray-450">TOTAL</p>
        <div className="flex items-center gap-x-6">
          <div className="flex flex-col items-end">
            <p>
              <p className="font-nunito text-2xl font-semibold">
                {formattedPrice(total, currency)}
              </p>
            </p>
            <p className="font-nunito text-sm font-semibold text-yellow-600">
              Detalhes da sua compra
            </p>
          </div>
          <ChevronUpIcon className="h-6 w-6 text-yellow-600" />
        </div>
      </button>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        initialFocus={closeButtonRef}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full flex-col items-center justify-end">
            <Dialog.Panel className="flex w-full flex-col items-center rounded bg-white p-12">
              <div
                ref={closeButtonRef}
                tabIndex={0}
                role="button"
                onClick={() => {
                  setIsOpen(false);
                }}
                onKeyDown={({ key }) => {
                  if (key === 'Enter') {
                    setIsOpen(false);
                  }
                }}
                className="mb-12 flex w-full items-center justify-between"
              >
                <Dialog.Title className="text-xl font-semibold text-gray-450">
                  Detalhes da sua compra
                </Dialog.Title>
                <ChevronDownIcon className="h-6 w-6 text-yellow-600" />
              </div>

              <div className="mb-4 flex w-full flex-col rounded-lg border border-gray-200 bg-white pb-4 pt-6 font-nunito">
                <div className="flex w-full items-center justify-between px-6 pb-4 text-sm text-gray-450">
                  <p>Valor do Plano</p>
                  <p>{formattedPrice(price, currency)}</p>
                </div>
                {React.Children.toArray(
                  discounts.map(discount => (
                    <div className="flex w-full items-center justify-between px-6 pb-4 text-sm text-success-light">
                      <p>
                        {discount.type === 'upgrade'
                          ? 'Plano Anterior'
                          : 'Desconto Cupom'}{' '}
                        {discount.type === 'discount_code' && (
                          <>
                            <br />
                            {discount.name}
                          </>
                        )}
                      </p>
                      <p>- {formattedPrice(discount.value, currency)}</p>
                    </div>
                  ))
                )}
                <footer className="flex w-full items-center justify-between border-t border-gray-200 px-6 pt-4 text-gray-450">
                  <p className="text-sm font-medium">Total</p>
                  <p className="text-lg font-medium">
                    {formattedPrice(total, currency)}
                  </p>
                </footer>
              </div>

              <div className="mb-4 flex w-full flex-col gap-y-4 rounded-lg border border-gray-200 bg-white px-6 py-5 font-nunito text-gray-450">
                <p className="text-base font-semibold">Detalhes</p>
                {React.Children.toArray(
                  details.map(item => <p className="text-sm">{item}</p>)
                )}
              </div>

              <p className="max-w-xs text-center font-nunito text-sm text-blue-650">
                Ao criar uma conta, você concorda com os nossos&nbsp;
                <a
                  className="underline hover:text-yellow-750 hover:underline"
                  href="https://www.caren.app/paginas/termos-de-uso-telessaude"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termos de Uso
                </a>
                &nbsp;e&nbsp;
                <a
                  className="underline hover:text-yellow-750 hover:underline"
                  href="https://www.caren.app/paginas/aviso-de-privacidade"
                  target="_blank"
                  rel="noreferrer"
                >
                  Políticas de Privacidade
                </a>
              </p>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
