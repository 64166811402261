import ActionsCell from './Cells/ActionsCell';
import HeaderCell from './Cells/HeaderCell';
import LoadingCell from './Cells/LoadingCell';
import PersonCell from './Cells/PersonCell';
import TextCell from './Cells/TextCell';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import TableRoot from './TableRoot';
import TableRow from './TableRow';

export const Table = {
  Root: TableRoot,
  Header: TableHeader,
  Body: TableBody,
  Row: TableRow,
  Cell: {
    Header: HeaderCell,
    Person: PersonCell,
    Text: TextCell,
    Actions: ActionsCell,
    Loading: LoadingCell
  }
};
