import React from 'react';
import { twJoin } from 'tailwind-merge';
import usePatientSignupForm from '../../hooks/patient/usePatientSignupForm';

export default function Stepper() {
  const { actualStep, maxSteps } = usePatientSignupForm();

  const steps = Array.from({ length: maxSteps }, (_, index) => index + 1);

  return (
    <div className="mt-6 flex items-start gap-x-4">
      {React.Children.toArray(
        steps.map(index => (
          <>
            <div className="flex flex-col gap-y-2">
              <span
                className={twJoin(
                  'h-4 w-4 rounded-full ring-2 ring-gray-950 ring-offset-2',
                  index === actualStep ? 'bg-gray-950' : 'bg-white'
                )}
              />
              <span className="text-center font-nunito">{index}</span>
            </div>
            <span
              className={twJoin(
                'mt-2 h-0.5 w-24 bg-gray-950',
                index === maxSteps ? 'hidden' : ''
              )}
            />
          </>
        ))
      )}
    </div>
  );
}
