import React from 'react';
import styled from 'styled-components/macro';

export const Svg = styled.svg`
  animation: ${({ animated }) => animated && 'wait 1.5s infinite 0s ease-out;'}
    @keyframes wait {
    70% {
      transform: rotate(0deg) scale(0.95);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;

export default function UploadLogoDynamic({ color, size, animated }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="-62 0 406 406"
      width={size}
      animated={animated}
    >
      <g>
        <path
          d="m87.101562 54.101562v-44.203124l-77.203124 77.203124h44.203124c18.199219-.054687 32.945313-14.800781 33-33zm0 0"
          data-original="#000000"
          className="active-path"
          data-old_color="#000000"
          fill={color}
        />
        <path
          d="m249.699219 0h-148.597657v54.101562c-.042968 25.9375-21.0625 46.957032-47 47h-54.101562v271.898438c.0195312 18.21875 14.78125 32.980469 33 33h216.800781c18.21875-.019531 32.980469-14.78125 33-33v-340c-.101562-18.222656-14.878906-32.953125-33.101562-33zm-39.199219 210.300781c-2.503906 2.945313-6.90625 3.34375-9.898438.898438l-52.300781-43.898438v152.597657c0 3.867187-3.136719 7-7 7-3.867187 0-7-3.132813-7-7v-152.597657l-52.300781 43.898438c-2.980469 2.484375-7.414062 2.082031-9.898438-.898438-2.488281-2.984375-2.082031-7.414062.898438-9.902343l63.800781-53.5c.300781-.199219.597657-.5.898438-.699219.054687 0 .101562-.042969.101562-.097657.320313-.1875.65625-.355468 1-.5.097657 0 .097657 0 .199219-.101562.300781-.101562.699219-.199219 1-.300781h.199219c.359375-.085938.730469-.121094 1.101562-.097657.367188-.019531.738281.015626 1.097657.097657h.203124c.398438.101562.699219.199219 1 .300781.097657 0 .097657 0 .199219.101562.351563.125.6875.292969 1 .5.054688 0 .097657.042969.097657.097657.34375.175781.648437.414062.902343.699219l63.800781 53.5c1.4375 1.191406 2.34375 2.90625 2.511719 4.765624.167969 1.859376-.414062 3.707032-1.613281 5.136719zm0 0"
          data-original="#000000"
          className="active-path"
          data-old_color="#000000"
          fill={color}
        />
      </g>
    </Svg>
  );
}
