import { useCallback, useState } from 'react';
import Video from 'twilio-video';
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_ENABLED,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_ENABLED,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../constants/video';
import getDeviceInfo from '../../../utils/getDeviceInfo';
import isPermissionDenied from '../../../utils/isPermissionDenied';

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState();
  const [videoTrack, setVideoTrack] = useState();

  const [videoTrackEnabled, setVideoTrackEnabled] = useState(null);
  const [audioTrackEnabled, setAudioTrackEnabled] = useState(null);

  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

  const getLocalAudioTrack = useCallback((deviceId) => {
    const options = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    );

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const options = {
      ...DEFAULT_VIDEO_CONSTRAINTS,
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && {
        deviceId: { exact: selectedVideoDeviceId },
      }),
    };

    return Video.createLocalVideoTrack(options).then((newTrack) => {
      setVideoTrack(newTrack);
      return newTrack;
    });
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const getAudioAndVideoTracks = useCallback(async () => {
    const {
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices)
      return Promise.resolve();
    if (isAcquiringLocalTracks || audioTrack || videoTrack)
      return Promise.resolve();

    setIsAcquiringLocalTracks(true);

    const selectedAudioDeviceId = window.localStorage.getItem(
      SELECTED_AUDIO_INPUT_KEY
    );
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    );

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const selectedVideoEnabled = window.localStorage.getItem(
      SELECTED_VIDEO_ENABLED
    );

    if (selectedVideoEnabled) {
      setVideoTrackEnabled(selectedVideoEnabled === 'true');
    }

    const selectedAudioEnabled = window.localStorage.getItem(
      SELECTED_AUDIO_ENABLED
    );
    if (selectedAudioEnabled) {
      setAudioTrackEnabled(selectedAudioEnabled === 'true');
    }

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

    const shouldAcquireVideo =
      hasVideoInputDevices && !isCameraPermissionDenied;
    const shouldAcquireAudio =
      hasAudioInputDevices && !isMicrophonePermissionDenied;

    const localTrackConstraints = {
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice
          ? {
              deviceId: { exact: selectedAudioDeviceId },
            }
          : hasAudioInputDevices),
    };

    if (shouldAcquireVideo && selectedVideoEnabled === 'true') {
      localTrackConstraints.video = {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: { exact: selectedVideoDeviceId },
        }),
      };
    }

    return Video.createLocalTracks(localTrackConstraints)
      .then(async (tracks) => {
        const newVideoTrack = tracks.find((track) => track.kind === 'video');
        const newAudioTrack = tracks.find((track) => track.kind === 'audio');

        if (newVideoTrack) {
          if (selectedVideoEnabled === 'false') {
            await newVideoTrack.disable();
          }

          setVideoTrack(newVideoTrack);
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.

          window.localStorage.setItem(
            SELECTED_VIDEO_INPUT_KEY,
            newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? ''
          );
        }
        if (newAudioTrack) {
          if (selectedAudioEnabled === 'false') {
            await newAudioTrack.disable();
          }
          setAudioTrack(newAudioTrack);
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error();
          error.name = 'NotAllowedError';
          throw error;
        }

        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied');
        }

        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied');
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [audioTrack, videoTrack, isAcquiringLocalTracks]);

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  );

  const updateAudioTrackState = (state) => {
    window.localStorage.setItem(SELECTED_AUDIO_ENABLED, state);
    setAudioTrackEnabled(state);
  };

  const updateVideoTrackState = (state) => {
    window.localStorage.setItem(SELECTED_VIDEO_ENABLED, state);
    setVideoTrackEnabled(state);
  };

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    videoTrackEnabled,
    audioTrackEnabled,
    setAudioTrackEnabled: updateAudioTrackState,
    setVideoTrackEnabled: updateVideoTrackState,
  };
}
