import { useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputMask from 'react-input-mask';
import {
  makeStyles,
  TextField,
  IconButton,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../../../../components/Loading';

import {
  type PatientCreate,
  PatientCreateSchema
} from '../../../entities/patient';
import { zodResolver } from '@hookform/resolvers/zod';
import Stepper from '../stepper';
import { type FiscalCodeFormData } from './fiscalCode';
import CheckboxInput from '../../../components/inputs/input/checkbox';
import usePatientSignupForm from '../../../hooks/patient/usePatientSignupForm';

const useStyles = makeStyles(_theme => ({
  passwordFieldMargin: {
    marginRight: '-40px'
  },

  legend: {
    width: '51%',
    padding: '18.5px 14px',
    marginBottom: '0px'
  },

  v2TextFieldBorder: {
    '&.MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#FDBF71' // yellow-650
    }
  }
}));

type PersonalInfoStepProps = {
  step: number;
  loading: boolean;
  onComplete: (formData: PatientCreate) => void;
};

export default function PersonalInfo({
  step,
  loading,
  onComplete
}: PersonalInfoStepProps) {
  const { actualStep, steps } = usePatientSignupForm();
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors }
  } = useForm<PatientCreate>({
    resolver: zodResolver(PatientCreateSchema)
  });

  const { t } = useTranslation(['doctor_signup']);

  const classes = useStyles();

  useEffect(() => {
    if (steps[1]) {
      const fiscalCodeData = steps[1] as FiscalCodeFormData;

      setValue('fiscal_code', fiscalCodeData.login);
    }
  }, [setValue, steps]);

  function handlePasswordType() {
    if (showPassword) {
      return 'text';
    }

    return 'password';
  }

  function showPasswordOnClick() {
    setShowPassword(!showPassword);
  }

  function renderShowPasswordButton() {
    if (!showPassword) {
      return (
        <IconButton className="relative" onClick={showPasswordOnClick}>
          <FaEyeSlash size={16} />
        </IconButton>
      );
    }

    return (
      <IconButton className="relative" onClick={showPasswordOnClick}>
        <FaEye size={16} color="red" />
      </IconButton>
    );
  }

  if (actualStep !== step) return null;

  return (
    <form
      className="mx-auto mt-8 flex w-[512px] max-w-[95%] flex-col items-center justify-between gap-y-4 rounded-xl bg-white px-12 py-8"
      onSubmit={handleSubmit(onComplete)}
    >
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={fields => (
          <TextField
            InputProps={{
              classes: {
                focused: classes.v2TextFieldBorder
              }
            }}
            placeholder="Nome e sobrenome"
            type="text"
            variant="outlined"
            fullWidth
            inputRef={fields.field.ref}
            {...fields.field}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="name"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        render={fields => (
          <InputMask {...fields.field} mask="999.999.999-99" type="text">
            <TextField
              placeholder="CPF"
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              InputProps={{
                classes: {
                  focused: classes.v2TextFieldBorder
                },
                readOnly: true
              }}
            />
          </InputMask>
        )}
        name="fiscal_code"
      />
      <ErrorMessage
        errors={errors}
        name="fiscal_code"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <div className="flex w-full items-center justify-between">
        <FormLabel className={classes.legend} component="legend">
          {t('Sexo biológico')}
        </FormLabel>
        <Controller
          control={control}
          defaultValue={undefined}
          render={fields => (
            <RadioGroup className="w-full" {...fields.field} row>
              <FormControlLabel
                name="gender"
                value="female"
                control={<Radio />}
                label={t('Feminino')}
                inputRef={fields.field.ref}
              />
              <FormControlLabel
                name="gender"
                value="male"
                control={<Radio />}
                label={t('Masculino')}
                inputRef={fields.field.ref}
              />
            </RadioGroup>
          )}
          name="gender"
        />
      </div>
      <ErrorMessage
        errors={errors}
        name="gender"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <Controller
        control={control}
        name="birthdate"
        /* @ts-expect-error  KeyboardDatePicker starts with today's date unless passed null (not undefined). However we cannot pass null due to schema type validation */
        defaultValue={null}
        render={fields => (
          <KeyboardDatePicker
            {...fields.field}
            disableFuture
            placeholder="Data de Nascimento"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            InputProps={{
              classes: {
                focused: classes.v2TextFieldBorder
              }
            }}
            inputRef={fields.field.ref}
            fullWidth
            invalidDateMessage="Esta data não é válida."
            minDateMessage="Por favor selecione uma data válida."
            maxDateMessage="Por favor selecione uma data no passado."
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="birthdate"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        render={fields => (
          <TextField
            {...fields.field}
            placeholder="E-mail"
            variant="outlined"
            type="email"
            fullWidth
            inputRef={fields.field.ref}
            InputProps={{
              classes: {
                focused: classes.v2TextFieldBorder
              }
            }}
          />
        )}
        name="email"
      />
      <ErrorMessage
        errors={errors}
        name="email"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        render={fields => (
          <div className="flex w-full items-center">
            <TextField
              className={classes.passwordFieldMargin}
              placeholder="Senha"
              type={handlePasswordType()}
              variant="outlined"
              fullWidth
              InputProps={{
                classes: {
                  focused: classes.v2TextFieldBorder
                }
              }}
              inputRef={fields.field.ref}
              {...fields.field}
            />
            {renderShowPasswordButton()}
          </div>
        )}
        name="password"
      />
      <ErrorMessage
        errors={errors}
        name="password"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        render={fields => (
          <div className="flex w-full items-center">
            <TextField
              className={classes.passwordFieldMargin}
              placeholder="Confirme a senha"
              type={handlePasswordType()}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              InputProps={{
                classes: {
                  focused: classes.v2TextFieldBorder
                }
              }}
              {...fields.field}
            />
            {renderShowPasswordButton()}
          </div>
        )}
        name="password_confirmation"
      />
      <ErrorMessage
        errors={errors}
        name="password_confirmation"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        render={fields => (
          <InputMask {...fields.field} mask="(99) 99999-9999" type="text">
            <TextField
              placeholder="Celular"
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              InputProps={{
                classes: {
                  focused: classes.v2TextFieldBorder
                }
              }}
            />
          </InputMask>
        )}
        name="phone"
      />
      <ErrorMessage
        errors={errors}
        name="phone"
        render={({ message }: { message: string }) => (
          <p className="text-xs text-error">{message}</p>
        )}
      />
      <CheckboxInput
        type="checkbox"
        {...register('accept_terms')}
        error={errors.accept_terms?.message}
      >
        <span>Li e aceito os&nbsp;</span>
        <a
          className="underline hover:text-yellow-750 hover:underline"
          href="https://www.caren.app/paginas/termos-de-uso-telessaude"
          target="_blank"
          rel="noreferrer"
        >
          Termos de Uso
        </a>
        <span>&nbsp;e&nbsp;</span>
        <a
          className="underline hover:text-yellow-750 hover:underline"
          href="https://www.caren.app/paginas/aviso-de-privacidade"
          target="_blank"
          rel="noreferrer"
        >
          Políticas de Privacidade
        </a>
        <span>.</span>
      </CheckboxInput>
      <div className="flex w-full justify-center">
        <button
          type="submit"
          className="mt-6 flex w-full items-center justify-center rounded-xl bg-gray-950 p-4 font-nunito text-xl text-white transition ease-in-out hover:scale-105"
        >
          {loading ? <LoadingComponent /> : t('Enviar')}
        </button>
      </div>
      <Stepper />
    </form>
  );
}
