export const WEEKDAY_TRANSLATION = {
  sunday: 'Domingos',
  monday: 'Segundas',
  tuesday: 'Terças',
  wednesday: 'Quartas',
  thursday: 'Quintas',
  friday: 'Sextas',
  saturday: 'Sábados'
};

export const WEEKDAY_NEXT = {
  sunday: 'monday',
  monday: 'tuesday',
  tuesday: 'wednesday',
  wednesday: 'thursday',
  thursday: 'friday',
  friday: 'saturday',
  saturday: 'sunday'
};

export const WEEKDAY_PREVIOUS = {
  sunday: 'saturday',
  monday: 'sunday',
  tuesday: 'monday',
  wednesday: 'tuesday',
  thursday: 'wednesday',
  friday: 'thursday',
  saturday: 'friday'
};
