import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../contexts/ToastContext';
import { anamnesisDetailService } from '../../../services/anamnesis-detail.service';
import Anamnesis from '../../Twilio/Room/types/Professional/Tabs/Anamnesis';
import ClinicalEvaluation from '../../Twilio/Room/types/Professional/Tabs/ClinicalEvaluation';
import History from '../../Twilio/Room/types/Professional/Tabs/History';
import {
  Navigation,
  Tabs,
  TabsContent,
  TabsContentTitle,
  TabsContentDescription,
} from './styles';

const ClinicalEvaluationInfo = ({
  consultation,
  onDetail = undefined,
  onClickBack = undefined,
  editingField = null,
  setHideActions = false,
  isWidget = false,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['video_call']);

  const [tab, setTab] = useState(2);
  const [anamnesisDetail, setAnamnesisDetail] = useState(null);
  const [requestedReloadAt, setRequestedReloadAt] = useState(null);

  useEffect(async () => {
    if (consultation?.anamnesis_key) {
      try {
        const { data } = await anamnesisDetailService(
          consultation.anamnesis_key,
          {
            with_history: true,
          }
        );

        setAnamnesisDetail(data);
      } catch (error) {
        toast(t('Ops, não foi possível carregar o pré-atendimento...'), {
          variant: 'error',
        });
      }
    }
  }, [consultation, requestedReloadAt]);

  useEffect(() => {
    // Callback
    if (anamnesisDetail && onDetail) {
      onDetail(anamnesisDetail);
    }
  }, [anamnesisDetail]);

  function patchClinicalEvaluation(updated) {
    setAnamnesisDetail((current) => ({
      ...current,
      clinical_evaluation: updated,
    }));
  }

  function reloadAndGoBack() {
    setRequestedReloadAt(new Date().getTime());
    if (onClickBack) return onClickBack();
  }

  return (
    <div className="flex flex-col flex-grow pb-10 gap-y-4">
      <Navigation>
        <button
          type="button"
          className={tab === 0 ? 'active' : ''}
          title="Selecionar aba"
          aria-label="Selecionar aba"
          onClick={() => setTab(0)}
        >
          Pré-atendimento
        </button>

        <button
          type="button"
          className={tab === 1 ? 'active' : ''}
          title="Selecionar aba"
          aria-label="Selecionar aba"
          onClick={() => setTab(1)}
        >
          Histórico
        </button>

        <button
          type="button"
          className={tab === 2 ? 'active' : ''}
          title="Selecionar aba"
          aria-label="Selecionar aba"
          onClick={() => setTab(2)}
        >
          Avaliação
        </button>
      </Navigation>

      <Tabs>
        <TabsContent
          isWidget={isWidget}
          className={tab === 0 ? 'active tab-data' : 'tab-data'}
        >
          <TabsContentTitle>Pré-atendimento</TabsContentTitle>

          <TabsContentDescription>
            <p>
              Perguntas respondidas pelo paciente durante a etapa de Anamnese
            </p>
          </TabsContentDescription>

          <Anamnesis detail={anamnesisDetail} showClinicalEvaluation={false} />
        </TabsContent>

        <TabsContent
          isWidget={isWidget}
          className={tab === 1 ? 'active tab-data' : 'tab-data'}
        >
          <TabsContentTitle>Histórico</TabsContentTitle>

          <TabsContentDescription>
            <p>Histórico de consultas do paciente</p>
          </TabsContentDescription>

          <History detail={anamnesisDetail} />
        </TabsContent>

        <TabsContent
          isWidget={isWidget}
          className={tab === 2 ? 'active tab-data' : 'tab-data'}
        >
          <TabsContentTitle>Avaliação do Atendimento</TabsContentTitle>

          <TabsContentDescription>
            <p>Perguntas a serem respondidas durante o atendimento</p>
          </TabsContentDescription>

          <ClinicalEvaluation
            anamnesisKey={consultation?.anamnesis_key}
            detail={anamnesisDetail}
            onUpdate={patchClinicalEvaluation}
            editable={consultation?.evaluation_concluded_at == null}
            editingField={editingField}
            onClickBack={reloadAndGoBack}
            setHideActions={setHideActions}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ClinicalEvaluationInfo;
