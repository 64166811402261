import styled from 'styled-components/macro';
import { MdFavorite, MdFavoriteBorder, MdShare } from 'react-icons/md';
import { Link } from 'react-router-dom';
import TextFit from 'react-textfit';
import toolsIcon from '../../assets/images/doctortool.png';
import globeIcon from '../../assets/images/globe.png';
import bookIcon from '../../assets/images/book.png';
import shieldIcon from '../../assets/images/healthcare.png';
import fapesIcon from '../../assets/images/plano_vc.png';
import heartIcon from '../../assets/images/heart-attack.png';
import ImageOrName from '../../components/ImageOrName';

export const Container = styled.div``;

export const CardViewButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FindADoctor = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
  }

  h2,
  p {
    padding: 20px;
    font-size: 26px;
    color: var(--caren-grey);
    font-weight: bold;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

export const FindADoctorClock = styled.p`
  padding: 20px;
  font-size: 26px;
  color: var(--caren-grey);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const CardFavoriteContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
`;

export const CardFavorite = styled(MdFavorite).attrs({
  size: 24,
  color: 'var(--caren-green)'
})`
  &:hover {
    cursor: pointer;
  }
`;

export const CardNotFavorite = styled(MdFavoriteBorder).attrs({
  size: 24,
  color: 'var(--caren-green)'
})`
  &:hover {
    cursor: pointer;
  }
`;

export const CardShare = styled(MdShare).attrs({
  size: 24,
  color: 'var(--caren-green)'
})`
  &:hover {
    cursor: pointer;
  }
`;

export const CardContainer = styled.div`
  margin-top: 30px;

  margin-bottom: 50px;
  margin: 30px 5% 50px 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  transition: 2s;

  @media (max-width: 1600px) {
    & {
      grid-template-columns: 1fr 1fr;
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  @media (max-width: 968px) {
    & {
      grid-template-columns: 1fr;
    }
  }

  grid-gap: 35px;
`;

export const CardBox = styled.div`
  position: relative;
  box-shadow: 0px 0px 8px 0px #343f4b2a;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr minmax(0, 4fr);
  grid-template-rows: min-content min-content auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'image profile'
    'slots profile'
    'slots button';
  background-color: var(--caren-white);
  padding: 20px;
  width: 100%;
  max-width: min(600px, 90vw);
  margin: 0 auto;

  @media (max-width: 968px) {
    padding: 10px;
  }
`;

export const CardDoctorName = styled(TextFit)`
  display: flex;
  padding: 10px;
  margin-left: 2px;
  color: var(--caren-icongrey);
  font-weight: bold;
`;

export const CardInfos = styled.div`
  width: 100%;
  margin: 0 7%;
  text-align: -webkit-center;
  flex: ${(props) => (props.flex ? 1 : 'unset')};
`;

export const CardImagePanel = styled.div`
  grid-area: image;
  padding: 10px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const CardInfoPanel = styled.div`
  grid-area: profile;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const CardNextSlotsPanel = styled.div`
  grid-area: slots;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CardSchedulePanel = styled.div`
  grid-area: button;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 10px;

  @media (max-width: 768px) {
    padding: 0 5px;
  }
`;

export const CardInfosTitle = styled.div`
  margin: 10px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    color: var(--caren-grey);
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const CardInfosCenteredCondensedTitle = styled(CardInfosTitle)`
  margin: 0;
  justify-content: center;
  white-space: nowrap;

  @media (max-width: 768px) {
    p {
      text-align: center;
    }
  }
`;

export const CardInfosBadge = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CardSlotsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardSmallIcon = styled.img`
  height: 20px;
  margin-right: 10px;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

  @media (max-width: 768px) {
    height: 16px;
  }
`;

export const CardToolsIcon = styled(CardSmallIcon).attrs({ src: toolsIcon })``;

export const CardGlobeIcon = styled(CardSmallIcon).attrs({ src: globeIcon })``;

export const CardBookIcon = styled(CardSmallIcon).attrs({ src: bookIcon })`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const CardHeartIcon = styled(CardSmallIcon).attrs({ src: heartIcon })``;

export const CardShieldIcon = styled(CardSmallIcon).attrs({
  src: shieldIcon
})``;

export const CardShieldImageIcon = styled.img.attrs({
  src: fapesIcon,
  title: 'VC Saúde'
})`
  height: 20px;
  margin-right: 10px;
  color: var(--caren-red);
`;

export const NotFoundContainer = styled.div`
  margin: 0 10%;

  h1 {
    font-weight: medium;
  }
`;

export const CardViewProfile = styled(Link).attrs(({ to }) => ({
  to
}))`
  padding: 8px 8px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border: 1px solid #eeeeee;
  background-color: var(--caren-green);
  width: 100%;
  color: var(--caren-white);
  transition: all 0.2s;

  &:hover {
    color: var(--caren-white);
    background-color: var(--caren-lightgreen);
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const CardNextTime = styled.span`
  color: ${({ late }) =>
    late ? 'rgba(255, 186, 92, 0.83)' : 'var(--caren-green)'};
  border: 1px solid
    ${({ late }) => (late ? 'rgba(255, 186, 92, 0.83)' : 'var(--caren-green)')};
  border-radius: 7px;
  padding: 2px 5px;
  background-color: var(--caren-white);
  margin: 0 3px;
  margin-top: 5px;
  flex-grow: 1;
  text-align: center;

  &:first-child {
    margin-right: 3px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const CardImage = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  border-radius: 50%;
`;
