import api from '../../services/api';
import { StatsSchema } from '../entities/doctor';

export async function authorizeCRMUpload(filename: string) {
  const response = await api.post<{ url: string }>(
    '/employees/doctor-documents/authorizations',
    { filename }
  );

  return response.data;
}

export async function uploadCRM(path: string, file: File) {
  const response = await fetch(path, { body: file, method: 'PUT' });

  if (!response.ok) {
    throw Error('Cannot upload this file');
  }
}

export async function updateDoctorDocuments(path: string, filename: string) {
  await api.post('/employees/doctor-documents', {
    path,
    filename,
    document_type: 'crm'
  });
}

export async function deleteDoctorCRM(key: string) {
  const response = await api.delete(`/employees/doctor-documents/${key}`);

  return response.status === 200;
}

export async function getStatsCount() {
  const { data } = await api.get('/employees/new-telemed-stats');

  if (data?.data) {
    return StatsSchema.parse(data.data);
  }

  return undefined;
}
