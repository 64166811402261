import { twMerge } from 'tailwind-merge';

type TextCellProps = {
  text: string
  label: string
  className?: string
}

export default function TextCell({ text, label, className }: TextCellProps) {
  return (
    <td
      className={twMerge(
        'text-gray-350 text-center text-sm p-4 lg:py-5 lg:px-8 font-nunito',
        className
      )}
    >
      <span className="text-sm text-blue-650 font-semibold mb-1 block lg:hidden">
        {label}
      </span>
      {text}
    </td>
  );
}
