import api from './api';

export const anamnesisDetailService = async (anamnesisKey, params = {}) => {
  const { data } = await api.get(
    `/partner-appointments/anamnesis/${anamnesisKey}`,
    {
      params
    }
  );

  return data;
};

export const teleconsultationKeyService = async (anamnesisKey) => {
  const { data } = await api.get(
    `/partner-appointments/anamnesis/${anamnesisKey}/teleconsultation-key/`
  );

  return data;
};

export const authorizeAnamnesis = async (appointmentKey) => {
  const { data } = await api.post(
    `/partner-appointments/anamnesis/${appointmentKey}/authorize`
  );

  return data;
};
