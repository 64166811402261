import React, { createContext } from 'react';
import useSpeakerViewParticipants from '../hooks/video/useSpeakerViewParticipants';

export const ParticipantContext = createContext(null);

export const ParticipantProvider = ({ children }) => {
  const speakerViewParticipants = useSpeakerViewParticipants();

  return (
    <ParticipantContext.Provider
      value={{
        speakerViewParticipants,
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
};
