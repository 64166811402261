import styled, { createGlobalStyle } from 'styled-components/macro';
import InputMask from 'react-input-mask';
import logoGreen from '../../assets/images/logo-green.png';

export const GlobalStyle = createGlobalStyle`
  body {
    &::-webkit-scrollbar {
      width: 8px;
      margin-top: -50px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--caren-input);
      border-radius: 12px;
      margin: 4px;
    }

    &::-webkit-scrollbar-button {
      background: none;
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: linear-gradient(var(--caren-green), var(--caren-greenblue));
    }
  }
`;

export const LogoGreen = styled.img.attrs({ src: logoGreen })`
  width: 120px;
`;

export const CreateDoctorContainer = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  background: linear-gradient(var(--caren-green), var(--caren-greenblue));

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 480px;
    min-width: 280px;
    border-radius: 3em;
    background-color: var(--caren-white);
    padding: 1.5em;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.5em;

  & > div {
    margin-bottom: 0.7em;
  }

  & > p {
    margin-bottom: 0.7em;
  }
`;

export const CreateDoctorButton = styled.button`
  border-radius: 5px;
  height: 35px;
  margin: 10px 0;
  width: 50%;
  font-size: 16px;
  color: var(--caren-white);
  background-color: ${({ bgColor }) => bgColor || 'var(--caren-green)'};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const ErrorMessageText = styled.p`
  color: red;
  font-size: 0.8em;
`;

export const LabelInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: solid 1px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;

  &:hover {
    border-color: black;
  }

  &:focus {
    border-color: var(--caren-green);
  }
`;

export const Input = styled.input`
  padding-left: 10px;
  margin: 5px 0;
  height: 40px;
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
`;

export const MaskInput = styled(InputMask)`
  height: 30px;
  font-size: 1rem;
  background-color: transparent;
  padding: 26px 0 26px 13px;
  border: 1px solid #bdbdbd;
  margin-bottom: 10px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
`;
