import styled from 'styled-components/macro';

export const Navigation = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--caren-input);

  button {
    background-color: white;
    color: var(--caren-lightgrey);
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    padding: 5px 14px;
    border-radius: 5px 5px 0 0;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;

    @media (max-width: 355px) {
      font-size: 12px;
      padding: 5px 10px 4px;
    }

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.active {
      color: var(--caren-icongrey);
      font-weight: bold;
      border-bottom: 2px solid var(--caren-grey);
    }
  }
`;

export const Tabs = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 10px;
  overflow: auto;
`;

export const TabsContent = styled.div<{ isWidget?: boolean }>`
  position: relative;
  width: 100%;
  padding: ${(props) => (props.isWidget ? '0' : '40px 35px')};
  display: none;

  &.active {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .MuiInputBase-input:focus {
    box-shadow: none;
  }
`;

export const TabsContentTitle = styled.div`
  color: #47525e;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 1140px) {
    font-size: 18px;
  }
`;

export const TabsContentDescription = styled.div`
  background-color: #f8f8f8;
  padding: 8px 15px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  p {
    color: #47525e;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
  }
`;
