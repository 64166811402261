import { useEffect, useState } from 'react';
import formattedDatetime from '../utils/formattedDatetime';

function useCurrentHour () {
  const [currentHour, setCurrentHour] = useState(false);

  useEffect(() => {
    setCurrentHour(formattedDatetime(new Date()));
    const interval = setInterval(() => {
      setCurrentHour(formattedDatetime(new Date()));
    }, 5000);
    return () => { clearInterval(interval); };
  }, []);

  return currentHour;
}

export default useCurrentHour;
