import styled from 'styled-components/macro';

export const OuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;

  font-size: 18px;
  color: var(--caren-grey);
  font-weight: bold;

  @media (max-width: 1023px) {
    font-size: 16px;
    padding: 20px 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px;

      &:not(:last-child) {
        @media (max-width: 1023px) {
          margin-bottom: 10px;
        }
      }
    }

    span {
      margin-left: 1em;

      @media (max-width: 1023px) {
        margin: 0;
      }
    }

    b {
      @media (max-width: 1023px) {
        margin: 0;
      }
    }

    p {
      @media (max-width: 1023px) {
        margin: 0;
      }
    }
  }
`;
