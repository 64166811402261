import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../components/CustomSelect';
import DoctorBadgeCounter from '../../components/DoctorBadgeCounter';
import Header from '../../components/Header';
import VerticalTime from '../../components/VerticalTime';
import DataTracker from '../../components/DataTracker';
import Modal from '../../components/Modal';
import {
  Container,
  FirstGrid,
  SecondGrid,
  DoctorLogo,
  DoctorPanel,
  DoctorChangePhoto,
  DoctorName,
  DoctorSpec,
  CameraIcon,
  DoctorSchedule,
  DoctorTitleBox,
  DoctorTitle,
  DoctorTimeBox,
  DoctorGraph,
  DoctorCamIcon,
  DoctorTimeTitle,
  DoctorClock,
  DivRelativeFlex,
  VerticalTimeContainer,
  SecondHeader,
  SecondSubHeader,
  UserPanel,
  UserImage,
  UserName,
  UserAge,
  UserInfo,
  Gifts,
  StarIcon,
  Trophy,
  SearchInputContainer,
  SearchInputIcon,
  SearchInput,
  Input,
  Textarea,
  ModalButton,
  Form,
  SelectBox,
  SearchButtons,
} from './doctor-record.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';

export default function DoctorRecord() {
  const { t } = useTranslation(['doctor_record']);

  const data = {
    datasets: [
      {
        label: false,
        backgroundColor: ['#00C597', '#ffffff'],
        hoverBackgroundColor: ['#00ffc4', '#ffffff'],
        data: [75, 25],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
      color: 'red',
    },
    tooltips: {
      enabled: false,
    },
    cutoutPercentage: 70,
  };

  const [openModal, setOpenModal] = useState(true);
  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  const TITLE = 'Data Record - Caren';

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <Container>
        <FirstGrid>
          <DoctorPanel>
            <DoctorLogo />
            <DivRelativeFlex>
              <DoctorChangePhoto>
                <CameraIcon />
              </DoctorChangePhoto>
            </DivRelativeFlex>
            <DoctorName>Dr. André Paiva</DoctorName>
            <DoctorSpec>Cardiologista</DoctorSpec>
          </DoctorPanel>
          <DoctorSchedule>
            <DoctorTitleBox>
              <DoctorTitle>{t('Próximo Atendimento')}</DoctorTitle>
              <DoctorBadgeCounter />
            </DoctorTitleBox>
            <DoctorTimeBox>
              <DivRelativeFlex>
                <DoctorGraph zeroPadTime={0} data={data} options={options} />
                <DoctorCamIcon />
              </DivRelativeFlex>
              <DoctorTimeTitle>
                {t('o próximo atendimento começa em')}:
              </DoctorTimeTitle>

              <DoctorClock>
                <Countdown
                  date={Date.now() + 10000000}
                  renderer={({ formatted: { hours, minutes, seconds } }) => (
                    <span>
                      {hours}:{minutes}:{seconds}
                    </span>
                  )}
                />
              </DoctorClock>
            </DoctorTimeBox>
          </DoctorSchedule>
        </FirstGrid>

        <SecondGrid>
          <SecondHeader>
            <UserPanel>
              <UserImage />
              <UserInfo>
                <UserName>
                  Thiago Bonfim
                  <Gifts>
                    <StarIcon />
                    <Trophy />
                  </Gifts>
                </UserName>
                <UserAge>39 {t('anos')}</UserAge>
              </UserInfo>
            </UserPanel>
            <SearchInputContainer>
              <SearchInputIcon />
              <SearchInput />
            </SearchInputContainer>
          </SecondHeader>
          <SecondSubHeader>
            <div>
              <SearchButtons>7 {t('dias')}</SearchButtons>
              <SearchButtons>15 {t('dias')}</SearchButtons>
              <SearchButtons>30 {t('dias')}</SearchButtons>
            </div>
            <DataTracker />
          </SecondSubHeader>
          <VerticalTimeContainer>
            <VerticalTime />
          </VerticalTimeContainer>
        </SecondGrid>
      </Container>
      <Modal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        modalTitle={t('Criar Novo Evento')}
      >
        <Form>
          <SelectBox>
            <CustomSelect />
          </SelectBox>
          <Input type="text" placeholder={t('Título')} />
          <Textarea rows="5" placeholder={t('Mensagem')} />
          <Input
            type="text"
            placeholder={t('Destinatário (Não obrigatório)')}
          />
          <ModalButton type="button">{t('Criar')}</ModalButton>
        </Form>
      </Modal>
    </>
  );
}
