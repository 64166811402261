import styled from 'styled-components/macro';

export const WaitingForRemote = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: var(--caren-white);
  text-align: center;
  width: 100%;
  white-space: pre-wrap;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
