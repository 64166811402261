import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoBlack } from '../../../assets/images/logo-black.svg';
import { ReactComponent as LoginPatient } from '../../../assets/icons/login-patient.svg';
import { ReactComponent as LoginProfessional } from '../../../assets/icons/login-professional.svg';
import { ReactComponent as LoginSecretary } from '../../../assets/icons/secretary-login-icon.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/arrow-left.svg';
import FapesBndes from '../../../assets/images/fapes-bndes.png';

export default function Login() {
  return (
    <>
      <Helmet title="Telessaúde Caren" />
      <div className="flex min-h-screen w-full flex-col bg-yellow-150">
        <div className="flex items-center justify-between px-4 pt-4 lg:px-16 lg:pt-10">
          <a href="https://www.caren.app/tele">
            <LogoBlack className="w-32" />
          </a>
        </div>
        <div className="lg:mt-15 mt-10 flex-1 2xl:mt-32">
          <h1 className="text-center font-nunito text-3xl lg:text-5xl">
            Entrar
          </h1>
          <div className="mt-10 flex w-full flex-row flex-wrap justify-center gap-x-6">
            <Link
              to="/login/paciente"
              className="mb-4 flex h-32 w-32 flex-col gap-y-2 rounded-3xl bg-white px-3 py-3 transition ease-in-out hover:scale-105 lg:h-60 lg:w-60 lg:gap-y-6 lg:px-8 lg:py-6"
            >
              <LoginPatient className="w-9 text-gray-950 lg:w-[4.5rem]" />
              <span className="text-md whitespace-pre text-left font-semibold text-gray-950 lg:text-2xl">
                Paciente
              </span>
            </Link>
            <Link
              to="/login/profissional/doctor"
              className="mb-4 flex h-32 w-32 flex-col gap-y-2 rounded-3xl bg-white px-3 py-3 transition ease-in-out hover:scale-105 lg:h-60 lg:w-60 lg:gap-y-6 lg:px-8 lg:py-6"
            >
              <LoginProfessional className="w-9 text-gray-950 lg:w-[4.5rem]" />
              <span className="text-md whitespace-pre text-left font-semibold text-gray-950 lg:text-2xl">
                Profissional
                <br />
                de saúde
              </span>
            </Link>
            <Link
              to="/login/profissional/secretary"
              className="flex h-32 w-32 flex-col gap-y-2 rounded-3xl bg-white px-3 py-3 transition ease-in-out hover:scale-105 lg:h-60 lg:w-60 lg:gap-y-6 lg:px-8 lg:py-6"
            >
              <LoginSecretary className="w-9 text-gray-950 lg:w-[4.5rem] md:my-3" />
              <span className="text-md whitespace-pre text-left font-semibold text-gray-950 lg:text-2xl">
                Secretária
                <br />
                de saúde
              </span>
            </Link>
            <span className='h-32 w-32 md:hidden'></span>
          </div>
          <div className="mt-4 flex flex-row justify-center">
            <a
              href="https://www.caren.app/tele"
              className="flex items-center gap-x-3 p-4"
            >
              <BackArrow className="w-4 text-black" />
              <span className="uppercase text-black">Voltar</span>
            </a>
          </div>
        </div>
        <div className="mb-8 mt-8 flex flex-col gap-y-4 bg-yellow-180 px-4 py-4 lg:px-16 2xl:py-6">
          <h2 className="text-lg 2xl:text-xl">Conveniados:</h2>
          <div className="flex flex-row gap-x-8">
            <Link
              to="/home/fapes"
              className="rounded-xl bg-white p-4 transition ease-in-out hover:scale-105"
            >
              <img src={FapesBndes} className="w-32" alt="Fapes Logo" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
