import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/AuthContext';
import isMobile from '../../utils/isMobile';

const CONTENT = options => `
window.chatwootSettings = ${JSON.stringify(options)} ;
(function(d,t) {
  var BASE_URL="https://app.chatwoot.com";
  var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
  g.src=BASE_URL+"/packs/js/sdk.js";
  g.defer = true;
  g.async = true;
  s.parentNode.insertBefore(g,s);
  g.onload=function(){
    window.chatwootSDK.run({
      websiteToken: '${import.meta.env.VITE_CHATWOOT_WEBSITE_TOKEN}',
      baseUrl: BASE_URL
    })
  }
})(document,"script");
`;

export default function Chatwoot() {
  const { credentials } = useAuth();

  const updateUserInfo = () => {
    if (typeof window?.$chatwoot?.setUser === 'function') {
      window.$chatwoot.setUser(credentials?.session?.key, {
        email: credentials?.session?.email,
        name: credentials?.session?.name,
        avatar_url: credentials?.session?.avatar_url,
        phone_number: credentials?.session?.phone
      });
    }
  };

  useEffect(() => {
    let scriptNode = document.querySelector('script#chatwoot-script');

    if (!scriptNode) {
      // Create script element and add it to document body
      scriptNode = document.createElement('script');
      scriptNode.id = 'chatwoot-script';
      scriptNode.type = 'text/javascript';
      scriptNode.text = CONTENT(
        isMobile
          ? { position: 'right' }
          : {
              position: 'right',
              type: 'expanded_bubble',
              launcherTitle: 'Fale conosco'
            }
      );
      scriptNode.async = true;
      document.body.appendChild(scriptNode);
    }

    if (typeof window?.$chatwoot?.toggleBubbleVisibility === 'function') {
      window.$chatwoot.toggleBubbleVisibility('show');

      updateUserInfo();
    }

    window.addEventListener('chatwoot:ready', updateUserInfo);

    return () => {
      if (scriptNode) {
        scriptNode.remove();
      }

      if (typeof window?.$chatwoot?.toggleBubbleVisibility === 'function') {
        window.$chatwoot.toggleBubbleVisibility('hide');
      }

      window.removeEventListener('chatwoot:ready', updateUserInfo);
    };
  }, []);

  useEffect(updateUserInfo, [credentials?.session?.key]);

  return <></>;
}
