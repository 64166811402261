import React from 'react';
import Snackbar from '../Snackbar';
import useRoomState from '../../../hooks/video/useRoomState';

export default function ReconnectingNotification() {
  const roomState = useRoomState();

  return (
    <Snackbar
      variant="error"
      headline="Conexão perdida."
      message="Reconectando..."
      open={roomState === 'reconnecting'}
    />
  );
}
