// import React from 'react';
// import inviteFriendLogo from '../../assets/images/invite-friend.png';
// import { Container } from './styles';

function InviteFooter() {
  return null;
  // return (
  //   <Container>
  //     <img src={inviteFriendLogo} alt="logo convite" />
  //     <p>CONVIDE UM AMIGO</p>
  //   </Container>
  // );
}

export default InviteFooter;
