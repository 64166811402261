import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import { useTranslation } from 'react-i18next';
import { Container } from './styles';

const useStyles = makeStyles({
  root: {
    width: '60%',
  },
});

function valuetext(value) {
  return `${value}`;
}

export default function DiscreteSlider() {
  const { t } = useTranslation(['doctor_record']);
  const classes = useStyles();

  const marks = [
    {
      value: 0,
      label: t('Jan'),
    },
    {
      value: 10,
      label: t('Fev'),
    },
    {
      value: 20,
      label: t('Mar'),
    },
    {
      value: 30,
      label: t('Abr'),
    },
    {
      value: 40,
      label: t('Jun'),
    },
    {
      value: 50,
      label: t('Jul'),
    },
    {
      value: 60,
      label: t('Ago'),
    },
    {
      value: 70,
      label: t('Set'),
    },
    {
      value: 80,
      label: t('Out'),
    },
    {
      value: 90,
      label: t('Nov'),
    },
    {
      value: 100,
      label: t('Dez'),
    },
  ];

  return (
    <Container className={classes.root}>
      <Slider
        defaultValue={30}
        aria-labelledby="discrete-slider-small-steps"
        getAriaValueText={valuetext}
        // valueLabelDisplay="auto"
        step={10}
        marks={marks}
      />
    </Container>
  );
}
