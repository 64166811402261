export default (str, pat, n) => {
  let i;

  for (i = -1; n > 0; n -= 1) {
    i = str.indexOf(pat, i + 1);
    if (i === -1) break;
  }

  return i;
};
