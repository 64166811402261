import React from 'react';

import { IoLogoTwitter } from 'react-icons/io';
import {
  FaFacebookF,
  FaRegHeart,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
// import ReactFlagsSelect from 'react-flags-select';
import {
  FooterContainer,
  // LogoFooter,
  RightContainer,
  RightMainContent,
} from './footer.styles';
import // countries,
// countryLabels,
// countryToLocale,
// localeToCountryAcronym,
'../../utils/countryLocaleUtils';

export default function Footer({ enableLanguagePicker = true }) {
  const { t } = useTranslation(['home']);

  return (
    <FooterContainer>
      <div>
        <p className="footer-text">
          &copy; {new Date().getFullYear()}. {t('Todos direitos reservados.')}{' '}
          <br />
          Feito com <FaRegHeart /> no Rio de Janeiro
        </p>
        <div className="icons-container">
          <a
            href="https://twitter.com/Caren_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoTwitter
              color="var(--caren-white)"
              style={{ opacity: '0.5' }}
              size={16}
            />
          </a>
          <a
            href="https://www.facebook.com/CarenChat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF
              color="var(--caren-white)"
              style={{ opacity: '0.5' }}
              size={16}
            />
          </a>
          <a
            href="https://www.instagram.com/caren.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram
              color="var(--caren-white)"
              style={{ opacity: '0.5' }}
              size={16}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/carenapp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn
              color="var(--caren-white)"
              style={{ opacity: '0.5' }}
              size={16}
            />
          </a>
        </div>
      </div>
      {enableLanguagePicker && (
        <RightContainer>
          <RightMainContent>
            {/* <ReactFlagsSelect
              placeholder="Idioma"
              countries={countries}
              customLabels={countryLabels}
              selected={localeToCountryAcronym(i18n.language)}
              onSelect={(code) => i18n.changeLanguage(countryToLocale(code))}
            /> */}
          </RightMainContent>
        </RightContainer>
      )}
    </FooterContainer>
  );
}
