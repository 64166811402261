import styled from 'styled-components/macro';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const FirstGrid = styled.div`
  background-color: #f6f7f8;
  padding: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SecondGrid = styled.div`
  padding: 20px;
`;

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubgridTitle = styled.div`
  min-height: 32px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor && 'var(--caren-subheader)'};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 5px;
  margin-bottom: 15px;
  p {
    color: var(--caren-white);
    font-size: 12px;
  }
`;

export const ResponseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;

  text-align: center;

  p {
    font-size: 28px;
    color: var(--caren-icongrey);
  }
`;

export const TitlePage = styled.h2`
  font-weight: bold;
  margin: 20px 0;
  color: #64727f;
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AddButtonContainer = styled.div`
  text-align: right;
`;

export const VoucherButton = styled.button`
  background: white;
  color: var(--caren-greenblue);
  padding: 5px 15px;
  border-radius: 5px;
  padding-top: 13px;
  box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 37%);

  span {
    top: -14px;
    position: relative;
    font-weight: 600;
    padding: 0 8px;
    font-size: 21px;
  }
`;
