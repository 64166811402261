import styled from 'styled-components/macro';

export const SecretaryPanelContainer = styled.aside`
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    padding-right: 10px;

    @media (max-width: 1024px) {
      padding-right: 0;
    }

    &:nth-child(2) {
      margin-top: 10px;

      > p {
        background: var(--caren-white);
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        color: #262c2e;
        padding: 5px 0;
        border-radius: 4px;
      }
    }
  }

  & > ul {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    overflow-y: auto;
    height: 70vh;
    padding-right: 10px;
    scroll-snap-type: y mandatory;

    @media (max-width: 1024px) {
      height: fit-content;
      padding-right: 0;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: #3995a9;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #077a94;
    }
  }
`;

export const CreateConsultButton = styled.button`
  font-weight: bold;
  font-size: 14px;
  color: var(--caren-white);
  background-color: #3995a9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  width: 100%;
  padding: 16px;
  text-transform: uppercase;
  color: #262c2e;

  & > svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: #077a94;
  }
`;

export const DoctorConsultCard = styled.article<{
  today?: boolean;
  pending?: boolean;
}>`
  width: 100%;
  background-color: var(--caren-white);
  border-radius: 14px;
  padding: 14px 18px;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;

  & > header {
    padding-bottom: 6px;
    border-bottom: 1px solid #d7d7d7;
    position: relative;

    & > span {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 12px;
      font-weight: bold;
      color: ${props => (props.today ? '#03A691' : '#262C2E')};
    }

    & > h2 {
      font-weight: bold;
      color: #262c2e;
      margin: 0;
      font-size: 16px;
    }

    & > p {
      font-weight: normal;
      color: #262c2e;
      margin: 0;
      font-size: 14px;
    }

    & > div {
      display: flex;
      align-items: flex-end;

      & > div {
        border-radius: 100%;

        :nth-child(1) {
          width: 64px;
          height: 64px;
          padding: 4px;
          display: grid;
          place-content: center;
          background-color: ${props => (props.today ? '#FDBF71' : '#262c2e')};
        }

        :nth-child(2) {
          width: 49px;
          height: 49px;
          padding: 4px;
          display: grid;
          place-content: center;
          background-color: ${props => (props.today ? '#FDBF71' : '#262c2e')};
        }
      }
    }
  }

  & > footer {
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => (props.pending ? '#FBA474' : '#262c2e')};

    & > p {
      :first-child {
        font-weight: normal;
        margin: 0;
        font-size: 12px;
      }

      :nth-child(2) {
        font-weight: bold;
        margin: 0;
        font-size: 26px;

        & > span {
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }

    & > button {
      text-decoration: underline;
      background: none;
      font-weight: bold;
      font-size: 12px;
    }
  }
`;
