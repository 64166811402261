import { useCallback } from 'react';
import useIsTrackEnabled from './useIsTrackEnabled';
import useVideoContext from './useVideoContext';

export default function useLocalAudioToggle() {
  const { localTracks, setAudioTrackEnabled } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio');
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      if (audioTrack.isEnabled) {
        audioTrack.disable();
        setAudioTrackEnabled(false);
      } else {
        audioTrack.enable();
        setAudioTrackEnabled(true);
      }
    }
  }, [audioTrack, setAudioTrackEnabled]);

  return [isEnabled, toggleAudioEnabled];
}
