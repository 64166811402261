import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import ContainerOpacity from '../../components/ContainerOpacity';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
import {
  getDoctorsInvitations,
  resendConsultInvitation
} from '../../services/secretary';
import { useToast } from '../../contexts/ToastContext';
import { SecretaryHome, ReturnButton, Title, DocListBox } from './styles';
import SecretaryPanel from '../../components/SecretaryPanel';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
import DoctorInvitesGrid from '../../components/SecretaryInvites/DoctorInvitesGrid/DoctorInvitesGrid';
import { BackButton } from '../../components/BackButton';
import { type Invite } from '../../v2/entities/invite';
import { acceptTeamInvitation, denyTeamInvitation } from '../../services/team';
import SuccessStep from '../../v2/dialogs/consult/steps/success';

export default function SecretaryInvites() {
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [unreversibleType, setUnreversibleType] = useState<null | 'resend'>(
    null
  );
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [invitationLink, setInvitationLink] = useState<string | null>(null);
  const toast = useToast();
  const handleToggleConfirmationModal = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const [selectedInvite, setSelectedInvite] = useState<string | null>(null);
  const [openInviteUnreversible, setOpenInviteUnreversible] = useState(false);

  const [doctorsList, setDoctorsList] = useState<Invite[] | null>(null);

  const getDoctorsFromSecretary = useCallback(async () => {
    if (doctorsList === null) {
      const newDoctors = await getDoctorsInvitations();
      setDoctorsList(newDoctors);
    }
  }, [doctorsList]);

  useEffect(() => {
    getDoctorsFromSecretary();
  }, [getDoctorsFromSecretary]);

  const decideUnreversibleTitle = useCallback(() => {
    if (unreversibleType === 'resend') {
      return 'Você tem certeza de que deseja reenviar esse convite?';
    }

    return null;
  }, [unreversibleType]);

  const resendInvite = useCallback(
    async (key: string) => {
      if (key.length <= 0) return;

      try {
        const { link } = await resendConsultInvitation(key);

        setInvitationLink(link);

        toast('Convite reenviado com sucesso!', {
          variant: 'success'
        });

        setOpenConfirmation(true);
      } catch (e) {
        toast('Ops, não foi possível reenviar o convite :(', {
          variant: 'error'
        });
      }
    },
    [toast]
  );

  const handleConfirmation = useCallback(
    (status: boolean) => {
      if (status && selectedKey) {
        if (unreversibleType === 'resend') {
          resendInvite(selectedKey);
        }
      }

      setUnreversibleType(null);
      setSelectedKey(null);
      setOpenUnreversible(false);
    },
    [resendInvite, selectedKey, unreversibleType]
  );

  const handleInvite = useCallback(
    async (accepted: boolean) => {
      if (!selectedInvite) return;

      try {
        if (accepted) {
          await acceptTeamInvitation(selectedInvite);
        } else {
          await denyTeamInvitation(selectedInvite);
        }

        setSelectedInvite(null);
        setOpenInviteUnreversible(false);

        toast(`Convite ${accepted ? 'aceito' : 'recusado'} com sucesso!`, {
          variant: 'success'
        });

        setDoctorsList(null);
      } catch (e) {
        toast(
          `Ops, não foi possível ${
            accepted ? 'aceitar' : 'recusar'
          } o convite :(`,
          {
            variant: 'error'
          }
        );
      }
    },
    [selectedInvite, toast]
  );

  return (
    <>
      <Helmet title="Estatísticas - Caren"></Helmet>
      <ContainerOpacity />
      <Header />
      <UserSidebar />
      <SecretaryHome>
        <SecretaryPanel
          onResendClick={key => {
            setSelectedKey(key);
            setUnreversibleType('resend');
            setOpenUnreversible(true);
          }}
        />
        <DocListBox>
          <ReturnButton>
            <BackButton path="/clinic" title="Voltar" />
          </ReturnButton>
          <Title>Confira aqui com quais médicos você possui vínculo</Title>
          {doctorsList && (
            <DoctorInvitesGrid
              onClickInvite={invite => {
                setSelectedInvite(invite.key);
                setOpenInviteUnreversible(true);
              }}
              data={doctorsList}
            />
          )}
        </DocListBox>
      </SecretaryHome>

      <UnreversibleConfirmationModal
        title={decideUnreversibleTitle()}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
        cancelButtonText={null}
        confirmButtonText={null}
      />

      <UnreversibleConfirmationModal
        title="O que você deseja fazer?"
        openModal={openInviteUnreversible}
        setOpenModal={setOpenInviteUnreversible}
        handleConfirmation={async (status: boolean) => {
          await handleInvite(status);
        }}
        cancelButtonText="Rejeitar"
        confirmButtonText="Aceitar"
      />

      <Modal
        handleOpenModal={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        openModal={openConfirmation}
        modalTitle="Convite Enviado!"
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside
      >
        <SuccessStep
          invitationLink={invitationLink ?? ''}
          onClose={handleToggleConfirmationModal}
        />
      </Modal>

      <Tawk />
      <Chatwoot />
    </>
  );
}
