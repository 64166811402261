import { useEffect, useContext } from 'react';
import { Store } from '../store';
import { unreadNotificationsService } from '../services/notification.service';
import { setUnreadCount } from '../store/modules/Notification/actions';
import { useAuth } from './AuthContext';

const REFRESH_TIME = 5000;

function useNotificationCount() {
  const { credentials } = useAuth();
  const [state, dispatch] = useContext(Store);

  const { unread = 0, lastRefresh } = state.notifications;

  const isLoggedIn = credentials?.token.token != null;
  const shouldUpdate = !lastRefresh || Date.now() - lastRefresh > REFRESH_TIME;

  useEffect(() => {
    if (isLoggedIn && shouldUpdate) {
      unreadNotificationsService().then(({ counter }) => {
        dispatch(setUnreadCount(counter));
      });
    }
  }, [dispatch, isLoggedIn, shouldUpdate]);

  return unread;
}

export default useNotificationCount;
