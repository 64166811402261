import React, { useState } from 'react';

type PlanIntervals = 'day' | 'week' | 'month' | 'year';

type Plan = {
  key: string;
  price: number;
  currency: string;
  name: string;
  interval: PlanIntervals;
  tier: number;
};

type PlansListProps = {
  plans: Plan[];
  loading: boolean;
  currentPlan?: Plan;
};

const PLAN_INTERVAL_WEIGHT: Record<PlanIntervals, number> = {
  day: 1,
  week: 2,
  month: 3,
  year: 4
};

export default function PlansList({
  plans,
  loading,
  currentPlan
}: PlansListProps) {
  const [planSelected, setPlanSelected] = useState<string | null>(null);

  const renderInterval = (interval: PlanIntervals) => {
    switch (interval) {
      case 'month':
        return '/mês';
      case 'year':
        return '/ano';
      case 'day':
        return '/dia';
      default:
        return '';
    }
  };

  const goToPlan = () => {
    window.location.assign('/checkout/' + planSelected);
  };

  const disablePlanChange = (current: Plan, compare: Plan) => {
    if (current.tier >= compare.tier) return false;

    const currentInterval = PLAN_INTERVAL_WEIGHT[current.interval];
    const compareInterval = PLAN_INTERVAL_WEIGHT[compare.interval];

    return currentInterval > compareInterval;
  };

  return (
    <>
      <div className="flex w-full border-b border-gray-200 bg-gray-150 px-4 py-3 font-bold md:px-7">
        <div className="w-1/2 md:w-2/3">Plano</div>
        <div className="w-1/2 md:w-1/3">Valor</div>
      </div>
      {!loading &&
        plans &&
        React.Children.toArray(
          plans.map(item => (
            <button
              disabled={
                currentPlan ? disablePlanChange(currentPlan, item) : false
              }
              title={
                currentPlan && disablePlanChange(currentPlan, item)
                  ? 'Este plano não está disponível para sua conta'
                  : 'Clique para selecionar este plano'
              }
              type="button"
              onClick={() => {
                setPlanSelected(item.key);
              }}
              className={`group flex w-full cursor-pointer rounded-md border-b border-gray-200 px-4 py-3 text-left disabled:cursor-not-allowed disabled:bg-gray-180 disabled:opacity-30 md:px-7 ${
                planSelected === item.key ? 'bg-yellow-150' : 'bg-transparent'
              }`}
            >
              <p className="w-1/2 group-disabled:line-through md:w-2/3">
                {item.name}
              </p>
              <div className="w-1/2 group-disabled:line-through md:w-1/3">
                {(item?.price / 100).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: item?.currency || 'BRL'
                })}
                {renderInterval(item.interval)}
              </div>
            </button>
          ))
        )}
      {loading && (
        <>
          <div className="flex w-full cursor-pointer rounded-md border-b border-gray-200 px-7 py-3">
            <div className="w-2/3">
              <div className="h-5 w-32 animate-pulse rounded-lg bg-gray-200 duration-300 group-first:mx-0 group-odd:w-40" />
            </div>
            <div className="bg- w-1/3">
              <div className="h-5 w-32 animate-pulse rounded-lg bg-gray-200 duration-300 group-first:mx-0 group-odd:w-40" />
            </div>
          </div>
          <div className="flex w-full cursor-pointer rounded-md border-b border-gray-200 px-7 py-3">
            <div className="w-2/3">
              <div className="h-5 w-32 animate-pulse rounded-lg bg-gray-200 duration-300 group-first:mx-0 group-odd:w-40" />
            </div>
            <div className="bg- w-1/3">
              <div className="h-5 w-32 animate-pulse rounded-lg bg-gray-200 duration-300 group-first:mx-0 group-odd:w-40" />
            </div>
          </div>
        </>
      )}
      <div className="mt-5 flex justify-center">
        <button
          type="button"
          disabled={planSelected === null}
          onClick={() => {
            goToPlan();
          }}
          className={`${
            !planSelected
              ? 'bg-yellow-400 text-gray-350'
              : 'bg-yellow-650 text-black hover:bg-yellow-750 active:bg-yellow-650'
          } mt-5 rounded-md p-2 px-10  text-lg font-bold`}
        >
          Confirmar
        </button>
      </div>
    </>
  );
}
