import React, { useMemo } from 'react';
import { useAuth } from '../../hooks/AuthContext';
import {
  ROLE_PATIENT,
  ROLE_PROFESSIONAL,
  ROLE_SECRETARY
} from '../../routes/constants';
import DoctorProfile from './Doctor';
import Patient from './Patient';
import Secretary from './Secretary';

function Profile({ location }) {
  const { credentials } = useAuth();

  return useMemo(() => {
    if (credentials?.token?.user_type === ROLE_PROFESSIONAL)
      return <DoctorProfile view={location?.state?.view} />;
    if (credentials?.token?.user_type === ROLE_PATIENT) return <Patient />;
    if (credentials?.token?.user_type === ROLE_SECRETARY) return <Secretary />;
    return null;
  }, [credentials]);
}

export default Profile;
