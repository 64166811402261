import { AiFillCamera } from 'react-icons/ai';
import styled from 'styled-components/macro';
import ImageOrName from '../ImageOrName';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 32px;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const DoctorLogo = styled(ImageOrName).attrs(({ src }) => ({
  src
}))``;

export const CameraIcon = styled(AiFillCamera).attrs({
  size: 20,
  color: 'var(--caren-white)'
})`
  background-color: var(--caren-darkgreen);
  height: 35px;
  width: 35px;
  padding: 5px;
  border-radius: 50%;

  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const InfoContainer = styled.div`
  margin-top: 10px;
`;

export const ImageContainerLoading = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.5s;
  pointer-events: none;
  z-index: 50;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`;

export const ImageContainerSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: relative;

  @keyframes doctor_panel_progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: doctor_panel_progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const DoctorName = styled.p`
  color: var(--caren-icongrey);
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
`;

export const DoctorSpec = styled.p`
  color: var(--caren-icongrey);
  font-size: 16px;
  text-align: center;
`;

export const NextDoctorContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
