import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { FaRegCheckCircle, FaArrowAltCircleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { scheduleDetailsService } from '../../../services/patient-service';

import {
  MainText,
  ProcessContainer,
  ProcessFooter,
  UserProcessContainer,
  UserProcessBackground,
  UserProcessImg,
  ProcessLine,
  UploadIcon,
  Border,
  ProcessTime,
  Text,
  BorderBox,
  FooterText,
} from '../consult.styles';

import {
  ProcessFooterCenter,
  GetInRoom,
  GetInIcon,
} from './consult-confirmed.styles';

export default function CurrentConsult({
  scheduleDetails,
  consultId,
  //  doctorBankDetails
}) {
  const { t } = useTranslation(['consult_confirmed']);
  const [opened, setOpened] = useState(false);
  const TITLE = 'Sala de Espera - Caren';

  useEffect(() => {
    if (!opened) {
      const interval = setInterval(async () => {
        const { data } = await scheduleDetailsService(consultId);

        if (data.open) {
          setOpened(true);
        }
      }, 10000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [consultId, opened]);

  const dots = new Array(15).fill(true);
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <MainText>{t('Consulta confirmada')}</MainText>
      <ProcessContainer>
        <UserProcessContainer>
          <UserProcessBackground>
            <UserProcessImg
              opacity={1}
              imgUrl={scheduleDetails?.doctor?.avatar_url}
              backgroundStyle={{ width: 160 }}
            />
          </UserProcessBackground>
        </UserProcessContainer>

        {dots.map((_, index) => (
          <ProcessLine color="var(--caren-lightgrey)" key={index.toString()} />
        ))}
        <UploadIcon color="var(--caren-green)" />
        {dots.map((_, index) => (
          <ProcessLine color="var(--caren-lightgrey)" key={index.toString()} />
        ))}
        <Link to={`/consultas/${consultId}/sala-de-espera`}>
          <UserProcessContainer>
            <UserProcessBackground>
              <UserProcessImg
                opacity={1}
                imgUrl={scheduleDetails?.doctor?.avatar_url}
                backgroundStyle={{ width: 160 }}
              />
            </UserProcessBackground>
            {opened && (
              <GetInRoom>
                <p>{t('Entrar na Sala')}</p>
                <GetInIcon />
              </GetInRoom>
            )}
          </UserProcessContainer>
        </Link>
      </ProcessContainer>
      <Border />
      <ProcessFooterCenter>
        <ProcessTime>
          <Text>{t('Tempo para confirmação')}</Text>
          <BorderBox>
            <div className="time-success">
              <p>
                {t('Comprovante Verificado')} <FaRegCheckCircle />
              </p>
            </div>
            <div className="time">
              <p>06:10</p>
            </div>
          </BorderBox>
        </ProcessTime>
      </ProcessFooterCenter>
      <Border />
      <ProcessFooter>
        <FooterText>
          {t(
            'Responda ao questionário de pré-atendimento. Perguntas de saúde que ajudam o seu médico.'
          )}{' '}
          <FaArrowAltCircleRight size={18} />
        </FooterText>
      </ProcessFooter>
    </>
  );
}
