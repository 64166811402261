import React, { useState } from 'react';
import { BackButton } from '../../components/BackButton';
import { useToast } from '../../contexts/ToastContext';
import { useSocket } from '../../hooks/SocketContext';

import { Content, Button } from './styles';

function ChannelTest() {
  const CHANNEL_TOPIC = 'signup:test';

  const [enableJoin, setEnableJoin] = useState(false);
  const toast = useToast();

  const { channel } = useSocket(
    CHANNEL_TOPIC,
    { test: true },
    { enableJoin, afterJoin: listenToMessages }
  );

  function listenToMessages(joined) {
    joined.on('shout', handleShout);
  }

  function handleShout(payload) {
    toast(`Shout ${JSON.stringify(payload)}`);
  }

  const connect = () => {
    setEnableJoin(true);
  };

  const sendPing = () => {
    channel
      .push('ping', { foo: 'bar' }, 10000)
      .receive('ok', (msg) => toast(`Pong ${JSON.stringify(msg)}`));
  };

  return (
    <>
      <BackButton path="/perfil" />
      <Content>
        <p>Phoenix Channel Test</p>
        {channel == null ? (
          <Button type="button" onClick={connect}>
            Connect
          </Button>
        ) : (
          <Button type="button" onClick={sendPing}>
            Send ping
          </Button>
        )}
      </Content>
    </>
  );
}

export default ChannelTest;
