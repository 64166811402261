import { type Consult, type ConsultInvite } from '../v2/entities/consult';
import api from './api';

export const getDoctorSchedule = async (
  from: string,
  to: string,
  employee?: string
) => {
  const params: {
    from: string
    to: string
    limit: number
    employee_key?: string
  } = {
    from,
    to,
    limit: 50
  };

  if (employee) {
    params.employee_key = employee;
  }

  const { data } = await api.get<{ data: Consult[] }>('/employees/schedule', {
    params
  });

  return data.data;
};

export const createScheduleInvitation = async (params: ConsultInvite) => {
  const { data } = await api.post('/teleconsultations/invitations', params);

  return data;
};
