import { z } from 'zod';

const PlanSchema = z.object({
  currency: z.enum(['BRL']),
  description: z.string(),
  interval: z.enum(['day', 'month', 'year']),
  key: z.string(),
  name: z.string(),
  price: z.number()
});

type Plan = z.infer<typeof PlanSchema>;

export { PlanSchema };
export type { Plan };
