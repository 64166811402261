import React from 'react';
import { Link } from 'react-router-dom';

type StepBase = {
  title: string;
  tooltip?: string;
};

type StepLink = {
  type: 'link';
  href: string;
  disabled?: boolean;
} & StepBase;

type StepButton = {
  type: 'button';
  onClick: () => void;
  disabled?: boolean;
} & StepBase;

type StepText = {
  type: 'text';
} & StepBase;

type WaitRoomStepsProps = {
  steps: Array<StepLink | StepButton | StepText>;
  actualStep: number;
};

export default function WaitRoomSteps({
  steps,
  actualStep
}: WaitRoomStepsProps) {
  return (
    <div className="mt-8 flex flex-col justify-center gap-8 text-center md:flex-row md:gap-4">
      {React.Children.toArray(
        steps.map((step, index) => (
          <div
            data-active={
              (index === 0 && actualStep === 0) || actualStep === index + 1
            }
            data-full={actualStep > index + 1}
            className="group relative flex w-full items-center justify-start gap-3 md:max-w-24 md:flex-col"
          >
            <p className="z-10 grid h-8 w-8 shrink-0 place-items-center rounded-full bg-gray-250 text-sm text-white group-data-[active=true]:bg-yellow-750 group-data-[full=true]:bg-success-light sm:h-9 sm:w-9 sm:text-base">
              {index + 1}
            </p>
            <p className="text-balance text-left text-sm font-semibold leading-4 text-gray-250 group-data-[active=true]:text-yellow-750 group-data-[full=true]:text-success-light sm:text-center">
              {step.title}
            </p>
            {index > 0 && (
              <div className="absolute -top-4 left-4 h-1/3 w-0.5 bg-gray-250 group-data-[active=true]:bg-success-light group-data-[full=true]:bg-success-light md:-left-4 md:top-4 md:h-0.5 md:w-1/3" />
            )}
            {index < steps.length - 1 && (
              <div className="absolute -bottom-4 left-4 h-1/3 w-0.5 bg-gray-250 group-data-[full=true]:bg-success-light md:-right-4 md:bottom-auto md:left-auto md:top-4 md:h-0.5 md:w-1/3" />
            )}
            {step.type === 'link' && actualStep >= index + 1 && (
              <Link
                data-disabled={step.disabled}
                className="absolute inset-0 z-20 h-full w-full data-[disabled=true]:pointer-events-none data-[disabled=true]:hidden"
                to={step.href}
              />
            )}
            {step.type === 'button' && actualStep >= index + 1 && (
              <button
                type="button"
                disabled={step.disabled}
                onClick={step.onClick}
                className="absolute inset-0 z-20 h-full w-full disabled:pointer-events-none disabled:hidden"
              />
            )}
            {step.tooltip && actualStep >= index + 1 && (
              <div className="pointer-events-none absolute left-10 top-[90%] z-30 min-w-32 text-balance rounded-md px-1 py-0.5 text-left text-xs font-semibold leading-tight text-gray-400 opacity-100 transition-all duration-300 md:left-auto md:top-[110%] md:bg-gray-400 md:text-center md:text-white md:opacity-0 md:group-hover:opacity-100">
                {step.tooltip}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}
