import styled from 'styled-components/macro';
import { IoIosRemoveCircle } from 'react-icons/io';
import { AiOutlineBank } from 'react-icons/ai';
import { GiHealthNormal, GiTicket } from 'react-icons/gi';
import NumberFormat from 'react-number-format';

export const RedirectButton = styled.button`
  border-radius: 4px;
  background-color: var(--caren-greenblue);
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  width: 150px;
  color: var(--caren-white);
  margin-left: auto;
  display: block;
  cursor: pointer;
`;

export const SaveButton = styled.button`
  border-radius: 5px;
  height: 35px;
  margin-top: 20px;
  width: 100%;
  font-size: 16px;
  margin-right: auto;
  color: var(--caren-white);
  background-color: var(--caren-green);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > li {
    width: 300px;
    height: 300px;
    border-radius: 5px;
    margin-bottom: 20px;
    background: var(--caren-white);
  }
`;

export const BankTransfer = styled(AiOutlineBank).attrs({
  color: '#00A0B1',
  size: '40%'
})``;

export const HealthInsurance = styled(GiHealthNormal).attrs({
  color: '#00A0B1',
  size: '40%'
})``;

export const Voucher = styled(GiTicket).attrs({
  color: '#00A0B1',
  size: '40%'
})``;

export const Icon = styled.span`
  display: flex;
  justify-content: center;
`;

export const UpdateDurationButton = styled(RedirectButton).attrs({
  type: 'submit'
})``;

export const RemoveIcon = styled(IoIosRemoveCircle).attrs({
  size: 25,
  color: 'var(--caren-red)'
})`
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    transform: rotate(360deg) scale(1.2);
  }
`;

export const FormContainer = styled.form`
  margin-bottom: 10px;
  background: #f7f7f7;
  padding: 25px;
  border-radius: 5px;

  margin: 10px 0;
`;

export const CalendarContainer = styled.div`
  min-width: 670px;
`;

export const Label = styled.label`
  display: block;
  padding: 10px;

  h1 {
    font-size: 18px;
    margin-top: 20px;
    text-align: center;
    color: var(--caren-greenblue);
  }
  input {
    margin-right: 5px;
    position: relative;
    top: 1px;

    &[type='checkbox'] {
      width: 1.5em;
      height: 1.5em;
    }
  }
`;

export const CurrencyInputContainer = styled.div`
  display: flex;
  justify-content: center;

  input {
    border-bottom: 1px solid #bfbfbf;
  }
`;

export const SectionContainer = styled.div`
  margin-bottom: 10px;
  background: #f7f7f7;
  padding: 25px;
  border-radius: 5px;
  width: 100%;
  overflow-x: auto;
`;

export const InlineInput = styled(NumberFormat)`
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--caren-lightgrey) !important;
  border-radius: 0 !important;
  width: auto !important;
  margin-left: 0%;
  margin-bottom: 10px;
`;

export const InlineInputPresential = styled(NumberFormat)`
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--caren-lightgrey) !important;
  border-radius: 0 !important;
  display: inline-block;
  width: 20% !important;
  min-width: 160px;
  margin: 0;
`;

export const TeleConsultation = styled.div`
  display: flex;
  justify-content: center;
`;
