import { isPlainObject } from 'is-plain-object';

const removeUndefineds = (obj) => {
  if (!isPlainObject(obj)) return obj;

  const target = {};

  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  });

  return target;
};

export default removeUndefineds;
