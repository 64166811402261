import React from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { useVideoAppState } from '../../../../hooks/video/useVideoAppState';
import useDevices from '../../../../hooks/video/useDevices';

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useVideoAppState();
  const activeOutputLabel = audioOutputDevices.find(
    (device) => device.deviceId === activeSinkId
  )?.label;

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            Saída de Áudio
          </Typography>
          <Select
            onChange={(e) => setActiveSinkId(e.target.value)}
            value={activeSinkId}
            variant="outlined"
          >
            {audioOutputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2">Saída de Áudio</Typography>
          <Typography>
            {activeOutputLabel || 'Saída padrão do sistema'}
          </Typography>
        </>
      )}
    </div>
  );
}
