import React from 'react';

type ActionsCellProps = {
  actions: React.ReactNode[];
};

export default function ActionsCell({ actions }: ActionsCellProps) {
  return (
    <td className="px-8 py-5 text-center">
      <span className="mb-1 block font-nunito text-sm font-semibold text-blue-650 lg:hidden">
        Ações
      </span>
      <div className="flex h-full w-full items-center justify-center gap-x-2.5">
        {React.Children.toArray(actions)}
      </div>
    </td>
  );
}
