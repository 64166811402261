import React from 'react';

type TableBodyProps = {
  children: React.ReactNode
}

export default function TableBody({ children }: TableBodyProps) {
  return (
    <tbody className="w-full bg-white divide-y divide-gray-200/50">
      {children}
    </tbody>
  );
}
