import { z } from 'zod';

const ActiveSubscriptionSchema = z.object({
  key: z.string(),
  current_cycle_end: z.string(),
  current_cycle_start: z.string(),
  start_at: z.string(),
  status: z.string(),
  plan: z.object({
    key: z.string(),
    name: z.string(),
    enable_private_teleconsultation_invitations: z.boolean(),
    enable_secretaries: z.boolean()
  })
});

type ActiveSubscription = z.infer<typeof ActiveSubscriptionSchema>;

export { ActiveSubscriptionSchema };
export type { ActiveSubscription };
