import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoBlack } from '../../../assets/images/logo-black.svg';

import { useToast } from '../../../contexts/ToastContext';
import HomeSidebar from '../../../components/Sidebar/HomeSidebar';
import DoctorForm, { FormTypes } from '../../components/forms/doctor';
import { type DoctorCreate } from '../../entities/doctor';
import { AxiosError } from 'axios';
import { doctorSignup } from '../../../services/doctor';
import { useAuth } from '../../../hooks/AuthContext';

export default function DoctorSignup() {
  const { t } = useTranslation(['login']);
  const toast = useToast();
  const { persistSession } = useAuth();

  async function handleCreateDoctor(formData: DoctorCreate) {
    try {
      const user = await doctorSignup(formData);
      localStorage.setItem('@Caren:firstAccess', 'true');

      persistSession(user.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.response?.data?.error?.message?.login) {
          toast(`Email ${error.response.data.error.message.login}`, {
            variant: 'error'
          });
        } else if (error?.response?.data?.error?.message?.email) {
          toast(`Email ${error.response.data.error.message.email}`, {
            variant: 'error'
          });
        } else
          toast(t('Não foi possível efetuar o cadastro, tente novamente.'), {
            variant: 'error'
          });
      }
    }
  }

  return (
    <>
      <Helmet title="Telessaúde Caren" />
      <HomeSidebar onCloseCallback={() => {}} />
      <div className="flex min-h-screen w-full flex-col bg-yellow-150">
        <div className="flex items-center justify-between px-4 pt-4 lg:px-16 lg:pt-10">
          <a href="https://www.caren.app/tele">
            <LogoBlack className="w-32" />
          </a>
        </div>
        <div className="lg:mt-22 mt-10 flex flex-1 flex-col items-center">
          <h1 className="max-w-[95%] text-balance text-center font-nunito text-xl sm:max-w-xl sm:text-2xl lg:text-3xl">
            Crie seu cadastro como Profissional de Saúde para acessar a
            plataforma.
          </h1>
          <DoctorForm
            className="mx-auto mb-8 mt-8 flex w-[512px] max-w-[95%] flex-col items-center justify-between rounded-xl bg-white px-8 py-8"
            saveData={handleCreateDoctor}
            buttonText="Cadastrar"
            type={FormTypes.Register}
            allowLogin={false}
          />
        </div>
      </div>
    </>
  );
}
