import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { RiStethoscopeLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../contexts/ToastContext';
import Sidebar from '..';
import Loading from '../../Loading';
import Modal from '../../Modal';
import history from '../../../services/history';

import {
  LogoSidebar,
  Image,
  SignupButton,
  SignupContainer,
  ChatLogo,
  SignupButtonText,
  Copyright,
  InputField,
  Forgot,
  CaptchaContainer,
  ErrorMessage,
} from './homesidebar.styles';
import SidebarBox from '../SidebarBox';
import { useAuth } from '../../../hooks/AuthContext';
import { forgotPasswordService } from '../../../services/forgot-password.service';
import {
  ROLE_PATIENT,
  ROLE_PROFESSIONAL,
  ROLE_SECRETARY,
} from '../../../routes/constants';

export default function HomeSidebar({ onCloseCallback }) {
  const [loading, setLoading] = useState(false);
  const [signInError, setSignInError] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(false);
  const [_setUnavaiable, unavaiable] = useState(true);
  const location = useLocation();

  const captchaEnabled = import.meta.env.VITE_RECAPTCHA_KEY != null;

  const {
    signIn,
    credentials,
  } = useAuth();

  const toast = useToast();
  const { register, handleSubmit, errors } = useForm();
  const {
    register: forgetRegister,
    handleSubmit: handleForgetSubmit,
    errors: forgetErrors,
  } = useForm();

  const { t } = useTranslation(['home']);
  const recaptchaRef = useRef(null);

  const onSubmit = async (data) => {
    if (!captchaEnabled || captchaResponse) {
      setLoading(true);
      setSignInError(false);
      try {
        const result = await signIn({
          login: data.login,
          password: data.password,
          recaptcha_response: captchaResponse,
        });

        setSignInError(!result);
      } catch (error) {
        toast(t('No momento, não foi possível realizar o login.'), {
          variant: 'error',
        });
      }

      if (recaptchaRef?.current) {
        recaptchaRef.current.reset();
      }

      setLoading(false);
    } else {
      toast(t('Por favor verifique que você não é um robô.'), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (location?.state?.noRedirect) return false;

    if (
      credentials &&
      credentials.token.user_type === ROLE_PATIENT &&
      credentials.token.session_type !== 'limited'
    ) {
      return history.push(location?.state?.referrer || '/consultas');
    }

    if (credentials && credentials.token.user_type === ROLE_PROFESSIONAL) {
      return history.push(location?.state?.referrer || '/consultorio');
    }

    if (credentials && credentials.token.user_type === ROLE_SECRETARY) {
      return history.push(location?.state?.referrer || '/clinic');
    }

    return false;
  }, [credentials]);

  const [openModal, setOpenModal] = useState(false);
  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  async function handleForgotPassword(data) {
    try {
      const { result: _result } = await forgotPasswordService(data);
      toast(t('Enviamos instruções para o seu endereço de e-mail.'), {
        variant: 'success',
      });
      setOpenModal(!openModal);
    } catch (error) {
      toast(
        t('Não foi possível solicitar no momento. Por favor, tente novamente.'),
        {
          variant: 'error',
        }
      );
    }
  }

  const handleCaptchaVerification = (response) => {
    if (response) {
      setCaptchaResponse(response);
    }
  };

  return unavaiable ? null : (
    <Sidebar onCloseCallback={onCloseCallback}>
      <LogoSidebar />
      <SidebarBox heightArea="30%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            name="login"
            marginBottom
            type="login"
            placeholder={t('Login')}
            ref={register({ required: true })}
          />
          {errors && errors.login && t('Login é obrigatório')}
          <InputField
            name="password"
            marginBottom
            type="password"
            placeholder={t('Senha')}
            ref={register({ required: true })}
          />
          {errors && errors.password && t('Senha é obrigatório')}
          {captchaEnabled && (
            <CaptchaContainer>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
                hl="pt-BR"
                onChange={handleCaptchaVerification}
              />
            </CaptchaContainer>
          )}
          {signInError && (
            <ErrorMessage>{t('Login ou senha inválida.')}</ErrorMessage>
          )}
          <button type="submit">{loading ? <Loading /> : t('Entrar')}</button>
          <p>
            <Forgot onClick={handleOpenModal}>
              {t('Esqueceu sua senha? Clique aqui')}
            </Forgot>
          </p>
        </form>
        <Modal
          handleOpenModal={handleOpenModal}
          openModal={openModal}
          modalTitle="Esqueceu a senha?"
          modalWidth="80%"
        >
          <form onSubmit={handleForgetSubmit(handleForgotPassword)}>
            <InputField
              name="email"
              marginBottom
              type="email"
              placeholder="Digite seu e-mail..."
              ref={forgetRegister({ required: true })}
            />
            {forgetErrors &&
              forgetErrors.email &&
              t('Por favor digite o e-mail.')}
            <button type="submit">{t('Enviar')}</button>
          </form>
        </Modal>
      </SidebarBox>
      <SidebarBox heightArea="70%">
        <p>
          {t('Ainda não é usuário?')}
          <br />
          {t('Cadastre-se grátis')}:
        </p>
        <SignupContainer>
          <Image />
          <Link to="/cadastro">
            <SignupButton>
              <ChatLogo />
              <SignupButtonText>{t('Paciente')}</SignupButtonText>
            </SignupButton>
          </Link>
          <Link to="/cadastro-medico">
            <SignupButton style={{ marginTop: 5 }}>
              <RiStethoscopeLine size={22} color="var(--caren-white)" />
              <SignupButtonText>{t('Profissional de Saúde')}</SignupButtonText>
            </SignupButton>
          </Link>
        </SignupContainer>
      </SidebarBox>
      <Copyright>Caren &copy; {new Date().getFullYear()}</Copyright>
    </Sidebar>
  );
}
