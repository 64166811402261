import { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import moment from 'moment';
import 'react-credit-cards/es/styles-compiled.css';
import Button from '../../inputs/buttons/Button';
import TextInput from '../../inputs/input/text';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import MaskInput from '../../inputs/input/mask';
import validateCpf from '../../../../utils/validateCpf';
import { type DoctorCreate } from '../../../entities/doctor';
import { stringToCPF } from '../../../utils/formatter';
import useCheckoutForm from '../../../hooks/checkout/useCheckoutForm';
import { XMarkIcon } from '@heroicons/react/24/solid';

const PaymentFormSchema = z.object({
  cardName: z
    .string({ required_error: 'Digite o nome impresso no cartão' })
    .nonempty('Digite o nome impresso no cartão')
    .trim()
    .regex(/^[a-zA-Z ]+$/, 'Caracteres especiais não são permitidos')
    .max(64, { message: 'Máximo de 64 caracteres' }),
  cardNumber: z
    .string({ required_error: 'Digite um número de cartão' })
    .nonempty('Digite um número de cartão')
    .regex(/\d{4}\s\d{4}\s\d{4}\s\d{4}/, 'Digite um número de cartão válido'),
  cardExpiration: z
    .string({ required_error: 'Digite a data de validade' })
    .nonempty('Digite a data de validade')
    .regex(/\d{2}\/\d{4}/, 'Digite uma data válida')
    .refine(
      val => {
        return moment(val, 'MM/YYYY').endOf('month').isAfter(moment());
      },
      {
        message: 'Este cartão não é mais válido'
      }
    ),
  cardVerification: z
    .string({ required_error: 'Digite o código de verificação' })
    .nonempty('Digite o código de verificação')
    .min(3, 'Digite o código de verificação')
    .max(4, 'Digite o código de verificação')
    .regex(/^\d{3,4}$/, 'Digite um código válido'),
  cpf: z
    .string({ required_error: 'Digite um CPF' })
    .nonempty('Digite um CPF')
    .regex(/\d{3}\.\d{3}\.\d{3}-\d{2}/, 'Digite um CPF válido')
    .refine(validateCpf, {
      message: 'Verifique se o CPF foi digitado corretamente'
    })
});

export type PaymentFormData = z.infer<typeof PaymentFormSchema>;

type PaymentStepProps = {
  step: number;
  planComparison: {
    type: 'regular' | 'upgrade' | 'downgrade' | 'equivalent';
    current_subscription_key: string | null;
    discount_code: string | null;
    discount_code_exists: boolean | null;
    discount_code_applied: boolean | null;
  } | null;
  onCheckDiscountCode: (discountCode: string) => void;
};

export default function PaymentStep({
  step,
  planComparison,
  onCheckDiscountCode
}: PaymentStepProps) {
  const { actualStep, steps, forwardStep, backwardStep, setStepData } =
    useCheckoutForm();

  const [discountCode, setDiscountCode] = useState<string>('');

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<PaymentFormData>({
    resolver: zodResolver(PaymentFormSchema)
  });

  const [focus, setFocus] = useState<'name' | 'number' | 'expiry' | 'cvc'>();

  const personData = steps[1] as DoctorCreate & { isAuthenticated?: boolean };

  const cardNumber = watch('cardNumber');
  const cardName = watch('cardName');
  const cardExpiration = watch('cardExpiration');
  const cardVerification = watch('cardVerification');

  const isCardUpdate = () =>
    planComparison?.type === 'equivalent' &&
    planComparison?.current_subscription_key;

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    let focusedName: 'name' | 'number' | 'expiry' | 'cvc' | undefined;

    if (e.target.name === 'cardNumber') focusedName = 'number';
    if (e.target.name === 'cardName') focusedName = 'name';
    if (e.target.name === 'cardExpiration') focusedName = 'expiry';
    if (e.target.name === 'cardVerification') focusedName = 'cvc';

    setFocus(focusedName);
  };

  const saveData = (data: PaymentFormData) => {
    setStepData(step, data);
    forwardStep();
  };

  const onChangeDiscountCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(e.target.value);
  };

  useEffect(() => {
    setDiscountCode('');
  }, [planComparison?.discount_code]);

  useEffect(() => {
    if (personData?.fiscal_code) {
      setValue('cpf', stringToCPF(personData.fiscal_code));
    }
  }, [personData?.fiscal_code]);

  if (actualStep !== step) return null;

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="mt-7 flex h-full w-full max-w-sm flex-grow flex-col items-center md:mt-10"
    >
      <Cards
        cvc={cardVerification ?? ''}
        expiry={cardExpiration ?? ''}
        focused={focus}
        name={cardName ?? ''}
        number={cardNumber ?? ''}
        locale={{ valid: 'Válido até' }}
        placeholders={{ name: 'NOME SOBRENOME' }}
      />
      <div className="mb-5 mt-6 flex w-full flex-col gap-y-5">
        <div className="grid w-full grid-cols-1 gap-5 sm:grid-cols-2">
          <Controller
            control={control}
            name="cardNumber"
            render={({ field: { onChange, name, ref, value } }) => (
              <MaskInput
                mask="9999 9999 9999 9999"
                label="Número do Cartão:"
                error={errors.cardNumber?.message}
                onChange={e => {
                  onChange(e);
                }}
                defaultValue={value}
                ref={ref}
                onFocus={handleInputFocus}
                name={name}
              />
            )}
          />
          <Controller
            control={control}
            name="cardExpiration"
            render={({ field: { onChange, name, ref, value } }) => (
              <MaskInput
                mask="99/9999"
                label="Vencimento do Cartão:"
                error={errors.cardExpiration?.message}
                onChange={e => {
                  onChange(e);
                }}
                defaultValue={value}
                ref={ref}
                onFocus={handleInputFocus}
                name={name}
              />
            )}
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-5 sm:grid-cols-2">
          <TextInput
            label="CVC:"
            type="text"
            maxLength={4}
            {...register('cardVerification')}
            error={errors.cardVerification?.message}
            onFocus={handleInputFocus}
          />
          <Controller
            control={control}
            name="cpf"
            render={({ field: { onChange, ref, value } }) => (
              <MaskInput
                mask="999.999.999-99"
                label="CPF do titular:"
                error={errors.cpf?.message}
                onChange={e => {
                  onChange(e);
                }}
                defaultValue={value}
                ref={ref}
              />
            )}
          />
        </div>
        <TextInput
          label="Nome impresso no cartão:"
          type="text"
          {...register('cardName')}
          error={errors.cardName?.message}
          onFocus={handleInputFocus}
        />
      </div>
      {!isCardUpdate() && (
        <div className="mb-8 flex w-full flex-col">
          <div className="flex w-full flex-row gap-5">
            <div className="relative flex grow flex-col gap-y-1">
              <TextInput
                label="Cupom:"
                value={discountCode}
                onChange={onChangeDiscountCode}
                type="text"
              />
            </div>
            <div className="mt-5 p-1">
              <Button
                icon={
                  planComparison?.discount_code_exists ? XMarkIcon : undefined
                }
                label={
                  planComparison?.discount_code_exists ? 'Limpar' : 'Validar'
                }
                type="button"
                colors="yellow"
                size="small"
                onClick={() => {
                  onCheckDiscountCode(discountCode.toUpperCase());
                }}
              />
            </div>
          </div>
          {planComparison?.discount_code_applied === false && (
            <span className="mt-1 font-nunito text-xs text-error">
              {planComparison?.discount_code_exists
                ? 'Este cupom não pode ser utilizado com o plano selecionado.'
                : 'Cupom inválido.'}
            </span>
          )}
          {planComparison?.discount_code_applied === true && (
            <span className="mt-1 font-nunito text-xs text-success">
              Cupom {planComparison?.discount_code} aplicado!
            </span>
          )}
        </div>
      )}
      <Button
        label="Finalizar Compra"
        type="submit"
        colors="success"
        size="large"
      />
      <button
        className="mt-10 font-nunito text-sm font-semibold text-blue-650"
        type="button"
        onClick={() => {
          backwardStep();
        }}
      >
        Voltar
      </button>
    </form>
  );
}
