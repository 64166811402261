import styled from 'styled-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ConsultDialogContext } from '../context';
import { type Doctor } from '../../../entities/doctor';
import { getDoctors } from '../../../../services/secretary';
import ImageOrName from '../../../../components/ImageOrName';
import { type ActiveSubscription } from '../../../entities/active_subscription';

const Step = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  > div.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    @media (max-width: 636px) {
      grid-template-columns: 1fr;
    }

    & > div.title {
      grid-column: 1 / -1;
      text-align: center;
      font-size: 14px;
      color: var(--caren-green);
    }

    & > button.doctor {
      width: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      border-bottom: 1px solid var(--caren-input-border);
      border-radius: 4px;
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      background-color: #f5f5f5;

      &:hover {
        border-color: var(--caren-green);
        background-color: #ededed;
      }

      & > p {
        font-size: 16px;
      }
    }
  }
`;

type DoctorStepProps = {
  step: number;
}

type DoctorsWithSubscription = {
  employee: Doctor;
  active_subscription: ActiveSubscription;
}

export default function DoctorStep({ step }: DoctorStepProps) {
  const dialogContext = useContext(ConsultDialogContext);

  const [doctors, setDoctors] = useState<Doctor[] | null>(null);

  const conformDoctorAndSubscription = (
    response: DoctorsWithSubscription[]
  ) => {
    const completeDoctors: Doctor[] = [];

    response.forEach(doctor => {
      const newEmployee = {
        ...doctor.employee,
        active_subscription: doctor.active_subscription
      };
      completeDoctors.push(newEmployee);
    });

    return completeDoctors;
  };

  const getDoctorsFromSecretary = useCallback(async () => {
    if (doctors === null) {
      const newDoctors = await getDoctors();

      setDoctors(conformDoctorAndSubscription(newDoctors));
    }
  }, [doctors]);

  const onSelectDoctor = useCallback(
    (doctor: Doctor) => {
      if (dialogContext === null) return;

      dialogContext.setStepData(step, { header: doctor.name, value: doctor });
      dialogContext.forwardStep();
    },
    [dialogContext, step]
  );

  useEffect(() => {
    getDoctorsFromSecretary();
  }, [getDoctorsFromSecretary]);

  if (dialogContext === null || dialogContext.actualStep !== step) return null;

  return (
    <Step>
      <div className="content">
        <div className="title">
          <p>SELECIONE O MÉDICO PARA AGENDAR UMA CONSULTA</p>
        </div>
        {doctors?.map(doctor => (
          <button
            type="button"
            onClick={() => {
              onSelectDoctor(doctor);
            }}
            className="doctor"
            key={doctor.key}
          >
            <ImageOrName
              src={doctor.avatar_url}
              title={doctor.name}
              titleSize={16}
              backgroundStyle={{
                width: 60,
                height: 60,
                borderRadius: '100%',
                backgroundColor: 'var(--caren-image-fallback)'
              }}
            />
            <p>{doctor.name}</p>
          </button>
        ))}
      </div>
    </Step>
  );
}
