import styled from 'styled-components/macro';

import { Link } from 'react-router-dom';
import barChartIcon from '@mui/icons-material/BarChart';
import doctorImg from '../../../assets/images/healthcare-and-medical.svg';
import favoriteImg from '../../../assets/images/signs.svg';
import consultImg from '../../../assets/images/cross.svg';
import myDataImg from '../../../assets/images/social.svg';

export const SideBarLogoBox = styled.div`
  background-color: #ffffff85;
  border-radius: 50%;
  width: 161px;
  height: 161px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DisplayFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Border = styled.div`
  border: 1px solid #262c2e;
  opacity: 0.5;
  width: 100%;
`;

export const SideBarLogo = styled.div<{ hasImage: boolean, imgUrl: string }>`
  background-color: var(--caren-white);
  border-radius: 50%;
  width: 134.67px;
  height: 134.67px;
  background-image: ${({ hasImage, imgUrl }) =>
    hasImage ? `url(${imgUrl})` : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SideBarLogoLetters = styled.span`
  color: var(--caren-green);
  font-size: 60px;
  font-weight: bold;
  line-height: 78px;
  width: 100%;
  text-align: center;
`;

export const SideBarUserName = styled.span`
  color: #262c2e;
  font-size: 26px;
  font-weight: bold;
  line-height: 33px;
  margin: 30px 0 50px 0;
`;

export const Menu = styled.div`
  width: 100%;

  div:not(:first-child) {
    margin-top: 10px;
  }
`;

export const List = styled(Link).attrs(({ to }) => ({ to }))`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 15px;
  padding: 8px;
  color: #262c2e;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #262c2e;
    opacity: 0;
    transition: opacity 0.2s;
  }

  img {
    width: 25px;
    margin-right: 15px;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 0.1;
    }

    span {
      transform: scale(1.1);
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    color: #262c2e;
  }
`;

export const IconText = styled.span`
  cursor: pointer;
  color: '#262C2E';
  transition: 0.3s;
`;

const Icon = styled.img`
  z-index: 1;
`;

export const IconDoctor = styled(Icon).attrs({
  src: doctorImg
})``;

export const IconStatistics = styled(barChartIcon).attrs({})`
  width: 30px;
  margin-right: 16px;

  path {
    fill: white !important;
  }
`;

export const IconFavorite = styled(Icon).attrs({
  src: favoriteImg
})``;

export const IconConsult = styled(Icon).attrs({
  src: consultImg
})``;

export const IconMyData = styled(Icon).attrs({
  src: myDataImg
})``;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  p {
    cursor: pointer;
  }
`;
