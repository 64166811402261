import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import { useToast } from '../../../../contexts/ToastContext';
import { resendEmailConfirmation } from '../../../../v2/services/user';
import { useProfileCompleteness } from '../../../../v2/hooks/user/useProfileCompleteness';
import { useState } from 'react';

export default function AccountCompleteChecklist() {
  const toast = useToast();
  const [resentEmail, setResentEmail] = useState(false);

  const { data, isLoading } = useProfileCompleteness();

  const handleResendEmailConfirmation = async () => {
    setResentEmail(true);
    const result = await resendEmailConfirmation();

    if (result) {
      toast(
        'Email de confirmação enviado com sucesso. Verifique sua caixa de entrada!',
        {
          variant: 'success'
        }
      );
    } else {
      setResentEmail(false);
      toast('Não foi possível enviar o email de confirmação.', {
        variant: 'error'
      });
    }
  };

  return (
    <div className="flex w-fit flex-col items-start gap-y-2 rounded border border-yellow-180 bg-yellow-150/50 p-4">
      <p className="font-semibold text-gray-950">
        Para realizar os seus atendimentos você precisa completar o seu perfil
      </p>
      {isLoading && (
        <ul className="flex flex-col items-start">
          <li className="text-sm text-gray-400">
            <SyncIcon fontSize="small" className="mb-1 animate-spin" />
            &nbsp;Verificando informações...
          </li>
        </ul>
      )}
      {data && !isLoading && (
        <ul className="flex flex-col items-start">
          <li className="text-sm">
            {data.criteria.phone &&
            data.criteria.fiscal_code &&
            data.criteria.professional_code &&
            data.criteria.specialty &&
            data.criteria.office ? (
              <CheckIcon className="text-success" fontSize="small" />
            ) : (
              <CloseIcon className="text-error" fontSize="small" />
            )}
            &nbsp;Preencha seu perfil
          </li>
          <li className="text-sm">
            {data.criteria.confirmed_email ? (
              <CheckIcon className="text-success" fontSize="small" />
            ) : (
              <CloseIcon className="text-error" fontSize="small" />
            )}
            &nbsp;Verifique seu e-mail&nbsp;
            {!data.criteria.confirmed_email && !resentEmail && (
              <button
                onClick={handleResendEmailConfirmation}
                type="button"
                className="text-gray-400 underline"
              >
                (reenviar)
              </button>
            )}
          </li>
          <li className="text-sm">
            {data.criteria.profile_picture ? (
              <CheckIcon className="text-success" fontSize="small" />
            ) : (
              <CloseIcon className="text-error" fontSize="small" />
            )}
            &nbsp;Carregue uma foto de perfil
          </li>
        </ul>
      )}
    </div>
  );
}
