import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../../hooks/AuthContext';
import { ROLE_PATIENT } from '../../../routes/constants';

import { Container } from '../ConsultWaitRoomHeader/styles';
import { BackButton } from '../../../components/BackButton';

function EmergencyWaitingQueueHeader() {
  const {
    credentials,
  } = useAuth();

  if (credentials?.token?.user_type === ROLE_PATIENT) {
    return (
      <Container style={{ flex: 0.2 }}>
        <div>
          <BackButton path="/consultas" />
        </div>
      </Container>
    );
  }

  return <Redirect to="/" />;
}

export default EmergencyWaitingQueueHeader;
