import styled from 'styled-components/macro';
import { Doughnut } from 'react-chartjs-2';
import { IoIosVideocam } from 'react-icons/io';
import {
  FaFileAlt,
  FaArrowAltCircleRight,
  FaCheckCircle
} from 'react-icons/fa';

import UploadLogoDynamic from '../../components/UploadLogoDynamic';
import nurseLogo from '../../assets/images/nurse.png';
import ImageOrName from '../../components/ImageOrName';

export const Container = styled.div`
  padding: 20px 40px 40px;
  flex: 1;

  @media (max-width: 768px) {
    padding: 20px 20px 40px;
  }
`;

export const Wrapper = styled.div`
  background-color: var(--caren-white);
  width: 660px;
  padding: 30px 15px;
  border-radius: 15px;
  margin-top: 30px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const WrapperTitle = styled.div`
  color: var(--caren-grey);
  font-size: 21px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const WrapperInfo = styled.div`
  text-align: center;

  span {
    color: var(--caren-grey);
    font-size: 16px;
    font-weight: 500;
    display: inline-block;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const WrapperTime = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WrapperTimeBlock = styled.div`
  position: relative;

  > div,
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const WrapperTimeLogo = styled(ImageOrName).attrs(({ src }) => ({
  src
}))``;

export const WrapperTimeName = styled.p`
  color: var(--caren-icongrey);
  font-size: 20px;
  text-align: center;
  margin-top: 10px;

  @media (min-width: 769px) {
    white-space: nowrap;
    display: inline-block;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      white-space: nowrap;
      display: inline-block;
      max-width: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media (max-width: 768px) {
    position: relative;
    font-size: 18px;
    margin-top: 20px;
    width: 100%;
    display: block;
    line-height: 1.2;
  }

  span {
    display: block;
  }

  a {
    color: var(--caren-icongrey);
    display: inline-block;
  }
`;

export const WrapperClock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > div {
    width: 195px;
  }
`;

export const WrapperClockTitle = styled.p`
  color: var(--caren-icongrey);
  font-size: 16px;
  text-align: center;
  margin-top: 10px;

  > span {
    color: var(--caren-orange);
    font-weight: 600;
    font-style: normal;
  }

  > span.patient {
    max-width: 28rem;
    width: 100%;
    display: flex;
  }

  > p {
    font-style: normal;
    color: var(--caren-icongrey);
  }

  > div.color-bluegreen {
    color: var(--caren-green);

    .alert {
      color: #ff9052;
    }
  }
`;

export const WrapperSteps = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .cwr-step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    @media (max-width: 768px) {
      flex-direction: unset;
    }

    &:not(:last-child) {
      margin-right: 50px;

      @media (max-width: 768px) {
        margin: 0 0 50px;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 17px;
      left: 85px;
      width: 90px;
      height: 2px;

      @media (max-width: 768px) {
        transform-origin: 0%;
        top: 45px;
        left: 17px;
        width: 2px;
        height: 34px;
      }
    }

    @keyframes cwr_count_step {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      30% {
        opacity: 1;
      }
      60% {
        opacity: 0;
      }
      75%,
      100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
      }
    }

    @keyframes cwr_active_step {
      0% {
        transform-origin: 0% 50%;
        transform: scaleX(0);
      }
      50% {
        transform-origin: 0% 50%;
        transform: scaleX(1);
      }
      50.1% {
        transform-origin: 100% 50%;
        transform: scaleX(1);
      }
      100% {
        transform-origin: 100% 50%;
        transform: scaleX(0);
      }
    }

    @keyframes cwr_active_step_mobile {
      0% {
        transform-origin: 50% 0%;
        transform: scaleY(0);
      }
      50% {
        transform-origin: 50% 0%;
        transform: scaleY(1);
      }
      50.1% {
        transform-origin: 50% 100%;
        transform: scaleY(1);
      }
      100% {
        transform-origin: 50% 100%;
        transform: scaleY(0);
      }
    }

    &::before {
      background-color: rgba(0, 0, 0, 0.1);
      transition: background-color 0.5s ease;
    }

    &::after {
      background-color: var(--caren-green);
      transform-origin: 0% 50%;
      transform: scaleX(0);
    }

    &:last-child {
      &::before,
      &::after {
        display: none;
      }
    }

    &-count {
      position: relative;

      &::before,
      &::after {
        content: '';
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        z-index: 1;
      }

      span {
        background-color: #b0b0b0;
        color: white;
        font-size: 17px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 1px 0 0;
        transition: background-color 0.5s ease;
        position: relative;
        z-index: 2;
      }
    }

    &-tooltip {
      background-color: var(--caren-lightgrey);
      color: white;
      font-size: 12px;
      font-weight: bold;
      padding: 8px 10px;
      border-radius: 8px;
      text-align: left;
      white-space: nowrap;
      position: absolute;
      top: 110%;
      left: auto;
      right: auto;
      // transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 10;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &-title {
      color: #b0b0b0;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
      white-space: pre-wrap;
      width: 110px;
      height: 35px;
      transition: color 0.5s ease;
      margin-top: 10px;

      span {
        color: var(--caren-grey);
        font-size: 11px;
        font-weight: bold;
        display: none;
        margin-top: 4px;

        @media (max-width: 768px) {
          display: block;
        }
      }

      @media (max-width: 768px) {
        margin: 0 0 0 20px;
        text-align: left;
        width: 100%;
        white-space: nowrap;

        span {
          white-space: pre-wrap;
        }

        br {
          display: none;
        }
      }
    }

    &-action {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: initial;
      z-index: 5;

      button,
      a {
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;

        :disabled {
          cursor: not-allowed;
        }
      }
    }

    &.active,
    &.full {
      &::after {
        animation-name: cwr_active_step;
        animation-duration: 2s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;

        @media (max-width: 768px) {
          animation-name: cwr_active_step_mobile;
        }
      }

      .cwr-step-count {
        span {
          background-color: #03a691;
        }
      }

      .cwr-step-title {
        color: #03a691;
      }
    }

    &.initial {
      .cwr-step-title {
        font-size: 1rem;
        color: var(--caren-darkgreen);
      }

      .cwr-step-count {
        &::before,
        &::after {
          animation-name: cwr_count_step;
          animation-duration: 2s;
          animation-timing-function: ease;
          animation-iteration-count: infinite;
        }

        &::after {
          animation-delay: 0.5s;
        }

        span {
          background-color: var(--caren-darkgreen);
        }
      }
    }

    &.active {
      .cwr-step-count {
        &::before,
        &::after {
          animation-name: cwr_count_step;
          animation-duration: 2s;
          animation-timing-function: ease;
          animation-iteration-count: infinite;
        }

        &::after {
          animation-delay: 0.5s;
        }
      }
    }

    &.full {
      &::before {
        background-color: #03a691;
      }

      &::after {
        animation-play-state: paused;
      }

      .cwr-step-count {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    &:hover {
      @media (min-width: 769px) {
        .cwr-step-count {
          span {
            background-color: var(--caren-darkgreen);
          }
        }

        .cwr-step-tooltip {
          opacity: 1;
        }

        .cwr-step-title {
          color: var(--caren-darkgreen);
        }
      }
    }
  }
`;

export const FindADoctorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const FindADoctor = styled.div`
  background-color: #f8f8f8;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  p {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  span {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  div {
    display: flex;
  }
`;

export const MainText = styled.p`
  color: var(--caren-grey);
  font-size: 27px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const Text = styled.p`
  color: var(--caren-lightgrey);
  font-size: 18px;
  font-weight: bold;
`;

export const ProcessContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 70px 200px 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const ProcessFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;

    > div {
      width: 100%;
      max-width: 330px;
    }
  }
`;

export const UserProcessContainer = styled.div`
  position: relative;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const UserProcessBackground = styled.div`
  background-color: #f8f8f8;
  border-radius: 50%;
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 110px;
    height: 110px;
  }
`;

export const UserProcessImg = styled.img.attrs({
  src: 'https://source.unsplash.com/300x300/?doctor',
  opacity: 1
})`
  background-color: var(--caren-white);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  opacity: ${({ opacity }) => opacity || 1};

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

export const ProcessLine = styled.div`
  min-width: 5px;
  min-height: 5px;
  background-color: ${({ color }) => color ?? '#888'};
  border-radius: 100%;

  @media (max-width: 1220px) {
    display: none;

    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 1110px) {
    margin: 0 3px;
    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin: 3px 0;
    &:nth-child(3n + 0) {
      display: none;
    }
  }
`;

export const UploadIcon = styled(UploadLogoDynamic).attrs(({ color }) => ({
  size: 50,
  color: color ? 'var(--caren-green)' : 'var(--caren-lightgrey)'
}))``;

export const Border = styled.div`
  border-bottom: 1px solid var(--caren-lightgrey);
  margin: 20px 200px;

  @media (max-width: 768px) {
    margin: 50px 0;
  }
`;

export const BorderBox = styled.div`
  margin-top: 10px;
  border: 1px solid var(--caren-lightgrey);
`;

export const DoctorTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 15px;
  width: 95%;
`;

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 50px 0;
  }
`;

export const DoctorBlock = styled.div`
  position: relative;

  > div,
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const DoctorGraph = styled(Doughnut)``;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DoctorTimeName = styled.p`
  position: absolute;
  bottom: -36px;
  left: auto;
  right: auto;
  color: var(--caren-icongrey);
  font-size: 22px;
  text-align: center;

  @media (min-width: 769px) {
    white-space: nowrap;
    display: inline-block;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      white-space: nowrap;
      display: inline-block;
      max-width: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media (max-width: 768px) {
    position: relative;
    margin-top: 20px;
    bottom: unset;
    width: 100%;
    display: block;
    line-height: 1.2;
  }

  a {
    color: var(--caren-icongrey);
  }
`;

export const OnlineStatus = styled.p<{ online: boolean }>`
  color: ${({ online }) =>
    online ? 'var(--caren-darkgreen)' : 'var(--caren-icongrey)'};
  font-weight: ${({ online }) => (online ? 'bold' : 'normal')};
  font-style: ${({ online }) => (online ? 'normal' : 'italic')};
  font-size: 0.66em;
`;

export const DoctorTimeTitle = styled.p`
  color: var(--caren-icongrey);
  font-size: 18px;
  text-align: center;
`;

export const DoctorClock = styled.span`
  color: #969faa;
  font-size: 28px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  span {
    font-size: 48px;
  }

  svg {
    height: 50px;
  }
`;

export const DoctorCamIcon = styled(IoIosVideocam).attrs({
  color: 'var(--caren-clocklightgrey)',
  size: 100
})``;

export const DoctorTimeBox = styled.div`
  canvas {
    margin: 30px 0;

    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const NurseLogo = styled.img.attrs({
  src: nurseLogo,
  opacity: 1
})`
  width: 100px;
  height: 100px;
  opacity: ${({ opacity }) => opacity || '0.3'};
`;

export const DoctorLogo = styled(ImageOrName).attrs(({ src }) => ({
  src
}))``;

export const DocIcon = styled(FaFileAlt).attrs(({ color }) => ({
  size: 30,
  color: color ?? 'rgba(0,0,0, 0.3)'
}))`
  position: absolute;
  bottom: -5px;
  right: -3px;
`;

export const DocIconCheck = styled(FaCheckCircle).attrs(({ color }) => ({
  size: 30,
  color: color ?? '#00c597'
}))`
  position: absolute;
  bottom: -5px;
  right: -3px;
`;

export const QuestionAnswer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #969faa;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 10px;
  svg {
    margin-left: 15px;
  }
`;

export const WaitRoomContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;

  @media (max-width: 1280px) {
    column-gap: 20px;
  }

  @media (max-width: 1024px) {
    padding-top: 40px;
    flex-direction: column;
  }
`;

export const ReadyInfo = styled.div<{ noFlex?: boolean }>`
  display: ${props => (props.noFlex ? 'block' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  p {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 12px;
    margin-right: 10px;
    text-align: center;
    white-space: pre-wrap;

    &.filled {
      background-color: #e4e2e3;
      color: #969aa3;
      font-weight: bold;
      padding: 8px 20px;
      margin-right: 0;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;

      svg {
        flex-shrink: 0;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
`;

export const GetInRoom = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;

  p {
    margin-top: -10px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: var(--caren-green);
  }
`;

export const GetInIcon = styled(FaArrowAltCircleRight).attrs({
  size: 28,
  color: '#00c597'
})`
  position: absolute;
  top: -40px;
  right: 19px;
`;

export const ContinueConsulta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;

  p {
    font-size: 16px;
    text-align: center;
    color: var(--caren-grey);
  }

  button {
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    height: 40px;
    padding: 0 20px;
    border-radius: 10px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    @media (max-width: 636px) {
      min-width: unset;
      width: 100%;
    }

    &:disabled {
      filter: grayscale(1);
      cursor: not-allowed;
    }

    background-color: #3995a9;
    &:hover {
      background-color: #077a94;
    }
  }
`;
