import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ROOM_COMPLETED_CODE } from '../../../constants/video';

function ErrorDialog({ dismissError, error }) {
  const { message, code, title } = error || {};

  return (
    <Dialog
      open={error !== null && code !== ROOM_COMPLETED_CODE}
      onClose={() => dismissError()}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{title || 'ERRO'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {Boolean(code) && (
          <pre>
            <code>Código: {code}</code>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissError()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
