import styled from 'styled-components/macro';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubHeaderContainer = styled.div`
  width: 100%;
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    max-width: 95vw;
  }
`;

export const TitlePage = styled.h2`
  font-weight: bold;
  margin: 20px 0;
  color: var(--caren-icongrey);
`;

export const MainContent = styled.div`
  width: 100%;
  max-width: 70vw;

  @media (max-width: 768px) {
    max-width: 95vw;
  }
`;

export const PatientInfoContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 150px;
  padding: 32px;
  margin-top: 15px;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 1140px) {
    flex-direction: column;
    padding: 25px;
    height: auto;
    border-radius: 10px;
  }
`;

export const ConsultationContainer = styled.div`
  margin-top: 20px;
`;

export const ActionsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 80px;
`;

export const HistoryContainer = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  height: calc(100% - 30px);
  border-radius: 0 10px 10px 10px;
  padding: 40px 35px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const BackButtonContainer = styled.div`
  margin-right: 40px;
`;
