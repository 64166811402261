import styled from 'styled-components/macro';
import { MdFileUpload } from 'react-icons/md';
import ImageOrName from '../../components/ImageOrName';
import itauLogo from '../../assets/images/itau_logo.png';
import UploadLogoDynamic from '../../components/UploadLogoDynamic';
import userPadlockIcon from '../../assets/images/user_padlock.svg';
import nurseLogo from '../../assets/images/nurse-colored-old.png';

export const Container = styled.div`
  background-color: var(--caren-white);
  padding: 40px;
`;

export const FindADoctorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 400px) {
    display: block;
  }
`;

export const BackButtonContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 0 8px;
`;

export const FindADoctor = styled.div`
  background-color: #f8f8f8;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  p {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  span {
    padding: 20px;
    font-size: 18px;
    color: var(--caren-grey);
    font-weight: bold;
  }

  div {
    display: flex;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export const MainText = styled.p`
  color: var(--caren-grey);
  font-size: 27px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const Text = styled.p`
  color: var(--caren-lightgrey);
  font-size: 18px;
  font-weight: bold;
`;

export const ProcessContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const ProcessFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

export const UserProcessContainer = styled.div``;

export const UserProcessBackground = styled.div`
  background-color: #f8f8f8;
  border-radius: 50%;
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 110px;
    height: 110px;
  }
`;

export const UserProcessImg = styled(ImageOrName).attrs(({ imgUrl }) => ({
  src: imgUrl
}))`
  background-color: var(--caren-white);
  border-radius: 50%;
  width: 160px;
  height: 160px;
  opacity: ${({ opacity }) => opacity ?? 1};

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const ProcessLine = styled.div`
  min-width: 5px;
  min-height: 5px;
  background-color: ${({ color }) => color ?? '#888'};
  border-radius: 100%;

  min-width: 5px;
  min-height: 5px;
  background-color: ${({ color }) => color ?? '#888'};
  border-radius: 100%;

  @media (max-width: 1220px) {
    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 1110px) {
    margin: 0 3px;
    &:nth-child(2n + 0) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin: 3px 0;
    &:nth-child(3n + 0) {
      display: none;
    }
  }
`;

export const UploadIcon = styled(UploadLogoDynamic).attrs(({ color }) => ({
  size: 50,
  color: color ? 'var(--caren-green)' : 'var(--caren-lightgrey)'
}))``;

export const Border = styled.div`
  border-bottom: 1px solid var(--caren-lightgrey);
  margin: 50px 200px;

  @media (max-width: 768px) {
    margin: 50px 0;
  }
`;

export const BorderBox = styled.div`
  margin-top: 10px;
  border: 1px solid var(--caren-lightgrey);
`;

export const ProcessTime = styled.div`
  div:first-child {
    padding: 5px 10px;
  }

  .time-title {
    background-color: var(--caren-warning);

    p {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      svg {
        margin-left: 10px;
      }
    }
  }

  .time-success {
    background-color: var(--caren-green);

    p {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      svg {
        margin-left: 10px;
      }
    }
  }

  .time {
    font-size: 50px;
    color: var(--caren-grey);
    padding: 20px 0;
    text-align: center;
  }
`;

export const DepositData = styled.div``;

export const BankDetails = styled.div`
  background-color: #f8f8f8;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const BankLogo = styled.img.attrs({
  src: itauLogo
})`
  height: 40px;
`;

export const BankInfo = styled.div`
  margin-left: 15px;
  color: var(--caren-grey);
`;

export const SendReceipt = styled.div`
  border: 2px solid var(--caren-grey);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 2px;
  border-radius: 15px;
  margin: 0 10px;
  margin-top: 10px;
  color: var(--caren-lightgrey);
`;

export const ReceiptLogo = styled(MdFileUpload).attrs({
  size: 30
})``;

export const FooterText = styled.p`
  width: 100%;
  text-align: center;
  color: var(--caren-grey);
`;

export const HealthInsuranceCardCContainer = styled.div`
  display: flex;
  justify-content: center;
  div {
    @media (max-width: 400px) {
      width: 100%;
    }
  }
`;

export const HealthInsuranceCard = styled.div`
  margin-top: 10px;
  width: 399px;
  height: 268.13px;
  perspective: 1000px;

  & > div {
    ${({ flip }) => flip && 'transform: rotateY(180deg)'}
  }
`;

export const HealthInsuranceCardContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

export const HealthInsuranceCardFront = styled.form`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--caren-white);
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
  padding: 20px;
  backface-visibility: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 130px;
  }

  input {
    border: 1px solid var(--caren-lightgrey);
    height: 35px;
    border-radius: 5px;
    width: 100%;
    padding-left: 15px;
  }

  div {
    text-align: right;
  }
`;

export const HealthInsuranceCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--caren-white);
  border: 1px solid var(--caren-lightgrey);
  border-radius: 10px;
  padding: 20px;
  backface-visibility: hidden;
  transform: rotateY(180deg);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 130px;
  }
`;

export const HealthInsuranceCardButton = styled.button`
  background-color: var(--caren-action-button);
  border: 1px solid rgba(199, 199, 199, 0);
  border-radius: 5px;
  width: 95px;
  color: var(--caren-icongrey);
  font-size: bold;
  padding: 7px 40px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const MainIconContainer = styled.div`
  background-color: #f3f3f3;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainIcon = styled.img.attrs(props => ({
  src: props.src
}))`
  height: 90px;
  filter: ${({ success }) => (success ? 'none' : 'grayscale(100%)')};
  animation: ${({ animated }) => animated && 'wait 1.5s infinite 0s ease-out;'}
    @keyframes wait {
    70% {
      transform: rotate(0deg) scale(0.95);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;

export const UserPadlockIcon = styled.img.attrs({
  src: userPadlockIcon
})`
  width: 50%;
  max-width: 100px;
  margin: 10px auto;
  display: block;
`;

export const NurseLogo = styled.img.attrs({
  src: nurseLogo
})`
  width: 80%;
  height: 80%;
`;

export const HealthInsuranceInvitationCard = styled.form`
  margin-top: 10px;
  width: 399px;
  height: auto;
  min-height: 273.13px;
  padding: 0 0 10px;
  perspective: 1000px;
  box-shadow: 0px 4px 10px 2px rgb(0 0 0 / 49%);
  background: #f9f9f9;

  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  @media (max-width: 389px) {
    height: 310.13px;
  }
  @media (max-width: 351px) {
    height: 370.13px;
  }
`;

export const HealthInsuranceInvitationAcceptButton = styled.button`
  background-color: var(--caren-action-button);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(199, 199, 199, 0);
  border-radius: 5px;
  width: 70%;
  height: 38px;
  color: var(--caren-icongrey);
  font-weight: bold;
  font-size: 1rem;
  margin: 25px 0 0 0;
  padding: 10px 0;
  text-transform: uppercase;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
`;

export const HealthInsuranceInvitationDeclineButton = styled.button`
  width: 70%;
  height: 20%;
  color: var(--caren-icongrey);
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10px;
  background: none;
`;

export const ConfirmationTop = styled.div`
  width: 100%;
  background-color: var(--caren-subheader);
  border-radius: 10px 10px 0px 0px;
  padding: 10px 0;

  h1 {
    text-align: center;
    color: var(--caren-icongrey);
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

export const ConfirmationContainer = styled.div`
  padding: 10px 30px;
  text-align: -webkit-center;
  background: #f9f9f9;
  height: 100%;
  width: 100%;
  border-radius: 0px 0 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 20px;
    font-size: 1.25rem;
    font-weight: bold;
  }

  h1,
  h3 {
    margin-bottom: 0;
    color: #666;
  }
`;
