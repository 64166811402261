import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import {
  Container,
  Wrapper,
  WrapperTitle,
  WrapperInfo,
  WrapperTime,
  WrapperTimeBlock,
  WrapperTimeLogo,
  WrapperTimeName,
  WrapperClock,
  WrapperClockTitle,
  ReadyInfo,
  WaitRoomContainer,
  ContinueConsulta
} from './consult-wait-room.styles';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import { scheduleDetailsService } from '../../services/patient-service';
import DoctorClock from '../../components/PatientPanel/DoctorClock';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_PATIENT, ROLE_PROFESSIONAL } from '../../routes/constants';
import getDoctorTitle from '../../utils/getDoctorTitle';
import LiveWaitRoomStatus from './LiveWaitRoomStatus';
import CountdownClock from './CountdownClock';
import formattedDate from '../../utils/formattedDate';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';
import { FORMAT } from '../../utils/moment/momentFormat';
import VideoAppStateProvider from '../../hooks/video/useVideoAppState';
import { VideoApp } from '../../components/Twilio';
import { roomAuthorization } from '../../services/video.service';
import { type TeleconsultationComplete } from '../../v2/entities/consult';
import DeviceSelectionScreen from '../../components/Twilio/DeviceSelectionScreen';
import Chatwoot from '../../components/Chatwoot';
import Tawk from '../../components/Tawk';
import WaitRoomSteps from '../../v2/components/wait-room/steps';
import { BackButton } from '../../components/BackButton';
import FreePlanVerify from '../../v2/components/wait-room/free-plan';
import hashids from '../../utils/hashids';

type ConsultWaitRoomProps = {
  computedMatch: { params: { consult_id: string } };
};

export default function ConsultWaitRoom({
  computedMatch: {
    params: { consult_id: consultId }
  }
}: ConsultWaitRoomProps) {
  const { credentials } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(['consult_wait_room']);

  const toast = useToast();

  const isDoctor = credentials?.token?.user_type === ROLE_PROFESSIONAL;

  const [scheduleDetails, setScheduleDetails] =
    useState<TeleconsultationComplete | null>(null);
  const [clockCompleted, setClockCompleted] = useState(false);
  const [showInitButton, setShowInitButton] = useState(false);
  const [channelConnected, setChannelConnected] = useState(false);
  const [otherPartyOnline, setOtherPartyOnline] = useState(false);
  const [patientIsReady, setPatientIsReady] = useState(false);
  const [requestedEarlyStart, setRequestedEarlyStart] = useState(false);
  const [callPatient, setCallPatient] = useState(false);
  const [isReady, setIsReady] = useState<boolean | null>(null);
  const [isDisableReady, setDisableReady] = useState<boolean>(true);

  const [stepProfessional, setStepProfessional] = useState(0);
  const [stepPatient, setStepPatient] = useState(0);

  const connectionCheckTimer = useRef<NodeJS.Timer>();

  function handleEarlyStart() {
    setShowInitButton(true);

    history.push(`/video-call/${consultId}`);
  }

  function handleCancelEarlyStart() {
    setShowInitButton(false);
  }

  function handleReload() {
    setTimeout(() => {
      window.location.reload();
    }, 50);
  }

  async function requestEarlyStart() {
    setRequestedEarlyStart(true);

    try {
      await roomAuthorization(consultId);

      setCallPatient(true);
    } catch (error) {
      toast(
        t(
          'Não foi possível iniciar a consulta. Por favor tente atualizar a página.'
        ),
        {
          variant: 'error'
        }
      );

      setRequestedEarlyStart(false);
    }
  }

  const getDataFromService = useCallback(async () => {
    if (scheduleDetails === null) {
      try {
        const { data: scheduleData } = await scheduleDetailsService(consultId);
        setScheduleDetails(scheduleData);
        setShowInitButton(!!scheduleData.room_opened_at);
        setClockCompleted(scheduleData.open);
      } catch (error) {
        toast(t('Ops, não foi possível carregar a consulta...'), {
          variant: 'error'
        });

        if (credentials?.token?.user_type === ROLE_PATIENT) {
          history.push('/consultas');
        } else {
          history.push('/consultorio');
        }
      }
    }
  }, [consultId, credentials, scheduleDetails]);

  const consultInfo = useMemo(() => {
    if (!credentials || !scheduleDetails) return null;

    if (credentials?.token?.user_type === ROLE_PATIENT) {
      return {
        counterpart: scheduleDetails.doctor,
        prefix: getDoctorTitle(scheduleDetails.doctor)
      };
    }

    if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
      return {
        counterpart: scheduleDetails.patient,
        prefix: ''
      };
    }
  }, [
    showInitButton,
    scheduleDetails,
    credentials,
    otherPartyOnline,
    requestedEarlyStart
  ]);

  useEffect(() => {
    if (channelConnected) {
      if (connectionCheckTimer.current) {
        clearInterval(connectionCheckTimer.current);
      }
    } else {
      connectionCheckTimer.current = setInterval(() => {
        if (!showInitButton) {
          toast(
            t(
              'Não estamos conseguindo conectar com a sala de espera.\nPor favor verifique sua conexão e tente novamente.'
            ),
            {
              variant: 'error'
            }
          );
        }
      }, 10000);
    }

    return () => {
      if (connectionCheckTimer.current) {
        clearInterval(connectionCheckTimer.current);
      }
    };
  }, [channelConnected, showInitButton]);

  useEffect(() => {
    if (credentials?.token?.user_type === ROLE_PATIENT) {
      setStepPatient(1);

      if (scheduleDetails?.flow_concluded) {
        setStepPatient(2);
      }
    }

    if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
      setStepProfessional(0);

      if (scheduleDetails?.flow_concluded) {
        setStepProfessional(2);
      }
    }
  }, [scheduleDetails, credentials]);

  useEffect(() => {
    getDataFromService();
  }, [getDataFromService]);

  useEffect(() => {
    if (scheduleDetails?.room_closed_at != null) {
      if (credentials?.token?.user_type === ROLE_PATIENT) {
        toast(t('Ops, a consulta foi finalizada.'), {
          variant: 'error'
        });

        if (credentials?.token?.session_type === 'limited') {
          history.push('/');
        } else {
          history.push('/consultas');
        }
      } else if (scheduleDetails?.room_opened_at == null) {
        toast(t('Esta consulta não foi realizada.'), {
          variant: 'error'
        });

        history.push('/agenda');
      } else {
        history.push(`/pos-atendimento/${scheduleDetails.key}`);
      }
    }
  }, [scheduleDetails]);

  useEffect(() => {
    if (credentials?.token?.user_type === ROLE_PROFESSIONAL) {
      if (!channelConnected && stepProfessional >= 3) {
        setStepProfessional(2);
      }

      if (
        channelConnected &&
        otherPartyOnline &&
        stepProfessional === 2 &&
        !patientIsReady
      ) {
        setStepProfessional(3);
      }

      if (channelConnected && !otherPartyOnline && stepProfessional >= 3) {
        setStepProfessional(2);
      }

      if (
        channelConnected &&
        otherPartyOnline &&
        patientIsReady &&
        stepProfessional === 3
      ) {
        setStepProfessional(4);
      }

      if (
        channelConnected &&
        otherPartyOnline &&
        !patientIsReady &&
        stepProfessional === 4
      ) {
        setStepProfessional(3);
      }
    }
  }, [
    channelConnected,
    credentials?.token?.user_type,
    otherPartyOnline,
    patientIsReady,
    stepProfessional
  ]);

  return (
    <>
      <Helmet title="Sala de Espera - Caren"></Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <Container>
        {credentials?.token && credentials.token.session_type !== 'limited' && (
          <BackButton />
        )}
        <WaitRoomContainer>
          {(credentials?.token?.user_type === ROLE_PROFESSIONAL ||
            (credentials?.token?.user_type === ROLE_PATIENT &&
              stepPatient >= 2)) && (
            <VideoAppStateProvider>
              <VideoApp>
                <DeviceSelectionScreen
                  name={credentials?.session?.name}
                  haveAudioPermission={(status: boolean) => {
                    if (
                      !isReady &&
                      credentials.token.user_type === ROLE_PROFESSIONAL &&
                      status
                    ) {
                      setIsReady(true);
                    }

                    setDisableReady(!status);
                  }}
                />
              </VideoApp>
            </VideoAppStateProvider>
          )}

          <Wrapper>
            <WrapperTitle>Sala de Espera Virtual</WrapperTitle>

            {scheduleDetails && (
              <WrapperInfo>
                <span>{formattedDate(scheduleDetails.scheduled_datetime)}</span>

                <span>-</span>

                <span>
                  {momentUtcLocal(scheduleDetails.scheduled_datetime).format(
                    FORMAT['hour-and-minute']
                  )}
                </span>
              </WrapperInfo>
            )}

            <WrapperTime>
              <WrapperTimeBlock>
                <CountdownClock consultation={scheduleDetails} />

                {consultInfo?.counterpart && (
                  <WrapperTimeLogo
                    src={consultInfo.counterpart.avatar_url}
                    title={consultInfo.counterpart.name}
                    titleSize={52}
                    backgroundStyle={{
                      width: 141,
                      height: 141,
                      borderRadius: 140,
                      backgroundColor: 'var(--caren-image-fallback)',
                      position: 'absolute'
                    }}
                    imageStyle={{ position: 'absolute' }}
                  />
                )}
              </WrapperTimeBlock>

              <WrapperTimeName>
                {credentials?.token?.user_type === ROLE_PROFESSIONAL ? (
                  <Link
                    to={{
                      pathname: `/pacientes/${scheduleDetails?.patient?.key}`,
                      state: {
                        referrer: location.pathname
                      }
                    }}
                  >
                    {consultInfo?.prefix} {consultInfo?.counterpart?.name}
                  </Link>
                ) : (
                  <span>
                    {consultInfo?.prefix} {consultInfo?.counterpart?.name}
                  </span>
                )}
              </WrapperTimeName>
            </WrapperTime>
            {showInitButton ? (
              <ContinueConsulta>
                <p>
                  <strong>A consulta já está em andamento</strong>, se as
                  configurações de áudio e vídeo estiverem corretas basta clicar
                  no botão abaixo para retornar!
                </p>
                <button
                  type="button"
                  onClick={() => {
                    history.push(`/video-call/${consultId}`);
                  }}
                  disabled={isDisableReady}
                >
                  Retornar para a Consulta
                </button>
              </ContinueConsulta>
            ) : (
              <>
                <WrapperClock>
                  {showInitButton ? (
                    <WrapperClockTitle>
                      {t('A consulta foi iniciada! Entrando...')}
                    </WrapperClockTitle>
                  ) : (
                    <>
                      <WrapperClockTitle>
                        {credentials?.token?.user_type === ROLE_PROFESSIONAL ? (
                          <>
                            {scheduleDetails?.flow_concluded ? (
                              <>
                                {!clockCompleted &&
                                  !isDisableReady &&
                                  patientIsReady &&
                                  t('Sua consulta começa em:')}

                                <span className="alert">
                                  {isDisableReady &&
                                    `Para iniciar a consulta, habilite o áudio e
                                    vídeo do seu navegador.`}
                                  <br />
                                  {!patientIsReady &&
                                    'Aguarde seu paciente estar pronto.'}
                                </span>
                              </>
                            ) : (
                              <>
                                <span>
                                  {t(
                                    'O paciente ainda não respondeu o questionário de pré-atendimento.'
                                  )}
                                </span>
                                <p>
                                  Após ser preenchido,{' '}
                                  <button onClick={handleReload}>
                                    {t('clique aqui')}
                                  </button>{' '}
                                  para atualizar.
                                </p>
                              </>
                            )}
                          </>
                        ) : (
                          <div className="color-gray-950 mb-4">
                            {scheduleDetails?.flow_concluded ? (
                              <>
                                {isDisableReady || !isReady ? (
                                  <span className="alert">
                                    Para iniciar a consulta habilite seu áudio e
                                    vídeo, depois clique no passo 2 e aguarde o
                                    profissional começar a consulta. Essa é uma
                                    etapa obrigatória!
                                  </span>
                                ) : (
                                  'Aguardando o profissional da saúde iniciar consulta.'
                                )}
                              </>
                            ) : (
                              <span className="patient">
                                Clique no passo 1 para preencher o
                                pré-atendimento.
                                <br />
                                Essa é uma etapa obrigatória.
                              </span>
                            )}
                          </div>
                        )}
                      </WrapperClockTitle>
                    </>
                  )}

                  {!clockCompleted && (
                    <DoctorClock
                      className="mx-auto my-3"
                      consultation={scheduleDetails}
                      onComplete={() => {
                        setClockCompleted(true);
                      }}
                    />
                  )}
                </WrapperClock>

                {isDoctor ? (
                  <WaitRoomSteps
                    steps={[
                      // scheduleDetails?.flow_concluded
                      //   ? {
                      //       type: 'link',
                      //       title: 'Anamnese do paciente',
                      //       href: `/consultas/${scheduleDetails?.key}/anamnese/${scheduleDetails?.anamnesis_key}`,
                      //       tooltip: 'Clique para ler a anamnese'
                      //     }
                      //   : {
                      //       type: 'text',
                      //       title: 'Aguardando anamnese',
                      //       tooltip: 'Paciente precisa preencher a anamnese'
                      //     },
                      {
                        type: 'text',
                        title: 'Consulta criada'
                      },
                      {
                        type: 'text',
                        title: otherPartyOnline
                          ? 'Paciente online'
                          : 'Paciente offline',
                        tooltip: !otherPartyOnline
                          ? 'Aguardando o paciente ficar online'
                          : undefined
                      },
                      {
                        type: 'text',
                        title: patientIsReady
                          ? 'Paciente pronto'
                          : 'Aguardando paciente',
                        tooltip: !patientIsReady
                          ? 'Aguardando o paciente ficar pronto'
                          : undefined
                      },
                      requestedEarlyStart
                        ? {
                            type: 'text',
                            title: 'Iniciando...'
                          }
                        : {
                            type: 'button',
                            title: 'Iniciar consulta',
                            onClick: () => {
                              requestEarlyStart();
                            },
                            disabled:
                              isDisableReady || requestedEarlyStart || !isReady,
                            tooltip: isDisableReady
                              ? 'Antes de continuar verifique as permissões de áudio e vídeo'
                              : 'Clique para iniciar a consulta'
                          }
                    ]}
                    actualStep={stepProfessional}
                  />
                ) : (
                  <>
                    {stepPatient > 2 ? (
                      patientIsReady ? (
                        <div className="mt-8 flex w-full items-center justify-center rounded-md bg-success p-8">
                          <p className="w-full max-w-80 text-balance text-center text-xl font-semibold text-white">
                            Tudo pronto! Aguardando o profissional iniciar a
                            consulta.
                          </p>
                        </div>
                      ) : (
                        <div className="mt-8 flex w-full items-center justify-center rounded-md bg-gray-400 p-8">
                          <p className="w-full max-w-80 text-balance text-center text-xl font-semibold text-white">
                            Carregando...
                          </p>
                        </div>
                      )
                    ) : (
                      <WaitRoomSteps
                        steps={[
                          // {
                          //   type: 'link',
                          //   title: 'Pré-Atendimento',
                          //   href: `/consultas/${scheduleDetails?.key}/anamnese/${scheduleDetails?.anamnesis_key}/chat`,
                          //   disabled: stepPatient > 1,
                          //   tooltip:
                          //     stepPatient <= 1
                          //       ? 'Clique aqui para responder seu Pré-atendimento'
                          //       : undefined
                          // },
                          {
                            type: 'text',
                            title: 'Consulta criada'
                          },
                          isReady
                            ? {
                                type: 'text',
                                title:
                                  'Agora aguarde o profissional iniciar a consulta'
                              }
                            : {
                                type: 'button',
                                title: 'Clique aqui para iniciar o atendimento',
                                onClick: () => {
                                  setIsReady(true);
                                  setStepPatient(3);
                                },
                                disabled:
                                  (isDisableReady || isReady) ??
                                  stepPatient > 2,
                                tooltip: isDisableReady
                                  ? 'Antes de continuar verifique as permissões de áudio e vídeo'
                                  : 'Clique para iniciar o atendimento'
                              }
                        ]}
                        actualStep={stepPatient}
                      />
                    )}
                  </>
                )}

                {credentials?.token?.user_type === ROLE_PROFESSIONAL && (
                  <>
                    {scheduleDetails && scheduleDetails.flow_concluded && (
                      <>
                        {otherPartyOnline && patientIsReady ? (
                          <ReadyInfo>
                            <p>
                              Tudo pronto, clique no passo 4 para iniciar a sua
                              teleconsulta.
                            </p>
                          </ReadyInfo>
                        ) : (
                          <ReadyInfo>
                            <p>
                              Entre em contato com o paciente. A consulta só
                              poderá ser iniciada quando o paciente estiver
                              online
                              <br />e com as permissões de áudio e vídeo
                              configuradas.
                            </p>
                            {scheduleDetails?.invitation?.short_link ? (
                              <p>
                                O seu código da sala é{' '}
                                <strong className="tracking-widest">
                                  {scheduleDetails.invitation.short_link}
                                  {/* {hashids
                                    .encodeHex(
                                      scheduleDetails?.key.replace(/-/g, '')
                                    )
                                    .slice(0, 8)} */}
                                </strong>
                                .
                                <br />
                                Se o paciente estiver no link correto, o mesmo
                                código será mostrado.
                              </p>
                            ) : null}
                          </ReadyInfo>
                        )}
                      </>
                    )}
                  </>
                )}

                {credentials?.token?.user_type !== ROLE_PROFESSIONAL && (
                  <ReadyInfo>
                    <p>
                      {t(
                        'Encontre um local reservado para realizar a sua teleconsulta.'
                      )}{' '}
                      {t(
                        'Não se atrase!\nPreferencialmente use um fone de ouvido e esteja na frente do computador 5 minutos antes do seu horário.'
                      )}
                    </p>
                    {scheduleDetails?.invitation?.short_link ? (
                      <p>
                        O seu código da sala é{' '}
                        <strong className="tracking-widest">
                          {scheduleDetails.invitation.short_link}
                          {/* {hashids
                            .encodeHex(scheduleDetails?.key.replace(/-/g, ''))
                            .slice(0, 8)} */}
                        </strong>
                        .
                        <br />
                        Informe este código ao seu médico em caso de problemas
                        para iniciar a consulta.
                      </p>
                    ) : null}
                  </ReadyInfo>
                )}
              </>
            )}
          </Wrapper>
        </WaitRoomContainer>
        <LiveWaitRoomStatus
          consultation={scheduleDetails}
          userType={credentials?.token?.user_type}
          callPatient={callPatient}
          isReady={isReady}
          onOpenRoom={handleEarlyStart}
          onCloseRoom={handleCancelEarlyStart}
          setOnline={setOtherPartyOnline}
          setConnected={setChannelConnected}
          setPatientIsReady={setPatientIsReady}
        />
      </Container>
      {credentials?.token?.user_type === ROLE_PROFESSIONAL && (
        <>
          <FreePlanVerify
            insuranceCoverage={scheduleDetails?.insurance_coverage !== null}
            privateConsults={
              credentials?.session?.active_subscription?.plan
                ?.enable_private_teleconsultation_invitations === true
            }
          />
          <Chatwoot />
          <Tawk />
        </>
      )}
    </>
  );
}
