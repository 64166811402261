import React, { useEffect, useState } from 'react';
// import Autocomplete from '@mui/material/Autocomplete';
// import Checkbox from '@mui/material/Checkbox';
// import TextField from '@mui/material/TextField';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FormGroup } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CurrencyInput from 'react-currency-input-field';
import { useToast } from '../../../../contexts/ToastContext';
import { updateProfileService } from '../../../../services/session';

import {
  FormContainer,
  UpdateDurationButton,
  TableBox,
  Label,
  Icon,
  BankTransfer,
  HealthInsurance,
  Voucher,
  CurrencyInputContainer,
} from './styles';

import { useAuth } from '../../../../hooks/AuthContext';

function MoreDetails() {
  const {
    credentials,
    refreshSession,
  } = useAuth();

  const { t, i18n } = useTranslation(['doctor_profile']);
  const [rawBillingAmount, setRawBillingAmount] = useState(0);

  useEffect(() => {
    setRawBillingAmount(
      parseFloat(
        credentials?.session?.teleconsultation_settings?.default?.billing_amount,
        10
      )
    );
  }, [credentials]);

  const toast = useToast();

  // const insurancePartnersOptions = [
  //   { title: session?.insurance_partners.map((e) => e.name) },
  // ];

  // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  // const checkedIcon = <CheckBoxIcon fontSize="small" />;

  async function handleUpdatePaymentMethods(dataParams) {
    // const insurance_partners = [];
    // healthInsurance.map((e) => insurance_partners.push(...e.title));

    try {
      dataParams.teleconsultation_settings.default.billing_amount = rawBillingAmount;

      await updateProfileService(dataParams);
      if(credentials){
        refreshSession(credentials.token);
      }
      toast(t('Configurações atualizadas com sucesso!'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Verifique se os campos estão preenchidos corretamente.'), {
        variant: 'error',
      });
    }
  }

  const {
    handleSubmit: handleMoreDetailsSubmit,
    control,
    register,
  } = useForm();

  return (
    <>
      <FormContainer
        onSubmit={handleMoreDetailsSubmit(handleUpdatePaymentMethods)}
      >
        <TableBox>
          <li>
            <Label>
              <input
                type="checkbox"
                name="payment_methods.bank_transfer"
                defaultChecked={credentials?.session?.payment_methods.bank_transfer}
                disabled
              />
              <h1>Transferência Bancária</h1>
              <Icon>
                <BankTransfer />
              </Icon>
            </Label>
          </li>
          <li>
            <Label>
              <input
                type="checkbox"
                name="payment_methods.insurance_partner"
                defaultChecked={credentials?.session?.payment_methods?.insurance_partner}
                ref={register()}
              />
              <h1>Plano de Saúde</h1>
              <Icon>
                <HealthInsurance />
              </Icon>
              {/* <Autocomplete
                multiple
                options={insurancePartnersOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                onChange={(event, value) => setHealthInsurance(value)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                  </li>
                )}
                style={{ marginTop: 10 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plano de Saúde"
                    placeholder="Plano de Saúde"
                  />
                )}
              /> */}
            </Label>
            {credentials && credentials.session?.insurance_partners?.map((partner) => (
              <ul key={partner.key}>
                <img
                  style={{
                    margin: '0 5px 0px 15px',
                    maxWidth: '20px',
                    maxHeight: '20px',
                  }}
                  src={partner.icon}
                  alt={partner.name}
                />
                <li>{partner.name}</li>
              </ul>
            ))}
          </li>
          <li>
            <Label>
              <FormGroup>
                <input
                  type="checkbox"
                  name="payment_methods.voucher"
                  defaultChecked={credentials?.session?.payment_methods.voucher}
                  ref={register()}
                />
                <h1>Geração de Voucher</h1>
                <Icon>
                  <Voucher />
                </Icon>
                <small>
                  * Vouchers são gerados em caso de pagamentos realizados fora
                  da plataforma.
                </small>
              </FormGroup>
            </Label>
          </li>
          <li>
            <Label>
              <h1>Valor da Teleconsulta: </h1>
              <CurrencyInputContainer>
                <Controller
                  control={control}
                  as={
                    <>
                      <Controller
                        control={control}
                        render={({ field: { onChange, ...props } }) => (
                          <CurrencyInput
                            placeholder={t('Valor da Telemedicina')}
                            onValueChange={(value, _name, { float }) => {
                              setRawBillingAmount(float);
                              onChange(value);
                            }}
                            intlConfig={{
                              locale: i18n.language,
                              currency: credentials?.session?.billing_currency,
                            }}
                            allowNegativeValue={false}
                            {...props}
                          />
                        )}
                        name="teleconsultation_settings.default.billing_amount"
                        defaultValue={
                          credentials?.session.teleconsultation_settings?.default
                            ?.billing_amount
                        }
                      />
                    </>
                  }
                  name="teleconsultation_settings.default.duration"
                  defaultValue={
                    credentials?.session.teleconsultation_settings?.default?.duration || 40
                  }
                />
              </CurrencyInputContainer>
            </Label>
          </li>
        </TableBox>
        <UpdateDurationButton>{t('Atualizar')}</UpdateDurationButton>
      </FormContainer>
    </>
  );
}

export default MoreDetails;
