import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';

type BaseDialogProps = {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  disableBackdropClick?: boolean;
};

export default function BaseDialog({
  children,
  title,
  isOpen,
  onClose,
  disableBackdropClick = false
}: BaseDialogProps) {
  const onDialogClose = () => {
    if (!disableBackdropClick) onClose();
  };

  return (
    <Dialog className="relative z-50" open={isOpen} onClose={onDialogClose}>
      <div className="fixed inset-0 bg-gray-450/50" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="relative flex w-full max-w-3xl flex-col items-center justify-between overflow-hidden rounded-lg bg-white font-nunito">
          <Dialog.Title className="w-full bg-gray-150 py-6 text-center font-nunito text-xl font-bold text-blue-650">
            {title}
          </Dialog.Title>
          <button
            className="absolute right-3.5 top-5 grid h-10 w-10 place-items-center rounded-full bg-gray-180 text-gray-250 transition-all duration-200 hover:bg-gray-250 hover:text-gray-350"
            type="button"
            onClick={onClose}
          >
            <XMarkIcon className="h-5 w-5 shrink-0" />
          </button>
          <div className="w-full p-4">{children}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
