import { type ThemeOptions } from '@mui/material';

export const BASE_THEME: ThemeOptions = {
  typography: {
    fontFamily: "'Nunito Sans', sans-serif"
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            whiteSpace: 'normal',
            height: '100%',
            '& .MuiChip-label': {
              overflowWrap: 'anywhere',
              whiteSpace: 'normal'
            }
          }
        }
      ]
    }
  }
};
