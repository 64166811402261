import { type PropsWithChildren, useRef } from 'react';

type ImageUploaderProps = {
  className?: string;
  onChange: (file: Blob) => void;
};

export const ImageUploader = ({
  className = '',
  onChange,
  children
}: PropsWithChildren<ImageUploaderProps>) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event?.target?.files?.[0];

    if (fileUploaded) {
      onChange(fileUploaded);
    }
  };

  return (
    <>
      <button className={className} onClick={handleClick}>
        {children}
      </button>
      <input
        className="hidden"
        type="file"
        accept="image/*"
        onChange={handleChange}
        ref={hiddenFileInput}
      />
    </>
  );
};

export default ImageUploader;
