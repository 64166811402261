import { PauseCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useReducer, useState } from 'react';

type CounterProps = {
  paused?: boolean;
  initialTime?: number;
};

type CounterConfiguration = {
  paused: boolean;
  initiated: boolean;
  timer: null | number;
};

type CounterAction = {
  type: 'pause' | 'resume' | 'start' | 'timer';
  value?: number;
};

function counterReducer(
  state: CounterConfiguration,
  action: CounterAction
): CounterConfiguration {
  if (action.type === 'pause') {
    return { ...state, paused: true };
  } else if (action.type === 'resume') {
    return { ...state, paused: false };
  } else if (action.type === 'start') {
    if (action.value) {
      return { ...state, initiated: true, timer: action.value };
    } else {
      return { ...state, initiated: true };
    }
  } else if (action.type === 'timer') {
    if (state.paused) return state;

    return { ...state, timer: state.timer ? state.timer + 1 : 1 };
  }

  return state;
}

export default function Counter({ paused = false, initialTime }: CounterProps) {
  const [counterConfiguration, counterDispatch] = useReducer(counterReducer, {
    paused: false,
    initiated: false,
    timer: null
  });
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);

  const getSecondsToTimeText = (time: number) => {
    const seconds = time % 60;
    const minutes = Math.floor(time / 60);
    const hours = Math.floor(minutes / 60);

    return `${
      hours >= 1 ? `${hours.toString().padStart(2, '0')}:` : ''
    }${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  useEffect(() => {
    if (
      timer === null &&
      counterConfiguration.initiated &&
      !counterConfiguration.paused
    ) {
      setTimer(
        setInterval(() => {
          counterDispatch({ type: 'timer' });
        }, 1000)
      );
    }

    return () => {
      if (counterConfiguration.initiated && timer) {
        clearInterval(timer);
        setTimer(null);
      }
    };
  }, [counterConfiguration.initiated, counterConfiguration.paused, timer]);

  useEffect(() => {
    if (!counterConfiguration.initiated) {
      if (initialTime !== undefined && typeof initialTime === 'number') {
        counterDispatch({ type: 'start', value: initialTime });
      } else {
        counterDispatch({ type: 'start' });
      }
    }
  }, [counterConfiguration.initiated, initialTime]);

  useEffect(() => {
    if (counterConfiguration.initiated) {
      if (counterConfiguration.paused && !paused) {
        counterDispatch({ type: 'resume' });
      } else if (!counterConfiguration.paused && paused) {
        counterDispatch({ type: 'pause' });
        if (timer) {
          clearInterval(timer);
          setTimer(null);
        }
      }
    }
  }, [
    counterConfiguration.initiated,
    counterConfiguration.paused,
    paused,
    timer
  ]);

  return (
    <div className="flex items-center gap-x-2">
      {counterConfiguration.paused && (
        <PauseCircleIcon
          className="h-6 w-6 text-white"
          title="Aguardando Paciente"
        />
      )}
      {counterConfiguration.timer && (
        <p className="font-semibold text-white">
          {getSecondsToTimeText(counterConfiguration.timer)}
        </p>
      )}
    </div>
  );
}
