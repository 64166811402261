import React from 'react';
import Modal from '../index';

import {
  ModalWrapper,
  ModalWrapperSmall,
  ModalWrapperDescription,
} from './styles';

export default function ModalVIDaas({ openVIDaasAlert, setOpenVIDaasAlert }) {
  function handleToggleModal() {
    setOpenVIDaasAlert(!openVIDaasAlert);
  }

  const renderContent = () => (
    <ModalWrapper>
      <ModalWrapperSmall>Orientações</ModalWrapperSmall>

      <ModalWrapperDescription>
        <p>
          1. Acessar o site{' '}
          <a
            href="https://certificadodigital.cfm.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            [AR CFM - Certificado Digital]
          </a>
        </p>

        <p>
          2. Buscar no final da página, “Como obter o seu Certificado Digital?”.
          Para médicos regularmente inscritos no CFM - Acessar o CRM Virtual.
        </p>

        <p>
          3. Atentar caso seja o primeiro acesso, informar: CRM, CPF e Primeiro
          nome da Mãe.
        </p>

        <p>
          4. Selecionar “não sou um robô” e clique em enviar. O sistema irá
          encaminhar a senha para o e-mail cadastrado junto ao CRM.
        </p>

        <p>
          5. Clicar no ambiente do CRM Virtual, selecionar a opção “Certificado
          Digital” conferindo seus dados.
        </p>

        <p>
          6. Selecionar a aba seguinte, verificando dados de contato
          ESPECIALMENTE o e-mail.
        </p>

        <p>
          7. Finalizar o processo de solicitação, anexando a cópia digital de um
          documento de identidade, preferencialmente CNH.
        </p>

        <p>8. Acompanhar a tramitação no site do CRM Virtual.</p>

        <p>
          9. Verificar retorno de aprovação da solicitação pelo CRM, via e-mail
          cadastrado. Este enviará os detalhes da instalação.
        </p>
      </ModalWrapperDescription>
    </ModalWrapper>
  );

  return (
    <Modal
      handleOpenModal={handleToggleModal}
      openModal={openVIDaasAlert}
      modalTitle="CERTIFICADO DIGITAL GRATUITO - CFM"
      modalTitleColor="black"
      modalWidth="760px"
    >
      {renderContent()}
    </Modal>
  );
}
