import api from './api';

export const insuranceAuthorizationsService = async (partner, partnerCode) => {
  const { data } = await api.post(
    '/teleconsultations/insurance-authorizations',
    {
      partner,
      partner_code: partnerCode
    }
  );

  return data;
};

export const bankTransferUpload = async (consultationKey, partnerCode = '') => {
  const { data } = await api.post(
    `/teleconsultations/${consultationKey}/bank-transfer-authorizations`,
    {
      partner_code: partnerCode
    }
  );

  return data;
};

export const bankTransferReceipt = async (consultationKey, path) => {
  const { data } = await api.post(
    `/teleconsultations/${consultationKey}/bank-transfer-receipts`,
    {
      path
    }
  );

  return data;
};

export const removeReceiptService = async (consultationKey, receiptKey) => {
  const { data } = await api.delete(
    `/teleconsultations/${consultationKey}/bank-transfer-receipts/${receiptKey}`
  );

  return data;
};

export const updateReceipt = async (consultationKey, receiptKey, state) => {
  const { data } = await api.put(
    `/teleconsultations/${consultationKey}/bank-transfer-receipts/${receiptKey}`,
    {
      state
    }
  );

  return data;
};

export const bankListService = async () => {
  const { data } = await api.get('/banks');

  return data;
};
