import api from './api';

export const cid10Service = async (query) => {
  const { data } = await api.get('/cid10', {
    params: {
      q: query
    }
  });

  return data;
};
