import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ReactInputMask from 'react-input-mask';
import { type Doctor } from '../../../entities/doctor';
import { useAuth } from '../../../../hooks/AuthContext';
import { getDoctorInformation } from '../../../../services/secretary';
import { getPatientEmail } from '../../../../services/patient';
import {
  type PatientFormData,
  PatientFormSchema
} from '../../../entities/consult';
import { ConsultDialogContext } from '../context';
import { getSubscription } from '../../../../services/plan';
import { Link } from 'react-router-dom';
import {
  getDoctorFreePlanDetails,
  getFreePlanDetails
} from '../../../services/plan';

const Step = styled.form`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > footer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (max-width: 636px) {
      width: 100%;
    }

    > div {
      display: flex;
      align-items: center;
      column-gap: 20px;

      @media (max-width: 636px) {
        width: 100%;
        column-gap: 10px;
      }

      button {
        color: var(--caren-icongrey);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 250px;
        height: 40px;
        padding: 0 20px;
        border-radius: 5px;
        text-transform: uppercase;
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        @media (max-width: 636px) {
          min-width: unset;
          width: 100%;
        }

        &:disabled {
          filter: grayscale(1);
          cursor: not-allowed;
        }

        &.next {
          background-color: #fdbf71;
          &:hover {
            background-color: #077a94;
          }
        }

        &.back {
          min-width: 150px;
          background-color: var(--caren-lightgrey);
          color: var(--caren-white);

          @media (max-width: 636px) {
            min-width: unset;
          }

          &:hover {
            filter: brightness(115%);
          }
        }
      }
    }
  }
`;

export const FormGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 25px;
  margin-bottom: 60px;

  @media (max-width: 636px) {
    grid-template-columns: 1fr;
  }
`;

export const FormGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    color: var(--caren-icongrey);
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-bottom: 8px;

    span {
      color: red;
    }
  }

  input,
  select {
    background-color: var(--caren-white);
    font-size: 16px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid var(--caren-icongrey);
    border-radius: 4px;
  }
`;

export const ErrorMessageText = styled.p`
  color: red;
  position: absolute;
  bottom: -20px;
  left: 0;
  font-size: 0.8em;
  text-align: start;
`;

type PateintStepProps = {
  step: number;
};

export default function PateintStep({ step }: PateintStepProps) {
  const dialogContext = useContext(ConsultDialogContext);

  const { credentials } = useAuth();

  const [partners, setPartners] = useState<Array<{
    key: string;
    name: string;
  }> | null>(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [paymentIssue, setPaymentIssue] = useState<null | boolean>(null);
  const [activePlanName, setActivePlanName] = useState<null | string>(null);
  const [freePlan, setFreePlan] = useState<null | Awaited<
    ReturnType<typeof getFreePlanDetails>
  >>(null);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    control,
    formState: { errors }
  } = useForm<PatientFormData>({
    resolver: zodResolver(PatientFormSchema),
    mode: 'onChange'
  });

  const cpfText = useWatch<PatientFormData>({
    control,
    name: 'fiscal_code',
    defaultValue: ''
  });
  const emailText = useWatch<PatientFormData>({
    control,
    name: 'email',
    defaultValue: ''
  });

  const onSubmit = async (data: PatientFormData) => {
    if (dialogContext) {
      dialogContext.setStepData(step, {
        header: data.fiscal_code,
        value: data
      });
      dialogContext.forwardStep();
    }
  };

  function formatCPF(cpf: string) {
    let newCPF = cpf;
    newCPF = newCPF.replaceAll('.', '');
    newCPF = newCPF.replaceAll('-', '');
    newCPF = newCPF.replaceAll('_', '');

    return newCPF.trim();
  }

  const renderPrivatePartnerOption = () => {
    let activeSubscription: boolean | undefined = false;
    let hasPrivateFeature: boolean | undefined = false;
    let hasFeatures: boolean | undefined = false;
    let planName: string | undefined = '';

    activeSubscription =
      credentials?.session.active_subscription?.status === 'active';

    hasPrivateFeature =
      credentials?.session.active_subscription?.plan
        ?.enable_private_teleconsultation_invitations;

    planName = credentials?.session.active_subscription?.plan?.name;
    hasFeatures = hasPrivateFeature;

    if (dialogContext) {
      if (dialogContext.isManager && dialogContext.steps.has(1)) {
        const doctor = dialogContext.steps.get(1)?.value as Doctor;
        const hasSecretaryFeature =
          doctor.active_subscription?.plan?.enable_secretaries;
        hasPrivateFeature =
          doctor.active_subscription?.plan
            ?.enable_private_teleconsultation_invitations;

        planName = doctor.active_subscription?.plan?.name;
        activeSubscription = doctor.active_subscription?.status === 'active';

        hasFeatures = hasPrivateFeature && hasSecretaryFeature;
      }
    }

    if (activeSubscription && hasFeatures) {
      return (
        <option value="private" key="private">
          {planName}
        </option>
      );
    } else if (partners && partners.length === 0) {
      if (freePlan && freePlan.used < freePlan.total) {
        return (
          <option value="private" key="private">
            Plano Free
          </option>
        );
      }
    }

    return null;
  };

  const onBlurCpf = () => {
    const cpf = formatCPF(getValues('fiscal_code'));

    if (cpf.length !== 11) {
      setError('fiscal_code', {
        type: 'custom',
        message: 'CPF inválido'
      });
    } else {
      setEmailLoading(true);

      let doctor = null;

      if (dialogContext) {
        if (dialogContext.isManager && dialogContext.steps.has(1)) {
          doctor = dialogContext.steps.get(1)?.value as Doctor;
        }
      }

      getPatientEmail(cpf, doctor?.key ?? undefined).then(data => {
        if (data.email) setValue('email', data.email);
        clearErrors('email');
        setEmailLoading(false);
      });
    }
  };

  useEffect(() => {
    if (
      dialogContext &&
      partners !== null &&
      paymentIssue === null &&
      !dialogContext.isManager
    ) {
      getSubscription().then(response => {
        if (
          response.data.current_invoice &&
          response.data.current_invoice.status === 'failed'
        ) {
          setPaymentIssue(true);
          setActivePlanName(response.data.plan.name);
        }
      });
    }
  }, [dialogContext, partners, paymentIssue]);

  useEffect(() => {
    if (dialogContext && partners === null) {
      if (dialogContext.isManager) {
        if (dialogContext.steps.has(1)) {
          const doctor = dialogContext.steps.get(1)?.value as Doctor;
          getDoctorInformation(doctor.key).then(async response => {
            setPartners(response.insurance_partners);
          });
        }
      } else {
        if (credentials?.session.insurance_partners) {
          setPartners(credentials.session.insurance_partners);
        }
      }
    }
  }, [dialogContext, partners, credentials]);

  useEffect(() => {
    if (dialogContext && freePlan === null) {
      if (!dialogContext.isManager) {
        getFreePlanDetails().then(response => {
          setFreePlan(response);
        });
      } else {
        if (dialogContext.steps.size > 0) {
          const doctor = dialogContext.steps.get(1)?.value as Doctor;
          getDoctorFreePlanDetails(doctor.key).then(response => {
            setFreePlan(response);
          });
        }
      }
    }
  }, [dialogContext, freePlan]);

  if (dialogContext === null || dialogContext.actualStep !== step) return null;

  return (
    <Step onSubmit={handleSubmit(onSubmit)}>
      {!dialogContext.isManager &&
        partners &&
        partners.length > 0 &&
        credentials?.session.active_subscription === null && (
          <header className="mb-10 flex items-center justify-center">
            {paymentIssue ? (
              <p className="text-center text-sm text-warning">
                Notamos uma dificuldade na cobrança do seu plano&nbsp;
                {activePlanName ?? ''}. Por favor atualize seus&nbsp;
                <Link
                  to="/perfil"
                  target="_blank"
                  className="font-semibold underline"
                >
                  dados de pagamento
                </Link>{' '}
                para criar consultas particulares.
              </p>
            ) : (
              <p className="text-center text-sm text-gray-350">
                Traga suas consultas particulares para a plataforma adquirindo
                um de&nbsp;
                <a
                  href="https://www.caren.app/tele#plans"
                  target="_blank"
                  className="text-yellow-750"
                  rel="noreferrer"
                >
                  nossos planos!
                </a>
              </p>
            )}
          </header>
        )}

      <FormGrid>
        <FormGroup>
          <label htmlFor="insurance_partner_key">
            Plano <span>*</span>
          </label>

          <select {...register('insurance_partner_key')}>
            <option disabled value="">
              Selecione...
            </option>
            {partners?.map(partner => (
              <option value={partner.key} key={partner.key}>
                {partner.name}
              </option>
            ))}
            {renderPrivatePartnerOption()}
          </select>

          <ErrorMessage
            errors={errors}
            name="insurance_partner_key"
            as={<ErrorMessageText />}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="fiscal_code">
            CPF <span>*</span>
          </label>

          <Controller
            name="fiscal_code"
            control={control}
            render={({ field }) => (
              <ReactInputMask
                mask="999.999.999-99"
                maskChar=""
                value={field.value}
                onChange={field.onChange}
                onBlur={() => {
                  onBlurCpf();
                  field.onBlur();
                }}
                inputMode="numeric"
              />
            )}
          />

          <ErrorMessage
            errors={errors}
            name="fiscal_code"
            as={<ErrorMessageText />}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="email">
            E-mail <span>*</span>
          </label>

          <input type="email" disabled={emailLoading} {...register('email')} />

          <ErrorMessage
            errors={errors}
            name="email"
            as={<ErrorMessageText />}
          />
        </FormGroup>
      </FormGrid>

      <footer>
        <div>
          <button
            className="back"
            type="button"
            onClick={() => {
              dialogContext.backwardStep();
            }}
          >
            Voltar
          </button>
          <button
            disabled={
              emailText === undefined ||
              emailText.length <= 0 ||
              cpfText === undefined ||
              cpfText.length <= 0 ||
              errors.fiscal_code !== undefined ||
              errors.email !== undefined ||
              errors.insurance_partner_key !== undefined
            }
            className="next"
            type="submit"
          >
            Avançar
          </button>
        </div>
      </footer>
    </Step>
  );
}
