import { useEffect } from 'react';
import { WrapperLoading } from './styles';
import history from '../../services/history';
import { useLocation } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';
import { confirmVidaasQR } from '../../services/vidaas-confirm-qr.service';
import { useTranslation } from 'react-i18next';

export default function VidaasConfirmationRedirect() {
  const location = useLocation();
  const toast = useToast();
  const { t } = useTranslation(['vidaas']);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (query && t && toast) {
      const code = query.get('code');
      const teleconsultationKey = query.get('state');

      async function postQRConfirm() {
        try {
          const { result: _success } = await confirmVidaasQR(
            teleconsultationKey,
            code
          );

          toast(
            t(
              'O pedido de assinatura foi autenticado e será processado em instantes.'
            ),
            {
              variant: 'success'
            }
          );

          window.close();
        } catch (error) {
          toast(
            t(
              'Este pedido de assinatura não pôde ser validado no momento. Por favor tente novamente.'
            ),
            {
              variant: 'error'
            }
          );
        }

        history.push(`/pos-atendimento/${teleconsultationKey}`);
      }

      if (code && teleconsultationKey) {
        postQRConfirm();
      }
    }
  }, []);

  return (
    <WrapperLoading>
      <span />
      <span />
    </WrapperLoading>
  );
}
