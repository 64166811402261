import React from 'react';
import ParticipantInfo from '../ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks';

function Participant({ participant, name, avatarUrl, isLocalParticipant }) {
  return (
    <ParticipantInfo
      participant={participant}
      name={name}
      avatarUrl={avatarUrl}
      isLocalParticipant={isLocalParticipant}
    >
      <ParticipantTracks
        participant={participant}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}

export default React.memo(Participant);
