import api from './api';

export const sessionService = async () => {
  const { data } = await api.get('/profile');

  return data;
};

export const updateProfileService = async (params: any) => {
  const { data } = await api.put('/profile', params);

  return data;
};

export const signIn = async (
  login: string,
  password: string,
  recaptchaResponse: string | null
) => {
  const { data } = await api.post('/signin', {
    login,
    password,
    recaptcha_response: recaptchaResponse
  });

  return data;
};

export const signOut = async () => {
  const { data } = await api.post('/signout', {});

  return data;
};
