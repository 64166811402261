import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Helmet } from 'react-helmet-async';
import {
  makeStyles,
  TextField,
  IconButton,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import LoadingComponent from '../../components/Loading';
import history from '../../services/history';
import Modal from '../../components/Modal';

import {
  FormContainer,
  CreateDoctorButton,
  CreateDoctorContainer,
  BackButtonContainer,
  LogoGreen,
  ErrorMessageText,
  LabelInputContainer,
  GlobalStyle,
} from './form.styles';
import { BackButton } from '../../components/BackButton';
import {
  completeSignup,
  getSignUpInvitation,
} from '../../services/patient-service';

const useStyles = makeStyles((_theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },

  title: {
    color: 'var(--caren-greenblue)',
    fontWeight: 'bold',
    fontSize: '1.5em',
  },

  inviteTitle: {
    color: 'var(--caren-greenblue)',
    fontWeight: 'bold',
    fontSize: '1.5em',
    textAlign: 'center',
  },

  inviteButtons: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    columnGap: '20px',
    marginTop: '20px',

    '& button': {
      padding: '5px 20px',
      width: '100%',
      height: '100%',
      maxWidth: '150px',
      borderRadius: '8px',
      fontSize: '14px',
      fontWeight: '600',
      color: 'var(--caren-white)',
      transition: 'all ease-in-out 200ms',

      '&.deny': {
        backgroundColor: 'var(--caren-red)',

        '&:hover': {
          backgroundColor: 'var(--caren-darkred)',
        },
      },

      '&.accept': {
        backgroundColor: 'var(--caren-green)',

        '&:hover': {
          backgroundColor: 'var(--caren-darkgreen)',
        },
      },
    },
  },

  showPasswordButton: {
    position: 'relative',
  },

  passwordFieldMargin: {
    marginRight: '-40px',
  },

  legend: {
    width: '51%',
    padding: '18.5px 14px',
    marginBottom: '0px',
  },

  radioGroup: {
    width: '100%',
  },

  buttonText: {
    color: 'var(--caren-white) !important',
    fontWeight: 'bold',
    fontSize: '1em',
    textTransform: 'uppercase',
  },
}));

function PatientSignup({
  computedMatch: {
    params: { invite_token },
  },
}) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [_invite, setInvite] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);

  const TITLE = 'Complete seu cadastro';

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const { t } = useTranslation(['doctor_signup']);

  const validationErrorMessages = {
    required: t('Este campo é obrigatório'),
    passwordMinLength: t('Senha deve ter no mínimo 8 caracteres'),
    specialtiesMax: t('No máximo 3 especialidades'),
    passwordsMustMatch: t('As senhas devem ser iguais'),
    invalidEmail: t('E-mail inválido'),
    invalidCPF: t('CPF inválido'),
    phoneTooShort: t('Celular inválido'),
  };

  async function handleCreatePatient(dataParams) {
    const formData = { ...dataParams, response_token: invite_token };
    formData.birthdate = moment(selectedDate);
    
    if (formData.password === formData.password_confirmation) {
      setLoading(true);
      try {
        const { _data } = await completeSignup(formData);

        setOpenModal(true);
      } catch (error) {
        if (error?.response?.data?.error?.message?.login) {
          toast(`Email ${error.response.data.error.message.login}`, {
            variant: 'error',
          });
        } else
          toast(t('Não foi possível efetuar o cadastro, tente novamente.'), {
            variant: 'error',
          });
      }
      setLoading(false);
    }
  }

  const classes = useStyles();

  function handlePasswordType() {
    if (showPassword) {
      return 'text';
    }

    return 'password';
  }

  function showPasswordOnClick() {
    setShowPassword(!showPassword);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function renderShowPasswordButton() {
    if (!showPassword) {
      return (
        <IconButton
          className={classes.showPasswordButton}
          onClick={showPasswordOnClick}
        >
          <FaEyeSlash size={16} />
        </IconButton>
      );
    }

    return (
      <IconButton
        className={classes.showPasswordButton}
        onClick={showPasswordOnClick}
      >
        <FaEye size={16} color="red" />
      </IconButton>
    );
  }

  function validateConfirmPassword(confirmPassword) {
    if (confirmPassword !== getValues('password')) {
      return validationErrorMessages.passwordsMustMatch;
    }

    return null;
  }

  const getInviteInitialInformations = useCallback(async () => {
    const inviteData = await getSignUpInvitation(invite_token);
    setInvite(inviteData);
    setValue('email', inviteData.email);
    setValue('fiscal_code', inviteData.fiscal_code);
  }, []);

  useEffect(() => {
    getInviteInitialInformations();
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <GlobalStyle />
      {openModal && (
        <Modal
          openModal={openModal}
          handleOpenModal={() => ''}
          modalTitle="Sua conta foi criada com sucesso!"
          modalTitleColor="black"
          modalWidth="40%"
          noClosing
        >
          <Button
            onClick={() => history.push('/login')}
            color="primary"
            fullWidth
            variant="contained"
            classes={{
              label: classes.buttonText,
            }}
          >
            Fazer Login
          </Button>
        </Modal>
      )}
      <CreateDoctorContainer>
        <section>
          <LogoGreen />
          <h1 className={classes.title}>{t('Complete seu cadastro')}</h1>
          <FormContainer onSubmit={handleSubmit(handleCreatePatient)}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: validationErrorMessages.required }}
              render={(fields) => (
                <TextField
                  label={t('Nome Completo')}
                  type="text"
                  variant="outlined"
                  fullWidth
                  inputRef={fields.field.ref}
                  {...fields.field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <ErrorMessageText>{message}</ErrorMessageText>
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              render={(fields) => (
                <TextField
                  {...fields.field}
                  label="CPF"
                  variant="outlined"
                  type="text"
                  fullWidth
                  inputRef={fields.field.ref}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              name="fiscal_code"
              rules={{
                required: validationErrorMessages.required,
                pattern: {
                  message: validationErrorMessages.invalidCPF,
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name="cpf"
              render={({ message }) => (
                <ErrorMessageText>{message}</ErrorMessageText>
              )}
            />
            <LabelInputContainer>
              <FormLabel className={classes.legend} component="legend">
                {t('Sexo biológico')}
              </FormLabel>
              <Controller
                control={control}
                defaultValue=""
                render={(fields) => (
                  <RadioGroup
                    className={classes.radioGroup}
                    {...fields.field}
                    row
                  >
                    <FormControlLabel
                      name="gender"
                      value="female"
                      control={<Radio />}
                      label={t('Feminino')}
                      inputRef={fields.field.ref}
                    />
                    <FormControlLabel
                      name="gender"
                      value="male"
                      control={<Radio />}
                      label={t('Masculino')}
                      inputRef={fields.field.ref}
                    />
                  </RadioGroup>
                )}
                name="gender"
                rules={{ required: validationErrorMessages.required }}
              />
            </LabelInputContainer>
            <ErrorMessage
              errors={errors}
              name="gender"
              render={({ message }) => (
                <ErrorMessageText>{message}</ErrorMessageText>
              )}
            />
            <Controller
              control={control}
              name="birthdate"
              render={(fields) => (
                <KeyboardDatePicker
                  {...fields.field}
                  label="Data de Nascimento"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  onChange={handleDateChange}
                  inputRef={fields.field.ref}
                  value={selectedDate}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              render={(fields) => (
                <TextField
                  {...fields.field}
                  label="E-mail"
                  variant="outlined"
                  type="email"
                  fullWidth
                  inputRef={fields.field.ref}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              name="email"
              rules={{
                required: validationErrorMessages.required,
                pattern: {
                  // value: emailRegex,
                  message: validationErrorMessages.invalidEmail,
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <ErrorMessageText>{message}</ErrorMessageText>
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              type="password"
              rules={{
                required: validationErrorMessages.required,
                minLength: {
                  value: 8,
                  message: validationErrorMessages.passwordMinLength,
                },
              }}
              render={(fields) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextField
                    className={classes.passwordFieldMargin}
                    label={t('Senha')}
                    type={handlePasswordType()}
                    variant="outlined"
                    fullWidth
                    inputRef={fields.field.ref}
                    {...fields.field}
                  />
                  {renderShowPasswordButton()}
                </div>
              )}
              name="password"
            />
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <ErrorMessageText>{message}</ErrorMessageText>
              )}
            />
            <Controller
              className={classes.formInput}
              control={control}
              defaultValue=""
              type="password"
              render={(fields) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <TextField
                    className={classes.passwordFieldMargin}
                    label={t('Confirme a senha')}
                    type={handlePasswordType()}
                    variant="outlined"
                    fullWidth
                    inputRef={fields.field.ref}
                    {...fields.field}
                  />
                  {renderShowPasswordButton()}
                </div>
              )}
              name="password_confirmation"
              rules={{
                required: validationErrorMessages.required,
                minLength: {
                  value: 8,
                  message: validationErrorMessages.passwordMinLength,
                },
                validate: validateConfirmPassword,
              }}
            />
            <ErrorMessage
              errors={errors}
              name="password_confirmation"
              render={({ message }) => (
                <ErrorMessageText>{message}</ErrorMessageText>
              )}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CreateDoctorButton
                bgColor="var(--caren-greenblue)"
                type="submit"
              >
                {loading ? <LoadingComponent /> : t('Enviar')}
              </CreateDoctorButton>
            </div>
          </FormContainer>
        </section>
      </CreateDoctorContainer>
    </>
  );
}

export default PatientSignup;
