import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import validateCpf from '../../../../../utils/validateCpf';
import MaskInput from '../../../../../v2/components/inputs/input/mask';
import TextInput from '../../../../../v2/components/inputs/input/text';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  stateLabels,
  stateCities
} from '../../../../../utils/brazilStateUtils';
import SelectInput from '../../../../../v2/components/inputs/input/select';
import AutocompleteInput from '../../../../../v2/components/inputs/input/autocomplete';
import Button from '../../../../../v2/components/inputs/buttons/Button';
import onlyDigits from '../../../../../utils/onlyDigits';
import {
  getTokenizePaymentCard,
  updateSubscriptionCard
} from '../../../../../services/pagarme';
import { type Subscription } from '../../../../../v2/entities/subscription';
import { useAuth } from '../../../../../hooks/AuthContext';
import { getSubscription } from '../../../../../services/plan';
import { useToast } from '../../../../../contexts/ToastContext';

const PaymentFormSchema = z.object({
  cardName: z
    .string({ required_error: 'Digite o nome impresso no cartão' })
    .nonempty('Digite o nome impresso no cartão')
    .trim()
    .regex(/^[a-zA-Z ]+$/, 'Caracteres especiais não são permitidos')
    .max(64, { message: 'Máximo de 64 caracteres' }),
  cardNumber: z
    .string({ required_error: 'Digite um número de cartão' })
    .nonempty('Digite um número de cartão')
    .regex(/\d{4}\s\d{4}\s\d{4}\s\d{4}/, 'Digite um número de cartão válido'),
  cardExpiration: z
    .string({ required_error: 'Digite a data de validade' })
    .nonempty('Digite a data de validade')
    .regex(/\d{2}\/\d{4}/, 'Digite uma data válida')
    .refine(
      val => {
        return moment(val, 'MM/YYYY').endOf('month').isAfter(moment());
      },
      {
        message: 'Este cartão não é mais válido'
      }
    ),
  cardVerification: z
    .string({ required_error: 'Digite o código de verificação' })
    .nonempty('Digite o código de verificação')
    .min(3, 'Digite o código de verificação')
    .max(4, 'Digite o código de verificação')
    .regex(/^\d{3,4}$/, 'Digite um código válido'),
  cpf: z
    .string({ required_error: 'Digite um CPF' })
    .nonempty('Digite um CPF')
    .regex(/\d{3}\.\d{3}\.\d{3}-\d{2}/, 'Digite um CPF válido')
    .refine(validateCpf, {
      message: 'Verifique se o CPF foi digitado corretamente'
    }),
  address: z.object({
    zipCode: z
      .string({ required_error: 'Digite um CEP' })
      .nonempty('Digite um CEP')
      .regex(/\d{5}-\d{3}/, 'Digite um CEP válido'),
    street: z
      .string({ required_error: 'Digite o logradouro' })
      .nonempty('Digite o logradouro'),
    number: z
      .string({ required_error: 'Digite o número' })
      .nonempty('Digite o número'),
    complement: z.string().optional(),
    city: z
      .string({ required_error: 'Digite a cidade' })
      .nonempty('Digite a cidade'),
    state: z
      .string({ required_error: 'Escolha um estado' })
      .nonempty('Escolha um estado')
      .regex(/[A-Z]{2}/, 'Escolha um estado válido'),
    country: z
      .string({ required_error: 'Digite um país válido' })
      .nonempty('Digite um país válido')
      .regex(/[A-Z]{2}/, 'Digite um país válido')
  })
});

type PaymentFormData = z.infer<typeof PaymentFormSchema>;

export default function PaymentForm({
  currentSubscription,
  reloadSubscription
}: {
  currentSubscription: string;
  reloadSubscription: () => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [addressState, setAddressState] = useState<null | string>(null);
  const [addressCity, setAddressCity] = useState<null | string>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const { credentials } = useAuth();
  const toast = useToast();

  const {
    handleSubmit,
    resetField,
    setValue,
    register,
    control,
    watch,
    reset,
    formState: { errors, defaultValues }
  } = useForm<PaymentFormData>({
    resolver: zodResolver(PaymentFormSchema),
    defaultValues: { address: { country: 'BR' } }
  });

  const zipcode = watch('address.zipCode');
  const selectedAddressState = watch('address.state');

  const cityOptions = useMemo(() => {
    if (!selectedAddressState) {
      return [];
    }

    const stateKey = selectedAddressState.toLowerCase();

    const cities = stateCities[stateKey as keyof typeof stateCities] ?? [];

    return cities.map((city: string) => ({ label: city, value: city }));
  }, [selectedAddressState]);

  const onComplete = () => {
    toast('Meio de pagamento atualizado com sucesso!', {
      variant: 'success'
    });
    reset();
    setAddressState(null);
    setAddressCity(null);
    setSubscription(null);
    setLoading(false);
    setTimeout(reloadSubscription, 1000);
  };

  const getSubscriptionStatus = useCallback(async () => {
    if (error !== null || !subscription || !credentials?.token) return;

    try {
      const { data: result }: { data: Subscription } = await getSubscription();

      if (result?.current_invoice?.status === 'paid') {
        onComplete();
        return;
      }

      if (['canceled', 'failed'].includes(result?.current_invoice?.status)) {
        setLoading(false);
        setError(
          'O cartão escolhido não foi autorizado pelo sistema de pagamentos. Por favor verifique os dados do cartão e tente novamente.'
        );
        return;
      }

      setTimeout(() => {
        getSubscriptionStatus();
      }, 3000);
    } catch (e) {
      setLoading(false);
      setError(
        'Seus dados foram atualizados, mas tivemos um problema ao verificar o status.'
      );
    }
  }, [subscription, error, credentials]);

  const savePaymentData = async (data: PaymentFormData) => {
    setLoading(true);
    setError(null);

    try {
      const expiration = data.cardExpiration.split('/');

      const creditCardToken = await getTokenizePaymentCard({
        number: data.cardNumber.replace(/\D/g, ''),
        cvv: data.cardVerification,
        name: data.cardName,
        cpf: onlyDigits(data.cpf),
        exp_month: Number(expiration[0]),
        exp_year: Number(expiration[1].slice(-2))
      });

      try {
        const { data: subscription }: { data: Subscription } =
          await updateSubscriptionCard(currentSubscription, {
            card_token: creditCardToken,
            billing_address: {
              zip_code: data.address.zipCode.replace(/\D/g, ''),
              line_1: `${data.address.street} ${data.address.number}`,
              line_2: data.address.complement ?? '',
              city: data.address.city,
              country: data.address.country,
              state: data.address.state
            }
          });

        if (subscription.status === 'failed') {
          setLoading(false);
          setError(
            'O cartão escolhido não foi autorizado pelo sistema de pagamentos. Por favor verifique os dados do cartão e tente novamente.'
          );
        } else {
          setSubscription(subscription);
        }
      } catch (error: any) {
        setLoading(false);
        setError(
          'Tivemos um problema ao atualizar o meio de pagamento. Verifique os dados e tente novamente.'
        );
      }
    } catch (e) {
      setLoading(false);
      setError(
        'Houve um erro ao processar os dados do seu cartão. Verifique os dados e tente novamente.'
      );
    }
  };

  useEffect(() => {
    if (zipcode) {
      const zipcodeNumbers = zipcode.replace(/\D/g, '');

      if (zipcodeNumbers.length >= 8) {
        fetch(`https://viacep.com.br/ws/${zipcodeNumbers}/json/`, {
          headers: { 'Content-Type': 'application/json' }
        })
          .then(async response => await response.json())
          .then(data => {
            setValue('address.street', data.logradouro, {
              shouldValidate: true
            });
            setValue('address.state', data.uf, { shouldValidate: true });
            setValue('address.city', data.localidade, { shouldValidate: true });
            setAddressState(data.uf);
            setAddressCity(data.localidade);
          });
      }
    }
  }, [zipcode]);

  useEffect(() => {
    if (subscription) {
      setTimeout(() => {
        getSubscriptionStatus();
      }, 1000);
    }
  }, [subscription, getSubscriptionStatus]);

  return (
    <form
      onSubmit={handleSubmit(savePaymentData)}
      className="grid grid-cols-1 gap-5 sm:grid-cols-2"
    >
      <Controller
        control={control}
        name="cardNumber"
        render={({ field: { onChange, name, ref, value } }) => (
          <MaskInput
            mask="9999 9999 9999 9999"
            label="Número do Cartão:"
            error={errors.cardNumber?.message}
            onChange={e => {
              onChange(e);
            }}
            defaultValue={value}
            ref={ref}
            name={name}
          />
        )}
      />
      <div className="flex items-center gap-x-5">
        <Controller
          control={control}
          name="cardExpiration"
          render={({ field: { onChange, name, ref, value } }) => (
            <MaskInput
              mask="99/9999"
              label="Vencimento:"
              error={errors.cardExpiration?.message}
              onChange={e => {
                onChange(e);
              }}
              defaultValue={value}
              ref={ref}
              name={name}
            />
          )}
        />
        <TextInput
          label="CVC:"
          type="text"
          maxLength={4}
          {...register('cardVerification')}
          error={errors.cardVerification?.message}
        />
      </div>
      <TextInput
        label="Nome impresso no cartão:"
        type="text"
        {...register('cardName')}
        error={errors.cardName?.message}
      />
      <Controller
        control={control}
        name="cpf"
        render={({ field: { onChange, ref, value } }) => (
          <MaskInput
            mask="999.999.999-99"
            label="CPF:"
            error={errors.cpf?.message}
            onChange={e => {
              onChange(e);
            }}
            defaultValue={value}
            ref={ref}
          />
        )}
      />
      <div className="col-span-full border-t border-gray-200 pt-2">
        <p className="font-semibold text-gray-450">Endereço de Cobrança</p>
      </div>
      <div className="col-span-full grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <Controller
          control={control}
          name="address.zipCode"
          render={({ field: { onChange, ref, value } }) => (
            <MaskInput
              mask="99999-999"
              label="CEP:"
              error={errors.address?.zipCode?.message}
              onChange={e => {
                onChange(e);
              }}
              defaultValue={value}
              ref={ref}
            />
          )}
        />
        <TextInput
          label="Logradouro:"
          type="text"
          {...register('address.street')}
          error={errors.address?.street?.message}
        />
        <TextInput
          label="Número:"
          type="text"
          {...register('address.number')}
          error={errors.address?.number?.message}
        />
        <TextInput
          label="Complemento:"
          type="text"
          {...register('address.complement')}
          error={errors.address?.complement?.message}
        />
      </div>
      <div className="col-span-full grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
        <SelectInput
          label="País:"
          defaultOption={defaultValues?.address?.country}
          options={[{ label: 'Brasil', value: 'BR' }]}
          onSelectOption={option => {
            if (option) {
              setValue('address.country', option.value, {
                shouldValidate: true
              });
            } else {
              resetField('address.country', { keepError: false });
            }
          }}
          error={errors.address?.country?.message}
        />
        <SelectInput
          label="Estado:"
          defaultOption={addressState ?? defaultValues?.address?.state}
          options={Object.entries(stateLabels).map(([key, value]) => ({
            label: value,
            value: key.toUpperCase()
          }))}
          onSelectOption={option => {
            if (option) {
              setValue('address.state', option.value, { shouldValidate: true });
            } else {
              resetField('address.state', { keepError: false });
            }
          }}
          error={errors.address?.state?.message}
        />
        <AutocompleteInput
          label="Cidade:"
          defaultOption={addressCity ?? defaultValues?.address?.city}
          options={cityOptions}
          onSelectOption={option => {
            if (option) {
              setValue('address.city', option.value, { shouldValidate: true });
            } else {
              resetField('address.city', { keepError: false });
            }
          }}
          error={errors.address?.city?.message}
        />
      </div>
      <div className="col-span-full flex flex-col items-center justify-center pt-5">
        <div className="w-full max-w-md">
          <Button
            disabled={loading}
            size="medium"
            label={loading ? 'Salvando...' : 'Salvar'}
            type="submit"
            colors="success"
          />
        </div>
        {error && <p className="mt-2 text-sm text-error">{error}</p>}
      </div>
    </form>
  );
}
