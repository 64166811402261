import { useState, forwardRef, useImperativeHandle, useCallback } from 'react';

import styled from 'styled-components';
import BaseDialog from '../base';
import onlyDigits from '../../../utils/onlyDigits';
import TextInput from '../../components/inputs/input/text';

const Wrapper = styled.div`
  width: 100%;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    > div {
      display: flex;
      align-items: center;
      column-gap: 20px;

      button {
        color: var(--caren-icongray);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 250px;
        height: 40px;
        padding: 0 20px;
        border-radius: 5px;
        text-transform: uppercase;
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        &:disabled {
          filter: grayscale(1);
          cursor: not-allowed;
        }

        &.back {
          min-width: 150px;
          color: var(--caren-white);
          background-color: var(--caren-lightgrey);

          &:hover {
            filter: brightness(115%);
          }
        }
      }

      a {
        color: var(--caren-icongray);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 200px;
        height: 40px;
        padding: 0 20px;
        border-radius: 5px;
        text-transform: uppercase;
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        &.disabled {
          filter: grayscale(1);
          cursor: not-allowed;
          pointer-events: none;
        }

        &.next {
          background-color: #fdbf71;
          &:hover {
            background-color: #077a94;
          }
        }
      }
    }
  }
`;

type SendWhatsappDialogHandles = {
  open: () => void;
};

type SendWhatsappDialogProps = {
  invitationLink: string;
};

const SendWhatsappDialog = forwardRef<
  SendWhatsappDialogHandles,
  SendWhatsappDialogProps
>(({ invitationLink }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const [patientNumber, setPatientNumber] = useState('');

  const disableSend = () => onlyDigits(patientNumber).length < 9;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatientNumber(e.target.value);
  };

  const buildWhatsAppLink = () => {
    if (disableSend()) return '#';

    const patientDigits = onlyDigits(patientNumber);
    const text = `Olá! Este é o link para confirmar a sua teleconsulta:\n\n${invitationLink}`;

    return `https://wa.me/${patientDigits}?text=${encodeURIComponent(text)}`;
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    }
  }));

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <BaseDialog
      title="Enviar para o Whatsapp"
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <Wrapper>
        <div className="mb-4 w-1/2">
          <TextInput
            label="Número do Paciente"
            value={patientNumber}
            onChange={onChange}
          />
        </div>
        <footer>
          <div>
            <button
              className="back"
              type="button"
              onClick={() => {
                handleClose();
              }}
            >
              Voltar
            </button>
            <a
              href={buildWhatsAppLink()}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                handleClose();
              }}
              className={`next ${disableSend() ? 'disabled' : ''}`}
            >
              Enviar
            </a>
          </div>
        </footer>
      </Wrapper>
    </BaseDialog>
  );
});

SendWhatsappDialog.displayName = 'SendWhatsappDialog';
export type { SendWhatsappDialogHandles };
export default SendWhatsappDialog;
