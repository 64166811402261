import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlanTableList from './PlanTableList';
import { Tooltip } from '@material-ui/core';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { getInvoices } from '../../../../../services/plan';
import moment from 'moment';

const PlanSummary = ({ subscription }) => {
  const { t } = useTranslation(['doctor_profile']);
  const [tooltipValuePlanText, setTooltipValuePlanText] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPayments();

    setTooltipValuePlanText(
      subscription?.plan?.interval == 'month'
        ? 'Este é o valor a ser pago por mês.'
        : 'Este é o valor a ser pago anualmente.'
    );
  }, []);

  const getPayments = async () => {
    setLoading(true);
    try {
      const res = await getInvoices(3);
      setInvoices(res.data);
      setLoading(false);
    } catch (error) {
      toast(t('Não foi possível buscar as informações de fatura.'), {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <div className="bg-white border border-gray-200 rounded-md py-4 px-7">
        <h3 className="text-lg font-bold mb-2 text-gray-450">
          Detalhes do Plano
        </h3>
        <div className="flex justify-between mb-10 lg:flex-row flex-col">
          <div className="mb-5 lg:mb-0">
            <h5 className="font-bold flex">Forma de pagamento</h5>
            <p className="text-gray-350">
              Cartão de crédito ({subscription?.card?.brand})
            </p>
            <p className="text-gray-350">
              ●●●● ●●●● ●●●● {subscription?.card?.last_four_digits}
            </p>
          </div>
          {subscription?.renewal_stopped_at ? (
            <div className="mb-5 lg:mb-0">
              <h5 className="font-bold flex text-warning">Não será renovada</h5>
            </div>
          ) : (
            <div className="mb-5 lg:mb-0">
              <h5 className="font-bold flex">
                Renovação em
                <Tooltip
                  placement="top"
                  title={'Data da próxima cobrança'}
                  className="ml-2 text-gray-350 text-lg"
                >
                  <button>
                    <AiFillQuestionCircle />
                  </button>
                </Tooltip>
              </h5>
              <p className="text-gray-350">
                {/* Date is sent by payment gateway as UTC but actually refers to UTC-3 */}
                {moment(subscription?.next_billing_at).format('LL')}
              </p>
            </div>
          )}
          <div className="mb-5 lg:mb-0">
            <h5 className="font-bold flex">
              Valor do plano
              <Tooltip
                placement="top"
                title={tooltipValuePlanText}
                className="ml-2 text-gray-350 text-lg"
              >
                <button>
                  <AiFillQuestionCircle />
                </button>
              </Tooltip>
            </h5>
            <p className="text-gray-350">
              {(subscription?.plan?.price / 100).toLocaleString('pt-br', {
                style: 'currency',
                currency: subscription?.plan?.currency,
              })}
            </p>
          </div>
        </div>
        <h4 className="font-bold text-md text-gray-450 mb-5">
          Detalhes das últimas faturas
        </h4>
        <PlanTableList
          title={'Data'}
          isInvoice
          data={invoices}
          loading={loading}
        />
      </div>
    </>
  );
};

export default PlanSummary;
