import styled from 'styled-components/macro';
import { ModalContainer } from '../../../../../../../Modal/styles';

export const WrapperLoading = styled.div<{ size: number }>`
  display: inline-block;
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  margin: 0 auto;
  position: relative;

  @keyframes progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: #3995a9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: 720px;
  min-height: 720px;
`;

export const MevoFrame = styled.iframe`
  border: 0;
  width: 100%;
  flex: 1;
`;

export const ModalContent = styled.div<{ modalWidth?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  margin: auto;
  border-radius: 5px;
  width: ${({ modalWidth }) => modalWidth ?? '70%'};
  min-height: min(720px, 80%);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export { ModalContainer };
