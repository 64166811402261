import { useEffect, useState } from 'react';
import useVideoContext from './useVideoContext';

export default function useParticipants () {
  const { room } = useVideoContext();
  const [participants, setParticipants] = useState(null);

  useEffect(() => {
    if (room) {
      if (participants === null) {
        setParticipants(Array.from(room.participants.values() ?? []));
      }

      const participantConnected = (participant) => {
        setParticipants((prevParticipants) => {
          const indexOfParticipant = prevParticipants.findIndex(
            (p) => p.identity === participant.identity
          );

          if (indexOfParticipant !== -1) {
            prevParticipants[indexOfParticipant] = participant;
            return [...prevParticipants];
          }

          return [...prevParticipants, participant];
        });
      };

      const participantDisconnected = (participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.map((p) => {
            if (p.identity === participant.identity) {
              return participant;
            }

            return p;
          })
        );
      };

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }

    return () => {};
  }, [room, participants]);

  return participants;
}
