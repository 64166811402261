import React from 'react';

import VideoAppStateProvider, {
  useVideoAppState,
} from '../../hooks/video/useVideoAppState';
import ErrorDialog from './ErrorDialog';
import { ParticipantProvider } from '../../contexts/ParticipantContext';
import { VideoProvider } from '../../contexts/VideoContext';
import useConnectionOptions from '../../utils/useConnectionOptions';
import App from './App';

export const VideoApp = ({ children }) => {
  const { error, setError } = useVideoAppState();
  const connectionOptions = useConnectionOptions();

  const ValidateError = (err) => {
    if (err.message === 'Permission denied') {
      setError({
        message: 'Você precisa permitir o uso da câmera pelo seu navegador.',
        title: 'ATENÇÃO',
      });
    }
  };

  return (
    <VideoProvider options={connectionOptions} onError={ValidateError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      {children}
    </VideoProvider>
  );
};

export default ({ token, consultation, refreshConsultation }) => (
  <VideoAppStateProvider
    token={token}
    consultation={consultation}
    refreshConsultation={refreshConsultation}
  >
    <VideoApp>
      <ParticipantProvider>
        <App />
      </ParticipantProvider>
    </VideoApp>
  </VideoAppStateProvider>
);
