import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 168px;
  background-color: var(--caren-white);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--caren-clocklightgrey);

  a {
    color: #ff9052;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid #ff9052;
    position: absolute;
    top: 10px;
    right: 10px;
    transition-property: color, border-color;
    transition-duration: 0.3s;

    &:hover {
      color: #53d3b0;
      border-color: #53d3b0;
    }
  }

  & > p {
    font-size: 12px;
    font-weight: bold;
  }
`;
