import React, { type ElementType } from 'react';
import ReactInputMask, { type Props } from 'react-input-mask';
import { twJoin } from 'tailwind-merge';

type MaskInputProps = {
  icon?: ElementType;
  label?: string;
  error?: string;
} & Props

const MaskInput = React.forwardRef<HTMLInputElement, MaskInputProps>(
  ({ icon: Icon, label, error, ...rest }, ref) => {
    return (
      <div className="relative flex w-full flex-col items-start">
        {label && (
          <label className="mb-1 font-nunito text-sm font-semibold text-gray-350">
            {label}
          </label>
        )}
        {Icon && (
          <Icon className="pointer-events-none absolute bottom-3 left-4 h-3.5 w-3.5 text-warning" />
        )}
        <ReactInputMask
          className={twJoin(
            'h-10 w-full rounded-md px-4 py-3 text-sm text-gray-450 ring-transparent focus:border-yellow-650 focus:ring-yellow-650',
            Icon ? 'pl-10' : '',
            error ? 'border-error bg-error/10' : 'border-blue-650/50 bg-white'
          )}
          type="text"
          inputMode="numeric"
          inputRef={ref}
          {...rest}
        />
        {error && (
          <span className="mt-1 font-nunito text-xs text-error">{error}</span>
        )}
      </div>
    );
  }
);

MaskInput.displayName = 'MaskInput';
export default MaskInput;
