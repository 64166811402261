import styled from 'styled-components/macro';
import bannerImg1 from '../../assets/images/doctor-1.jpg';
import bannerImg2 from '../../assets/images/doctor-2.jpg';
import bannerImg3 from '../../assets/images/doctor-3.jpg';

export const BannerImage = styled.div`
  background: ${({ index, beginColor, endColor }) => {
    const banners = [bannerImg1, bannerImg2, bannerImg3];
    const bannerImg = banners[index];

    return `
      linear-gradient(45deg, rgb(68, 68, 68), ${beginColor} 16%, ${endColor}), url(${bannerImg})
    `;
  }};
  background-position: center center;
  background-size: cover;
`;

export const Overlay = styled.div`
  background: linear-gradient(45deg, rgb(2 29 16), #68c6b2);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

export const Banner = styled.div`
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 5%;
  min-height: calc(100vh - 75px - 150px);

  @media (max-width: 1160px) {
    & {
      justify-content: center;
      padding: 20px;
    }
  }
`;

export const PrimaryText = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
  margin-top: ${({ withTopMargin }) => (withTopMargin ? '200px' : '0')};

  color: var(--caren-white);

  @media (max-width: 768px) {
    & {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 10px;
    }
  }
`;

export const SecondText = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  color: var(--caren-white);
  font-size: 20px;
  font-weight: 400;
  line-height: 45px;

  @media (max-width: 768px) {
    & {
      font-size: 15px;
      line-height: 42px;
      margin-bottom: 10px;
    }
  }
`;
