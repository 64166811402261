import { type AxiosResponse } from 'axios';
import api from './api';
import { ConsultSchema } from '../v2/entities/consult';
import moment from 'moment';
import { type Doctor } from '../v2/entities/doctor';

export const getConsultations = async (
  state: string,
  filters: {
    search?: string;
    start?: string;
    end?: string;
    status?: string;
    insurance_partner?: string;
  },
  selectedDoctor: null | Doctor,
  limit?: number,
  after?: string
) => {
  const params = buildParamsFromFilters(state, filters, after, limit);

  if (selectedDoctor !== null) params.employee_key = selectedDoctor.key;

  const { data } = await api.get<unknown, AxiosResponse<{ data: unknown[] }>>(
    '/employees/teleconsultations',
    {
      params
    }
  );

  return data.data.map(item => ConsultSchema.parse(item));
};

const buildParamsFromFilters = (
  state: string,
  filters: {
    search?: string;
    start?: string;
    end?: string;
    status?: string;
    insurance_partner?: string;
  },
  after?: string,
  limit?: number
) => {
  const params: {
    state: string;
    search?: string;
    pending?: boolean;
    concluded?: boolean;
    cancelled?: boolean;
    from?: string;
    to?: string;
    employee_key?: string;
    limit?: number;
    after?: string;
    insurance_partner?: string;
  } = {
    state,
    limit: limit ?? 20
  };

  if (filters.search) params.search = filters.search;
  if (filters.status && filters.status === 'pending') {
    params.pending = true;
    params.cancelled = false;
  }
  if (filters.status && filters.status === 'concluded') {
    params.concluded = true;
    params.cancelled = false;
  }
  if (filters.start)
    params.from = moment(filters.start).startOf('day').utc().toISOString();
  if (filters.end)
    params.to = moment(filters.end).endOf('day').utc().toISOString();

  if (after) params.after = after;

  if (filters.insurance_partner)
    params.insurance_partner = filters.insurance_partner;

  return params;
};

export const getConsultationsReports = async (filters: {
  tab: 'upcoming' | 'historic';
  search?: string;
  start?: string;
  end?: string;
  status?: string;
  employee_key?: string;
  insurance_partner?: string;
}) => {
  const body = buildParamsFromFilters(filters.tab, filters);

  const { data } = await api.post<unknown, AxiosResponse<{ key: string }>>(
    '/reports/employee-appointments',
    {
      filters: body
    },
    {
      params: filters.employee_key ? { employee_key: filters.employee_key } : {}
    }
  );

  return data.key;
};

export const getReportsStatus = async (key: string, employeeKey?: string) => {
  const { data } = await api.get<
    unknown,
    AxiosResponse<{
      ready: 'boolean';
    }>
  >(`/reports/on-demand/${key}`, {
    params: employeeKey ? { employee_key: employeeKey } : {}
  });

  return data.ready;
};

export const getTeleconsultationRunningDuration = async (key: string) => {
  const { data } = await api.get(`/teleconsultations/${key}/running-duration`);

  return data;
};
