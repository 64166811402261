import styled from 'styled-components/macro';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.5em;

  & > div {
    margin-bottom: 0.7em;
  }

  & > p {
    margin-bottom: 0.7em;
  }

  .MuiInputBase-input:focus {
    box-shadow: none;
  }
`;

export const UpdateProfileButton = styled.button`
  border-radius: 5px;
  height: 35px;
  margin: 10px 0;
  width: 250px;
  font-size: 16px;
  color: var(--caren-white);
  background-color: var(--caren-icongrey);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChangePasswordButton = styled.button`
  border-radius: 5px;
  height: 35px;
  margin: 10px 0;
  width: 150px;
  font-size: 16px;
  margin-right: auto;
  color: var(--caren-white);
  background-color: var(--caren-icongrey);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OpenChangePasswordModal = styled(ChangePasswordButton)``;

export const ErrorMessageText = styled.p`
  color: red;
  font-size: 0.8em;
`;

export const CheckBoxDiv = styled.div`
  display: flex;
  margin-bottom: 20px !important;

  span {
    margin-left: 5px;
    position: relative;
    top: -1px;
  }
`;

export const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border: solid 1px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 18.5px 14px;

  &:hover {
    border-color: black;
  }

  &:focus {
    border-color: var(--caren-green);
  }
`;
