import styled from 'styled-components/macro';
import { AiOutlineWoman, AiOutlineMan } from 'react-icons/ai';
// import ImageOrName from '../../components/ImageOrName';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 50px 40px 50px 65px;
  min-height: calc(100vh - 95px);

  @media (max-width: 1140px) {
    padding: 50px 25px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DivRelativeFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FirstGrid = styled.div`
  width: 260px;
  flex-shrink: 0;
  margin-right: 70px;

  @media (max-width: 1140px) {
    margin-right: 25px;
  }
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 320px;
    margin: 0 0 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SecondGrid = styled.div`
  flex-grow: 1;

  @media (max-width: 1023px) {
    flex-grow: unset;
    width: 100%;
  }
`;

export const Subgrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;

  > div {
    position: relative;

    .link-full {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 50;
    }
  }

  @media (max-width: 1023px) {
    grid-template-columns: 100%;
    margin-bottom: 100px;
  }
`;

export const SecondHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 100px;
  margin: 0 0 41px;

  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0 0 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    color: var(--caren-icongrey);
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
  }
`;

export const BackButtonContainer = styled.div`
  margin-right: 40px;
`;

export const CardDefaultContent = styled.div`
  margin: 0 20px 0 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardNumber = styled.p`
  font-size: 43px;
  color: var(--caren-disabled-text);

  span {
    font-size: 20px;
  }
`;

export const CardText = styled.p`
  color: var(--caren-disabled-text);
  font-size: 34px;
`;

export const CardWomanMan = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--caren-disabled-text);
  width: 60%;

  p {
    font-size: 10px;
  }
`;

export const CardUserData = styled.div`
  width: 60%;

  p {
    color: var(--caren-disabled-text);
  }

  a {
    color: var(--caren-disabled-text);
    font-size: 10px;
  }
`;

export const WomanData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WomenIcon = styled(AiOutlineWoman).attrs({})``;

export const Porcentage = styled.div`
  font-size: 10px;
  font-weight: bold;
`;

export const Border = styled.p`
  border: 1px solid var(--caren-disabled-text);
`;

export const ManData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ManIcon = styled(AiOutlineMan).attrs({})``;
