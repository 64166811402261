import api from './api';

export const retrieveTimelineUpload = async (key) => {
  const { data } = await api.get(`/timelines/uploads/${key}`);

  return data;
};

export const teleconsultationReport = async (key) => {
  const { data } = await api.get(`/teleconsultations/${key}/report`);

  return data;
};
