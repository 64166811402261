import styled from 'styled-components/macro';

export const DoctorSchedule = styled.div`
  background-color: #ffffff;
  max-width: 100%;
  border-radius: 3px;
  padding: 15px 10px;
  margin: 0 auto;
  border-radius: 15px;
  flex: 1;
`;

export const DoctorTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DoctorTitle = styled.div`
  color: var(--caren-icongrey);
  font-size: 14px;
`;

export const PatientContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 10px;
  padding: 12px 0 13px;
`;

export const PatientContentImage = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`;

export const PatientContentName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  svg {
    display: block;
    position: relative;
    top: -3px;

    path {
      fill: #262c2e;
      transition-property: fill;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }
  }

  span {
    color: #262c2e;
    font-size: 16px;
    font-weight: bold;
    max-width: 150px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition-property: fill;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  a,
  button {
    background-color: transparent;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:hover {
      svg path {
        fill: #077a94;
      }

      span {
        color: #077a94;
      }
    }
  }
`;

export const PatientContentDate = styled.div`
  color: #455054;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const PatientContentClock = styled.div`
  text-align: center;
  margin-top: 10px;

  .patient-clock-orange {
    div:nth-child(1) {
      background-color: transparent;
      color: #ff9052;
      font-size: 12px;
      font-weight: bold;
      padding: 0;
      height: auto;
      text-align: center;
      line-height: unset;
    }

    div:nth-child(2) {
      background-color: transparent;
      color: #ff9052;
      font-size: 26px;
      font-weight: bold;
    }
  }

  > a,
  > button {
    background-color: transparent;
    color: #ff9052;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid #ff9052;
    transition-property: color, border-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    &:hover {
      color: #3995a9;
      border-color: #3995a9;
    }
  }
`;

export const PatientContentClockTitle = styled.div`
  color: #ff9052;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 4px;
  position: relative;

  span {
    background-color: var(--caren-clockgrey);
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 8px 10px;
    border-radius: 8px;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    pointer-events: none;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover span {
    opacity: 1;
  }
`;

export const PatientContentClockHint = styled.div`
  color: #969faa;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 10px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const PatientContentCTA = styled.div`
  margin-top: 15px;
  width: 100%;
  padding: 0 18px;

  a {
    background-color: #3995a9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 38px;
    border-radius: 8px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    svg {
      display: inline-block;
      font-size: 20px;
      flex-shrink: 0;
      margin-right: 5px;

      polygon,
      rect {
        fill: white;
        stroke: white;
      }
    }

    span {
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
    }

    &:hover {
      background-color: #077a94;
    }
  }
`;
