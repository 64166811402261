import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useToast } from '../../../../contexts/ToastContext';
import { useAuth } from '../../../../hooks/AuthContext';
import LoadingComponent from '../../../Loading';
import { ErrorMessage } from '@hookform/error-message';
import { FormContainer, UpdateProfileButton, ErrorMessageText, InputBox } from './form.styles';
import { updateProfileService } from '../../../../services/session';
import { FORMAT } from '../../../../utils/moment/momentFormat';

function DataForm() {
  const {
    credentials,
    refreshSession,
  } = useAuth();

  const { t } = useTranslation(['patient_profile']);

  const toast = useToast();
  const {
    register: updateProfileRegister,
    control: updateProfileControl,
    handleSubmit: handleUpdateProfileSubmit,
    formState: { errors },
  } = useForm({mode: "onBlur"});

  const [loading, setLoading] = useState(false);

  async function handleUpdateProfile(data) {
    setLoading(true);
    try {
      const formatted = data;
      formatted.birthdate = moment(data?.birthdate).format(FORMAT['us-date']);
      await updateProfileService(formatted);
      if(credentials){
        refreshSession(credentials.token);
      }
      toast(t('Perfil atualizado com sucesso!'), {
        variant: 'success',
      });
    } catch (error) {
      toast(t('Verifique se os campos estão preenchidos corretamente.'), {
        variant: 'error',
      });
    }
    setLoading(false);
  }

  return (
    <>
      <FormContainer onSubmit={handleUpdateProfileSubmit(handleUpdateProfile)}>
        <div>
          <label htmlFor="mobile">{t('Celular')}:</label>
          <input
            type="text"
            id="mobile"
            defaultValue={credentials?.session.phone}
            {...updateProfileRegister('phone')}
          />
        </div>
        <div>
          <label htmlFor="birthday">{t('Nascimento')}:</label>
          <input
            type="date"
            id="birthday"
            defaultValue={credentials?.session.birthdate}
            {...updateProfileRegister('birthdate')}
          />
        </div>
        <InputBox>
          <label htmlFor="weight">{t('Peso')}:</label>
          <div>
            <Controller
              name="weight"
              control={updateProfileControl}
              defaultValue={credentials?.session.weight}
              render={(fields) => (
                <NumberFormat
                  id="weight"
                  prefix=""
                  suffix="kg"
                  decimalSeparator=","
                  thousandSeparator={false}
                  allowNegative={false}
                  decimalScale={1}
                  displayType="input"
                  type="text"
                  onValueChange={(value) => {
                    fields.field.onChange()
                  }}
                  getInputRef={fields.field.ref}
                  {...fields.field}
                />
              )}
              rules={{
                validate: {
                  notEmpty: (value) => {
                    return (value.length > 0) || "Por favor informe o Peso";
                  },
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name="weight"
              as={<ErrorMessageText />}
            />
          </div>
        </InputBox>
        <InputBox>
          <label htmlFor="height">{t('Altura')}:</label>
          <div>
          <Controller
            name="height"
            control={updateProfileControl}
            defaultValue={credentials?.session.height}
            render={(fields) => (
              <NumberFormat
                id="height"
                prefix=""
                suffix="m"
                decimalSeparator=","
                thousandSeparator={false}
                allowNegative={false}
                decimalScale={2}
                displayType="input"
                type="text"
                onValueChange={(value) => {
                  fields.field.onChange()
                }}
                getInputRef={fields.field.ref}
                {...fields.field}
              />
            )}
            rules={{
              validate: {
                notEmpty: (value) => {
                  return (value.length > 0) || "Por favor informe a Altura";
                },
              },
            }}
          />
          <ErrorMessage
              errors={errors}
              name="height"
              as={<ErrorMessageText />}
          />
          </div>
        </InputBox>
        <div>
          <label htmlFor="email">{t('E-mail')}:</label>
          <input type="text" disabled defaultValue={credentials?.session.email} />
        </div>
        <UpdateProfileButton type="submit">
          {loading ? <LoadingComponent /> : t('Atualizar Perfil')}
        </UpdateProfileButton>
      </FormContainer>
    </>
  );
}

export default DataForm;
